import React, { useEffect }  from 'react';

import ModalActionConfirm from './../../dialogs/ModalActionConfirm';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalSendAssetCancelFunctions = (props) => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SEND_ASSET_FLOW);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
        props.onCancelProccess && props.onCancelProccess();
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    

    return (
        <ModalActionConfirm 
        title="Want to cancel transaction" 
        desc="" 
        dataClass="modal_action_failed1" 
        onClose={onClose} 
        goBackward={goBackward}
        
        iconErr={'images/wallets/cancel_confirm.svg'}
        /> 
    )
}

export default ModalSendAssetCancelFunctions;