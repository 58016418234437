import React from "react";

const SendToInput = ({
  value,
  setValue,
  isValidAddress,
  successMsg,
  disabled,
  onChange,
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
    setValue(newValue);
  };

  return (
    <div className="form__input form__input_default from_input_send_to">
      {/* <span className="input_label">Send To</span> */}
      <div className="form__input_default">
      <span className="input_label">To</span>
        <input
          type="text"
          className="form-input-default"
          placeholder="Enter Wallet Address"
          value={value}
          disabled={disabled}
          successMsg={successMsg}
          onChange={(e) => {
            onChange(e);
            handleChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default SendToInput;
