import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import "../../styles/registration/migrationV2.scss";
import Button from "../../components/registration/Button/Button";
import Footer from "../../components/registration/Footer/Footer";
import SeedsConfirm from "../../components/registration/SeedsConfirm";
import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import "../../styles/registration/migrationV2.scss";
import { useApp } from "../../helpers/AppProvider";
import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useFlowGate } from "../../components/flow/FlowGate";

const MigrationV2 = () => {
  const { advance } = useFlowGate();
  const { userActions } = useUser();
  const { actions } = useApp();
  const recaptchaRef = useRecaptcha();
  let navigate = useNavigate();
  const { mnemonic, setMnemonic, mnemonicArr, setMnemonicArr, oldVersionUser } =
    useRegistration({});
  const [error, setError] = useState(false);

  useEffect(() => {
    async function setMnemonicArray() {
      setMnemonicArr(new Array(12).fill(""));
    }
    setMnemonicArray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateSeeds = (seeds) => {
    setMnemonic(seeds.join(" "));
    setMnemonicArr(seeds);
  };

  const goBack = () => {
    navigate(ROUTES.SIGNUP, { replace: true });
  };

  const handlePin = async () => {
    actions.setLoading(true);
    try {
      actions.setLoading(false);
      const token = await recaptchaRef.current.executeAsync();
      const mnems = mnemonic;
      /* ------------- ------------------ */
      /* changePassword( email, safleId, password, mnemonics, pin, recaptchaToken ) - changes user password after being verified
            /* -------------------------------- */
      // const safleID = await userActions.getSafleIdByEmail(otpStatus.email);
      const safleID = oldVersionUser.email;

      /* ------------- ------------------ */
      /* validateMnemonic( safleId, mnemonics ) - validates mnemonics belong to safleId vault
            /* -------------------------------- */
      const valid = await userActions.validateMnemonic(safleID, mnems, token);

      if (valid) {
        actions.setLoading(false);
        advance();
      } else {
        actions.setLoading(false);
        setError("You have entered wrong seed phrase");
      }
    } catch (e) {
      actions.setLoading(false);
      setError("You have entered wrong seed phrase");
    }
  };
  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <div
              className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl migrationv2_moon"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/registration/img/background-full_mod@2x.png` +
                  ")",

                backgroundPosition: "100% bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex">
                <div className="w-full lg:w-2/5 lg:mr-12  pb-10">
                  <img
                    alt="rocket"
                    src={`${process.env.PUBLIC_URL}/images/registration/img/rocket_2.png`}
                    className="absolute rocket_migration z-10 hidden lg:block"
                  />
                  <div className="mb-8 backtowrapper">
                    <div className="backto" onClick={goBack}>
                      <ArrowLeftIcon className="" />
                    </div>
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                  </div>
                  <h1 className=" lg:text-justify font-medium migration_border migration_h pb-4 mb-6">
                    Migrate to V2
                    <p className={"mt-4 text-xs text-left migration_p"}>
                      Enter your seed phrase to migrate to the new version.
                    </p>
                  </h1>
                  <div className="mb-10">
                    <SeedsConfirm onUpdate={handleUpdateSeeds} />
                    <div className="flex my-3 lg:block seed_error_msg">
                      {error}
                    </div>
                  </div>
                  <Button
                    text="Submit"
                    size="lg"
                    fullwidth
                    onClick={handlePin}
                    style={{
                      opacity:
                        mnemonicArr.filter((e) => e !== "").length < 12
                          ? 0.7
                          : 1,
                    }}
                    disabled={mnemonicArr.filter((e) => e !== "").length < 12}
                  />
                </div>

                <div className="justify-center w-0.5 lg:h-96 lg:my-20 mx-10 hidden lg:block "></div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MigrationV2;
