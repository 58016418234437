import { useNavigate } from "react-router-dom";
const NFTHeader = ({ title }) => {
  let navigate = useNavigate();

  return (
    <div className="assets__overview_top flex flex-col justify-center">
      <div className="assets__overview-single-back-listing ">
        <div
          className="assets__overview-single-back-btn back_assets"
          onClick={() => navigate(-1)}
        >
          <span className="icon-arrow-back-black-24-dp" />
        </div>
        <div className="assets__overview-single-back asset__coin">
          <div className="asset__coin-asset-name">{title}</div>
        </div>
      </div>
    </div>
  );
};

export default NFTHeader;
