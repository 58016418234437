import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react"; // Sentry for error logging
import App from "./App";
import "./helpers/deployment.js";
// import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://41ec7ad1bd736be6425097943c388a3a@o1199502.ingest.sentry.io/4505877593063424",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/*\.getsafle\.com/],
    }),
    new Sentry.Replay(),
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  profilesSampleRate: 1.0, // Set profilesSampleRate to 1.0 to profile every transaction.
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
