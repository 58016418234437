// import React, { useState ,useEffect , useRef } from 'react';

import {
  middleEllipsis,
  limitTitleFromMiddle,
} from "../../../helpers/generalHelper";

const DropdownWalletItemNew = (items) => {
  const item = items.items;
  let drpType = "";
  if (items.drpType && items.drpType !== undefined) {
    drpType = items.drpType;
  }
  return (
    <div
      className={
        "drop-link dropdown__active__item control-wallet-dropwdon-new " +
        (items.highlight ? "selected" : "")
      }
      onClick={items.clickHandle}
    >
      {drpType === "NFTDropDown" && (
        <div className="wallet-address-new-name-content wallet-address-receive-assets">
          {/*<img src={props.icon} alt="" />*/}
          <span className="drp_name wallet_address wallet-name-new">
            {limitTitleFromMiddle(item?.wallet_name, 20)}
          </span>
          <span
            className="drp_name wallet_address wallet-address-new"
            style={{ marginLeft: 20 }}
          >
            {middleEllipsis(item.wallet_address, 7)}
          </span>
        </div>
      )}
      {drpType !== "receiveAssets" &&
        drpType !== "wamount" &&
        drpType !== "NFTDropDown" && (
          <div className="wallet-address-new-name-content">
            <img src={item.wallet_avatar} alt="" />
            <span className="drp_name wallet_address wallet-name-new flex ">
              <div class="flex !border-0 justify-center">
                <div className="flex-1 w-80 !border-0  ">
                  {limitTitleFromMiddle(item?.wallet_name, 14)}
                </div>
                <div className="flex-1 !border-0 w-16 ">
                  {" "}
                  {middleEllipsis(item.wallet_address, 4)}
                </div>
              </div>
            </span>
          </div>
        )}
      {/* { drpType === 'receiveAssets' && <span className='drp_name wallet_address wallet-address-new' style={{paddingLeft: 0}} title={item.wallet_address}>
                {item.wallet_address}
            </span>} */}
      {/* { drpType !== 'receiveAssets' && <span className='drp_name wallet_address wallet-address-new'>
                {item.wallet_address}
            </span>} */}
      {drpType === "wamount" && (
        <span className="wallet_asset_amount">
          {item.wallet_ammount} {item.wallet_prefix}{" "}
        </span>
      )}
      {/* {drpType !== "receiveAssets" && drpType !== "NFTDropDown" && (
            <div className="wallet-address-new-name-content">
              <img src={item.wallet_avatar} alt="" />
              <span className="drp_name wallet_address wallet-name-new">
                {item.wallet_name}
              </span>
            </div>
          )} */}
      {drpType === "receiveAssets" && (
        <span
          className="drp_name wallet_address wallet-address-new"
          style={{ paddingLeft: 0 }}
          title={item.wallet_address}
        >
          {item.wallet_address}
        </span>
      )}
      {/* {drpType !== "receiveAssets" && drpType !== "NFTDropDown" && (
            <span className="drp_name wallet_address wallet-address-new">
              {item.wallet_address}
            </span>
          )} */}
      {drpType === "wamount" && (
        <span className="wallet_asset_amount">
          {item.wallet_ammount} {item.wallet_prefix}{" "}
        </span>
      )}
    </div>
  );
};

export default DropdownWalletItemNew;
