import React, { useState, useEffect, useRef } from "react";
import DropdownMobileFilterItem from "../DropdownMobileFilterItem/DropdownMobileFilterItem";
import { useApp } from "../../../helpers/AppProvider";

const DropdownMobileFilter = (props) => {
  const { actions } = useApp();

  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const menuItems = props?.menuItems;
  //populate an object with items

  // first time load the first address in the object
  // or select the selected used address
  useEffect(() => {
    setDropdown(menuItems[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes =
    "main__controls controls__dropdown controls__dropdown_filters_mobile";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        if (props?.noBlockScroll === false) {
          //in popup dont remove add class
        } else {
          actions.setAppData({ blockScroll: false });
        }
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);

      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: true });
      }
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: false });
      }

      // document.body.classList.remove('no_scroll_dropdown');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, filterItem) => {
    //
    // add class with selected
    setHighlightedItem(index);
    // set the state for the dropdown with last value
    setDropdown(filterItem);
    props.onSelect && props.onSelect(filterItem);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    if (props?.noBlockScroll === false) {
    } else {
      actions.setAppData({ blockScroll: false });
    }
    // document.body.classList.remove('no_scroll_dropdown');
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(menuItems.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //menuItems[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, menuItems[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-filters-dropdown-mobile"
        onClick={onClick}
      >
        <div className="filters-content">
          <span className="drp_name filter_name ">{dropdown.filter_label}</span>
        </div>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
      </div>
      <div className="dropdown__list__items">
        {menuItems.map((menuItemsMap, index) => (
          <DropdownMobileFilterItem
            items={menuItemsMap}
            highlight={index === highlighted}
            key={index}
            clickHandle={() => onClickItem(index, menuItemsMap)}
          />
        ))}
      </div>
    </div>
  );
};

export default DropdownMobileFilter;
