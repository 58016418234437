/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { limitTitleFromMiddle } from "../../../helpers/generalHelper";
import ROUTES from "../../../helpers/routes";
// import Storage from "../../../helpers/Storage";
import DropdownNFTWalletItem from "../DropdownNFTWalletItem/DropdownNFTWalletItem";
import { useApp } from "./../../../helpers/AppProvider";

//type = wamount / address

const DropdownNFTWallet = (props) => {
  const { actions, appData } = useApp();
  const location = useLocation();
  // const getAlreadySelectedWalletID =
  //   Storage.load("NFTSelectedWallet")?.selectedWallet;

  let extraClass = "";

  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState();
  const [selectedWallet, setSelectedWallet] = useState({});
  const [dropDownData, setDropDownData] = useState([]);

  // first time load the first address in the object
  useEffect(() => {
    // This will run when user is in nft group component

    // if (appData?.wallets) {
    // if (location.pathname === ROUTES.NFT_COLLECTION_DETAIL) {
    //   console.log(
    //     "entering location of nft collection group",
    //     getAlreadySelectedWalletID
    //   );
    //   const alreadySelectedWallet = appData.wallets.find(
    //     (wallet) => wallet.id === getAlreadySelectedWalletID
    //   );
    //   setSelectedWallet(alreadySelectedWallet);
    //   return;
    // }
    const filteredWallets = appData.accounts;
    // ?.filter(
    //   (account) => account.type === "evm"
    // );

    setSelectedWallet(filteredWallets[0]);
    setHighlightedItem(0);
    setDropDownData(filteredWallets);
    // }
  }, [appData?.accounts]);

  useEffect(() => {
    //
    if (
      selectedWallet
      // &&
      // Object.keys(selectedWallet).length !== 0
      // &&
      // location.pathname !== ROUTES.NFT_COLLECTION_DETAIL
    ) {
      actions.setAppData({ activeWallet: selectedWallet?.address });
      if (selectedWallet?.type === "bitcoin") {
        actions.setAppData({ activeChain: 0 });
      }
    }
  }, [selectedWallet]);

  const onClick = () => {
    location.pathname !== ROUTES.NFT_COLLECTION_DETAIL &&
      setIsActive(!isActive);
  };
  // dropdown classes sum up
  //default class for dropdown
  const default_classes = "main__controls controls__dropdown ";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        actions.setAppData({ blockScroll: false });
        actions.setAppData({ activeWallet: selectedWallet?.address });

        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      // document.body.classList.add('no_scroll_dropdown');
      //actions.setAppData({ blockScroll: true }, { activeWallet: dropdown?.id });
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      // document.body.classList.remove('no_scroll_dropdown');
      //actions.setAppData({ blockScroll: false });
    };
  }, [isActive]);
  // on click item in dropdown do this ;
  const onClickItem = (index, selectedWalletDetails) => {
    setHighlightedItem(index);
    setSelectedWallet(selectedWalletDetails);
    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    actions.setAppData({ blockScroll: false });
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(dropDownData?.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, dropDownData[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div className="dropdown__active__item" onClick={onClick}>
        <span className="icon-navbar-icon-wallet-de-active icon-navbar-icon-new"></span>
        <span
          className="drp_label overflow-hidden"
          style={{
            minWidth: 50,
            marginRight: 10,
            maxWidth: 50,
            textOverflow: "ellipsis",
            fontWeight: 500,
            whiteSpace: "nowrap",
            fontSize: 12,
          }}
        >
          {typeof selectedWallet?.label === "string"
            ? selectedWallet?.label
            : selectedWallet?.label?.polygon}
        </span>
        <span className="drp_name address drp_name_address_new">
          {limitTitleFromMiddle(selectedWallet?.address, 10)}
        </span>
        {location.pathname !== ROUTES.NFT_COLLECTION_DETAIL && (
          <span className="dropdown_arrow_down icon-chevron-down-solid-1 float-right"></span>
        )}
      </div>
      <div className="dropdown__list__items z-30">
        {dropDownData &&
          dropDownData.map((wallet, index) => (
            <DropdownNFTWalletItem
              items={wallet}
              highlight={index === highlighted}
              key={index}
              clickHandle={() => {
                onClickItem(index, wallet);
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default DropdownNFTWallet;
