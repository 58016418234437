import React, { useState, useEffect } from "react";
import CopyAddress from "./../../../common/CopyAddress/CopyAddress";
// import CopyAddress from '../../common/CopyAddress/CopyAddress';
import QRCode from "./../../../common/QRCode/QRCode";
import MainModal from "./../../../generic/MainModal";
import TooltipCopy from "../../../registration/TooltipCopy";
// import { middleEllipsis } from './../../../helpers/generalHelper';
import useModalNavigation, {
  useModalData,
} from "./../../../../helpers/useModalNavigation";

import { useApp } from "./../../../../helpers/AppProvider";
import QRCodeImage from "qrcode";

const ModalWalletDetails = (props) => {
  const [qrImage, setQrImage] = useState("");
  const [modalData, setModalData] = useModalData();
  // const items = props.items;
  const { appData, actions } = useApp();

  // const icon = actions.getChainLogo( props?.useModalData? modalData?.chain : props.items.chain );
  const addr = props.useModalData ? modalData?.address : props.items.address;
  const label = props.useModalData
    ? typeof modalData?.label === "string"
      ? modalData?.label
      : modalData?.label?.polygon
    : typeof props.items.label === "string"
    ? props.items.label
    : props.items.label?.polygon;
  //

  const generateQrAddress = async () => {
    let chain;
    if (props.items.type === "bitcoin") {
      chain = "bitcoin";
    } else if (props.items.type === "evm") {
      chain = "ethereum";
    } else {
      chain = "ethereum";
    }
    var durl = await QRCodeImage.toDataURL(`${chain}:${addr}`, {
      errorCorrectionLevel: "L",
      mode: "alphanumeric",
      margin: 0,
    });
    // $el.find('.qr_area').html(`<img src="${durl}" alt="scan this qr code" />`);
    setQrImage(durl);
  };
  useEffect(() => {
    generateQrAddress();
  }, [props.items.type, addr]);
  return (
    <MainModal
      extraClasses={[
        "modal_w400",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_wallet_details",
        "modal_ovv",
      ]}
      onClose={props.onClose}
      dataClass="modal_receive_asset"
    >
      <div className="modal_wallet_details_content">
        <div className="modal_wallet_details_rows mb_30">
          <div className="modal__receive__heading">Wallet details</div>
        </div>
        <div className="modal_wallet_details_row ">
          <div className="user__box-name">
            <span className="">{label}</span>
          </div>
        </div>
        <div className="modal_wallet_details_row ">
          <QRCode qrImage={qrImage} />
        </div>
        <div className="modal_wallet_details_row ">
          <div className="modal_token_swap_desc modal_token_receive_asset">
            <CopyAddress
              ellipsis_split="7"
              item={addr}
              // logo={ icon? icon : ''}
            />
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalWalletDetails;
