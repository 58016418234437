
const Tip = ( { text, extraStyle = '' } ) => {

  return (
    <div className={`change_safle_ids-tips ${extraStyle}`}>
      { text }
    </div>
  );
};

export default Tip;