import React, { useState } from 'react';
import ModalSwapConfirmation from '../components/dialogs/ModalSwapConfirmation';
import ModalSwapDexSelect from '../components/dialogs/ModalSwapDexSelect';
import ModalSwapSelectChain from '../components/dialogs/ModalSwapSelectChain';
import ModalSwapSelectToken from '../components/dialogs/ModalSwapSelectToken';
import ModalSwapSelectWallet from '../components/dialogs/ModalSwapSelectWallet';
import ModalSwapTxnDone from '../components/dialogs/ModalSwapTxnDone';

const SwapDialogsScreen = () => {

  // openedPopup state hook
  const [ openedPopup, setOpenedPopup ] = useState( null );

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup( false );
  };

  // function for opening the popup
  const openPopup = ( popup ) => {
    setOpenedPopup( popup );
  };

  return (
    <>
        <div>
            <h1>Swap dialogs</h1>
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_swap_dex_select')}>DEX Select</button>
            <br /><br />
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_swap_select_chain')}>Select Chain</button>
            <br /><br />
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_swap_select_wallet')}>Select Wallet</button>
            <br /><br />
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_swap_select_token')}>Select Token</button>
            <br /><br />
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_swap_confirmation')}>Confirmation</button>
            <br /><br />
            <button className="control__button btn_blue modal__trigger" onClick={() => openPopup('modal_txn_done')}>Txn Done</button>
            <br /><br />
        </div>

        { openedPopup === 'modal_swap_dex_select' && <ModalSwapDexSelect onClose={onClose} />}
        { openedPopup === 'modal_swap_select_chain' && <ModalSwapSelectChain onClose={onClose} />}
        { openedPopup === 'modal_swap_select_wallet' && <ModalSwapSelectWallet onClose={onClose} />}
        { openedPopup === 'modal_swap_select_token' && <ModalSwapSelectToken onClose={onClose} />}
        { openedPopup === 'modal_swap_confirmation' && <ModalSwapConfirmation onClose={onClose} />}
        { openedPopup === 'modal_txn_done' && <ModalSwapTxnDone onClose={onClose} />}
    </>
  );
};

export default SwapDialogsScreen;