import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  SETTINGS_CHANGE_PROFILE_IMAGE,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useState, useEffect } from "react";
import { useUser } from "../../../helpers/UserProvider";

const UserAvatar = () => {
  const { userData, userActions } = useUser();
  const [setActiveModalNav, goForward, goBackward] = useModalNavigation(
    SETTINGS_CHANGE_PROFILE_IMAGE
  );
  const [profileImg, setProfileImg] = useState("");

  const load_avatar = async () => {
    const profile_img = await userActions.getProfileImg();
    setProfileImg(profile_img);
  };
  useEffect(() => {
    load_avatar();
  }, []);

  const triggerEditProfile = () => {
    setActiveModalNav("settings_change_profile_upload");
  };

  return (
    <div className="settings__overview_user_ico" onClick={triggerEditProfile}>
      <img
        className="settings__overview_profile_pic"
        src={profileImg + `?${~~(Math.random() * 1000)}`}
        alt="profile image"
      />
      <img
        className="settings__overview_upload_ico"
        src="images/settings/camera-icon.svg"
        alt="upload profile picture"
      />
    </div>
  );
};

export default UserAvatar;
