import React, { useState, useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import ModalSwapSelectChain from "../../dialogs/ModalSwapSelectChain";

const ChainSelector = () => {
  const { appData } = useApp();

  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);
  const [activeChain, setActiveChain] = useState(null);

  useEffect(() => {
    if (appData?.activeChain) {
      if (appData?.activeChain === 1 || appData?.activeChain === 137) {
        setActiveChain(
          appData?.chains
            ? appData.chains.filter(
                (chain) => chain?.chainId === appData?.activeChain
              )[0]
            : appData?.chains[0]
        );
      } else {
        setActiveChain(appData?.chains[0]);
        // actions.setAppData({ activeChain: 1 });
      }
    } else {
      setActiveChain(appData?.chains[0]);
      // actions.setAppData({ activeChain: 1 });
    }
  }, [appData.activeChain, appData.chains]);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  return (
    <div
      className="main__controls controls__dropdown controls__dropdown-new-network"
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-network-dropwdon-new"
        onClick={() => openPopup("modal_swap_select_chain")}
      >
        <div className="network-new-name-content">
          <img src={activeChain && activeChain.details.imageURL} alt="" />
          <span className="drp_name network_name network-name-new">
            {activeChain && activeChain.name}
          </span>
        </div>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
      </div>
      {openedPopup === "modal_swap_select_chain" && (
        <ModalSwapSelectChain onClose={onClose} />
      )}
    </div>
  );
};

export default ChainSelector;
