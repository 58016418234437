import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import Button from '../../registration/Button/Button';

import { XIcon } from '@heroicons/react/solid';
const Toast = ( {
  isOpen = false,
  setOpen,
  hideAfter,
  title,
  bodyText,
  icon = false
} ) => {
  setTimeout(() => {
    setOpen( false )
  }, hideAfter)
  return (
    <Transition appear show={ isOpen } as={ Fragment }>
    <div className="flex space-x-2 justify-center fixed top-5 left z-[200] left-1/2 transform -translate-x-1/2">
      <Transition.Child
            as={ Fragment }
            enter='duration-700'
            enterFrom='-translate-y-50 opacity-0 sm:-translate-y-20 sm:translate-x-0'
            enterTo='translate-y-20 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-300'
            leaveFrom='opacity-100'
            leaveTo='-translate-y-20 opacity-0 sm:translate-x-0'
          >
              <div className="bg-white shadow-lg mx-auto w-100 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-md block" id="static-example" role="alert" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">                
                <div className="p-3 bg-white break-words text-gray-700 rounded-md">
                 { bodyText }
                </div>
              </div>
            </Transition.Child>
            </div>
    </Transition>
  );
};

export default Toast;
