import React, { useState } from "react";
import MainModal from "../../generic/MainModal";
import ModalHeadIcon from "../../common/ModalHeadIcon/ModalHeadIcon";
import PinInput from "../../common/PinInput/PinInput";
import PrimaryBtn from "../../common/PrimaryBtn/PrimaryBtn";
import { useUser } from "../../../helpers/UserProvider";
// import { useApp } from "../../../helpers/AppProvider";

// const Swap = require("@getsafle/safle-swaps-v2").Swaps;
// const Web3 = require("web3");
// const axios = require("axios");
// const Vault = require("@getsafle/safle-vault");

const ModalPinConfirm = ({
  onClose,
  callbackCancelGeneratePopup,
  onHandleConfirm,
}) => {
  const { userActions } = useUser();
  // const { appData, actions } = useApp();
  const [userPin, setPin] = useState(0);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePinComplete = (value) => {
    setPin(value);
  };

  const verifyPin = async (pinValue) => {
    try {
      // const pinNo = Object.values(numbers).join("");
      const resp = await userActions.validatePin(pinValue);

      if (typeof resp !== "object" && resp === true) {
        onHandleConfirm(pinValue);
      } else {
        if (resp?.error) {
          setErrorMessage(resp?.error);
        } else {
          setErrorMessage("Invalid PIN");
        }
      }
    } catch (e) {}
  };

  // async function handleClick(pin) {
  //     actions.setLoading(true, "Please wait")
  //     const activeChain = appData.chains[appData.activeChain || 0];
  //     const web3 = new Web3(new Web3.providers.HttpProvider(activeChain.uri));
  //     const pin = parseInt(userPin); // user pin
  //     const vaultString = userData.user.vault //user vault
  //     // // Initialise vault SDK with vault pin
  //     const vault = new Vault(vaultString);
  //     const enckey = userActions.getDecriptionKey() // decrypted encryption key
  //     // // Restore keyring state if state is not persisted in local storage
  //     const accounts = await vault.restoreKeyringState(vaultString, pin, enckey);

  //

  //     // // Initialise sawps SDK
  //     const swaps = new Swap({ dex: appData.activeDex, rpcURL: "https://mainnet.infura.io/v3/b3a845111c5f4e3eaf646c79bcb4d4c0", chain: activeChain.chain_name });
  //     // // Get raw transactionfrom swaps SDK
  //     const approval = await swaps.approvalRawTransaction({
  //       walletAddress: appData.activeAccountAddress,
  //       fromContractAddress: appData.activeTokenA,
  //       fromQuantity: (appData.fromTokenData[0] && appData.swapFromQuantity * 10 ** appData.fromTokenData[0].decimals).toString()
  //     })
  //
  //     let result_approve;
  //     if (approval !== true) {
  //       // // Get nonce for give address
  //       const count = await web3.eth.getTransactionCount(approval.from);
  //       const defaultNonce = await web3.utils.toHex(count);
  //       // // convert type 1 transaction to type 2 transaction
  //       const approval_tx = {
  //         to: approval.to,
  //         from: approval.from,
  //         value: web3.utils.numberToHex(approval.value),
  //         data: approval.data,
  //         nonce: defaultNonce,
  //         type: '0x2',
  //         gasLimit: web3.utils.numberToHex(approval.gas),
  //         maxPriorityFeePerGas: web3.utils.numberToHex(parseFloat(web3.utils.toWei(parseFloat(appData.maxPriorityFeePerGas).toString(), 'gwei'))),
  //         maxFeePerGas: web3.utils.numberToHex(parseFloat(web3.utils.toWei(parseFloat(appData.maxFeePerGas).toString(), 'gwei'))),
  //       }
  //       // // sign transaction using safle vault
  //       const signedApproval = await vault.signTransaction(approval_tx, pin, activeChain.uri);
  //       // send the signed transaction
  //       try {
  //         result_approve = await web3.eth.sendSignedTransaction(signedApproval.response);
  //       } catch (error) {
  //         result_approve = error;
  //       }
  //     }
  //     const rawTx = await swaps.getRawTransaction({
  //       walletAddress: appData.activeAccountAddress,
  //       toContractAddress: appData.activeTokenB,
  //       toContractDecimal: appData.toTokenData[0] && appData.toTokenData[0].decimals,
  //       fromContractAddress: appData.activeTokenA,
  //       fromContractDecimal: appData.fromTokenData[0] && appData.fromTokenData[0].decimals,
  //       fromQuantity: appData.fromTokenData[0] && appData.swapFromQuantity * 10 ** appData.fromTokenData[0].decimals,
  //       slippageTolerance: appData.swapSlippage,
  //     })
  //     const count_tx = await web3.eth.getTransactionCount(rawTx.from);
  //     const defaultNonce_tx = await web3.utils.toHex(count_tx);
  //     // // convert type 1 transaction to type 2 transaction
  //     const tx = {
  //       to: rawTx.to,
  //       from: rawTx.from,
  //       value: web3.utils.numberToHex(rawTx.value),
  //       data: rawTx.data,
  //       nonce: defaultNonce_tx,
  //       type: '0x2',
  //       gasLimit: web3.utils.numberToHex(rawTx.gas),
  //       maxPriorityFeePerGas: web3.utils.numberToHex(parseFloat(web3.utils.toWei(parseFloat(appData.maxPriorityFeePerGas).toString(), 'gwei'))),
  //       maxFeePerGas: web3.utils.numberToHex(parseFloat(web3.utils.toWei(parseFloat(appData.maxFeePerGas).toString(), 'gwei'))),
  //     }
  //     // // sign transaction using safle vault
  //     const signedTx = await vault.signTransaction(tx, pin, activeChain.uri);
  //     // // send the signed transaction
  //     let result;
  //     try {
  //       result = await web3.eth.sendSignedTransaction(signedTx.response);
  //       const transactionFee = (result.gasUsed * result.effectiveGasPrice) / (10 ** 18);
  //       actions.setAppData({ txResult: true, txId: result.transactionHash, txFee: transactionFee});
  //     } catch (error) {
  //       result = error;
  //       actions.setAppData({ txResult: false });
  //     }
  //     actions.setLoading(false)
  //     callbackCancelGeneratePopup("modal_txn_done");
  //
  // }

  return (
    <MainModal
      extraClasses={[
        "modal_w400",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_enter_pin",
      ]}
      onClose={() => callbackCancelGeneratePopup("modal_cancel_swap")}
      dataClass="modal_enter_pin"
    >
      <div className="modal_succes_content">
        <ModalHeadIcon img="images/icon-pin.svg" />
        <div className="modal_succes_head ">
          Enter PIN to proceed
          <br /> this transaction
        </div>
        <div className="modal_pin_pad-wrp">
          <PinInput
            onCompletePIN={handlePinComplete}
            disabled={(e) => setDisableConfirm(e)}
          />
          {errorMessage && (
            <div className="modal_pin_pad_error text-red-600 font-medium p-[10px]">
              {errorMessage}
            </div>
          )}
        </div>
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <PrimaryBtn
            classes="btn-default btn-white-bordered close_modal_cancel"
            label="Cancel"
            onClick={() => callbackCancelGeneratePopup("modal_cancel_swap")}
          />
          <PrimaryBtn
            classes="btn-default btn-blue modal__trigger"
            label="Confirm"
            disabled={!userPin || disableConfirm}
            onClick={() => verifyPin(userPin)}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalPinConfirm;
