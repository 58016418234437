import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { SETTINGS_EXPORT_SEED_PHRASE } from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import SettingBox from "../../settings/SettingBox/SettingBox";
import Header from "../Header/Header";
import SettingsExportSeedPhraseProcess from "../SettingsExportSeedPhraseProcess/SettingsExportSeedPhraseProcess";
import Sidebar from "../Sidebar/Sidebar";
import SidebarNotification from "../SidebarNotification/SidebarNotification";
import SiteContent from "../SiteContent/SiteContent";

const RecoveryMechanism = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { actions } = useApp();
  const [setExportSeedPhrase] = useModalNavigation(SETTINGS_EXPORT_SEED_PHRASE);
  const [modalData, setData] = useModalData();

  const [sidebarActive, setSidebarActive] = useState(false);
  //const storage =
  const [storage, setStorage] = useState(true);
  const [cloudStorage, setCloudStorage] = useState({ cloud: true });

  useEffect(() => {
    if (location.state) {
      if (location.state.storage === true) {
        // setStorage(true);
        // setCloudStorage(location.state.cloudStorage);
      }
    } else {
      setStorage(false);
    }
    actions.setLoading(false);
  }, [location]);

  const backupSeedsAction = async (e) => {
    setData({
      flow: "export_seed_phrase",
    });
    setExportSeedPhrase("settings_export_seedphrase_pin");
  };

  return (
    <>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <div>
        <div className="main__content__wrapper">
          <Sidebar
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          {/* main content */}
          <SiteContent extraClass="assets__overview-wrapper wallets__overview-wrapper">
            <div className="assets__overview" data-to_show="all_assets">
              {/*----header----*/}
              <div className="py-6 flex flex-row items-center bg-[#EEF2F7]">
                <span>
                  <img
                    src={"images/nft/NFT_back_arrow.svg"}
                    alt=""
                    className="pl-12 cursor-pointer"
                    onClick={() => navigate(-1)}
                  />
                </span>
                <span className="pl-6 text-2xl font-bold">
                  {storage ? "Storage Type" : "Recovery Mechanism"}
                </span>
              </div>
              <div
                className="settings__overview_inner_content bs-0 h-full min-w-[100%] bg-[#F7F9FB]"
                data-show="manage_id"
              >
                <div className="settings__overview_inner-scroll">
                  <div className="settings__overview_manage-safle">
                    <SettingBox
                      title={storage ? "Cloud" : "Backup Seeds"}
                      description={
                        storage
                          ? "Your Vault is stored in the cloud"
                          : "Backup and keep the seeds safe"
                      }
                      icon={"msbico-backup-now"}
                      buttonLabel={!storage && "Backup Now"}
                      onClickAction={!storage && backupSeedsAction}
                      showBtn={storage === true ? false : true}
                      hasStatusBadge={storage && cloudStorage ? true : false}
                      enabled={cloudStorage?.cloud && cloudStorage?.cloud}
                    />
                    <SettingBox
                      title={storage ? "Self" : "Guardian Setup"}
                      description={
                        storage
                          ? "Your Vault is stored on your mobile device"
                          : "Guardian Setup will coming soon"
                      }
                      icon={"msbico-generate-wallet"}
                      hasStatusBadge={storage && !cloudStorage ? true : false}
                      buttonLabel={!storage && "Coming Soon"}
                      showBtn={storage === true ? false : true}
                      enabled={cloudStorage?.mobile && cloudStorage?.mobile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SiteContent>
          {/* end main content */}
          {/* aside notification */}
          <SidebarNotification />
          {/* end aside notification */}
        </div>
        <div className="main__footer" />
        <div className="drop_shadow" />
        <div className="drop_shadow_menu" />
      </div>

      {sidebarActive && <div className="mobile_blur"></div>}
      <SettingsExportSeedPhraseProcess />
    </>
  );
};

export default RecoveryMechanism;
