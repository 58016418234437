const initialState = {
    user: {
        vault           : '',
        safleID         : '',
        avatar          : '',
        decriptionKey   : false,
        token           : false
    },
    keyType: null
}

export default initialState;