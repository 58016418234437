import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NFTHeader from "../components/assets/NFTHeader/NFTHeader";
import Header from "../components/common/Header/Header";
import NFTDetailsLeft from "../components/common/NFTDetailsLeft/NFTDetailsLeft";
import NFTDetailsRight from "../components/common/NFTDetailsRight/NFTDetailsRight";
import Sidebar from "../components/common/Sidebar/Sidebar";

import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import ModalEnterPin from "../components/dialogs/ModalEnterPin";
import ModalSendNFT from "../components/dialogs/ModalSendNFT";
import ModalTransactionDone from "../components/dialogs/ModalTransactionDone";
import { DEFAULT_MODAL_STATUS, SEND_NFT_FLOW } from "../constants/ui";
import { useApp } from "../helpers/AppProvider";
import Storage from "../helpers/Storage";
import ROUTES from "../helpers/routes";
import useModalNavigation from "../helpers/useModalNavigation";
import { useUser } from "../helpers/UserProvider";

const NFTSDetailScreen = () => {
  const location = useLocation();
  const { userData, userActions } = useUser();
  const { appData } = useApp();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goForward, goBackward] =
    useModalNavigation(SEND_NFT_FLOW);
  const modalStorageData = Storage.load("modalData");

  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
    },
    actions,
  } = useApp() || {};

  const [nftDetails, setNftDetails] = useState({});
  const [address, setAddress] = useState("");
  const [nftTxData, setNftTxData] = useState();
  const [txHash, setTxHash] = useState("");
  const [nftTxStatus, setNftTxStatus] = useState(null);

  useEffect(() => {
    if (location?.state?.nftData) {
      let nftData = location?.state?.nftData;
      nftData.chainId = appData?.chains?.filter((chain) => {
        if (location?.state?.nftData?.chain === "eth") {
          return chain?.chain_name === "ethereum";
        } else {
          return chain?.chain_name === location?.state?.nftData?.chain;
        }
      })[0]?.chainId;

      setNftDetails(nftData);

      setAddress(appData?.activeWallet || appData?.accounts[0]?.address);
    }
  }, [location, appData?.activeWallet, appData?.chains, appData?.accounts]);

  const handleModal = (val) => {
    if (val === "next") {
      goForward();
    } else {
      setActiveModalNav(val);
    }
  };

  const NftTxn = async (pinVal) => {
    actions.setLoading(true, "loading");
    try {
      const payload = {
        activeChain: nftDetails?.chainId,
        vaultString: userData.user.vault,
        pin: parseInt(pinVal),
        enckey: userActions.getDecriptionKey(),
        contractAddress: nftDetails?.contract || nftDetails?.contractAddress,
        tokenId: nftDetails?.tokenId,
        from: nftDetails?.wallet || address,
        to: (modalStorageData?.to).trim(),
        maxPriorityFee: modalStorageData?.maxPriorityFee,
        maxFeePerGas: modalStorageData?.maxFee,
        gasLimit: modalStorageData?.gasLimit,
        isErc721:
          nftDetails?.type === "ERC721" || nftDetails?.erc721 ? true : false,
      };
      const res = await actions.sendNftTxn(payload, setTxHash);
      if (!res?.status) {
        setNftTxStatus(false);
        setNftTxData({
          //...res,
          image: nftDetails?.metadata?.image,
          sendTo: modalStorageData?.to,
          maxPriorityFee: modalStorageData?.maxPriorityFee,
          maxFeePerGas: modalStorageData?.maxFee,
          gasLimit: modalStorageData?.gasLimit
        });
      }
      if (res?.status) {
        setNftTxStatus(res?.status);
        setNftTxData({
          ...res,
          image: nftDetails?.metadata?.image,
          sendTo: modalStorageData?.to,
          url:
            appData?.chains?.find(
              (chain) => chain?.chainId === nftDetails?.chainId
            )?.details?.explorer +
            "/tx/" +
            res?.transactionHash,
          chainId: nftDetails?.chainId,
        });
        actions.setLoading(false);
      }
    } catch (err) {
      actions.setLoading(false);
    } finally {
      actions.setLoading(false);
    }
  };

  const close = async () => {
    try {
      actions.setLoading(true, "loading");
      setActiveModalNav("CLOSED");
      if (nftTxData?.status) {
        await Promise.all(
          appData?.wallets.map(async (wallet) => {
            await actions.getWalletNFT(wallet, true);
          })
        );
      }
    } catch (err) {
    } finally {
      setNftTxStatus(null);
      setTxHash("");
      setNftTxData();
      actions.setLoading(false);
      navigate(ROUTES.NFTS);
    }
  };
  return (
    <>
      <div>
        <Header />
        <div className="main__content__wrapper">
          <Sidebar />
          {/* <!-- main content --> */}
          <div className="main__content__wrp assets__overview-wrapper assets__overview-single assets__overview-nft-single">
            <div className="assets__overview">
              <NFTHeader title={nftDetails?.name && nftDetails?.name} />
              <div className="assets__overview_content">
                <NFTDetailsLeft
                  nftDetails={nftDetails}
                  handleModal={handleModal}
                />
                <NFTDetailsRight
                  nftDetails={nftDetails}
                  address={address}
                  handleModal={handleModal}
                />
              </div>
            </div>
          </div>
          {/* <!-- end main content --> */}
          {/* <!-- aside notification --> */}
          <SidebarNotification />
          {/* <!-- end aside notification --> */}
        </div>
      </div>
      {activeModalLabel === "modal_send_nft" && (
        <ModalSendNFT handleModal={handleModal} chainId={nftDetails?.chainId} />
      )}
      {activeModalLabel === "modal_send_nft_enter_pin" && (
        <ModalEnterPin
          labelContinue="Confirm"
          callbackCancelPopup={() => setActiveModalNav("modal_send_nft")}
          onClose={() => setActiveModalNav("modal_send_nft")}
          callbackOpenPopup={(pinVal) => {
            NftTxn(pinVal);
            setNftTxData({
              image: nftDetails?.metadata?.image,
              status: null,
              sendTo: modalStorageData?.to,
            });
            setActiveModalNav("modal_send_nft_success");
          }}
        />
      )}
      {activeModalLabel === "modal_send_nft_success" && (
        <ModalTransactionDone
          onClose={close}
          nftTxHash={txHash}
          nftTxData={nftTxData}
          nftTxStatus={nftTxStatus}
          compType={"NFT"}
        />
      )}
    </>
  );
};

export default NFTSDetailScreen;
