import React, { useEffect }  from 'react';
import { useApp } from '../../../helpers/AppProvider';

import { SETTINGS_EXPORT_SEED_PHRASE, DEFAULT_MODAL_STATUS } from '../../../constants/ui';

import useModalNavigation from '../../../helpers/useModalNavigation';
import ModalEnterOtpFunctions from './ModalEnterOtpFunctions';
import ModalExportSeedPhraseFunctions from './ModalExportSeedPhraseFunctions';
import ModalExportCancelFunctions from './ModalExportCancelFunctions';
import ModalEnterPinFunctions from './ModalEnterPinFunctions';

const SettingsExportSeedPhraseProcess = () => {
    const { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}} = useApp().appData || {};

    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SETTINGS_EXPORT_SEED_PHRASE);
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    return (
        <>
            {activeModalLabel === 'settings_export_seedphrase_otp' && <ModalEnterOtpFunctions />}
            {activeModalLabel === 'settings_export_seedphrase_pin' && <ModalEnterPinFunctions />}
            {activeModalLabel === 'settings_export_seedphrase' && <ModalExportSeedPhraseFunctions />}
            {activeModalLabel === 'settings_export_seedphrase_cancel' && <ModalExportCancelFunctions />}

        </>
    );
}

export default SettingsExportSeedPhraseProcess;