import SettingsMenuIcon from "../SettingsMenuIcon/SettingsMenuIcon";

const SettingsMenuItem = ( { icon, label, onClick, activeClass } ) => {
  return (
    <div className={`settings__overview_aside-menu-item ${activeClass}`} onClick={ () => onClick() }>
      <SettingsMenuIcon icon={ icon } />
      <span className="aside-menu-label">{ label }</span>
    </div>
  );
};

export default SettingsMenuItem;