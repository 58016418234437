const PercentWorthGained = ({ hist, percent = 0 }) => {
    return(
        <span 
            className={ hist ? "asset__coin-worth-gained green_hist" : "asset__coin-worth-gained red_hist" }
            >
            <span className="icon-grow" />
            <span className="icon-grow-copy" />
            <span className="asset__coin_asset-percent">{percent}%</span>
        </span>
    );
  }
  
  export default PercentWorthGained;
  