import { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { convertIFPStoHttpUrl } from "../../../helpers/generalHelper";
import { useModalData } from "../../../helpers/useModalNavigation";
import { useUser } from "../../../helpers/UserProvider";

const NFTDetailsLeft = (props) => {
  const { appData } = useApp();

  const [setData, modalData] = useModalData();

  return (
    <>
      <div className="nft__details-action_mobile">
        <button
          className="btn-default btn-blue"
          onClick={() => {
            modalData(props.nftDetails);
            props?.handleModal("modal_send_nft");
          }}
        >
          Send
        </button>
      </div>
      <div className="assets__overview_single-nft-image">
        <div className="nft__item-image">
          <div className="nft__item-image-bg">
            <img
              src={
                props?.nftDetails?.metadata?.image !==
                "https://usdcgo.com/image.png"
                  ? convertIFPStoHttpUrl(props?.nftDetails?.metadata?.image)
                  : ""
              }
              alt=""
            />
          </div>
          <div className="nft__item-arrows">
            <div className="nft__item-arrow nft__item-arrow-left">
              <span class="icon-chevron-down-solid-1"></span>
            </div>
            <div className="nft__item-arrow nft__item-arrow-right">
              <span class="icon-chevron-down-solid-1"></span>
            </div>
          </div>
        </div>
        <div className="nft__details-description" style={{ minWidth: "50%" }}>
          <p className="break-words">
            {props?.nftDetails?.description ||
              props?.nftDetails?.metadata?.description}
          </p>
        </div>
      </div>
    </>
  );
};

export default NFTDetailsLeft;
