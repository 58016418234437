

const HideLowBalance = ({ title, handleChecked, isChecked }) => {

  return (
    <>
      <div className="asset__listing-action-rel">
        <div className="asset__listing-action ">
          <div className="control__switch">
            <div className="control__switch-label">{ title }</div>
            <div className="control__switch-input">
              <label className="control__switch-switch">
                <input
                  type="checkbox"
                  defaultChecked={isChecked}
                  onChange={() => handleChecked(isChecked)}/>
                <span className="slider round" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HideLowBalance;