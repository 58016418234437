import React, { useState } from "react";

// import ButtonWithIcon from '../ButtonWithIcon'
import { copyToClipboard } from "./../../../helpers/vaultHelpers";
import Toast from "./../../../components/registration/Toast/Toast";
import TooltipCopy from "./../../../components/registration/TooltipCopy";

import { EyeIcon, EyeOffIcon, DuplicateIcon } from "@heroicons/react/solid";

const PrivateKeyCard = ({ handleGetPrivateKey }) => {
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [privateKey, setPrivateKey] = useState("");
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handlePrivateKeyClick = async () => {
    const resp = await handleGetPrivateKey();
    if (resp) {
      setShowPrivateKey(true);
      setPrivateKey(resp);
    }
  };
  const copyPrivateKey = async () => {
    const resp = await handleGetPrivateKey();
    if (resp) {
      setToastMessage("Private key copied.");
      setToastIsOpen(true);
      copyToClipboard(resp);
    }
  };

  const tooltipText = "";
  // const tooltipIcon = `${ process.env.PUBLIC_URL }/images/registration/icons/pkey-icon.svg`;
  const tooltipTextIn = "Copy to clipboard";

  const TooltipIconSvg = () => {
    // return <DuplicateIcon className='w-5 h-5 text-gray-500 hover:text-gray-300 cursor-pointer' />
    return (
      <img
        src="/images/registration/icons/copy_icon_gray.svg"
        alt="Copy Icon"
        className="icon_copy_p_address icon-copy-90"
      />
    );
  };

  const tooltipTextInChange = "Copied to clipboard";

  return (
    <div className="text-white mt-4 mb-6 zindex10 private_key_card">
      <div className="px-4 py-5 sm:p-6 w-full">
        <div>
          <div className="flex items-center mb-2">
            <img
              alt="pub-icon"
              src={`${process.env.PUBLIC_URL}/images/registration/icons/key-icon.svg`}
            />
            <h5 className="ml-4 wallet_details_p_headings">Private Key</h5>
          </div>
          {/* <p className='text-xs text-gray-400 text-start'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            id cursus.
          </p> */}
          {showPrivateKey ? (
            <div className="flex w-full mt-4 mh50">
              <span
                className="text-xs text-gray-500 mr-3 flex items-center mw_36_calc"
                style={{ position: "relative", width: "100%" }}
              >
                <p className="text-start flex p_address p_address_pkey">
                  {privateKey}
                </p>
                {/* <input type="text" className='input_class' value={privateKey} readOnly /> */}
              </span>
              <div className="mw_36">
                <TooltipCopy
                  extraClasses="tooltip_registration flex justify-end mb-2  cursor-pointer no_bg w36"
                  textToDisplay={tooltipText}
                  imageToDisplay={<TooltipIconSvg />}
                  tooltipTextIn={tooltipTextIn}
                  tooltipTextInChange={tooltipTextInChange}
                  itemProps={privateKey}
                  copiedText={privateKey}
                  hideAfter="3000"
                />
              </div>
            </div>
          ) : (
            <div className="flex justify-between w-full mt-4 mh50"></div>
          )}
          {showPrivateKey ? (
            <div className="private_key_bottom">
              <div
                className="unveil_p_key cwhite"
                onClick={() => setShowPrivateKey(false)}
              >
                Click to hide
              </div>
            </div>
          ) : (
            <div className="private_key_bottom">
              <div
                className="unveil_p_key"
                onClick={() => handlePrivateKeyClick()}
              >
                Click to Unveil
              </div>
            </div>
          )}
        </div>
      </div>
      <Toast
        title=""
        bodyText={toastMessage}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
      />
    </div>
  );
};

export default PrivateKeyCard;
