import React, { useState, useEffect, useRef } from "react";

// import AssetsHistoryList from "../AssetsHistoryList/AssetsHistoryList";
import AssetsHistoryListNew from "../AssetsHistoryList/AssetsHistoryListNew";
import AssetsFilters from "../AssetsFilters/AssetsFilters";
// import SearchAsset from "../../common/SearchAsset/SearchAsset";
import DropdownMobileFilter from "../../common/DropdownMobileFilter/DropdownMobileFilter";
import FilterAssetMobileViewItem from "../FilterAssetMobileViewItem/FilterAssetMobileViewItem";
// import { useApp } from "../../../helpers/AppProvider";

import AssetsAddCustomTokenProcess from "../../common/AssetsAddCustomTokenProcess/AssetsAddCustomTokenProcess";
import Toast from "../../registration/Toast/Toast";

//change this to false to enable assets page ( for launch preparation )
const show_beta = process.env.REACT_APP_HIDE_ASSETS === "true";

const AllAssetsList = () => {
  // const { appData, actions, setAppData } = useApp();
  // eslint-disable-next-line no-unused-vars
  const [search, setNewSearch] = useState("");
  const [sortByKey, setSortByKey] = useState("");
  const [sortOrder, setSortOrder] = useState(true);
  const [assetTypeFilterKey, setAssetTypeFilterKey] = useState("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [showFilterOption, setShowFilterOption] = useState(false);
  const [highlighted, setHighlightedItem] = useState({ filter_label: "" });
  const [isMobileFilterActive, setMobileFilterIsActive] = useState(false);
  const [labelMobileFilter, setlabelMobileFilter] = useState("");
  const [favouriteFilter, setFavouriteFilter] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);

  // FILTER ITEMS objects
  const favoriteList = [{ sort_name: "ASC" }, { sort_name: "DESC" }];
  const sortBy = [
    { sort_name: "Sort By" },
    { sort_name: "Name" },
    { sort_name: "Price" },
    { sort_name: "Market Cap" },
    { sort_name: "Volume 24h" },
    { sort_name: "Circulating Supply" },
  ];
  const filterCurrency = [
    { sort_name: "USD" },
    { sort_name: "EUR" },
    { sort_name: "GBP" },
    { sort_name: "INR" },
    { sort_name: "BRL" },
    { sort_name: "CAD" },
    { sort_name: "USD" },
    { sort_name: "EUR" },
    { sort_name: "GBP" },
    { sort_name: "INR" },
    { sort_name: "BRL" },
    { sort_name: "CAD" },
    { sort_name: "USD" },
    { sort_name: "EUR" },
    { sort_name: "GBP" },
    { sort_name: "INR" },
    { sort_name: "BRL" },
    { sort_name: "CAD" },
  ];
  const filterTimeline = [
    { sort_name: "Hourly Change" },
    { sort_name: "Daily Change" },
    { sort_name: "Weekly Change" },
    { sort_name: "Monthly Change" },
    { sort_name: "2 Months Change" },
    { sort_name: "Quarterly Change" },
  ];
  const filterCoinTypes = [
    { sort_name: "All Cryptocurrencies" },
    { sort_name: "Coins" },
    { sort_name: "Tokens" },
  ];

  const mobileFilterRef = useRef(null);

  // const assetSearchHandler = (e) => {
  //   // e.preventDefault();
  //   const lowerCase = e.toLowerCase();
  //   setNewSearch(lowerCase);
  // };

  // const filterEmpty = (e) => {
  //   // e.preventDefault();
  //   setNewSearch("");
  // };

  const filterMobileMap = [
    {
      // key : 1,
      filter_label: (
        <>
          <span className="icon-star-regular"></span> Favourite
        </>
      ),
      filter_key: "filter_fav",
      // filter_action: (e) => filterCallbackFav(e),
    },
    // {
    // 	// key : 2,
    // 	filter_label: 'Select currency',
    //     filter_key:'filter_currency'
    // 	// filter_action: (e) => filterCallbackCurrency(e),

    // },
    {
      // key : 3,
      filter_label: "Sort by",
      filter_key: "filter_sort",
      // filter_action: (e) => filterCallbackSortBy(e),
    },
    {
      // key : 4,
      filter_label: "% Change Timeline",
      filter_key: "filter_timeline",
      // filter_action: (e) => filterCallbackTimeline(e),
    },
    {
      // key : 5,
      filter_label: "Asset type",
      filter_key: "filter_asset",
      // filter_action: (e) => filterCallbackAssetType(e),
    },
  ];

  // const onClick = () => setMobileFilterIsActive(!isMobileFilterActive);
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        mobileFilterRef.current !== null &&
        !mobileFilterRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setMobileFilterIsActive(!isMobileFilterActive);
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isMobileFilterActive) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isMobileFilterActive]);
  let tm = false;
  let tm_fake = false;

  const onClickFakeClose = () => {
    setShowFilterOption(false);
    // set your thing here
    if (tm_fake) {
      clearTimeout(tm_fake);
    }
  };

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    setShowFilterOption(false);
    setHighlightedItem(index);

    // set your thing here
    if (tm) {
      clearTimeout(tm);
    }
  };

  const filterChanged = (activeFilter) => {
    const get_selected = activeFilter.filter_key;
    setShowFilterOption(get_selected);
  };

  useEffect(() => {
    if (showFilterOption === "filter_currency") {
      setlabelMobileFilter("Select currency");
    } else if (showFilterOption === "filter_sort") {
      setlabelMobileFilter("Sort by");
    } else if (showFilterOption === "filter_timeline") {
      setlabelMobileFilter("% Change Timeline");
    } else if (showFilterOption === "filter_asset") {
      setlabelMobileFilter("Asset type");
    } else if (showFilterOption === "filter_fav") {
      setlabelMobileFilter("Favorite");
    } else {
      setlabelMobileFilter("Filter by");
    }
  }, [showFilterOption]);

  const onSortAction = (option) => {
    setSortByKey(option.key);
    setSortOrder(!sortOrder);
  };

  const onFilterByTypeAction = (option) => {
    setAssetTypeFilterKey(option.key);
    setSortOrder(!sortOrder);
  };

  return (
    <div className="assets__overview_content">
      <div
        className="assets__overview_content_tab content_tab-assets active"
        data-view="all__assets"
        style={{ position: "relative" }}
      >
        {!show_beta && (
          <>
            <div className="assets__overview_filters">
              {/* <div className="assets__search_ctn">
                <SearchAsset
                  assetSearchHandler={assetSearchHandler}
                  filter={search}
                  setFilter={setNewSearch}
                  filterEmpty={filterEmpty}
                />
              </div> */}
              <AssetsFilters
                onSortAction={onSortAction}
                onFilterByTypeAction={onFilterByTypeAction}
                setFavouriteFilter={setFavouriteFilter}
                favouriteFilter={favouriteFilter}
              />
              <div className="assets__overview_filters_mobile">
                <DropdownMobileFilter
                  menuItems={filterMobileMap}
                  onSelect={(e) => filterChanged(e)}
                />
              </div>
            </div>
            <AssetsHistoryListNew
              favouriteFilter={favouriteFilter}
              shouldRefresh={shouldRefresh}
              search={search}
              show_beta={show_beta}
              sortByKey={sortByKey}
              sortOrder={sortOrder}
              assetTypeFilterKey={assetTypeFilterKey}
            />
          </>
        )}

        {show_beta && (
          <div
            className="coming_soon__overview"
            style={{
              position: "absolute",
              width: "100%",
              height: "80vh",
              left: 0,
              top: 0,
              zIndex: 100,
              justifyContent: "center",
            }}
          >
            <span className="msbico-planet_guardian"></span>
            <span className="coming_soon">Coming Soon</span>
            <div className="coming_soon_msg">
              We will notify you once this feature is ready 🙌
            </div>
          </div>
        )}
      </div>
      {showFilterOption && (
        <div className="mobile_filter_options_wrapper">
          {
            // select currency
            // sort by
            // change timeline
            // asset type
          }
          <div
            className="mobile_filter_option_main_fake"
            onClick={onClickFakeClose}
          ></div>
          <div className="mobile_filter_option_main" ref={mobileFilterRef}>
            <span className="mobile_filter_options_heading">
              {labelMobileFilter}
            </span>
            {/* {showFilterOption === "filter_currency" && (
              <div className="mobile_filter_options_search">
                <div className="form__input form__input-search">
                  <input
                    type="text"
                    className="form-input-default"
                    style={{ paddingLeft: "10px" }}
                    placeholder="Search"
                  />
                </div>
              </div>
            )} */}

            <div className="mobile_filter_options_inner_scroll">
              {showFilterOption === "filter_fav" &&
                favoriteList.map((favoriteList, index) => (
                  <FilterAssetMobileViewItem
                    items={favoriteList}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, favoriteList)}
                  />
                ))}
              {showFilterOption === "filter_sort" &&
                sortBy.map((sortBy, index) => (
                  <FilterAssetMobileViewItem
                    items={sortBy}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, sortBy)}
                  />
                ))}
              {showFilterOption === "filter_currency" &&
                filterCurrency.map((filterCurrency, index) => (
                  <FilterAssetMobileViewItem
                    items={filterCurrency}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, filterCurrency)}
                  />
                ))}
              {showFilterOption === "filter_timeline" &&
                filterTimeline.map((filterTimeline, index) => (
                  <FilterAssetMobileViewItem
                    items={filterTimeline}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, filterTimeline)}
                  />
                ))}
              {showFilterOption === "filter_asset" &&
                filterCoinTypes.map((filterCoinTypes, index) => (
                  <FilterAssetMobileViewItem
                    items={filterCoinTypes}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, filterCoinTypes)}
                  />
                ))}
            </div>
          </div>
        </div>
      )}

      <AssetsAddCustomTokenProcess
        shouldRefresh={shouldRefresh}
        callbackModalClose={(e) => setShouldRefresh(e)}
        setToastIsOpen={setToastIsOpen}
      />
      <Toast
        title=""
        bodyText={"Token added successfully"}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        seedPhrase
      />
    </div>
  );
};

export default AllAssetsList;
