import React from "react";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import MainModal from "./../generic/MainModal";

const ModalWalletRemoved = ({ onClose }) => {
  return (
    <MainModal
      onClose={onClose}
      title=" "
      extraClasses={[
        "modal_w420",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_wallet_remove",
      ]}
      dataClass="modal_wallet_removed"
    >
      <div className="modal_succes_content">
        <div className="modal_succes_ico">
          <img src="images/wallets/icon-done.svg" alt="" />
        </div>
        <div className="modal_succes_head">Your wallet is archived</div>
        <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered">
            The wallet has been successfully archived
          </span>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions">
          <PrimaryBtn
            classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600 close_modal_okr"
            label="OK"
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalWalletRemoved;
