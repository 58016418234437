import React, { useRef, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import DropDownNFTNetworkItem from "../DropdownNFTNetworkItem/DropDownNFTNetworkItem";

const DropDownNFTNetwork = (props) => {
  const { actions } = useApp();
  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);

  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  // sum all classes in to one
  //default class for dropdown
  const default_classes =
    "main__controls controls__dropdown controls__dropdown-new-network";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  const onClickItem = (index, chain) => {
    setHighlightedItem(index);
    props?.setSelectedChain(chain);
    //actions.setAppData({activeChain:index})
    //setChosenChain(index);
    // set the state for the dropdown with last value
    //setSelectedChain(chain);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    props.onSelect && props.onSelect(chain);

    if (props?.noBlockScroll === false) {
    } else {
      actions.setAppData({ blockScroll: false });
    }
  };

  const onClick = () => setIsActive(!isActive);

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(props.networks.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //setHighlightedItem
    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      onClickItem(updateHighlightVal, props.chains[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-network-dropwdon-new"
        onClick={onClick}
      >
        <div className="network-new-name-content">
          <img src={props.selectedChain?.network_logo} alt="" />
          <span className="drp_name network_name network-name-new">
            {props.selectedChain?.network_name}
          </span>
        </div>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
      </div>
      <div className="dropdown__list__items">
        {props.chains.length !== 0 &&
          props.chains?.map((chain, index) => (
            <DropDownNFTNetworkItem
              items={chain}
              highlight={index === highlighted}
              key={index}
              clickHandle={() => onClickItem(index, chain)}
            />
          ))}
      </div>
    </div>
  );
};

export default DropDownNFTNetwork;
