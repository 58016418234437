import React, { useEffect } from "react";

import ModalActionSuccess from "./../../dialogs/ModalActionSuccess";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  SETTINGS_CHANGE_EMAIL,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { checkProperties } from "ethers/lib/utils";

const ModalEmailUpdatedFunctions = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    SETTINGS_CHANGE_EMAIL
  );

  const onClose = () => {
    setActiveModalNav("CLOSED");

    props.onEmailUpdate && props.onEmailUpdate();
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //

  return (
    <ModalActionSuccess
      title="Email ID is updated"
      desc="You can now login using the new Email ID."
      dataClass="modal_action_success1"
      onClose={onClose}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      // modalNext_label='modal_email_address_failed'
      // callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalEmailUpdatedFunctions;
