import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

import Button from "./../../components/registration/Button/Button";
import Footer from "./../../components/registration/Footer/Footer";
import Modal from "./../../components/registration/Modal/Modal";
import OTPInputs from "./../../components/registration/OTPInputs/OTPInputs";
import "../../styles/registration/base.scss";
import "../../styles/registration/otpVerification.scss";
import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useApp } from "../../helpers/AppProvider";

import { useFlowGate } from "./../../components/flow/FlowGate";

import { useUser } from "../../helpers/UserProvider";

const initialValues = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
};

const OTPVerificationRegistration = () => {
  const { advance } = useFlowGate();
  let navigate = useNavigate();
  const { actions } = useApp();
  const { userActions } = useUser();
  const wtime = 30;
  const { otpStatus, setOtpStatus, setNewPasswordToken } = useRegistration();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(wtime);
  const [numbers, setNumbers] = useState(initialValues);
  const [counting, setCounting] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showTooltip, setShowTooltip] = useState(false);
  const timerRef = useRef(null);
  const tooltipActiveClass = showTooltip ? " opened " : " ";
  const tooltipTimer = (e) => {
    setShowTooltip(true);
    timerRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };
  useEffect(() => {
    document.title = "Register | Safle";
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  let tm;
  let now;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    now = new Date().getTime();

    setMinutes(0);
    setCounting(true);
    start_timer();

    return () => {
      clearInterval(tm);
    };
  }, []);
  const start_timer = () => {
    tm = setInterval(() => {
      let tl = wtime - ~~((new Date().getTime() - now) / 1000);
      setSeconds(tl);
      if (tl < 1) {
        clearInterval(tm);
        setCounting(false);
      }
    }, 1000);
  };

  const resetOTP = () => {
    setNumbers({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
    });
    setForceRefresh(true);
    setErrorMessage("");
    setTimeout(() => {
      setForceRefresh(false);
    }, 100);
  };

  const verifyOTP = async () => {
    // use this one for the SignUp Flow
    actions.setLoading(true);

    const otp = `${numbers.first}${numbers.second}${numbers.third}${numbers.fourth}${numbers.fifth}${numbers.sixth}`;
    let params = {
      safleID: otpStatus.email,
      email: otpStatus.email,
      otp,
      action: "email-confirmation",
    };

    if (otpStatus && otpStatus.otpType === "email-verification") {
      params = {
        safleID: otpStatus.safleId,
        email: otpStatus.email,
        otp,
        action: otpStatus.otpType,
      };
    }
    const resp = await userActions.verifyEmailOTP(params);

    if (resp.statusCode === 201) {
      setNewPasswordToken(resp.token);
      if (otpStatus.otpType === "email-confirmation") {
        advance();
      } else {
        advance();
      }
    } else if (resp.statusCode === 400) {
      setErrorMessage("You have entered wrong verification code");
    }
    actions.setLoading(false);
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();
    actions.setLoading(true);
    if (otpStatus.email) {
      const resp = await userActions.resendEmailOTP(
        otpStatus.email,
        otpStatus.safleId
      );

      if (resp.statusCode === 201) {
        const dateNow = Date.now();
        setOtpStatus({
          ...otpStatus,
          email: otpStatus.email,
          message: resp.message,
          startedAt: dateNow,
        });
        resetOTP();
        now = new Date().getTime();
        setSeconds(wtime);

        setCounting(true);

        start_timer();
      }
    } else {
      navigate(ROUTES.FORGOT_PASS, { replace: true });
    }
    actions.setLoading(false);
  };

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <div
              className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/registration/img/img2@2x.png` +
                  ")",
                backgroundSize: "40%",
                backgroundPosition: "100% bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex">
                <div className="w-full lg:w-2/5 lg:mr-12">
                  <div className="mb-8">
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                  </div>
                  <h1 className="lg:text-justify font-medium otp_border otp_h pb-4 mb-6">
                    Enter verification code
                    <p className={"mt-4 text-xs text-left otp_p"}>
                      We have just sent a verification code to {otpStatus.email}
                    </p>
                  </h1>

                  <div className="mb-7">
                    {!forceRefresh && (
                      <OTPInputs
                        numbers={numbers}
                        setNumbers={setNumbers}
                        forceRefresh={forceRefresh}
                        errorCallback={setErrorMessage}
                      />
                    )}
                    {errorMessage && (
                      <div className="flex my-3 lg:block seed_error_msg no_padding_left">
                        {errorMessage}
                      </div>
                    )}
                    <p className={"my-5 flex justify-center text-sm otp_timer"}>
                      {" "}
                      {minutes.toString().padStart(2, "0")}:
                      {seconds.toString().padStart(2, "0")}{" "}
                    </p>
                  </div>

                  <div className="my-7 flex justify-center md:justify-start text-xs didnt_receive">
                    <span>Didn't receive the code?</span>

                    {!counting && (
                      <Link
                        to={"#"}
                        onClick={counting ? () => {} : handleResendOTP}
                        className={
                          "flex items-center font-bold ml-1 sm:ml-2 " +
                          (counting ? "text-gray-200" : "text-blue-500")
                        }
                      >
                        Resend Code
                        <ArrowNarrowRightIcon className="h-2 w-2 ml-1" />
                      </Link>
                    )}
                    {counting && (
                      <Link
                        to={"#"}
                        onClick={(e) => tooltipTimer(e)}
                        className={
                          "flex items-center font-bold ml-1 sm:ml-2 waiting_counter text-gray-200" +
                          tooltipActiveClass
                        }
                        data-timer={
                          "Wait for the counter to reach 0 in order to resend code."
                        }
                      >
                        Resend Code
                        <ArrowNarrowRightIcon className="h-2 w-2 ml-1" />
                      </Link>
                    )}
                  </div>

                  <Button
                    text="Verify"
                    size="lg"
                    fullwidth
                    onClick={verifyOTP}
                    disabled={Object.values(numbers).join("").length < 6}
                    style={{
                      opacity:
                        Object.values(numbers).join("").length < 6 ? 0.5 : 1,
                    }}
                  />
                </div>

                <div className="justify-center w-0.5 lg:h-96 lg:my-20 mx-10 hidden lg:block "></div>
              </div>
            </div>
            <img
              alt="rocket"
              src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
              className="h-22 w-40 absolute -right-8 top-32 z-10 hidden md:block"
            />
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Install"
        extraClass={"install_safle_chrome"}
        isOpen={modalIsOpen}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};

export default OTPVerificationRegistration;
