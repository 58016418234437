/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useApp } from "./../../../helpers/AppProvider";
import WalletAsset from "./WalletAsset/WalletAsset";
import WalletTitle from "./WalletTitle/WalletTitle";
import WalletDetails from "./WalletDetails/WalletDetails";
import ModalWalletDetails from "../dialogs/ModalWalletDetails/ModalWalletDetails";
import ModalWalletSafleID from "../dialogs/ModalWalletSafleID/ModalWalletSafleID";
import Storage from "../../../helpers/Storage";
// import ModalEditWalletLabel from "../dialogs/ModalEditWalletLabel/ModalEditWalletLabel";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";

import { EDIT_LABEL_WALLET_FLOW } from "../../../constants/ui";
import { formatMoney } from "../../../helpers/walletHelpers";

const WalletCardNew = ({ item, isSafleCard, index, cardHandler, userID }) => {
  const { appData, actions } = useApp();
  const [setActiveEditLabelModalNav] = useModalNavigation(
    EDIT_LABEL_WALLET_FLOW
  );
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();

  const [openedPopup, setOpenedPopup] = useState(null);
  const [walletAssetsCount, setWalletAssetsCount] = useState(0);
  const [walletNFTCount, setWalletNFTCount] = useState(0);
  const [connectedChains, setConnectedChains] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [renderBalances, setRenderBalances] = useState(false);
  const [walletTotalBalance, setWalletTotalBalance] = useState();
  const currency = actions.getActiveCurrencySymbol();
  const accountType = appData?.accounts?.find(
    (account) => account?.address === item?.address
  )?.type;
  const get_balances = async () => {
    //
    // const walletAvailable = appData.wallets.find(
    //   (e) => e.address === item.address && e.chain === item.chain
    // );
    const connChains =
      accountType === "evm"
        ? appData.portfolioData[item?.address]?.activeChains?.filter(
            (chain) => chain !== "bitcoin" && chain !== "avalanche"
          )?.length
        : appData.portfolioData[item?.address]?.activeChains?.length;
    if (appData?.portfolioData) {
      const coinCountInChain = appData.portfolioData[
        item?.address
      ]?.activeChains.map((chain) => {
        return parseFloat(appData.portfolioData[item?.address][chain]?.balance)
          ? appData.portfolioData[item?.address][chain]?.tokens?.length + 1
          : appData.portfolioData[item?.address][chain]?.tokens?.length;
      });
      const coinCount = coinCountInChain?.reduce((acc, obj) => {
        return acc + obj;
      }, 0);
      setWalletAssetsCount(coinCount);
      // const coinCount =
      //   appData.portfolioData[item?.address]?.length +
      //   (walletAvailable.balance > 0.0 ? 1 : 0);
      // setWalletAssetsCount(coinCount);

      const nftCountInChain = appData.portfolioData[
        item?.address
      ]?.activeChains.map((chain) => {
        return appData.portfolioData[item?.address][chain]?.nfts
          ? parseInt(appData.portfolioData[item?.address][chain]?.nfts?.length)
          : 0;
      });
      const nftCount =
        nftCountInChain && nftCountInChain.length > 0
          ? nftCountInChain.reduce((acc, obj) => {
              return acc + obj;
            }, 0)
          : [];

      setWalletNFTCount(nftCount);

      // setWalletNFTCount(appData.walletNFTs[walletAvailable.id]?.length);
      setConnectedChains(connChains);

      setRenderBalances(true);
    }
  };
  useEffect(() => {
    get_balances();
  }, [appData]);

  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };
  const onClickDetailsItem = (item) => {
    openPopup("modal_wallet_details");
  };
  const onClickWalletEditLabel = (item) => {
    setModalData({
      type: "change_wallet_label",
      address: item.address,
      chainName:
        item?.chain === 1
          ? "ethereum"
          : item?.chain === 137
          ? "polygon"
          : item?.chain === 5
          ? "bsc"
          : "",
    });
    setActiveEditLabelModalNav("modal_edit_wallet_label");
  };

  useEffect(() => {
    if (!appData?.priceData || !appData?.portfolioData) {
      return;
    }
    const AssetsInWallets = appData?.accounts
      // ?.filter((account) => account.type === "evm")
      ?.map((account) => {
        const AssetsInWallet = appData?.portfolioData[
          account?.address
        ]?.activeChains
          ?.filter((chain) => chain !== "avalanche")
          ?.map((chain) => {
            let AssetsInChainInWallet;
            if (chain !== "bitcoin" && account.type === "evm") {
              AssetsInChainInWallet = [
                ...appData?.portfolioData[account?.address][chain]?.tokens,
              ];
            }
            AssetsInChainInWallet =
              AssetsInChainInWallet?.length > 0
                ? AssetsInChainInWallet.map((obj) => {
                    return {
                      ...obj,
                      chain: chain,
                      value: parseFloat(
                        appData?.priceData?.filter(
                          (item) => item?.symbol === obj?.symbol
                        )[0]?.price *
                          (parseFloat(obj?.balance) /
                            Math.pow(10, obj?.decimals))
                      ),
                      // ?.toFixed(2),
                      // ?.replace(/\.?0+$/, ""),
                    };
                  })
                : [];

            AssetsInChainInWallet.push({
              token_address: null,
              symbol: appData?.portfolioData[account?.address][chain]?.symbol,
              name: appData?.portfolioData[account?.address][chain]
                ?.nativeCurrency,
              logo: appData?.portfolioData[account?.address][chain]?.imageURL,
              thumbnail: null,
              decimals: null,
              balance: appData?.portfolioData[account?.address][chain]?.balance,
              possible_spam: false,
              chain: chain,
              value: parseFloat(
                appData?.priceData?.filter(
                  (item) =>
                    item?.symbol ===
                    appData?.portfolioData[account?.address][chain]?.symbol
                )[0]?.price *
                  parseFloat(
                    appData?.portfolioData[account?.address][chain]?.balance
                  )
              ),
              // ?.toFixed(2),
              // ?.replace(/\.?0+$/, ""),
            });
            return AssetsInChainInWallet;
          });
        return AssetsInWallet?.reduce((acc, obj) => {
          return acc?.concat(obj);
        }, []);
      });

    let unfilteredAssets =
      AssetsInWallets?.length > 0
        ? AssetsInWallets?.reduce((acc, obj) => {
            return acc?.concat(obj);
          })
        : [];

    unfilteredAssets =
      unfilteredAssets?.length > 0
        ? Object.values(
            unfilteredAssets.reduce((acc, token) => {
              const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
              if (!acc[key]) acc[key] = { ...token };
              else {
                acc[key].balance = (
                  parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                ).toString();
                acc[key].value = (
                  parseFloat(acc[key]?.value) + parseFloat(token?.value)
                ).toString();
              }
              return acc;
            }, {})
          ).map((obj) => ({ ...obj }))
        : [];

    unfilteredAssets = unfilteredAssets.filter((item) => !item?.possible_spam);

    let totalBalance = 0;
    for (let i = 0; i < unfilteredAssets.length; i++) {
      totalBalance += isNaN(unfilteredAssets[i]?.value)
        ? 0.0
        : parseFloat(unfilteredAssets[i]?.value);
    }
    setWalletTotalBalance(totalBalance);
  }, [appData.priceData, appData.portfolioData]);

  return (
    <>
      <div
        className={
          "dashboard__overview_card flex flex-col" +
          (isSafleCard ? " is_safle_card divide-y" : " ") +
          " " +
          (item?.address === appData.activeWallet ? "active" : "")
        }
        onClick={(e) =>
          isSafleCard ? cardHandler(e, 0) : cardHandler(e, item.address)
        }
      >
        {isSafleCard && (
          <div className="flex-1 flex flex-col justify-center items-center">
            <div>
              <img
                src="./images/safle_logo_rounded.svg"
                alt="safle-logo"
                className="w-[30px] h-[30px] mx-auto"
              />
            </div>
            <div className="text-[14px] text-[#0D203E] pt-[8px]">
              {userID ? userID : Storage.load("user")?.safleID}
            </div>
          </div>
        )}
        {isSafleCard && (
          <div className="flex-1 flex flex-col justify-center items-center">
            <div className="text-[24px] text-[#0D203E]">
              {currency}{" "}
              {formatMoney(
                actions.activeCurrency(walletTotalBalance?.toFixed(2))
              )}
            </div>
            <div className="text-[12px] text-[#8690A0]">ASSETS worth</div>
          </div>
        )}
        {!isSafleCard && (
          <div
            className="text-[8px] text-[#0669F8] p-[8px] border-b-[1px] border-b-[#95a9c0] px-[10px]
          w-[100%] flex flex-end"
          >
            {connectedChains > 0 && (
              <div className="card_wallet-info-box number_chain border-[1px] border-[#95a9c0]">
                {/* <div className="card_wallet-info-box number_chain tooltip_to_show"> */}
                {/* <div className='tooltip_description'>Chains linked to this wallet</div> */}
                <div className="info-box">
                  <div className="info-box-count flex flex-row pl-[6px]">
                    <div className="count">{connectedChains}</div>
                  </div>
                  <div className="info-box-icon ">
                    <span className="icon-chain"></span>
                  </div>
                  <div className="info-box-count flex flex-row">
                    {appData?.portfolioData[item?.address]?.activeChains &&
                    appData?.portfolioData[item?.address]?.activeChains
                      ?.length > 0 ? (
                      <div className="flex flex-row items-center">
                        {accountType === "evm"
                          ? appData?.portfolioData[item?.address]?.activeChains
                              ?.filter(
                                (chain) =>
                                  chain !== "bitcoin" && chain !== "avalanche"
                              )
                              ?.map((chainName, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={
                                      chainName === "bitcoin"
                                        ? "/images/wallets/bitcoin-wallet.svg"
                                        : appData.chains.find((chain) =>
                                            chainName === "eth"
                                              ? chain.chain_name === "ethereum"
                                              : chain.chain_name === chainName
                                          )?.details?.imageURL
                                    }
                                    alt={chainName}
                                    className="h-[16px] ml-[5px]"
                                  />
                                );
                              })
                          : appData?.portfolioData[
                              item?.address
                            ]?.activeChains?.map((chainName, index) => {
                              return (
                                <img
                                  key={index}
                                  src={
                                    chainName === "bitcoin"
                                      ? "/images/wallets/bitcoin-wallet.svg"
                                      : appData.chains.find((chain) =>
                                          chainName === "eth"
                                            ? chain.chain_name === "ethereum"
                                            : chain.chain_name === chainName
                                        )?.details?.imageURL
                                  }
                                  alt={chainName}
                                  className="h-[16px] ml-[5px]"
                                />
                              );
                            })}
                      </div>
                    ) : null}
                    {/* <span className="text paddings">
                      {connectedChains > 1 ? " Chains" : " Chain"}
                    </span> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {!isSafleCard && (
          <div className="flex-grow flex flex-col justify-between p-[10px]">
            <WalletTitle
              accountType={accountType}
              walletName={
                typeof item?.label === "string"
                  ? item?.label
                  : item?.label?.polygon
              }
              walletAddress={item?.address}
              item={item}
              clickHandleLabel={() => onClickWalletEditLabel(item)}
              clickHandle={() => onClickDetailsItem(item)}
            />
            {/* {renderBalances && ( */}
            <WalletAsset item={item} isSafleCard={isSafleCard} />
            {/* )} */}
            {item?.type === "evm" && (
              <WalletDetails
                countAssets={walletAssetsCount}
                countNTFS={walletNFTCount}
                countChain={connectedChains}
                showChains={false}
              />
            )}
            {item?.type !== "evm" && <div className="mb-[20px]"></div>}
          </div>
        )}
      </div>
      {openedPopup === "modal_wallet_details" && (
        <ModalWalletDetails
          items={item}
          onClose={onClose}
          useModalData={false}
        />
      )}
      {openedPopup === "modal_wallet_safleid" && (
        <ModalWalletSafleID items={item} onClose={onClose} />
      )}
      {/* {openedPopup === 'modal_wallet_edit_label' &&
                // <ModalEditWalletLabel items={item} onClose={onClose} />
            } */}
    </>
  );
};

export default WalletCardNew;
