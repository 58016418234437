import { add } from "lodash";

const RecentActivityDetails = ({
  type,
  address,
  explorerLink,
  txType,
  swapDetails,
}) => {
  
  return (
    <>
      {type === "default" && (
        <div className="activity_asset_hidden_details_wrp">
          <div className="activity_asset_hidden_details_left">
            <div className="label">{txType === "send" ? "to" : "From"}</div>
            <div className="details">{address}</div>
          </div>
          <div className="activity_asset_hidden_details_right">
            <div className="label">&nbsp;</div>
            <div className="details">
              <a href={explorerLink} target="_blank" rel="noreferrer">
                Check on blockchain explorer{" "}
                <span className="icon-icon_eth_scan"></span>
              </a>
            </div>
          </div>
        </div>
      )}
       {type === "bitcoin" && (
        <div className="activity_asset_hidden_details_wrp">
          <div className="activity_asset_hidden_details_right">
            <div className="label">&nbsp;</div>
            <div className="details">
              <a href={explorerLink} target="_blank" rel="noreferrer">
                Check on blockchain explorer{" "}
                <span className="icon-icon_eth_scan"></span>
              </a>
            </div>
          </div>
        </div>
      )}
      {type === "partner" && (
        <div className="activity_asset_hidden_details_wrp">
          <div className="activity_asset_hidden_details_left">
            <div className="label">Trade ref ID</div>
            <div className="details">{address}</div>
          </div>
          <div className="activity_asset_hidden_details_right">
            <div className="label">Trade Partner</div>
            <div className="details">
              <img alt="Transak" src="images/transak_logo.svg" />
            </div>
          </div>
        </div>
      )}
      {type === "swap" && (
        <div className="activity_asset_hidden_details_wrp">
          <div className="activity_asset_hidden_details_left">
            <div className="label">Wallet Address</div>
            <div className="details">{swapDetails?.from}</div>
            <div className="mt-4 flex items-center label">
              <img src={swapDetails?.sent[0]?.logo_url} alt="" width={"10%"} />
              <span className="pl-3 font-semibold text-black">
                {swapDetails?.sent[0]?.value /
                  Math.pow(10, swapDetails?.sent[0]?.decimals)}{" "}
                {swapDetails?.sent[0]?.name}
              </span>
              <span className="px-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <img
                src={swapDetails?.received[0]?.logo_url}
                alt=""
                width={"10%"}
              />{" "}
              <span className="pl-3 font-semibold text-black">
                {swapDetails?.received[0]?.value /
                  Math.pow(10, swapDetails?.received[0]?.decimals)}{" "}
                {swapDetails?.received[0]?.name}
              </span>
            </div>
          </div>
          <div className="activity_asset_hidden_details_right">
            <div className="label">Swap Txn ID</div>
            <div className="details">{swapDetails?.id}</div>
          </div>
          <div className="activity_asset_hidden_details_left ml-4">
            <div className="label">Swap Partner</div>
            <span
              className="data"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <img src={`images/swap/${swapDetails?.dex}-logo.svg`} alt="" />
            </span>
            {explorerLink ? (
              <div className="details">
                <a href={explorerLink} target="_blank" rel="noreferrer">
                  Check on blockchain explorer{" "}
                  <span className="icon-icon_eth_scan"></span>
                </a>
              </div>
            ) : null}
            {/* <div className="details">{swapDetails?.dex}</div> */}
          </div>
        </div>
      )}
      {type === "contract-call" && (
        <div className="activity_asset_hidden_details_wrp">
          <div className="activity_asset_hidden_details_left">
            <div className="label">wallets</div>
            <div className="details">{address}</div>
          </div>
          <div className="activity_asset_hidden_details_right">
            <div className="label">&nbsp;</div>
            <div className="details">
              <a href={explorerLink} target="_blank" rel="noreferrer">
                Check on blockchain explorer{" "}
                <span className="icon-icon_eth_scan"></span>
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RecentActivityDetails;
