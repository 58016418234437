import React, { useState, useEffect, useRef } from "react";

const WalletSearchBar = ({
  assetSearchHandler,
  filter,
  setFilter,
  filterEmpty,
}) => {
  const [isEmpty, seIsEmpty] = useState(false);
  const clearValue = (event) => {
    filterEmpty && filterEmpty(event);
    assetSearchHandler("");
    seIsEmpty(false);
  };
  const inputValue = (e) => {
    e.preventDefault();
    setFilter && setFilter(e.target.value);

    if (e.target.value !== "") {
      seIsEmpty(true);
    } else {
      seIsEmpty(false);
    }

    assetSearchHandler(e.target.value);
  };
  return (
    <div className="form__input form__input-search relative justify-end flex ">
      <input
        onChange={inputValue}
        type="text"
        className=" form-input-default cursor-pointer bg-transparent pl-12 outline-none focus:w-96	 focus:cursor-text    pt-4 pb-4"
        placeholder="Search"
        value={filter}
      />
      <span className="icon-search icon-search-black-24-dp pt-1"></span>
      {isEmpty && (
        <span
          className="icon-close absolute top-5 right-1"
          onClick={clearValue}
        ></span>
      )}
    </div>
  );
};

export default WalletSearchBar;
