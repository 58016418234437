import { useEffect, useRef } from "react";

class CropperLib {
  pw = 0;
  ctx = null;
  src = null;
  img = null;
  ox = 0;
  oy = 0;
  dragging = false;
  state = {
    width: 0,
    height: 0,
    w: 0,
    h: 0,
    scale: 1,
    x: 0,
    y: 0,
    orgx: 0,
    orgy: 0,
    oldw: 0,
    oldh: 0,
    initial: true,
  };

  constructor(canv, pw = 100, x = 0, y = 0) {
    this.pw = pw;
    this.state.x = x;
    this.state.y = y;
    this.canv = canv;
    this.ctx = this.canv.getContext("2d");
    this.whaction = (e) => this.mwheel(e);
    this.mda = (e) => this.md(e);
    this.mua = (e) => this.mu(e);
    this.mma = (e) => this.mm(e);
    this.canv.addEventListener("wheel", this.whaction);
    this.canv.addEventListener("mousedown", this.mda);
    window.addEventListener("mouseup", this.mua);
    this.canv.addEventListener("mousemove", this.mma);

    this.canv.addEventListener("touchstart", this.mda);
    window.addEventListener("touchend", this.mua);
    this.canv.addEventListener("touchmove", this.mma);
  }
  destroy() {
    this.canv.removeEventListener("wheel", this.whaction);
    this.canv.removeEventListener("mousedown", this.mda);
    window.removeEventListener("mouseup", this.mua);
    this.canv.removeEventListener("mousemove", this.mma);
  }
  mwheel(e) {
    e.preventDefault();
    this.state.scale = Math.min(
      20,
      Math.max(
        1,
        parseFloat(this.state.scale) + parseFloat(e.wheelDelta) * 0.001
      )
    );
    window.dispatchEvent(
      new CustomEvent("crop:zoom", { detail: this.state.scale })
    );
    this.draw();
  }
  getEvent(e) {
    return e.type.indexOf("touch") !== -1 ? e.changedTouches[0] : e;
  }
  md(e) {
    e.preventDefault();
    const ev = this.getEvent(e);

    this.ox = ev.clientX; // - e.offsetX;
    this.oy = ev.clientY; // - e.offsetY;
    this.state.orgx = this.state.x;
    this.state.orgy = this.state.y;

    this.dragging = true;
  }
  mu(e) {
    e.preventDefault();
    this.ox = 0;
    this.oy = 0;
    this.dragging = false;
  }
  mm(e) {
    e.preventDefault();
    if (this.dragging) {
      const ev = this.getEvent(e);

      const cx = parseInt(ev.clientX) - parseInt(this.ox);
      const cy = parseInt(ev.clientY) - parseInt(this.oy);

      this.state.x = this.state.orgx - cx;
      this.state.y = this.state.orgy - cy;
      window.dispatchEvent(
        new CustomEvent("crop:move", {
          detail: { x: this.state.x, y: this.state.y },
        })
      );

      this.draw();
    }
  }
  setImage(src) {
    this.src = src;
    this.img = new Image();
    this.img.addEventListener("load", () => {
      this.state.x = 0;
      this.state.y = 0;
      this.state.width = this.img.width;
      this.state.height = this.img.height;
      this.state.initial = true;
      this.smartFit();
      this.draw();
    });
    this.img.src = this.src;
  }
  smartFit() {
    if (this.state.width > this.state.height) {
      this.state.h = this.pw;
      this.state.w = this.pw * (this.state.width / this.state.height);
      this.state.x =
        this.state.x !== 0 ? this.state.x : -(this.state.w - this.pw) / 2;
    } else {
      this.state.w = this.pw;
      this.state.h = this.pw * (this.state.height / this.state.width);
      this.state.y =
        this.state.y !== 0 ? this.state.y : -(this.state.h - this.pw) / 2;
    }
    window.dispatchEvent(
      new CustomEvent("crop:move", {
        detail: { x: this.state.x, y: this.state.y },
      })
    );
  }
  setScale(s) {
    this.state.scale = s; // Math.min( 20, Math.max( 1, s ) );
    // window.dispatchEvent( new CustomEvent('crop:zoom', { detail: this.state.scale }) );
    this.draw();
  }
  draw() {
    this.ctx.clearRect(0, 0, this.pw, this.pw);
    const iw = this.state.w * this.state.scale;
    const ih = this.state.h * this.state.scale;
    let px = this.state.initial
      ? this.state.x
      : this.getPointAt(this.pw / 2, this.state.x, iw);
    let py = this.state.initial
      ? this.state.y
      : this.getPointAt(this.pw / 2, this.state.y, ih);
    this.state.initial = false;
    px = this.bound(px, iw, this.pw);
    py = this.bound(py, ih, this.pw);

    this.state.oldw = iw;
    this.state.oldh = ih;
    this.ctx.drawImage(
      this.img,
      0,
      0,
      this.state.width,
      this.state.height,
      px,
      py,
      iw,
      ih
    );
  }
  getPointAt(p, org, size) {
    // return -(org+p);
    return -(size / 2 - p + org);
  }
  bound(x, idim, prev_dim) {
    if (x < -(idim - prev_dim)) {
      x = -(idim - prev_dim);
    }
    if (x > 0) {
      x = 0;
    }
    return x;
  }
}
let cropper = null;
const Cropper = ({
  src,
  setCanvasRef,
  onScaleHandler = null,
  onMoveHandler = null,
  scale = 1,
  pos = { x: 0, y: 0 },
}) => {
  const canvasRef = useRef();
  const PW = 280;

  const onScale = (e) => {
    onScaleHandler && onScaleHandler(e.detail);
  };
  const onMove = (e) => {
    onMoveHandler && onMoveHandler(e.detail);
  };

  useEffect(() => {
    cropper = new CropperLib(canvasRef.current, PW, pos.x, pos.y);
    // cropper.setImage( src );

    setCanvasRef && setCanvasRef(canvasRef.current);

    window.addEventListener("crop:zoom", onScale);
    window.addEventListener("crop:move", onMove);

    return () => {
      window.removeEventListener("crop:zoom", onScale);
      window.removeEventListener("crop:move", onMove);
      cropper.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    cropper && cropper.setImage(src);
  }, [src]);

  useEffect(() => {
    if (!isNaN(scale)) {
      if (cropper && cropper.state.scale !== scale) {
        cropper.setScale(scale);
      }
    }
  }, [scale]);

  return (
    <div
      style={{
        width: PW,
        height: PW,
        backgroundColor: "#000",
        overflow: "hidden",
        borderRadius: 6,
      }}
    >
      <canvas
        ref={canvasRef}
        width={PW}
        height={PW}
        style={{ width: PW + "px", height: PW + "px" }}
      />
    </div>
  );
};
export default Cropper;
