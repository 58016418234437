/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect }  from 'react';
import ModalProfileUpdate from '../../dialogs/ModalProfileUpdate';
import ModalCancelProcess from '../../dialogs/ModalCancelProcess.js';
import ModalActionConfirm from '../../dialogs/ModalActionConfirm';
import ModalPinConfirm from '../../dialogs/ModalPinConfirm';

import { useUser } from '../../../helpers/UserProvider';
import { useApp } from '../../../helpers/AppProvider';
import useModalNavigation, { useModalData } from '../../../helpers/useModalNavigation';
import { SETTINGS_CHANGE_PROFILE_IMAGE, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ChangeProfileImageProcess = () => {
    const { userData, userActions } = useUser();
    const { appData: { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}, wallets = []}, actions } = useApp() || {};
    const [ setActiveModalNav, goForward , goBackward ] = useModalNavigation(SETTINGS_CHANGE_PROFILE_IMAGE);
    const [ modalData, setModalData ] = useModalData();
        
    const onClose = ( popup ) => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelProfilePopup = (popup) => {
        setActiveModalNav( 'settings_change_profile_cancel', true);
    };

    const callbackProfileUpdated = () => {
        setActiveModalNav('CLOSED');
    }

    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return (
        <>
            {
                // modal order:
                // ModalProfileUpdate
            }
            {activeModalLabel === 'settings_change_profile_upload' &&
                <ModalProfileUpdate 
                    dataClass="modal_profile_update0" modalState={0}
                    onClose={callbackCancelProfilePopup} 
                    callbackProfileUpdated={callbackProfileUpdated}
                />
            }
            {activeModalLabel === 'settings_change_profile_cancel' && 
            //     <ModalCancelProcess 
            //     onClose={onClose}  
            //     goBackward={goBackward}
            //     body="Are you sure do you want to cancel updating your profile image?"
            // />
                <ModalActionConfirm 
                title="Are you sure you want to cancel updating your profile image?" 
                desc="" 
                dataClass="modal_action_confirm1 " 
                onClose={onClose} 
                goBackward={goBackward}
                iconErr={'images/wallets/cancel_confirm.svg'}
                /> 
            }     
        </>
    );
}

export default ChangeProfileImageProcess;