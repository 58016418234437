import React, { useState } from "react";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PinInput from "../common/PinInput/PinInput";

const ModalChangeID2 = (props) => {
  const [disabledButton, setDisabledButton] = useState(true);

  return (
    <MainModal
      // on close - display conformation first
      onClose={() =>
        props.callbackCancelGeneratePopup("modal_generate_cancel_wallet")
      }
      extraClasses={[
        "modal_w400",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_enter_pin",
        "modal_wallet--1",
      ]}
      dataClass="modal_change_pin"
    >
      <div className="modal_succes_content">
        <ModalHeadIcon img={"images/icon-pin.svg"} />
        {props.customTitle ? (
          <div className="modal_succes_head modal_enter_pin_title">
            {props.customTitle}
          </div>
        ) : (
          <div className="modal_succes_head modal_enter_pin_title">
            Enter PIN to proceed
            <br /> this transaction
          </div>
        )}
        {/* <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered">Lorem ipsum dolor sit amet, consectetur<br /> adipiscing elit. Phasellus id cursus</span>
        </div> */}
        <div className="modal_pin_pad-wrp modal_pin_margin">
          <PinInput
            onCompletePIN={props?.onCompletePIN}
            disabled={(e) => setDisabledButton(e)}
          />
        </div>
        <div className="validation">&nbsp; {props.validation || ""} &nbsp;</div>
        <div className="modal_footer">
          <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
            <button
              className={"btn-default btn-white-bordered close_modal_cancel"}
              onClick={() =>
                props.callbackCancelGeneratePopup(
                  "modal_generate_cancel_wallet"
                )
              }
            >
              Cancel
            </button>
            <button
              className={"btn-default btn-blue modal__trigger"}
              onClick={() => props.submitAction()}
              disabled={disabledButton}
            >
              Confirm
            </button>
            {/* <PrimaryBtn label={'Cancel'} classes={'btn-default btn-white-bordered close_modal_cancel'} />
            <PrimaryBtn label={'Confirm'} classes={'btn-default btn-blue modal__trigger'} /> */}
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalChangeID2;
