import { useEffect, useState } from "react";
import Storage from "./Storage";
import { useApp } from "./AppProvider";

import { DEFAULT_MODAL_STATUS, ACTIVE_MODAL_KEY } from "../constants/ui";
import { configModalFlows } from "../constants/modalFlows";

const MODAL_DATA_KEY = "modalData";

export default function useModalNavigation(flow = null) {
  const { appData, actions } = useApp();
  const modalFlow = configModalFlows[flow];

  useEffect(() => {
    if (!appData?.activeModal) {
      const activeModalKey =
        Storage.load(ACTIVE_MODAL_KEY) || DEFAULT_MODAL_STATUS;
      actions.setAppData({ activeModal: modalFlow[activeModalKey] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goForward = () => {
    const nextStepKey = appData?.activeModal?.next;
    if (nextStepKey) {
      setActiveModalNav(nextStepKey);
    } else {
      Storage.remove(MODAL_DATA_KEY);
    }
  };

  const goBackward = () => {
    const prevStepKey = Storage.load(ACTIVE_MODAL_KEY);
    if (prevStepKey) {
      setActiveModalNav(prevStepKey);
    } else {
    }
  };

  const setActiveModalNav = (modalKey = false, confirmAction = false) => {
    const checkDefault =
      modalKey !== false ? modalKey : Storage.load(ACTIVE_MODAL_KEY);
    const nextModal =
      checkDefault && modalFlow && modalFlow.hasOwnProperty(modalKey)
        ? modalFlow[modalKey]
        : false;

    if (nextModal) {
      actions.setAppData({ activeModal: nextModal });

      //if closing, erase all temp storage
      if (modalKey === DEFAULT_MODAL_STATUS) {
        Storage.remove(MODAL_DATA_KEY);
      }

      // Don't save Cancel confirmation popups for backward/forward steps
      if (!confirmAction) {
        Storage.save(ACTIVE_MODAL_KEY, modalKey);
      }
    }
  };

  return [setActiveModalNav, goForward, goBackward];
}

export function useModalData() {
  const data = Storage.load(MODAL_DATA_KEY) || {};
  const [modalData, setModalData] = useState({ ...data });

  const setData = (changes) => {
    setModalData({ ...data, ...changes });
    Storage.save(MODAL_DATA_KEY, { ...data, ...changes });
  };
  const reloadModalData = () => {
    const data = Storage.load(MODAL_DATA_KEY) || {};
    setModalData((prev) => {
      return { ...prev, ...data };
    });
  };

  return [modalData, setData, reloadModalData];
}
