export const walletTypes = [
  {
    label: "EVM wallet",
    code: "EVM",
    icon: "images/wallets/icon_EVM.svg",
    wallets: "Ethereum, Polygon, BSC, Arbitrum, Optimism, Velas, Mantle",
    network: "ethereum",
  },
  {
    label: "Bitcoin wallet",
    code: "BTC",
    icon: "images/wallets/currency-bitcoin.svg",
    wallets: "BTC",
    network: "bitcoin",
    // disabled: true,
  },
];

export const encKey = {
  0: 163,
  1: 168,
  2: 234,
  3: 214,
  4: 142,
  5: 78,
  6: 188,
  7: 37,
  8: 235,
  9: 240,
  10: 229,
  11: 193,
  12: 33,
  13: 170,
  14: 99,
  15: 153,
  16: 131,
  17: 56,
  18: 204,
  19: 245,
  20: 254,
  21: 5,
  22: 17,
  23: 90,
  24: 12,
  25: 186,
  26: 182,
  27: 22,
  28: 2,
  29: 179,
  30: 123,
  31: 73,
  32: 247,
  33: 232,
  34: 100,
  35: 97,
  36: 169,
  37: 155,
  38: 144,
  39: 227,
  40: 92,
  41: 123,
  42: 238,
  43: 62,
  44: 126,
  45: 49,
  46: 73,
  47: 4,
  48: 32,
  49: 58,
  50: 165,
  51: 247,
  52: 205,
  53: 124,
  54: 200,
  55: 110,
  56: 152,
  57: 172,
  58: 7,
  59: 167,
  60: 183,
  61: 82,
  62: 254,
  63: 31,
};
