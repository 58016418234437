/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";

import WalletsListItem from "../WalletsListItem/WalletsListItem";
import WalletListItemLoader from "../WalletsListItem/WalletsListItemLoader";
import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  EXPORT_WALLET_PRIVATE_KEY_FLOW,
  QR_CODE_FLOW,
  EDIT_LABEL_WALLET_FLOW,
} from "../../../constants/ui";
import { checkWalletLinkedToSafleID } from "../../../helpers/walletHelpers";

let tm = false;

const WalletsList = ({ walletSearchKeyword }) => {
  const [setActiveModalNav] = useModalNavigation(
    EXPORT_WALLET_PRIVATE_KEY_FLOW
  );
  const [setActiveEditLabelModalNav] = useModalNavigation(
    EDIT_LABEL_WALLET_FLOW
  );
  const [setActiveQRModalNav] = useModalNavigation(QR_CODE_FLOW);

  // const { userData } = useUser();
  const { appData, actions } = useApp();
  const { userData } = useUser();

  const [showWallets, setShowWallets] = useState(false);
  const [walletCount, setWalletCount] = useState(0);
  const [walletCards, setWalletCards] = useState([]);
  const [walletLinkedToSafleId, setWalletLinkedToSafleId] = useState();
  // eslint-disable-next-line no-unused-vars
  const [walletCardsCopy, setWalletCardsCopy] = useState([]);

  useEffect(() => {
    async function checkSafleId() {
      if (userData?.user?.safleID) {
        const resp = await checkWalletLinkedToSafleID(userData?.user?.safleID);
        // if (!resp || resp === "This SafleID is not registered.") {
        //   if (w3.utils.isAddress(toAddress)) {
        //     setIsValidAddress(true);
        //   } else {
        //     setIsValidAddress(false);
        //   }
        //   setUpdatedAddress("");
        // } else {
        setWalletLinkedToSafleId(resp?.toLowerCase());
        // }
      }
    }
    checkSafleId();
  }, [userData?.user?.safleID]);

  // useEffect(() => {
  //   if (appData?.regStatus === "complete" && appData?.accounts?.length > 0)
  //     // actions.setLoading(true, "Loading Vault");
  //     load_vault();
  // }, [appData.regStatus, appData?.accounts, appData.portfolioData]);

  const openEnterPinModal = () => setActiveModalNav("export_prvkey_enter_pin");
  const openEditLabelModal = () =>
    setActiveEditLabelModalNav("modal_edit_wallet_label");
  const openQRModal = () => setActiveQRModalNav("modal_qr_code");

  // const load_vault = async () => {
  //   // actions.setLoading(true, "Loading");
  //   // await Promise.all([
  //   //   // actions.loadChains(),
  //   //   // actions.loadSupportedAssets(),
  //   //   // actions.loadSupportedCurrencies(),
  //   //   // actions.loadActiveCurrency(userData.user.token),
  //   //   // actions.loadUsdRate(),
  //   //   // userActions.loadVault(),
  //   // ]);
  //   // await delay_code(400);
  //   const acc = await appData?.accounts;
  //   const addreses = acc ? Object.values(acc) : {};

  //   const wallet_changes = await actions.loadWalletCardData(addreses);
  //   actions.setAppData(wallet_changes);

  //   // actions.setLoading(false);
  // };

  // useEffect(() => {
  //   if (appData.wallets && appData.wallets.length && !showWallets) {
  //     tm && clearTimeout(tm);
  //     tm = setTimeout(() => {
  //       setShowWallets(true);
  //       setWalletCount(appData.wallets.length);
  //       actions.setLoading(false);
  //     }, 500);
  //   }
  //   if (appData.wallets && appData.wallets.length !== walletCount) {
  //     // setShowWallets(false);
  //     setWalletCount(false);
  //     actions.setLoading(false);
  //   }
  // }, [appData.wallets]);

  // useEffect(() => {
  //   if (appData.wallets && appData.wallets.length) {
  //     let filteredWallets = appData.wallets.filter((e) => {
  //       if (appData.activeChain && appData.activeChain !== 0) {
  //         const curr = appData.chains.filter(
  //           (chain) => chain?.chainId === appData?.activeChain
  //         )[0];
  //         return e.chain === curr?.chainId;
  //       }
  //       return e;
  //     });
  //     //filteredWallets = filteredWallets?.filter((i) => i.chain === 1);
  //     setWalletCards(filteredWallets);
  //     setWalletCardsCopy(filteredWallets);
  //   }
  // }, [appData.wallets, showWallets, appData.activeChain]);

  return (
    <div className="assets__overview_content_tab content_wallets active">
      <div className="activity__asset-filter-wrapper">
        <div className="filter_table_body wallets_items_ctn ">
          {/* filter method is used for search functionality for wallets*/}
          {
            // showWallets === true &&
            appData?.accounts?.length > 0 &&
              appData?.accounts
                ?.filter((item) => {
                  return walletSearchKeyword === ""
                    ? item
                    : typeof item.label === "string"
                    ? item.label
                        ?.toLowerCase()
                        ?.includes(walletSearchKeyword.toLowerCase()) ||
                      item?.address?.includes(walletSearchKeyword)
                    : item.label?.polygon
                        ?.toLowerCase()
                        ?.includes(walletSearchKeyword.toLowerCase()) ||
                      item?.address?.includes(walletSearchKeyword);
                })
                .map((item, index) => {
                  return (
                    <WalletsListItem
                      key={index.toString()}
                      item={item}
                      openEnterPinModal={openEnterPinModal}
                      openEditLabelModal={openEditLabelModal}
                      openQRModal={openQRModal}
                      walletLinkedToSafleId={walletLinkedToSafleId}
                      firstBTCWallet={
                        appData?.accounts.filter(
                          (account) => account.type === "bitcoin"
                        )[0]?.address
                      }
                      // openRemoveModal={openRemoveModal}
                      // logo={actions.getChainLogo(item.chain)}
                      // name={item.label}
                      // prefix={actions.getNativeToken(item.chain)}
                      // fiateq={''}
                      // percent={''}
                    />
                  );
                })
          }
          {/* {!showWallets &&
            new Array(9)
              .fill(1)
              .map((i, idx) => <WalletListItemLoader key={idx} idx={idx} />)} */}
        </div>
      </div>
    </div>
  );
};

export default WalletsList;
