// import { useEffect, useState } from "react";
import { EXPORT_WALLET_PRIVATE_KEY_FLOW } from "../../constants/ui";
// import { useApp } from "../../helpers/AppProvider";
// import { delay_code } from "../../helpers/generalHelper";
import useModalNavigation from "../../helpers/useModalNavigation";
// import { useUser } from "../../helpers/UserProvider";

import VaultActiveWalletList from "./VaultActiveWalletsList";
import VaultListHeader from "./VaultListHeader";
// let tm = false;

const VaultActiveWallet = ({ tab, data }) => {
  const [setActiveModalNav] = useModalNavigation(
    EXPORT_WALLET_PRIVATE_KEY_FLOW
  );

  const openEnterPinModal = () => {
    setActiveModalNav("export_prvkey_enter_pin");
  };

  return (
    <>
      <div className="assets__overview_content_tab content_wallets active">
        <div className="activity__asset-filter-wrapper">
          <div className="filter_table_body wallets_items_ctn vault_active_wallet">
            <table width={"100%"}>
              <VaultListHeader tab={tab} />
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <VaultActiveWalletList
                      key={index.toString()}
                      item={item}
                      index={index}
                      openEnterPinModal={openEnterPinModal}
                      // openEditLabelModal={openEditLabelModal}
                      // openQRModal={openQRModal}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VaultActiveWallet;
