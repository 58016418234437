import cryptoRandomString from "get-random-values";
import crypto from "crypto-browserify";
import aes from "aes-js";
import APIS from "../helpers/apis";
import jwt from "jsonwebtoken";

export async function getSafleName(token) {
  const decoded = await jwt.decode(token);
  return decoded;
}

export function generateRandomNumber() {
  let firstNumber = Math.floor(Math.random() * 11 + 1);
  let secondNumber = Math.floor(Math.random() * 11 + 1);

  while (secondNumber === firstNumber) {
    secondNumber = Math.floor(Math.random() * 11 + 1);
  }

  return { response: { firstNumber, secondNumber } };
}

// Method to generate encryption key
export async function generateEncryptionKey() {
  const bytes = new Uint8Array(64);
  const encryptionKey = cryptoRandomString(bytes);
  return encryptionKey;
}

// Method to generate pdkey
export async function generatePDKey({ safleID, password }) {
  const passwordDerivedKey = crypto.pbkdf2Sync(
    password,
    safleID,
    10000,
    32,
    "sha512"
  );

  const passwordDerivedKeyHash = crypto.createHash("sha256");
  passwordDerivedKeyHash.update(passwordDerivedKey, "utf8");

  const passwordDerivedKeyHashHex = passwordDerivedKeyHash.digest(); //Buffer.from( passwordDerivedKeyHash.digest() );
  return Promise.resolve(passwordDerivedKeyHashHex);
}

export async function generatePDKey2({ safleID, password }) {
  const passwordDerivedKey = crypto.pbkdf2Sync(
    password,
    safleID,
    10000,
    32,
    "sha512"
  );

  const passwordDerivedKeyHash = crypto.createHash("sha512");
  passwordDerivedKeyHash.update(passwordDerivedKey, "utf8");

  const passwordDerivedKeyHashHex = passwordDerivedKeyHash.digest(); //Buffer.from( passwordDerivedKeyHash.digest() );
  return Promise.resolve(passwordDerivedKeyHashHex);
}

// Method to encrpty encryption key
export async function encryptEncryptionKey({
  passwordDerivedKey,
  encryptionKey,
}) {
  const aesCBC = new aes.ModeOfOperation.cbc(Buffer.from(passwordDerivedKey));
  const encryptedEncryptionKey = aesCBC.encrypt(Object.values(encryptionKey));
  return encryptedEncryptionKey;
}

// Method to create pdkey hash
export async function createPDKeyHash({ passwordDerivedKey }) {
  const passwordDerivedKeyHash = crypto.createHash("sha512");
  passwordDerivedKeyHash.update(passwordDerivedKey);
  const passwordDerivedKeyHashHex = passwordDerivedKeyHash.digest("hex");
  return passwordDerivedKeyHashHex;
}

// Method to generate hashed password
export async function hashPassword({ password, passwordDerivedKey }) {
  const passwordHash = crypto.pbkdf2Sync(
    passwordDerivedKey,
    password,
    1,
    32,
    "sha512"
  );
  const passwordHashHex = passwordHash.toString("hex");
  return passwordHashHex;
}

// retrieve cloud functions
export const getCloudToken = async (user, pass, gtoken) => {
  try {
    const derivedKey = await generatePDKey({ safleID: user, password: pass });
    const PDKeyHash = await createPDKeyHash({ passwordDerivedKey: derivedKey });

    const jwtToken = await fetch(APIS.login, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        userName: user,
        password: PDKeyHash,
        "g-recaptcha-response": gtoken.toString(),
      }),
    }).then((resp) => resp.json());

    if (jwtToken.statusCode === 201) {
      return jwtToken.data.token;
    } else {
      Promise.reject(new Error(jwtToken.info[0].message));
    }
  } catch (e) {
    Promise.reject(new Error(e.message));
  }
};
export const retrieveVaultFromCloud = async (PDKeyHash, authToken) => {
  try {
    const req = await fetch(APIS.retrieve_vault, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      method: "POST",
      body: JSON.stringify({ PDKeyHash }),
    })
      .then((resp) => resp.json())
      .catch((e) => {
        return Promise.reject(new Error(e.message));
      });
    return req.data?.data?.vault;
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};

export const updateVaultOnCloud = async (PDKeyHash, authToken, vault) => {
  try {
    const req = await fetch(APIS.update_vault, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      method: "PATCH",
      body: JSON.stringify({ PDKeyHash, vault }),
    })
      .then((resp) => resp.json())
      .catch((e) => {
        return Promise.reject(new Error(e.message));
      });
    return req;
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};

export const retrieveEncryptionKey = async (PDKeyHash, authToken) => {
  try {
    const req = await fetch(APIS.retrieve_encription_key, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      method: "POST",
      body: JSON.stringify({ PDKeyHash }),
    })
      .then((resp) => resp.json())
      .catch((e) => {
        return Promise.reject(new Error(e.message));
      });

    return req.data?.encryptedEncryptionKey;
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};

export const decryptEncryptionKey = (
  safleID,
  password,
  encryptedEncryptionKey,
  ret = "array"
) => {
  const aes = require("aes-js");

  function generatePDKey({ safleID, password }) {
    const passwordDerivedKey = crypto.pbkdf2Sync(
      password,
      safleID,
      10000,
      32,
      "sha512"
    );
    const passwordDerivedKeyHash = crypto.createHash("sha256");
    passwordDerivedKeyHash.update(passwordDerivedKey, "utf8");
    const passwordDerivedKeyHashHex = Buffer.from(
      passwordDerivedKeyHash.digest()
    );
    return passwordDerivedKeyHashHex;
  }

  let passwordDerivedKey = generatePDKey({ safleID, password });
  const k = Array.from(passwordDerivedKey);

  const aesCBC = new aes.ModeOfOperation.cbc(k);
  const decriptedKey = aesCBC.decrypt(encryptedEncryptionKey);
  if (ret === "object") {
    return decriptedKey;
  }
  if (typeof decriptedKey === "object") {
    return Object.values(decriptedKey);
  }
  return decriptedKey;
};

export const middleEllipsis = (text, split = 3) => {
  if (!text) {
    return;
  }
  const sz = Math.floor(text?.length / split);
  return text?.slice(0, sz) + "..." + text?.slice(-sz);
};
export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};
export const setCookie = (cname, cvalue, exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const updateVaultLogs = async (type, tempPayload, authToken) => {
  try {
    const makingPayload = tempPayload.map((i) => {
      return {
        activity: i?.activity,
        timestamp: i?.timestamp,
        platform: "mobile",
        address: i?.address,
        storage: ["cloud"],
        action: i?.action,
      };
    });
    const payload = { type, logs: makingPayload };

    const res = await fetch(process.env.REACT_APP_LOGS_SERVICE_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      method: "PATCH",
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .catch((e) => {
        return Promise.reject(new Error(e.message));
      });
    return res;
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};

export const saveVaultLogs = async (tempPayload, authToken, address, chain) => {
  try {
    const makingPayload = tempPayload?.map((i) => {
      return {
        action: i?.action,
        timestamp: i?.timestamp?.toString(),
        platform: "web",
        address: i?.address || address,
        storage: "cloud",
        chain: chain,
      };
    });
    const payload = { logs: makingPayload };
    const res = await fetch(process.env.REACT_APP_LOGS_SERVICE_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .catch((e) => {
        return Promise.reject(new Error(e.message));
      });
    return res;
  } catch (e) {
    return Promise.reject(new Error(e.message));
  }
};
