import React, { useState, useEffect } from "react";
import AssetBadge from "../common/AssetBadge/AssetBadge";
import TokenIcon from "../common/TokenIcon/TokenIcon";
import MainModal from "./../generic/MainModal";
import { walletTypes } from "../../constants/wallets";

const ModalGenerateNewWallet = (props) => {
  const { walletLabel, walletType, setWalletType, onChangeWalletLabel } = props;
  const [tooltipActive, setTooltipActive] = useState(false);

  const handleChange = (event) => {
    setWalletType(event.target.value);
  };
  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    onChangeWalletLabel("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainModal
      title="Add Wallet"
      // on close - display conformation first
      onClose={() =>
        props.callbackCancelGeneratePopup("modal_generate_cancel_wallet")
      }
      extraClasses={["modal_w440 modal_mh400 modal_generate_new_wallet"]}
      dataClass="modal_generate_new_wallet"
    >
      <div className="modal_padding">
        <div className="form_inner">
          <label htmlFor="label">Set Wallet Label</label>
          <input
            name="label"
            type="text"
            placeholder="Wallet Label"
            onChange={(e) => onChangeWalletLabel(e.target.value)}
          />
        </div>
      </div>
      <div>
        <div className="modal_wallet_list_title">Type</div>
        {walletTypes.map((type) => (
          <div
            key={type.code}
            style={{ opacity: type.disabled ? "0.6" : 1 }}
            onClick={type.disabled ? () => {} : () => setWalletType(type.code)}
            className={
              walletType === type.code
                ? "modal_wallet_item input-checked"
                : "modal_wallet_item"
            }
          >
            <div className="asset__coin form__input_filter_item" data-name={""}>
              <TokenIcon logo={type.icon} />
              <div className="asset__coin_content">
                <div className="asset__coin_names">
                  <div className="asset__coin-name asset__coin-name--generate">
                    {type.label}
                  </div>
                  <div className="asset__coin-info">
                    <AssetBadge coinPrefix={type.code} />
                    {type.code === "EVM" && (
                      <div
                        className="tooltip"
                        onClick={() =>
                          tooltipActive
                            ? setTooltipActive(false)
                            : setTooltipActive(true)
                        }
                      >
                        <span className="ico icon-info-circle-solid" />
                        <span className="tooltiptext"
                          style={
                            tooltipActive
                              ? { visibility: "visible" }
                              : { visibility: "" }
                          }>
                          <span className="max-fee-title">EVM</span>
                          <div className="max-fee-text">
                            Ethereum Virtual Machine compatible
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="asset__coin-text">{type.wallets}</div>
              </div>
            </div>
            {!type.disabled && (
              <input
                className="modal_generate_new_wallet_checkbox"
                checked={walletType === type.code}
                onChange={handleChange}
                value={type.code}
                type="radio"
                name="radio"
              />
            )}
          </div>
        ))}
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={() =>
              props.callbackCancelGeneratePopup("modal_generate_cancel_wallet")
            }
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            disabled={walletLabel?.trim().length === 0 || !walletType}
            onClick={() =>
              props.callbackOpenPopup("modal_generate_add_wallet_popup")
            }
          >
            Confirm
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalGenerateNewWallet;
