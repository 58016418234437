// import TokenIcon from "../../../common/TokenIcon/TokenIcon";
import {
  unEvenEllipsis,
  // middleEllipsis,
} from "./../../../../helpers/generalHelper";
import React, { useState, useEffect } from "react";
// import { useApp } from "./../../../../helpers/AppProvider";


const WalletTitle = ({
  accountType,
  item,
  isSafleCard,
  clickHandle,
  clickHandleLabel,
  walletName,
}) => {
  // const { actions } = useApp();

  // const [networkName, setNetworkName] = useState();
  const [walletIcon, setWalletIcon] = useState("");

  //

  // useEffect(() => {
  //     setNetworkName( walletName );
  // }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const load_icon = async () => {
  
    if (accountType === "bitcoin") {
      setWalletIcon("./images/wallets/currency-bitcoin.svg");
    } else {
      setWalletIcon("./images/walletsIcon.svg");
      // const wicon = await actions.getChainLogo(item?.chain);
      //
      // setWalletIcon(wicon);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    load_icon();
  }, [item]);

  return (
    <div className="card_wallet-network">
      {isSafleCard === true && (
        <div className="card_wallet-network-image" onClick={item?.clickHandle}>
          <div
            className="card_wallet-network-image-icon"
            style={{
              backgroundImage: `url(${walletIcon})`,
            }}
          ></div>
        </div>
      )}
      {!isSafleCard === true && (
        <div className="card_wallet-network-image">
          <div
            className="card_wallet-network-image-icon"
            style={{
              backgroundImage: `url(${walletIcon})`,
            }}
          ></div>
        </div>
      )}

      {isSafleCard === true && (
        <div className="card_wallet-network-details" onClick={() => {}}>
          <div className="card_wallet-network-name">
            <span title={walletName}>{walletName}</span>
          </div>
        </div>
      )}
      {!isSafleCard === true && (
        <div className="card_wallet-network-details">
          <div className="card_wallet-network-name">
            <span title={walletName}>{walletName}</span>
            <div className="card_wallet-network-actions">
              <div
                className="edit"
                onClick={clickHandleLabel}
                data-button="true"
              >
                <span
                  className="icon-pen-solid"
                  style={{ pointerEvents: "none" }}
                ></span>
              </div>
              <div className="details" onClick={clickHandle} data-button="true">
                <div
                  className="details_relative"
                  style={{ pointerEvents: "none" }}
                >
                  <span className="icon_glass icon-scan-18"></span>
                  {/* <span className="icon_plus icon-plus-solid1"></span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card_wallet-network-address">
            <span> {unEvenEllipsis(item?.address)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletTitle;
