import React, { useEffect } from "react";

import ModalActionConfirm from "./../../dialogs/ModalActionConfirm";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
  RESTORE_WALLET_FLOW,
} from "../../../constants/ui";

const ModalCancelFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <ModalActionConfirm
      title="Cancel restore wallet?"
      desc=""
      dataClass="modal_action_failed1"
      onClose={onClose}
      goBackward={() => setActiveModalNav("modal_restore_enter_pin")}
      iconErr={"images/wallets/cancel_confirm.svg"}
    />
  );
};

export default ModalCancelFunctions;
