import React from 'react';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';
import PublicAddressGenerated from '../common/PublicAddressGenerated/PublicAddressGenerated';

const ModalImportSuccess = ({onClose, address}) => {

    return (
        <MainModal onClose={onClose}
            title=" " extraClasses={['modal_w440', 'modal_mh400 modal_dflex', 'modal_success_msg', 'modal_import_succes', 'modal_import_wallet_success']} dataClass="modal_import_success" >
            <div className="modal_succes_content">
                <div className="modal_succes_ico">
                    <img src="images/wallets/icon-done.svg" alt="" />
                </div>
                <div className="modal_succes_head">Your Wallet is <br /> Successfully Imported</div>
                <div className="modal_succes_desc">
                    <PublicAddressGenerated address={address} />
                </div>
            </div>
            <div className="modal_succes_content_actions">
                <div className="modal_footer">

                    <button
                        onClick={() => onClose()}
                        className={'btn-default btn-blue btn-br6 btn-mh46 btn-fw600 close_modal_ok'}
                    >
                        Done
                    </button>
                </div>
            </div>
        </MainModal>
    );
};

export default ModalImportSuccess;