/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import ModalSwapConfirmation from "../../dialogs/ModalSwapConfirmation";
import ModalPinConfirm from "../../swap/dialogs/ModalPinConfirm";
import ModalCancelProcess from "../../dialogs/ModalCancelProcess";
import ModalSwapTxnDone from "../../dialogs/ModalSwapTxnDone";
// import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import useModalNavigation from "../../../helpers/useModalNavigation"; // useModalData,
import { SWAP_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import ModalTransactionApproval from "../../dialogs/ModalTransactionApproval";
import Storage from "../../../helpers/Storage";
const Swap = require("@getsafle/safle-swaps-v2").Swaps;
const Web3 = require("web3");
const Vault = require("@getsafle/safle-vault");

const SwapProcess = () => {
  // const { userData, userActions } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      ...appData
    },
    actions,
  } = useApp() || {};
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goForward, goBackward] =
    useModalNavigation(SWAP_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
    window.location.reload();
  };

  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };

  const callbackCancelGeneratePopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    setActiveModalNav();
  }, []);

  async function handleClickPinApprove(pin) {
    // actions.setLoading(true, "Please wait");
    const activeChain = appData.chains
      ? appData.chains.filter(
          (chain) => chain?.chainId === appData?.activeChain
        )[0]
      : appData.chains[0];
    pin = Number(pin); // user pin
    // Initialise sawps SDK
    const swaps = new Swap({
      dex: appData.activeDex,
      rpcURL: (appData.chains
        ? appData.chains.filter(
            (chain) => chain?.chainId === appData?.activeChain
          )[0]
        : appData.chains[0]
      ).uri,
      chain: activeChain.chain_name,
    });
    // Get approvalRawTransaction swaps SDK

    const approval = await swaps.approvalRawTransaction({
      walletAddress: appData.activeAccountAddress,
      fromContractAddress: appData.activeTokenA,
      fromQuantity: parseInt(
        parseFloat(appData?.swapFromQuantity)?.toFixed(
          appData.fromTokenData[0].decimals
        ) * Math.pow(10, appData.fromTokenData[0].decimals)
      ).toString(),
    });

    if (approval & !approval?.nonce) {
      actions.setLoading(false);
      callbackCancelGeneratePopup("modal_swap_confirmation");
    } else {
      actions.setAppData({ approvalTxRes: approval });
      // actions.setAppData({ approvalRawTxRes: approval });
      const web3 = new Web3(new Web3.providers.HttpProvider(activeChain.uri));
      const vaultString = Storage.load("user").vault; //user vault
      const enckey = Object.values(Storage.load("user")?.decriptionKey);
      const vault = new Vault({ vault: vaultString, encryptionKey: enckey });
      // const enckey = userActions.getDecriptionKey(); // decrypted encryption key
      let tx = {
        ...approval,
        nonce: approval?.nonce,
        gasLimit: parseInt(approval?.gasLimit * 1.2),
      };

      tx.from = tx?.from?.toLowerCase();

      try {
        await vault.restoreKeyringState(
          vaultString,
          pin?.toString()?.padStart(6, "0"),
          enckey
        );
        // const { response, error } = await vault.getAccounts(
        //   Object.values(enckey)
        // );
        let publicAddress;
        // try {
        publicAddress = appData?.activeAccountAddress; //response[0].address;
        // } catch (e) {
        // throw new Error(`public address error ${e} & ${error}`);
        // }
        // const vault_new__netwrk = vault.changeNetwork(activeChain.chain_name);

        const signedTx = await vault.signTransaction(
          tx,
          // Object.values(enckey),
          pin?.toString()?.padStart(6, "0"),
          activeChain.uri
        );

        if (!signedTx.error) {
          // actions.setLoading(false);
          // callbackCancelGeneratePopup("modal_swap_confirmation");

          const fromTokenData =
            appData.supportedTokens &&
            appData.supportedTokens.filter(
              (token) => token.address === appData.activeTokenA
            );
          const toTokenData =
            appData.supportedTokens &&
            appData.supportedTokens.filter(
              (token) => token.address === appData.activeTokenB
            );
          let data = {
            fromToken: appData.activeTokenA,
            toToken: appData.activeTokenB,
            fromQty: appData?.swapFromQuantity.toString(),
            fromTokenSymbol: fromTokenData[0].symbol,
            fromTokenImage: fromTokenData[0].logoURI,
            toQty: appData?.swapToQuantity.toString(),
            toTokenSymbol: toTokenData[0].symbol,
            toTokenImage: toTokenData[0].logoURI,
            dex: appData.activeDex,
            slippage: appData.swapSlippage.toString(),
            network: activeChain?.chain_name,
          };

          const swapTransactions = appData.swapTransactions;

          try {
            let onGoingSwapTransactionId = null;
            const txHash = await web3.utils.sha3(signedTx.response);
            const swapPostResp = await actions.saveSwapTransaction({
              ...data,
              status: "pending",
              publicAddress: publicAddress,
              txHash: txHash,
            });

            if (swapPostResp.data) {
              onGoingSwapTransactionId = swapPostResp.data.transactionId;
            } else {
              throw new Error("Swap Api Error");
            }

            actions.setAppData({
              txResult: undefined,
              txId: txHash,
              swapTransactions: swapTransactions.concat([
                {
                  ...data,
                  transactionId: onGoingSwapTransactionId,
                  txHash: txHash,
                  status: "pending",
                },
              ]),
            });

            const sendTransaction = web3.eth
              .sendSignedTransaction(signedTx.response)

              .on("receipt", function (receipt) {
                // Transition to success screen
                actions.setLoading(false);
                callbackCancelGeneratePopup("modal_swap_confirmation");
                const transactionFee =
                  (receipt.gasUsed * receipt.effectiveGasPrice) / 10 ** 18;

                data = {
                  // ...data,
                  transactionId: onGoingSwapTransactionId,
                  // txHash: receipt.transactionHash,
                  status: receipt.status ? "success" : "failed",
                  // publicAddress: publicAddress,
                  linkToBlockExplorer:
                    activeChain.chainId === 137
                      ? `https://polygonscan.com/tx/${receipt.transactionHash}`
                      : `https://etherscan.io/tx/${receipt.transactionHash}`,
                  gasFee: receipt.gasUsed,
                  txReceipt: receipt,
                  gasFeeUsd: transactionFee,
                };

                actions.setAppData({ txResult: true, txFee: transactionFee });
                actions.saveSwapTransaction(data, "PATCH").then((res) => {
                  Storage.remove("transactionResult");
                  Storage.remove("pendingSwapHash");
                  getSwapHistory();
                });
                return receipt;
              });

            return sendTransaction;
            //
          } catch (error) {
            // result = error;
            actions.setLoading(false);
            callbackCancelGeneratePopup("modal_txn_done");

            actions.setAppData({ txResult: false, swapError: error.message });
            throw new Error(error);
          }
        } else {
          throw new Error(signedTx.error);
        }
      } catch (error) {
        callbackCancelGeneratePopup("modal_txn_done");

        actions.setAppData({ txResult: false, swapError: error.message });
      }

      actions.setLoading(false);
      callbackCancelGeneratePopup("modal_swap_confirmation");
    }
  }

  async function handleClickOnPin(pin) {
    pin = parseInt(pin);
    actions.setAppData({
      txResult: undefined,
      txId: undefined,
      txFee: undefined,
      swapError: undefined,
    });
    // actions.setLoading(true, "Please wait")
    const activeChain = appData.chains
      ? appData.chains.filter(
          (chain) => chain?.chainId === appData?.activeChain
        )[0]
      : appData.chains[0];

    const web3 = new Web3(new Web3.providers.HttpProvider(activeChain.uri));

    const vaultString = Storage.load("user").vault; //userData.user.vault; //user vault
    // // Initialise vault SDK with vault pin
    const vault = new Vault({
      vault: vaultString,
      encryptionKey: Storage.load("user")?.decriptionKey
        ? Object.values(Storage.load("user")?.decriptionKey)
        : Storage.load("registration")?.encryptionKey
        ? Object.values(Storage.load("registration")?.encryptionKey)
        : null,
    });

    const enckey = Object.values(Storage.load("user")?.decriptionKey); // decrypted encryption key
    // // Restore keyring state if state is not persisted in local storage

    let tx = {
      ...appData.approvalRawTxRes,
      nonce: appData.approvalRawTxRes.nonce,
      gasLimit: parseInt(appData.approvalRawTxRes.gasLimit),
    };

    tx.from = tx.from.toLowerCase();
    // delete tx.chainId

    try {
      await vault.restoreKeyringState(
        vaultString,
        pin?.toString()?.padStart(6, "0"),
        enckey
      );
      // const { response, error } = await vault.getAccounts(
      //   Object.values(enckey)
      // );

      let publicAddress = appData?.activeAccountAddress;
      try {
        // publicAddress = response[0].address;
      } catch (e) {
        // throw new Error(`public address error ${e} & ${error}`);
      }
      vault.changeNetwork(activeChain.chain_name);

      const signedTx = await vault.signTransaction(
        tx,
        // Object.values(enckey),
        pin?.toString()?.padStart(6, "0"),
        activeChain.uri
      );

      // let result;
      if (!signedTx.error) {
        actions.setLoading(false);
        callbackCancelGeneratePopup("modal_txn_done");

        const fromTokenData =
          appData.supportedTokens &&
          appData.supportedTokens.filter(
            (token) => token.address === appData.activeTokenA
          );
        const toTokenData =
          appData.supportedTokens &&
          appData.supportedTokens.filter(
            (token) => token.address === appData.activeTokenB
          );
        let data = {
          fromToken: appData.activeTokenA,
          toToken: appData.activeTokenB,
          fromQty: appData?.swapFromQuantity.toString(),
          fromTokenSymbol: fromTokenData[0].symbol,
          fromTokenImage: fromTokenData[0].logoURI,
          toQty: appData?.swapToQuantity.toString(),
          toTokenSymbol: toTokenData[0].symbol,
          toTokenImage: toTokenData[0].logoURI,
          dex: appData.activeDex,
          slippage: appData.swapSlippage.toString(),
          network: activeChain?.chain_name,
        };

        const swapTransactions = appData.swapTransactions;

        try {
          let onGoingSwapTransactionId = null;
          const txHash = await web3.utils.sha3(signedTx.response);
          const swapPostResp = await actions.saveSwapTransaction({
            ...data,
            status: "pending",
            publicAddress: publicAddress,
            txHash: txHash,
          });

          if (swapPostResp.data) {
            onGoingSwapTransactionId = swapPostResp.data.transactionId;
          } else {
            throw new Error("Swap Api Error");
          }

          actions.setAppData({
            txResult: undefined,
            txId: txHash,
            swapTransactions:
              swapTransactions?.length > 0
                ? swapTransactions?.concat([
                    {
                      ...data,
                      transactionId: onGoingSwapTransactionId,
                      txHash: txHash,
                      status: "pending",
                    },
                  ])
                : [
                    {
                      ...data,
                      transactionId: onGoingSwapTransactionId,
                      txHash: txHash,
                      status: "pending",
                    },
                  ],
          });

          const sendTransaction = web3.eth
            .sendSignedTransaction(signedTx.response)
            .on("error", (error) => {
              console.log(error);
              actions.setAppData({ txResult: false, swapError: error.message });
            })

            .on("receipt", function (receipt) {
              // Transition to success screen
              const transactionFee =
                (receipt.gasUsed * receipt.effectiveGasPrice) / 10 ** 18;

              data = {
                // ...data,
                transactionId: onGoingSwapTransactionId,
                // txHash: receipt.transactionHash,
                status: receipt.status ? "success" : "failed",
                // publicAddress: publicAddress,
                linkToBlockExplorer:
                  activeChain.chainId === 137
                    ? `https://polygonscan.com/tx/${receipt.transactionHash}`
                    : `https://etherscan.io/tx/${receipt.transactionHash}`,
                gasFee: receipt.gasUsed,
                txReceipt: receipt,
                gasFeeUsd: transactionFee,
              };

              actions.setAppData({ txResult: true, txFee: transactionFee });
              actions.saveSwapTransaction(data, "PATCH").then((res) => {
                Storage.remove("transactionResult");
                Storage.remove("pendingSwapHash");
                getSwapHistory();
              });
              return receipt;
            });

          return sendTransaction;
        } catch (error) {
          console.log(error, "error");
          // result = error;
          callbackCancelGeneratePopup("modal_txn_done");

          actions.setAppData({ txResult: false, swapError: error.message });
          throw new Error(error);
        }
      } else {
        throw new Error(signedTx.error);
      }
    } catch (error) {
      callbackCancelGeneratePopup("modal_txn_done");

      actions.setAppData({ txResult: false, swapError: error.message });
      actions.setLoading(false);
    }

    return;
  }

  const getSwapHistory = async () => {
    try {
      const token = Storage.load("user")?.token;
      actions.setLoading(true, "Loading");
      const swapTxnData = await actions.getSwapHistory(token);
      if (swapTxnData?.statusCode === 200) {
        // setData(swapTxnData?.data?.transactions);
        let data = swapTxnData?.data?.transactions;
        //   let pendingSwapHash = Storage.load("pendingSwapHash")
        //   if(pendingSwapHash){
        //     data.push({txHash:pendingSwapHash})
        //   }
        actions.setAppData({ swapTransactions: data });
      }
    } catch (err) {
    } finally {
      actions.setLoading(false);
    }
  };
  return (
    <>
      {activeModalLabel === "modal_swap_confirmation" && (
        <ModalSwapConfirmation
          onClose={onClose}
          callbackOpenPopup={callbackOpenPopup}
        />
      )}
      {activeModalLabel === "modal_transaction_approval" && (
        <ModalTransactionApproval
          onClose={onClose}
          callbackOpenPopup={callbackOpenPopup}
        />
      )}

      {activeModalLabel === "modal_txn_done" && (
        <ModalSwapTxnDone onClose={onClose} />
      )}

      {activeModalLabel === "modal_enter_pin_approval" && (
        <ModalPinConfirm
          onClose={onClose}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
          onHandleConfirm={handleClickPinApprove}
        />
      )}
      {activeModalLabel === "modal_enter_pin" && (
        <ModalPinConfirm
          onClose={onClose}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
          onHandleConfirm={handleClickOnPin}
        />
      )}

      {activeModalLabel === "modal_cancel_swap" && (
        <ModalCancelProcess
          onClose={onClose}
          goBackward={goBackward}
          body={`Cancel swap token?`}
        />
      )}
    </>
  );
};

export default SwapProcess;
