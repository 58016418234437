import React from "react";
import { convertIFPStoHttpUrl } from "../../../helpers/generalHelper";

const NFTImage = (props) => {
  return (
    <div
      className="nft__item-img"
      style={{
        backgroundImage: `url(${
          props.image !== "https://usdcgo.com/image.png"
            ? convertIFPStoHttpUrl(props.image)
            : ""
        })`,
        padding: 0,
        height: "80%",
      }}
    ></div>
  );
};

export default NFTImage;
