/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CopyAddress from "../common/CopyAddress/CopyAddress";
import DropdownNetworkNew from "../common/DropdownNetworkNew/DropdownNetworkNew";
import DropdownWalletNew from "../common/DropdownWalletNew/DropdownWalletNew";
import QRCode from "../common/QRCode/QRCode";
import TooltipCopy from "../registration/TooltipCopy";

import { useModalData } from "./../../helpers/useModalNavigation";
import MainModal from "./../generic/MainModal";
import { useApp } from "./../../helpers/AppProvider";
import QRCodeImage from "qrcode";
import { useUser } from "./../../helpers/UserProvider";

import { checkIfWalletLinked } from "../../helpers/walletHelpers";

const ModalAssetReceive = (props) => {
  const [sendChain, setSendChain] = useState("ethereum");
  const [qrImage, setQrImage] = useState("");
  const [userName, setUserName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();

  const { userData } = useUser();
  const [activeToken, setActiveToken] = useState({ name: "" });
  const [networks, setNetworks] = useState([]);

  // const items = props.items;
  const { appData, actions } = useApp();
  const [addr, setAddr] = useState(
    props.useModalData ? modalData?.address : props.items
  );
  const [showId, setShowId] = useState(false);
  useEffect(() => {
    async function displayName() {
      let res = await shouldUserNameDisplay();
      setShowId(res);
    }
    displayName();
  }, [addr]);
  useEffect(() => {
    if (!props.useModalData) {
      setAddr(props.items);
      getNetworks();
    }
  }, [props.items]);

  useEffect(() => {
    const symbol = appData.activeAsset;
    const assets = appData.assets;
    const keys = Object.keys(assets);
    const token = Object.values(assets)
      .map((e, idx) => {
        return { ...e, address: keys[idx] };
      })
      .find((e) => e.symbol === symbol);
    setActiveToken(token);
  }, [modalData, appData.assets, sendChain]);

  useEffect(() => {
    setUserName(userData.user.safleID);
  }, [userData.user]);

  const generateQrAddress = async () => {
    const durl = await QRCodeImage.toDataURL(
      `${modalData?.symbol === "BTC" ? "bitcoin" : sendChain}:${addr}`,
      {
        errorCorrectionLevel: "L",
        mode: "alphanumeric",
        margin: 0,
      }
    );
    // $el.find('.qr_area').html(`<img src="${durl}" alt="scan this qr code" />`);
    setQrImage(durl);
  };
  const onSelect = async (addr) => {
    //
    setAddr(addr.wallet_address);
  };
  useEffect(() => {
    generateQrAddress();
  }, [addr, sendChain]);

  const updateChain = (chain) => {
    const chosenChain = appData?.chains?.find(
      (e) => e?.name === chain?.network_name
    );
    if (sendChain !== chain) {
      setSendChain(chosenChain?.chain_name);
    }

    actions.setAppData({ activeWalletChain: chosenChain });
  };
  const getTokenName = () => {
    return (
      modalData?.name ||
      modalData?.info?.name ||
      modalData?.data?.data[activeToken?.symbol]?.name ||
      modalData?.symbol
    );
    // if( activeToken.hasOwnProperty('correlatedName') ){
    //     return activeToken.chain === sendChain? activeToken.name : activeToken.correlatedName;
    // }
    // return activeToken.name;
  };

  const shouldUserNameDisplay = async () => {
    let res = await checkIfWalletLinked(
      { address: addr },
      userData?.user?.linkedWallets
    );
    return res;
  };
  const getNetworks = () => {
    const res = actions.getTokenNetworks(activeToken?.symbol);
    //  .then(res=>{
    setNetworks(res);
    // })
  };
  return (
    <MainModal
      extraClasses={[
        "modal_w440",
        "modal_mh400",
        "modal_dflex",
        "modal_receive",
        "modal_receive_asset",
        "modal_send_asset_dropdown_fix",
      ]}
      onClose={props.onClose}
      title={"Receive " + getTokenName()}
      dataClass="modal_receive_asset"
    >
      {}
      {/* <div className="modal_heading_ctn">
                <div className="modal-heading">Receive {activeToken && activeToken?.name}</div>
            </div> */}
      <div className="modal_receive_content">
        {modalData?.symbol !== "BTC" && (
          <DropdownNetworkNew
            extraClass="dropdown_network_assets"
            noBlockScroll={false}
            networks={networks}
            onSelect={updateChain}
          />
        )}
        <DropdownWalletNew
          extraClass="dropdown_wallet_assets"
          compType="sendAssets"
          icon="images/wallet-icon.svg"
          noBlockScroll={false}
          onSelect={onSelect}
          symbol={modalData?.symbol}
        />
        <div className="modal_receive_row ">
          {showId && (
            <div className="user__box-name">
              <span className="colored">{userName}</span>
              {/* <span className="icon_copy_field icon-content-copy-black-24-dp" /> */}
              <TooltipCopy
                extraClasses="tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy tooltip_seed_phrase_copy"
                textToDisplay={""}
                imageToDisplay={
                  <img
                    src="images/swap/copy-icon.svg"
                    alt="copy to clipboard"
                  />
                }
                tooltipTextIn={"Copy to clipboard"}
                tooltipTextInChange="Copied to clipboard"
                copiedText={userName}
                hideAfter="3000"
              />
            </div>
          )}
        </div>
        <div className="modal_receive_row ">
          {/* <QRCode qrImage="images/qr-code.svg" /> */}
          <QRCode qrImage={qrImage} />
        </div>
        <div className="modal_receive_row ">
          <div className="modal_token_swap_desc modal_token_receive_asset">
            <CopyAddress shareAddress="show" ellipsis="remove" item={addr} />
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalAssetReceive;
