import React, { useEffect, useState } from "react";
// import DropdownNetwork from "../../common/DropdownNetwork/DropdownNetwork";
// import BalanceAssetsList from "../BalanceAssetsList/BalanceAssetsList";
// import HideLowBalance from "../HideLowBalance/HideLowBalance";
import { useApp } from "./../../../helpers/AppProvider";
import { convertIFPStoHttpUrl } from "../../../helpers/generalHelper";
import ROUTES from "../../../helpers/routes";
import { useNavigate } from "react-router-dom";

const NFTBalanceList = () => {
  const { appData, actions } = useApp();
  const [chainNFT, setChainNFT] = useState();
  const [NFTList, setNFTList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let NFTs = [];

    if (!appData.portfolioData) {
      return;
    }
    if (appData.activeWallet) {
      // NFTs for the wallet
      if (chainNFT) {
        // NFTs for the chain for the wallet
        NFTs = appData.portfolioData?.[appData.activeWallet]?.[chainNFT]?.nfts
          ?.length
          ? appData.portfolioData?.[appData.activeWallet]?.[
              chainNFT
            ]?.nfts?.map((obj) => {
              return {
                ...obj,
                chain: chainNFT,
                wallet: appData.activeWallet,
              };
            })
          : [];
      } else {
        // NFTs for all chains for the wallet
        const NFTinChain = appData?.portfolioData[
          appData?.activeWallet
        ]?.activeChains?.map((chain) => {
          return appData.portfolioData?.[appData.activeWallet]?.[chain]?.nfts
            ?.length
            ? appData.portfolioData?.[appData.activeWallet]?.[chain]?.nfts?.map(
                (obj) => {
                  return { ...obj, chain: chain, wallet: appData.activeWallet };
                }
              )
            : [];
        });
        NFTs = NFTinChain?.reduce((acc, obj) => {
          return acc.concat(obj);
        }, []);
      }
    } else {
      // NFTs for all wallets
      if (chainNFT) {
        // NFTs for the chain for all wallets
        const NFTinWallets = appData?.accounts?.map((account) => {
          return appData?.portfolioData?.[account?.address]?.[chainNFT]?.nfts
            ?.length
            ? appData?.portfolioData?.[account.address]?.[chainNFT]?.nfts?.map(
                (obj) => {
                  return { ...obj, chain: chainNFT, wallet: account.address };
                }
              )
            : [];
        });
        NFTs = NFTinWallets?.reduce((acc, obj) => {
          return acc.concat(obj);
        }, []);
      } else {
        // NFTs for all chains for all wallets
        const NFTinWallets = appData.accounts.map((account) => {
          const NFTinChain = appData.portfolioData[account.address]
            ? appData.portfolioData[account.address]?.activeChains.map(
                (chain) => {
                  return appData.portfolioData?.[account.address]?.[chain]?.nfts
                    ?.length > 0
                    ? appData.portfolioData?.[account.address]?.[
                        chain
                      ]?.nfts?.map((obj) => {
                        return {
                          ...obj,
                          chain: chain,
                          wallet: account.address,
                        };
                      })
                    : [];
                }
              )
            : [];
          return NFTinChain?.reduce((acc, obj) => {
            return acc.concat(obj);
          }, []);
        });

        NFTs = NFTinWallets.reduce((acc, innerArray) => {
          return acc.concat(innerArray);
        }, []);

        // console.log(
        //   "NFTinWallets",
        //   NFTs,
        // );
      }
    }
    setNFTList(NFTs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.portfolioData, appData.activeWallet, chainNFT]);

  return (
    <div className="dashboard__overview-column dashboard__overview-balance-cont">
      <div className="dashboard__overview-column-inner">
        <div className="dashboard__overview-column-heading flex flex-row justify-between items-center bg-[#F7F9FB] py-[10px] px-[20px] rounded-t-[8px]">
          <div>NFTs</div>
          <div>
            <select
              value={chainNFT}
              onChange={(e) => setChainNFT(e.target.value)}
              className="bg-[#F7F9FB] border border-[#E5E5E5] rounded-[5px] px-[10px] py-[5px] text-black"
            >
              {appData?.activeWallet &&
              appData?.accounts?.find(
                (account) => account?.address === appData?.activeWallet
              )?.type === "bitcoin" ? (
                <option value="">Bitcoin</option>
              ) : (
                <>
                  <option value="">All Chains</option>
                  {!appData?.activeWallet && <option value="">Bitcoin</option>}
                  {appData.chains.map((chain, index) => {
                    return (
                      <option
                        key={index}
                        value={
                          chain.chain_name === "ethereum"
                            ? "eth"
                            : chain.chain_name
                        }
                        // disabled={chain.chain_name === "mantle"}
                      >
                        {chain.chain_name === "bsc"
                          ? chain.chain_name?.toUpperCase()
                          : chain.chain_name?.charAt(0).toUpperCase() +
                            chain.chain_name?.slice(1)}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
          </div>
        </div>
        <div className="dashboard__overview-column-inner-scroll flex flex-row justify-center flex-wrap p-[10px]">
          {NFTList !== undefined || NFTList?.length > 0
            ? NFTList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-[45%] pb-[10px] bg-[#EEF2F7] m-[5px] rounded-xl cursor-pointer"
                    onClick={() => {
                      actions.setAppData({ activeWallet: item?.wallet });
                      navigate(ROUTES.NFTS_DETAIL, {
                        state: { nftData: item },
                      });
                    }}
                  >
                    <div className="p-[10px] rounded-xl">
                      <img
                        className="rounded-xl"
                        src={
                          item?.metadata?.image !==
                          "https://usdcgo.com/image.png"
                            ? convertIFPStoHttpUrl(item?.metadata?.image)
                            : ""
                        }
                        alt={item?.metadata?.name}
                      />
                    </div>
                    <div className="px-[10px] flex flex-row justify-between items-center w-[100%]">
                      <div className="w-[70%]">
                        <div className="text-[14px] break-words">
                          {item?.metadata?.name}
                        </div>
                        <div className="text-[12px] break-words">
                          Token ID: {item?.tokenId}
                        </div>
                      </div>
                      <div>
                        <img
                          className="w-[25px] rounded-full"
                          src={
                            appData.chains.filter((chain) => {
                              if (item?.chain === "eth") {
                                return chain?.chain_name === "ethereum";
                              } else {
                                return chain?.chain_name === item?.chain;
                              }
                            })[0]?.details?.imageURL
                          }
                          alt={item?.chain}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : null}

          {(NFTList === undefined || !NFTList.length) && <div>No NFTs</div>}
        </div>
      </div>
    </div>
  );
};

export default NFTBalanceList;
