import { useContext, useState, useRef } from "react";
import userContext from "./../context/userContext";
import initialState from "./../context/initialStateUser";
import UserController from "./../controllers/UserController";
import RecaptchaContext from "../context/RecaptchaContext";
import ReCAPTCHA from "react-google-recaptcha";
import Storage from "./Storage";
import { useNavigate } from "react-router-dom";

const UserProvider = ({ children }) => {
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
  const recaptchaRef = useRef(null);

  const state = useProvideUser();
  return (
    <RecaptchaContext.Provider value={recaptchaRef}>
      <userContext.Provider value={state}>
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={SITE_KEY} />
        {children}
      </userContext.Provider>
    </RecaptchaContext.Provider>
  );
};

const useProvideUser = () => {
  const navigate = useNavigate();

  const iState = initialState;
  const usr = Storage.load("user");
  if (usr !== false) {
    iState.user = usr;
  }
  const [user, setUser] = useState(iState);

  const actions = new UserController(user, setUser, { navigate });

  return {
    userData: user,
    setUserData: setUser,
    userActions: actions,
  };
};

const useUser = () => {
  return useContext(userContext);
};
function useRecaptcha() {
  return useContext(RecaptchaContext);
}

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export { useUser, useRecaptcha, UserProvider, isValidEmail };
