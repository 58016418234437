export const ACTIVE_MODAL_KEY = "ACTIVE_MODAL_KEY";
export const DEFAULT_MODAL_STATUS = "CLOSED";
export const TEST_FLOW = "TEST_FLOW";
export const ADD_WALLET_FLOW = "ADD_WALLET_FLOW";
export const LINK_WITH_SAFLEID = "LINK_WITH_SAFLEID";
export const CHANGE_SAFLE_ID = "CHANGE_SAFLE_ID";
export const SETTINGS_CHANGE_EMAIL = "SETTINGS_CHANGE_EMAIL";
export const SETTINGS_CHANGE_NAME = "SETTINGS_CHANGE_NAME";
export const SETTINGS_CHANGE_PROFILE_IMAGE = "SETTINGS_CHANGE_PROFILE_IMAGE";
export const SETTINGS_EXPORT_SEED_PHRASE = "SETTINGS_EXPORT_SEED_PHRASE";
export const EXPORT_WALLET_PRIVATE_KEY_FLOW = "EXPORT_WALLET_PRIVATE_KEY_FLOW";
export const REMOVE_NEW_WALLET_FLOW = "REMOVE_NEW_WALLET_FLOW";
export const EDIT_LABEL_WALLET_FLOW = "EDIT_LABEL_WALLET_FLOW";
export const REMOVE_WALLET_FLOW = "REMOVE_WALLET_FLOW";
export const QR_CODE_FLOW = "QR_CODE_FLOW";
export const BUY_TRANSAK_FLOW = "BUY_TRANSAK_FLOW";
export const CHANGE_PIN_FLOW = "CHANGE_PIN_FLOW";
export const CHANGE_PASSWORD_FLOW = "CHANGE_PASSWORD_FLOW";
export const FORGOT_PIN_FLOW = "FORGOT_PIN_FLOW";
export const BUY_ASSET_FLOW = "BUY_ASSET_FLOW";
export const RECEIVED_ASSET_FLOW = "RECEIVED_ASSET_FLOW";
export const SEND_ASSET_FLOW = "SEND_ASSET_FLOW";
export const ADD_CUSTOM_ASSET_FLOW = "ADD_CUSTOM_ASSET_FLOW";
export const RECEIVE_NFT_FLOW = "RECEIVE_NFT_FLOW";
export const SEND_NFT_FLOW = "SEND_NFT_FLOW";
export const SWAP_FLOW = "SWAP_FLOW";
export const RESTORE_WALLET_FLOW = "RESTORE_WALLET_FLOW";
