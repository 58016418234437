/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import DropdownNetworkItemNew from "../DropdownNetworkItemNew/DropdownNetworkItemNew";
import { useApp } from "../../../helpers/AppProvider";

const DropdownNetworkNew = (props) => {
  const { appData, actions } = useApp();
  const { showLabel = true } = props;
  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [chosenChain, setChosenChain] = useState(
    appData?.chains?.findIndex((chain) =>
      appData?.activeAssetChain === "eth"
        ? chain.chain_name === "ethereum"
        : chain.chain_name === appData?.activeAssetChain
    ) || 0
  );
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [chains, setChains] = useState([]);

  //populate an object with items
  const dummyAddress = [
    {
      // key : 1,
      network_name: "Ethereum Mainnet",
      network_logo: "images/currency/currency-ethereum-gray.svg",
    },
  ];
  // first time load the first address in the object
  // or select the selected used address
  useEffect(() => {
    if (props.hasOwnProperty("networks")) {
      let networks = props.networks
        ? appData.chains.filter(
            (e) => props.networks.indexOf(e.chain_name) !== -1
          )
        : [];

      if (networks.length > 0) {
        networks = networks.map((e) => {
          return { network_name: e.name, network_logo: e.details.imageURL };
        });
        setChains(networks);
        //
        setDropdown(networks[chosenChain]);
        props.onSelect && props.onSelect(networks[chosenChain]);
      }
    }
  }, [props.networks]);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes =
    "main__controls controls__dropdown controls__dropdown-new-network";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        if (props?.noBlockScroll === false) {
          //in popup dont remove add class
          // actions.setAppData({ blockScroll: false });
        } else {
          actions.setAppData({ blockScroll: false });
        }
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);

      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: true });
      }
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: false });
      }

      // document.body.classList.remove('no_scroll_dropdown');
    };
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, chain) => {
    // add class with selected
    setHighlightedItem(index);
    setChosenChain(index);
    // set the state for the dropdown with last value
    setDropdown(chain);
    actions.setAppData({ activeWalletChain: chain });
    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    props.onSelect && props.onSelect(chain);

    if (props?.noBlockScroll === false) {
    } else {
      actions.setAppData({ blockScroll: false });
    }
    // document.body.classList.remove('no_scroll_dropdown');
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(dummyAddress.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, chains[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    // <div
    //   className={
    //     props.type === "header"
    //       ? "form__input form__input_default"
    //       : "assets__overview_nav_right"
    //   }
    // >
    //   {showLabel && (
    //     <span className="input_label">
    //       {props.type === "header" ? "Select Chain" : ""}
    //     </span>
    //   )}
    <div className="form__input form__input_default">
      {showLabel && <span className="input_label">Select Chain</span>}

      <div
        className={finalDrpClasses}
        ref={dropdownRef}
        onKeyUp={dropdownMoveUp}
        tabIndex="0"
      >
        <div
          className="dropdown__active__item control-network-dropwdon-new"
          onClick={onClick}
        >
          <div className="network-new-name-content">
            <img src={dropdown?.network_logo} alt="" />
            <span className="drp_name network_name network-name-new">
              {dropdown?.network_name}
            </span>
          </div>
          <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
        </div>
        <div className="dropdown__list__items">
          {chains.length &&
            chains.map((dummyAddres, index) => (
              <DropdownNetworkItemNew
                items={dummyAddres}
                highlight={index === highlighted}
                key={index}
                clickHandle={() => onClickItem(index, dummyAddres)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownNetworkNew;
