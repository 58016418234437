const RecentActivityIcon = ({ type, failed = "" }) => {
  // const isFailed = failed;
  // let color_default = "";
  // let label_failed = "";
  // if (isFailed) {
  //   // color_default = "activity_failed";
  //   label_failed = "Failed";
  // }
  const getClassName = () => {
    switch (type) {
      case "Buy":
        return "_bought color_light_blue";
      case "send":
        return "_sent color_black";
      case "receive":
        return "_received color_green";
      case "swap":
        return "_swapped color_black";
      case "contract-call":
        return "_swapped color_black";
      default:
        return "_swapped color_black";
    }
  };
  return (
    <>
      {/*
      Send
      Receive
      Swapped
      Buy
      */}

      <div className="recent__activity_types">
        <div className="flex item-center justify-between">
          <div className="recent__activity_row">
            <div className="recent__activity_type">
              {type === "receive" && (
                <span className="icon-activity_received color_green"></span>
              )}
              {type === "send" && (
                <span className="icon-activity_sent color_blue_dark"></span>
              )}
              {(type === "swap" || type === "contract-call") && (
                <span className="icon-activity_swapped color_blue_light"></span>
              )}
              {type === "Buy" && (
                <span className="icon-activity_bought color_blue_light"></span>
              )}
              {type === "approve" && (
                <img
                  src="/images/approval.svg"
                  alt="approval"
                  style={{ width: "20px" }}
                />
              )}
              {type === "contract_execution" && (
                <img
                  src="/images/scontractexec.svg"
                  alt="approval"
                  style={{ width: "12px" }}
                />
              )}
            </div>
          </div>
          <div
            className={"ml-3 asset__coin-asset-type-activity " + getClassName()}
          >
            <span className="activity_label">
              {" "}
              {type === "contract_execution" ? "SCE" : type}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentActivityIcon;
