import React, { useState, useEffect } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import ModalAssetSend from "./../../dialogs/ModalAssetSend";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";
import { set } from "lodash";

const ModalSendAssetFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(SEND_ASSET_FLOW);
  const [modalData, setData] = useModalData();
  const { appData, actions } = useApp();

  const [availNetworks, setNetworks] = useState([]);
  const [sendToken, setSendToken] = useState(false);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    //
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
    //
  };
  const onCompletePIN = (value) => {
    //
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  useEffect(() => {
    const availableNetworks = actions.getTokenNetworks(
      modalData?.sendToken?.symbol || modalData?.symbol
    );
    setNetworks(availableNetworks);
    setSendToken(modalData);
    // actions
    //   .getTokenNetworks(modalData?.sendToken?.symbol || modalData?.symbol)
    //   ?.then((availNetworks) => {
    //
    //     setNetworks(availNetworks);
    //     setSendToken(modalData);
    //   });
  }, []);

  //

  return (
    <ModalAssetSend
      networks={availNetworks}
      token={modalData}
      onClose={onClose}
      // onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="modal_send_asset_cancel"
      modalNext_label="modal_send_asset_enter_pin"
    />
  );
};

export default ModalSendAssetFunctions;
