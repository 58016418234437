import React from "react";
import {
  limitTitleFromMiddle,
  middleEllipsis,
} from "../../../helpers/generalHelper";

const DropdownNFTWalletItem = (items) => {
  const item = items.items;

  let drpType = "";
  if (items.drpType && items.drpType !== undefined) {
    drpType = items.drpType;
  }

  return (
    <div
      className={"drop-link " + (items.highlight ? "selected" : "")}
      onClick={items.clickHandle}
    >
      {/* <span className="icon-navbar-icon-wallet-de-active"></span> */}
      <span
        className="drop_label"
        style={{
          minWidth: 50,
          marginRight: 10,
          maxWidth: 50,
          textOverflow: "ellipsis",
          overflow: "hidden",
          fontWeight: 500,
          whiteSpace: "nowrap",
          fontSize: 12,
        }}
      >
        {typeof item.label === "string" ? item.label : item.label?.polygon}
      </span>
      <span className="drp_name wallet_address">
        {limitTitleFromMiddle(item.address, 10)}
      </span>
    </div>
  );
};

export default DropdownNFTWalletItem;
