import React from 'react';
import MainModal from '../generic/MainModal';
import SafleSearchID from '../common/SafleSearchID/SafleSearchID';
import Tip from '../common/Tip/Tip';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalChangeIDColored = ( { onClose } ) => {

  return (
    <MainModal onClose={ onClose } extraClasses={ [ 'modal_w440', 'modal_p30', 'modal_dflex', 'modal_change_safle', 'modal_change_safle_colored' ] } dataClass="modal_change_safle_colored" >
      <div className="modal_succes_content">
        <div className="modal_succes_head pt_60">Change Safle ID</div>
        <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered">You can update your SafleID to a new one. <br/> Just search below to check availability.</span>
        </div>
        <div className=" modal--padding">
          <div className="change_safle_ids-wrp">
            <div className="change_safle_row mb-30">
              <div className="change_safle_top_search">
                <SafleSearchID />
              </div>
            </div>
            <div className="change_safle_row">
              <div className="change_safle_current">
                <div className="change_safle_left_side">Available</div>
                <div className="change_safle_right_side">
                  <span className="available_id">apoorv</span>
                </div>
              </div>
            </div>
            <div className="change_safle_row_divider" />
            <div className="change_safle_row mb-10">
              <div className="change_safle_current">
                <div className="change_safle_left_side">Current Safle ID</div>
                <div className="change_safle_right_side">
                  <span className="blue">charles</span>
                </div>
              </div>
            </div>
            <div className="change_safle_row">
              <div className="change_safle_current">
                <div className="change_safle_left_side">Remaining</div>
                <div className="change_safle_right_side">
                  <span className="default">2 updates*</span>
                </div>
              </div>
            </div>
          </div>
          <Tip text={ 'Tip: Change your safle ID if it is required, you can only change your safle ID, only 3 times' } />
        </div>
        <div className="modal_succes_content_actions ">
          <PrimaryBtn
            classes={ 'btn-default btn-blue modal__trigge' }
            label='Next'
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalChangeIDColored;