import React from "react";

const NotFound = () => {
  return (
    <div className="text-white flex w-[100%] justify-center pt-[100px] flex-col">
      <div className="flex justify-center text-[40px]">Error 404</div>
      <div className="flex justify-center pt-[40px]">Page Not found</div>
    </div>
  );
};

export default NotFound;
