import React, { useEffect, useState } from "react";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";

import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";
import OTPInputs from "../registration/OTPInputs/OTPInputs";

const ModalSetNewPin = ({
  validation,
  setValidation,
  modalNextLabel,
  modalCancelLabel,
  callbackCancelPopup,
  callbackOpenPopup,
  mnemonics,
}) => {
  const { userActions } = useUser();
  const { actions } = useApp();

  const [pinVal, setPinVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const [pinConfirmVal, setConfirmPinVal] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    validateConfirmPin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinVal, pinConfirmVal]);

  const setPin = (val) => {
    setPinVal({ ...pinVal, ...val });
  };
  const setConfirmPin = (val) => {
    setConfirmPinVal({ ...pinConfirmVal, ...val });
  };

  const validateConfirmPin = () => {
    const p1 = Object.values(pinVal).join("");
    const p2 = Object.values(pinConfirmVal).join("");
    setValidation(false);

    if (p2?.length < 6) return false;

    if (p1 === 123456) {
      setValidation("Pin cannot be 123456");
      setSuccess(false);
    } else if (p1 === p2) {
      setSuccess(true);
    } else {
      setValidation("Wrong pin, Please type correct one");
      setSuccess(false);
    }
  };

  const submitNewPin = async () => {
    actions.setLoading(true);
    const pinValue = Object.values(pinVal).join("");

    try {
      const result = await userActions.changePin(
        Object.values(mnemonics).join(" "),
        Number(pinValue)
      );
      actions.setLoading(false);
      if (typeof result?.response === "string") {
        // setUserData({ user: { ...userData.user, vault: result?.response } }, true);
        // setModalData( { vault: result?.response } );
        // updateVaultData( result.response );
        callbackOpenPopup(modalNextLabel);
      }
    } catch (error) {
      actions.setLoading(false);
      if (typeof error === "object") {
        // when seed phrase is missing go back and re-add it.
        if (error.message === "Seed phrase is invalid.") {
          setValidation(error.message);
          callbackOpenPopup("enter_seed_phrase");
        } else {
          setValidation(error.message);
        }
      } else if (typeof error === "string") {
        setValidation(error);
      }
    }
  };

  // if State was lost then go back to previous step
  useEffect(() => {
    if (mnemonics[10].length < 2) {
      callbackOpenPopup("enter_seed_phrase");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainModal
      onClose={() => callbackCancelPopup(modalCancelLabel)}
      extraClasses={[
        "modal_w440",
        "modal_dflex",
        "modal_action",
        "modal_set_new_pin",
      ]}
      dataClass="modal_set_new_pin"
    >
      <div className="modal_success_content">
        <ModalHeadIcon img="images/icon-pin.svg" />
        <div className="modal_title">Set New PIN</div>
        <div className="modal_pin_label">New PIN</div>
        <div className={`modal_pin_pad-wrp mb_30 ${success && "pin-success"}`}>
          <OTPInputs numbers={pinVal} setNumbers={setPin} />
        </div>
        <div className="modal_pin_label">Confirm New PIN</div>
        <div className="mb_34">
          <div
            className={`modal_pin_pad-wrp ${validation && "pin-error"} ${
              success && "pin-success"
            }`}
          >
            <OTPInputs
              numbers={pinConfirmVal}
              setNumbers={setConfirmPin}
              autofocus={false}
            />
          </div>
          <div className="modal_pin_error">{validation} &nbsp; </div>
          {/* Wrong pin, Please type correct one */}
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <PrimaryBtn
            classes={"btn-default btn-white-bordered close_modal_cancel"}
            label="Cancel"
            onClick={() => callbackCancelPopup(modalCancelLabel)}
          />
          <PrimaryBtn
            classes={"btn-default btn-blue modal__trigger"}
            disabled={!success}
            onClick={submitNewPin}
            label="Update"
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalSetNewPin;
