import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import {
  formatXMinDecimals,
  limitTitleFromMiddle,
  middleEllipsis,
} from "../../../helpers/generalHelper";
import { getCoinRate } from "../../../helpers/walletHelpers";
const Web3 = require("web3");

const SwapHistoryItem = ({
  index,
  out,
  outIcon,
  outTo,
  outToIcon,
  status,
  wallet,
  time,
  txnId,
  gasFee,
  slippage,
  partner,
  externalUrl,
  handleActiveRow,
  activeRow,
  item,
}) => {
  // const gasFeeFormatter = ()=>{
  //   const web3 = new Web3();
  //   if(item?.gasFeeUsd)
  //   return  formatXMinDecimals(web3.utils.fromWei(web3.utils.BN( item.gasFeeUsd.toString()), 'ether' ) )
  // }
  const [price, setPrice] = useState(0);

  const calculatePrice = async (amt) => {
    if (!amt) {
      return null;
    }
    const res = await getCoinRate(item.fromTokenSymbol);
    let price = res.data.data[item.fromTokenSymbol]?.quote["USD"]?.price || 1;
    return parseFloat((price * amt).toFixed(3));
  };

  useEffect(() => {
    calculatePrice(item.gasFeeUsd).then((res) => setPrice(res));
  }, [item]);

  return (
    <div
      onClick={() => handleActiveRow(index)}
      className={
        activeRow === index
          ? "swap__history-row swap__history-open"
          : "swap__history-row"
      }
    >
      <div className="swap__history-top">
        <div className="swap__history-details">
          <span className="swap__history-ico icon-chevron-down-solid-1" />
        </div>
      </div>
      <div className="swap__history-asset">
        <div className="asset__coin">
          <div className="asset__coin-image">
            <div
              className="asset__coin-image-icon"
              style={{ backgroundImage: "url(" + outIcon + ")" }}
            />
          </div>
          <div className="asset__coin_prefix">
            {out} {item?.fromTokenSymbol}
          </div>
        </div>
        <div className="swap__history_arr">
          <img src="images/swap/swap-arrow-gray.svg" alt="" />
        </div>
        <div className="asset__coin">
          <div className="asset__coin-image">
            <div
              className="asset__coin-image-icon"
              style={{ backgroundImage: "url(" + outToIcon + ")" }}
            />
          </div>
          <div className="asset__coin_prefix">
            {outTo} {item?.toTokenSymbol}
          </div>
        </div>
        <div className="swap__history_arr swap-status">
          {status === "pending" ? (
            <img src="images/swap/icon-pending.svg" alt="" />
          ) : status === "success" ? (
            <img src="images/swap/icon-done.svg" alt="" />
          ) : (
            <img src="images/swap/icon-error.svg" alt="" />
          )}
        </div>
      </div>
      <div className="swap__history-desc">
        <div className="swap-row-desc-info">
          <div className="swap-col-desc-info">
            <span className="title">Wallet:</span>
            <span className="data">
              {limitTitleFromMiddle(limitTitleFromMiddle(wallet, 10))}
            </span>
            {/*<span className='data'>{middleEllipsis(wallet,1)}</span>*/}
          </div>
          <div className="swap-col-desc-info">
            <span className="title">Swap time:</span>
            <span className="data">
              {moment(time).format("MMM D YYYY, h:mm a")}
            </span>
          </div>
        </div>
        <div
          className={
            activeRow === index ? "show-row-desc-info" : "hidden-row-desc-info"
          }
        >
          <div className="swap-row-desc-info">
            <div className="swap-col-desc-info">
              <span className="title">Txn ID:</span>
              {/*<span className='title'>Swap txn ID:</span>*/}
              <span className="data">{limitTitleFromMiddle(txnId, 10)}</span>
            </div>
            <div className="swap-col-desc-info">
              <span className="title">Gas fee:</span>
              <span className="data">
                {formatXMinDecimals(item?.gasFeeUsd, 2, 4)}{" "}
                {item.fromTokenSymbol} {`($${price ? price : " NA"})`}
              </span>
            </div>
          </div>
          <div className="swap-row-desc-info">
            <div className="swap-col-desc-info">
              <span className="title">Slippage:</span>
              {/*<span className='title'>Max slippage:</span>*/}
              <span className="data">{slippage}%</span>
            </div>
            <div className="swap-col-desc-info">
              <span className="title">Swap partner:</span>
              <span
                className="data"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <img src={`images/swap/${partner}-logo.svg`} alt="" />
              </span>
            </div>
          </div>
          <div className="swap-row-desc-info">
            <div className="swap-col-desc-info">
              <span className="title">Network:</span>
              <span className="data">{item?.network}</span>
            </div>
            {externalUrl ? (
              <a
                href={externalUrl}
                target="_blank"
                className="check-swap-external"
              >
                Check on blockchain explorer{" "}
                <span className="icon-icon_eth_scan swap-history-external"></span>
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapHistoryItem;
