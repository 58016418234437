import React, { useEffect } from "react";

import ModalActionSuccess from "./../../dialogs/ModalActionSuccess";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import { delay_code } from "../../../helpers/generalHelper";

const ModalSuccessFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);
  const { actions } = useApp();
  const { userActions } = useUser();
  const onClose = async () => {
    try {
      actions.setLoading(true, "loading");
      setActiveModalNav(DEFAULT_MODAL_STATUS);
      const acc = await userActions.getAccounts();
      await actions.getAccounts();
      const addreses = acc ? Object.values(acc) : {};
      await actions.refreshWalletCardData(addreses);
      actions.setLoading(false);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <ModalActionSuccess
      title="Wallet is Archived"
      desc={`Your Wallet is now archived, you can see it in Archived wallet “TAB”`}
      dataClass="modal_action_success1"
      onClose={onClose}
      // callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalSuccessFunctions;
