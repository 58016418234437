const EnterAmountInput = ({
  value,
  setValue,
  error = false,
  gasFeesError = false,
}) => {
  const normalizeInput = (val) => {
    setValue(val.replace(",", "."));
  };

  return (
    <div className="form__input form__input_default from_input_send_to">
      <span className="input_label">Enter Amount</span>
      <div className="form__input_default">
        <input
          className={
            "token-amount-input form-input-default form-input-default-numbers " +
            (error ? "error" : "")
          }
          title="Enter Amount"
          placeholder={0.0}
          type="number"
          step="0.01"
          min="0"
          /*inputMode="decimal"
                autoComplete="off"
                autoCorrect="off"
                type="number"
                pattern="^[0-9]*[.]?[0-9]*$"
                
                maxLength={79}
                spellCheck="false"
                defaultValue=""*/
          value={String(value)?.replace(/^0+(?=[0-9])/, "")}
          onChange={(e) => normalizeInput(e.target.value)}
        />
      </div>
      {error && (
        <span className="from_input_send_to_error">
          {value && value > 0 && (
            <>The amount entered exceeds your available balance</>
          )}
          {value && value < 0 && <>The amount entered cannot be less then 0</>}
        </span>
      )}
      {gasFeesError && (
        <span className="from_input_send_to_error">
          {gasFeesError && <>Insufficient chain native balance for gas fees</>}
        </span>
      )}
    </div>
  );
};

export default EnterAmountInput;
