import React from 'react';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import Tip from '../common/Tip/Tip';
import DuplicateIcon from '@heroicons/react/outline/DuplicateIcon';
import TooltipCopy from '../registration/TooltipCopy';

const ModalExportKeys = ( props ) => {

    const tooltipText = '';
    // const tooltipIcon = `${process.env.PUBLIC_URL}/assets/icons/file-icon.svg`;
    const tooltipTextIn = 'Copy to clipboard';

    const tooltipTextInChange = "Copied to clipboard";

    const TooltipIconSvg = () => {
        return <span className="icon_copy_gray icon-copy-90"></span>
    }

    return (
        <MainModal onClose={ () => props.callbackCancelPopup(props.modalCancel_label) } extraClasses={ [ 'modal_w440 modal_p30', 'modal_dflex', 'modal_export_private_keys' ] } dataClass="modal_export_keys" >
            <div className="modal_succes_content">
                <div className="modal_padding">
                    <div className="modal_succes_ico">
                        <img src="images/wallets/icon-done.svg" alt="Succes IcON" />
                    </div>
                    <div className="modal_succes_head">Export Private Keys</div>
                    <div className="modal_succes_desc mb_40">
                        <span className="simple_text simple_text_centered">This is your private key (Click to Copy)</span>
                    </div>
                    <div className="private_key_code">
                        <span>{props.pvkey}</span>
                        <TooltipCopy 
                            extraClasses='tooltip_registration cursor-pointer tooltip_webapp_copy private_key_copy' 
                            textToDisplay={tooltipText} 
                            imageToDisplay={<TooltipIconSvg />} 
                            tooltipTextIn={tooltipTextIn} 
                            tooltipTextInChange={tooltipTextInChange} 
                            copiedText={props.pvkey} 
                            itemProps={props.pvkey} 
                            hideAfter="3000"
                        />
                    </div>
                    <Tip text="Warning: Never disclose the key. Anyone with your private keys can steal your assets from your account." />
                </div>
                <div className="modal_footer">
                    <div className="modal_succes_content_actions">
                        <PrimaryBtn onClick={props.onClose} classes="btn-default btn-blue close_modal_ok" label={props.btnLabel ? props.btnLabel : "Link with Safle ID"} />
                    </div>
                </div>
            </div>

        </MainModal>
    );
};

export default ModalExportKeys;