import React, { useState, useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
const CurrencyIcon = ({ icon }) => {
  const { appData } = useApp();
  const { userActions } = useUser();
  const imageRef = React.createRef();
  const [image, setImage] = useState(icon);
  const [defaultImage, setDefaultImage] = useState(false);

  useEffect(() => {
    const ni = new Image();
    const listener = () => {
      if (imageRef.current) {
        imageRef.current.style.backgroundImage =
          "url(images/currency/coin.svg)";
        setDefaultImage(false);
      }
    };
    ni.addEventListener("error", listener);
    // ni.src=item['Country Flag'];
    return () => {
      document.removeEventListener("error", listener);
    };
  }, []);

  return (
    <div className="select__currency-image ">
      <div
        className="select__currency-image-icon"
        style={{
          backgroundImage: `url(${icon})`,
          // filter: defaultImage? 'grayscale(1)' : 'none',
          // opacity: defaultImage? '0.6' : '1'
          // opacity: appData.activeCurrency && appData.activeCurrency.hasOwnProperty('Country Flag')? '1' : '0.6',
          // filter: appData.activeCurrency && appData.activeCurrency.hasOwnProperty('Country Flag')? '' : 'grayscale(1)'
        }}
        ref={imageRef}
      />
    </div>
  );
};

export default CurrencyIcon;
