import React, { useEffect, useState } from "react";
import TokenIcon from "../common/TokenIcon/TokenIcon";
import AssetBadge from "../common/AssetBadge/AssetBadge";
import WalletItemControls from "../wallets/WalletItemControls/WalletItemControls";
import TooltipCopy from "../../components/registration/TooltipCopy";
import DuplicateIcon from "@heroicons/react/outline/DuplicateIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";
import PencilIcon from "@heroicons/react/solid/PencilIcon";
import useModalNavigation, {
  useModalData,
} from "../../helpers/useModalNavigation";
import {
  EXPORT_WALLET_PRIVATE_KEY_FLOW,
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../constants/ui";
import { useUser } from "../../helpers/UserProvider";
import { useApp } from "../../helpers/AppProvider";
import { useNavigate } from "react-router-dom";
import { middleEllipsis } from "../../helpers/generalHelper";
import moment from "moment";
import { checkIfWalletLinked } from "../../helpers/walletHelpers";

const VaultActiveWalletList = ({
  item,
  openEnterPinModal,
  openEditLabelModal,
  openQRModal,
  key,
  index,
}) => {
  const navigate = useNavigate();

  const { userData, userActions } = useUser();
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);
  const [modalData, setModalData] = useModalData();
  const { appData, actions } = useApp();
  const icon = actions.getChainLogo(item.chain);
  const [txnCount, setTxnCount] = useState();
  const [walletLinkedToSafleId, setWalletLinkedToSafleId] = useState(false);

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    checkWalletLinkedtoSafleID(item);
  }, []);

  const checkWalletLinkedtoSafleID = async (item) => {
    const res = await checkIfWalletLinked(item, userData.user.linkedWallets);
    setWalletLinkedToSafleId(res);
    // res;
  };

  useEffect(() => {
    getTxnCount(item?.address, item?.chainName);
  }, []);

  const getTxnCount = async (address, chainName) => {
    try {
      actions.setLoading(true, "loading");
      const count = await actions.getWalletTransactions(address, chainName);
      setTxnCount(count?.length);
    } catch (e) {
    } finally {
      actions.setLoading(false);
    }
  };
  const startRemoveWalletFlow = (item) => {
    setModalData({ address: item.address });
    setActiveModalNav("modal_remove_wallet_popup");
  };

  const openExportPvKeyHandler = () => {
    setModalData({ type: "export_pvkey", address: item.address });
    openEnterPinModal();
  };

  const tooltipText = "";
  // const tooltipIcon = `${process.env.PUBLIC_URL}/assets/icons/file-icon.svg`;
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };

  return (
    <>
      {item && item.address && (
        <tr>
          <td className="text-left pl-8">
            {typeof item?.label === "string"
              ? item?.label?.length > 20
                ? middleEllipsis(
                    item?.label,
                    parseInt(item?.label?.length * 0.1),
                    4
                  )
                : item?.label
              : item?.label?.polygon?.length > 20
              ? middleEllipsis(
                  item?.label?.polygon,
                  parseInt(item?.label?.polygon?.length * 0.1),
                  4
                )
              : item?.label?.polygon}
          </td>
          <td className="text-left pl-8">{middleEllipsis(item?.address)}</td>
          <td className="text-center">
            <span
              className="vault_chip"
              style={{
                background:
                  item?.chainName === "polygon"
                    ? "#A693FD"
                    : item?.chainName === "bitcoin"
                    ? "#F7931A"
                    : "#8690A0",
              }}
            >
              {item?.chainName?.charAt(0).toUpperCase() +
                item?.chainName?.slice(1)}
            </span>
          </td>
          <td className="text-center">
            {item?.createdAt !== "-"
              ? moment(item?.createdAt).format("DD-MM-YYY , hh:mm:ss")
              : "-"}
          </td>
          <td className="text-center">{item?.type} </td>
          <td className="text-center" width="10%">
            {typeof txnCount === "number" ? txnCount : "-"}
          </td>
          <td>
            {" "}
            <div className="aoc_table_col">
              <span className="filter_table_wallet_controls vault_active_wallet_actions">
                <span className="filter_table_wallet-icon">
                  <span className="ilter_table_wallet-tooltiptext export-pkey ">
                    <TooltipCopy
                      extraClasses="tooltip_registration tool_tip_vault_management cursor-pointer tooltip_webapp_copy"
                      textToDisplay={tooltipText}
                      imageToDisplay={<TooltipIconSvg />}
                      tooltipTextIn={tooltipTextIn}
                      tooltipTextInChange={tooltipTextInChange}
                      copiedText={item.address}
                      itemProps={item.address}
                      hideAfter="3000"
                    />
                  </span>
                </span>
                <span
                  onClick={() => openExportPvKeyHandler()}
                  className="filter_table_wallet-icon"
                >
                  <span className="filter_table_wallet-tooltiptext export-pkey">
                    Export Wallet{" "}
                  </span>
                  {/*<span className="icon-icon-wallet-outline" />*/}
                  <img
                    src={"images/vault/vault_management_export_wallet.png"}
                    //width="34px"
                    //height="34px"
                    alt=""
                  />
                </span>
                <span
                  className={`filter_table_wallet-icon disabled !bg-white `}
                  onClick={() =>
                    !walletLinkedToSafleId &&
                    !item?.canBeArchived &&
                    !(item?.address === userData?.user?.safleIdPublicAddress) &&
                    !(
                      item?.address ===
                      appData?.accounts.filter(
                        (account) => account.type === "bitcoin"
                      )[0]?.address
                    ) &&
                    startRemoveWalletFlow(item)
                  }
                  style={{
                    cursor:
                      (walletLinkedToSafleId ||
                        item?.canBeArchived ||
                        item?.address ===
                          userData?.user?.safleIdPublicAddress) &&
                      item?.address ===
                        appData?.accounts.filter(
                          (account) => account.type === "bitcoin"
                        )[0]?.address &&
                      "not-allowed",
                  }}
                >
                  <span className="filter_table_wallet-tooltiptext">
                    {walletLinkedToSafleId ||
                    item?.canBeArchived ||
                    item?.address === userData?.user?.safleIdPublicAddress ||
                    item?.address ===
                      appData?.accounts.filter(
                        (account) => account.type === "bitcoin"
                      )[0]?.address
                      ? "Wallet cannot be Archived"
                      : "Wallet Archive"}
                  </span>
                  <img
                    src={"images/wallets/wallet-archive.svg"}
                    width="34px"
                    height="34px"
                    alt=""
                    className={`${
                      (item?.canBeArchived ||
                        walletLinkedToSafleId ||
                        item?.address ===
                          userData?.user?.safleIdPublicAddress ||
                        item?.address ===
                          appData?.accounts.filter(
                            (account) => account.type === "bitcoin"
                          )[0]?.address) &&
                      "opacity-50"
                    }`}
                  />
                </span>
              </span>
            </div>
          </td>
        </tr>
      )}
    </>
    //</div>
  );
};

export default VaultActiveWalletList;
