
import useModalNavigation from '../../../helpers/useModalNavigation';
import { ADD_WALLET_FLOW } from '../../../constants/ui';

const AddWalletButton = ({withLabel = false}) => {
    const [setActiveModalNav] = useModalNavigation(ADD_WALLET_FLOW);
    const btnClasses = withLabel ? 'btn-icon-assets' : 'btn-icon';
    const containerClasses = withLabel ? 'assets__add_new modal__trigger' : 'dashboard__overview-wallets-add-new';

    const onClick = () => {
        setActiveModalNav('modal_add_wallet');
    }

    return (
        <div className={containerClasses}>
            <div className="add_wallet_btn" onClick={() => onClick()} style={{display:'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}}>
                <button className={`btn-default btn-add ${btnClasses}`} />
                {withLabel && 
                <div className="asset-add-new-label">Add Wallet</div>}
            </div>
            
        </div>
    );
}

export default AddWalletButton;