/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  UserIcon,
  LockClosedIcon,
  CheckCircleIcon,
  ArrowRightIcon,
  EyeIcon,
  EyeOffIcon,
} from "@heroicons/react/solid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import { useApp } from "../../helpers/AppProvider";
import { isValidEmail, isValidName } from "../../helpers/generalHelper";
import URLS from "../../helpers/routes";

import Preloader from "../../components/common/Preloader/Preloader";
import InputGroup from "../../components/registration/InputGroup/InputGroup";
import Button from "../../components/registration/Button/Button";
import Footer from "../../components/registration/PublicFooter/PublicFooter";
import Modal from "../../components/registration/Modal/Modal";
import Toast from "../../components/common/Toast/index";
import TooltipCopy from "../../components/registration/TooltipCopy/index";
import "../../styles/registration/signUp.scss";
import { useFlowGate } from "../../components/flow/FlowGate";

import CssFix from "./../../helpers/cssFix";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { copyToClipboard } from "../../helpers/generalHelper";
const min_pass_lenght = 8;

const SignUp = ({ history }) => {
  const { advance, initFlow } = useFlowGate();
  const { userActions } = useUser();
  const recaptchaRef = useRecaptcha();
  const { actions } = useApp();
  const { encryptionKey, setOtpStatus, setTempPassword, setSignupName } =
    useRegistration();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [safleId, setSafleId] = useState();
  const [checkSafleId, setCheckSafleId] = useState(false);
  const [safleError, setSafleError] = useState(false);
  const [isValidSafleId, setIsValidSafleId] = useState(null);
  const [name, setName] = useState();
  const [checkValidName, setCheckValidName] = useState(null);
  const [email, setEmail] = useState();
  const [checkValidEmail, setCheckValidEmail] = useState(null);
  const [checkEmailAvailable, setCheckEmailAvailable] = useState(true);
  const [checkSafleEmail, setCheckSafleEmail] = useState(false);
  const [password, setPassword] = useState();
  const [checkOneLowerChar, setCheckOneLowerChar] = useState(null);
  const [checkOneUpperChar, setCheckOneUpperChar] = useState(null);
  const [checkOneNumber, setCheckOneNumber] = useState(null);
  const [checkMinLength, setCheckMinLength] = useState(null);
  const [checkMaxLength, setCheckMaxLength] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState();
  const [checkConfirmPassword, setCheckConfirmPassword] = useState(null);
  const [tyc, setTyc] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [copietInputName, setCopiedInputName] = useState(null);
  const [copiedText, setCopiedText] = useState(null);
  const tooltipTextIn = "Copied to clipboard";
  const tooltipTextInChange = "Copied to clipboard";
  const hideTooltipAfter = 3000;
  const timerRef = useRef(null);

  const onCopyAction = (e) => {
    if (e.target.value) {
      setCopiedText(e.target.value);
      copyToClipboard(e.target.value);
      setCopiedInputName(e.target.name);
      clearTimeout(timerRef.current);
    }
    e.preventDefault();

    timerRef.current = setTimeout(() => {
      // Hide tooltip after 3 sec
      setCopiedText(null);
    }, hideTooltipAfter);
  };

  useEffect(() => {
    //update routing step
    document.title = "Register | Safle";
    initFlow("signup");
    const inputElems = document.querySelectorAll('input[type="text"]');

    inputElems.forEach((input) => {
      input.addEventListener("copy", onCopyAction);
    });

    return () => {
      inputElems.forEach((input) => {
        input.removeEventListener("copy", onCopyAction);
      });
    };
  }, []);

  const convertName = (name) => {
    return name
      .trim()
      .replaceAll(/(\s)+/g, " ")
      .split(" ")
      .map((word) => {
        const name = word.toLowerCase().split("");
        const fLetter = name.shift();
        return fLetter?.toUpperCase() + name.join("");
      })
      .join(" ");
  };

  const handleSubmit = async () => {
    actions.setLoading(true);
    const encK = Array.from(Object.values(encryptionKey));
    const token = await recaptchaRef.current.executeAsync();
    const resp = await userActions.register(
      safleId,
      convertName(name),
      email,
      password,
      token,
      encK
    );
    if (resp.statusCode === 201) {
      const dateNow = Date.now();
      setSignupName(name);
      setOtpStatus({
        safleId,
        email,
        message: resp.message,
        startedAt: dateNow,
        otpType: "email-verification",
      });
      setTempPassword(password);
      advance();
    } else {
      const messages = resp.details;
      setToastMessage(messages[0].message);
      setToastIsOpen(true);
    }
    actions.setLoading(false);
  };

  let tm;

  const handleSafleId = (e) => {
    if (tm) {
      clearTimeout(tm);
    }
    let value = e.target.value.trim().toLowerCase();
    const words = value.split(" ");
    value = words.join("");
    setSafleId(value);
    setIsValidSafleId(false);
    setCheckSafleId(false);
    if (value.length > 3 && value.length <= 16) {
      setSafleError(false);
      validateSafleId(e);
    } else {
      setSafleError("SafleID must have between 4 and 16 characters");
      setCheckSafleId(false);
    }
  };

  const validateSafleId = async (e) => {
    let safleId = e.target.value.toLowerCase();
    if (checkSafleId === true) return isValidSafleId;

    if (
      safleId.length >= 4 &&
      safleId.length <= 16 &&
      !isValidSafleId &&
      safleId
    ) {
      setCheckSafleId(true);
      const response = await userActions.validateSafleID(safleId);
      if (e.target.value.toLowerCase() !== safleId) {
        return;
      }
      setCheckSafleId(false);
      if (response.statusCode === 200) {
        if (response.data.isAvailable === false) {
          setIsValidSafleId(false);
          setSafleError("SafleID not available");
        } else {
          setIsValidSafleId(true);
          setSafleError(false);
        }
      }
    }
  };

  const handleName = (e) => {
    let value = e.target.value;
    if (
      value.length < 65 &&
      value.length > 0 &&
      value.trim().length !== 0 &&
      isValidName(value)
    ) {
      setCheckValidName(true);
    } else {
      setCheckValidName(false);
    }
    setName(value);
  };

  const handleEmail = (e) => {
    let value = e.target.value.trim().toLowerCase();
    const words = value.split(" ");
    value = words.join("");
    if (isValidEmail(value)) {
      setCheckValidEmail(true);
      validateSafleEmail(value);
    } else setCheckValidEmail(false);
    setEmail(value);
  };

  const validateSafleEmail = async (email) => {
    setCheckSafleEmail(true);
    const response = await userActions.validateSafleEmail(email);
    setCheckSafleEmail(false);
    if (response.statusCode === 200) {
      setCheckValidEmail(!response.data.isRegistered);
      setCheckEmailAvailable(!response.data.isRegistered);
    }
  };
  const handlePassword = (e) => {
    let value = e.target.value.trim();
    const words = value.split(" ");
    value = words.join("");

    if (/[a-z]/.test(value)) setCheckOneLowerChar(true);
    else setCheckOneLowerChar(false);

    if (/[A-Z]/.test(value)) setCheckOneUpperChar(true);
    else setCheckOneUpperChar(false);

    if (/\d/.test(value)) setCheckOneNumber(true);
    else setCheckOneNumber(false);

    if (value.length >= min_pass_lenght) setCheckMinLength(true);
    else setCheckMinLength(false);

    if (value.length <= 32) {
      setCheckMaxLength(true);
    } else {
      setCheckMaxLength(false);
    }

    if (value === confirmPassword && value) setCheckConfirmPassword(true);
    else setCheckConfirmPassword(false);

    setPassword(value);
  };

  const handleConfirmPassword = (e) => {
    let value = e.target.value.trim();
    const words = value.split(" ");
    value = words.join("");
    if (value === password && value) setCheckConfirmPassword(true);
    else setCheckConfirmPassword(false);
    setConfirmPassword(value);
  };

  const handleTyc = (e) => {
    let value = e.target.checked ? e.target.value : 0;
    setTyc(parseInt(value));
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const slides = [
    {
      title: "Wallets",
      description: "Your SafleID is your metaverse identity",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide2@2x.png`,
    },
    {
      title: "Keyless Transactions",
      description:
        "Sign transactions on the go, with ease using PIN or Biometrics",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide3@2x.png`,
    },
    {
      title: "Blackbox",
      description: "6 layers of encryption to your digital assets",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide1@2x.png`,
    },
  ];

  const CopyTooltipEl = () => (
    <div
      title="Copy address to clipboard"
      className="ml-auto flex items-center justify-center pr-3 pl-3 bg-gray-700 cursor-pointer rounded-r-md"
    >
      <TooltipCopy
        extraClasses="tooltip cursor-pointer w20 svg_no_margin"
        tooltipTextIn={tooltipTextIn}
        tooltipTextInChange={tooltipTextInChange}
        setCopiedText={setCopiedText}
        hideAfter={hideTooltipAfter}
        copiedText={copiedText}
      />
    </div>
  );

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>

          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <Preloader />
            <div className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24">
              <div className="flex">
                <div className="w-full lg:w-2/5 lg:mr-12">
                  <div className="mb-8">
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                    <p className="text-xs text-gray-400 flex items-center fw-500 pt--14">
                      Create an account
                      <span className="minus-dash"></span>
                      {/* <MinusIcon className='w-4 h-4 ml-2' /> */}
                    </p>
                  </div>
                  <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change">
                    {/* Welcome! Join the revolution of IDs */}
                    Join the revolution
                  </h1>
                  <InputGroup
                    label="Your SafleID *"
                    placeholder="Enter safleID"
                    id="safleid"
                    name="safleid"
                    icon={
                      <UserIcon
                        className="h-5 w-5 text-gray-400 icon_dark_gray"
                        aria-hidden="true"
                      />
                    }
                    value={safleId}
                    valid={isValidSafleId}
                    check={checkSafleId}
                    onBlur={validateSafleId}
                    onKeyUp={handleSafleId}
                    onChange={handleSafleId}
                    errorMsg={safleError}
                  />
                  {copietInputName === "safleid" && <CopyTooltipEl />}

                  <div className="flex-grow justify-between grid gap-4 grid-cols-2">
                    <div>
                      <InputGroup
                        label="Your Name *"
                        placeholder="Enter full name"
                        name="name"
                        type="text"
                        value={name}
                        valid={checkValidName}
                        onChange={handleName}
                        errorMsg={"Name must be valid"}
                      />
                      {copietInputName === "name" && <CopyTooltipEl />}
                    </div>
                    <div>
                      <InputGroup
                        label="Your Email *"
                        placeholder="Enter your email ID"
                        name="email"
                        type="text"
                        value={email}
                        valid={checkValidEmail}
                        check={checkSafleEmail}
                        onChange={handleEmail}
                        errorMsg={
                          checkEmailAvailable
                            ? email?.length < 256
                              ? "Email must be valid"
                              : "Email cannot exceed 256 characters."
                            : "Email has been already registered with us. Please use a different email to sign up"
                        }
                      />
                      {copietInputName === "email" && <CopyTooltipEl />}
                    </div>
                  </div>
                  <InputGroup
                    label="Password *"
                    placeholder="Enter password"
                    name="pass"
                    type={passwordShown ? "text" : "password"}
                    hiddenEye={
                      <EyeOffIcon
                        onClick={() => setPasswordShown(true)}
                        className={
                          passwordShown
                            ? "h-5 w-5 text-gray-400 hidden"
                            : "h-5 w-5 text-gray-400"
                        }
                        aria-hidden="true"
                      />
                    }
                    visibleEye={
                      <EyeIcon
                        onClick={() => setPasswordShown(false)}
                        className={
                          !passwordShown
                            ? "h-5 w-5 text-gray-400 hidden"
                            : "h-5 w-5 text-gray-400"
                        }
                        aria-hidden="true"
                      />
                    }
                    icon={
                      <LockClosedIcon
                        className="h-5 w-5 text-gray-400 icon_dark_gray"
                        aria-hidden="true"
                      />
                    }
                    value={password}
                    valid={
                      checkOneLowerChar &&
                      checkOneUpperChar &&
                      checkOneNumber &&
                      checkMinLength &&
                      checkMaxLength
                    }
                    onChange={handlePassword}
                    errorMsg={
                      !checkMaxLength &&
                      "Password should be contain only 8 to 32 characters"
                    }
                  />
                  <InputGroup
                    label="Confirm Password *"
                    placeholder="Confirm Password"
                    name="repeat_pass"
                    type={confirmPasswordShown ? "text" : "password"}
                    hiddenEye={
                      <EyeOffIcon
                        onClick={() => setConfirmPasswordShown(true)}
                        className={
                          confirmPasswordShown
                            ? "h-5 w-5 text-gray-400 hidden"
                            : "h-5 w-5 text-gray-400"
                        }
                        aria-hidden="true"
                      />
                    }
                    visibleEye={
                      <EyeIcon
                        onClick={() => setConfirmPasswordShown(false)}
                        className={
                          !confirmPasswordShown
                            ? "h-5 w-5 text-gray-400 hidden"
                            : "h-5 w-5 text-gray-400"
                        }
                        aria-hidden="true"
                      />
                    }
                    icon={
                      <LockClosedIcon
                        className="h-5 w-5 text-gray-400 icon_dark_gray"
                        aria-hidden="true"
                      />
                    }
                    value={confirmPassword}
                    valid={checkConfirmPassword}
                    onChange={handleConfirmPassword}
                    errorMsg={"Confirm Password dosen't match the Password"}
                  />
                  <div className="my-2 justify-between grid grid-cols-2">
                    <div className="flex items-center">
                      <div className="h-4 w-4 flex items-center justify-center">
                        {checkOneLowerChar ? (
                          <CheckCircleIcon
                            className={"h-4 w-4 text-blue-500"}
                          />
                        ) : (
                          <div
                            className={
                              "h-3 w-3 border border-gray-400 rounded-full"
                            }
                          ></div>
                        )}
                      </div>
                      <div className="ml-1 text-sm">
                        <p
                          className={
                            checkOneLowerChar
                              ? " checkbox_label checked "
                              : "checkbox_label "
                          }
                        >
                          One lowercase character
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="h-4 w-4 flex items-center justify-center">
                        {checkOneUpperChar ? (
                          <CheckCircleIcon
                            className={"h-4 w-4 text-blue-500"}
                          />
                        ) : (
                          <div
                            className={
                              "h-3 w-3 border border-gray-400 rounded-full"
                            }
                          ></div>
                        )}
                      </div>
                      <div className="ml-1 text-sm">
                        <p
                          className={
                            checkOneUpperChar
                              ? "checkbox_label checked "
                              : "checkbox_label "
                          }
                        >
                          One uppercase character
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2 justify-between grid grid-cols-2">
                    <div className="flex items-center">
                      <div className="h-4 w-4 flex items-center justify-center">
                        {checkOneNumber ? (
                          <CheckCircleIcon
                            className={"h-4 w-4 text-blue-500"}
                          />
                        ) : (
                          <div
                            className={
                              "h-3 w-3 border border-gray-400 rounded-full"
                            }
                          ></div>
                        )}
                      </div>
                      <div className="ml-1 text-sm">
                        <p
                          className={
                            checkOneNumber
                              ? "checkbox_label checked "
                              : "checkbox_label "
                          }
                        >
                          One number
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="h-4 w-4 flex items-center justify-center">
                        {checkMinLength ? (
                          <CheckCircleIcon
                            className={"h-4 w-4 text-blue-500"}
                          />
                        ) : (
                          <div
                            className={
                              "h-3 w-3 border border-gray-400 rounded-full"
                            }
                          ></div>
                        )}
                      </div>
                      <div className="ml-1 text-sm">
                        <p
                          className={
                            checkMinLength
                              ? "checkbox_label checked "
                              : "checkbox_label "
                          }
                        >
                          {min_pass_lenght} characters minimum
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        value={1}
                        onChange={handleTyc}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm mb-4">
                      <label htmlFor="offers" className=" terms_and_pp">
                        Creating an account means you're ok with our
                        <a
                          className="blue_text px-1"
                          href={process.env.REACT_APP_LINK_TERMS}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Service
                        </a>
                        and
                        <a
                          className="blue_text px-1"
                          href={process.env.REACT_APP_LINK_PRIVACY}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </label>
                    </div>
                  </div>
                  <Button
                    text="Submit"
                    size="lg"
                    disabled={
                      !(
                        tyc === 1 &&
                        checkOneLowerChar &&
                        checkOneUpperChar &&
                        checkOneNumber &&
                        checkMinLength &&
                        checkConfirmPassword &&
                        isValidSafleId &&
                        checkValidEmail &&
                        checkValidName &&
                        checkMaxLength
                      )
                    }
                    fullwidth
                    style={{
                      opacity: !(
                        tyc === 1 &&
                        checkOneLowerChar &&
                        checkOneUpperChar &&
                        checkOneNumber &&
                        checkMinLength &&
                        checkConfirmPassword &&
                        isValidSafleId &&
                        checkValidEmail &&
                        checkValidName &&
                        checkMaxLength
                      )
                        ? "0.35"
                        : "1",
                    }}
                    onClick={(e) => {
                      handleSubmit();
                    }}
                  />
                  <div className="my-6 flex justify-center md:justify-start text-xs mt-6 dont_have_account">
                    <span>Already have an account?</span>
                    <Link
                      to="/"
                      className="flex items-center dont_have_account_link"
                    >
                      Sign In
                      <ArrowRightIcon className="h-2 w-2 ml-1" />
                    </Link>
                  </div>
                </div>

                <div className="justify-center w-0.5 border_between_cols lg:my-20 bg-gray-300 mx-10 hidden lg:block sign_up_middle_spacer"></div>
                <div className="lg:block w-2/5 slider-size-fix lg:ml-12 flex justify-center add_to_chrome">
                  <div className="mb-20 flex justify-end">
                    <span
                      to={URLS.FORGOT_PASSWORD}
                      className="flex items-center text-blue-500 font-medium text-xs cursor-pointer"
                      onClick={() => setModalIsOpen(true)}
                    >
                      Add to Chrome
                      <ArrowRightIcon className="h-2 w-2 ml-1" />
                    </span>
                  </div>
                  <div className="w-full mx-auto">
                    <Slider
                      ref={(slider) => slider}
                      {...carouselSettings}
                      className="flex justify-center slick_sl"
                    >
                      {slides.map((slide, idx) => (
                        <div className="" key={idx}>
                          <div className="relative">
                            <img
                              alt="slide-1"
                              src={slide.image}
                              className="mx-auto mb-10 h-60"
                            />
                          </div>
                          <div className="flex flex-col justify-end">
                            <p className="text-2xl font-semibold text-center mb-2 slide_title">
                              {slide.title}
                            </p>
                            <p className="text-center text-sm w-auto slide_desc">
                              {slide.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="rocket"
              src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
              className="h-32 w-60 absolute -right-16 bottom-0 z-10 hidden md:block"
            />
          </div>
          <Footer />
        </div>
      </div>
      <Toast
        title=""
        bodyText={toastMessage}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
      />
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Download"
        callToAction={() => {
          window.open(process.env.REACT_APP_EXTENSION_LINK);
        }}
        extraClass={"install_safle_chrome"}
        isOpen={modalIsOpen}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};

export default SignUp;
