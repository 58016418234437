import React, { useEffect }  from 'react';

import ModalEditWalletLabel from './../../dialogs/ModalEditWalletLabel';



import useModalNavigation , { useModalData } from '../../../helpers/useModalNavigation';
// import { changeWalletLabel} from '../../../helpers/walletHelpers';
import { useUser } from '../../../helpers/UserProvider';
import { EDIT_LABEL_WALLET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';



const ModalEditWalletLabelFunctions = () => {
    // eslint-disable-next-line no-unused-vars
    
    const [ setActiveModalNav, goNext , goBackward ] = useModalNavigation(EDIT_LABEL_WALLET_FLOW);
    const { userData, userActions } = useUser();
    const [ modalData, setModalData ] = useModalData();
    const onClose = () => {
        // setActiveModalNav('CLOSED');
        setActiveModalNav('modal_edit_label_cancel_wallet', true);
    };
    const callbackOpenPopup = (label) => {
        // setActiveModalNav(popup);
        setModalData({newLabel: label});
        setActiveModalNav('modal_edit_wallet_enter_pin');
        
        
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav('modal_edit_label_cancel_wallet', true);
    };

    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return (
        <ModalEditWalletLabel 
            onClose={onClose}
            callbackOpenPopup= {callbackOpenPopup}
            callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
        />
    );
}

export default ModalEditWalletLabelFunctions;