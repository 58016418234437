import React, { useState, useEffect } from "react";
import QRCode from "qrcode";

import Toast from "./../../../components/registration/Toast/Toast";

import Modal from "./../../../components/registration/Modal/Modal";
import TooltipCopy from "./../../../components/registration/TooltipCopy";
import ModalReceive from "./../../../components/registration/ModalReceive/ModalReceive";
import "./../../../styles/registration/privateKeyCard.scss";
import "./../../../styles/registration/publicAddressCard.scss";

const PublicAddressCard = ({ publicAddress, safleID }) => {
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modaReceivelIsOpen, setModalReceiveIsOpen] = useState(false);
  const generateQrAddress = async () => {
    var durl = await QRCode.toDataURL(`ethereum:${publicAddress}`, {
      errorCorrectionLevel: "L",
      mode: "alphanumeric",
      margin: 0,
    });
    // $el.find('.qr_area').html(`<img src="${durl}" alt="scan this qr code" />`);
    setQrImage(durl);
    setModalReceiveIsOpen(true);
  };
  //remove afterwards , visible if access directly for debuging

  const tooltipText = "";
  // const tooltipIcon = `${process.env.PUBLIC_URL}/images/registration/img/file-icon.svg`;
  const tooltipTextIn = "Copy to clipboard";

  // const TooltipIconSvg = () => {
  //   return <span className="icon_copy_p_address icon-copy-90"></span>
  // }

  const TooltipIconSvg = () => {
    return (
      <img
        src="/images/registration/icons/copy_icon_gray.svg"
        alt="Copy Icon"
        className="icon_copy_p_address icon-copy-90"
      />
    );
  };

  const tooltipTextInChange = "Copied to clipboard";

  /*overflow-hidden to class brakes the tooltip*/
  return (
    <div className="mt-8 mb-4 wallet_details_bg zindex10">
      <div className="px-4 py-5 sm:p-6 flex">
        <div className="w-5/6">
          <div className="flex items-center mb-2">
            <img
              alt="pub-icon"
              src={`${process.env.PUBLIC_URL}/images/registration/img/pub-icon.svg`}
            />
            <h5 className="wallet_details_headings ml-4">Public Address</h5>
          </div>
          {/* <p className='text-xs text-start'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id
            cursus.
          </p> */}
          <p className="text-start flex mt-6 p_address ">{publicAddress}</p>
        </div>
        <div className="w-1/6">
          <div className="flex flex-col">
            <a
              className="cursor-pointer icon_open_addr_modal"
              onClick={(e) => {
                e.preventDefault();
                generateQrAddress();
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/registration/img/copy_to_clipboard.svg`}
              />
              {/* <span className="icon-scan-18"></span> */}
            </a>
            <TooltipCopy
              extraClasses="tooltip_registration flex justify-end pr-1 cursor-pointer mleft_auto "
              textToDisplay={tooltipText}
              imageToDisplay={<TooltipIconSvg />}
              tooltipTextIn={tooltipTextIn}
              tooltipTextInChange={tooltipTextInChange}
              copiedText={publicAddress}
              itemProps={publicAddress}
              hideAfter="3000"
            />
          </div>
        </div>
      </div>
      <Toast
        title=""
        bodyText={toastMessage}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
      />

      <ModalReceive
        title="Wallet details"
        safleID={safleID}
        // safleID={'devreg310'}
        publicAddress={publicAddress}
        // publicAddress={'0x31ef9e7d1b2d2d64f79a14cc26d7ac938c54c1e5'}
        qrImage={qrImage}
        isOpen={modaReceivelIsOpen}
        setOpen={(val) => setModalReceiveIsOpen(val)}
      />
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText={qrImage}
        extraClass={"install_safle_chrome"}
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Install"
        isOpen={modalIsOpen}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};
export default PublicAddressCard;
