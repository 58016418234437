/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import ModalAssetReceive from "./../../dialogs/ModalAssetReceive";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  RECEIVED_ASSET_FLOW,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
// import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";

const ModaSendAssetTransactionFunctions = (props) => {
  // const { userActions } = useUser();
  const { appData } = useApp();
  const [items, setItems] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] =
    useModalNavigation(RECEIVED_ASSET_FLOW);
  const [modalData, setModalData] = useModalData();
  console.log(modalData);

  const onClose = () => {
    setActiveModalNav("CLOSED");
    // setActiveModalNav('modal_receive_asset_cancel',true);
  };
  // const callbackOpenPopup = (popup) => {
  //   setActiveModalNav(popup);
  // };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //
  const load_wallets = async () => {
    if (appData?.accounts?.length > 0) {
      let acc;
      //
      if (modalData?.symbol === "BTC") {
        acc = await appData?.accounts?.filter(
          (account) => account.type === "bitcoin"
        );
      } else {
        acc = await appData?.accounts?.filter(
          (account) => account.type === "evm"
        );
      }
      const addrs = acc ? Object.values(acc).map((e) => e.address) : [];
      setItems(addrs[0]);
    }
  };
  useEffect(() => {
    load_wallets();
  }, [appData?.accounts]);

  return (
    <ModalAssetReceive
      items={items}
      onClose={onClose}
      useModalData={false}
      // callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      modalCancel_label="modal_receive_asset_cancel"
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModaSendAssetTransactionFunctions;
