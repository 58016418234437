import React from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import '../../styles/registration/signIn.scss';
import './../../../styles/registration/signInSlider.scss';

const slides = [
  {
    title: 'Wallets',
    description: 'Your SafleID is your metaverse identity',
    image: `${process.env.PUBLIC_URL}/images/migration/img/login-slide2@2x.png`,
  },
  {
    title: 'Keyless Transactions',
    description:
      'Sign transactions on the go, with ease using PIN or Biometrics',
    image: `${process.env.PUBLIC_URL}/images/migration/img/login-slide3@2x.png`,
  },
  {
    title: 'Blackbox',
    description: '6 layers of encryption to your digital assets',
    image: `${process.env.PUBLIC_URL}/images/migration/img/login-slide1@2x.png`,
  },

]

const SignInSlider = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <Slider
      ref={(slider) => slider}
      {...carouselSettings}
      className='flex justify-center slick_sl'
    >
      {slides.map((slide, idx) => (
        <div className='' key={idx}>
          <div className='relative test-class2'>
            <img
              alt='slide-1'
              src={slide.image}
              className='mx-auto mb-10 h-60'
            />
          </div>
          <div className='flex flex-col justify-end'>
            <p className='text-2xl font-semibold text-center mb-2 slide_title'>
              {slide.title}
            </p>
            <p className='text-center text-sm w-auto slide_desc'>{slide.description}</p>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default SignInSlider
