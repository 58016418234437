/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import useModalNavigation from "../../../helpers/useModalNavigation";
import "./../../../styles/registration/popupMessage.scss";
import { ADD_WALLET_FLOW } from "../../../constants/ui";
// import helpers/users
import CssFix from "./../../../helpers/cssFix";
const PopupMessage = ({ history, saffleId, title, onClose }) => {
  const { userActions } = useUser();
  const { appData, actions } = useApp();
  const chains = [
    "ethereum",
    "polygon",
    "arbitrum",
    "optimism",
    "bsc",
    "velas",
    "mantle",
    "bitcoin",
  ];

  const [setActiveModalNav] = useModalNavigation(ADD_WALLET_FLOW);
  const [loading, setLoading] = useState(true);

  const logoutUser = async () => {
    await actions.logout();
    await userActions.logout();
  };

  useEffect(() => {
    //timer
    setLoading(true);

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="min-h-[100%] w-[100%] absolute top-5 left-0 flex items-center justify-center">
      <div
        className="coming_soom"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "84vw",
          left: "50%",
          top: "50%",
          zIndex: 10000,
          background: "rgba(255,255,255,1)",
          borderRadius: 15,
          maxWidth: 1170,
        }}
      >
        <div className="innerFixFixed">
          <CssFix />

          <img
            style={{
              position: "absolute",
              width: "15.7vw",
              height: "auto",
              right: "-5vw",
              bottom: "5vw",
              maxWidth: 238,
            }}
            alt="rocket"
            src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
          />

          <div className="flex flex-row w-[100%] justify-end items-center mt-[10px] py-[10px]">
            <div
              className=" flex flex-grow justify-center items-center"
              style={{ alignSelf: "center", right: "70px", top: "50px" }}
            >
              <img
                style={{ width: "72px", height: "30px", marginBottom: "0px" }}
                alt="safle logo"
                src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
              />
            </div>
            <div
              className="popup_logout"
              style={{
                position: "absolute",
                right: "30px",
                top: "20px",
                cursor: "pointer",
                color: "#0d203e",
                fontWeight: "600",
              }}
              onClick={
                process.env.REACT_APP_ALLOW_BETA_ACCESS === "allowed"
                  ? onClose
                  : logoutUser
              }
            >
              {process.env.REACT_APP_ALLOW_BETA_ACCESS === "allowed" && (
                <span className="icon_pop_msg_close icon-close"></span>
              )}
              {process.env.REACT_APP_ALLOW_BETA_ACCESS !== "allowed" && (
                <span style={{ fontSize: "14px" }}>Logout</span>
              )}
            </div>
          </div>
          <div
            style={{
              margin: "auto 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="popup_message">
              <div>
                <div
                  className="popup_text fadein"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    wordBreak: "break-all",
                  }}
                >
                  <span
                    className="popup_safleid"
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      backgroundColor: "#fff",
                      backgroundImage:
                        "linear-gradient(45deg, #32c5ff, #b620e0, #f7b500)",
                      backgroundSize: "100%",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    {saffleId}
                  </span>
                  <div className="flex flex-row items-center">
                    <h3
                      className="popup_title"
                      style={{
                        fontSize: "23px",
                        fontWeight: "600",
                        color: "#0D203E",
                        letterSpacing: "-0.3px",
                        marginBottom: "5px",
                      }}
                    >
                      {title}
                    </h3>
                    <span>
                      <img
                        src="/images/wallets/rocket.svg"
                        className="ml-1 mb-[1rem] h-[1.5rem] w-[1.5rem]"
                        alt="rocket"
                      />
                    </span>
                  </div>
                  <hr />
                </div>
                <div className="title !text-[#0669F8]">What’s new ?</div>
                <ul>
                  <li>
                    Safle now supports Bitcoin! You can generate your Bitcoin
                    wallet and start using it.
                  </li>
                  {/* <li>Manage your asset watch list.</li>
                <li>Send and Receive tokens.</li> */}
                </ul>
                <div className="popup_networks">
                  <span className="flex flex-col flex-wrap">
                    <div className="text-[#0669F8]">Supported Networks:</div>
                    <div className="flex flex-wrap mt-[15px]">
                      {chains?.map((chain) => {
                        return (
                          <span className="network_item ml-3">
                            <img
                              src={
                                chain === "bitcoin"
                                  ? "/images/wallets/bitcoin-logo.svg"
                                  : appData.chains.find(
                                      (item) => item.chain_name === chain
                                    )?.details?.imageURL
                              }
                              alt="chain icon"
                            />
                            <span>
                              {chain === "bsc"
                                ? chain?.toUpperCase()
                                : chain?.charAt(0).toUpperCase() +
                                  chain?.slice(1)}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="fadein social-icons"
              style={{
                marginTop: "10px",
                width: "50%",
                paddingTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <a
                href={`${process.env.REACT_APP_LINK_TWITTER}`}
                style={{ margin: "0 5px" }}
                target="_blank"
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="Twitter logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/icons/twitter-icon.svg`}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_LINK_MEDIUM}`}
                style={{ margin: "0 5px" }}
                target="_blank"
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="Medium logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/icons/medium-icon.svg`}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_LINK_DISCORD}`}
                style={{ margin: "0 5px" }}
                target="_blank"
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="Discord logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/icons/discord-icon.svg`}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_LINK_INSTAGRAM}`}
                style={{ margin: "0 5px" }}
                target="_blank"
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="Instagram logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/icons/instagram-icon.svg`}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_LINK_TELEGRAM}`}
                style={{ margin: "0 5px" }}
                target="_blank"
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  alt="Telegram logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/icons/telegram-icon.svg`}
                />
              </a>
            </div>

            <button
              className={`get-safle-btn fadein inline-flex px-4 py-2 text-base justify-center  items-center border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none ${
                appData?.loading ? "cursor-progress" : "cursor-pointer"
              }`}
              style={{ marginTop: "2%" }}
              disabled={loading}
              onClick={() => {
                onClose();
                setActiveModalNav("modal_generate_new_wallet");
              }}
              // href={`${process.env.REACT_APP_EXTENSION_LINK}`}
              // target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px", marginRight: "12px" }}
                alt="Medium logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/safle-logo-initials.svg`}
              />
              Generate Bitcoin Wallet Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMessage;
