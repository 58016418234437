import { useEffect, useState } from "react";

import TokenIcon from "../../common/TokenIcon/TokenIcon";
import AssetBadge from "../../common/AssetBadge/AssetBadge";
import {
  copyToClipboard,
  middleEllipsis,
} from "./../../../helpers/generalHelper";
import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
import RecentActivityIcon from "../../common/RecentActivityIcon/RecentActivityIcon";
import RecentActivityStatus from "../../common/RecentActivityStatus/RecentActivityStatus";
import RecentActivityDetails from "../../common/RecentActivityDetails/RecentActivityDetails";

const ActivityHistoryItemFake = (props) => {
  const item = props.item;
  // const [isToggleActive, setToggleActive] = useState(false);
  const handleToggle = (e) => {
    // setToggleActive(!isToggleActive);
    props.clickHandle();
  };

  useEffect(() => {}, []);
  const { userData, userActions } = useUser();
  const { appData, actions } = useApp();
  //const icon = item.logo;

  return (
    <div
      className={
        "assets__overview_content-filtered_items_row " +
        (props.highlight ? "row_visible" : "row_hidden")
      }
    >
      {/*
                default
                partner
                */}
      <RecentActivityDetails
        type={
          item?.type === "swap"
            ? "swap"
            : item?.type === "BUY" || item?.type === "SELL"
            ? "partner"
            : item?.type === "receive" || item?.type === "send"
            ? "default"
            : "contract-call"
        }
        txType={item?.type}
        address={item?.type !== "swap" && item?.viewDetails?.address}
        explorerLink={item?.viewDetails?.hash}
        swapDetails={item?.type === "swap" && item}
      />
    </div>
  );
};

export default ActivityHistoryItemFake;
