import React, { useState, useEffect, useRef } from "react";
import AssetsTheadFilterItems from "../AssetsTheadFilterItems/AssetsTheadFilterItems";
// import { useApp } from "../../../helpers/AppProvider";

const AssetsTheadFilter = (props) => {
  // const { appData, setAppData } = useApp();

  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  // const [dropdown, setDropdown] = useState(false);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes =
    "main__controls controls__dropdown controls__dropdown-filters";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        if (props?.noBlockScroll === false) {
          //in popup dont remove add class
          // setAppData({ blockScroll: false });
        } else {
          // setAppData({ blockScroll: false });
        }
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);

      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // setAppData({ blockScroll: false });
      } else {
        // setAppData({ blockScroll: true });
      }
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // setAppData({ blockScroll: false });
      } else {
        // setAppData({ blockScroll: false });
      }

      // document.body.classList.remove('no_scroll_dropdown');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    // add class with selected
    setHighlightedItem(index);

    props.items[index]?.filter_callback();
    // set the state for the dropdown with last value
    // setDropdown(addr);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    if (props?.noBlockScroll === false) {
    } else {
      //   setAppData({ blockScroll: false });
    }
    // document.body.classList.remove('no_scroll_dropdown');
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(items.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //items[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, items[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  const items = props.items;
  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-filters-dropdown"
        onClick={onClick}
      >
        <div className="network-new-name-content">
          <span className="drp_name filter_name">{props.filterName}</span>
        </div>
        <div className={"filter_arrows"}>
          <span className="icon-down icon-sort-down-solid"></span>
          <span className="icon-up  icon-sort-up-solid"></span>
        </div>
      </div>
      <div className="dropdown__list__items">
        {items.length &&
          items.map((items, index) => (
            <AssetsTheadFilterItems
              items={items}
              highlight={index === highlighted}
              key={index}
              clickHandle={() => onClickItem(index, items)}
            />
          ))}
      </div>
    </div>
  );
};

export default AssetsTheadFilter;
