import React, { useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";

import ModalPinConfirmFunctions from "./../../common/SettingsChangePinProcess/ModalPinConfirmFunctions";
import ModalChangePinUpdatedFunctions from "./../../common/SettingsChangePinProcess/ModalChangePinUpdatedFunctions";
import ModalChangePinFailedFunctions from "./../../common/SettingsChangePinProcess/ModalChangePinFailedFunctions";
import ModalChangePinCancelFunctions from "./../../common/SettingsChangePinProcess/ModalChangePinCancelFunctions";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import { CHANGE_PIN_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";

import useModalNavigation from "../../../helpers/useModalNavigation";

const SettingsChangePinProcess = (props) => {
  const { userData } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      wallets = [],
    },
    setAppData,
  } = useApp() || {};

  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(CHANGE_PIN_FLOW);

  const onChangePinUpdate = (e) => {
    setActiveModalNav("CLOSED");
    props.onCompletProccess && props.onCompletProccess();
  };
  const onCancel = (e) => {
    setActiveModalNav("CLOSED");
    props.onCancelProccess && props.onCancelProccess();
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  return (
    <>
      {
        // modal order:
        // ModalPinConfirm -- modal_email_enter_pin
        // ModalActionSuccess -- modal_email_updated
        // ModalActionFailed -- modal_email_address_failed
        // ModalActionConfirm -- modal_cancel_email_address
      }

      {activeModalLabel === "modal_change_pin_enter_pin" && (
        <ModalPinConfirmFunctions callbackOpenPopup={() => goNext()} />
      )}
      {activeModalLabel === "modal_change_pin_updated" && (
        <ModalChangePinUpdatedFunctions onChangePinUpdate={onChangePinUpdate} />
      )}
      {activeModalLabel === "modal_change_pin_failed" && (
        <ModalChangePinFailedFunctions />
      )}
      {activeModalLabel === "modal_change_pin_cancel" && (
        <ModalChangePinCancelFunctions onCancelProccess={onCancel} />
      )}
    </>
  );
};

export default SettingsChangePinProcess;
