import React from 'react';
import MainModal from '../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PinInput from '../common/PinInput/PinInput';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalChangeIDTxnDone = ( { onClose } ) => {

    return (
        <MainModal extraClasses={ [ "modal_w440", "modal_mh400", "modal_dflex", "modal_changeid_txn_done" ] } onClose={ onClose } dataClass="modal_changeid_txn_done">
            <div className="modal_succes_content">
                <div className="modal--padding">
                    <ModalHeadIcon img="images/wallets/icon-done.svg" />
                    <div className="modal_succes_head ">Transaction successful!</div>
                    <div className="modal_succes_desc">
                        <span className="simple_text simple_text_centered">You have successfully pushed your SafleID to the <br/> blockchain. You can now use your new SafleID.</span>
                    </div>
                    <div className="modal_id_colored">apoorv</div>
                    <div className="txn_hash_label">Txn hash</div>
                    <div className="txn_hash_input">0xcD9z3198789olp09879oklp098u</div>
                </div>
                <div className="modal_succes_content_actions">
                    <PrimaryBtn
                        classes="btn-default btn-blue modal__trigger"
                        label="Done"
                    />
                    <a href="#">Check on blockchain explorer <span><img src="images/settings/explorer-icon.svg" alt="" /></span></a>
                </div>
            </div>
        </MainModal>
    );
};

export default ModalChangeIDTxnDone;
