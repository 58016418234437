
const GuardianSetupContent = () => {

  return (
    <div className="settings__overview_inner_content white_bg settings__overview_guardian" data-show="guardian_setup">
      <div className="mobile_screen_title">Guardian setup</div>
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_guardian_setup">
          <span className="msbico-planet_guardian" />
          <span className="coming_soon">Coming Soon</span>
          <div className="coming_soon_msg">We will notify you once this feature is ready 🙌</div>
        </div>
      </div>
    </div>
  );
};

export default GuardianSetupContent;