import React, { useEffect } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";

const ModalEnterPinFunctions = () => {
  const { userActions } = useUser();
  const { appData, actions } = useApp();

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setData] = useModalData();
  const onClose = () => {
    setActiveModalNav("modal_remove_cancel_wallet", true);
  };
  const callbackOpenPopup = async (pin) => {
    actions.setLoading(true);
    const pinNo = pin;

    const accountType = appData?.accounts?.find(
      (account) => account?.address === modalData?.address
    )?.type;

    const resp = await userActions.removeWallet(
      accountType,
      modalData.address,
      pinNo
    );

    if (resp === true) {
      //setTimeout( async () => {
      setActiveModalNav("modal_remove_success");
      actions.setLoading(false);
      //}, 200 );
    } else {
      actions.setLoading(false);
      setActiveModalNav("modal_remove_failed_wallet");
    }
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav("modal_remove_cancel_wallet", true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  return (
    <ModalEnterPin
      onClose={onClose}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalEnterPinFunctions;
