import React, { useState } from "react";
const InputEmail = ({
  placeholder = "Enter Address",
  label,
  value,
  onChange,
  errorMsg,
  successMsg,
  checkButton,
  checkValueCallback,
  disabled,
}) => {
  const [checkingValue, setCheckingValue] = useState("");

  return (
    <div className="form__input form__input_default from_input_send_to">
      <span className="input_label" style={{ fontWeight: 500, fontSize: 12 }}>
        {label}
      </span>
      <div className="form__input_default relative flex">
        <input
          type="text"
          className={`form-input-default ${
            successMsg
              ? "!border !border-[#00D192]"
              : errorMsg
              ? "!border !border-[#FF0000]"
              : ""
          }`}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange(e);
            if (checkButton) {
              setCheckingValue(e.target.value);
            }
          }}
        />
        {errorMsg && (
          <span className="absolute pt-16 text-red-500 text-sm">
            {errorMsg}
          </span>
        )}
        {successMsg && (
          <span className="absolute pt-16 text-green-500 text-sm">
            {successMsg}
          </span>
        )}

        {checkButton && (
          <div className="check-button-container">
            <button
              className="py-0 btn-default btn-blue btn-small h-[29px] w-[88px] rounded-3xl"
              disabled={!checkingValue}
              onClick={() => {
                if (checkingValue) {
                  checkValueCallback(checkingValue);
                }
              }}
            >
              {checkButton}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InputEmail;
