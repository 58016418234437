import { useCallback } from "react";
import { useEffect, useState } from "react";

const TokenIcon = ( { logo } ) => {
  const [ hasLogo, setHasLogo ] = useState( false );
  const [loading, setLoading] = useState( true );
  useEffect( () => {
    logo && setHasLogo(logo);
    setLoading(false);
  }, []);

  return (
    <>
      <div className="asset__coin-image">
       {!loading ? <div className="asset__coin-image-icon" style={ {
          backgroundSize: '100% auto' ,
          backgroundImage: `url(${ logo && hasLogo? logo.replace('github.com', 'raw.githubusercontent.com').replace('blob', '') : 'images/currency/coin.svg' })`,
          backgroundColor: logo && hasLogo? '' : '#EEE',
          // filter: logo && hasLogo? '' : 'grayscale(100)'
        } } /> :
        <div className="fade p-4 bg-white   rounded-xl z-10 flex flex-col items-center justify-center"> <img src="/images/registration/loading.svg" alt="loading" width={40}   /></div>}
      </div>
    </>
  );
};

export default TokenIcon;