import React, { useState, useEffect, useCallback } from "react";
import AddTokenItem from "../swap/AddTokenItem";
import MainModal from "./../generic/MainModal";
import { useApp } from "../../helpers/AppProvider";
import { delay_code } from "../../helpers/generalHelper";
const Swaps = require("@getsafle/safle-swaps-v2").Swaps;

const ModalSwapSelectToken = ({ onClose, isFromToken }) => {
  const { appData, actions } = useApp();
  const [searchToken, setTokenSearch] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [filteredData, setFilterData] = useState([]);

  useEffect(() => {
    let timer = setTimeout(async () => {
      if (searchText) {
        setLoading(true);
        setTokenSearch(searchText);

        if (data && data.length) {
          if (!searchText) {
            setFilterData(data);
          } else {
            const filtered = data.filter(
              (filterToken) =>
                filterToken.symbol &&
                filterToken.symbol
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            );
            setFilterData(filtered);
            setNoData(filtered.length);
          }
          setLoading(false);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    async function tokensDisplay() {
      setLoading(true);
      if (appData?.supportedTokens?.length) {
        if (!appData.supportedTokens.find((i) => i.fixedLogo)) {
          const withLogoData = await Promise.all(
            appData.supportedTokens.map(async (i) => {
              let item = i;
              if (i.fixedLogo) return i;
              if (!i.logoURI) {
                item = {
                  ...i,
                  logoURI: "images/currency/coin.svg",
                };
              }

              item = { ...item, fixedLogo: true };
              return item;
            })
          );
          setData(withLogoData);
          setFilterData(withLogoData);
          if (!appData.supportedTokens.find((i) => i.fixedLogo)) {
            actions.setAppData({ supportedTokens: withLogoData });
          }
        } else {
          setData(appData.supportedTokens);
          setFilterData(appData.supportedTokens);
        }
      } else {
        const activeChain =
          appData.chains && appData?.activeChain
            ? appData.chains.filter(
                (chain) => chain?.chainId === appData?.activeChain
              )[0]
            : appData.chains[0];
        const safleSwaps = new Swaps({
          dex: appData.activeDex,
          rpcURL: activeChain?.uri,
          chain: activeChain?.chain_name || "ethereum",
        });
        await delay_code(2000);

        const supportedTokens = await safleSwaps.getSupportedTokens();

        supportedTokens?.tokens?.forEach((token) => {
          token.logoURI = token?.logoURI?.replace(
            "https://tokens.1inch.io/",
            "https://tokens-data.1inch.io/images/"
          );
        });

        actions.setAppData({ supportedTokens: supportedTokens.tokens });
      }
      setLoading(false);
    }
    tokensDisplay();
  }, [appData.supportedTokens]);

  const isValidImage = async (logo) => {
    try {
      let res = await fetch(logo); //.then((res)=>{if(res.ok){ return  res}else{ throw new Error('Something went wrong.')}}).catch(e=> {throw new Error(e)})
      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const onClickItem = useCallback(
    (contractAddress) => {
      if (isFromToken) {
        if (appData.activeTokenB !== contractAddress) {
          actions.setAppData({ activeTokenA: contractAddress });
        } else {
          alert("Please select another token.");
        }
      } else {
        if (appData.activeTokenA !== contractAddress) {
          actions.setAppData({ activeTokenB: contractAddress });
        } else {
          alert("Please select another token.");
        }
      }
      onClose();
    },
    [isFromToken, actions, onClose, appData.activeTokenA, appData.activeTokenB]
  );

  return (
    <>
      <MainModal
        title="Select Token"
        onClose={onClose}
        extraClasses={["modal_w400 modal_mh368 modal_swap_select_token"]}
        dataClass="modal_swap_select_token"
      >
        <div className="form__inner_filter_assets">
          <div className="form__inner_filter_header">
            <div className="form__input form__input-search">
              <input
                type="text"
                className="form-input-default"
                placeholder="Search"
                onKeyUp={(e) => setSearchText(e.target.value)}
              />
              <span className="icon-search icon-search-black-24-dp"></span>
            </div>
          </div>
          <div className="form__input_filter_results form__input_filter_assets">
            {/* <div className="tokens_found">
                <AddTokenItem coinName="Binance" coinPrefix="BNB" coinLogo="images/swap/bnb-chain.svg" />
                <div className="tokens_found_title">Other Tokens</div>
            </div> */}
            <div className="token_list">
              {!loading &&
                filteredData.length > 0 &&
                filteredData?.map((item, key) => (
                  <AddTokenItem
                    key={key.toString()}
                    coinName={item.name}
                    coinPrefix={item.symbol}
                    coinLogo={item.logoURI}
                    onClickItem={() => onClickItem(item.address)}
                  />
                ))}
              {!loading && !noData ? (
                <div className="flex justify-center align-center">
                  <p className="text-sm text-gray-400 flex items-center fw-500 pt--14">
                    Oops! No Token with this name found.
                  </p>
                </div>
              ) : null}
              {loading && !filteredData.length ? (
                <div className="fade p-4 bg-white   rounded-xl z-10 flex flex-col items-center justify-center">
                  {" "}
                  <img
                    src="/images/registration/loading.svg"
                    alt="loading"
                    width={40}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </MainModal>
    </>
  );
};

export default ModalSwapSelectToken;
