const contractMetadata = {
    "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2": {
       "name": "SushiSwap",
       "logo": "sushi.svg",
       "erc20": true,
       "erc721": false,
       "symbol": "SUSHI",
       "decimals": 18
     },
     "0x2Ec3275f7aCe4044e499823F511cd58250be8E3d": {
       "name": "Privilege",
       "logo": "prvg.svg",
       "erc20": true,
       "symbol": "PRVG",
       "decimals": 8
     },
     "0x65BB569FAaDD324a00883FdE4c46346cc96D5c0A": {
       "name": "Provide",
       "logo": "prvd.svg",
       "erc20": true,
       "symbol": "PRVD",
       "decimals": 8
     },
     "0xB4272071eCAdd69d933AdcD19cA99fe80664fc08": {
       "name": "CryptoFranc",
       "logo": "XCHF.svg",
       "erc20": true,
       "symbol": "XCHF",
       "decimals": 18
     },
     "0x5aFE3855358E112B5647B952709E6165e1c1eEEe": {
       "name": "Safe Token",
       "logo": "safe-white-token.svg",
       "erc20": true,
       "symbol": "SAFE",
       "decimals": 18
     },
     "0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03": {
       "name": "Nouns",
       "erc20": false,
       "erc721": true,
       "symbol": "NOUN",
       "decimals": 0,
       "logo": "nouns.svg"
     },
     "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D": {
       "name": "BoredApeYachtClub",
       "erc20": false,
       "erc721": true,
       "symbol": "BAYC",
       "decimals": 0,
       "logo": "bayc.svg"
     },
     "0x08D7C0242953446436F34b4C78Fe9da38c73668d": {
       "name": "PROOF Collective",
       "erc20": false,
       "erc721": true,
       "symbol": "PROOF",
       "decimals": 0,
       "logo": "proof.svg"
     },
     "0x23581767a106ae21c074b2276D25e5C3e136a68b": {
       "name": "Moonbirds",
       "erc20": false,
       "erc721": true,
       "symbol": "MOONBIRD",
       "decimals": 0,
       "logo": "moonbirds.svg"
     },
     "0x60E4d786628Fea6478F785A6d7e704777c86a7c6": {
       "name": "MutantApeYachtClub",
       "erc20": false,
       "erc721": true,
       "symbol": "MAYC",
       "decimals": 0,
       "logo": "mayc.svg"
     },
     "0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B": {
       "name": "CloneX",
       "erc20": false,
       "erc721": true,
       "symbol": "CloneX",
       "decimals": 0,
       "logo": "clonex.svg"
     },
     "0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e": {
       "name": "Doodles",
       "erc20": false,
       "erc721": true,
       "symbol": "DOODLE",
       "decimals": 0,
       "logo": "doodles.svg"
     },
     "0xED5AF388653567Af2F388E6224dC7C4b3241C544": {
       "name": "Azuki",
       "erc20": false,
       "erc721": true,
       "symbol": "AZUKI",
       "decimals": 0,
       "logo": "azuki.svg"
     },
     "0xa3AEe8BcE55BEeA1951EF834b99f3Ac60d1ABeeB": {
       "name": "VeeFriends",
       "erc20": false,
       "erc721": true,
       "symbol": "VFT",
       "decimals": 0,
       "logo": "veefriends.svg"
     },
     "0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258": {
       "name": "Otherdeed",
       "erc20": false,
       "erc721": true,
       "symbol": "OTHR",
       "decimals": 0,
       "logo": "otherdeed.svg"
     },
     "0xBBc2AE13b23d715c30720F079fcd9B4a74093505": {
       "name": "Ethernity Chain Token",
       "logo": "ERN.svg",
       "erc20": true,
       "symbol": "ERN",
       "decimals": 18
     },
     "0xD057B63f5E69CF1B929b356b579Cba08D7688048": {
       "name": "CoW Protocol Virtual Token",
       "logo": "vCOW.svg",
       "erc20": true,
       "symbol": "vCOW",
       "decimals": 18
     },
     "0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB": {
       "name": "CoW Protocol Token",
       "logo": "COW.svg",
       "erc20": true,
       "symbol": "COW",
       "decimals": 18
     },
     "0xEd04915c23f00A313a544955524EB7DBD823143d": {
       "name": "Alchemy Pay",
       "logo": "ACH.svg",
       "erc20": true,
       "symbol": "ACH",
       "decimals": 8
     },
     "0xeDF6568618A00C6F0908Bf7758A16F76B6E04aF9": {
       "name": "Arianee",
       "logo": "aria20.svg",
       "erc20": true,
       "symbol": "ARIA20",
       "decimals": 18
     },
     "0x88D8DA2A8D0fA5b1f4E38030AC486aDE0AFA2798": {
       "name": "Terrapass Coin",
       "logo": "tpsc.svg",
       "erc20": true,
       "symbol": "TPSC",
       "decimals": 18
     },
     "0x4730fB1463A6F1F44AEB45F6c5c422427f37F4D0": {
       "name": "The 4th Pillar Token",
       "logo": "four.svg",
       "erc20": true,
       "symbol": "FOUR",
       "decimals": 18
     },
     "0x875773784Af8135eA0ef43b5a374AaD105c5D39e": {
       "name": "Idle DAO Token",
       "logo": "idle.svg",
       "erc20": true,
       "symbol": "IDLE",
       "decimals": 18
     },
     "0x6BeA7CFEF803D1e3d5f7C0103f7ded065644e197": {
       "name": "Gamma",
       "logo": "Gamma.svg",
       "erc20": true,
       "symbol": "GAMMA",
       "decimals": 18
     },
     "0x26805021988F1a45dC708B5FB75Fc75F21747D8c": {
       "name": "xGamma",
       "logo": "xGamma.svg",
       "erc20": true,
       "symbol": "xGAMMA",
       "decimals": 18
     },
     "0xc7D9c108D4E1dD1484D3e2568d7f74bfD763d356": {
       "name": "SORA Synthetic USD",
       "logo": "sora-xstusd.svg",
       "erc20": true,
       "symbol": "XSTUSD",
       "decimals": 18
     },
     "0x519C1001D550C0a1DaE7d1fC220f7d14c2A521BB": {
       "name": "Polkaswap",
       "logo": "sora-pswap.svg",
       "erc20": true,
       "symbol": "PSWAP",
       "decimals": 18
     },
     "0x40FD72257597aA14C7231A7B1aaa29Fce868F677": {
       "name": "SORA",
       "logo": "sora-xor.svg",
       "erc20": true,
       "symbol": "XOR",
       "decimals": 18
     },
     "0x909E34d3f6124C324ac83DccA84b74398a6fa173": {
       "name": "$ZKP Token",
       "logo": "ZKP.svg",
       "erc20": true,
       "erc721": false,
       "symbol": "$ZKP",
       "decimals": 18
     },
     "0xe88f8313e61A97cEc1871EE37fBbe2a8bf3ed1E4": {
       "name": "SORA Validator",
       "logo": "sora-val.svg",
       "erc20": true,
       "symbol": "VAL",
       "decimals": 18
     },
     "0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71": {
       "name": "ConstitutionDAO",
       "logo": "PEOPLE.svg",
       "erc20": true,
       "symbol": "PEOPLE",
       "decimals": 18
     },
     "0x32E6C34Cd57087aBBD59B5A4AECC4cB495924356": {
       "name": "BitBase",
       "logo": "BTBS.svg",
       "erc20": true,
       "symbol": "BTBS",
       "decimals": 18
     },
     "0x3fa400483487A489EC9b1dB29C4129063EEC4654": {
       "name": "Cryptokek.com",
       "logo": "cryptokek.svg",
       "erc20": true,
       "symbol": "KEK",
       "decimals": 18
     },
     "0xb753428af26E81097e7fD17f40c88aaA3E04902c": {
       "name": "Spice",
       "logo": "sfi.svg",
       "erc20": true,
       "symbol": "SFI",
       "decimals": 18
     },
     "0xd084944d3c05CD115C09d072B9F44bA3E0E45921": {
       "name": "Manifold Finance",
       "logo": "Manifold.svg",
       "erc20": true,
       "symbol": "FOLD",
       "decimals": 18
     },
     "0x9a0242b7a33DAcbe40eDb927834F96eB39f8fBCB": {
       "name": "BABB",
       "logo": "bax.svg",
       "erc20": true,
       "symbol": "BAX",
       "decimals": 18
     },
     "0x4Cf89ca06ad997bC732Dc876ed2A7F26a9E7f361": {
       "name": "Mysterium",
       "logo": "MYST.svg",
       "erc20": true,
       "symbol": "MYST",
       "decimals": 18
     },
     "0x697eF32B4a3F5a4C39dE1cB7563f24CA7BfC5947": {
       "name": "Insula Token",
       "logo": "Insula.svg",
       "erc20": true,
       "symbol": "ISLA",
       "decimals": 18
     },
     "0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A": {
       "name": "DeHive",
       "logo": "dehive.svg",
       "erc20": true,
       "erc721": false,
       "symbol": "DHV",
       "decimals": 18
     },
     "0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e": {
       "name": "Unibright",
       "logo": "ubt.svg",
       "erc20": true,
       "symbol": "UBT",
       "decimals": 8
     },
     "0xfAd45E47083e4607302aa43c65fB3106F1cd7607": {
       "name": "Hoge Finance",
       "logo": "HogeFinanceLogo.svg",
       "erc20": true,
       "symbol": "HOGE",
       "decimals": 9
     },
     "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b": {
       "name": "Axie Infinity Shard",
       "logo": "AXS.svg",
       "erc20": true,
       "symbol": "AXS",
       "decimals": 18
     },
     "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25": {
       "name": "Smooth Love Potion",
       "logo": "SLP.svg",
       "erc20": true,
       "symbol": "SLP",
       "decimals": 0
     },
     "0x10633216E7E8281e33c86F02Bf8e565a635D9770": {
       "name": "Dvision Network",
       "logo": "dvision.svg",
       "erc20": true,
       "symbol": "DVI",
       "decimals": 18
     },
     "0x898BAD2774EB97cF6b94605677F43b41871410B1": {
       "name": "vEth2",
       "logo": "vEth2.svg",
       "erc20": true,
       "symbol": "vEth2",
       "decimals": 18
     },
     "0xE94B97b6b43639E238c851A7e693F50033EfD75C": {
       "name": "Rainbow Token",
       "logo": "halodao-rnbw.svg",
       "erc20": true,
       "symbol": "RNBW",
       "decimals": 18
     },
     "0x47BE779De87de6580d0548cde80710a93c502405": {
       "name": "Rainbow Pool",
       "logo": "halodao-xrnbw.svg",
       "erc20": true,
       "symbol": "xRNBW",
       "decimals": 18
     },
     "0xe7aE6D0C56CACaf007b7e4d312f9af686a9E9a04": {
       "name": "Vabble",
       "logo": "VAB.svg",
       "erc20": true,
       "symbol": "VAB",
       "decimals": 18
     },
     "0x853d955aCEf822Db058eb8505911ED77F175b99e": {
       "name": "Frax",
       "logo": "frax.svg",
       "erc20": true,
       "symbol": "FRAX",
       "decimals": 18
     },
     "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0": {
       "name": "Frax Share",
       "logo": "fxs.svg",
       "erc20": true,
       "symbol": "FXS",
       "decimals": 18
     },
     "0x84810bcF08744d5862B8181f12d17bfd57d3b078": {
       "name": "SharedStake",
       "logo": "sharedstake.svg",
       "erc20": true,
       "symbol": "SGT",
       "decimals": 18
     },
     "0x75387e1287Dd85482aB66102DA9f6577E027f609": {
       "name": "MindsyncAI",
       "logo": "MAI.svg",
       "erc20": true,
       "symbol": "MAI",
       "decimals": 18
     },
     "0x196f4727526eA7FB1e17b2071B3d8eAA38486988": {
       "name": "Reserve",
       "logo": "rsv.svg",
       "erc20": true,
       "symbol": "RSV",
       "decimals": 18
     },
     "0x320623b8E4fF03373931769A31Fc52A4E78B5d70": {
       "name": "Reserve Rights",
       "logo": "rsr.svg",
       "erc20": true,
       "symbol": "RSR",
       "decimals": 18
     },
     "0x1cF4592ebfFd730c7dc92c1bdFFDfc3B9EfCf29a": {
       "name": "WAVES",
       "logo": "waves.svg",
       "erc20": true,
       "symbol": "WAVES",
       "decimals": 18
     },
     "0x252739487C1fa66eaeaE7CED41d6358aB2a6bCa9": {
       "name": "ArCoin",
       "logo": "ArCoin.svg",
       "erc20": true,
       "symbol": "RCOIN",
       "decimals": 8
     },
     "0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998": {
       "name": "Audius",
       "logo": "audius.svg",
       "erc20": true,
       "symbol": "AUDIO",
       "decimals": 18
     },
     "0x8E3BCC334657560253B83f08331d85267316e08a": {
       "name": "Rubic",
       "logo": "brbc.svg",
       "erc20": true,
       "symbol": "BRBC",
       "decimals": 18
     },
     "0xca1207647Ff814039530D7d35df0e1Dd2e91Fa84": {
       "name": "dHEDGE DAO Token",
       "logo": "DHT.svg",
       "erc20": true,
       "symbol": "DHT",
       "decimals": 18
     },
     "0x6243d8CEA23066d098a15582d81a598b4e8391F4": {
       "name": "Reflexer Ungovernance Token",
       "logo": "flx.svg",
       "erc20": true,
       "symbol": "FLX",
       "decimals": 18
     },
     "0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919": {
       "name": "Rai Reflex Index",
       "logo": "rai.svg",
       "erc20": true,
       "symbol": "RAI",
       "decimals": 18
     },
     "0xF25c91C87e0B1fd9B4064Af0F427157AaB0193A7": {
       "name": "BASIC Token",
       "logo": "basic.svg",
       "erc20": true,
       "symbol": "BASIC",
       "decimals": 18
     },
     "0x53C8395465A84955c95159814461466053DedEDE": {
       "name": "DeGate Token",
       "logo": "DG.svg",
       "erc20": true,
       "symbol": "DG",
       "decimals": 18
     },
     "0xBCf9dBf8B14eD096B2BA08b7269356197fDd1b5D": {
       "name": "Avaluse",
       "logo": "avaluse.svg",
       "erc20": true,
       "symbol": "AVAL",
       "decimals": 18
     },
     "0x6E765D26388A17A6e86c49A8E41DF3F58aBcd337": {
       "name": "Kangal",
       "logo": "kangal.svg",
       "erc20": true,
       "symbol": "KANGAL",
       "decimals": 18
     },
     "0x9AF4f26941677C706cfEcf6D3379FF01bB85D5Ab": {
       "name": "DomRaiderToken",
       "logo": "drt.svg",
       "erc20": true,
       "symbol": "DRT",
       "decimals": 8
     },
     "0x06B179e292f080871825beD5D722162fD96B4c95": {
       "name": "10x.gg",
       "logo": "xgg.svg",
       "erc20": true,
       "symbol": "XGG",
       "decimals": 18
     },
     "0xF29992D7b589A0A6bD2de7Be29a97A6EB73EaF85": {
       "name": "DMScript",
       "logo": "dmst.svg",
       "erc20": true,
       "symbol": "DMST",
       "decimals": 18
     },
     "0x64aa3364F17a4D01c6f1751Fd97C2BD3D7e7f1D5": {
       "name": "Olympus",
       "logo": "OHM.svg",
       "erc20": true,
       "symbol": "OHM",
       "decimals": 9
     },
     "0x0ab87046fBb341D058F17CBC4c1133F25a20a52f": {
       "name": "Governance OHM",
       "logo": "gOHM.svg",
       "erc20": true,
       "symbol": "gOHM",
       "decimals": 18
     },
     "0xDd1Ad9A21Ce722C151A836373baBe42c868cE9a4": {
       "name": "Universal Basic Income",
       "logo": "ubi.svg",
       "erc20": true,
       "symbol": "UBI",
       "decimals": 18
     },
     "0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4": {
       "name": "Sylo",
       "logo": "SYLO.svg",
       "erc20": true,
       "symbol": "SYLO",
       "decimals": 18
     },
     "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84": {
       "name": "Liquid staked Ether 2.0",
       "logo": "stETH.svg",
       "erc20": true,
       "symbol": "stETH",
       "decimals": 18
     },
     "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0": {
       "name": "Wrapped liquid staked Ether 2.0",
       "logo": "wstETH.svg",
       "erc20": true,
       "symbol": "wstETH",
       "decimals": 18
     },
     "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32": {
       "name": "Lido DAO Token",
       "logo": "LDO.svg",
       "erc20": true,
       "symbol": "LDO",
       "decimals": 18
     },
     "0xbd31EA8212119f94A611FA969881CBa3EA06Fa3d": {
       "name": "LUNA Token",
       "logo": "Luna.svg",
       "erc20": true,
       "symbol": "LUNA",
       "decimals": 6
     },
     "0xa693B19d2931d498c5B318dF961919BB4aee87a5": {
       "name": "UST Token",
       "logo": "UST.svg",
       "erc20": true,
       "symbol": "UST",
       "decimals": 6
     },
   
     "0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9": {
       "name": "LUNA Token - Shuttle",
       "logo": "shuttleLUNA.svg",
       "erc20": true,
       "symbol": "LUNA-S",
       "decimals": 18
     },
     "0xa47c8bf37f92aBed4A126BDA807A7b7498661acD": {
       "name": "UST Token - Shuttle",
       "logo": "shuttleUST.svg",
       "erc20": true,
       "symbol": "UST-S",
       "decimals": 18
     },
     "0xcAAfF72A8CbBfc5Cf343BA4e26f65a257065bFF1": {
       "name": "KRT Token - Shuttle",
       "logo": "KRT.svg",
       "erc20": true,
       "symbol": "KRT-S",
       "decimals": 18
     },
     "0x676Ad1b33ae6423c6618C1AEcf53BAa29cf39EE5": {
       "name": "SDT Token - Shuttle",
       "logo": "SDT.svg",
       "erc20": true,
       "symbol": "SDT-S",
       "decimals": 18
     },
     "0x156B36ec68FdBF84a925230BA96cb1Ca4c4bdE45": {
       "name": "MNT Token - Shuttle",
       "logo": "MNT.svg",
       "erc20": true,
       "symbol": "MNT-S",
       "decimals": 18
     },
     "0x09a3EcAFa817268f77BE1283176B946C4ff2E608": {
       "name": "MIR Token - Shuttle",
       "logo": "MIR.svg",
       "erc20": true,
       "symbol": "MIR-S",
       "decimals": 18
     },
     "0xd36932143F6eBDEDD872D5Fb0651f4B72Fd15a84": {
       "name": "Mirror AAPL Token - Shuttle",
       "logo": "mAAPL.svg",
       "erc20": true,
       "symbol": "mAAPL-S",
       "decimals": 18
     },
     "0x59A921Db27Dd6d4d974745B7FfC5c33932653442": {
       "name": "Mirror GOOGL Token - Shuttle",
       "logo": "mGOOGL.svg",
       "erc20": true,
       "symbol": "mGOGL-S",
       "decimals": 18
     },
     "0x21cA39943E91d704678F5D00b6616650F066fD63": {
       "name": "Mirror TSLA Token - Shuttle",
       "logo": "mTSLA.svg",
       "erc20": true,
       "symbol": "mTSLA-S",
       "decimals": 18
     },
     "0xC8d674114bac90148d11D3C1d33C61835a0F9DCD": {
       "name": "Mirror NFLX Token - Shuttle",
       "logo": "mNFLX.svg",
       "erc20": true,
       "symbol": "mNFLX-S",
       "decimals": 18
     },
     "0x13B02c8dE71680e71F0820c996E4bE43c2F57d15": {
       "name": "Mirror QQQ Token - Shuttle",
       "logo": "mQQQ.svg",
       "erc20": true,
       "symbol": "mQQQ-S",
       "decimals": 18
     },
     "0xEdb0414627E6f1e3F082DE65cD4F9C693D78CCA9": {
       "name": "Mirror TWTR Token - Shuttle",
       "logo": "mTWTR.svg",
       "erc20": true,
       "symbol": "mTWTR-S",
       "decimals": 18
     },
     "0x41BbEDd7286dAab5910a1f15d12CBda839852BD7": {
       "name": "Mirror MSFT Token - Shuttle",
       "logo": "mMSFT.svg",
       "erc20": true,
       "symbol": "mMSFT-S",
       "decimals": 18
     },
     "0x0cae9e4d663793c2a2A0b211c1Cf4bBca2B9cAa7": {
       "name": "Mirror AMZN Token - Shuttle",
       "logo": "mAMZN.svg",
       "erc20": true,
       "symbol": "mAMZN-S",
       "decimals": 18
     },
     "0x56aA298a19C93c6801FDde870fA63EF75Cc0aF72": {
       "name": "Mirror BABA Token - Shuttle",
       "logo": "mBABA.svg",
       "erc20": true,
       "symbol": "mBABA-S",
       "decimals": 18
     },
     "0x1d350417d9787E000cc1b95d70E9536DcD91F373": {
       "name": "Mirror IAU Token - Shuttle",
       "logo": "mIAU.svg",
       "erc20": true,
       "symbol": "mIAU-S",
       "decimals": 18
     },
     "0x9d1555d8cB3C846Bb4f7D5B1B1080872c3166676": {
       "name": "Mirror SLV Token - Shuttle",
       "logo": "mSLV.svg",
       "erc20": true,
       "symbol": "mSLV-S",
       "decimals": 18
     },
     "0x31c63146a635EB7465e5853020b39713AC356991": {
       "name": "Mirror USO Token - Shuttle",
       "logo": "mUSO.svg",
       "erc20": true,
       "symbol": "mUSO-S",
       "decimals": 18
     },
     "0xf72FCd9DCF0190923Fadd44811E240Ef4533fc86": {
       "name": "Mirror VIXY Token - Shuttle",
       "logo": "mVIXY.svg",
       "erc20": true,
       "symbol": "mVIXY-S",
       "decimals": 18
     },
     "0x21BfBDa47A0B4B5b1248c767Ee49F7caA9B23697": {
       "name": "OVR",
       "logo": "OVR.svg",
       "erc20": true,
       "symbol": "OVR",
       "decimals": 18
     },
     "0x4691937a7508860F876c9c0a2a617E7d9E945D4B": {
       "name": "Wootrade Network",
       "logo": "wootrade.svg",
       "erc20": true,
       "symbol": "WOO",
       "decimals": 18
     },
     "0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5": {
       "name": "RFUEL",
       "logo": "RFUEL.svg",
       "erc20": true,
       "symbol": "RFUEL",
       "decimals": 18
     },
     "0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC": {
       "name": "JasmyCoin",
       "logo": "JASMY.svg",
       "erc20": true,
       "symbol": "JASMY",
       "decimals": 18
     },
     "0x947AEb02304391f8fbE5B25D7D98D649b57b1788": {
       "name": "Mandala Exchange Token",
       "logo": "mandala.svg",
       "erc20": true,
       "symbol": "MDX",
       "decimals": 18
     },
     "0xCdeee767beD58c5325f68500115d4B722b3724EE": {
       "name": "Carbon",
       "logo": "CRBN.svg",
       "erc20": true,
       "symbol": "CRBN",
       "decimals": 18
     },
     "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3": {
       "name": "Rubic",
       "logo": "Rubic.svg",
       "erc20": true,
       "symbol": "RBC",
       "decimals": 18
     },
     "0x3A880652F47bFaa771908C07Dd8673A787dAEd3A": {
       "name": "DerivaDAO",
       "logo": "DDX.svg",
       "erc20": true,
       "symbol": "DDX",
       "decimals": 18
     },
     "0xbC396689893D065F41bc2C6EcbeE5e0085233447": {
       "name": "Perpetual",
       "logo": "PERP.svg",
       "erc20": true,
       "symbol": "PERP",
       "decimals": 18
     },
     "0xeca82185adCE47f39c684352B0439f030f860318": {
       "name": "Perlin",
       "logo": "PERL.svg",
       "erc20": true,
       "symbol": "PERL",
       "decimals": 18
     },
     "0xA1AFFfE3F4D611d252010E3EAf6f4D77088b0cd7": {
       "name": "Reflect Finance",
       "logo": "RFI.svg",
       "erc20": true,
       "symbol": "RFI",
       "decimals": 9
     },
     "0xC0bA369c8Db6eB3924965e5c4FD0b4C1B91e305F": {
       "name": "DLP Duck Token",
       "logo": "dlpducktoken.svg",
       "erc20": true,
       "symbol": "DUCK",
       "decimals": 18
     },
     "0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20": {
       "name": "DuckDaoDime",
       "logo": "ddim.svg",
       "erc20": true,
       "symbol": "DDIM",
       "decimals": 18
     },
     "0xB4d930279552397bbA2ee473229f89Ec245bc365": {
       "name": "MahaDAO",
       "logo": "MAHA.svg",
       "erc20": true,
       "symbol": "MAHA",
       "decimals": 18
     },
     "0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f": {
       "name": "Monerium EUR",
       "logo": "EURe.svg",
       "erc20": true,
       "symbol": "EURe",
       "decimals": 18
     },
     "0x7ba92741Bf2A568abC6f1D3413c58c6e0244F8fD": {
       "name": "Monerium GBP",
       "logo": "GBPe.svg",
       "erc20": true,
       "symbol": "GBPe",
       "decimals": 18
     },
     "0xBc5142e0CC5eB16b47c63B0f033d4c2480853a52": {
       "name": "Monerium USD",
       "logo": "USDe.svg",
       "erc20": true,
       "symbol": "USDe",
       "decimals": 18
     },
     "0xC642549743A93674cf38D6431f75d6443F88E3E2": {
       "name": "Monerium ISK",
       "logo": "ISKe.svg",
       "erc20": true,
       "symbol": "ISKe",
       "decimals": 18
     },
     "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6": {
       "name": "WISE Token",
       "logo": "wise.svg",
       "erc20": true,
       "symbol": "WISE",
       "decimals": 18
     },
     "0x72F020f8f3E8fd9382705723Cd26380f8D0c66Bb": {
       "name": "PlotX",
       "logo": "plotx.svg",
       "erc20": true,
       "symbol": "PLOT",
       "decimals": 18
     },
     "0x44197A4c44D6A059297cAf6be4F7e172BD56Caaf": {
       "name": "ELTCOIN",
       "logo": "ELTCOIN.svg",
       "erc20": true,
       "symbol": "ELT",
       "decimals": 8
     },
     "0x6781a0F84c7E9e846DCb84A9a5bd49333067b104": {
       "name": "ZAP TOKEN",
       "logo": "zapicon.svg",
       "erc20": true,
       "symbol": "ZAP",
       "decimals": 18
     },
     "0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7": {
       "name": "SKALE",
       "logo": "skl.svg",
       "erc20": true,
       "symbol": "SKL",
       "decimals": 18
     },
     "0x674C6Ad92Fd080e4004b2312b45f796a192D27a0": {
       "name": "Neutrino USD",
       "logo": "usdn.svg",
       "erc20": true,
       "symbol": "USDN",
       "decimals": 18
     },
     "0xE5CAeF4Af8780E59Df925470b050Fb23C43CA68C": {
       "name": "Ferrum Network Token",
       "logo": "frm.svg",
       "erc20": true,
       "symbol": "FRM",
       "decimals": 6
     },
     "0x998FFE1E43fAcffb941dc337dD0468d52bA5b48A": {
       "name": "Rupiah Token",
       "logo": "idrt.svg",
       "erc20": true,
       "symbol": "IDRT",
       "decimals": 2
     },
     "0x4E15361FD6b4BB609Fa63C81A2be19d873717870": {
       "name": "Fantom",
       "logo": "ftm.svg",
       "erc20": true,
       "symbol": "FTM",
       "decimals": 18
     },
     "0x557B933a7C2c45672B610F8954A3deB39a51A8Ca": {
       "name": "REVV",
       "logo": "revv.svg",
       "erc20": true,
       "symbol": "REVV",
       "decimals": 18
     },
     "0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B": {
       "name": "Aave AAVE",
       "logo": "aAAVE.svg",
       "erc20": true,
       "symbol": "aAAVE",
       "decimals": 18
     },
     "0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1": {
       "name": "Aave BAT",
       "logo": "aBAT.svg",
       "erc20": true,
       "symbol": "aBAT",
       "decimals": 18
     },
     "0xA361718326c15715591c299427c62086F69923D9": {
       "name": "Aave BUSD",
       "logo": "aBUSD.svg",
       "erc20": true,
       "symbol": "aBUSD",
       "decimals": 18
     },
     "0x028171bCA77440897B824Ca71D1c56caC55b68A3": {
       "name": "Aave DAI",
       "logo": "aDAI.svg",
       "erc20": true,
       "symbol": "aDAI",
       "decimals": 18
     },
     "0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef": {
       "name": "Aave ENJ",
       "logo": "aENJ.svg",
       "erc20": true,
       "symbol": "aENJ",
       "decimals": 18
     },
     "0xD37EE7e4f452C6638c96536e68090De8cBcdb583": {
       "name": "Aave GUSD",
       "logo": "aGUSD.svg",
       "erc20": true,
       "symbol": "aGUSD",
       "decimals": 2
     },
     "0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA": {
       "name": "Aave KNC",
       "logo": "aKNC.svg",
       "erc20": true,
       "symbol": "aKNC",
       "decimals": 18
     },
     "0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0": {
       "name": "Aave LINK",
       "logo": "aLINK.svg",
       "erc20": true,
       "symbol": "aLINK",
       "decimals": 18
     },
     "0xa685a61171bb30d4072B338c80Cb7b2c865c873E": {
       "name": "Aave MANA",
       "logo": "aMANA.svg",
       "erc20": true,
       "symbol": "aMANA",
       "decimals": 18
     },
     "0xc713e5E149D5D0715DcD1c156a020976e7E56B88": {
       "name": "Aave MKR",
       "logo": "aMKR.svg",
       "erc20": true,
       "symbol": "aMKR",
       "decimals": 18
     },
     "0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a": {
       "name": "Aave REN",
       "logo": "aREN.svg",
       "erc20": true,
       "symbol": "aREN",
       "decimals": 18
     },
     "0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2": {
       "name": "Aave SNX",
       "logo": "aSNX.svg",
       "erc20": true,
       "symbol": "aSNX",
       "decimals": 18
     },
     "0x6C5024Cd4F8A59110119C56f8933403A539555EB": {
       "name": "Aave SUSD",
       "logo": "aSUSD.svg",
       "erc20": true,
       "symbol": "aSUSD",
       "decimals": 18
     },
     "0x101cc05f4A51C0319f570d5E146a8C625198e636": {
       "name": "Aave TUSD",
       "logo": "aTUSD.svg",
       "erc20": true,
       "symbol": "aTUSD",
       "decimals": 18
     },
     "0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1": {
       "name": "Aave UNI",
       "logo": "aUNI.svg",
       "erc20": true,
       "symbol": "aUNI",
       "decimals": 18
     },
     "0xBcca60bB61934080951369a648Fb03DF4F96263C": {
       "name": "Aave USDC",
       "logo": "aUSDC.svg",
       "erc20": true,
       "symbol": "aUSDC",
       "decimals": 6
     },
     "0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811": {
       "name": "Aave USDT",
       "logo": "aUSDT.svg",
       "erc20": true,
       "symbol": "aUSDT",
       "decimals": 6
     },
     "0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656": {
       "name": "Aave WBTC",
       "logo": "aWBTC.svg",
       "erc20": true,
       "symbol": "aWBTC",
       "decimals": 8
     },
     "0x030bA81f1c18d280636F32af80b9AAd02Cf0854e": {
       "name": "Aave WETH",
       "logo": "aWETH.svg",
       "erc20": true,
       "symbol": "aWETH",
       "decimals": 18
     },
     "0x5165d24277cD063F5ac44Efd447B27025e888f37": {
       "name": "Aave YFI",
       "logo": "aYFI.svg",
       "erc20": true,
       "symbol": "aYFI",
       "decimals": 18
     },
     "0xdef1fac7Bf08f173D286BbBDcBeeADe695129840": {
       "name": "Defi Factory Token",
       "logo": "DEFT.svg",
       "erc20": true,
       "symbol": "DEFT",
       "decimals": 18
     },
     "0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e": {
       "name": "Aave ZRX",
       "logo": "aZRX.svg",
       "erc20": true,
       "symbol": "aZRX",
       "decimals": 18
     },
     "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9": {
       "name": "Aave",
       "logo": "AAVE.svg",
       "erc20": true,
       "symbol": "AAVE",
       "decimals": 18
     },
     "0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A": {
       "name": "Attrace",
       "logo": "attr.svg",
       "erc20": true,
       "symbol": "ATTR",
       "decimals": 18
     },
     "0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0": {
       "name": "Equalizer",
       "logo": "EQZ.svg",
       "erc20": true,
       "symbol": "EQZ",
       "decimals": 18
     },
     "0x4da27a545c0c5B758a6BA100e3a049001de870f5": {
       "name": "Staked Aave",
       "logo": "stkAAVE.svg",
       "erc20": true,
       "symbol": "stAAVE",
       "decimals": 18
     },
     "0xba9d4199faB4f26eFE3551D490E3821486f135Ba": {
       "name": "SwissBorg",
       "logo": "chsb.svg",
       "erc20": true,
       "symbol": "CHSB",
       "decimals": 8
     },
     "0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429": {
       "name": "Golem Network Token",
       "logo": "glm.svg",
       "erc20": true,
       "symbol": "GLM",
       "decimals": 18
     },
     "0x7240aC91f01233BaAf8b064248E80feaA5912BA3": {
       "name": "OctoFi",
       "logo": "octo.svg",
       "erc20": true,
       "symbol": "OCTO",
       "decimals": 18
     },
     "0xff56Cc6b1E6dEd347aA0B7676C85AB0B3D08B0FA": {
       "name": "Orbs",
       "logo": "orbs.svg",
       "erc20": true,
       "symbol": "ORBS",
       "decimals": 18
     },
     "0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437": {
       "name": "GAME Credits",
       "logo": "GAMECreditsLogo.svg",
       "erc20": true,
       "symbol": "GAME",
       "decimals": 18
     },
     "0x5150956E082C748Ca837a5dFa0a7C10CA4697f9c": {
       "name": "Zeedex",
       "logo": "zdex.svg",
       "erc20": true,
       "symbol": "ZDEX",
       "decimals": 18
     },
     "0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419": {
       "name": "DIAdata",
       "logo": "dia.svg",
       "erc20": true,
       "symbol": "DIA",
       "decimals": 18
     },
     "0xb78B3320493a4EFaa1028130C5Ba26f0B6085Ef8": {
       "name": "Dracula",
       "logo": "dracula.svg",
       "erc20": true,
       "symbol": "DRC",
       "decimals": 18
     },
     "0xF433089366899D83a9f26A773D59ec7eCF30355e": {
       "name": "Metal",
       "logo": "mtl.svg",
       "erc20": true,
       "symbol": "MTL",
       "decimals": 8
     },
     "0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC": {
       "name": "KEEP",
       "logo": "keep.svg",
       "erc20": true,
       "symbol": "KEEP",
       "decimals": 18
     },
     "0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa": {
       "name": "tBTC",
       "logo": "tbtc.svg",
       "erc20": true,
       "symbol": "TBTC",
       "decimals": 18
     },
     "0xD7EFB00d12C2c13131FD319336Fdf952525dA2af": {
       "name": "Proton",
       "logo": "proton.svg",
       "erc20": true,
       "symbol": "XPR",
       "decimals": 4
     },
     "0x178c820f862B14f316509ec36b13123DA19A6054": {
       "name": "Energy Web Token Bridged",
       "logo": "ewtb.svg",
       "erc20": true,
       "symbol": "EWTB",
       "decimals": 18
     },
     "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39": {
       "name": "HEX",
       "logo": "hex.svg",
       "erc20": true,
       "symbol": "HEX",
       "decimals": 8
     },
     "0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF": {
       "name": "Rarible",
       "logo": "rari.svg",
       "erc20": true,
       "symbol": "RARI",
       "decimals": 18
     },
     "0x7865af71cf0b288b4E7F654f4F7851EB46a2B7F8": {
       "name": "Sentivate",
       "logo": "sentivate.svg",
       "erc20": true,
       "symbol": "SNTVT",
       "decimals": 18
     },
     "0xa3d58c4E56fedCae3a7c43A725aeE9A71F0ece4e": {
       "name": "Metronome",
       "logo": "metronome.svg",
       "erc20": true,
       "symbol": "MET",
       "decimals": 18
     },
     "0x49d716DFe60b37379010A75329ae09428f17118d": {
       "name": "PoolTogether Dai",
       "logo": "pldai.svg",
       "erc20": true,
       "symbol": "PLDAI",
       "decimals": 18
     },
     "0xfE6892654CBB05eB73d28DCc1Ff938f59666Fe9f": {
       "name": "PoolTogether Sai",
       "logo": "plsai.svg",
       "erc20": true,
       "symbol": "PLSAI",
       "decimals": 18
     },
     "0x5732046A883704404F284Ce41FfADd5b007FD668": {
       "name": "Bluzelle Token",
       "logo": "Bluzelle.svg",
       "erc20": true,
       "symbol": "BLZ",
       "decimals": 18
     },
     "0xBD87447F48ad729C5c4b8bcb503e1395F62e8B98": {
       "name": "PoolTogether USDC",
       "logo": "plusdc.svg",
       "erc20": true,
       "symbol": "PLUSDC",
       "decimals": 6
     },
     "0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23": {
       "name": "$Poolz Finance",
       "logo": "Poolz.svg",
       "erc20": true,
       "symbol": "POOLZ",
       "decimals": 18
     },
     "0x0adA190c81b814548ddC2F6AdC4a689ce7C1FE73": {
       "name": "yAxis",
       "logo": "yaxis.svg",
       "erc20": true,
       "symbol": "YAXIS",
       "decimals": 18
     },
     "0xb1dC9124c395c1e97773ab855d66E879f053A289": {
       "name": "yAxis",
       "logo": "yaxis.svg",
       "erc20": true,
       "symbol": "YAX",
       "decimals": 18
     },
     "0xeF31Cb88048416E301Fee1eA13e7664b887BA7e8": {
       "name": "Staked yAxis",
       "logo": "yaxis.svg",
       "erc20": true,
       "symbol": "sYAX",
       "decimals": 18
     },
     "0x28cb7e841ee97947a86B06fA4090C8451f64c0be": {
       "name": "YF Link",
       "logo": "YFL.svg",
       "erc20": true,
       "symbol": "YFL",
       "decimals": 18
     },
     "0x75D1aA733920b14fC74c9F6e6faB7ac1EcE8482E": {
       "name": "YFLink Staking Share",
       "logo": "YFL.svg",
       "erc20": true,
       "symbol": "yYFL",
       "decimals": 18
     },
     "0x7b760D06E401f85545F3B50c44bf5B05308b7b62": {
       "name": "YFLink USD",
       "logo": "YFLUSD.svg",
       "erc20": true,
       "symbol": "YFLUSD",
       "decimals": 18
     },
     "0x8282df223AC402d04B2097d16f758Af4F70e7Db0": {
       "name": "YFLink Synthetic",
       "logo": "sYFL.svg",
       "erc20": true,
       "symbol": "sYFL",
       "decimals": 18
     },
     "0x008377EB0C62cE8e0BA3D7Bb4A5638591f21588E": {
       "name": "YFLink Bond",
       "logo": "bYFL.svg",
       "erc20": true,
       "symbol": "bYFL",
       "decimals": 18
     },
     "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e": {
       "name": "yearn.finance",
       "logo": "yfi.svg",
       "erc20": true,
       "symbol": "YFI",
       "decimals": 18
     },
     "0x261b45D85cCFeAbb11F022eBa346ee8D1cd488c0": {
       "name": "rDAI",
       "logo": "rdai.svg",
       "erc20": true,
       "symbol": "rDAI",
       "decimals": 18
     },
     "0xea8b224eDD3e342DEb514C4176c2E72Bcce6fFF9": {
       "name": "rSAI",
       "logo": "rsai.svg",
       "erc20": true,
       "symbol": "rSAI",
       "decimals": 18
     },
     "0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD": {
       "name": "GridPlus",
       "logo": "GridPlus.svg",
       "erc20": true,
       "symbol": "GRID",
       "decimals": 12
     },
     "0xDb05EA0877A2622883941b939f0bb11d1ac7c400": {
       "name": "Opacity",
       "logo": "opct.svg",
       "erc20": true,
       "symbol": "OPCT",
       "decimals": 18
     },
     "0x4575f41308EC1483f3d399aa9a2826d74Da13Deb": {
       "name": "Orchid",
       "logo": "OXT.svg",
       "erc20": true,
       "symbol": "OXT",
       "decimals": 18
     },
     "0x4CC19356f2D37338b9802aa8E8fc58B0373296E7": {
       "name": "Selfkey",
       "logo": "KEY.svg",
       "erc20": true,
       "symbol": "KEY",
       "decimals": 18
     },
     "0x92A5B04D0ED5D94D7a193d1d334D3D16996f4E13": {
       "name": "Eristica",
       "logo": "Eristica.svg",
       "erc20": true,
       "symbol": "ERT",
       "decimals": 18
     },
     "0xdAC17F958D2ee523a2206206994597C13D831ec7": {
       "name": "Tether USD",
       "logo": "usdt.svg",
       "erc20": true,
       "symbol": "USDT",
       "decimals": 6
     },
     "0x06AF07097C9Eeb7fD685c692751D5C66dB49c215": {
       "name": "Chai",
       "logo": "CHAI.svg",
       "erc20": true,
       "symbol": "CHAI",
       "decimals": 18
     },
     "0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828": {
       "name": "UMA",
       "logo": "UMA.png",
       "erc20": true,
       "symbol": "UMA",
       "decimals": 18
     },
     "0x5f778ec4B31a506c1Dfd8b06F131E9B451a61D39": {
       "name": "UPX Token",
       "logo": "UPX.svg",
       "erc20": true,
       "symbol": "UPX",
       "decimals": 18
     },
     "0xb1CD6e4153B2a390Cf00A6556b0fC1458C4A5533": {
       "name": "ETHBNT Liquidity Pool",
       "logo": "ETHBNT.svg",
       "erc20": true,
       "symbol": "ETHBNT",
       "decimals": 18
     },
     "0xf0Ee6b27b759C9893Ce4f094b49ad28fd15A23e4": {
       "name": "Enigma",
       "logo": "enigma.svg",
       "erc20": true,
       "symbol": "ENG",
       "decimals": 8
     },
     "0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d": {
       "name": "Celsius",
       "logo": "celsius.svg",
       "erc20": true,
       "symbol": "CEL",
       "decimals": 4
     },
     "0xb683D83a532e2Cb7DFa5275eED3698436371cc9f": {
       "name": "BTU",
       "logo": "BTU.svg",
       "erc20": true,
       "symbol": "BTU",
       "decimals": 18
     },
     "0x746DdA2ea243400D5a63e0700F190aB79f06489e": {
       "name": "BOSAGORA",
       "logo": "BOA.svg",
       "erc20": true,
       "symbol": "BOA",
       "decimals": 7
     },
     "0x5D858bcd53E085920620549214a8b27CE2f04670": {
       "name": "POP Network Token",
       "logo": "POP.svg",
       "erc20": true,
       "symbol": "POP",
       "decimals": 18
     },
     "0x048Fe49BE32adfC9ED68C37D32B5ec9Df17b3603": {
       "name": "Skrumble Network V2",
       "logo": "skm.svg",
       "erc20": true,
       "symbol": "SKM",
       "decimals": 18
     },
     "0x16EA01aCB4b0Bca2000ee5473348B6937ee6f72F": {
       "name": "Enecuum",
       "logo": "ENQ-logo.svg",
       "erc20": true,
       "symbol": "ENQ",
       "decimals": 10
     },
     "0xE5B826Ca2Ca02F09c1725e9bd98d9a8874C30532": {
       "name": "ZEON Network",
       "logo": "ZEON_logo.png",
       "erc20": true,
       "symbol": "ZEON",
       "decimals": 18
     },
     "0x13cb85823f78Cff38f0B0E90D3e975b8CB3AAd64": {
       "name": "REMIIT REMI Token",
       "logo": "remi.svg",
       "erc20": true,
       "symbol": "REMI",
       "decimals": 18
     },
     "0x93a7174dafd31d13400cD9fa01f4e5B5BAa00D39": {
       "name": "Shaka",
       "logo": "hak.svg",
       "erc20": true,
       "symbol": "HAK",
       "decimals": 18
     },
     "0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69": {
       "name": "IoTeX",
       "logo": "iotex.svg",
       "erc20": true,
       "symbol": "IOTX",
       "decimals": 18
     },
     "0xE5a3229CCb22b6484594973A03a3851dCd948756": {
       "name": "RAE Token",
       "logo": "rae.svg",
       "erc20": true,
       "symbol": "RAE",
       "decimals": 18
     },
     "0x4470BB87d77b963A013DB939BE332f927f2b992e": {
       "name": "AdEx Legacy Token",
       "logo": "adex.svg",
       "erc20": true,
       "symbol": "ADXL",
       "decimals": 4
     },
     "0xADE00C28244d5CE17D72E40330B1c318cD12B7c3": {
       "name": "AdEx Token",
       "logo": "adex.svg",
       "erc20": true,
       "symbol": "ADX",
       "decimals": 18
     },
     "0xDc5864eDe28BD4405aa04d93E05A0531797D9D59": {
       "name": "Falcon",
       "logo": "fnt.svg",
       "erc20": true,
       "symbol": "FNT",
       "decimals": 6
     },
     "0x1966d718A565566e8E202792658D7b5Ff4ECe469": {
       "name": "nDEX",
       "logo": "nDEX.png",
       "erc20": true,
       "symbol": "NDX",
       "decimals": 18
     },
     "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599": {
       "name": "Wrapped BTC",
       "logo": "wbtc.svg",
       "erc20": true,
       "symbol": "WBTC",
       "decimals": 8
     },
     "0x150b0b96933B75Ce27af8b92441F8fB683bF9739": {
       "name": "Dragonereum Gold",
       "logo": "gold.svg",
       "erc20": true,
       "symbol": "GOLD",
       "decimals": 18
     },
     "0x82f4dED9Cec9B5750FBFf5C2185AEe35AfC16587": {
       "name": "DreamTeam Token",
       "logo": "dream.svg",
       "erc20": true,
       "symbol": "DREAM",
       "decimals": 6
     },
     "0x5d60d8d7eF6d37E16EBABc324de3bE57f135e0BC": {
       "name": "MyBit",
       "logo": "MYB.svg",
       "erc20": true,
       "symbol": "MYB",
       "decimals": 18
     },
     "0x01FA555c97D7958Fa6f771f3BbD5CCD508f81e22": {
       "name": "Civil Token",
       "logo": "CVL_token.svg",
       "erc20": true,
       "symbol": "CVL",
       "decimals": 18
     },
     "0x5adc961D6AC3f7062D2eA45FEFB8D8167d44b190": {
       "name": "Dether",
       "logo": "dether.svg",
       "erc20": true,
       "symbol": "DTH",
       "decimals": 18
     },
     "0x3F17Dd476faF0a4855572F0B6ed5115D9bBA22AD": {
       "name": "Wibson",
       "logo": "wibson-logo.png",
       "erc20": true,
       "symbol": "WIB",
       "decimals": 9
     },
     "0xDF347911910b6c9A4286bA8E2EE5ea4a39eB2134": {
       "name": "Bob's Repair",
       "logo": "bobsrepair.png",
       "erc20": true,
       "symbol": "BOB",
       "decimals": 18
     },
     "0x3505F494c3f0fed0B594E01Fa41Dd3967645ca39": {
       "name": "SWARM",
       "logo": "swarm.svg",
       "erc20": true,
       "symbol": "SWM",
       "decimals": 18
     },
     "0x6863bE0e7CF7ce860A574760e9020D519a8bDC47": {
       "name": "On.Live",
       "logo": "onlive.svg",
       "erc20": true,
       "symbol": "ONL",
       "decimals": 18
     },
     "0xea5f88E54d982Cbb0c441cde4E79bC305e5b43Bc": {
       "name": "Pareto",
       "logo": "ParetoLogo.png",
       "erc20": true,
       "symbol": "PARETO",
       "decimals": 18
     },
     "0x2e91E3e54C5788e9FdD6A181497FDcEa1De1bcc1": {
       "name": "Hercules",
       "logo": "herc.png",
       "erc20": true,
       "symbol": "HERC",
       "decimals": 18
     },
     "0x4946Fcea7C692606e8908002e55A582af44AC121": {
       "name": "FOAM",
       "logo": "FOAM.svg",
       "erc20": true,
       "symbol": "FOAM",
       "decimals": 18
     },
     "0x4D807509aECe24C0fa5A102b6a3B059Ec6E14392": {
       "name": "Menlo One",
       "logo": "Menlo_One.png",
       "erc20": true,
       "symbol": "ONE",
       "decimals": 18
     },
     "0xDDD460bBD9F79847ea08681563e8A9696867210C": {
       "name": "Spendcoin",
       "logo": "spnd.png",
       "erc20": true,
       "symbol": "SPND",
       "decimals": 18
     },
     "0x27054b13b1B798B345b591a4d22e6562d47eA75a": {
       "name": "AirSwap Token",
       "logo": "AST.png",
       "erc20": true,
       "symbol": "AST",
       "decimals": 4
     },
     "0x8D5682941cE456900b12d47ac06a88b47C764CE1": {
       "name": "RightMesh Token",
       "logo": "Rmesh.png",
       "erc20": true,
       "symbol": "RMESH",
       "decimals": 18
     },
     "0xDDe12a12A6f67156e0DA672be05c374e1B0a3e57": {
       "name": "JOYSO",
       "logo": "JOY.png",
       "erc20": true,
       "symbol": "JOY",
       "decimals": 6
     },
     "0x0D262e5dC4A06a0F1c90cE79C7a60C09DfC884E4": {
       "name": "JET8 Token",
       "logo": "J8T.svg",
       "erc20": true,
       "symbol": "J8T",
       "decimals": 8
     },
     "0x4a220E6096B25EADb88358cb44068A3248254675": {
       "name": "Quant Network",
       "logo": "quant-network.svg",
       "erc20": true,
       "symbol": "QNT",
       "decimals": 18
     },
     "0xBC86727E770de68B1060C91f6BB6945c73e10388": {
       "name": "Ink Protocol",
       "logo": "ink_protocol.svg",
       "erc20": true,
       "symbol": "XNK",
       "decimals": 18
     },
     "0x4CEdA7906a5Ed2179785Cd3A40A69ee8bc99C466": {
       "name": "Aion Network",
       "logo": "Aion.png",
       "erc20": true,
       "symbol": "AION",
       "decimals": 8
     },
     "0xa95592DCFfA3C080B4B40E459c5f5692F67DB7F8": {
       "name": "ELY Token",
       "logo": "ely.png",
       "erc20": true,
       "symbol": "ELY",
       "decimals": 18
     },
     "0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1": {
       "name": "PolySwarm Nectar",
       "logo": "polyswarm_nectar.svg",
       "erc20": true,
       "symbol": "NCT",
       "decimals": 18
     },
     "0xe1A178B681BD05964d3e3Ed33AE731577d9d96dD": {
       "name": "BOX Token",
       "logo": "box_token.png",
       "erc20": true,
       "symbol": "BOX",
       "decimals": 18
     },
     "0x82BD526bDB718C6d4DD2291Ed013A5186cAE2DCa": {
       "name": "dutyof.care Token",
       "logo": "dutyof.care-square.png",
       "erc20": true,
       "symbol": "VDOC",
       "decimals": 18
     },
     "0x168296bb09e24A88805CB9c33356536B980D3fC5": {
       "name": "Rhoc",
       "logo": "rchain.png",
       "erc20": true,
       "symbol": "RHOC",
       "decimals": 8
     },
     "0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf": {
       "name": "DAOstack",
       "logo": "DAOstack.png",
       "erc20": true,
       "symbol": "GEN",
       "decimals": 18
     },
     "0x6aEDbF8dFF31437220dF351950Ba2a3362168d1b": {
       "name": "Dragonglass",
       "logo": "dragonglass.svg",
       "erc20": true,
       "symbol": "DGS",
       "decimals": 8
     },
     "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07": {
       "name": "OmiseGO",
       "logo": "omg.jpg",
       "erc20": true,
       "symbol": "OMG",
       "decimals": 18
     },
     "0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d": {
       "name": "Quantstamp",
       "logo": "qsp.svg",
       "erc20": true,
       "symbol": "QSP",
       "decimals": 18
     },
     "0x4162178B78D6985480A308B2190EE5517460406D": {
       "name": "Colu Local Network",
       "logo": "cln.png",
       "erc20": true,
       "symbol": "CLN",
       "decimals": 18
     },
     "0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC": {
       "name": "Storj",
       "logo": "storj.jpg",
       "erc20": true,
       "symbol": "STORJ",
       "decimals": 8
     },
     "0xD8E3FB3b08eBA982F2754988d70D57eDc0055ae6": {
       "name": "Zoracles",
       "logo": "ZORA.svg",
       "erc20": true,
       "symbol": "ZORA",
       "decimals": 9
     },
     "0xF938424F7210f31dF2Aee3011291b658f872e91e": {
       "name": "VISOR",
       "logo": "VISR.svg",
       "erc20": true,
       "symbol": "VISR",
       "decimals": 18
     },
     "0x0F5D2fB29fb7d3CFeE444a200298f468908cC942": {
       "name": "Decentraland",
       "logo": "mana.svg",
       "erc20": true,
       "symbol": "MANA",
       "decimals": 18
     },
     "0x0F513fFb4926ff82D7F60A05069047AcA295C413": {
       "name": "CrowdstartCoin",
       "logo": "XSC_Logo.svg",
       "erc20": true,
       "symbol": "XSC",
       "decimals": 18
     },
     "0x5BC7e5f0Ab8b2E10D2D0a3F21739FCe62459aeF3": {
       "name": "Hut34 Entropy Token",
       "logo": "ENTRP.png",
       "symbol": "ENTRP",
       "erc20": true,
       "decimals": 18
     },
     "0x946112efaB61C3636CBD52DE2E1392D7A75A6f01": {
       "name": "HYDRO TOKEN",
       "logo": "hydro.svg",
       "erc20": true,
       "symbol": "HYDRO",
       "decimals": 18
     },
     "0x2604FA406Be957E542BEb89E6754fCdE6815e83f": {
       "name": "Playkey Token",
       "logo": "playkey.svg",
       "erc20": true,
       "symbol": "PKT",
       "decimals": 18
     },
     "0xE41d2489571d322189246DaFA5ebDe1F4699F498": {
       "name": "0x",
       "logo": "zrx.svg",
       "erc20": true,
       "symbol": "ZRX",
       "decimals": 18
     },
     "0xB563300A3BAc79FC09B93b6F84CE0d4465A2AC27": {
       "name": "RedCab",
       "logo": "redcab.png",
       "erc20": true,
       "symbol": "REDC",
       "decimals": 18
     },
     "0x957c30aB0426e0C93CD8241E2c60392d08c6aC8e": {
       "name": "Modum Token",
       "logo": "modum.svg",
       "erc20": true,
       "symbol": "MOD",
       "decimals": 0
     },
     "0x1a7a8BD9106F2B8D977E08582DC7d24c723ab0DB": {
       "name": "AppCoins",
       "logo": "appcoins.png",
       "erc20": true,
       "decimals": 18,
       "symbol": "APPC"
     },
     "0xdB25f211AB05b1c97D595516F45794528a807ad8": {
       "name": "STASIS EURS Token",
       "logo": "stasis-eurs.svg",
       "erc20": true,
       "decimals": 2,
       "symbol": "EURS"
     },
     "0xCA0e7269600d353F70b14Ad118A49575455C0f2f": {
       "name": "AMLT",
       "logo": "amlt.png",
       "erc20": true,
       "decimals": 18,
       "symbol": "AMLT"
     },
     "0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F": {
       "name": "Synthetix Network Token",
       "logo": "synthetix.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "SNX"
     },
     "0x57Ab1ec28D129707052df4dF418D58a2D46d5f51": {
       "name": "Synth sUSD",
       "logo": "synthetix_susd.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "SUSD"
     },
     "0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb": {
       "name": "Synth sETH",
       "logo": "synthetix_seth.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "SETH"
     },
     "0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6": {
       "name": "Synth sBTC",
       "logo": "synthetix_sbtc.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "SBTC"
     },
     "0x0DB8D8b76BC361bAcbB72E2C491E06085A97Ab31": {
       "name": "IQeon",
       "logo": "iqeon.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "IQN"
     },
     "0x000C100050E98C91f9114fa5Dd75CE6869Bf4F53": {
       "name": "CRYPTO10 Hedged",
       "logo": "c10.svg",
       "erc20": true,
       "symbol": "C10",
       "decimals": 18
     },
     "0x26E75307Fc0C021472fEb8F727839531F112f317": {
       "name": "Crypto20",
       "logo": "c20.svg",
       "erc20": true,
       "symbol": "C20",
       "decimals": 18
     },
     "0x06012c8cf97BEaD5deAe237070F9587f8E7A266d": {
       "name": "Crypto Kitties",
       "logo": "CryptoKitties-Kitty-13733.svg",
       "erc20": false,
       "erc721": true,
       "symbol": "CK",
       "decimals": 0
     },
     "0xE477292f1B3268687A29376116B0ED27A9c76170": {
       "name": "Herocoin",
       "logo": "herocoin_logo.png",
       "erc20": true,
       "symbol": "PLAY",
       "decimals": 18
     },
     "0x4F4f0Db4de903B88f2B1a2847971E231D54F8fd3": {
       "name": "Geens Platform Token",
       "logo": "gee-icon.svg",
       "erc20": true,
       "symbol": "GEE",
       "decimals": 8
     },
     "0xec67005c4E498Ec7f55E092bd1d35cbC47C91892": {
       "name": "Melon",
       "logo": "mln.jpg",
       "erc20": true,
       "symbol": "MLN",
       "decimals": 18
     },
     "0xba2184520A1cC49a6159c57e61E1844E085615B6": {
       "name": "HelloGold Token",
       "logo": "hgt.png",
       "erc20": true,
       "symbol": "HGT",
       "decimals": 8
     },
     "0xba358B6f5b4c0215650444B8C30D870B55050D2D": {
       "name": "Hub Token",
       "logo": "hub.svg",
       "erc20": true,
       "symbol": "HUB",
       "decimals": 18
     },
     "0xeAb43193CF0623073Ca89DB9B712796356FA7414": {
       "name": "GOLDX",
       "logo": "goldx.png",
       "erc20": true,
       "symbol": "GOLDX",
       "decimals": 18
     },
     "0x8727c112C712c4a03371AC87a74dD6aB104Af768": {
       "name": "Jetcoin Institute Token",
       "logo": "JETCOIN28.png",
       "erc20": true,
       "symbol": "JET",
       "decimals": 18
     },
     "0xf8e386EDa857484f5a12e4B5DAa9984E06E73705": {
       "name": "Indorse Token",
       "logo": "indorseLogo.jpg",
       "erc20": true,
       "symbol": "IND",
       "decimals": 18
     },
     "0xA54ddC7B3CcE7FC8b1E3Fa0256D0DB80D2c10970": {
       "name": "NEVERDIE Coin",
       "logo": "ndc.png",
       "erc20": true,
       "symbol": "NDC",
       "decimals": 18
     },
     "0x1c4481750daa5Ff521A2a7490d9981eD46465Dbd": {
       "name": "BlockMason Credit Protocol Token",
       "logo": "bcpt.svg",
       "erc20": true,
       "symbol": "BCPT",
       "decimals": 18
     },
     "0x20F7A3DdF244dc9299975b4Da1C39F8D5D75f05A": {
       "name": "Sapien Network Token",
       "logo": "spn.svg",
       "erc20": true,
       "symbol": "SPN",
       "decimals": 6
     },
     "0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0": {
       "name": "Loom Network Token",
       "logo": "loom.svg",
       "erc20": true,
       "symbol": "LOOM",
       "decimals": 18
     },
     "0x4F9254C83EB525f9FCf346490bbb3ed28a81C667": {
       "name": "Celer Network Token",
       "logo": "CELR.svg",
       "erc20": true,
       "symbol": "CELR",
       "decimals": 18
     },
     "0x71D01dB8d6a2fBEa7f8d434599C237980C234e4C": {
       "name": "Gladius",
       "logo": "gladius.svg",
       "erc20": true,
       "symbol": "GLA",
       "decimals": 8
     },
     "0xdd974D5C2e2928deA5F71b9825b8b646686BD200": {
       "name": "Kyber Network Crystal",
       "logo": "kyber.svg",
       "erc20": true,
       "symbol": "KNC",
       "decimals": 18
     },
     "0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C": {
       "name": "Bancor Network Token",
       "logo": "bancor.png",
       "erc20": true,
       "symbol": "BNT",
       "decimals": 18
     },
     "0xfa05A73FfE78ef8f1a739473e462c54bae6567D9": {
       "name": "Lunyr Token",
       "logo": "lun.png",
       "erc20": true,
       "symbol": "LUN",
       "decimals": 18
     },
     "0xC741f06082AA47F93729070aD0dD95E223Bda091": {
       "name": "LEDU Token",
       "logo": "edu.svg",
       "erc20": true,
       "symbol": "LEDU",
       "decimals": 8
     },
     "0x5c543e7AE0A1104f78406C340E9C64FD9fCE5170": {
       "name": "vSlice",
       "logo": "vslice.png",
       "erc20": true,
       "symbol": "VSL",
       "decimals": 18
     },
     "0x1f41E42D0a9e3c0Dd3BA15B527342783B43200A9": {
       "name": "Blockchain Capital",
       "logo": "bcap.svg",
       "erc20": true,
       "symbol": "BCAP",
       "decimals": 0
     },
     "0x6531f133e6DeeBe7F2dcE5A0441aA7ef330B4e53": {
       "name": "Chronobank TIME",
       "logo": "chronobank.png",
       "erc20": true,
       "symbol": "TIME",
       "decimals": 8
     },
     "0xE7775A6e9Bcf904eb39DA2b68c5efb4F9360e08C": {
       "name": "Token-as-a-Service",
       "logo": "taas-ico.png",
       "erc20": true,
       "symbol": "TAAS",
       "decimals": 6
     },
     "0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a": {
       "name": "Monolith TKN",
       "logo": "tkn.svg",
       "erc20": true,
       "symbol": "TKN",
       "decimals": 8
     },
     "0x08711D3B02C8758F2FB3ab4e80228418a7F8e39c": {
       "name": "Edgeless",
       "logo": "edgeless.jpg",
       "erc20": true,
       "symbol": "EDG",
       "decimals": 0
     },
     "0xf7B098298f7C69Fc14610bf71d5e02c60792894C": {
       "name": "Guppy",
       "logo": "guppy.png",
       "erc20": true,
       "symbol": "GUP",
       "decimals": 3
     },
     "0x4DF812F6064def1e5e029f1ca858777CC98D2D81": {
       "name": "Xaurum",
       "logo": "xaurum_logo.svg",
       "erc20": true,
       "symbol": "XAUR",
       "decimals": 8
     },
     "0xB9e7F8568e08d5659f5D29C4997173d84CdF2607": {
       "name": "Swarm City Token",
       "logo": "swt.jpg",
       "erc20": true,
       "symbol": "SWT",
       "decimals": 18
     },
     "0xCb94be6f13A1182E4A4B6140cb7bf2025d28e41B": {
       "name": "Trustcoin",
       "logo": "trustcoin.jpg",
       "erc20": true,
       "symbol": "TRST",
       "decimals": 6
     },
     "0xCB56b52316041A62B6b5D0583DcE4A8AE7a3C629": {
       "name": "Cigarettes",
       "logo": "cig.svg",
       "erc20": true,
       "symbol": "CIG",
       "decimals": 18
     },
     "0x960b236A07cf122663c4303350609A66A7B288C0": {
       "name": "Aragon Network Token v1",
       "logo": "antv1.svg",
       "erc20": true,
       "symbol": "ANTv1",
       "decimals": 18
     },
     "0x667088b212ce3d06a1b553a7221E1fD19000d9aF": {
       "name": "WINGS",
       "logo": "wings_logo.svg",
       "erc20": true,
       "symbol": "WINGS",
       "decimals": 18
     },
     "0x16484d73Ac08d2355F466d448D2b79D2039F6EBB": {
       "name": "KnoxsterCoin",
       "logo": "FortKnoxster.svg",
       "erc20": true,
       "symbol": "FKX",
       "decimals": 18
     },
     "0xAf30D2a7E90d7DC361c8C4585e9BB7D2F6f15bc7": {
       "name": "FirstBlood Token",
       "logo": "1st.svg",
       "erc20": true,
       "symbol": "1ST",
       "decimals": 18
     },
     "0x607F4C5BB672230e8672085532f7e901544a7375": {
       "name": "iExec RLC Token",
       "logo": "rlc.svg",
       "erc20": true,
       "symbol": "RLC",
       "decimals": 9
     },
     "0xaeC2E87E0A235266D9C5ADc9DEb4b2E29b54D009": {
       "name": "SingularDTV",
       "logo": "singulardtv.svg",
       "erc20": true,
       "symbol": "SNGLS",
       "decimals": 0
     },
     "0x888666CA69E0f178DED6D75b5726Cee99A87D698": {
       "name": "ICONOMI",
       "logo": "iconomi.png",
       "erc20": true,
       "symbol": "ICN",
       "decimals": 18
     },
     "0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A": {
       "name": "Digix",
       "logo": "DGD.png",
       "erc20": true,
       "symbol": "DGD",
       "decimals": 9
     },
     "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2": {
       "name": "Maker",
       "logo": "mkr.svg",
       "erc20": true,
       "symbol": "MKR",
       "decimals": 18
     },
     "0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359": {
       "name": "Sai Stablecoin v1.0",
       "logo": "sai.svg",
       "erc20": true,
       "symbol": "SAI",
       "decimals": 18
     },
     "0x6B175474E89094C44Da98b954EedeAC495271d0F": {
       "name": "Dai Stablecoin",
       "logo": "dai.svg",
       "erc20": true,
       "symbol": "DAI",
       "decimals": 18
     },
     "0xC477D038d5420C6A9e0b031712f61c5120090de9": {
       "name": "Boson Token",
       "logo": "boson.svg",
       "erc20": true,
       "symbol": "BOSON",
       "decimals": 18
     },
     "0xD46bA6D942050d489DBd938a2C909A5d5039A161": {
       "name": "Ampleforth",
       "logo": "ampl.svg",
       "erc20": true,
       "symbol": "AMPL",
       "decimals": 9
     },
     "0xDF2C7238198Ad8B389666574f2d8bc411A4b7428": {
       "name": "Mainframe Token",
       "logo": "Mainframe.png",
       "erc20": true,
       "symbol": "MFT",
       "decimals": 18
     },
     "0x0D8775F648430679A709E98d2b0Cb6250d2887EF": {
       "name": "Basic Attention Token",
       "logo": "bat.svg",
       "erc20": true,
       "symbol": "BAT",
       "decimals": 18
     },
     "0x4D8fc1453a0F359e99c9675954e656D80d996FbF": {
       "name": "BEE Token",
       "logo": "bee_token.svg",
       "erc20": true,
       "symbol": "BEE",
       "decimals": 18
     },
     "0x6810e776880C02933D47DB1b9fc05908e5386b96": {
       "name": "Gnosis Token",
       "logo": "gnosis.svg",
       "erc20": true,
       "symbol": "GNO",
       "decimals": 18
     },
     "0x514910771AF9Ca656af840dff83E8264EcF986CA": {
       "name": "Chainlink Token",
       "logo": "chainlink.svg",
       "erc20": true,
       "symbol": "LINK",
       "decimals": 18
     },
     "0xD8912C10681D8B21Fd3742244f44658dBA12264E": {
       "name": "Pluton",
       "logo": "plutus-god.svg",
       "erc20": true,
       "symbol": "PLU",
       "decimals": 18
     },
     "0x1985365e9f78359a9B6AD760e32412f4a445E862": {
       "name": "Reputation Old",
       "logo": "rep.svg",
       "erc20": true,
       "symbol": "REP",
       "decimals": 18
     },
     "0x221657776846890989a759BA2973e427DfF5C9bB": {
       "name": "Reputation",
       "logo": "repv2.svg",
       "erc20": true,
       "symbol": "REPv2",
       "decimals": 18
     },
     "0xd0929d411954c47438dc1d871dd6081F5C5e149c": {
       "name": "Refereum",
       "logo": "rfr.svg",
       "erc20": true,
       "symbol": "RFR",
       "decimals": 4
     },
     "0xb0866289e870D2efc282406cF4123Df6E5BcB652": {
       "name": "NoFakeCoin",
       "logo": "nfticon.png",
       "erc20": true,
       "symbol": "NFC",
       "decimals": 18
     },
     "0x2C4e8f2D746113d0696cE89B35F0d8bF88E0AEcA": {
       "name": "Open Simple Token",
       "logo": "OST.svg",
       "erc20": true,
       "symbol": "OST",
       "decimals": 18
     },
     "0x408e41876cCCDC0F92210600ef50372656052a38": {
       "name": "Republic Token",
       "logo": "ren.svg",
       "erc20": true,
       "symbol": "REN",
       "decimals": 18
     },
     "0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D": {
       "name": "renBTC",
       "logo": "renBTC.svg",
       "erc20": true,
       "symbol": "renBTC",
       "decimals": 8
     },
     "0x1C5db575E2Ff833E46a2E9864C22F4B22E0B37C2": {
       "name": "renZEC",
       "logo": "renZEC.svg",
       "erc20": true,
       "symbol": "renZEC",
       "decimals": 8
     },
     "0x459086F2376525BdCebA5bDDA135e4E9d3FeF5bf": {
       "name": "renBCH",
       "logo": "renBCH.svg",
       "erc20": true,
       "symbol": "renBCH",
       "decimals": 8
     },
     "0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5": {
       "name": "renFIL",
       "logo": "renFIL.svg",
       "erc20": true,
       "symbol": "renFIL",
       "decimals": 18
     },
     "0xa74476443119A942dE498590Fe1f2454d7D4aC0d": {
       "name": "Golem Network Token",
       "logo": "golem.svg",
       "erc20": true,
       "symbol": "GNT",
       "decimals": 18
     },
     "0x6888a16eA9792c15A4DCF2f6C623D055c8eDe792": {
       "name": "Spectiv Signal Token",
       "logo": "spectiv.svg",
       "erc20": true,
       "symbol": "SIG",
       "decimals": 18
     },
     "0x9a642d6b3368ddc662CA244bAdf32cDA716005BC": {
       "name": "Qtum",
       "logo": "qtum_28.png",
       "erc20": true,
       "decimals": 18,
       "symbol": "QTUM"
     },
     "0x1234567461d3f8Db7496581774Bd869C83D51c93": {
       "name": "BitClave",
       "logo": "bitclave.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "CAT"
     },
     "0x7d4b8Cce0591C9044a22ee543533b72E976E36C3": {
       "name": "Change",
       "logo": "change.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "CAG"
     },
     "0x1d462414fe14cf489c7A21CaC78509f4bF8CD7c0": {
       "name": "CanYaCoin",
       "logo": "CanYa.svg",
       "erc20": true,
       "decimals": 6,
       "symbol": "CAN"
     },
     "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72": {
       "name": "Ethereum Name Service",
       "logo": "ens.svg",
       "erc20": true,
       "decimals": 18,
       "symbol": "ENS"
     },
     "0x3d1BA9be9f66B8ee101911bC36D3fB562eaC2244": {
       "name": "Rivetz",
       "logo": "rivetz.png",
       "erc20": true,
       "decimals": 18,
       "symbol": "RVT"
     },
     "0x056017c55aE7AE32d12AeF7C679dF83A85ca75Ff": {
       "name": "Project Wyvern Token",
       "logo": "wyvern-logo.svg",
       "erc20": true,
       "symbol": "WYV",
       "decimals": 18
     },
     "0xfec0cF7fE078a500abf15F1284958F22049c2C7e": {
       "name": "Maecenas ART Token",
       "logo": "Maecenas.jpg",
       "erc20": true,
       "symbol": "ART",
       "decimals": 18
     },
     "0x05f4a42e251f2d52b8ed15E9FEdAacFcEF1FAD27": {
       "name": "Zilliqa",
       "logo": "zilliqa.svg",
       "erc20": true,
       "symbol": "ZIL",
       "decimals": 12
     },
     "0x986EE2B944c42D017F52Af21c4c69B84DBeA35d8": {
       "name": "BitMartToken",
       "logo": "bitmart.png",
       "erc20": true,
       "symbol": "BMX",
       "decimals": 18
     },
     "0x2af47a65da8CD66729b4209C22017d6A5C2d2400": {
       "name": "StandardBounties",
       "logo": "StandardBounties.png"
     },
     "0xF03f8D65BaFA598611C3495124093c56e8F638f0": {
       "name": "Viewly",
       "logo": "viewly.svg",
       "erc20": true,
       "symbol": "VIEW",
       "decimals": 18
     },
     "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": {
       "name": "Wrapped Ether",
       "logo": "weth.png",
       "erc20": true,
       "symbol": "WETH",
       "decimals": 18
     },
     "0xba5BDe662c17e2aDFF1075610382B9B691296350": {
       "name": "SuperRare Token",
       "logo": "rare.svg",
       "erc20": true,
       "symbol": "RARE",
       "decimals": 18
     },
     "0x6C8c6b02E7b2BE14d4fA6022Dfd6d75921D90E4E": {
       "name": "Compound Basic Attention Token",
       "logo": "ctoken-bat.svg",
       "erc20": true,
       "symbol": "cBAT",
       "decimals": 8
     },
     "0x70e36f6BF80a52b3B46b3aF8e106CC0ed743E8e4": {
       "name": "Compound Collateral",
       "logo": "ctoken-comp.svg",
       "erc20": true,
       "symbol": "cCOMP",
       "decimals": 8
     },
     "0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643": {
       "name": "Compound Dai",
       "logo": "ctoken-dai.svg",
       "erc20": true,
       "symbol": "cDAI",
       "decimals": 8
     },
     "0xF5DCe57282A584D2746FaF1593d3121Fcac444dC": {
       "name": "Compound Sai (Legacy Dai)",
       "logo": "ctoken-sai.svg",
       "erc20": true,
       "symbol": "cSAI",
       "decimals": 8
     },
     "0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5": {
       "name": "Compound Ether",
       "logo": "ctoken-eth.svg",
       "erc20": true,
       "symbol": "cETH",
       "decimals": 8
     },
     "0x158079Ee67Fce2f58472A96584A73C7Ab9AC95c1": {
       "name": "Compound Augur",
       "logo": "ctoken-rep.svg",
       "erc20": true,
       "symbol": "cREP",
       "decimals": 8
     },
     "0x39AA39c021dfbaE8faC545936693aC917d5E7563": {
       "name": "Compound USD Coin",
       "logo": "ctoken-usdc.svg",
       "erc20": true,
       "symbol": "cUSDC",
       "decimals": 8
     },
     "0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9": {
       "name": "Compound Tether",
       "logo": "ctoken-usdt.svg",
       "erc20": true,
       "symbol": "cUSDT",
       "decimals": 8
     },
     "0xC11b1268C1A384e55C48c2391d8d480264A3A7F4": {
       "name": "Compound Wrapped BTC",
       "logo": "ctoken-wbtc.svg",
       "erc20": true,
       "symbol": "cWBTC",
       "decimals": 8
     },
     "0xB3319f5D18Bc0D84dD1b4825Dcde5d5f7266d407": {
       "name": "Compound 0x",
       "logo": "ctoken-zrx.svg",
       "erc20": true,
       "symbol": "cZRX",
       "decimals": 8
     },
     "0xc00e94Cb662C3520282E6f5717214004A7f26888": {
       "name": "Compound",
       "logo": "comp.svg",
       "erc20": true,
       "symbol": "COMP",
       "decimals": 18
     },
     "0xFFE02ee4C69eDf1b340fCaD64fbd6b37a7b9e265": {
       "name": "NANJCOIN",
       "logo": "nanjcoin.svg",
       "erc20": true,
       "symbol": "NANJ",
       "decimals": 8
     },
     "0xB6eD7644C69416d67B522e20bC294A9a9B405B31": {
       "name": "0xBitcoin Token",
       "logo": "0xbitcoin.svg",
       "erc20": true,
       "symbol": "0xBTC",
       "decimals": 8
     },
     "0x744d70FDBE2Ba4CF95131626614a1763DF805B9E": {
       "name": "Status Network Token",
       "logo": "snt.svg",
       "erc20": true,
       "symbol": "SNT",
       "decimals": 18
     },
     "0x42d6622deCe394b54999Fbd73D108123806f6a18": {
       "name": "SPANK",
       "logo": "SPANK.png",
       "erc20": true,
       "symbol": "SPANK",
       "decimals": 18
     },
     "0x6B01c3170ae1EFEBEe1a3159172CB3F7A5ECf9E5": {
       "name": "BOOTY",
       "logo": "booty.png",
       "erc20": true,
       "symbol": "BOOTY",
       "decimals": 18
     },
     "0x4a6058666cf1057eaC3CD3A5a614620547559fc9": {
       "name": "Brickblock",
       "logo": "Brickblock.svg",
       "erc20": true,
       "symbol": "BBK",
       "decimals": 18
     },
     "0x80fB784B7eD66730e8b1DBd9820aFD29931aab03": {
       "name": "ETHLend Token",
       "logo": "lend.svg",
       "erc20": true,
       "symbol": "LEND",
       "decimals": 18
     },
     "0x48FF53777F747cFB694101222a944dE070c15D36": {
       "name": "Ether Kingdoms Token",
       "logo": "IMP.svg",
       "erc20": true,
       "symbol": "IMP",
       "decimals": 7
     },
     "0x02F61Fd266DA6E8B102D4121f5CE7b992640CF98": {
       "name": "LikeCoin",
       "logo": "like.svg",
       "erc20": true,
       "symbol": "LIKE",
       "decimals": 18
     },
     "0xB8c77482e45F1F44dE1745F52C74426C631bDD52": {
       "name": "Binance Coin",
       "logo": "bnb.png",
       "erc20": true,
       "symbol": "BNB",
       "decimals": 18
     },
     "0xD850942eF8811f2A866692A623011bDE52a462C1": {
       "name": "VeChain",
       "logo": "ven.svg",
       "erc20": true,
       "symbol": "VEN",
       "decimals": 18
     },
     "0xb5A5F22694352C15B00323844aD545ABb2B11028": {
       "name": "ICON",
       "logo": "icon-icx-logo.png",
       "erc20": true,
       "symbol": "ICX",
       "decimals": 18
     },
     "0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6": {
       "name": "Aergo",
       "logo": "Aergo.svg",
       "erc20": true,
       "symbol": "AERGO",
       "decimals": 18
     },
     "0x5CA9a71B1d01849C0a95490Cc00559717fCF0D1d": {
       "name": "Aeternity",
       "logo": "aeternity.svg",
       "erc20": true,
       "symbol": "AE",
       "decimals": 18
     },
     "0xd4fa1460F537bb9085d22C7bcCB5DD450Ef28e3a": {
       "name": "Populous",
       "logo": "FEATURED.POP_.png",
       "erc20": true,
       "symbol": "PPT",
       "decimals": 8
     },
     "0xFA1a856Cfa3409CFa145Fa4e20Eb270dF3EB21ab": {
       "name": "IOST",
       "logo": "iost.png",
       "erc20": true,
       "symbol": "IOST",
       "decimals": 18
     },
     "0x039B5649A59967e3e936D7471f9c3700100Ee1ab": {
       "name": "Kucoin Shares",
       "logo": "kucoin.svg",
       "erc20": true,
       "symbol": "KCS",
       "decimals": 6
     },
     "0x3893b9422Cd5D70a81eDeFfe3d5A1c6A978310BB": {
       "name": "Mithril",
       "logo": "mithril-featured.png",
       "erc20": true,
       "symbol": "MITH",
       "decimals": 18
     },
     "0xb7cB1C96dB6B22b0D3d9536E0108d062BD488F74": {
       "name": "Walton",
       "logo": "waltonchain.png",
       "erc20": true,
       "symbol": "WTC",
       "decimals": 18
     },
     "0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671": {
       "name": "Numeraire",
       "logo": "nmr.png",
       "erc20": true,
       "symbol": "NMR",
       "decimals": 18
     },
     "0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd": {
       "name": "Gemini Dollar",
       "logo": "gusd.svg",
       "erc20": true,
       "symbol": "GUSD",
       "decimals": 2
     },
     "0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe": {
       "name": "StableUSD",
       "logo": "usds.svg",
       "erc20": true,
       "symbol": "USDS",
       "decimals": 6
     },
     "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": {
       "name": "USD Coin",
       "logo": "usdc.svg",
       "erc20": true,
       "symbol": "USDC",
       "decimals": 6
     },
     "0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d": {
       "name": "Pinakion Token",
       "logo": "pinakion.svg",
       "erc20": true,
       "symbol": "PNK",
       "decimals": 18
     },
     "0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b": {
       "name": "FunFair",
       "logo": "fun.svg",
       "erc20": true,
       "symbol": "FUN",
       "decimals": 8
     },
     "0xcA2796F9F61dc7b238Aab043971e49c6164DF375": {
       "name": "YGGDRASH YEED Token",
       "logo": "yggdrash.svg",
       "erc20": true,
       "symbol": "YEED",
       "decimals": 18
     },
     "0x03e3f0c25965f13DbbC58246738C183E27b26a56": {
       "name": "Disciplina Token",
       "logo": "disciplina.svg",
       "erc20": true,
       "symbol": "DSCP",
       "decimals": 18
     },
     "0xE814aeE960a85208C3dB542C53E7D4a6C8D5f60F": {
       "name": "Chronologic DAY Token",
       "logo": "DAY.png",
       "erc20": true,
       "symbol": "DAY",
       "decimals": 18
     },
     "0x7E43581b19ab509BCF9397a2eFd1ab10233f27dE": {
       "name": "PLATINUM",
       "logo": "plat.png",
       "erc20": true,
       "symbol": "PLAT",
       "decimals": 18
     },
     "0x8E870D67F660D95d5be530380D0eC0bd388289E1": {
       "name": "Pax Dollar",
       "logo": "usdp.svg",
       "erc20": true,
       "symbol": "USDP",
       "decimals": 18
     },
     "0x45804880De22913dAFE09f4980848ECE6EcbAf78": {
       "name": "PAX Gold",
       "logo": "paxg.svg",
       "erc20": true,
       "symbol": "PAXG",
       "decimals": 18
     },
     "0x0000000000085d4780B73119b644AE5ecd22b376": {
       "name": "TrueUSD",
       "logo": "TUSD.svg",
       "erc20": true,
       "symbol": "TUSD",
       "decimals": 18
     },
     "0x4Fabb145d64652a948d72533023f6E7A623C7C53": {
       "name": "Binance USD",
       "logo": "busd.svg",
       "erc20": true,
       "symbol": "BUSD",
       "decimals": 18
     },
     "0x23Ccc43365D9dD3882eab88F43d515208f832430": {
       "name": "MidasProtocol",
       "logo": "MAS.png",
       "erc20": true,
       "symbol": "MAS",
       "decimals": 18
     },
     "0xC16b542ff490e01fcc0DC58a60e1EFdc3e357cA6": {
       "name": "ICE ROCK MINING",
       "logo": "rock2.png",
       "erc20": true,
       "symbol": "ROCK2",
       "decimals": 0
     },
     "0x6758B7d441a9739b98552B373703d8d3d14f9e62": {
       "name": "POA20 Token",
       "logo": "POA20.png",
       "erc20": true,
       "symbol": "POA20",
       "decimals": 18
     },
     "0x3DB6Ba6ab6F95efed1a6E794caD492fAAabF294D": {
       "name": "LTO Network Token (old)",
       "logo": "LTO.svg",
       "erc20": true,
       "symbol": "LTOOLD",
       "decimals": 8
     },
     "0xd01409314aCb3b245CEa9500eCE3F6Fd4d70ea30": {
       "name": "LTO Network Token",
       "logo": "LTO.svg",
       "erc20": true,
       "symbol": "LTO",
       "decimals": 8
     },
     "0xfeF4185594457050cC9c23980d301908FE057Bb1": {
       "name": "VIDT Datalink",
       "logo": "VIDT.svg",
       "erc20": true,
       "symbol": "VIDT",
       "decimals": 18
     },
     "0x7064aAb39A0Fcf7221c3396719D0917a65E35515": {
       "name": "Cpollo",
       "logo": "Cpollo.svg",
       "erc20": true,
       "symbol": "CPLO",
       "decimals": 18
     },
     "0xd8446236FA95b9b5f9fd0f8E7Df1a944823c683d": {
       "name": "NEEO",
       "logo": "neeo.png",
       "erc20": true,
       "symbol": "NEEO",
       "decimals": 18
     },
     "0xA823E6722006afe99E91c30FF5295052fe6b8E32": {
       "name": "Neumark",
       "logo": "neu.svg",
       "erc20": true,
       "symbol": "NEU",
       "decimals": 18
     },
     "0x8f693ca8D21b157107184d29D398A8D082b38b76": {
       "name": "Streamr",
       "logo": "streamr.svg",
       "erc20": true,
       "symbol": "DATA",
       "decimals": 18
     },
     "0x0Cf0Ee63788A0849fE5297F3407f701E122cC023": {
       "name": "Streamr (old)",
       "logo": "streamr_old.svg",
       "erc20": true,
       "symbol": "XDATA",
       "decimals": 18
     },
     "0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b": {
       "name": "Crypto.com Chain",
       "logo": "CRO.png",
       "erc20": true,
       "symbol": "CRO",
       "decimals": 8
     },
     "0xF70a642bD387F94380fFb90451C2c81d4Eb82CBc": {
       "name": "STAR",
       "logo": "starbase.png",
       "erc20": true,
       "symbol": "STAR",
       "decimals": 18
     },
     "0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098": {
       "name": "Santiment",
       "logo": "santiment.svg",
       "erc20": true,
       "symbol": "SAN",
       "decimals": 18
     },
     "0x58b6A8A3302369DAEc383334672404Ee733aB239": {
       "name": "Livepeer",
       "logo": "livepeer.png",
       "erc20": true,
       "symbol": "LPT",
       "decimals": 18
     },
     "0x6fe56C0bcdD471359019FcBC48863d6c3e9d4F41": {
       "name": "Props",
       "logo": "props.png",
       "erc20": true,
       "symbol": "PROPS",
       "decimals": 18
     },
     "0x6c6EE5e31d828De241282B9606C8e98Ea48526E2": {
       "name": "HoloToken",
       "logo": "holotoken.png",
       "erc20": true,
       "symbol": "HOT",
       "decimals": 18
     },
     "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c": {
       "name": "Enjin Coin",
       "logo": "enj.svg",
       "erc20": true,
       "symbol": "ENJ",
       "decimals": 18
     },
     "0xbf2179859fc6D5BEE9Bf9158632Dc51678a4100e": {
       "name": "AELF",
       "logo": "aelf.png",
       "erc20": true,
       "symbol": "ELF",
       "decimals": 18
     },
     "0x39Bb259F66E1C59d5ABEF88375979b4D20D98022": {
       "name": "Wax",
       "logo": "wax.png",
       "erc20": true,
       "symbol": "WAX",
       "decimals": 8
     },
     "0x595832F8FC6BF59c85C527fEC3740A1b7a361269": {
       "name": "PowerLedger",
       "logo": "power.png",
       "erc20": true,
       "symbol": "POWR",
       "decimals": 6
     },
     "0x28dee01D53FED0Edf5f6E310BF8Ef9311513Ae40": {
       "name": "BlitzPredict",
       "logo": "blitzpredict.svg",
       "erc20": true,
       "symbol": "XBP",
       "decimals": 18
     },
     "0x33349B282065b0284d756F0577FB39c158F935e6": {
       "name": "Maple Finance",
       "logo": "maple-finance.svg",
       "erc20": true,
       "erc721": false,
       "symbol": "MPL",
       "decimals": 18
     },
     "0xFBeef911Dc5821886e1dda71586d90eD28174B7d": {
       "name": "KnownOrigin",
       "logo": "knownorigin.svg",
       "erc20": true,
       "symbol": "KODA",
       "decimals": 0
     },
     "0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26": {
       "name": "Origin Protocol",
       "logo": "originprotocol.png",
       "erc20": true,
       "symbol": "OGN",
       "decimals": 18
     },
     "0x2A8e1E676Ec238d8A992307B495b45B3fEAa5e86": {
       "name": "Origin Dollar",
       "logo": "ousd.svg",
       "erc20": true,
       "symbol": "OUSD",
       "decimals": 18
     },
     "0x9c354503C38481a7A7a51629142963F98eCC12D0": {
       "name": "Origin Dollar Governance",
       "logo": "ogv.svg",
       "erc20": true,
       "symbol": "OGV",
       "decimals": 18
     },
     "0x0C4576Ca1c365868E162554AF8e385dc3e7C66D9": {
       "name": "Vote Escrowed Origin Dollar Governance",
       "logo": "veogv.svg",
       "erc20": true,
       "symbol": "veOGV",
       "decimals": 18
     },
     "0x8f8221aFbB33998d8584A2B05749bA73c37a938a": {
       "name": "Request",
       "logo": "request.svg",
       "erc20": true,
       "symbol": "REQ",
       "decimals": 18
     },
     "0x2aEa4Add166EBf38b63d09a75dE1a7b94Aa24163": {
       "name": "Kudos",
       "logo": "kudos.svg",
       "erc20": false,
       "erc721": true,
       "symbol": "KDO",
       "decimals": 0
     },
     "0x14C926F2290044B647e1Bf2072e67B495eff1905": {
       "name": "Bethereum",
       "logo": "bether.jpg",
       "erc20": true,
       "symbol": "BETHER",
       "decimals": 18
     },
     "0x9AAb071B4129B083B01cB5A0Cb513Ce7ecA26fa5": {
       "name": "Hunt Token",
       "logo": "hunt.png",
       "erc20": true,
       "symbol": "HUNT",
       "decimals": 18
     },
     "0xFEF3884b603C33EF8eD4183346E093A173C94da6": {
       "name": "Metamorph",
       "logo": "metamorph.svg",
       "erc20": true,
       "symbol": "METM",
       "decimals": 18
     },
     "0x0AbdAce70D3790235af448C88547603b945604ea": {
       "name": "district0x",
       "logo": "district0x.svg",
       "erc20": true,
       "symbol": "DNT",
       "decimals": 18
     },
     "0x0C6144c16af288948C8fdB37fD8fEc94bfF3d1d9": {
       "name": "Neutral Dollar",
       "logo": "neutraldollar.png",
       "erc20": true,
       "symbol": "NUSD",
       "decimals": 6
     },
     "0xB26631c6dda06aD89B93C71400D25692de89c068": {
       "name": "Minds",
       "logo": "minds.svg",
       "erc20": true,
       "symbol": "MINDS",
       "decimals": 18
     },
     "0xBb1f24C0c1554b9990222f036b0AaD6Ee4CAec29": {
       "name": "CryptoSoul",
       "logo": "SOUL.svg",
       "erc20": true,
       "symbol": "SOUL",
       "decimals": 18
     },
     "0xFF0E5e014cf97e0615cb50F6f39Da6388E2FaE6E": {
       "name": "Origo",
       "logo": "origo.svg",
       "erc20": true,
       "symbol": "OGO",
       "decimals": 18
     },
     "0x0cB8D0B37C7487b11d57F1f33dEfA2B1d3cFccfE": {
       "name": "DANKToken",
       "logo": "dank-logo.svg",
       "erc20": true,
       "symbol": "DANK",
       "decimals": 18
     },
     "0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430": {
       "name": "PlayDapp Token",
       "logo": "playdapp.svg",
       "erc20": true,
       "symbol": "PLA",
       "decimals": 18
     },
     "0xE9e3F9cfc1A64DFca53614a0182CFAD56c10624F": {
       "name": "Su Squares",
       "logo": "Su-Squares.svg",
       "erc20": false,
       "erc721": true,
       "symbol": "SU",
       "decimals": 0
     },
     "0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a": {
       "name": "ROOK",
       "logo": "Rook.svg",
       "erc20": true,
       "symbol": "ROOK",
       "decimals": 18
     },
     "0xbE9375C6a420D2eEB258962efB95551A5b722803": {
       "name": "StormX Token",
       "logo": "stormx.svg",
       "erc20": true,
       "symbol": "STMX",
       "decimals": 18
     },
     "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0": {
       "name": "Matic Network Token",
       "logo": "matic-network-logo.svg",
       "erc20": true,
       "symbol": "MATIC",
       "decimals": 18
     },
     "0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC": {
       "name": "Polymath",
       "logo": "polymath_logo.svg",
       "erc20": true,
       "symbol": "POLY",
       "decimals": 18
     },
     "0x0a50C93c762fDD6E56D86215C24AaAD43aB629aa": {
       "name": "LGO Token",
       "logo": "lgo.png",
       "erc20": true,
       "symbol": "LGO",
       "decimals": 8
     },
     "0xc8Cac7672f4669685817cF332a33Eb249F085475": {
       "name": "LivenCoin",
       "logo": "LVN.svg",
       "erc20": true,
       "symbol": "LVN",
       "decimals": 18
     },
     "0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD": {
       "name": "Loopring",
       "logo": "LRC.svg",
       "erc20": true,
       "symbol": "LRC",
       "decimals": 18
     },
     "0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6": {
       "name": "Raiden Network Token",
       "logo": "raiden.svg",
       "erc20": true,
       "symbol": "RDN",
       "decimals": 18
     },
     "0x66fD97a78d8854fEc445cd1C80a07896B0b4851f": {
       "name": "Lunch Money",
       "logo": "LMY.svg",
       "erc20": true,
       "symbol": "LMY",
       "decimals": 18
     },
     "0x679131F591B4f369acB8cd8c51E68596806c3916": {
       "name": "Trustlines Network Token",
       "logo": "TLN128.png",
       "erc20": true,
       "symbol": "TLN",
       "decimals": 18
     },
     "0xdcD85914b8aE28c1E62f1C488E1D968D5aaFfE2b": {
       "name": "TOP Network Token",
       "logo": "TOP.svg",
       "erc20": true,
       "symbol": "TOP",
       "decimals": 18
     },
     "0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85": {
       "name": "Fetch",
       "logo": "fetch.svg",
       "erc20": true,
       "symbol": "FET",
       "decimals": 18
     },
     "0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14": {
       "name": "Mettalex",
       "logo": "mettalex.svg",
       "erc20": true,
       "symbol": "MTLX",
       "decimals": 18
     },
     "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984": {
       "name": "Uniswap",
       "logo": "uni.svg",
       "erc20": true,
       "symbol": "UNI",
       "decimals": 18
     },
     "0x2e6539edc3b76f1E21B71d214527FAbA875F70F3": {
       "name": "Yearn Finance Dot",
       "logo": "YFDOT.svg",
       "erc20": true,
       "symbol": "YFDOT",
       "decimals": 18
     },
     "0x9F284E1337A815fe77D2Ff4aE46544645B20c5ff": {
       "name": "Darwinia Commitment Token",
       "logo": "kton.svg",
       "erc20": true,
       "symbol": "KTON",
       "decimals": 18
     },
     "0xE48972fCd82a274411c01834e2f031D4377Fa2c0": {
       "name": "TwoKeyEconomy",
       "logo": "2key.svg",
       "erc20": true,
       "symbol": "2KEY",
       "decimals": 18
     },
     "0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d": {
       "name": "Fuse Token",
       "logo": "fuse.svg",
       "erc20": true,
       "symbol": "FUSE",
       "decimals": 18
     },
     "0x0Ae055097C6d159879521C384F1D2123D1f195e6": {
       "name": "STAKE Token",
       "logo": "stake.png",
       "erc20": true,
       "symbol": "STAKE",
       "decimals": 18
     },
     "0x5dc60C4D5e75D22588FA17fFEB90A63E535efCE0": {
       "name": "dKargo",
       "logo": "DKA.svg",
       "erc20": true,
       "symbol": "DKA",
       "decimals": 18
     },
     "0x9469D013805bFfB7D3DEBe5E7839237e535ec483": {
       "name": "Darwinia Network Native Token",
       "logo": "ring.svg",
       "erc20": true,
       "symbol": "RING",
       "decimals": 18
     },
     "0xA31B1767e09f842ECFd4bc471Fe44F830E3891AA": {
       "name": "ROOBEE",
       "logo": "ROOBEE.svg",
       "erc20": true,
       "symbol": "ROOBEE",
       "decimals": 18
     },
     "0xC7e43A1c8E118aA2965F5EAbe0e718D83DB7A63C": {
       "name": "ZCore Token",
       "logo": "zcrt.svg",
       "erc20": true,
       "symbol": "ZCRT",
       "decimals": 18
     },
     "0x08d32b0da63e2C3bcF8019c9c5d849d7a9d791e6": {
       "name": "Dentacoin Token",
       "logo": "dcn.svg",
       "erc20": true,
       "symbol": "DCN",
       "decimals": 0
     },
     "0xe2f2a5C287993345a840Db3B0845fbC70f5935a5": {
       "name": "mStable USD",
       "logo": "mUSD.svg",
       "erc20": true,
       "symbol": "mUSD",
       "decimals": 18
     },
     "0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2": {
       "name": "Meta",
       "logo": "MTA.svg",
       "erc20": true,
       "symbol": "MTA",
       "decimals": 18
     },
     "0xe3818504c1B32bF1557b16C238B2E01Fd3149C17": {
       "name": "Pillar",
       "logo": "plr.svg",
       "erc20": true,
       "symbol": "PLR",
       "decimals": 18
     },
     "0xba100000625a3754423978a60c9317c58a424e3D": {
       "name": "Balancer (BAL)",
       "logo": "balancer.svg",
       "erc20": true,
       "symbol": "BAL",
       "decimals": 18
     },
     "0x8a854288a5976036A725879164Ca3e91d30c6A1B": {
       "name": "Guaranteed Entrance Token",
       "logo": "get-logo.svg",
       "erc20": true,
       "symbol": "GET",
       "decimals": 18
     },
     "0x2781246fe707bB15CeE3e5ea354e2154a2877B16": {
       "name": "Elysia",
       "logo": "el.svg",
       "erc20": true,
       "symbol": "EL",
       "decimals": 18
     },
     "0xc12d099be31567add4e4e4d0D45691C3F58f5663": {
       "name": "Auctus",
       "logo": "auc.svg",
       "erc20": true,
       "symbol": "AUC",
       "decimals": 18
     },
     "0xdF574c24545E5FfEcb9a659c229253D4111d87e1": {
       "name": "HUSD",
       "logo": "HUSD.svg",
       "erc20": true,
       "symbol": "HUSD",
       "decimals": 8
     },
     "0x2ecB13A8c458c379c4d9a7259e202De03c8F3D19": {
       "name": "Block-Chain.com Token",
       "logo": "block-chain.svg",
       "erc20": true,
       "symbol": "BC",
       "decimals": 18
     },
     "0x38e4adB44ef08F22F5B5b76A8f0c2d0dCbE7DcA1": {
       "name": "Concentrated Voting Power",
       "logo": "PowerPool.svg",
       "erc20": true,
       "symbol": "CVP",
       "decimals": 18
     },
     "0xa117000000f279D81A1D3cc75430fAA017FA5A2e": {
       "name": "Aragon Network Token",
       "logo": "antv2.svg",
       "erc20": true,
       "symbol": "ANT",
       "decimals": 18
     },
     "0xDecade1c6Bf2cD9fb89aFad73e4a519C867adcF5": {
       "name": "Experty Wisdom Token",
       "logo": "wisdom-token.svg",
       "erc20": true,
       "symbol": "WIS",
       "decimals": 18
     },
     "0x7D85e23014F84E6E21d5663aCD8751bEF3562352": {
       "name": "Axion Old",
       "logo": "obsolete.svg",
       "erc20": true,
       "symbol": "AXNv1",
       "decimals": 18
     },
     "0x358AA737e033F34df7c54306960a38d09AaBd523": {
       "name": "Ares Token",
       "logo": "ares.svg",
       "erc20": true,
       "symbol": "ARES",
       "decimals": 18
     },
     "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206": {
       "name": "NEXO",
       "logo": "NEXO.svg",
       "erc20": true,
       "symbol": "NEXO",
       "decimals": 18
     },
     "0x4612021C75809160Be60DB21fBc9D6adD0B32deF": {
       "name": "BCP Coin",
       "logo": "BCP.svg",
       "erc20": true,
       "symbol": "BCP",
       "decimals": 18
     },
     "0xE9E73E1aE76D17A16cC53E3e87a9a7dA78834d37": {
       "name": "Camp",
       "logo": "Camp.svg",
       "erc20": true,
       "symbol": "CAMP",
       "decimals": 18
     },
     "0xdacD69347dE42baBfAEcD09dC88958378780FB62": {
       "name": "Atari Token",
       "logo": "atrilast.svg",
       "erc20": true,
       "symbol": "ATRI",
       "decimals": 0
     },
     "0x71F85B2E46976bD21302B64329868fd15eb0D127": {
       "name": "Axion",
       "logo": "axion.svg",
       "erc20": true,
       "symbol": "AXN",
       "decimals": 18
     },
     "0xc944E90C64B2c07662A292be6244BDf05Cda44a7": {
       "name": "Graph Token",
       "logo": "graphToken.svg",
       "erc20": true,
       "symbol": "GRT",
       "decimals": 18
     },
     "0xFE3E6a25e6b192A42a44ecDDCd13796471735ACf": {
       "name": "Reef Finance",
       "logo": "reef.svg",
       "erc20": true,
       "symbol": "REEF",
       "decimals": 18
     },
     "0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd": {
       "name": "DODO bird",
       "logo": "DODO.svg",
       "erc20": true,
       "symbol": "DODO",
       "decimals": 18
     },
     "0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496": {
       "name": "NAOS Finance",
       "logo": "naos.svg",
       "erc20": true,
       "symbol": "NAOS",
       "decimals": 18
     },
     "0x111111111117dC0aa78b770fA6A738034120C302": {
       "name": "1INCH Token",
       "logo": "1inch.svg",
       "erc20": true,
       "symbol": "1INCH",
       "decimals": 18
     },
     "0x27702a26126e0B3702af63Ee09aC4d1A084EF628": {
       "name": "Aleph.im Token",
       "logo": "aleph.svg",
       "erc20": true,
       "symbol": "ALEPH",
       "decimals": 18
     },
     "0x32a7C02e79c4ea1008dD6564b35F131428673c41": {
       "name": "CRUST",
       "logo": "crust.svg",
       "erc20": true,
       "symbol": "CRU",
       "decimals": 18
     },
     "0x3472A5A71965499acd81997a54BBA8D852C6E53d": {
       "name": "BADGER",
       "logo": "badger.svg",
       "erc20": true,
       "symbol": "BADGER",
       "decimals": 18
     },
     "0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436": {
       "name": "Kanpeki",
       "logo": "kanpeki-green.svg",
       "erc20": true,
       "symbol": "KAE",
       "decimals": 18
     },
     "0xDcB01cc464238396E213a6fDd933E36796eAfF9f": {
       "name": "Yield",
       "logo": "yield-logo.svg",
       "erc20": true,
       "symbol": "YLD",
       "decimals": 18
     },
     "0x968F6f898a6Df937fC1859b323aC2F14643e3fED": {
       "name": "Newscrypto",
       "logo": "NWC.svg",
       "erc20": true,
       "symbol": "NWC",
       "decimals": 18
     },
     "0xeb269732ab75A6fD61Ea60b06fE994cD32a83549": {
       "name": "dForce USDx",
       "logo": "USDx.svg",
       "erc20": true,
       "symbol": "USDx",
       "decimals": 18
     },
     "0x6e0daDE58D2d89eBBe7aFc384e3E4f15b70b14D8": {
       "name": "QuiverX",
       "logo": "quiverx.svg",
       "erc20": true,
       "symbol": "QRX",
       "decimals": 18
     },
     "0x0beAD9a1bcc1b84D06E3f2df67E3549Fd55aB054": {
       "name": "EURxb",
       "logo": "eurxb.svg",
       "erc20": true,
       "symbol": "EURxb",
       "decimals": 18
     },
     "0x9F9c8ec3534c3cE16F928381372BfbFBFb9F4D24": {
       "name": "GraphLinq",
       "logo": "glq.svg",
       "erc20": true,
       "symbol": "GLQ",
       "decimals": 18
     },
     "0xEB58343b36C7528F23CAAe63a150240241310049": {
       "name": "NBU",
       "logo": "NBU.svg",
       "erc20": true,
       "symbol": "NBU",
       "decimals": 18
     },
     "0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4": {
       "name": "Route",
       "logo": "route.svg",
       "erc20": true,
       "symbol": "ROUTE",
       "decimals": 18
     },
     "0x75739d5944534115d7C54ee8C73F186D793BAE02": {
       "name": "Collective",
       "logo": "CO2.svg",
       "erc20": true,
       "symbol": "CO2",
       "decimals": 18
     },
     "0x35bD01FC9d6D5D81CA9E055Db88Dc49aa2c699A8": {
       "name": "FWB Pro",
       "logo": "FWB.svg",
       "erc20": true,
       "symbol": "FWB",
       "decimals": 18
     },
     "0x9B99CcA871Be05119B2012fd4474731dd653FEBe": {
       "name": "Antimatter",
       "logo": "antimatter.svg",
       "erc20": true,
       "symbol": "MATTER",
       "decimals": 18
     },
     "0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24": {
       "name": "Render Token",
       "logo": "RNDR.svg",
       "erc20": true,
       "symbol": "RNDR",
       "decimals": 18
     },
     "0xDf49C9f599A0A9049D97CFF34D0C30E468987389": {
       "name": "SaTT",
       "logo": "SaTT.svg",
       "erc20": true,
       "symbol": "SATT",
       "decimals": 18
     },
     "0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0": {
       "name": "dForce",
       "logo": "DF.svg",
       "erc20": true,
       "symbol": "DF",
       "decimals": 18
     },
     "0x6e1A19F235bE7ED8E3369eF73b196C07257494DE": {
       "name": "Wrapped Filecoin",
       "logo": "WFIL.svg",
       "erc20": true,
       "symbol": "WFIL",
       "decimals": 18
     },
     "0xE452E6Ea2dDeB012e20dB73bf5d3863A3Ac8d77a": {
       "name": "Wrapped Celo",
       "logo": "WCELO.svg",
       "erc20": true,
       "symbol": "wCELO",
       "decimals": 18
     },
     "0x4A64515E5E1d1073e83f30cB97BEd20400b66E10": {
       "name": "Wrapped ZEC",
       "logo": "WZEC.svg",
       "erc20": true,
       "symbol": "WZEC",
       "decimals": 18
     },
     "0xA3865E64121537b5b59B5e239Db4aCBe6F36aa74": {
       "name": "Wrapped Tezos",
       "logo": "WXTZ.svg",
       "erc20": true,
       "symbol": "WXTZ",
       "decimals": 18
     },
     "0x53c4871322Bb47e7A24136fce291a6dcC832a294": {
       "name": "Wrapped Litecoin",
       "logo": "WLTC.svg",
       "erc20": true,
       "symbol": "WLTC",
       "decimals": 18
     },
     "0xad3E3Fc59dff318BecEaAb7D00EB4F68b1EcF195": {
       "name": "Wrapped Celo USD",
       "logo": "WCUSD.svg",
       "erc20": true,
       "symbol": "wCUSD",
       "decimals": 18
     },
     "0x85D7bdfc9c3426b33A684241eEEE70385Bc42820": {
       "name": "Wrapped Kadena",
       "logo": "WKDA.svg",
       "erc20": true,
       "symbol": "WKDA",
       "decimals": 18
     },
     "0x39fBBABf11738317a448031930706cd3e612e1B9": {
       "name": "Wrapped XRP",
       "logo": "WXRP.svg",
       "erc20": true,
       "symbol": "WXRP",
       "decimals": 18
     },
     "0xBBC7f7A6AADAc103769C66CBC69AB720f7F9Eae3": {
       "name": "INX Token",
       "logo": "INX.svg",
       "erc20": true,
       "symbol": "INX",
       "decimals": 18
     },
     "0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421": {
       "name": "Vesper",
       "logo": "Vesper-VSP-Token.svg",
       "erc20": true,
       "symbol": "VSP",
       "decimals": 18
     },
     "0x544c42fBB96B39B21DF61cf322b5EDC285EE7429": {
       "name": "InsurAce",
       "logo": "INSUR.svg",
       "erc20": true,
       "symbol": "INSUR",
       "decimals": 18
     },
     "0xcC665390b03c5D324D8fAF81C15eCee29A73bCB4": {
       "name": "ChainSwap.com Governance Token",
       "logo": "chainswap.svg",
       "erc20": true,
       "symbol": "ASAP",
       "decimals": 18
     },
     "0x182F4c4C97cd1c24E1Df8FC4c053E5C47bf53Bef": {
       "name": "keyTango Token",
       "logo": "keyTango.svg",
       "erc20": true,
       "symbol": "TANGO",
       "decimals": 18
     },
     "0x0275E1001e293C46CFe158B3702AADe0B99f88a5": {
       "name": "Oiler",
       "logo": "oiler.svg",
       "erc20": true,
       "symbol": "OIL",
       "decimals": 18
     },
     "0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F": {
       "name": "Gitcoin",
       "logo": "gtc.svg",
       "erc20": true,
       "symbol": "GTC",
       "decimals": 18
     },
     "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2": {
       "name": "Polychain Monsters",
       "logo": "PMON.svg",
       "erc20": true,
       "symbol": "PMON",
       "decimals": 18
     },
     "0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41": {
       "name": "Tixl",
       "logo": "TXL.svg",
       "erc20": true,
       "symbol": "TXL",
       "decimals": 18
     },
     "0x5218E472cFCFE0b64A064F055B43b4cdC9EfD3A6": {
       "name": "unFederalReserve Token",
       "logo": "eRSDL.svg",
       "erc20": true,
       "symbol": "eRSDL",
       "decimals": 18
     },
     "0xE4cC5A22B39fFB0A56d67F94f9300db20D786a5F": {
       "name": "unFederal eRSDL",
       "logo": "uneRSDL.svg",
       "erc20": true,
       "symbol": "uneRSDL",
       "decimals": 8
     },
     "0x2dbA05B51eF5A7DE3E7c3327201CA2F8a25C2414": {
       "name": "unFederal DAI",
       "logo": "unDAI.svg",
       "erc20": true,
       "symbol": "unDAI",
       "decimals": 8
     },
     "0xFaCecE87e14B50eafc85C44C01702F5f485CA460": {
       "name": "unFederal ETH",
       "logo": "unETH.svg",
       "erc20": true,
       "symbol": "unETH",
       "decimals": 8
     },
     "0x6b576972de33BebDe3A703BfF52a091e79f8c87A": {
       "name": "unFederal USDC",
       "logo": "unUSDC.svg",
       "erc20": true,
       "symbol": "unUSDC",
       "decimals": 8
     },
     "0x6e2aA5bB90ac37D9006685AFc651ef067E1c7b44": {
       "name": "unFederal USDT",
       "logo": "unUSDT.svg",
       "erc20": true,
       "symbol": "unUSDT",
       "decimals": 8
     },
     "0x5D446FC8DBd10EBAcfE9A427aB5402586af98cD4": {
       "name": "unFederal WBTC",
       "logo": "unWBTC.svg",
       "erc20": true,
       "symbol": "unWBTC",
       "decimals": 8
     },
     "0xD837eCa6C91c67D98461A411BA2f00bdA9960a9D": {
       "name": "unFederal AAVE",
       "logo": "unAAVE.svg",
       "erc20": true,
       "symbol": "unAAVE",
       "decimals": 8
     },
     "0x9e29Ce9cD25F4141dF6BB85b27Ef6933a16A5824": {
       "name": "unFederal YFI",
       "logo": "unYFI.svg",
       "erc20": true,
       "symbol": "unYFI",
       "decimals": 8
     },
     "0x031002d15B0D0Cd7c9129d6F644446368deaE391": {
       "name": "unFederal LINK",
       "logo": "unLINK.svg",
       "erc20": true,
       "symbol": "unLINK",
       "decimals": 8
     },
     "0xB8E2e2101eD11e9138803cd3e06e16dd19910647": {
       "name": "ArdCoin",
       "logo": "ardcoin.svg",
       "erc20": true,
       "symbol": "ARDX",
       "decimals": 2
     },
     "0x6D0F5149c502faf215C89ab306ec3E50b15e2892": {
       "name": "Portion Token",
       "logo": "PRT.svg",
       "erc20": true,
       "symbol": "PRT",
       "decimals": 18
     },
     "0x3845badAde8e6dFF049820680d1F14bD3903a5d0": {
       "name": "SAND",
       "logo": "SAND.svg",
       "erc20": true,
       "symbol": "SAND",
       "decimals": 18
     },
     "0xcc7ab8d78dBA187dC95bF3bB86e65E0C26d0041f": {
       "name": "Spacelens",
       "logo": "SPACE.svg",
       "erc20": true,
       "symbol": "SPACE",
       "decimals": 18
     },
     "0x50f5474724e0Ee42D9a4e711ccFB275809Fd6d4a": {
       "name": "Sandbox's LANDs",
       "logo": "SAND.svg",
       "erc20": false,
       "erc721": true,
       "symbol": "LAND",
       "decimals": 0
     },
     "0x362bc847A3a9637d3af6624EeC853618a43ed7D2": {
       "name": "PARSIQ",
       "logo": "parsiq.svg",
       "erc20": true,
       "symbol": "PRQ",
       "decimals": 18
     },
     "0x21381e026Ad6d8266244f2A583b35F9E4413FA2a": {
       "name": "Formation Finance",
       "logo": "form.svg",
       "erc20": true,
       "symbol": "FORM",
       "decimals": 18
     },
     "0x2ba592F78dB6436527729929AAf6c908497cB200": {
       "name": "Cream",
       "logo": "cream.svg",
       "erc20": true,
       "symbol": "CREAM",
       "decimals": 18
     },
     "0x46576e20EC5F25586A6Fa2E0d6B6058354B72E72": {
       "name": "Cryptomind",
       "logo": "CR.svg",
       "erc20": true,
       "erc721": false,
       "symbol": "CR",
       "decimals": 8
     },
     "0x5ab21Ec0bfa0B29545230395e3Adaca7d552C948": {
       "name": "PUNKS Comic",
       "logo": "punks-comic.svg",
       "erc20": false,
       "erc721": true,
       "symbol": "COMIC",
       "decimals": 0
     },
     "0x9C2dc0c3CC2BADdE84B0025Cf4df1c5aF288D835": {
       "name": "Coreto",
       "logo": "coreto.svg",
       "erc20": true,
       "symbol": "COR",
       "decimals": 18
     },
     "0x0De05F6447ab4D22c8827449EE4bA2D5C288379B": {
       "name": "OOKI",
       "logo": "ooki.svg",
       "erc20": true,
       "symbol": "OOKI",
       "decimals": 18
     },
     "0x6B4c7A5e3f0B99FCD83e9c089BDDD6c7FCe5c611": {
       "name": "Million",
       "logo": "million.svg",
       "erc20": true,
       "symbol": "MM",
       "decimals": 18
     },
     "0x56d811088235F11C8920698a204A5010a788f4b3": {
       "name": "bZx Protocol Token (BZRX)",
       "logo": "ic_token_bzrx.svg",
       "erc20": true,
       "symbol": "BZRX",
       "decimals": 18
     },
     "0xB72B31907C1C95F3650b64b2469e08EdACeE5e8F": {
       "name": "bZx Vesting Token (vBZRX)",
       "logo": "ic_token_vbzrx.svg",
       "erc20": true,
       "symbol": "vBZRX",
       "decimals": 18
     },
     "0x3085154623F51b00Dedfc6Ceeb5197277A66B17B": {
       "name": "NIFTY",
       "logo": "nfty.svg",
       "erc20": true,
       "symbol": "NFTY",
       "decimals": 18
     },
     "0xB983E01458529665007fF7E0CDdeCDB74B967Eb6": {
       "name": "Fulcrum ETH iToken (iETH)",
       "logo": "ic_token_ieth.svg",
       "erc20": true,
       "symbol": "iETH",
       "decimals": 18
     },
     "0x32E4c68B3A4a813b710595AebA7f6B7604Ab9c15": {
       "name": "Fulcrum USDC iToken (iUSDC)",
       "logo": "ic_token_iusdc.svg",
       "erc20": true,
       "symbol": "iUSDC",
       "decimals": 6
     },
     "0x2ffa85f655752fB2aCB210287c60b9ef335f5b6E": {
       "name": "Fulcrum WBTC iToken (iWBTC)",
       "logo": "ic_token_ibtc.svg",
       "erc20": true,
       "symbol": "iWBTC",
       "decimals": 8
     },
     "0x687642347a9282Be8FD809d8309910A3f984Ac5a": {
       "name": "Fulcrum KNC iToken (iKNC)",
       "logo": "ic_token_iknc.svg",
       "erc20": true,
       "symbol": "iKNC",
       "decimals": 18
     },
     "0x463538705E7d22aA7f03Ebf8ab09B067e1001B54": {
       "name": "Fulcrum LINK iToken (iLINK)",
       "logo": "ic_token_ilink.svg",
       "erc20": true,
       "symbol": "iLINK",
       "decimals": 18
     },
     "0x6b093998D36f2C7F0cc359441FBB24CC629D5FF0": {
       "name": "Fulcrum DAI iToken (iDAI)",
       "logo": "ic_token_idai.svg",
       "erc20": true,
       "symbol": "iDAI",
       "decimals": 18
     },
     "0x7e9997a38A439b2be7ed9c9C4628391d3e055D48": {
       "name": "Fulcrum USDT iToken (iUSDT)",
       "logo": "ic_token_iusdt.svg",
       "erc20": true,
       "symbol": "iUSDT",
       "decimals": 6
     },
     "0x9189c499727f88F8eCC7dC4EEA22c828E6AaC015": {
       "name": "Fulcrum MKR iToken (iMKR)",
       "logo": "ic_token_imkr.svg",
       "erc20": true,
       "symbol": "iMKR",
       "decimals": 18
     },
     "0x18240BD9C07fA6156Ce3F3f61921cC82b2619157": {
       "name": "Fulcrum BZRX iToken (iBZRX)",
       "logo": "ic_token_ibzrx.svg",
       "erc20": true,
       "symbol": "iBZRX",
       "decimals": 18
     },
     "0x7F3Fe9D492A9a60aEBb06d82cBa23c6F32CAd10b": {
       "name": "Fulcrum YFI iToken (iYFI)",
       "logo": "ic_token_iyfi.svg",
       "erc20": true,
       "symbol": "iYFI",
       "decimals": 18
     },
     "0x0a625FceC657053Fe2D9FFFdeb1DBb4e412Cf8A8": {
       "name": "Fulcrum UNI iToken (iUNI)",
       "logo": "ic_token_iuni.svg",
       "erc20": true,
       "symbol": "iUNI",
       "decimals": 18
     },
     "0x0cae8d91E0b1b7Bd00D906E990C3625b2c220db1": {
       "name": "Fulcrum AAVE iToken (iAAVE)",
       "logo": "ic_token_iaave.svg",
       "erc20": true,
       "symbol": "iAAVE",
       "decimals": 18
     },
     "0x3dA0e01472Dee3746b4D324a65D7EdFaECa9Aa4f": {
       "name": "Fulcrum LRC iToken (iLRC)",
       "logo": "ic_token_ilrc.svg",
       "erc20": true,
       "symbol": "iLRC",
       "decimals": 18
     },
     "0x6d29903BC2c4318b59B35d97Ab98ab9eC08Ed70D": {
       "name": "Fulcrum COMP iToken (iCOMP)",
       "logo": "ic_token_icomp.svg",
       "erc20": true,
       "symbol": "iCOMP",
       "decimals": 18
     },
     "0x08c32b0726C5684024ea6e141C50aDe9690bBdcc": {
       "name": "Stratos Token",
       "logo": "stos.svg",
       "erc20": true,
       "symbol": "STOS",
       "decimals": 18
     },
     "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30": {
       "name": "Injective",
       "logo": "inj.svg",
       "erc20": true,
       "symbol": "INJ",
       "decimals": 18
     },
     "0xe516D78d784C77D479977BE58905B3f2b1111126": {
       "name": "Bitspawn",
       "logo": "spwn.svg",
       "erc20": true,
       "symbol": "SPWN",
       "decimals": 18
     },
     "0xB16e967ff83DE3F1e9FCeAfbc2C28c1c5c56eF91": {
       "name": "Polkadog",
       "logo": "polkadog.svg",
       "erc20": true,
       "symbol": "PDOG",
       "decimals": 18
     },
     "0xF4d861575ecC9493420A3f5a14F85B13f0b50EB3": {
       "name": "Fractal Protocol",
       "logo": "fractal.svg",
       "erc20": true,
       "symbol": "FCL",
       "decimals": 18
     },
     "0x3af33bEF05C2dCb3C7288b77fe1C8d2AeBA4d789": {
       "name": "Kromatika",
       "logo": "krom.svg",
       "erc20": true,
       "symbol": "KROM",
       "decimals": 18
     },
     "0xA130E3a33a4d84b04c3918c4E5762223Ae252F80": {
       "name": "Swash Token",
       "logo": "swash.svg",
       "erc20": true,
       "symbol": "SWASH",
       "decimals": 18
     },
     "0xea7Cc765eBC94C4805e3BFf28D7E4aE48D06468A": {
       "name": "NearPad Token",
       "logo": "nearpad.svg",
       "erc20": true,
       "symbol": "PAD",
       "decimals": 18
     },
     "0x87C22615435998D69ACA34889D03155B694a94fc": {
       "name": "DiemLibre",
       "logo": "DLB.svg",
       "erc20": true,
       "symbol": "DLB",
       "decimals": 18
     },
     "0x4d224452801ACEd8B2F0aebE155379bb5D594381": {
       "name": "ApeCoin",
       "logo": "APE.svg",
       "erc20": true,
       "symbol": "APE",
       "decimals": 18
     },
     "0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe": {
       "name": "Torum Token",
       "logo": "torum.svg",
       "erc20": true,
       "symbol": "XTM",
       "decimals": 18
     },
     "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E": {
       "name": "FLOKI",
       "logo": "floki.svg",
       "erc20": true,
       "symbol": "FLOKI",
       "decimals": 9
     },
     "0xe831F96A7a1DcE1aa2EB760b1e296c6A74CaA9d5": {
       "name": "Nexum Coin",
       "logo": "nexum.svg",
       "erc20": true,
       "symbol": "Nexm",
       "decimals": 8
     },
     "0x6710c63432A2De02954fc0f851db07146a6c0312": {
       "name": "MFG",
       "logo": "mfg.svg",
       "erc20": true,
       "symbol": "MFG",
       "decimals": 18
     },
     "0xae78736Cd615f374D3085123A210448E74Fc6393": {
       "name": "Rocket Pool ETH",
       "logo": "rETH.svg",
       "erc20": true,
       "symbol": "rETH",
       "decimals": 18
     },
     "0xD33526068D116cE69F19A9ee46F0bd304F21A51f": {
       "name": "Rocket Pool",
       "logo": "rpl.svg",
       "erc20": true,
       "symbol": "RPL",
       "decimals": 18
     },
     "0xD3c625F54dec647DB8780dBBe0E880eF21BA4329": {
       "name": "HollaEx Token",
       "logo": "xht.svg",
       "erc20": true,
       "symbol": "XHT",
       "decimals": 18
     },
     "0x01e0E2e61f554eCAaeC0cC933E739Ad90f24a86d": {
       "name": "GTON",
       "logo": "gton.svg",
       "erc20": true,
       "symbol": "GTON",
       "decimals": 18
     },
     "0xeFF66B4A84C8a6b69b99EB1C5e39aF8fc35d13db": {
       "name": "sGTON",
       "logo": "sgton.svg",
       "erc20": true,
       "symbol": "sGTON",
       "decimals": 18
     }
};

export default contractMetadata;