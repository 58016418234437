/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useCallback } from "react";
import MainModal from "./../generic/MainModal";
import { useApp } from "../../helpers/AppProvider";
// import Storage from "../../helpers/Storage";
// import { useUser } from "../../helpers/UserProvider";
import { formatMoney } from "../../helpers/walletHelpers";

const Web3 = require("web3");
// const Swaps = require("@getsafle/safle-swaps-v2").Swaps;
// const Vault = require("@getsafle/safle-vault");

const ModalTransactionApproval = ({ onClose, callbackOpenPopup }) => {
  const { appData, actions } = useApp();
  // const { userData, userActions } = useUser();
  const currency = actions.getActiveCurrencySymbol();

  const web3 = new Web3();

  const [maxFee, setMaxFee] = useState({ ETH: 0, USD: 0 });
  // eslint-disable-next-line no-unused-vars
  const [timeLimit, setTimeLimit] = useState(30);

  const maxFeeCalculator = useCallback(async (value) => {
    const activeChain = appData.chains
      ? appData.chains.filter(
          (chain) => chain?.chainId === appData?.activeChain
        )[0]
      : appData.chains[0];
    const valueInWei = web3.utils.toWei(
      web3.utils.toBN(value).toString(),
      "gwei"
    );
    const valueInEth = web3.utils.fromWei(valueInWei, "ether");
    const data = actions.getChainNativeToUsd(activeChain?.chainId);
    // const data = actions.getEthToUsd();
    data.then((value) => {
      setMaxFee({
        ETH: valueInEth,
        USD:
          activeChain?.chainId === 1
            ? (value.data.data.ETH.quote.USD.price * valueInEth).toFixed(2)
            : (value.data.data.MATIC.quote.USD.price * valueInEth).toFixed(2),
      });
      activeChain?.chainId === 1
        ? actions.setAppData({ ethToUsd: value.data.data.ETH.quote.USD.price })
        : actions.setAppData({
            ethToUsd: value.data.data.MATIC.quote.USD.price,
          });
    });
  }, []);

  useEffect(() => {
    async function estimateApproval() {
      const bool =
        appData.fromTokenData[0]?.symbol ===
        appData?.chains?.find(
          (chain) => chain?.chainId === appData?.activeChain
        )?.details?.symbol;
      if (bool) {
        return callbackOpenPopup("modal_swap_confirmation");
       
      }
      // const data = await actions.getPriorityOptionValues();
      const estimatedGasApproval = appData?.approvalTxRes?.gasLimit;
      // setPriorityOption(data);
      maxFeeCalculator(estimatedGasApproval);
    }
    estimateApproval();
  }, []);

  const walletData = useMemo(() => {
    const activeWallet = Object.values(appData.accounts).find(
      (account) => account.address === appData.activeAccountAddress
    );

    if (activeWallet) {
      return {
        label: activeWallet.label,
        address: activeWallet.address,
      };
    } else {
      if (appData.accounts && Object.values(appData.accounts).length) {
        return {
          label: Object.values(appData.accounts)[0].label,
          address: Object.values(appData.accounts)[0].address,
        };
      }
    }
  }, [appData.activeAccountAddress, appData.accounts]);

  const logo = useMemo(() => {
    if (appData.dexLogos) {
      return appData.dexLogos[appData.activeDex];
    } else {
      return null;
    }
  }, [appData.activeDex, appData.dexLogos]);

  // const usr = Storage.load("user");
  // const retrieveEncryptionKey = Storage.load("encrypted_key");

 

  return (
    <MainModal
      title="Approval Required"
      onClose={onClose}
      // extraClasses={["modal_w440 modal_mh500 modal_swap_confirmation modal_transaction_approval"]}
      extraClasses={[
        "modal_w440 modal_mh500 modal_swap_confirmation modal_transaction_approval",
      ]}
      dataClass="modal_transaction_approval"
    >
      <div>
        <div className="modal--padding">
          <div className="modal_tx_approval_dex_logo">
            <img src={logo} alt="dex_logo" style={{ height: "50px" }} />
          </div>
          <div className="modal_tx_approval_title">
            Allow {appData.activeDex} to spend your{" "}
            {appData?.fromTokenData.length > 0 &&
              appData?.fromTokenData[0].symbol}
          </div>
          <div className="modal_tx_approval_message">
            By granting this permission, you’re allowing {appData.activeDex} to
            spend your{" "}
            {appData?.fromTokenData.length > 0 &&
              appData?.fromTokenData[0].symbol}{" "}
            and automate this transaction for you.
          </div>
          <div className="mt-4 mb-0 modal_wallet_info">
            <div className="modal_wallet_info_name">
              {walletData?.hasOwnProperty("label")
                ? appData.chains[appData.activeChain || 0]?.chainId === 1
                  ? walletData?.label?.ethereum
                  : appData.chains[appData.activeChain || 0]?.chainId === 137
                  ? walletData?.label?.polygon
                  : appData.chains[appData.activeChain || 0]?.chainId === 5
                  ? walletData?.label?.bsc
                  : ""
                : walletData?.label}
            </div>
            <div className="flex items-center justify-between">
              <div className="modal_wallet_info_address">
                {walletData && walletData.address}
              </div>
            </div>
          </div>
        </div>
        <div className="modal_fee_border">
          <div className="modal_fee">
            <div className="flex items-center justify-between">
              <div className="modal_fee_info flex items-center justify-between">
                <div className="modal_fee_price">
                  Max Fee{" "}
                  <span>
                    {maxFee.ETH}{" "}
                    {appData.chains.filter(
                      (chain) => chain?.chainId === appData?.activeChain
                    )[0]?.chainId === 1
                      ? "ETH"
                      : "MATIC"}{" "}
                    ({currency}{" "}
                    {formatMoney(actions.activeCurrency(maxFee.USD))})
                  </span>
                </div>
                <div className="tooltip">
                  <span className="ico icon-info-circle-solid" />
                  <span className="tooltiptext">
                    <span className="max-fee-title">Max Fee?</span>
                    <div className="max-fee-text">
                      Max fee = Base fee+ Priority fee
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="modal_fee_time">Likely in {timeLimit} Seconds</div>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            data-target="modal_change_safle_id_txn"
            onClick={() => callbackOpenPopup("modal_enter_pin_approval")}
            // onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalTransactionApproval;
