import React from "react";
import { useEffect } from "react";
import "./../../../styles/registration/seedConfirm.scss";

const SeedsConfirm = ({ onUpdate }) => {
  let parentRef = React.useRef(null);

  const [seeds, setSeeds] = React.useState(new Array().fill(""));

  useEffect(() => {
    onUpdate && onUpdate(seeds);
  }, [seeds]);

  const onChange = (e, idx) => {
    e.stopPropagation();
    const seedArr = [...seeds];
    const re = /^[a-z]+$/;
    let val = e.target.value;
    if (re.test(val) || val.length === 0) {
      //
      // trim else you get error afterwards ... it passes validate mnemonics somehow
      seedArr[idx] = val.trim();
      // seedArr[idx] = e.target.value;
      setSeeds([...seedArr]);
    } else {
      return;
    }
  };

  const pasteHandler = (e) => {
    const val = e.clipboardData.getData("Text").toLowerCase().split(" ");
    const inputs = parentRef.current.querySelectorAll("input");
    setTimeout(() => {
      const seedArr = seeds.slice();
      for (var i = 0; i < 12; i++) {
        if (val[i] === undefined) {
          inputs[i].value = "";
          seedArr[i] = "";
        } else {
          inputs[i].value = val[i];
          seedArr[i] = val[i];
        }
      }
      setSeeds(seedArr);
      inputs[0].blur();
      onUpdate && onUpdate(seedArr);
    }, 50);
    e.preventDefault();
  };

  return (
    <div className="my-5 flex flex-col shadow-sm seed_border py-1 mb-0">
      <div className={"px-5"} ref={parentRef}>
        <div className="flex mt-2 space-x-4">
          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>1</p>
            <input
              type="text"
              value={seeds[0]}
              onChange={(e) => {
                e.stopPropagation();
                onChange(e, 0);
              }}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[0] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>2</p>
            <input
              type="text"
              value={seeds[1]}
              onChange={(e) => onChange(e, 1)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[1] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>3</p>
            <input
              type="text"
              value={seeds[2]}
              onChange={(e) => onChange(e, 2)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[2] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>4</p>
            <input
              type="text"
              value={seeds[3]}
              onChange={(e) => onChange(e, 3)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[3] ? "filled" : "")
              }
            />
          </div>
        </div>
        <div className="flex mt-2 space-x-4">
          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>5</p>
            <input
              type="text"
              value={seeds[4]}
              onChange={(e) => onChange(e, 4)}
              onPaste={pasteHandler}
              className={
                "* block h-8 w-full seed_input_border " +
                (seeds[4] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>6</p>
            <input
              type="text"
              value={seeds[5]}
              onChange={(e) => onChange(e, 5)}
              onPaste={pasteHandler}
              className={
                "* block h-8 w-full seed_input_border " +
                (seeds[5] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>7</p>
            <input
              type="text"
              value={seeds[6]}
              onChange={(e) => onChange(e, 6)}
              onPaste={pasteHandler}
              className={
                "* block h-8 w-full seed_input_border " +
                (seeds[6] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>8</p>
            <input
              type="text"
              value={seeds[7]}
              onChange={(e) => onChange(e, 7)}
              onPaste={pasteHandler}
              className={
                "* block h-8 w-full seed_input_border " +
                (seeds[7] ? "filled" : "")
              }
            />
          </div>
        </div>
        <div className="flex mt-2 space-x-4">
          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>9</p>
            <input
              type="text"
              value={seeds[8]}
              onChange={(e) => onChange(e, 8)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[8] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>10</p>
            <input
              type="text"
              value={seeds[9]}
              onChange={(e) => onChange(e, 9)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[9] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>11</p>
            <input
              type="text"
              value={seeds[10]}
              onChange={(e) => onChange(e, 10)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[10] ? "filled" : "")
              }
            />
          </div>

          <div className={"flex flex-col mt-1 items-center w-3/12"}>
            <p className={"seed_font mb-1"}>12</p>
            <input
              type="text"
              value={seeds[11]}
              onChange={(e) => onChange(e, 11)}
              onPaste={pasteHandler}
              className={
                " block h-8 w-full seed_input_border " +
                (seeds[11] ? "filled" : "")
              }
            />
          </div>
        </div>
        <div style={{ height: "10px", lineHeight: "10px" }}></div>
      </div>
    </div>
  );
};

export default SeedsConfirm;
