import React, { useState, useEffect, useCallback } from "react";
import { useApp } from "../../../helpers/AppProvider";
import ModalSwapSelectToken from "../../dialogs/ModalSwapSelectToken";
import { useUser } from "../../../helpers/UserProvider";
import { decimalCount } from "../../../helpers/generalHelper";
const Vault = require("@getsafle/safle-vault");
const Web3 = require("web3");

const percentages = [
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
];

const FromTokenSelector = () => {
  const { appData, actions } = useApp();
  const { userData } = useUser();

  const vault = new Vault({});
  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);
  const [percentageActive, setPercentageActive] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [error, setError] = useState(null);
  // const [currentQuantity, setCurrentQuantity] = useState(0);

  useEffect(() => {
    if (
      appData.supportedTokens &&
      appData.supportedTokens.length &&
      appData.activeTokenA
    ) {
      const selectedToken = appData.supportedTokens.filter(
        (token) =>
          token.address.toLowerCase() === appData.activeTokenA.toLowerCase()
      );
      setSelectedToken(selectedToken[0]);
    }

    // if (appData.swapToQuantity) {
    //   setCurrentQuantity(Math.round(appData.swapToQuantity / appData.exchangeRate * 100)/100);
    //   actions.setAppData({ swapFromQuantity: Math.round(appData.swapToQuantity / appData.exchangeRate * 100)/100 });
    // }
  }, [appData.activeTokenA, appData.supportedTokens]);

  useEffect(() => {
    async function getBalance(contracAddress) {
      // const activeChain = appData?.chains[appData.activeChain || 0];
      const chain = appData?.chains?.find(
        (chain) => chain?.chainId === appData?.activeChain
      );

      if (selectedToken?.symbol === chain?.details?.symbol) {
        // const balance = Object.values(await vault.getBalance(appData.activeAccountAddress, appData.chains[appData.activeChain || 0].uri))[0].balance;
        // setTokenBalance(balance);

        const coinBalance = await actions.getCoinBalance(
          appData?.activeAccountAddress,
          selectedToken?.decimals
        );
        setTokenBalance(coinBalance);
      } else {
        // const balance = await actions.getBalance(contracAddress);
        // setTokenBalance((balance * 1) / (10 ** (decimals || 0)));

        const tokenBal = await actions.getTokenBalance(
          appData?.activeAccountAddress,
          selectedToken?.address
        );
        setTokenBalance(tokenBal);
      }
    }
    if (appData.activeTokenA) {
      getBalance(appData.activeTokenA);
    }
  }, [appData.activeChain, selectedToken, appData.activeAccountAddress]);

  useEffect(() => {
    if (appData.swapFromQuantity !== undefined) {
      setCurrentQuantity(appData.swapFromQuantity);
    }
  }, [appData.swapFromQuantity]);

  useEffect(() => {
    if (decimalCount(currentQuantity) > selectedToken?.decimals) {
      setError("Swaps cannot proceed for this value, try other value.");
    } else {
      setError(null);
    }
  }, [currentQuantity]);

  const handlePercentageActive = useCallback(
    async (index) => {
      if (percentageActive !== index && appData.activeTokenA) {
        const quantity = (
          (tokenBalance * percentages[index].value) /
          100
        ).toFixed(selectedToken?.decimals);
        setCurrentQuantity(quantity);
        setPercentageActive(index);
        actions.setAppData({ swapFromQuantity: quantity });
        actions.setAppData({
          swapToQuantity:
            (quantity * appData.exchangeRate).toFixed(
              appData?.toTokenData[0]?.decimals
            ) || 0,
        });
      } else {
        setPercentageActive(null);
      }
    },
    [percentageActive, tokenBalance, actions, appData.exchangeRate]
  );

  const handleChange = useCallback(
    async (e) => {
      setPercentageActive(null);
      setCurrentQuantity(e.target.value);
      actions.setAppData({ swapFromQuantity: e.target.value || 0 });
      actions.setAppData({
        swapToQuantity:
          (e.target.value * appData.exchangeRate).toFixed(
            appData?.toTokenData[0]?.decimals
          ) || 0,
      });
    },
    [actions, appData.exchangeRate, appData?.toTokenData[0]?.decimals]
  );

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  return (
    <>
      <div className="from_to_field_wrapper swap-field-to-wrapper">
        <div className="from_to_field">
          <div className="from_to_field-label swap-form-label">From</div>
          <div className="from_to_field-token">
            <div className="from_to_field-token-select">
              <div
                className="asset__coin modal__trigger swap-asset-coin"
                data-target="modal_assets_change"
                onClick={() => openPopup("modal_swap_select_token")}
              >
                <div className="asset__coin-image">
                  {selectedToken && (
                    <div
                      className="asset__coin-image-icon"
                      style={{
                        backgroundImage: 'url("' + selectedToken.logoURI + '")',
                      }}
                    />
                  )}
                </div>
                <div className="asset__coin_prefix swap-coin-prefix">
                  {selectedToken ? selectedToken.symbol : "Select token"}
                </div>
                <div className="swap__history-details swap-input-arrow">
                  <span className="swap__history-ico icon-chevron-down-solid-1"></span>
                </div>
              </div>
            </div>
            <div className="from_to_field-token-input">
              <input
                type="number"
                className="fake_input"
                onChange={handleChange}
                value={String(currentQuantity)?.replace(/^0+(?=[0-9])/, "")}
              />
            </div>
          </div>
        </div>
        {error ? (
          <div>
            {" "}
            <span className="modal_swap_select_error">{error}</span>
          </div>
        ) : null}
        <div className="swap-balance-amount">
          <div className="from_to_field_balance swap-field-balance">
            <span className="item_ico icon-Navbar_icon_Wallet_deActive1" />
            <span className="from_to_field_label swap-field-to-label">
              Balance:
            </span>
            <span className="from_to_field_amount">
              <span className="coin_val">
                {" "}
                {parseFloat(tokenBalance)
                  ?.toFixed(appData.fromTokenData?.[0]?.decimals)
                  ?.replace(/\.?0+$/, "")}{" "}
              </span>
              <span className="coin_prefix">
                {selectedToken && selectedToken.symbol}
              </span>
            </span>
          </div>
          <ul className="filter-percentage-amount">
            {percentages.map((item, index) => {
              return (
                <li
                  className={percentageActive === index ? "active" : null}
                  onClick={() => handlePercentageActive(index)}
                  key={index}
                >
                  {item.value} %
                </li>
              );
            })}
          </ul>
        </div>
        {openedPopup === "modal_swap_select_token" && (
          <ModalSwapSelectToken onClose={onClose} isFromToken={true} />
        )}
      </div>
    </>
  );
};

export default FromTokenSelector;
