import moment from "moment";
import React from "react";
import { middleEllipsis } from "../../helpers/generalHelper";

const VaultlogsList = ({ item }) => {
  return (
    <>
      <tr>
        <td className="text-left pl-8" width="13%">
          {moment(item?.timestamp).format("DD/MM/YYYY | hh:mm:ss") || "-"}
        </td>
        <td className="text-left pl-8" width="22%">
          {item?.action || item?.activity || "-"}
        </td>
        <td className="text-center" width="25%">
          {middleEllipsis(item?.address) || "-"}
        </td>
        <td className="text-center" width="23%">
          {item?.platform || "-"}
        </td>
        <td className="text-center">{item?.Where || "-"}</td>
      </tr>
    </>
  );
};

export default VaultlogsList;
