const Storage = {
  // simple session storage
  // to be encripted later

  load: (key) => {
    var v = sessionStorage.getItem(key);
    if (v) {
      return JSON.parse(v);
    }
    return false;
  },

  save: (key, obj) => {
    var v = JSON.stringify(obj);
    sessionStorage.setItem(key, v);
  },

  remove: (key) => {
    sessionStorage.removeItem(key);
  },
  encript: (str) => {
    var data = crypto.AES.encrypt(str, Storage.key);
    data = data.toString();
    return data;
  },

  decript: (data) => {
    var decr = crypto.AES.decrypt(data, Storage.key);
    decr = decr.toString(crypto.enc.Utf8);

    return decr;
  },
};

export default Storage;
