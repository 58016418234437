import { useEffect, useState } from "react";
import PercentWorthGained from "../PercentWorthGained/PercentWorthGained";
import { formatXMinDecimals } from "../../../helpers/generalHelper";
import { useApp } from "../../../helpers/AppProvider";

const AssetDetailHistory = ({ latestPrice, ohlcvData, symbol }) => {
  const { actions } = useApp();
  const [price, setPrice] = useState(0);
  const [dailyChange, setDailyChange] = useState(0);
  const [markeCap, setMarkeCap] = useState(0);
  const [circulatingSupply, setCirculatingSupply] = useState(0);
  const [marketCapDominance, setMarketCapDominance] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [volume, setVolume] = useState(0);
  const [volumeDominance, setVolumeDominance] = useState(0); // to do

  useEffect(() => {
    if (latestPrice) {
      const price = formatXMinDecimals(latestPrice?.price, 4, 8);
      const dailyChange = formatXMinDecimals(
        latestPrice?.percentChange24h,
        4,
        8
      );
      const volume_change_24h = formatXMinDecimals(
        latestPrice?.percentChangeVolume,
        4,
        8
      );
      const market_cap = formatXMinDecimals(latestPrice?.marketCap, 4, 8);
      const circulating_supply = formatXMinDecimals(
        latestPrice?.circulatingSupply,
        4,
        8
      );
      const market_cap_dominance = formatXMinDecimals(
        latestPrice?.marketCap,
        4,
        8
      );

      setPrice(price);
      setDailyChange(dailyChange);
      setMarkeCap(market_cap);
      setCirculatingSupply(circulating_supply);
      setMarketCapDominance(market_cap_dominance);
      setVolumeDominance(volume_change_24h);
    }

    if (ohlcvData) {
      let high = 0,
        low = 0,
        volume = 0;

      for (let i = 0; i < ohlcvData?.length; i++) {
        if (ohlcvData[i]?.quote["USD"]?.high > high) {
          high = ohlcvData[i]?.quote["USD"]?.high;
        }
        if (ohlcvData[i]?.quote["USD"]?.low > low) {
          low = ohlcvData[i]?.quote["USD"]?.low;
        }
        if (ohlcvData[i]?.quote["USD"]?.volume > volume) {
          volume = ohlcvData[i]?.quote["USD"]?.volume;
        }
      }
      setHigh(high);
      setLow(low);
      setVolume(volume);
    }
  }, [latestPrice, ohlcvData, symbol]);

  return (
    <div className="assets__overview_single-history w-[100%]">
      <div className="assets__overview_single-history-col bordered-col col_left ">
        <div className="assets__overview_single-history-row">
          <div className="asssets__overview_single-history-label">Price</div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-coin-price">
                {actions.getActiveCurrencySymbol()}
                {formatXMinDecimals(actions.activeCurrency(price), 4, 8)}
              </span>
              <PercentWorthGained
                hist={dailyChange > 0 ? true : false}
                percent={dailyChange}
              />
            </div>
          </div>
        </div>
        <div className="assets__overview_single-history-row">
          <div className="asssets__overview_single-history-label">
            Market Cap
          </div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-coin-price">
                {actions.getActiveCurrencySymbol()}
                {formatXMinDecimals(actions.activeCurrency(markeCap), 4, 8)}
              </span>
              {/* <PercentWorthGained
                hist={marketCapDominance > 0 ? true : false}
                percent={marketCapDominance}
              /> */}
            </div>
          </div>
        </div>
        <div className="assets__overview_single-history-row">
          <div className="asssets__overview_single-history-label">
            Circulating Supply
          </div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-prefix">
                {circulatingSupply} {symbol}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="assets__overview_single-history-col col_right">
        <div className="assets__overview_single-history-row">
          <div className="asssets__overview_single-history-label">High</div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-coin-price">
                {actions.getActiveCurrencySymbol()}
                {formatXMinDecimals(actions.activeCurrency(high), 4, 8)}
              </span>
            </div>
          </div>
        </div>
        <div className="assets__overview_single-history-row">
          <div className="asssets__overview_single-history-label">Low</div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-coin-price">
                {actions.getActiveCurrencySymbol()}
                {formatXMinDecimals(actions.activeCurrency(low), 4, 8)}
              </span>
            </div>
          </div>
        </div>
        <div className="assets__overview_single-history-row ">
          <div className="asssets__overview_single-history-label">Volume</div>
          <div className="asssets__overview_single-history-values">
            <div className="asset__coin-worth-asset">
              <span className="asset__coin-worth-prefix">
                {actions.getActiveCurrencySymbol()}
                {formatXMinDecimals(actions.activeCurrency(volume), 4, 8)}
              </span>
              <PercentWorthGained
                hist={volumeDominance > 0 ? true : false}
                percent={volumeDominance}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailHistory;
