/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MainModal from './../generic/MainModal';

const ModalImportWalletKey = ({ validation, privateKey, setPrivateKey, setNewAddress, setValidation, callbackOpenPopup, callbackCancelGeneratePopup }) => {

    const [ tooltipVisible, setTooltipVisible ] = useState(false);

    const onChangePrvKey = (e) => {
        setValidation(false)
        setPrivateKey(e.target.value);
    }

    useEffect(() => {
        setValidation(false);
        setPrivateKey('');
        setNewAddress('');
    }, [])

    return (
        <MainModal
            // on close - display conformation first
            onClose={() => callbackCancelGeneratePopup('modal_cancel_import_wallet')}
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet--1', 'modal_import_wallet_key']}
            dataClass="modal_import_wallet_key" >
            {/* Modal content */}
            <div className="modal_head--1">
                <img src="images/wallets/icon-wallet-import.svg" alt="" />
                <div className="modal_title modal_title_flex">
                    Import Wallet
                    <div className="modal_info">
                        {/* <InformationCircleIcon className="info-icon" /> */}
                        <img onClick={() => setTooltipVisible(!tooltipVisible)} className="info-btn" src="images/wallets/info-btn.svg" alt="" />
                        <span className={tooltipVisible ? "modal_info_tooltip visible" : "modal_info_tooltip"}>0xcD69z....jk098</span>
                    </div>
                </div>
                <div className="modal_text">Enter the Private key of the wallet you want to import</div>
            </div>
            <div className="modal_wrapper p-5">
                <input type="text" placeholder="Enter Private Key" onChange={onChangePrvKey} value={privateKey}/>
                <img className="paste-icon" src="images/paste-icon.svg" alt="" />
            </div>
            {validation &&
            <div className="validation">{validation}</div>}
            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered close_modal_cancel" onClick={() => callbackCancelGeneratePopup('modal_cancel_import_wallet')}>Cancel</button>
                    <button className="btn-default btn-blue modal__trigger" disabled={privateKey.length < 5} onClick={() => callbackOpenPopup('modal_import_wallet_pin')}>Confirm</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalImportWalletKey;