/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import MobilePortfolioSwitch from "../components/dashboard/MobilePortfolioSwitch/MobilePortfolioSwitch";
import Header from "../components/common/Header/Header";
import DashboardHome from "../components/dashboard/DashboardHome/DashboardHome";
import WalletArea from "../components/dashboard/WalletArea/WalletArea";
import WalletAreaPreload from "../components/dashboard/WalletArea/WalletAreaPreload";
import PopupMessage from "./../components/registration/PopupMessage/PopupMessage";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SiteContent from "../components/dashboard/SiteContent/SiteContent";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import AddWalletProcess from "../components/common/AddWalletProcess/AddWalletProcess";
import EditLabelWalletProcess from "../components/common/EditLabelWalletProcess/EditLabelWalletProcess";

import Storage from "../helpers/Storage";
import { useUser } from "../helpers/UserProvider";
import { useApp } from "../helpers/AppProvider";
import LinkWalletSafleIdProcess from "../components/common/LinkWalletSafleIdProcess/LinkWalletSafleIdProcess";

let tm = false;

const DashboardScreen = () => {
  const { userData } = useUser();
  const { appData, actions } = useApp();
  const [showWallets, setShowWallets] = useState(false);
  const [walletCount, setWalletCount] = useState(0);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [refreshDate, setRefreshDate] = useState(0);

  const [openedPopup, setOpenedPopup] = useState(null);
  const [popupMessageUser, setPopupMessageUser] = useState(null);
  const [popupMessageHeading, setPopupMessageHeading] = useState(null);
  let loading = false;
  const onClose = () => {
    setOpenedPopup(false);
    Storage.remove("userType");
    actions.setAppData({ blockScroll: false });
  };
  const openPopup = (popup) => {
    setOpenedPopup(popup);
    actions.setAppData({ blockScroll: true });
  };

  const refreshNow = () => {
    setRefreshCount(refreshCount + 1);
  };

  useEffect(() => {
    async function refreshAccounts() {
      await actions.getAccounts();
    }

    refreshAccounts();
  }, [refreshCount]);

  useEffect(() => {
    if (
      appData?.portfolioData &&
      Object.values(appData?.portfolioData)?.length
    ) {
      getData();
    }
  }, [appData.portfolioData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading state to true before fetching data
        actions.setLoading(true, "Loading");
        // Wait for appData.accounts and appData.regStatus to be available
        await Promise.all([waitForAppState()]);
        // Once data is fetched, check if required conditions are met
        if (
          appData?.accounts?.length > 0 &&
          appData.regStatus === "complete" &&
          !loading
        ) {
          loading = true;
          // Fetch data and wait for it to resolve using Promise.all
          await Promise.all([actions.getDashboardPortfolioData()]);
          // Assuming getDashboardPortfolioData stores results in appData.portfolioData
          if (appData.portfolioData && appData.portfolioData.length > 0) {
            // Call additional method if portfolioData has length
            await getData();
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // Set loading state to false after fetching data
        await actions.setAppData({ activeChain: 0 });
        const refresh = new Date();
        await setRefreshDate(refresh.getTime());
        actions.setLoading(false);
        loading = false;
      }
    };

    fetchData();
  }, [refreshCount, appData.regStatus]); // Empty dependency array means this effect runs only once, similar to componentDidMount

  // Wait for appData.accounts and appData.regStatus to be available
  const waitForAppState = useCallback(() => {
    return new Promise((resolve) => {
      const checkAppState = () => {
        if (appData?.accounts?.length > 0 && appData.regStatus === "complete") {
          resolve();
        } else {
          setTimeout(checkAppState, 100); // Check again after a short delay
        }
      };
      checkAppState();
    });
  }, [appData.accounts, appData.regStatus]);

  // Additional method to execute if portfolioData has length
  const getData = useCallback(async () => {
    try {
      // Call getPriceDataForCoins(), setRefreshDate(), etc.
      await actions.getPriceDataForCoins();
      // Additional tasks...
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  }, [actions]);

  useEffect(() => {
    if (appData?.accounts && appData?.accounts?.length && !showWallets) {
      //
      tm && clearTimeout(tm);
      tm = setTimeout(() => {
        setShowWallets(true);
        setWalletCount(appData.accounts.length);
      }, 500);
    } else {
      if (appData?.accounts && appData?.accounts?.length !== walletCount) {
        //
        setShowWallets(false);
        setWalletCount(false);
      }
    }
  }, [appData]);

  useEffect(() => {
    const storageData = Storage.load("userType");
    const storageDataName = userData.user.safleID;
    if (storageData) {
      openPopup("modal_popup_message");

      setPopupMessageUser(storageDataName);
      if (storageData.type === "signup") {
        setPopupMessageHeading("Welcome to V2.0.1");
      } else if (storageData.type === "signin") {
        setPopupMessageHeading("Welcome to V2.0.1");
      } else if (storageData.type === "migration") {
        setPopupMessageHeading("You have successfully migrated to V2!");
      }
    }
  }, []);

  useEffect(() => {
    if (!openedPopup) {
      actions.setAppData({ blockScroll: false });
    } else {
      actions.setAppData({ blockScroll: true });
    }
  }, [openedPopup]);

  return (
    <>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <div>
        <div className="main__content__wrapper dashboard__content__wrapper ">
          <Sidebar
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          {/* main content */}
          {process.env.REACT_APP_ALLOW_BETA_ACCESS === "allowed" && (
            <SiteContent>
              <div className="dashboard__overview" data-to_show="portfolio">
                <MobilePortfolioSwitch />
                {showWallets === false && <WalletAreaPreload />}
                {showWallets === true && (
                  <WalletArea userID={userData.user.safleID} />
                )}
                <DashboardHome
                  refresh={(e) => (e ? refreshNow() : null)}
                  refreshDate={refreshDate}
                />
              </div>
            </SiteContent>
          )}
          {/* end main content */}
          {/* <!-- aside notification --> */}
          <SidebarNotification />
          {/* <!-- end aside notification --> */}
        </div>
        <div className="main__footer" />
        <div className="drop_shadow" />
        <div className="drop_shadow_menu" />
      </div>
      <AddWalletProcess />
      <EditLabelWalletProcess />
      <LinkWalletSafleIdProcess />

      {openedPopup === "modal_popup_message" && (
        <PopupMessage
          history={""}
          saffleId={popupMessageUser}
          title={popupMessageHeading}
          onClose={onClose}
        />
      )}
      {sidebarActive && <div className="mobile_blur"></div>}
    </>
  );
};

export default DashboardScreen;
