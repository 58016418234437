/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Button from "../../components/registration/Button/Button";
import SuccessCard from "../../components/registration/SuccessCard";
import PublicFooter from "../../components/registration/Footer/Footer";
import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import "../../styles/registration/walletTransaction.scss";
import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import initialState from "../../context/initialStateRegistration";
import { useRegistration } from "../../helpers/RegistrationProvider";
import Storage from "./../../helpers/Storage";
import { useApp } from "../../helpers/AppProvider";
import { delay_code } from "../../helpers/generalHelper";

const WalletTransaction = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const { userData, userActions } = useUser();
  const { actions } = useApp();
  const retrieveEncryptionKey = Storage.load("encrypted_key");
  const pin = location.state?.pin;
  const [regStatus, setRegStatus] = useState("not_initiated");
  const [timer, setTimer] = useState(0);

  const {
    resetState,
    otpStatus,
    tempPassword,
    newPasswordToken,
    userVault,
    encryptionKey,
    signUpCompleted,
  } = useRegistration();

  const recaptchaRef = useRecaptcha();

  async function getStatus() {
    const status = await actions.getRegistrationStatus();
    setRegStatus(status?.safleIdRegistrationStatus);
    return status?.safleIdRegistrationStatus;
  }

  useEffect(() => {
    async function setSafleId() {
      const status = await getStatus();
      if (status === "not_initiated" || status === "error") {
        getAcc();
      }
    }
    setSafleId();
  }, []);

  useEffect(() => {
    const checkStatusInterval = setInterval(async () => {
      await getStatus();

      if (regStatus === "pending") {
      } else if (regStatus === "not_initiated" || regStatus === "error") {
        getAcc();
      } else {
        clearInterval(checkStatusInterval); // Stop the interval when status is complete
      }
    }, 8000);

    return () => {
      clearInterval(checkStatusInterval); // Clear the interval when the component unmounts
    };
  }, [regStatus]);

  const getAcc = async () => {
    try {
      // setting the below variables based on login status
      let enc =
        signUpCompleted === false
          ? retrieveEncryptionKey?.encryption_key
          : userData.user.decriptionKey;
      if (!enc) {
        enc = encryptionKey;
      }
      const pin_no = pin ? parseInt(pin) : parseInt(pin);
      const user_vault = userVault ? userVault : userData.user.vault;
      const old_storage = Storage.load("registration");
      Storage.save("registration", {
        ...old_storage,
        userVault: user_vault,
        encryptionKey: enc,
        newPasswordToken: newPasswordToken,
      });

      const address = await userActions.getAccountReg(
        user_vault,
        parseInt(pin_no),
        enc
      );
      blockchainTXN(address, pin_no, enc);
    } catch (e) {
      console.log(e);
    }
  };

  const blockchainTXN = async (publicAddress, pin_val, enc) => {
    try {
      const pin_no = parseInt(pin) || parseInt(pin_val);
      const private_Key = (
        await userActions.exportPrivateKey(
          publicAddress,
          parseInt(pin_no),
          newPasswordToken
        )
      ).response;

      const authToken = newPasswordToken;
      const trans = await userActions.relayTransaction({
        publicAddress: publicAddress,
        privateKey: private_Key?.privateKey,
        authToken: authToken,
      });
      await delay_code(500);
      if (trans?.response?.message === "Request successfully added to queue.") {
        setRegStatus("pending");
        setTimer(parseInt(trans?.response?.pendingRequests) * 5000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const goToDashboard = async (val) => {
    actions.setLoading(true);
    const user_token = await recaptchaRef.current.executeAsync();
    const safleID = await userActions.getSafleIdByEmail(otpStatus?.email);
    await userActions.login(otpStatus.safleId, tempPassword, user_token);

    Storage.save("userType", { type: "signup" });
    const token = await recaptchaRef.current.executeAsync();
    await userActions.retrieveVault(safleID, tempPassword, token);

    resetState();
    Storage.save("registration", initialState);
    Storage.save("activeFlow", {
      currentRoute: "/dashboard",
      flow: "signup",
    });
    Storage.remove("encrypted_key");
    setTimeout(() => {
      actions.setLoading(false);
      navigate(ROUTES.DASHBOARD, { replace: true });
    }, 1000);
  };

  return (
    <div className="bg_linear_inner z-0">
      <CssFix />
      <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
        <div className="flex justify-center mb-2">
          <img
            alt="safle logo"
            className="mb-4 h-8 w-20 md:hidden sm:block"
            src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
          />
        </div>
        <div className="md:h-auto bg-white shadow rounded-2xl relative ">
          <div className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 lg:pr-0">
            <div className="flex justify-between">
              <div className="w-full lg:w-5/12 flex flex-col">
                <div className="mb-8">
                  <img
                    alt="safle logo"
                    className="mb-4 h-8 w-20 hidden md:block"
                    src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                  />
                </div>
                <div>
                  <h1 className="lg:text-justify font-medium wallet-transaction pb-4 ">
                    {regStatus === "pending" ||
                    regStatus === "not_initiated" ||
                    regStatus === "error"
                      ? "In progress..."
                      : "Congratulations!"}
                  </h1>
                </div>
                <SuccessCard txnHash={regStatus === "complete"} timer={timer} />
                <div className="mt-4 flex flex-col justify-center lg:mb-40 wallet_transaction_bottom">
                  <Button
                    fullwidth
                    text="Go to Dashboard"
                    onClick={() => goToDashboard()}
                    style={{
                      opacity: 1,
                    }}
                  />

                  {regStatus === "pending" || regStatus === "not_initiated" ? (
                    <div>
                      <div className="flex items-center my-[20px] w-[100%]">
                        <div className="flex-grow h-[1px] bg-[#ccc]" />
                        <div className="mx-[15px]">Or</div>
                        <div className="flex-grow h-[1px] bg-[#ccc]" />
                      </div>

                      <div className="flex items-center w-[100%] justify-center">
                        You can wait here
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="hidden w-6/12 lg:block">
                <img
                  className="absolute top-0 right-0 h-full rounded-r-2xl"
                  src={`${process.env.PUBLIC_URL}/images/registration/img/background-full2_new@2x.png`}
                  alt="card background"
                />
              </div>
            </div>
          </div>
        </div>
        <PublicFooter />
      </div>
    </div>
  );
};

export default WalletTransaction;
