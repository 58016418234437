import React, { useEffect } from "react";
import { createIcon } from "../../../helpers/etherscan-blockies";

const blockies = require("ethereum-blockies");

const Blockie = ({ address = "dummy", label = "dummy", size = 8 }) => {
  const canvas = React.useRef();

  useEffect(() => {
    const icon = createIcon({
      seed:
        typeof label === "string" ? address + label : address + label?.polygon,
      size,
      scale: 4,
    });
    if (icon) canvas?.current?.appendChild(icon);

    return () => canvas?.current?.removeChild(icon);
  }, [address]);

  return <div className="blockie">{canvas && <div ref={canvas}></div>}</div>;
};

export default Blockie;
