import { useState, useEffect, useMemo } from "react";
import AddAssetList from "../common/AddAssetList/AddAssetList";
import SearchAsset from "../common/SearchAsset/SearchAsset";
import MainModal from "./../generic/MainModal";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
// import DropdownChain from "../common/DropdownChain/DropdownChain";
import TokenContractAddressInput from "../common/TokenContractAddressInput/TokenContractAddressInput";
import TokenSymbolInput from "../common/TokenSymbolInput/TokenSymbolInput";
import TokenDecimalInput from "../common/TokenDecimalInput/TokenDecimalInput";
import contractMetadata from "./../../helpers/contractMetadata";
import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";
import DropdownNetworkNew from "../common/DropdownNetworkNew/DropdownNetworkNew";
import { SafleID } from "@getsafle/safle-identity-wallet";

// const safleId = require("@getsafle/safle-identity-wallet").SafleID;
const safleTokenController = require("@getsafle/custom-token-controller");

const logoPrefix =
  "https://raw.githubusercontent.com/MetaMask/contract-metadata/master/images/";
const MARKET_SERVICE_URL = process.env.REACT_APP_MARKET_SERVICE_URL;

const ModalAddAsset = ({ onClose, setToastIsOpen, shouldRefresh }) => {
  const [addCustomTokenVisible, setAddCustomTokenVisible] = useState(false);
  const [filter, setFilter] = useState("");
  const [chains, setChains] = useState([]);
  const [selectedChain, setSelectedChain] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimal, setTokenDecimal] = useState("");
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [error, setError] = useState(null);

  const { appData, actions } = useApp();
  const { userData } = useUser();

  useEffect(() => {
    async function setChainList() {
      if (appData.chains && appData.chains.length) {
        setChains([...appData.chains.map((e) => e.chain_name)]);
      }
    }

    setChainList();
  }, [appData]);

  const assetSearchHandler = (val) => {
    const lowerCase = val.toLowerCase();
    setFilter(lowerCase);
  };

  const handleSelectTokenItem = (tokenInfo) => {
    setSelectedTokens((selectedTokens) => {
      if (selectedTokens.includes(tokenInfo)) {
        return selectedTokens.filter((token) => token !== tokenInfo);
      } else {
        return [...selectedTokens, tokenInfo];
      }
    });
  };
  const handleSelectChain = (chain) => {
    setSelectedChain(chain);
  };
  const handleChangeContractAddress = (e) => {
    e.preventDefault();
    const address = e.target.value.toLowerCase();
    setContractAddress(address);
    callAddTokenFn(address);
  };
  const handleChangeTokenSymbol = (e) => {
    e.preventDefault();
    const symbol = e.target.value.toUpperCase();
    setTokenSymbol(symbol);
  };
  const handleChangeTokenDecimal = (e) => {
    e.preventDefault();
    setTokenDecimal(e.target.value);
  };

  const callAddTokenFn = async (contractAddress) => {
    const envName =
      process.env.REACT_APP_ENV_MODE === "development" ||
      process.env.REACT_APP_ENV_MODE === "test"
        ? "testnet"
        : "mainnet"; // env value can be 'mainnet' or 'testnet'
    const polygonRpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL;
    const safleId = new SafleID(envName, polygonRpcUrl);
    const userAddress = await safleId.getAddress(userData.user.safleID);
    const activeChain = selectedChain;

    const tokenController = new safleTokenController.CustomTokenController({
      userAddress: userAddress,
      rpcURL: activeChain.uri,
      chain: activeChain.chain_name,
    });

    const tokenDetails = await tokenController.getTokenDetails(contractAddress);
    setTokenSymbol(tokenDetails.symbol);
    setTokenDecimal(tokenDetails.decimal);
  };

  const handleAddToken = async () => {
    try {
      if (addCustomTokenVisible) {
        actions.setLoading(true, "");
        const tokenInfo = await fetch(
          `${MARKET_SERVICE_URL}/info?coin=${tokenSymbol}`
        ).then((e) => e.json());
        const data = {
          chain: selectedChain.network_name,
          decimals: tokenDecimal,
          logo: tokenInfo.data.data[tokenSymbol].logo,
          name: tokenInfo.data.data[tokenSymbol].name,
          symbol: tokenSymbol,
          contractAddress: contractAddress,
        };

        const res = await actions.addCustomToken(data, userData.user.token);
        actions.setLoading(false);
        if (res.statusCode === 400) {
          // const message = res.details[0].message;
          setToastIsOpen(true);
          setTimeout(() => {
            // alert(JSON.stringify(message.replace(tokenInfo.data.data[tokenSymbol].name, '"'+tokenInfo.data.data[tokenSymbol].name+'"')));
            // onClose && onClose();
          }, 200);
        } else {
          // alert(JSON.stringify(res.message));
          // onClose && onClose(true);
        }
      } else {
        let reqs = [];
        for (let i = 0; i < selectedTokens.length; i++) {
          const contractAddress = Object.keys(contractMetadata).find(
            (key) => contractMetadata[key] === selectedTokens[i]
          );
          const data = {
            chain: "ethereum",
            decimals: selectedTokens[i].decimals,
            logo: logoPrefix + selectedTokens[i].logo,
            name: selectedTokens[i].name,
            symbol: selectedTokens[i].symbol,
            contractAddress: contractAddress,
          };
          reqs.push(actions.addCustomToken(data, userData.user.token));
        }

        let res = await Promise.all(reqs);

        for (let i = 0; i < res.length; i++) {
          if (res[i].statusCode === 400) {
            // const message = res[i].details[0].message.message;
            setToastIsOpen(true);
            // alert(selectedTokens[i].name + " " + message);
          } else {
            // alert(selectedTokens[i].name + " " + res[i].message.message);
          }
        }
        onClose && onClose();
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setToastIsOpen(true);
      setTimeout(() => {
        onClose(!shouldRefresh);
      }, 300);
    }
  };

  const isValidate = useMemo(() => {
    if (addCustomTokenVisible) {
      if (
        selectedChain.hasOwnProperty("network_name") &&
        contractAddress.length &&
        tokenSymbol?.length &&
        tokenDecimal?.length
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (selectedTokens.length) {
        return false;
      } else {
        return true;
      }
    }
  }, [
    addCustomTokenVisible,
    contractAddress,
    selectedChain,
    selectedTokens,
    tokenDecimal,
    tokenSymbol,
  ]);

  const getCustomTokenDetails = async () => {
    if (contractAddress && selectedChain) {
      const details = await actions.getCustomTokenDetails(
        contractAddress,
        selectedChain.network_name
      );

      if (details === "invalid") {
        setError("Token not found on current chain");
        setTokenSymbol("");
        setTokenDecimal("");
      } else if (details.hasOwnProperty("symbol")) {
        setError("");
        setTokenSymbol(details.symbol);
        setTokenDecimal(details.decimals);
      } else {
        setError("");
        setTokenSymbol("");
        setTokenDecimal("");
      }
    }
  };
  useEffect(() => {
    getCustomTokenDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, selectedChain]);

  const onCloseAction = () => {
    onClose(shouldRefresh);
  };

  return (
    <MainModal title="Add Token" onClose={onCloseAction} dataClass="add_asset">
      <div className="modal_succes_content max-h-fit">
        <div className="form__inner_filter_assets relative">
          <div className="form__inner_filter_header form__add_assets_filter_header">
            <SearchAsset
              assetSearchHandler={assetSearchHandler}
              filter={filter}
              setFilter={setFilter}
            />
          </div>
          <div
            onClick={() => setAddCustomTokenVisible(!addCustomTokenVisible)}
            className="flex items-center justify-between assets__add_custom_token"
          >
            <div className="modal_add_assets_custom_token_info flex items-center justify-between">
              <div className="assets__add_new">
                <button
                  className={`btn-default btn-add btn-icon modal__trigger ${
                    addCustomTokenVisible ? "active" : ""
                  }`}
                  data-target="modal_add_assets"
                />
                <span>Add Custom Token</span>
              </div>
            </div>
            <div
              className={
                addCustomTokenVisible
                  ? "modal_add_asset_custom_token flex items-center justify-between active"
                  : "modal_add_asset_custom_token flex items-center justify-between"
              }
            >
              <span className="icon-icon-chevron"></span>
            </div>
          </div>
          {addCustomTokenVisible ? (
            <div className="add_custom_token_info">
              <div className="modal_send_rows">
                <DropdownNetworkNew
                  extraClass="dropdown_chains"
                  networks={chains}
                  onSelect={handleSelectChain}
                  noBlockScroll={false}
                />
              </div>
              <div className="modal_send_rows">
                <TokenContractAddressInput
                  onChange={handleChangeContractAddress}
                  contractAddress={contractAddress}
                  error={error}
                />
              </div>
              <div className="modal_send_rows">
                <TokenSymbolInput
                  onChange={handleChangeTokenSymbol}
                  symbol={tokenSymbol}
                />
              </div>
              <div className="modal_send_rows pb-16 ">
                <TokenDecimalInput
                  onChange={handleChangeTokenDecimal}
                  decimal={tokenDecimal}
                />
              </div>
            </div>
          ) : (
            <AddAssetList filter={filter} onClick={handleSelectTokenItem} />
          )}
          <div className="modal_footer_extra_gray modal_footer_add_assets">
            <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
              <PrimaryBtn
                classes="btn-default btn-white-bordered close_modal_cancel"
                onClick={onCloseAction}
                label="Cancel"
              />
              <PrimaryBtn
                classes="btn-default btn-blue modal__trigger"
                label="Add Token"
                disabled={isValidate}
                onClick={handleAddToken}
              />
            </div>
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalAddAsset;
