const APIS = {
  login: process.env.REACT_APP_AUTH_URL + "/auth/local",
  registration: process.env.REACT_APP_AUTH_URL + "/auth/registration",
  otp_verification: process.env.REACT_APP_AUTH_URL + "/auth/verify-otp",
  otp_email_verification: process.env.REACT_APP_AUTH_URL + "/auth/verify-otp",
  resend_otp: process.env.REACT_APP_AUTH_URL + "/auth/resend-otp",
  check_email:
    process.env.REACT_APP_CHECKING_SERVICE_URL + "/checking/check-email/",
  get_safle_id_email:
    process.env.REACT_APP_AUTH_URL + "/auth/get-email-and-safleid/",
  forgot_pass: process.env.REACT_APP_AUTH_URL + "/auth/forgot-password",
  migrate: process.env.REACT_APP_AUTH_URL + "/auth/migrate",
  retrieve_vault: process.env.REACT_APP_AUTH_URL + "/vault/retrieve",
  retrieve_encription_key:
    process.env.REACT_APP_AUTH_URL + "/auth/encrypted-encryption-key",
  update_vault: process.env.REACT_APP_AUTH_URL + "/vault",
  link_wallet_to_safleid:
    process.env.REACT_APP_RELAYER_SERVICE_URL + "/register-other-coin",
  update_link_wallet_to_safleid:
    process.env.REACT_APP_RELAYER_SERVICE_URL + "/update-other-coin",
  change_safleid: process.env.REACT_APP_RELAYER_SERVICE_URL + "/update-safleid",
  update_credentials: process.env.REACT_APP_AUTH_URL + "/auth/credentials",
  historical_listing:
    process.env.REACT_APP_MARKET_SERVICE_URL + "/historical-listing",
  currencySave: process.env.REACT_APP_WALLETS_URL + "/wallet/user-currency",
  get_notifications:
    process.env.REACT_APP_NOTIFICATION_SERVICE_URL +
    "/notification/user-notification",
  update_user_notification:
    process.env.REACT_APP_NOTIFICATION_SERVICE_URL +
    "/notification/user-notification",
};

export default APIS;
