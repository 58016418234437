import React, { useEffect }  from 'react';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { EXPORT_WALLET_PRIVATE_KEY_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import { useApp } from '../../../helpers/AppProvider';
import ModalEnterPinFunctions from './ModalEnterPinFunctions';
import ModalExportCancelFunctions from './ModalExportCancelFunctions';
import ModalExportPrivateKeyFunctions from './ModalExportPrivateKeyFunctions';

const ExportWalletPrivateKeyProcess = () => {

    const { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}} = useApp().appData || {};
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(EXPORT_WALLET_PRIVATE_KEY_FLOW);

    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return(
        <>
            {activeModalLabel === 'export_prvkey_enter_pin' &&
                <ModalEnterPinFunctions />
            }
            {activeModalLabel === 'export_prvkey' &&
               <ModalExportPrivateKeyFunctions />
            }
            {activeModalLabel === 'export_prvkey_cancel' &&
               <ModalExportCancelFunctions />
            }
        </>
    );

}

export default ExportWalletPrivateKeyProcess;