const WALLETS_URL = process.env.REACT_APP_WALLETS_URL;

export const fetchFavouriteTokens = async (userData) => {
  const res = await fetch(`${WALLETS_URL}/wallet/user-favourite`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${userData.user.token}`,
      "Content-Type": "application/json",
    },
  }).then((e) => e.json());

  return {
    data: res.hasOwnProperty("data") ? res.data : [],
  };
};

export const getUserCustomToken = async (userData) => {
  const res = await fetch(WALLETS_URL + "/wallet/user-token", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + userData.user.token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      throw new Error(err);
    });

  return res;
};
