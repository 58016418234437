import NFTImage from '../NFTImage/NFTImage';
import NFTags from '../NFTTags/NFTTags';
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../helpers/AppProvider";
import ROUTES from "../../../helpers/routes";
const NFTCard = (props) => {
  const navigate = useNavigate();
  const item = props.items;

  return (
    <>
      <div
        className="nft__item-box max-w-[100%] cursor-pointer"
        onClick={() =>
          navigate(ROUTES.NFTS_DETAIL, {
            state: { nftData: item, activeWallet: props?.activeWallet },
          })
        }
        style={{
          boxShadow:'4px 4px 10px 1px rgb(0 0 0 / 20%)',
          height: 280,
        }}
      >
        <NFTImage image={item?.metadata?.image} />
        <div className=" pl-5 nft__item-desc">
          <span className="nft-name font-extrabold text-xl">
            {item?.metadata?.name}
          </span>
        </div>
        <NFTags />
      </div>
    </>
  );
};
  
  export default NFTCard;
  