import React, { useEffect, useState } from "react";

const ModalCurrencyItem = (items) => {
  const imageRef = React.createRef();

  // eslint-disable-next-line no-unused-vars
  const [defaultImage, setDefaultImage] = useState(false);
  const item = items.items;

  useEffect(() => {
    const ni = new Image();
    const listener = () => {
      if (imageRef.current) {
        imageRef.current.style.backgroundImage =
          "url(images/currency/coin.svg)";
        setDefaultImage(false);
      }
    };
    ni.addEventListener("error", listener);
    ni.src = item["Country Flag"];
    return () => {
      document.removeEventListener("error", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageRef]);

  return (
    <div
      className={
        "select__currency form__input_filter_item " +
        (items.highlight === item["Currency Name"] ? "active_currency" : "")
      }
      data-name={item["Currency Name"] + " " + item["Currency Code"]}
      onClick={items.clickHandle}
    >
      <div className="select__currency-image">
        <div
          className="select__currency-image-icon"
          style={{
            backgroundImage: `url(${item["Country Flag"]})`,
            backgroundSize: "cover",
            // filter: defaultImage? 'grayscale(1)' : 'none',
            // opacity: defaultImage? '0.6' : '1'
          }}
          ref={imageRef}
        />
      </div>
      <div className="select__currency-details">
        <div className="select__currency-name">{item["Currency Name"]}</div>
        <div className="select__currency_prefix">
          {item["Currency Code"]} - {item["Currency Code"]}
        </div>
      </div>
      <div className="selected__currency">
        {items.highlight && <span className="icon-circle-check-solid"></span>}
        {!items.highlight && <span className="icon-circle"></span>}
      </div>
    </div>
  );
};

export default ModalCurrencyItem;
