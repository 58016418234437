const PrimaryBtn = ({ classes, label, onClick, disabled= false, extraStyle={} }) => {
  return (
    <button
      className={classes}
      // data-target="modal_change_safle_user"
      onClick={(e) => onClick(e)}
      disabled={disabled}
      style={extraStyle}
      >
      {label}
    </button>
  );
};

export default PrimaryBtn;
