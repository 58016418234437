import ActivityHistoryItem from "../ActivityHistoryItem/ActivityHistoryItem";
// import SearchAsset from "../../common/SearchAsset/SearchAsset";
import React, { useState, useEffect } from "react";
// import { useUser } from "./../../../helpers/UserProvider";
// import { useApp } from "./../../../helpers/AppProvider";
import ActivityHistoryItemFake from "../ActivityHistoryItemFake/ActivityHistoryItemFake";
import AssetsTheadFilter from "../AssetsTheadFilter/AssetsTheadFilter";
import moment from "moment";
// import AssetsTabs from "../AssetsTabs/AssetsTabs";
// import { padding } from "aes-js";
import InfiniteScroll from "react-infinite-scroll-component";

const ActivityHistory = ({
  alltransactions,
  search,
  startDate,
  endDate,
  activityCall,
  endCalls,
}) => {
  // const { userData, userActions } = useUser();
  // const { appData, actions } = useApp();
  // const rowRef = useRef(null);
  const [highlighted, setHighlightedItem] = useState(false);

  // const [assets, setAssets] = useState([]);
  const [allTransactionList, setAllTransactionsList] = useState([]);
  const [descendingSort, setDescendingSort] = useState(true);

  useEffect(() => {
    if (startDate && endDate) {
      const dateFilteredList = alltransactions.filter((i) => {
        return (
          i !== undefined &&
          moment.unix(i.date).format("YYYY-MM-DD") >=
            moment(startDate).format("YYYY-MM-DD") &&
          moment.unix(i.date).format("YYYY-MM-DD") <=
            moment(endDate).format("YYYY-MM-DD")
        );
      });
      setAllTransactionsList(dateFilteredList);
    } else {
      setAllTransactionsList(alltransactions);
    }
  }, [startDate, endDate, alltransactions]);

  const onClickRow = (e) => {
    setHighlightedItem(e === highlighted ? false : e);
  };

  const filterAssetName = [
    {
      // key : 1,
      filter_label: "Sort Ascending",
      filter_icon: "icon-icon_profit icon_asc",
      filter_callback: () => setDescendingSort(false),
    },
    {
      // key : 2,
      filter_label: "Sort Descending",
      filter_icon: "icon-icon_profit icon_desc",
      filter_callback: () => setDescendingSort(true),
    },
  ];

  return (
    <div className="assets__overview_content">
      <div
        className="assets__overview_content_tab content_tab-activity active "
        data-view="all__activity"
      >
        <div className={"assets__overview_content-filtered_items "}>
          <div className="sticky_phantom_left">
            {alltransactions?.length &&
              alltransactions?.map((item, index) => (
                <ActivityHistoryItemFake
                  key={index}
                  highlight={index !== false && index + 1 === highlighted}
                  item={item}
                />
              ))}
          </div>
          <div className="sticky_items_wrapper">
            <div className="sticky_mobile_header">
              <div className="assets__overview_content-filtered_items_row assets__overview_content-filtered_items_heading">
                <div className="sticky_mobile gray_bg">
                  <div className="aoc_table_col" data-cls="activity_asset_name">
                    <span>Asset Name</span>
                  </div>
                </div>
                <div className="scrollable_mobile">
                  <div className="aoc_table_col" data-cls="activity_asset_name">
                    <span>Asset Name</span>
                  </div>
                  <div className="aoc_table_col" data-cls="activity_asset_time">
                    <AssetsTheadFilter
                      filterName={"Time"}
                      items={filterAssetName}
                    />
                  </div>
                  <div
                    className="aoc_table_col"
                    data-cls="activity_asset_activity"
                  >
                    <span>Type of Activity</span>
                  </div>
                  <div className="aoc_table_col" data-cls="activity_asset_fees">
                    <span>Amount</span>
                  </div>
                  <div
                    className="aoc_table_col"
                    data-cls="activity_asset_amount"
                  >
                    <span>Fees</span>
                  </div>

                  <div
                    className="aoc_table_col"
                    data-cls="activity_asset_status"
                  >
                    <span>Status</span>
                  </div>
                  <div
                    className="aoc_table_col"
                    data-cls="activity_asset_view_details"
                  >
                    <span>View Details</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="scrollable_mobile_inner_fileter_items">
              {/* {!alltransactions?.length && endCalls && (
                <div style={{ textAlign: "center", padding: "10px 0px" }}>
                  There is no activity yet.
                </div>
              )} */}
              {allTransactionList && (
                <InfiniteScroll
                  dataLength={alltransactions?.length}
                  next={activityCall}
                  hasMore={!endCalls} // Replace with a condition based on your data source
                  loader={
                    <div className="fade p-4 bg-white border border-solid border-slate-100 rounded-xl z-10 flex flex-col items-center justify-center">
                      {" "}
                      <img
                        src="/images/registration/loading.svg"
                        alt="loading"
                        width={40}
                        className="my-4"
                      />
                    </div>
                  }
                  endMessage={
                    <div className="ml-[500px] my-[20px]">
                      No more data to load.
                    </div>
                  }
                >
                  {allTransactionList &&
                    allTransactionList
                      // eslint-disable-next-line array-callback-return
                      ?.filter((i, index) => {
                        if (search === "") {
                          return i;
                        } else {
                          if (i?.hash?.includes(search)) {
                            return i;
                          } else if (
                            i?.wallet_label
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return i;
                          } else if (i?.name) {
                            if (
                              typeof i?.name === "string" &&
                              i?.name
                                ?.toLowerCase()
                                .includes(search?.toLowerCase())
                            ) {
                              return i;
                            } else {
                              if (
                                i?.name?.from
                                  ?.toLowerCase()
                                  .includes(search?.toLowerCase()) ||
                                i?.name?.to
                                  ?.toLowerCase()
                                  .includes(search?.toLowerCase())
                              ) {
                                return i;
                              }
                            }
                          } else {
                            return i;
                          }
                        }
                      })
                      .sort((date_1, date_2) => {
                        const dateA = moment.unix(date_1.date);
                        const dateB = moment.unix(date_2.date);
                        return descendingSort ? dateB - dateA : dateA - dateB;
                      })
                      .map(
                        (item, index) =>
                          item !== undefined && (
                            <ActivityHistoryItem
                              key={index}
                              index={index}
                              highlight={
                                index !== false && index + 1 === highlighted
                              }
                              item={item}
                              clickHandle={() => onClickRow(index + 1)}
                            />
                          )
                      )}
                </InfiniteScroll>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityHistory;
