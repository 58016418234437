import React from 'react';
import MainModal from '../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalPINChanged = ( { onClose } ) => {

  return (
    <MainModal onClose={ onClose } bodyClasses={ 'pt_70' } extraClasses={ [ 'modal_w400', 'modal_p30', 'modal_mh400', 'modal_dflex', 'modal_success_ms' ] } dataClass="modal_pin_changed" >
      <div className="modal_succes_content">
        <ModalHeadIcon span={ 'icon-check-circle-solid' } />
        <div className="modal_succes_head">PIN updated</div>
      </div>
      <div className="modal_succes_content_actions">
        <PrimaryBtn classes={ 'btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16' } onClick={onClose} label={ 'OK' } />
      </div>
    </MainModal>
  );
};

export default ModalPINChanged;