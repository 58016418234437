import TokenIcon from '../common/TokenIcon/TokenIcon';



const AddTokenItem = ( { coinName, coinPrefix, coinLogo, onClickItem } ) => {
    return (
        <div
            className='asset__coin form__input_filter_item modal__trigger'
            data-target="modal_asset_added"
            data-name='Ethereum'
            onClick={ onClickItem }
        >
            <TokenIcon logo={ coinLogo } />
            <div>
                <div className="asset__coin-prefix">{ coinPrefix }</div>
                <div className='asset__coin-name'>{ coinName }</div>
            </div>
        </div>
    );
};



export default AddTokenItem;