import React, { useEffect }  from 'react';
import { useApp } from '../../../helpers/AppProvider';

import ModalAssetPurchase from './../../dialogs/ModalAssetPurchase';
import { BUY_TRANSAK_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import useModalNavigation from '../../../helpers/useModalNavigation';

const BuyTransakProcess = () => {
    const { appData: { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}} } = useApp() || {};

    const [ setActiveModalNav ] = useModalNavigation(BUY_TRANSAK_FLOW);
   
    const onClose = () => {
        setActiveModalNav(DEFAULT_MODAL_STATUS);
    };
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    return (
        <>
            {activeModalLabel === 'modal_buy_transak' &&
                <ModalAssetPurchase 
                    onClose={onClose}
                    title={'Buy with Transak'}
                    extraClass='modal_transak_new'
                    callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
                    callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
                    modalCancel_label='modal_buy_asset_cancel'
                    modalNext_label='modal_buy_crypro_asset'
                />
            }
        </>
    );
}

export default BuyTransakProcess;