import React from 'react';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';

const ModalLinkWallet = ( { onClose, callbackOpenPopup, checkDuplicateWalletChain } ) => {

    return (
        <MainModal onClose={ onClose } title=" " extraClasses={ [ 'modal_w420', 'modal_dflex', 'modal_link_to_safle_id' ] } dataClass="modal_link_to_safle_id" >
            <div className="modal_succes_content">
                <div className="modal--padding">
                    <ModalHeadIcon img="images/icon-link-safle-id.svg" />
                    <div className="modal_succes_head ">Link wallet to Safle ID</div>
                    <div className="modal_succes_desc">
                        <span className="simple_text simple_text_centered mb_50">This wallet will be linked to your SafleID. This will  push a transaction to the Polygon Blockchain.</span>
                    </div>
                </div>
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns">
                    <PrimaryBtn classes="btn-default btn-white-bordered close_modal_cancel" label="Cancel" onClick={() => onClose()}/>
                    <PrimaryBtn classes="btn-default btn-blue modal__trigger" label="Proceed" onClick={() => callbackOpenPopup(checkDuplicateWalletChain()? 'update_link_to_safleid':'link_wallet_pin')}/>
                </div>
            </div>
        </MainModal>
    );
};

export default ModalLinkWallet;