import SwapHistoryList from "../SwapHistoryList/SwapHistoryList";

const SwapHistory = () => {
  return (
    <div className="token__swap-col token__swap-right">
      <div className="token__swap-top">
        <span className="sheading">swap history</span>
      </div>
      <SwapHistoryList />
    </div>
  );
};

export default SwapHistory;