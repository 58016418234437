import React, { useEffect, useState } from "react";

import ModalAddAsset from "./../../dialogs/ModalAddAsset";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  ADD_CUSTOM_ASSET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useUser } from "../../../helpers/UserProvider";

const ModalAddAssetFunctions = (props) => {
  const { userActions } = useUser();
  const [items, setItems] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    ADD_CUSTOM_ASSET_FLOW
  );

  const onClose = (bool) => {
    props.onClose(bool);
    setActiveModalNav("CLOSED");

    // setActiveModalNav('modal_receive_asset_cancel',true);
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //
  const load_wallets = async () => {
    const acc = await userActions.getAccounts();
    //
    const addrs = acc ? Object.values(acc).map((e) => e.address) : [];
    setItems(addrs[0]);
  };
  useEffect(() => {
    load_wallets();
  }, []);

  return (
    <ModalAddAsset
      setToastIsOpen={(e) => props.setToastIsOpen(e)}
      shouldRefresh={props.shouldRefresh}
      onClose={onClose}
      modalCancel_label="modal_receive_asset_cancel"
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalAddAssetFunctions;
