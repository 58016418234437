import React from 'react';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';

const ModalLinkFailed = ( { onClose, callbackOpenPopup } ) => {

    return (
        <MainModal onClose={ onClose } title=" " extraClasses={ [ 'modal_w420 modal_p30', 'modal_dflex', 'modal_remove_wallets' ] } dataClass="modal_remove_wallet" >
            <div className="modal_succes_content">
                <ModalHeadIcon img="images/warning-black-24-dp.svg" />
                <div className="modal_succes_head ">Warning</div>
                <div className="modal_succes_desc mb_70">
                    <span className="simple_text simple_text_centered">You have another account linked to safleID.<br />
                        Confirm if you want to unlink that and link this.</span>
                </div>
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns">
                    <PrimaryBtn classes="btn-default btn-blue modal__trigger" label="Confirm" onClick={() => callbackOpenPopup('link_wallet_pin')}/>
                    <PrimaryBtn classes="btn-default btn-white-bordered close_modal_cancel" label="Cancel"  onClick={() => onClose()}/>
                </div>
            </div>
        </MainModal>
    );
};

export default ModalLinkFailed;