/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import MainModal from "../generic/MainModal";
import {
  // CropperRef,
  FixedCropper,
  ImageRestriction,
  // minScale,
  // useScaleImageOptions,
} from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";
import { useModalData } from "../../helpers/useModalNavigation";
import { useUser } from "../../helpers/UserProvider";
// import { useApp } from "../../helpers/AppProvider";
// import { delay_code } from "../../helpers/generalHelper";
import Cropper from "../../helpers/Cropper";

const allowedTypes = ["image/jpeg", "image/jp2", "image/png"];

const ModalProfileUpdate = (props) => {
  // const { appData, actions } = useApp();
  const { userActions } = useUser();
  const [modalData, setData] = useModalData();
  const uploadRef = React.useRef();
  const cropperRef = React.useRef(null);
  const [uploadTypeError, setUploadTypeError] = useState(false);

  const zoomMin = 1;
  const zoomMax = 20;

  const [modalState, updateState] = useState(modalData?.modalState || 0);
  const [progress, setProgress] = useState(0.0);
  const [picName, setPicName] = useState("");
  const [beenCropped, setBeenCropped] = useState(false);
  const setModalState = (state) => {
    setData({ modalState: state });
    updateState(state);
  };
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");
  const [zoom, setZoom] = useState(zoomMin);
  const [scale, setScale] = useState(zoomMin);
  const [pos, setPos] = useState({ x: 0, y: 0 });

  // useEffect( ( ) => {
  //
  // }, [ zoom ]);

  const restartUpload = () => {
    setModalState(0);
    setBeenCropped(false);
    setImage("");
    setUploadTypeError(false);
  };

  const triggerUploadPicker = () => {
    setUploadTypeError(false);
    uploadRef.current.click();
  };

  const handleFile = (file) => {
    setPicName(file.name);

    if (allowedTypes.indexOf(file.type) === -1) {
      setModalState(0);
      setUploadTypeError(true);
      return;
    }

    const fr = new FileReader();
    fr.onload = () => {
      setImage(fr.result);
      setPreview(fr.result);
      setPos({ x: 0, y: 0 });
      setZoom(zoomMin);
      setScale(zoomMin);
      setModalState(3);
    };
    // fr.onprogress = ( e ) => {
    //
    // }
    fr.readAsDataURL(file);
  };

  const onLoadImage = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      setModalState(1);
      //
      handleFile(files[0]);
    }
  };

  const onDrop = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    const { files } = evt.dataTransfer; // instead of 'Text'
    handleFile(files[0]);
  };

  const handleCrop = () => {
    setModalState(4);
  };

  // const zoomCrop = (val) => {
  //   //
  //   setScale(parseFloat(val).toFixed(2));
  //   setZoom(parseFloat(val));
  //   // if( cropperRef.current ){
  //   //
  //   //     cropperRef.current.transformImage( { scale: val*0.1 }, { immediate: true }  );
  //   // }
  // };

  const scaleHandler = (val) => {
    // if( )
    setZoom(val);
    setScale(val);
  };
  const moveHandler = (val) => {
    setPos(val);
  };

  const finishCrop = () => {
    if (cropperRef.current) {
      const cropData = cropperRef.current?.toDataURL();
      setPreview(cropData);
      setBeenCropped(true);
    }

    setModalState(3);
  };

  const saveAvatar = async () => {
    // actions.setLoading( true );

    if (cropperRef.current) {
      const cropData = cropperRef.current
        .getCanvas({ height: 180, width: 180 })
        ?.toDataURL();

      setModalState(2);
      const saveImag = beenCropped ? preview : cropData;
      await userActions.updateProfileImage(saveImag, updateLoading);
      //
      // actions.setLoading( false );
      props.callbackProfileUpdated && props.callbackProfileUpdated();
    } else {
      setModalState(2);
      await userActions.updateProfileImage(preview, updateLoading);
      //
      // actions.setLoading( false );
      props.callbackProfileUpdated && props.callbackProfileUpdated();
    }
  };

  const updateLoading = (perc) => {
    setProgress(perc);
    //
  };

  return (
    <MainModal
      onClose={props.onClose}
      extraClasses={[
        "modal_w440",
        "modal_mh400",
        "modal_dflex",
        "modal_profile_update",
      ]}
      dataClass={props.dataClass}
    >
      {modalState === 0 && (
        <div className="dad--padding">
          <div
            className="dad"
            onClick={triggerUploadPicker}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={onDrop}
            style={{ borderColor: uploadTypeError ? "#ff0000" : "#CBD7E9" }}
          >
            <input
              type="file"
              accept="image/*"
              ref={uploadRef}
              onChange={onLoadImage}
            />
            <img
              className="dad_icon"
              src="images/settings/img-placeholder.svg"
              alt="img placeholder"
            />
            <div className="dad_title">
              Drag your image here, or <span>browse</span>
            </div>
            <div className="dad_subtitle">Supports: JPG, JPEG2000, PNG</div>
            {uploadTypeError && (
              <div
                className="dad_subtitle"
                style={{
                  color: "#ff0000",
                  position: "absolute",
                  bottom: 30,
                  margin: "0 auto",
                  left: 0,
                  width: "100%",
                }}
              >
                Invalid image type!
              </div>
            )}
          </div>
        </div>
      )}

      {modalState === 1 && (
        <div className="dad_uploading">
          <div className="dad_title">Uploding File...</div>
          <div className="dad_subtitle">Avatar image.jpg </div>
          <div className="circle-wrap">
            <div className="circle">
              <div className="mask full">
                {/* default, error - classes */}
                <div className="fill default" />
              </div>
              <div className="mask half">
                {/* default, error - classes */}
                <div className="fill default" />
              </div>
              <div className="inside-circle">
                {" "}
                <span>
                  <img src={image} alt="profile picture" />
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="progress-number">32%</div>
          {/* <div className="progress-error">Uploding Error</div> */}
        </div>
      )}

      {modalState === 2 && (
        <div className="dad_uploading">
          <div className="dad_title">Uploading File...</div>
          <div className="dad_subtitle">{picName} </div>
          <div className="circle-wrap">
            <div className="circle">
              <div class="preloader">
                <svg width="162" height="162" viewBox="0 0 100 100">
                  <g
                    style={{
                      transformOrigin: "50% 50%",
                      transform: "rotate(-90deg)",
                    }}
                  >
                    <circle
                      r="48"
                      cx="50"
                      cy="50"
                      fill="transparent"
                      stroke-width="2"
                      stroke="#0669F8"
                      strokeDasharray={2 * Math.PI * 48}
                      strokeDashoffset={2 * Math.PI * 48 * (1 - progress)}
                    />
                  </g>
                </svg>
              </div>
              <div className="inside-circle">
                {" "}
                <span>
                  <img src={preview} alt="profile picture" />
                </span>{" "}
              </div>
            </div>
          </div>
          <div className="progress-number">
            {Math.min(100, (progress * 100).toFixed(1))}%
          </div>
          {/* <div className="progress-error">Uploding Error</div> */}
        </div>
      )}

      {modalState === 3 && (
        <div className="dad_uploaded">
          <div className="dad_title">Preview File</div>
          <img
            className="profile-picture"
            src={preview}
            alt="profile picture"
          />

          <div className="profile-picture hidden-cropper">
            <FixedCropper
              ref={cropperRef}
              className="profile-picture_cropper"
              stencilSize={{
                width: 280,
                height: 280,
              }}
              stencilProps={{
                handlerClassNames: {
                  default: "handler",
                  hover: "handler--hover",
                  eastNorth: "handler--east-north",
                  north: "handler--north",
                  westNorth: "handler--west-north",
                  west: "handler--west",
                  westSouth: "handler--west-south",
                  south: "handler--south",
                  eastSouth: "handler--east-south",
                  east: "handler--ease",
                },
                movable: false,
                resizable: false,
                aspectRatio: 1 / 1,
              }}
              src={image}
              imageRestriction={ImageRestriction.stencil}
            />
          </div>
          <div className="img-actions">
            <div className="btn-crop" onClick={handleCrop}>
              <img src="images/settings/crop-icon.svg" alt="crop icon" />
            </div>
            <div className="btn-download" onClick={restartUpload}>
              <img
                src="images/settings/download-icon.svg"
                alt="download icon"
              />
            </div>
          </div>
          <div className="dad_footer">
            <button
              className="btn-default btn-blue btn-br6 btn-mh46 btn-fw600"
              onClick={saveAvatar}
            >
              Save
            </button>
          </div>
        </div>
      )}

      {modalState === 4 && (
        <div className="dad_uploaded">
          <div className="dad_title">Edit your Image</div>
          {/* <img className="profile-picture" src={image} alt="profile picture" /> */}
          <div className="profile-picture_ctn">
            <div className="profile-picture">
              <Cropper
                src={image}
                setCanvasRef={(canv) => {
                  cropperRef.current = canv;
                }}
                onScaleHandler={scaleHandler}
                onMoveHandler={moveHandler}
                scale={scale}
                pos={pos}
              />
              {/* <FixedCropper ref={cropperRef} className="profile-picture_cropper" 
                        stencilSize={{
                            width: 280,
                            height: 280
                        }}
                        stencilProps={{
                            handlerClassNames: {
                                default: 'handler',
                                hover: 'handler--hover',
                                eastNorth: 'handler--east-north',
                                north: 'handler--north',
                                westNorth: 'handler--west-north',
                                west: 'handler--west',
                                westSouth: 'handler--west-south',
                                south: 'handler--south',
                                eastSouth: 'handler--east-south',
                                east: 'handler--ease',
                            },
                            movable: false,
                            resizable: false,
                            aspectRatio: 1/1}} src={image}
                        imageRestriction={ImageRestriction.stencil} /> */}
            </div>
            <div className="crop_ctn">
              <div
                className="crop_increase"
                onClick={(e) => {
                  setZoom(Math.min(zoomMax, zoom + 1));
                  setScale(Math.min(zoomMax, zoom + 1));
                }}
              >
                +
              </div>
              <input
                type="range"
                min={zoomMin}
                max={zoomMax}
                step="0.01"
                // defaultValue={zoom}
                className="slider"
                id="myRange"
                value={zoom}
                onChange={(e) => {
                  setZoom(e.target.value);
                  setScale(e.target.value);
                }}
              />
              <div
                className="crop_decrease"
                onClick={(e) => {
                  setZoom(Math.max(zoomMin, zoom - 1));
                  setScale(Math.max(zoomMin, zoom - 1));
                }}
              >
                -
              </div>
            </div>
          </div>
          <div className="img-actions">
            <div className="btn-crop active">
              <img src="images/settings/crop-icon.svg" alt="crop icon" />
            </div>
            <div className="btn-download" onClick={restartUpload}>
              <img
                src="images/settings/download-icon.svg"
                alt="download icon"
              />
            </div>
          </div>
          <div className="dad_footer">
            <button
              className="btn-default btn-blue btn-br6 btn-mh46 btn-fw600"
              onClick={finishCrop}
            >
              Save Crop
            </button>
          </div>
        </div>
      )}
    </MainModal>
  );
};

export default ModalProfileUpdate;
