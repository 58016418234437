import React, { useEffect, useState } from "react";
// import BalanceAssetsList from "../BalanceAssetsList/BalanceAssetsList";
import HideLowBalance from "../HideLowBalance/HideLowBalance";
import { useApp } from "./../../../helpers/AppProvider";
import { useNavigate } from "react-router-dom";
import ROUTES from "./../../../helpers/routes";
import { formatMoney } from "../../../helpers/walletHelpers";

const AssetsBalanceList = () => {
  const [hideSmall, setHideSmall] = useState(false);
  const { appData, actions } = useApp();
  const [chainAssets, setChainAssets] = useState();
  const [assetsList, setAssetsList] = useState([]);
  const [assetsPrice, setAssetsPrice] = useState([]);
  const [assetsCompleteList, setAssetsCompleteList] = useState([]);
  const navigate = useNavigate();
  const currency = actions.getActiveCurrencySymbol();

  const changeState = () => {
    setHideSmall(!hideSmall);
  };

  const returnImageUrL = async (imagePath, symbol) => {
    if (imagePath) {
      const res = await actions.urlValidation(imagePath);
      if (res) {
        return imagePath;
      }
    }
    if (symbol) {
      const checkAnotherUrl = await actions.urlValidation(
        `https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${symbol}.png`
      );
      if (checkAnotherUrl) {
        return `https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${symbol}.png`;
      }
    }

    return "/images/currency/coin.svg";
  };

  const fetchImageIcons = async (assets) => {
    const assetsArray = await Promise.all(
      assets.map(async (asset) => {
        return {
          ...asset,
          logo: await returnImageUrL(asset?.logo, asset?.symbol),
        };
      })
    );
    setAssetsList(assetsArray);
  };

  useEffect(() => {
    let assets = [];
    const AssetsInWallets = appData?.accounts
      // ?.filter((account) => account.type === "evm")
      ?.map((account) => {
        const AssetsInWallet = appData?.portfolioData
          ? Object.keys(appData?.portfolioData)?.length > 0
            ? appData?.portfolioData[account?.address]?.activeChains
                ?.filter((chain) => chain !== "avalanche")
                ?.map((chain) => {
                  let AssetsInChainInWallet;
                  if (account.type === "evm" && chain !== "bitcoin") {
                    AssetsInChainInWallet = [
                      ...appData?.portfolioData[account?.address][chain]
                        ?.tokens,
                    ];
                  }
                  AssetsInChainInWallet =
                    AssetsInChainInWallet?.length > 0
                      ? AssetsInChainInWallet.map((obj) => {
                          return { ...obj, chain: chain };
                        })
                      : [];
                  AssetsInChainInWallet.push({
                    token_address: null,
                    symbol:
                      appData?.portfolioData[account?.address][chain]?.symbol,
                    name: appData?.portfolioData[account?.address][chain]
                      ?.nativeCurrency,
                    logo: appData?.portfolioData[account?.address][chain]
                      ?.imageURL,
                    thumbnail: null,
                    decimals: null,
                    balance:
                      appData?.portfolioData[account?.address][chain]?.balance,
                    possible_spam: false,
                    chain: chain,
                  });
                  return AssetsInChainInWallet;
                })
            : []
          : [];
        return AssetsInWallet?.reduce((acc, obj) => {
          return acc?.concat(obj);
        }, []);
      });

    let unfilteredAssets =
      AssetsInWallets?.length > 0
        ? AssetsInWallets?.reduce((acc, obj) => {
            return acc?.concat(obj);
          })
        : [];

    unfilteredAssets =
      unfilteredAssets?.length > 0
        ? Object.values(
            unfilteredAssets.reduce((acc, token) => {
              const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
              if (!acc[key]) acc[key] = { ...token };
              else
                acc[key].balance = (
                  parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                ).toString();
              return acc;
            }, {})
          ).map((obj) => ({ ...obj }))
        : [];

    assets = unfilteredAssets;
    setAssetsCompleteList(assets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.portfolioData, chainAssets, appData?.activeWallet]);

  useEffect(() => {
    if (!appData?.portfolioData) {
      return;
    }

    let assets = [];
    if (appData?.activeWallet) {
      if (chainAssets) {
        //assets on specific chain for specific wallet
        if (
          appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets] ===
            undefined ||
          !appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
        ) {
          return;
        }
        let AssetsInChainInWallet;
        const accountType = appData?.accounts?.find(
          (account) => account?.address === appData?.activeWallet
        )?.type;
        if (accountType === "evm" && chainAssets !== "bitcoin") {
          AssetsInChainInWallet = [
            ...appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
              ?.tokens,
          ];
        }
        AssetsInChainInWallet =
          AssetsInChainInWallet?.length > 0
            ? AssetsInChainInWallet?.map((obj) => {
                return { ...obj, chain: chainAssets };
              })
            : [];
        AssetsInChainInWallet.push({
          token_address: null,
          symbol:
            appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
              ?.symbol,
          name: appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
            ?.nativeCurrency,
          logo: appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
            ?.imageURL,
          thumbnail: null,
          decimals: null,
          balance:
            appData?.portfolioData?.[appData?.activeWallet]?.[chainAssets]
              ?.balance,
          possible_spam: false,
          chain: chainAssets,
        });
        assets = AssetsInChainInWallet;
      } else {
        //assets on all chains for specific wallet
        const AssetsInWallet = appData?.portfolioData[
          appData?.activeWallet
        ]?.activeChains
          ?.filter((chain) => chain !== "bitcoin" && chain !== "avalanche")
          ?.map((chain) => {
            // if (chain === "bitcoin") {
            //   return null;
            // }
            let AssetsInChainInWallet = [
              ...appData?.portfolioData?.[appData?.activeWallet]?.[chain]
                ?.tokens,
            ];
            AssetsInChainInWallet =
              AssetsInChainInWallet?.length > 0
                ? AssetsInChainInWallet?.map((obj) => {
                    return { ...obj, chain: chain };
                  })
                : [];
            AssetsInChainInWallet.push({
              token_address: null,
              symbol:
                appData?.portfolioData?.[appData?.activeWallet]?.[chain]
                  ?.symbol,
              name: appData?.portfolioData?.[appData?.activeWallet]?.[chain]
                ?.nativeCurrency,
              logo: appData?.portfolioData?.[appData?.activeWallet]?.[chain]
                ?.imageURL,
              thumbnail: null,
              decimals: null,
              balance:
                appData?.portfolioData?.[appData?.activeWallet]?.[chain]
                  ?.balance,
              possible_spam: false,
              chain: chain,
            });
            return AssetsInChainInWallet;
          });
        assets = AssetsInWallet?.reduce((acc, obj) => {
          return acc?.concat(obj);
        }, []);
      }
    } else {
      if (chainAssets) {
        //assets on specific chain for all wallets
        const AssetsInWallets = appData?.accounts?.map((account) => {
          if (
            appData?.portfolioData[account?.address]?.[chainAssets] ===
              undefined ||
            !appData?.portfolioData?.[account?.address]?.[chainAssets]
          ) {
            return [];
          }
          let AssetsInChainInWallet;
          if (chainAssets !== "bitcoin" && account.type === "evm") {
            AssetsInChainInWallet = [
              ...appData?.portfolioData?.[account?.address]?.[chainAssets]
                ?.tokens,
            ];
          }
          AssetsInChainInWallet =
            AssetsInChainInWallet?.length > 0
              ? AssetsInChainInWallet?.map((obj) => {
                  return { ...obj, chain: chainAssets };
                })
              : [];

          AssetsInChainInWallet.push({
            token_address: null,
            symbol:
              appData?.portfolioData?.[account?.address]?.[chainAssets]?.symbol,
            name: appData?.portfolioData?.[account?.address]?.[chainAssets]
              ?.nativeCurrency,
            logo: appData?.portfolioData?.[account?.address]?.[chainAssets]
              ?.imageURL,
            thumbnail: null,
            decimals: null,
            balance:
              appData?.portfolioData?.[account?.address]?.[chainAssets]
                ?.balance,
            possible_spam: false,
            chain: chainAssets,
          });
          return AssetsInChainInWallet;
        });

        let unfilteredAssets = AssetsInWallets?.reduce((acc, obj) => {
          return acc?.concat(obj);
        }, []);

        unfilteredAssets =
          unfilteredAssets?.length > 0
            ? Object.values(
                unfilteredAssets.reduce((acc, token) => {
                  const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
                  if (!acc[key]) acc[key] = { ...token };
                  else
                    acc[key].balance = (
                      parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                    ).toString();
                  return acc;
                }, {})
              ).map((obj) => ({ ...obj }))
            : [];

        assets = unfilteredAssets;
      } else {
        //assets on all chains for all wallets
        const AssetsInWallets = appData?.accounts
          // ?.filter((account) => account.type === "evm")
          ?.map((account) => {
            const AssetsInWallet = appData?.portfolioData[
              account?.address
            ]?.activeChains
              ?.filter((chain) => chain !== "avalanche")
              ?.map((chain) => {
                let AssetsInChainInWallet;
                if (account.type === "evm" && chain !== "bitcoin") {
                  AssetsInChainInWallet = [
                    ...appData?.portfolioData[account?.address][chain]?.tokens,
                  ];
                }
                AssetsInChainInWallet =
                  AssetsInChainInWallet?.length > 0
                    ? AssetsInChainInWallet.map((obj) => {
                        return { ...obj, chain: chain };
                      })
                    : [];
                AssetsInChainInWallet.push({
                  token_address: null,
                  symbol:
                    appData?.portfolioData[account?.address][chain]?.symbol,
                  name: appData?.portfolioData[account?.address][chain]
                    ?.nativeCurrency,
                  logo: appData?.portfolioData[account?.address][chain]
                    ?.imageURL,
                  thumbnail: null,
                  decimals: null,
                  balance:
                    appData?.portfolioData[account?.address][chain]?.balance,
                  possible_spam: false,
                  chain: chain,
                });
                return AssetsInChainInWallet;
              });
            return AssetsInWallet?.reduce((acc, obj) => {
              return acc?.concat(obj);
            }, []);
          });

        let unfilteredAssets =
          AssetsInWallets?.length > 0
            ? AssetsInWallets?.reduce((acc, obj) => {
                return acc?.concat(obj);
              })
            : [];

        unfilteredAssets =
          unfilteredAssets?.length > 0
            ? Object.values(
                unfilteredAssets.reduce((acc, token) => {
                  const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
                  if (!acc[key]) acc[key] = { ...token };
                  else
                    acc[key].balance = (
                      parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                    ).toString();
                  return acc;
                }, {})
              ).map((obj) => ({ ...obj }))
            : [];

        assets = unfilteredAssets;
        // setAssetsCompleteList(assets);
      }
    }

    let validAssets = [];
    let invalidAssets = [];
    // eslint-disable-next-line array-callback-return
    assets?.map((item) => {
      if (
        item?.possible_spam
        // isNaN(
        //   assetsPrice?.filter((item2) => item2?.symbol === item?.symbol)[0]
        //     ?.price
        // )
      ) {
        invalidAssets.push(item);
      } else {
        validAssets.push(item);
      }
    });
    validAssets.sort((a, b) => {
      let aValue, bValue;
      if (b?.decimals) {
        bValue = parseFloat(
          (assetsPrice?.filter((item2) => item2?.symbol === b?.symbol)[0]
            ?.price *
            parseFloat(b?.balance)) /
            Math.pow(10, b?.decimals)
        );
      } else {
        bValue = parseFloat(
          assetsPrice?.filter((item2) => item2?.symbol === b?.symbol)[0]
            ?.price * parseFloat(b?.balance)
        );
      }

      if (a?.decimals) {
        aValue = parseFloat(
          (assetsPrice?.filter((item2) => item2?.symbol === a?.symbol)[0]
            ?.price *
            parseFloat(a?.balance)) /
            Math.pow(10, a?.decimals)
        );
      } else {
        aValue = parseFloat(
          assetsPrice?.filter((item2) => item2?.symbol === a?.symbol)[0]
            ?.price * parseFloat(a?.balance)
        );
      }

      return bValue - aValue;
    });
    assets = hideSmall ? validAssets : validAssets?.concat(invalidAssets);
    fetchImageIcons(assets);

    setAssetsList(assets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.portfolioData, appData.activeWallet, chainAssets, hideSmall]);

  useEffect(() => {
    if (appData?.priceData) {
      setAssetsPrice(appData?.priceData);
    }
  }, [appData.priceData]);

  useEffect(() => {
    const accountType = appData?.accounts?.find(
      (account) => account?.address === appData?.activeWallet
    )?.type;
    if (appData.activeWallet && accountType === "bitcoin") {
      setChainAssets("bitcoin");
    } else {
      setChainAssets("");
    }
  }, [appData.activeWallet]);

  return (
    <div className="dashboard__overview-column dashboard__overview-balance-cont rounded-t-[10px]">
      {/* <div className="dashboard__overview-column-heading">Assets Balance</div> */}
      <div className="dashboard__overview-column-inner rounded-t-[10px]">
        <div className="flex flex-row justify-between items-center bg-[#F7F9FB] pr-[20px] text-[12px] leading-[14px] rounded-t-[10px]">
          <HideLowBalance
            title={"Hide Spam"}
            isChecked={hideSmall}
            handleChecked={changeState}
          />
          <div>
            <select
              value={chainAssets}
              onChange={(e) => setChainAssets(e?.target?.value)}
              className="bg-[#F7F9FB] border border-[#E5E5E5] rounded-[5px] px-[10px] py-[5px] text-black"
            >
              {appData?.activeWallet &&
              appData?.accounts?.find(
                (account) => account?.address === appData?.activeWallet
              )?.type === "bitcoin" ? (
                <option value="bitcoin">Bitcoin</option>
              ) : (
                <>
                  <option value="">All Chains</option>
                  {!appData?.activeWallet && (
                    <option value="bitcoin">Bitcoin</option>
                  )}
                  {appData.chains.map((chain, index) => {
                    return (
                      <option
                        key={index}
                        value={
                          chain.chain_name === "ethereum"
                            ? "eth"
                            : chain.chain_name
                        }
                        // disabled={chain.chain_name === "mantle"}
                      >
                        {chain.chain_name === "bsc"
                          ? chain.chain_name?.toUpperCase()
                          : chain.chain_name?.charAt(0).toUpperCase() +
                            chain.chain_name?.slice(1)}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
          </div>
        </div>
        <div className="dashboard__overview-column-inner-scroll flex flex-col divide-y overflow-y-scroll">
          {/* <BalanceAssetsList hideSmall={hideSmall} /> */}
          {assetsList !== undefined && assetsList.length > 0 ? (
            assetsList.map((asset, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center py-[10px] px-[10px] cursor-pointer"
                  onClick={() => {
                    navigate(ROUTES.ASSET_DETAIL, {
                      state: {
                        symbol: asset?.symbol,
                        chain: asset?.chain,
                        chainContractAddress: assetsCompleteList?.filter(
                          (item) => item?.symbol === asset?.symbol
                        ),
                        // chain: asset?.chain,
                        contractAddress: asset?.token_address
                          ? asset?.token_address
                          : "",
                      },
                    });
                  }}
                >
                  <div className="flex flex-row items-center">
                    <div className="h-12 w-12 flex flex-row items-end relative">
                      {asset?.possible_spam && (
                        <div className="absolute top-[-15px] left-[-12px] px-4 py-1 text-sm bg-orange-600 text-white">
                          SPAM
                        </div>
                      )}
                      <img
                        className="rounded-full w-full h-full"
                        src={
                          asset?.logo
                            ? asset?.logo
                            : "/images/currency/coin.svg"
                        }
                        alt={asset?.name}
                      />
                      <img
                        className="rounded-full w-[15px] h-[15px] relative bottom-0 right-[10px] border-2 border-white"
                        src={
                          appData.chains.find((chain) =>
                            asset?.chain === "eth"
                              ? chain.chain_name === "ethereum"
                              : chain.chain_name === asset?.chain
                          )?.details?.imageURL
                        }
                        alt={asset?.chain}
                      />
                    </div>
                    <div className="flex flex-col px-[10px] w-[150px] break-words">
                      <div>{asset?.name}</div>
                      <div>{asset?.symbol}</div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-right break-words">
                      {asset?.decimals
                        ? (
                            parseFloat(asset?.balance) /
                            Math.pow(10, asset?.decimals)
                          )
                            .toFixed(8)
                            .replace(/\.?0+$/, "") < 0.000001
                          ? "< 0.000001"
                          : (
                              parseFloat(asset?.balance) /
                              Math.pow(10, asset?.decimals)
                            )
                              .toFixed(8)
                              .replace(/\.?0+$/, "")
                        : parseFloat(asset?.balance)
                            ?.toFixed(8)
                            .replace(/\.?0+$/, "")}{" "}
                      {asset?.symbol}
                    </div>
                    {assetsPrice && (
                      <div className="text-right break-words">
                        {isNaN(
                          assetsPrice?.filter(
                            (item) => item?.symbol === asset?.symbol
                          )[0]?.price
                        )
                          ? "-"
                          : asset?.decimals
                          ? formatMoney(
                              actions.activeCurrency(
                                (
                                  assetsPrice?.filter(
                                    (item) => item?.symbol === asset?.symbol
                                  )[0]?.price *
                                  (parseFloat(asset?.balance) /
                                    Math.pow(10, asset?.decimals))
                                )
                                  .toFixed(2)
                                  .replace(/\.?0+$/, "")
                              )
                            ) +
                            " " +
                            currency
                          : formatMoney(
                              actions.activeCurrency(
                                (
                                  assetsPrice?.filter(
                                    (item) => item?.symbol === asset?.symbol
                                  )[0]?.price * parseFloat(asset?.balance)
                                )
                                  .toFixed(2)
                                  .replace(/\.?0+$/, "")
                              )
                            ) +
                            " " +
                            currency}
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center items-center w-[100%] p-[20px]">
              No assets found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsBalanceList;
