import React, { useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
// import { useUser } from "../../../helpers/UserProvider";

// import ModalEnterPinFunctions from "./../../common/SettingsChangeEmailProcess/ModalEnterPinFunctions";
import ModalPinConfirmFunctions from "./../../common/SettingsChangeEmailProcess/ModalPinConfirmFunctions";
import ModalEmailUpdatedFunctions from "./../../common/SettingsChangeEmailProcess/ModalEmailUpdatedFunctions";
import ModalEmailFailedFunctions from "./../../common/SettingsChangeEmailProcess/ModalEmailFailedFunctions";
import ModalEmailCancelFunctions from "./../../common/SettingsChangeEmailProcess/ModalEmailCancelFunctions";

// import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import {
  SETTINGS_CHANGE_EMAIL,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

import useModalNavigation from "../../../helpers/useModalNavigation";
// import { checkProperties } from "ethers/lib/utils";

const SettingsChangeEmailProcess = (props) => {
  // const { userData } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      // wallets = [],
    },
    // setAppData,
  } = useApp() || {};

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] = useModalNavigation(
    SETTINGS_CHANGE_EMAIL
  );

  const onEmailUpdate = () => {
    setActiveModalNav("CLOSED");
    props.onCompletProccess && props.onCompletProccess();
  };
  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {
        // modal order:
        // ModalPinConfirm -- modal_email_enter_pin
        // ModalActionSuccess -- modal_email_updated
        // ModalActionFailed -- modal_email_address_failed
        // ModalActionConfirm -- modal_cancel_email_address
      }
      {/* {activeModalLabel === 'modal_email_enter_pin_default' &&
                <ModalEnterPinFunctions />
            } */}
      {activeModalLabel === "modal_email_enter_pin" && (
        <ModalPinConfirmFunctions callbackOpenPopup={() => goNext()} />
      )}
      {activeModalLabel === "modal_email_enter_secondary_pin" && (
        <ModalPinConfirmFunctions callbackOpenPopup={() => goNext()} />
      )}

      {activeModalLabel === "modal_email_updated" && (
        <ModalEmailUpdatedFunctions onEmailUpdate={onEmailUpdate} />
      )}
      {activeModalLabel === "modal_email_address_failed" && (
        <ModalEmailFailedFunctions />
      )}
      {activeModalLabel === "modal_cancel_email_address" && (
        <ModalEmailCancelFunctions />
      )}
    </>
  );
};

export default SettingsChangeEmailProcess;
