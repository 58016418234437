import React, { useEffect, useState } from "react";
import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";
import MainModal from "../generic/MainModal";
import ModalAssetReceive from "./ModalAssetReceive";
import QRCodeImage from "qrcode";
import TooltipCopy from "../registration/TooltipCopy";
import QRCode from "../common/QRCode/QRCode";
import CopyAddress from "../common/CopyAddress/CopyAddress";
import DropDownNFTNetwork from "../common/DropDownNFTNetwork/DropDownNFTNetwork";
import DropdownWalletNew from "../common/DropdownWalletNew/DropdownWalletNew";
import Storage from "../../helpers/Storage";
import { checkWalletLinkedToSafleID } from "../../helpers/walletHelpers";

const ModalNFTReceive = (props) => {
  const { appData, actions } = useApp();
  const safleID = Storage.load("user")?.safleID;

  const [qrImage, setQrImage] = useState("");
  const [selectedChain, setSelectedChain] = useState({});
  const [addr, setAddr] = useState();
  const [walletSafleLinkAddr, setWalletSafleLinkAddr] = useState("");
  const [supportedChain, setSupportedChain] = useState([]);
  const [wallets, setWallets] = useState([]);

  useEffect(() => {
    getSupportedNetwork();
  }, []);

  useEffect(() => {
    if (selectedChain && Object.values(selectedChain).length !== 0) {
      getWallets();
    }
  }, [selectedChain]);

  useEffect(() => {
    if (selectedChain && addr) {
      generateQrAddress(selectedChain?.network_name, addr?.wallet_address);
    }
  }, [selectedChain, addr]);

  useEffect(() => {
    if (addr) {
      checkSafleIDLinked();
    }
  }, [addr]);

  const getWallets = () => {
    const wallets =
      // appData.wallets.filter((wallet) => {
      //   return wallet?.chain === selectedChain.network_Id
      // })
      appData?.accounts
        ?.filter((account) => account.type === "evm")
        ?.map((wal) => {
          return {
            wallet_address: wal?.address,
            wallet_name:
              typeof wal?.label === "string" ? wal?.label : wal?.label?.polygon,
          };
        });
    setWallets(wallets);
    setAddr(wallets[0]);
  };

  const checkSafleIDLinked = async () => {
    //await actions.loadSupportedAssets();
    try {
      actions.setLoading(true);
      const res = await checkWalletLinkedToSafleID(safleID);
      if (res) {
        setWalletSafleLinkAddr(res);
      }
    } catch (err) {
    } finally {
      actions.setLoading(false);
    }
  };

  const getSupportedNetwork = async () => {
    //const logo = {
    //  polygon: "images/wallets/nft_polygon.svg",
    //  ethereum: "images/wallets/eth_mainnet.svg",
    //  bsc: "images/wallets/BSC_mainnet.svg",
    //};
    const chains = Object.values(appData.chains).map((chain, index) => {
      return {
        network_name: chain.name,
        network_logo: chain.details.imageURL,
        network_Id: chain.chainId,
      };
    });
    setSupportedChain(chains);
    setSelectedChain(chains[0]);
  };

  const generateQrAddress = async (choosenNetwork, addr) => {
    const durl = await QRCodeImage.toDataURL(`${choosenNetwork}:${addr}`, {
      errorCorrectionLevel: "L",
      mode: "alphanumeric",
      margin: 0,
    });
    setQrImage(durl);
  };
  return (
    <MainModal
      extraClasses={[
        "modal_w440",
        "modal_mh400",
        "modal_dflex",
        "modal_receive",
        "modal_receive_asset",
        "modal_send_asset_dropdown_fix",
      ]}
      dataClass="modal_receive_asset"
      onClose={props.onClose}
      title={"Receive"}
    >
      <div className="modal_receive_content">
        <DropDownNFTNetwork
          extraClass="dropdown_network_assets"
          noBlockScroll={false}
          chains={supportedChain && supportedChain}
          setSelectedChain={setSelectedChain}
          selectedChain={selectedChain}
        />
        <DropdownWalletNew
          extraClass="dropdown_wallet_assets"
          compType={"NFTDropDown"}
          icon="images/wallet-icon.svg"
          noBlockScroll={false}
          wallets={wallets && wallets}
          onSelect={setAddr}
        />
        <div className="modal_receive_row ">
          {addr &&
            walletSafleLinkAddr &&
            walletSafleLinkAddr.toString().toLowerCase() ===
              addr?.wallet_address && (
              <div className="user__box-name">
                <span className="colored">{safleID}</span>
                {/* <span className="icon_copy_field icon-content-copy-black-24-dp" /> */}
                <TooltipCopy
                  extraClasses="tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy tooltip_seed_phrase_copy"
                  textToDisplay={""}
                  imageToDisplay={
                    <img
                      src="images/swap/copy-icon.svg"
                      alt="copy to clipboard"
                    />
                  }
                  tooltipTextIn={"Copy to clipboard"}
                  tooltipTextInChange="Copied to clipboard"
                  copiedText={addr}
                  hideAfter="3000"
                />
              </div>
            )}
        </div>
        <div className="modal_receive_row ">
          {/* <QRCode qrImage="images/qr-code.svg" /> */}
          <QRCode qrImage={qrImage} />
        </div>
        <div className="modal_receive_row ">
          <div className="modal_token_swap_desc modal_token_receive_asset">
            <CopyAddress
              shareAddress="show"
              ellipsis="remove"
              item={addr?.wallet_address}
            />
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalNFTReceive;
