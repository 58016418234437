import { useEffect, useRef, useState } from "react";
import ChangePasswordContent from "../ChangePasswordContent/ChangePasswordContent";
import ChangePINContent from "../ChangePINContent/ChangePINContent";
import ContactSupportContent from "../ContactSupportContent/ContactSupportContent";
import CurrencyContent from "../CurrencyContent/CurrencyContent";
import GuardianSetupContent from "../GuardianSetupContent/GuardianSetupContent";
import ManageVaultContent from "../ManageVaultContent/ManageVaultContent";
import PrivacyContent from "../PrivacyContent/PrivacyContent";
import SettingsIDcontent from "../SettingsIDContent/SettingsIDContent";
import SettingsMenu from "../SettingsMenu/SettingsMenu";

const SettingsOverview = () => {
  const ovContent = useRef();
  const [activePage, setActivePage] = useState("id-content");
  const [contentHeight, setContentHeight] = useState(500);

  useEffect(() => {
    if (ovContent.current) {
      const cs = window.getComputedStyle(ovContent.current, null);
      const h = parseInt(cs.getPropertyValue("height"));

      setContentHeight(h);
    }
  }, []);

  return (
    <div className="settings__overview_content justify-between" ref={ovContent}>
      <SettingsMenu
        onPageChange={(page) => setActivePage(page)}
        currentPage={activePage}
      />
      {activePage === "id-content" && <SettingsIDcontent />}
      {activePage === "change-pass-content" && <ChangePasswordContent />}
      {activePage === "change-pin-content" && <ChangePINContent />}
      {activePage === "manage-vault-content" && <ManageVaultContent />}
      {activePage === "currency-content" && (
        <CurrencyContent contentHeight={contentHeight} />
      )}
      {activePage === "guardian-setup-content" && <GuardianSetupContent />}
      {activePage === "contact-support-content" && <ContactSupportContent />}
      {activePage === "privacy-content" && <PrivacyContent />}
      {/* { activePage } */}
    </div>
  );
};

export default SettingsOverview;
