import React, { useState } from 'react';
import TooltipCopy from '../../registration/TooltipCopy';

const TooltipIconSvg = () => {
    return <img className="icon_copy_field icon-content-copy-black-24-dp" src="images/wallets/copy-icon.svg" alt="" />
}

const PrivateKeyGenerated = ({ privKey = '' }) => {
    const [ privateVisible, setPrivateVisible ] = useState('password');

    const changePrivateMode = () => {
        if( privateVisible ==='password'){
            setPrivateVisible('text');
        } else {
            setPrivateVisible('password');
        }
    }

    return (      
        <div className="modal_wallet_generated-wrp pt_20">
            <div className="wallet_import_used">
            <span className="gas_used_label">Private Key</span>
            </div>
            <div className="form__input_show_hide_pass private_key">
            <input type={ privateVisible } className="form-input-default form__input-switch" readOnly value={privKey} />
            <span className="show_hide" onClick={changePrivateMode}>
                {privateVisible === "password" ? (
                    <span className="icon-eye-slash-solid" />
                ) : (
                    <span className="icon-eye-solid" />
                )}
            </span>            
            <TooltipCopy 
                extraClasses='tooltip_registration cursor-pointer tooltip_webapp_copy private_key_copy' 
                imageToDisplay={<TooltipIconSvg />} 
                tooltipTextIn={'Copy to clipboard'} 
                tooltipTextInChange={'Copied to clipboard'} 
                copiedText={privKey}  
                hideAfter="3000"
            />
            </div>
        </div>     
    );
  };
  
  export default PrivateKeyGenerated;