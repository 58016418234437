import React from "react";
// import jwt from "jsonwebtoken";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../helpers/UserProvider";
import Storage from "../../helpers/Storage";
import flowConfig from "../../helpers/flowRoutes";
import ROUTES from "../../helpers/routes";

const flowStorageKey = "activeFlow";

const useFlowGate = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const activeFlow = Storage.load(flowStorageKey);
  //

  return {
    resetFlowGate: () => {
      Storage.remove(flowStorageKey);
    },
    getActiveFlow: () => {
      const activeFlow = Storage.load(flowStorageKey);
      return activeFlow ? activeFlow.flow : false;
    },
    advance: (extraPath = "") => {
      const activeFlow = Storage.load(flowStorageKey);

      if (!activeFlow) {
        return;
      }
      const currentLocation = activeFlow.currentRoute; //location.pathname.split('').pop() === '/'? location.pathname.substr(0, location.pathname.length-1 ) : location.pathname;

      const currentIdx = flowConfig[activeFlow.flow].indexOf(currentLocation);
      if (currentIdx !== -1) {
        //last page from flow
        if (currentIdx === flowConfig[activeFlow.flow].length - 1) {
          Storage.remove(flowStorageKey);
        } else {
          if (extraPath !== "") {
            Storage.save(flowStorageKey, {
              currentRoute:
                flowConfig[activeFlow.flow][currentIdx + 1].replace(
                  ":any",
                  ""
                ) + extraPath,
              flow: activeFlow.flow,
            });
            navigate(
              flowConfig[activeFlow.flow][currentIdx + 1].replace(":any", "") +
                extraPath
            );
          } else {
            Storage.save(flowStorageKey, {
              currentRoute: flowConfig[activeFlow.flow][currentIdx + 1],
              flow: activeFlow.flow,
            });
            navigate(flowConfig[activeFlow.flow][currentIdx + 1]);
          }
        }
      } else {
        //check for any (path)
      }
    },
    initFlow(name) {
      if (flowConfig.hasOwnProperty(name)) {
        // const currentLocation =
        //   location.pathname.split("").pop() === "/"
        //     ? location.pathname.substr(-1, 1)
        //     : location.pathname;

        Storage.save(flowStorageKey, {
          currentRoute: flowConfig[name][0],
          flow: name,
        });
      } else {
        console.error('No flow defined with name "' + name + '"');
      }
    },
  };
};

function FlowGate({ children, ...rest }) {
  // const user = useUser();
  const location = useLocation();
  let passTrough = true;
  let currentScreen;
  const activeFlow = Storage.load(flowStorageKey);

  if (activeFlow) {
    if (location.pathname !== activeFlow.currentRoute) {
      passTrough = false;
      currentScreen = activeFlow.currentRoute;
    }
  } else {
    passTrough = false;
    currentScreen = rest.default || "/";
  }

  return passTrough ? children : <Navigate to={currentScreen} />;
}

function LoggedInGate({ children, ...rest }) {
  const { userActions } = useUser();
  let loggedIn = userActions.isLoggedIn();

  return loggedIn ? <Navigate to={ROUTES.DASHBOARD} /> : children;
}

export { FlowGate, LoggedInGate, useFlowGate };
