const ModalHeadIcon = ({ span, img, extraClasses }) => {

    return (
        <div className={`${extraClasses && extraClasses} modal_succes_ico`}>
            {
                span && <span className={ span }></span>
            }
            {
                img && <img src={ img } alt="" style={{height:"50px"}}/>
            }
        </div>
    )
}

export default ModalHeadIcon;