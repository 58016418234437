import PrimaryBtn from "../../common/PrimaryBtn/PrimaryBtn";
import SettingBoxIcon from "../SettingBoxIcon/SettingBoxIcon";

const SettingBox = ({
  title,
  description,
  icon,
  buttonLabel,
  hasStatusBadge,
  hasLink,
  enabled,
  extraClass = "",
  showBtn = true,
  onClickAction = () => {},
}) => {
  return (
    <div className={"manage-safle-box" + (extraClass ? " " + extraClass : "")}>
      <SettingBoxIcon
        icon={icon}
        hasStatusBadge={hasStatusBadge}
        enabled={enabled}
      />
      <div className="manage-safle-box-desc">
        <span className="manage-safle-label">{title}</span>
        <span className="manage-safle-description">{description}</span>
      </div>
      {showBtn && (
        <PrimaryBtn
          classes={
            enabled
              ? "btn-default btn-gray-blue modal__trigger"
              : "btn-default btn-gray modal__trigger"
          }
          disabled={!enabled}
          label={buttonLabel}
          onClick={onClickAction}
        />
      )}
      {hasLink && (
        <a
          href="/"
          className="change_log modal__trigger"
          data-target="modal_manage_vault_change_log"
        >
          Change Log
        </a>
      )}
    </div>
  );
};

export default SettingBox;
