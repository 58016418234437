import React, { useEffect }  from 'react';

import ModalActionFailed from './../../dialogs/ModalActionFailed';

import useModalNavigation from '../../../helpers/useModalNavigation';

import { EDIT_LABEL_WALLET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalFailedFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(EDIT_LABEL_WALLET_FLOW);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    
    const onRetry = () => {
        setActiveModalNav('modal_edit_wallet_enter_pin');
    }

    return (
        <ModalActionFailed title="Edit Wallet Label failed" 
        desc="" 
        dataClass="modal_action_failed1" 
        onClose={ onClose } 
        onOk={onClose}
        callbackRetryPopup = {onRetry}
        iconErr={'images/wallets/update-failed-warning.svg'}
        /> 
    )
}

export default ModalFailedFunctions;