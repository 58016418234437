const MobileNavBadge = ({ sidebarActive, setSidebarActive }) => {

    const onClick = (e) => {
        // e.preventDefault();
        setSidebarActive(true);
        document.body.style.overflowY = 'hidden';
    }

    return(
        <div className="main__header_navigation_trigger">
            <label className="mobile_burrger" htmlFor="mobile_burrger_nav">
                <input onClick={(e) => onClick(e)} type="checkbox" id="mobile_burrger_nav" />
                <span />
                <span />
                <span />
            </label>
        </div>
    );
}

export default MobileNavBadge;