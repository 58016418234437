import NFTTag from '../NFTTag/NFTTag';

const NFTags = () => {
    return(
        <div className="nft__item-tag">
            <NFTTag />
        </div>
    );
  }
  
  export default NFTags;
  