import React, { useState, useEffect, useCallback } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import ModalSwapSelectToken from "../../dialogs/ModalSwapSelectToken";
import { decimalCount } from "../../../helpers/generalHelper";

const Vault = require("@getsafle/safle-vault");

const Web3 = require("web3");

const ToTokenSelector = () => {
  const { appData, actions } = useApp();
  const { userData } = useUser();
  const vault = new Vault({});

  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    let selectedToken = [];
    if (
      appData.supportedTokens &&
      appData.supportedTokens.length &&
      appData.activeTokenB
    ) {
      selectedToken = appData.supportedTokens.filter(
        (token) =>
          token.address.toLowerCase() === appData.activeTokenB.toLowerCase()
      );
      setSelectedToken(selectedToken[0]);
    }

    if (appData.swapFromQuantity) {
      actions.setAppData({
        swapToQuantity: (
          appData.swapFromQuantity * appData.exchangeRate
        ).toFixed(selectedToken[0]?.decimals),
      });
    }
  }, [appData.activeTokenB, appData.supportedTokens, appData.exchangeRate]);

  useEffect(() => {
    async function getBalance(contracAddress) {
      const chain = appData?.chains?.find(
        (chain) => chain?.chainId === appData?.activeChain
      );

      if (selectedToken?.symbol === chain?.details?.symbol) {
        // const balance = Object.values(await vault.getBalance(appData.activeAccountAddress, appData.chains[appData.activeChain || 0].uri))[0].balance;
        // setTokenBalance(balance);

        const coinBalance = await actions.getCoinBalance(
          appData?.activeAccountAddress,
          selectedToken?.decimals
        );
        setTokenBalance(coinBalance);
      } else {
        // const balance = await actions.getBalance(contracAddress);
        // setTokenBalance((balance * 1) / (10 ** (decimals || 0)));

        const tokenBal = await actions.getTokenBalance(
          appData?.activeAccountAddress,
          selectedToken?.address
        );
        setTokenBalance(tokenBal);
      }
    }
    if (appData.activeTokenA) {
      getBalance(appData.activeTokenB);
    }
  }, [appData.activeChain, selectedToken, appData.activeAccountAddress]);

  useEffect(() => {
    if (appData.swapFromQuantity !== undefined) {
      setCurrentQuantity(appData.swapToQuantity);
    }
  }, [appData.swapToQuantity]);

  useEffect(() => {
    if (decimalCount(currentQuantity) > selectedToken?.decimals) {
      setError("Swaps cannot proceed for this value, try other value.");
    } else {
      setError(null);
    }
  }, [currentQuantity]);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  const handleChange = useCallback(
    async (e) => {
      setCurrentQuantity(parseFloat(e.target.value) || 0);
      actions.setAppData({ swapToQuantity: e.target.value || 0 });
      actions.setAppData({
        swapFromQuantity:
          Math.round((e.target.value / appData.exchangeRate) * 100) / 100 || 0,
      });
    },
    [actions, appData.exchangeRate]
  );

  return (
    <>
      <div className="from_to_field_wrapper swap-field-to-wrapper">
        <div className="from_to_field">
          <div className="from_to_field-label swap-form-label">To</div>
          <div className="from_to_field-token">
            <div className="from_to_field-token-select">
              <div
                className="asset__coin modal__trigger swap-asset-coin"
                data-target="modal_assets_change"
                onClick={() => openPopup("modal_swap_select_token")}
              >
                <div className="asset__coin-image">
                  {selectedToken && (
                    <div
                      className="asset__coin-image-icon"
                      style={{
                        backgroundImage: 'url("' + selectedToken.logoURI + '")',
                      }}
                    />
                  )}
                </div>
                <div className="asset__coin_prefix swap-coin-prefix">
                  {selectedToken ? selectedToken.symbol : "Select Token"}
                </div>
                <div className="swap__history-details swap-input-arrow">
                  <span className="swap__history-ico icon-chevron-down-solid-1"></span>
                </div>
              </div>
            </div>
            <div className="from_to_field-token-input">
              <input
                type="number"
                className="fake_input"
                value={String(currentQuantity)?.replace(/^0+(?=[0-9])/, "")}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {error ? (
          <div>
            {" "}
            <span className="modal_swap_select_error">{error}</span>
          </div>
        ) : null}
        <div className="swap-balance-amount">
          <div className="from_to_field_balance swap-field-balance">
            <span className="item_ico icon-Navbar_icon_Wallet_deActive1" />
            <span className="from_to_field_label swap-field-to-label">
              Balance:
            </span>
            <span className="from_to_field_amount">
              <span className="coin_val">
                {parseFloat(tokenBalance)
                  ?.toFixed(appData.toTokenData?.[0]?.decimals)
                  ?.replace(/\.?0+$/, "")}{" "}
              </span>
              <span className="coin_prefix">
                {selectedToken && selectedToken.symbol}
              </span>
            </span>
          </div>
        </div>
        {openedPopup === "modal_swap_select_token" && (
          <ModalSwapSelectToken onClose={onClose} isFromToken={false} />
        )}
      </div>
    </>
  );
};

export default ToTokenSelector;
