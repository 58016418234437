import React from "react";

const MobileScreen = () => {
  return (
    <div className="h-[100%] flex flex-col justify-between bg-[#00112E]">
      <div
        className="flex-grow bg-cover bg-center"
        style={{
          backgroundImage: "url('images/landing_page/Background.png')",
        }}
      ></div>

      <div className="text-white relative ">
        <div className="text-center absolute top-[-30%] w-[100%]">
          <img
            src="images/landing_page/Safle_Logo.svg"
            alt="Logo"
            className="mx-auto"
            style={{ maxWidth: "150px" }}
          />
        </div>
        {/* Content Section */}
        <div className="text-center py-8 ">
          <h1 className="text-white text-5xl font-semibold pb-2">
            New-age Identity
          </h1>
          <h1 className="text-white text-5xl font-semibold pb-2">Wallets</h1>
          <h4 className="text-[#8690A0] text-lg">
            Pioneering Simplicity for Web3-Connected World
          </h4>
        </div>

        {/* Download Button */}
        <div className="pt-5 pb-5 flex space-x-12 justify-center">
          <div className="">
            <button
              className="bg-white text-black font-bold rounded-2xl"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/in/app/safle-web3-wallet/id6444435472"
                )
              }
            >
              <img
                src="images/landing_page/App-Store.svg"
                alt="Google-Play"
                className="mr-2"
              />
            </button>
          </div>
          <div className="">
            <button
              className="bg-white text-black font-bold rounded-2xl"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.safle"
                )
              }
            >
              <img
                src="images/landing_page/Google-Play.svg"
                alt="Google-Play"
                className="mr-2"
              />
            </button>
          </div>
        </div>

        {/* Terms and Conditions */}
        <div className="text-center py-8 ">
          <p className="text-[#8690A0]">
            <a href={process.env.REACT_APP_LINK_TERMS} target="_blank">
              Terms and Conditions | Copyright 2023, All rights reserved
            </a>
          </p>
        </div>

        {/* Social Icons */}
        <div className="flex justify-center pb-8">
          <a href={process.env.REACT_APP_LINK_TWITTER} className="mx-2">
            <img
              src="images/landing_page/Socials/Twitter-Line.svg"
              alt="Twitter"
            />
          </a>
          <a href={process.env.REACT_APP_LINK_MEDIUM} className="mx-2">
            <img
              src="images/landing_page/Socials/Medium-Line.svg"
              alt="Medium"
            />
          </a>

          <a href={process.env.REACT_APP_LINK_DISCORD} className="mx-2">
            <img
              src="images/landing_page/Socials/Discord-Line.svg"
              alt="Discord-Line"
            />
          </a>

          <a href={process.env.REACT_APP_LINK_INSTAGRAM} className="mx-2">
            <img
              src="images/landing_page/Socials/Instagram-Line.svg"
              alt="Instagram"
            />
          </a>

          <a href={process.env.REACT_APP_LINK_TELEGRAM} className="mx-2">
            <img
              src="images/landing_page/Socials/Telegram-Line.svg"
              alt="Telegram"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileScreen;
