import React, { useEffect, useState } from "react";

import SwapHistoryItem from "../SwapHistoryItem/SwapHistoryItem";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import Storage from "../../../helpers/Storage";

const SwapHistoryList = () => {
  const [data, setData] = useState([]);
  const { actions, appData } = useApp();
  const { userData, userActions } = useUser();

  useEffect(() => {
    getSwapHistory();
    // getSwapeedTransactions()
  }, [appData.regStatus]);

  useEffect(() => {
    let data = appData.swapTransactions?.slice(0)?.reverse();

    setData(data);
  }, [appData.swapTransactions]);

  const [activeRow, setActiveRow] = useState(null);

  function handleActiveRow(index) {
    setActiveRow(activeRow === index ? null : index);
  }
  const getSwapHistory = async () => {
    //getting all the swap transactions
    try {
      const token = Storage.load("user")?.token;
      actions.setLoading(true, "Loading");
      const swapTxnData = await actions.getSwapHistory(token);
      if (swapTxnData?.statusCode === 200) {
        // setData(swapTxnData?.data?.transactions);

        actions.setAppData({
          swapTransactions: swapTxnData?.data[0]?.transactions,
        });
      }
    } catch (err) {
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <div className="token__swap-right-scroll">
      {data?.length
        ? data.map((item, index) => {
            // datas&&datas.length&&datas.map((item, index) => {
            return (
              <SwapHistoryItem
                item={item}
                key={index}
                index={index}
                out={item?.fromQty}
                outIcon={item?.fromTokenImage}
                outTo={item?.toQty}
                outToIcon={item?.toTokenImage}
                status={item?.status}
                wallet={item?.publicAddress}
                time={item?.timestamp}
                txnId={item?.txHash}
                gasFee={item?.gasFee}
                slippage={item?.slippage}
                partner={item?.dex}
                externalUrl={item?.linkToBlockExplorer}
                handleActiveRow={handleActiveRow}
                activeRow={activeRow}
              />
            );
          })
        : null}
    </div>
  );
};

export default SwapHistoryList;
