import CurrencyItem from "../CurrencyItem/CurrencyItem";

const PopularCurrenciesList = ( { data, activeCurrency, onClick ,popularCurrencyModal = false, hideLabel=false} ) => {
  

  

  return (
    <>
      {!hideLabel && <div className="form__input_popular_label">Popular Currencies</div>}
      <div className="form__input_popular_currencies">
        {
          data.map( ( item, index ) => {
            return (
              <>
              {popularCurrencyModal &&
                <CurrencyItem
                key={ index }
                icon={ item["Country Flag"] }
                currencyName={ item["Currency Name"] }
                currencyPrefix={ item["Currency Code"] }
                currencySymbol={ item["Currency Symbol"] }
                active={ activeCurrency === item["Currency Name"] }
                onClick={() => onClick(index,item)}
              />
              }
              {!popularCurrencyModal &&
                <CurrencyItem
                key={ index }
                icon={ item["Country Flag"] }
                currencyName={ item["Currency Name"] }
                currencyPrefix={ item["Currency Code"] }
                currencySymbol={ item["Currency Symbol"] }
                active={ activeCurrency === item["Currency Name"] }
                onClick={onClick}
              />
              }
                
              </>
            );
          } )
        }
      </div>
    </>
    
  );
};

export default PopularCurrenciesList;