const blockchainInfo = {
  1: {
    name: "Ethereum Mainnet",
    uri: "https://mainnet.infura.io/v3/",
    chain_name: "ethereum",
  },
  137: {
    name: "Polygon Mainnet",
    uri: "https://polygon-mainnet.infura.io/v3/",
    chain_name: "polygon",
  },
  56: {
    name: "Binance Smart Chain",
    uri: "https://bsc-dataseed1.binance.org/",
    chain_name: "bsc",
  },
  10: {
    name: "Optimism Mainnet",
    uri: "https://optimism-mainnet.infura.io/v3/",
    chain_name: "optimism",
  },
  42161: {
    name: "Arbitrum Mainnet",
    uri: "https://arbitrum-mainnet.infura.io/v3/",
    chain_name: "arbitrum",
  },
  106: {
    name: "Velas Mainnet",
    uri: "https://explorer.velas.com/rpc/",
    chain_name: "velas",
  },
  5000: {
    name: "Mantle Mainnet",
    uri: "http://rpc.mantle.xyz/",
    chain_name: "mantle",
  },
};

export default blockchainInfo;
