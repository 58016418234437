import React, { useState, useEffect, useRef } from "react";

const SearchAsset = ({
  assetSearchHandler,
  filter,
  setFilter,
  filterEmpty,
}) => {
  const [isEmpty, seIsEmpty] = useState(true);

  const [searchText, setNewSearchText] = useState("");

  useEffect(() => {
    let timer = setTimeout(async () => {
      assetSearchHandler(searchText);
      setFilter(searchText);
    }, 800);

    return () => clearTimeout(timer);
  }, [searchText]);

  const clearValue = (event) => {
    filterEmpty && filterEmpty(event);
    assetSearchHandler("");
    setFilter("");
    setNewSearchText("");
    seIsEmpty(true);
  };
  const inputValue = (e) => {
    e.preventDefault();
    setNewSearchText(e.target.value);
    if (e.target.value.length === 0) {
      seIsEmpty(true);
    } else {
      seIsEmpty(false);
    }
    setNewSearchText(e.target.value);
  };
  return (
    <div className="form__input form__input-search">
      <input
        onChange={inputValue}
        type="text"
        className="form-input-default"
        placeholder="Search"
        value={searchText}
      />
      <span className="icon-search icon-search-black-24-dp"></span>
      {!isEmpty && <span className="icon-close" onClick={clearValue}></span>}
    </div>
  );
};

export default SearchAsset;
