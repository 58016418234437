/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import ModalAddWallet from "../../dialogs/ModalAddWallet";
import ModalImportWallet from "../../dialogs/ModalImportWallet";
import ModalSetWalletLabel from "../../dialogs/ModalSetWalletLabel";
import ModalImportWalletKey from "../../dialogs/ModalImportWalletKey";
import ModalImportSuccess from "../../dialogs/ModalImportSuccess";

// import ModalWalletFailed from "../../dialogs/ModalWalletFailed";
import ModalActionFailed from "../../dialogs/ModalActionFailed";

import ModalGenerateNewWallet from "../../dialogs/ModalGenerateNewWallet";
import ModalAddWalletPopup from "../../dialogs/ModalAddWalletPopup";
// import ModalChangePIN from '../../dialogs/ModalChangePIN';
import ModalEnterPin from "../../dialogs/ModalEnterPin";
import ModalActionConfirm from "../../dialogs/ModalActionConfirm";
import ModalWalletGenerated from "../../dialogs/ModalWalletGenerated";
import ModalCancelProcess from "../../dialogs/ModalCancelProcess.js";
import ModalGenerateWalletFailed from "../../dialogs/ModalGenerateWalletFailed";

import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import { generateWallet, validatePrvKey } from "../../../helpers/walletHelpers";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import { ADD_WALLET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
const bitcoinjs = require("bitcoinjs-lib");

const AddWalletProcess = () => {
  const { userData, userActions } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      // wallets = [],
    },
    actions,
  } = useApp() || {};
  const [setActiveModalNav, goForward, goBackward] =
    useModalNavigation(ADD_WALLET_FLOW);
  const [modalData, setModalData] = useModalData();

  const [walletType, setWalletType] = useState(false);
  const [walletLabel, setWalletLabel] = useState("");
  const [pinValue, setPinValue] = useState("");
  const [validation, setValidation] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const onCloseWalletGenerated = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackRetryOpenPopup = (popup) => {
    setActiveModalNav("modal_import_wallet_key");
  };
  const callbackCancelGeneratePopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCloseEnterPinImport = (popup) => {
    setActiveModalNav("modal_cancel_import_wallet", true);
  };

  const onChangeWalletLabel = (label) => {
    setWalletLabel(label);
  };

  const onCompletePIN = (pinVal) => {
    setValidation(false);
    setPinValue(Object.values(pinVal).join(""));
  };

  const callbackImportType = (e, option) => {
    setWalletType(option);
    setModalData({ type: option });
    goForward();
  };

  const callbackSetWalletLabel = (e) => {
    setModalData({ label: walletLabel });
    goForward();
  };

  const isValidBitcoinPrivateKey = (privateKey) => {
    try {
      bitcoinjs.ECPair.fromWIF(privateKey, bitcoinjs.networks.bitcoin);
      return true;
    } catch (error) {
      return false;
    }
  };

  const callbackImportWalletKey = async (e) => {
    setModalData({ pkey: privateKey });
    if (modalData?.type !== "BTC") {
      const validatePKey = await validatePrvKey(privateKey);

      if (validatePKey?.error) {
        setValidation(`Invalid key format. Please try again.`);
        return false;
      }
    } else {
      if (!isValidBitcoinPrivateKey(privateKey)) {
        setValidation(`Invalid key format. Please try again.`);
        return false;
      }
    }

    goForward();
  };

  const importFromPrivateKey = async () => {
    const userVault = userData?.user?.vault;
    const privateKey = modalData.pkey;

    if (!userVault) {
      setValidation("Your vault is not available. Please login again.");
    }

    if (privateKey && pinValue && userVault) {
      actions.setLoading(true);

      const { responseVault, newAcc, error } = await userActions.importWallet(
        privateKey,
        Number(pinValue),
        walletType === "BTC" ? "bitcoin" : null,
        modalData.label
      );

      // return;

      if (!error) {
        await userActions.updateVaultData(responseVault);
        setNewAddress(newAcc);

        await setTimeout(async () => {
          const acc = await userActions.getAccounts();
          const addreses = acc ? Object.values(acc) : {};

          await actions.refreshWalletCardData(addreses);
          callbackOpenPopup("modal_import_success");
          actions.setLoading(false);
        }, 300);
      } else {
        setValidation("Something went wrong. Please try again.");
        // if Errors Navigate to Failed modal
        callbackCancelGeneratePopup("modal_failed_import_wallet");
        actions.setLoading(false);
      }
    } else {
      setValidation("Privatekey, Pin or any other input has wrong data.");
      actions.setLoading(false);
    }
  };

  const generateNewWallet = async () => {
    const userVault = userData?.user?.vault;
    const token = userData?.user?.token;

    if (!userVault) {
      setValidation("Your vault is not available. Please login again.");
    }
    // const validPin = await userActions.validatePin( pinValue );
    // if( typeof validPin === 'object'){
    //     setValidation('Please enter valid PIN.');
    //     return;
    // }

    if (pinValue && userVault) {
      actions.setLoading(true);
      const decKey = userData?.user?.decriptionKey
        ? Object.values(userData?.user?.decriptionKey)
        : userActions.getDecriptionKey();
      const { newAcc, prvKey, error, vault } = await generateWallet(
        userVault,
        decKey,
        parseInt(pinValue),
        walletType === "BTC" ? "bitcoin" : null,
        walletLabel,
        token
      );

      //
      if (!error) {
        await userActions.updateVaultData(vault);

        setNewAddress(newAcc);
        setPrivateKey(prvKey);

        setTimeout(async () => {
          const acc = await userActions.getAccounts();
          const addreses = acc ? Object.values(acc) : {};

          onClose();
          await actions.refreshWalletCardData(addreses);
          callbackOpenPopup("modal_generate_wallet_generated");
          actions.setLoading(false);
        }, 1000);
      } else {
        setValidation("Something went wrong, please try again.");
      }
    }
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {
        // modal order:
        // ModalAddWallet -- choose import or generate
        // ModalImportWallet
        // ModalSetWalletLabel
        // ModalImportWalletKey
        // ModalImportSuccess
        // ModalCancelImportWallet
        // ModalWalletFailed
      }
      {activeModalLabel === "modal_add_wallet" && (
        <ModalAddWallet
          onClose={() => onClose()}
          callbackOpenPopup={callbackOpenPopup}
        />
      )}
      {
        // IMPORT WALLET
      }
      {activeModalLabel === "modal_import_wallet" && (
        <ModalImportWallet
          callbackOpenPopup={callbackImportType}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}
      {activeModalLabel === "modal_set_wallet_label" && (
        <ModalSetWalletLabel
          onChangeWalletLabel={onChangeWalletLabel}
          callbackOpenPopup={callbackSetWalletLabel}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}
      {activeModalLabel === "modal_import_wallet_key" && (
        <ModalImportWalletKey
          validation={validation}
          setValidation={setValidation}
          privateKey={privateKey}
          setPrivateKey={setPrivateKey}
          setNewAddress={setNewAddress}
          callbackOpenPopup={callbackImportWalletKey}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}

      {activeModalLabel === "modal_import_wallet_pin" && (
        <ModalEnterPin
          onClose={onCloseEnterPinImport}
          validation={validation}
          onCompletePIN={onCompletePIN}
          callbackOpenPopup={importFromPrivateKey}
          callbackCancelPopup={callbackCancelGeneratePopup}
          modalCancel_label="modal_cancel_import_wallet"
        />
      )}
      {activeModalLabel === "modal_import_success" && (
        <ModalImportSuccess address={newAddress} onClose={onClose} />
      )}
      {activeModalLabel === "modal_cancel_import_wallet" && (
        <>
          {/* <ModalCancelProcess 
                    onClose={onClose} 
                    goBackward={goBackward}
                    body={`Cancel importing wallet?`}
                /> */}
          <ModalActionConfirm
            title="Cancel importing wallet?"
            desc=""
            dataClass="modal_action_confirm1"
            onClose={onClose}
            goBackward={goBackward}
            iconErr={"images/wallets/cancel_confirm.svg"}
          />
        </>
      )}
      {activeModalLabel === "modal_failed_import_wallet" && (
        <>
          {/* <ModalWalletFailed 
                    onClose={onClose} 
                    callbackOpenPopup={callbackOpenPopup}
                    validation={validation}
                    reason="Failed to import wallet"
                    retryModal={'modal_import_wallet_key'}
                /> */}

          <ModalActionFailed
            title="Wallet import failed"
            desc={validation}
            dataClass="modal_action_failed2"
            onClose={onClose}
            callbackRetryPopup={callbackRetryOpenPopup}
            iconErr={"images/wallets/update-failed-warning.svg"}
          />
        </>
      )}

      {
        // GENERATE  WALLET
      }
      {activeModalLabel === "modal_generate_new_wallet" && (
        <ModalGenerateNewWallet
          walletType={walletType}
          walletLabel={walletLabel}
          onChangeWalletLabel={onChangeWalletLabel}
          setWalletType={setWalletType}
          callbackOpenPopup={callbackOpenPopup}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}
      {activeModalLabel === "modal_generate_add_wallet_popup" && (
        <ModalAddWalletPopup
          walletType={walletType}
          callbackOpenPopup={callbackOpenPopup}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}
      {activeModalLabel === "modal_generate_change_pin" && (
        <ModalEnterPin
          validation={validation}
          onCompletePIN={onCompletePIN}
          onClose={() =>
            callbackCancelGeneratePopup("modal_generate_cancel_wallet")
          }
          callbackCancelPopup={() =>
            callbackCancelGeneratePopup("modal_generate_cancel_wallet")
          }
          // submitAction={generateNewWallet}
          labelContinue={"Confirm"}
          callbackOpenPopup={generateNewWallet}
        />
      )}
      {activeModalLabel === "modal_generate_wallet_generated" && newAddress && (
        <ModalWalletGenerated
          newAddress={newAddress}
          privateKey={privateKey}
          onClose={onCloseWalletGenerated}
          callbackOpenPopup={callbackOpenPopup}
          callbackCancelGeneratePopup={callbackCancelGeneratePopup}
        />
      )}
      {activeModalLabel === "modal_generate_cancel_wallet" && (
        <ModalCancelProcess
          onClose={onClose}
          goBackward={goBackward}
          body={"Cancel generating new \n wallet?"}
          icon="images/wallets/cancel_confirm.svg"
        />
      )}
      {activeModalLabel === "modal_generate_failed_wallet" && (
        <ModalGenerateWalletFailed onClose={onClose} />
      )}
    </>
  );
};

export default AddWalletProcess;
