import PrimaryBtn from "../PrimaryBtn/PrimaryBtn";

const WalletGeneratedActions = (props) => {
  return (
    <>
      <div className="modal_footer">
        <div className="modal_succes_content_actions">
          <PrimaryBtn
            classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600"
            label="Done"
            onClick={(e) => {
              // props?.safleWalletLinkAction("link_to_safleid");
              e.preventDefault();
              props?.skipAction();
            }}
          />
          {/* <PrimaryBtn
            classes="mt-10 text-center w-full text-[#0669F8] font-bold"
            label="Skip"
            onClick={(e) => {
              e.preventDefault();
              props?.skipAction();
            }}
          /> */}
          {/* <PrimaryBtn classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600" label="Link with Safle ID" />
                    <a className="modal_skip" href="/#" onClick={( e ) =>{ e.preventDefault(); props?.skipAction()}}> Skip</a> */}
        </div>
      </div>
    </>
  );
};

export default WalletGeneratedActions;
