// import React, { useState ,useEffect , useRef } from 'react';



const DropdownNetworkItem = (items) => {
    const item = items.items;

    return (
      <div
        className={"drop-link " + (items.highlight ? "selected" : "")}
        onClick={()=>items.clickHandle(item)}
      >
        <span
          className="item_ico bullet"
          style={{ backgroundColor: item.network_color }}
        ></span>
        <span className="drp_name network_name">{item.network_name}</span>
      </div>
    );
};

export default DropdownNetworkItem;