import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
//import Icon_Pending from
import "./style.scss";
const SuccessCard = ({ txnHash, timer }) => (
  <div className="bg-white text-center overflow-hidden lg:border lg:border-solid lg:border-gray-400 lg:shadow rounded-3xl mt-2 mb-4 lg:text-left">
    <div className="flex flex-col">
      <div className="w-full  success_card_padding">
        <div className="flex flex-col items-center lg:items-start mb-2">
          {!txnHash ? (
            <div className="pb-2">
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/images/registration/img/Icon_Pending.svg`}
              />
            </div>
          ) : (
            <div className="pb-2">
              {/*//<span className="icon_wt icon-circle-check-solid"></span>*/}
              <img
                alt=""
                src={`${process.env.PUBLIC_URL}/images/registration/img/icon_done.svg`}
              />
            </div>
          )}
          <h5 className="font-semibold text-lg pt-2">
            {txnHash ? "SafleID registered" : "SafleID is being registered"}
          </h5>
        </div>
        <p className="wallet_transaction_metaverse text-start card_succes_text_align_left">
          {txnHash
            ? "Your metaverse identity is now live on the blockchain."
            : "Your metaverse identity is being created on the blockchain"}
        </p>
      </div>

      <div className="w-full  text-gray-500 success_card_bottom_gray">
        <div className="flex justify-center lg:justify-start mb-2">
          <h5 className=" txnhash">Your SafleID will be registered in</h5>
        </div>
        <p className="txnhshkey mt-2 card_succes_text_align_left">
          {timer
            ? `${timer / 1000}seconds`
            : "A confirmation email will be sent shortly."}
        </p>
      </div>
    </div>
  </div>
);

export default SuccessCard;
