/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import { useModalData } from "../../helpers/useModalNavigation";

const ModalIDChanged = ({ onClose }) => {
  const [modalData] = useModalData();

  const [trans, setTrans] = useState(modalData?.transactionHash || "");
  // eslint-disable-next-line no-unused-vars
  const [safleID, setSafleId] = useState(modalData?.newSafleId);

  useEffect(() => {
    setTrans(modalData.transactionHash);
  }, [modalData]);

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w420",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_success_ms",
        "modal_changeid_done",
      ]}
      dataClass="modal_id_changed"
    >
      <div className="modal_succes_content">
        <div className="pt_30">&nbsp;</div>
        <ModalHeadIcon span={"icon-check-circle-solid"} />
        <div className="modal_succes_head pt_20">Transaction successful!</div>
        <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered mb_30">
            You have successfully pushed your SafleID to the blockchain. You can
            now use your new SafleID.
          </span>
          <div className="modal_change_safle_id-wrp">
            <div className="change_safle_id_colored">
              <div className="colored">{safleID}</div>
            </div>
            <div className="change_safle_id_used">
              <span className="change_salfe_id_used_label">Txn hash</span>
            </div>
            <div className="input_text_copy_fake">
              <span className="input_copy" style={{ overflow: "hidden" }}>
                {trans}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_succes_content_actions">
        <PrimaryBtn
          classes={"btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16"}
          label={"Done"}
          onClick={onClose}
        />
        <a
          className="modal_check_eth"
          href={`https://${
            process.env.REACT_APP_ENV_MODE === "development" ||
            process.env.REACT_APP_ENV_MODE === "test"
              ? "mumbai."
              : ""
          }polygonscan.com/tx/${trans}`}
          target="_blank"
        >
          Check on blockchain explorer
          <span className="icon-icon_eth_scan"></span>
        </a>
      </div>
    </MainModal>
  );
};

export default ModalIDChanged;
