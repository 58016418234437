import { useState } from 'react';
import TokenIcon from '../TokenIcon/TokenIcon';
const logoPrefix = "https://raw.githubusercontent.com/MetaMask/contract-metadata/master/images/";

const AddAssetItem = ({ coinLogo, onClick, tokenInfo }) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div 
            className='asset__coin form__input_filter_item modal__trigger flex align-items-center'
            data-target="modal_asset_added"
            data-name='Ethereum'
            onClick={() => { setIsChecked(!isChecked); onClick(tokenInfo); }}
        >
            <div className='flex flex-grow items-center asset_coin_modal_ell_wrp'>
                <TokenIcon logo={ logoPrefix + tokenInfo.logo }  />
                <div className='flex flex-col flex-grow asset_coin_modal_ell'>
                    <div className='asset__coin-name full_width' title={ tokenInfo.name }>{ tokenInfo.name }</div>
                    <span className='asset__coin-symbol'>{ tokenInfo.symbol }</span>
                </div>
            </div>
            { isChecked && 
                <span className="icon-check-solid"></span>
            }
        </div>
    )
}

export default AddAssetItem;