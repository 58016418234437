import React from "react";
import MainModal from "./../generic/MainModal";

const ModalRemoveWalletPopup = (props) => {
  return (
    <MainModal
      onClose={props.onClose}
      extraClasses={[
        "modal_w440 modal_p30",
        "modal_dflex",
        "modal_wallet--1",
        "modal_remove_wallet_popup",
      ]}
      dataClass="modal_remove_wallet_popup"
    >
      {/* Modal content */}
      <div className="modal_head--1">
        <img src="images/wallets/icon-delete.svg" alt="" />
        <div className="modal_title">
          Are you sure you want to archive this wallet?
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={props.callbackCancelPopup}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            onClick={props.callbackOpenPopup}
          >
            Confirm
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalRemoveWalletPopup;
