import React, { useEffect } from "react";

import ModalAssetPurchase from "./../../dialogs/ModalAssetPurchase";

import useModalNavigation from "../../../helpers/useModalNavigation";
import { BUY_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";

const ModalBuyAssetPurchaseFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(BUY_ASSET_FLOW);

  const onClose = () => {
    // setActiveModalNav('CLOSED');
    setActiveModalNav("modal_buy_asset_cancel", true);
    //
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  //

  return (
    <ModalAssetPurchase
      onClose={onClose}
      title={"Buy with Transak"}
      extraClass="modal_transak_new"
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="modal_buy_asset_cancel"
      modalNext_label="modal_buy_crypro_asset"
    />
  );
};

export default ModalBuyAssetPurchaseFunctions;
