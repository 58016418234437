// import React, { useState ,useEffect , useRef } from 'react';

const DropdownFilterItem = (items) => {
  const item = items.items;
  //
  let drpType = "";
  if (items.drpType && items.drpType !== undefined) {
    drpType = items.drpType;
  }
  return (
    <div
      className={"drop-link " + (items.highlight ? "selected" : "")}
      onClick={items.clickHandle}
    >
      <span className="drp_name lang_name">
        {item.sort_name}
        {drpType === "currency" && items.showSymbol && (
          <span> - {item.currency_symbol}</span>
        )}
      </span>
    </div>
  );
};

export default DropdownFilterItem;
