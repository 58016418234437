import React, { useState, useEffect, useCallback } from "react";
import TokenIcon from "../common/TokenIcon/TokenIcon";
import MainModal from "./../generic/MainModal";
import { useApp } from "./../../helpers/AppProvider";

const Web3 = require("web3");
const Swap = require("@getsafle/safle-swaps-v2");

const ModalSwapDexSelect = ({ onClose }) => {
  const { appData, actions } = useApp();
  const [dexs, setDexs] = useState([]);
  const [dexsRates, setDexsRates] = useState([]);
  const [selectedDex, setSelectedDex] = useState(appData.activeDex);

  useEffect(() => {
    setDexsRates([]);
    async function getDexs() {
      const supportedDex = await Swap.getDex();
      setDexs(supportedDex);
    }
    getDexs();
  }, []);

  const getSafleSwaps = () => {
    const activeChain = appData.chains[appData.activeChain || 0];
    return new Swap.Swaps({
      dex: appData.activeDex,
      rpcURL: activeChain.uri,
      chain: activeChain.chain_name,
    });
  };

  const onComplete = useCallback(async () => {
    if (getSafleSwaps()) await getSafleSwaps().setDex(selectedDex);
    actions.setAppData({ activeDex: selectedDex });
    onClose();
  }, [actions, onClose, selectedDex]);

  useEffect(() => {
    if (dexs?.length && appData.activeTokenA && appData.activeTokenB) {
      getExchangeRates();
    }
  }, [dexs]);

  const getExchangeRates = async () => {
    actions.setLoading(true, "Fetching exchange rates");
    const data = await Promise.all(
      dexs?.map(async (dex) => {
        return await getDexExchangeRate(dex);
      })
    );
    setDexsRates(data);
    actions.setLoading(false);
  };

  const getDexExchangeRate = async (dex) => {
    const slippage = appData.swapSlippage;
    const activeChain = appData.activeChain
      ? appData.chains[appData.activeChain || 0]
      : appData.chains[0];
    const fromTokenData = appData.supportedTokens.filter(
      (token) =>
        appData.activeTokenA &&
        token.address &&
        token.address.toLowerCase() === appData.activeTokenA.toLowerCase()
    );
    const toTokenData = appData.supportedTokens.filter(
      (token) =>
        appData.activeTokenB &&
        token.address &&
        token.address.toLowerCase() === appData.activeTokenB.toLowerCase()
    );
    const fromQuantity =
      fromTokenData[0] && 1 * 10 ** fromTokenData[0].decimals;

    let safleSwaps = new Swap.Swaps({
      dex: dex,
      rpcURL: activeChain.uri,
      chain: activeChain.chain_name,
    });

    const toContractAddressChecksumAddress = Web3.utils.toChecksumAddress(
      appData.activeTokenB
    );
    const fromContractAddressChecksumAddress = Web3.utils.toChecksumAddress(
      appData.activeTokenA
    );
    const payload = {
      // toContractAddress: appData.activeTokenB,
      toContractAddress: toContractAddressChecksumAddress,
      toContractDecimal: toTokenData[0] && toTokenData[0].decimals,
      // fromContractAddress: appData.activeTokenA,
      fromContractAddress: fromContractAddressChecksumAddress,
      fromContractDecimal: fromTokenData[0] && fromTokenData[0].decimals,
      fromQuantity: fromQuantity?.toString(),
      slippageTolerance: slippage,
    };
    const rates = await safleSwaps.getExchangeRates(payload);

    const gasEstimation = await safleSwaps.getEstimatedGas(payload);
    rates.exchangeRate = rates?.toTokenAmount / 10 ** toTokenData[0].decimals;
    return rates;
  };

  return (
    <MainModal
      title="Select DEX"
      onClose={() => onClose()}
      extraClasses={["modal_w440 modal_mh410 modal_swap_dex_select"]}
      dataClass="modal_swap_dex_select"
    >
      <div>
        {dexs.map((item, i) => (
          <div
            onClick={() => setSelectedDex(item)}
            className={
              item === selectedDex
                ? "modal_wallet_item input-checked"
                : "modal_wallet_item"
            }
            key={i}
          >
            <div className="asset__coin form__input_filter_item" data-name={""}>
              <TokenIcon logo={appData.dexLogos[item]} />
              <div className="asset__coin_content">
                <div className="asset__coin_names mb_6">
                  <div className="asset__coin-name asset__coin-name--generate">
                    {item}
                  </div>
                </div>
                <div className="swap_dex_select_text mb_6">
                  Exchange Rate:{" "}
                  <span>
                    1.00 {appData?.fromTokenData[0]?.symbol} ={" "}
                    {dexsRates[i]?.exchangeRate}{" "}
                    {appData?.toTokenData[0]?.symbol}{" "}
                  </span>
                </div>
                <div className="swap_dex_select_text">
                  {/* Estimated Gas Fee: <span>{ formatMoney( actions.activeCurrency( dexsRates[i]?.estimatedGas ) ) }{currency}</span> */}
                  Estimated Gas Fee:{" "}
                  <span>
                    {dexsRates[i]?.estimatedGas}
                    {}
                  </span>
                </div>
              </div>
            </div>
            <input
              className="modal_swap_dex_select_checkbox"
              value={item}
              checked={selectedDex === item}
              onChange={() => setSelectedDex(item)}
              type="radio"
              name="radio"
            />
          </div>
        ))}
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            data-target="modal_change_safle_id_txn"
            onClick={onComplete}
          >
            Confirm
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalSwapDexSelect;
