import TooltipCopy from "../../registration/TooltipCopy";
import TokenSwapIcon from "../TokenSwapIcon/TokenSwapIcon";
import { middleEllipsis } from "./../../../helpers/generalHelper";

import { copyToClipboard } from "./../../../helpers/vaultHelpers";

const CopyAddress = (props) => {
  const items_address = props.item;
  const items_ellipsis = props.ellipsis;
  const items_ellipsis_split = props.ellipsis_split || 5;
  //

  const onClickCopy = (e) => {
    e.preventDefault();
    copyToClipboard(items_address);
  };

  return (
    <div className="token_swap_address-wrp">
      {/* <TokenSwapIcon icon={ props?.logo }/> */}
      <div className="token_swap_address" title={items_address}>
        {!items_ellipsis
          ? middleEllipsis(items_address, items_ellipsis_split)
          : items_address}
      </div>
      <div className="token_swap_address to_copy full_address">
        {items_address}
      </div>

      <div
        className="receive_token_address_copy-wrp"
        onClick={(e) => onClickCopy(e)}
      >
        <TooltipCopy
          extraClasses="tooltip_wallet_details tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy justify-center"
          textToDisplay={""}
          imageToDisplay={
            <span className="icon_copy_field icon-icon_copy"></span>
          }
          tooltipTextIn={"Copy to clipboard"}
          tooltipTextInChange={"Copied to clipboard"}
          copiedText={items_address}
          hideAfter="3000"
        />
      </div>

      {props.shareAddress && (
        <div className="receive_token_address_copy-wrp receive_token_address_share-wrp">
          <img src="images/share-icon.svg" alt="Share your public address" />
        </div>
      )}
    </div>
  );
};

export default CopyAddress;
