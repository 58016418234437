import React from "react";
import ExportedWalletList from "../../VaultLists/ExportedWalletList";
import VaultListHeader from "../../VaultLists/VaultListHeader";

const ExportedWallets = ({ tab, data }) => {
  return (
    <div>
      <div className="assets__overview_content_tab content_wallets active">
        <div className="activity__asset-filter-wrapper">
          <div className="filter_table_body wallets_items_ctn vault_active_wallet">
            <table width={"100%"}>
              <VaultListHeader tab={tab} />
              <tbody>
                {/*{data.flat()?.map((item, index) => {*/}
                {data?.map((item, index) => {
                  return (
                    //item && (
                    <ExportedWalletList
                      key={index.toString()}
                      item={item}
                      index={index}
                      // openEnterPinModal={openEnterPinModal}
                      // openEditLabelModal={openEditLabelModal}
                      // openQRModal={openQRModal}
                    />
                    //)
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportedWallets;
