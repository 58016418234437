import React, { useState, useEffect } from "react";

import WalletCardNew from "../WalletCardNew/WalletCardNew";

// import AddWalletProcess from "../../common/AddWalletProcess/AddWalletProcess";
import AddWalletButton from "../../common/AddWalletButton/AddWalletButton";

import useModalNavigation from "../../../helpers/useModalNavigation";
import { useApp } from "../../../helpers/AppProvider";

const WalletArea = ({ userID }) => {
  const { appData, actions } = useApp();
  const [isMobile, setIsMobile] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [setActiveModal] = useModalNavigation("ADD_WALLET_FLOW");
  // const [ safleCard, setSafleCard ] = useState( false );
  const [walletCards, setWalletCards] = useState([]);

  useEffect(() => {
    const resizeFn = () => {
      if (window.innerWidth < 1241) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", resizeFn);
    resizeFn();

    return () => {
      window.removeEventListener("resize", resizeFn);
    };
  }, []);

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appData.accounts && appData.accounts.length) {
      const filteredWallets = appData.accounts;
      // const filteredWallets = appData.wallets.filter((e) => {
      //   if (appData.activeChain && appData.activeChain !==0) {
      //     const curr = appData.chains[appData.activeChain - 1];
      //     return e.chain === curr.chainId;
      //   }
      //   return 1;
      // });

      setWalletCards(filteredWallets);
    }
  }, [appData]);

  // function to scroll right / left listing cards
  let w = 0;
  let parent_nav = React.createRef();
  w = appData.accounts ? appData.accounts.length * 260 : 1;
  let tm = false;
  let isPressed = false;
  const handlePressLeft = (event) => {
    if (w > parent_nav.current.clientWidth) {
      if (event.type === "mousedown") {
        event.preventDefault();
        isPressed = true;
        scr_by(-70);
      } else {
        event.preventDefault();
        isPressed = false;
        clearTimeout(tm);
      }
    }
  };
  const handlePressRight = (event) => {
    if (w > parent_nav.current.clientWidth) {
      if (event.type === "mousedown") {
        event.preventDefault();
        isPressed = true;
        scr_by(70);
      } else {
        event.preventDefault();
        isPressed = false;
        clearTimeout(tm);
      }
    }
  };

  const scr_by = (dir) => {
    if (w > parent_nav.current.clientWidth) {
      if (isPressed) {
        parent_nav.current.scrollBy(dir, 0);
        tm = setTimeout(scr_by, 100, dir);
      }
    }
  };

  const handleCardClick = (event, walletID) => {
    if (event.target.getAttribute("data-button")) {
      return;
    }
    // userActions.troll( appData.wallets.find(e=>e.id===walletID).address );
    actions.setAppData({ activeWallet: walletID });
  };

  return (
    <div className="dashboard__overview-wallets version-two">
      {walletCards.length && (
        <>
          <div className="wallet_selected">
            {appData.accounts && (
              <WalletCardNew
                // item={appData.safleCard}
                isSafleCard={true}
                cardHandler={handleCardClick}
                userID={userID}
              />
            )}
          </div>
          <div className="dashboard__overview-wallets-listing">
            <div
              className="dashboard__overview-wallets-listing-left"
              id="listing_cards_left"
              onMouseDown={handlePressLeft}
              onMouseUp={handlePressLeft}
            >
              <span className="icon-chevron-down-solid-1"></span>
            </div>
            <div
              className="dashboard__overview-wallets-listing-right"
              id="listing_cards_right"
              onMouseDown={handlePressRight}
              onMouseUp={handlePressRight}
            >
              <span className="icon-chevron-down-solid-1"></span>
            </div>
            <AddWalletButton withLabel={false} />
            <div
              className="dashboard__overview-wallets-listing-scrollable "
              id="listing_cards"
              ref={parent_nav}
            >
              {isMobile && (
                <WalletCardNew
                  // item={appData.safleCard}
                  isSafleCard={true}
                  cardHandler={handleCardClick}
                />
              )}
              {
                // appData.wallets.filter( e => {
                //     if( appData.activeChain && appData.activeChain !==0 ){
                //         const curr = appData.chains[ appData.activeChain-1 ];
                //         return e.chain === curr.chainId;
                //     }
                //     return 1;
                // })

                appData.accounts.map((item, index) => (
                  <React.Fragment key={index}>
                    {!item.isSafleCard && (
                      <WalletCardNew
                        item={item}
                        key={index}
                        index={index}
                        cardHandler={handleCardClick}
                      />
                    )}
                  </React.Fragment>
                ))
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default WalletArea;
