/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Preloader from "../../components/common/Preloader/Preloader";

import Button from "../../components/registration/Button/Button";
import Footer from "../../components/registration/Footer/Footer";
import PrivateKeyCard from "../../components/registration/PrivateKeyCard";
import PublicAddressCard from "../../components/registration/PublicAddressCard";
import CssFix from "./../../helpers/cssFix";
import "../../styles/registration/walletDetails.scss";
import { useUser } from "../../helpers/UserProvider";
import { useRegistration } from "../../helpers/RegistrationProvider";
import Storage from "../../helpers/Storage";
import Toast from "../../components/registration/Toast/Toast";
import ModalEnterPin from "../../components/dialogs/ModalEnterPin";

const WalletDetails = () => {
  const { userActions } = useUser();
  let navigate = useNavigate();
  let location = useLocation();
  const {
    encryptionKey,
    userVault,
    newPasswordToken,
    otpStatus,
    pinValidated,
    setPinValidated,
  } = useRegistration();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const [publicAddress, setPublicAddress] = useState();
  const [playAnimation, setPlayAnimation] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [telegramToastMessage, setTelegramToastMessage] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [pin, setPin] = useState(location.state?.pin);
  const [openModal, setOpenModal] = useState(false);
  const params = useParams();

  // This will run one time after the component mounts
  useEffect(() => {
    document.title = "Wallet Details | safle";

    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  const handleGetPrivateKey = async () => {
    const pkey = await userActions.exportPrivateKey(
      publicAddress,
      parseInt(pin),
      newPasswordToken
        ? newPasswordToken
        : Storage.load("registration").newPasswordToken
    );
    return pkey.response.privateKey;
  };

  useEffect(() => {
    async function retrieveVault() {
      await userActions.retrieveVaultAddress(
        Storage.load("registration")?.otpStatus?.safleId,
        Storage.load("registration")?.tempPassword,
        Storage.load("registration")?.newPasswordToken
      );
      if (!pin) {
        setOpenModal(true);
      }
      if (!params.transactionID && pinValidated === null) {
        getAcc();
      }
    }
    retrieveVault();
  }, []);

  const getAcc = async (pin_val) => {
    try {
      // setting the below variables based on login status
      const enc = Storage.load("user").decriptionKey
        ? Object.values(Storage.load("user").decriptionKey)
        : Storage.load("registration").encryptionKey
        ? Object.values(Storage.load("registration").encryptionKey)
        : encryptionKey;
      const pin_no = pin ? parseInt(pin) : parseInt(pin_val);
      const user_vault = userVault
        ? userVault
        : Storage.load("registration")?.userVault
        ? Storage.load("registration")?.userVault
        : Storage.load("user")?.vault;

      const address = await userActions.getAccountReg(
        user_vault,
        parseInt(pin_no),
        enc
      );
      setBtnDisabled(false);
      setPublicAddress(address);
    } catch (e) {}
  };

  const pushToBlockchain = async () => {
    navigate("/wallet-transaction", { state: { pin: pin } });
  };

  if (error) alert(error);

  const handleToastCustomBody = () => {
    return !telegramToastMessage ? (
      <div className="px-7 py-4 rounded-md flex items-center text-white bg-[#EA3943] font-medium text-base min-w-[100%]">
        There was an error in saving the Vault. Try again.
        <span className="pl-3 color-[#EA3943]">
          <img
            onClick={() => {
              setToastIsOpen(false);
            }}
            className="cursor-pointer"
            alt=""
            width={17}
            height={17}
            src={`${process.env.PUBLIC_URL}/images/registration/img/toast-retry.svg`}
          />
        </span>
      </div>
    ) : (
      <div className="px-6 py-3 rounded-md flex items-center text-white bg-[#EA3943] min-w-[100%] font-medium text-base ">
        <div>
          Your Vault could not be saved at this time. <br></br>Please contact
          support on Telegram.
        </div>
        <div className="pl-5">
          {" "}
          <a
            className="cursor-pointer"
            href={process.env.REACT_APP_LINK_TELEGRAM}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt=""
              width={27}
              src={`${process.env.PUBLIC_URL}/images/registration/img/toast-telegram.svg`}
            />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CssFix />
      <div
        className={
          "bg_linear_inner z-0 opacity_0 " +
          (playAnimation ? " fadein_wallet_details " : " ")
        }
      >
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          <div className="md:h-auto bg-white shadow rounded-2xl relative ">
            <Preloader />
            <div className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 lg:pr-0">
              <div className="flex justify-between">
                <div className="w-full lg:w-5/12 flex flex-col">
                  <div className="mb-8">
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                  </div>
                  <div>
                    <h1 className="lg:text-justify font-medium wdetails_h mb-1">
                      Wallet details
                    </h1>
                  </div>

                  <PublicAddressCard
                    publicAddress={publicAddress}
                    safleID={otpStatus.safleId}
                  />
                  <PrivateKeyCard handleGetPrivateKey={handleGetPrivateKey} />
                  <div className="mt-4 lg:mb-28">
                    <Button
                      style={{ opacity: btnDisabled ? 0.5 : 1 }}
                      fullwidth
                      text="Push SafleID to Blockchain"
                      onClick={() => pushToBlockchain()}
                      disabled={btnDisabled}
                    />
                  </div>
                </div>

                <div className="hidden w-6/12 lg:block">
                  <img
                    className="absolute top-0 right-0 h-full rounded-r-2xl"
                    alt=""
                    src={`${process.env.PUBLIC_URL}/images/registration/img/background-full@2x.png`}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {openModal && (
          <ModalEnterPin
            callbackOpenPopup={(val) => {
              setPin(val);
              setPinValidated(true);
              setOpenModal(false);
              getAcc(val);
            }}
            callbackCancelPopup={() => setOpenModal(false)}
          />
        )}
      </div>
      <Toast
        customBodyContent={handleToastCustomBody()}
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        customBody={true}
      />
    </div>
  );
};

export default WalletDetails;
