import React from "react";
// import {
//   CheckCircleIcon,
//   XCircleIcon,
//   ExclamationCircleIcon,
//   EyeIcon,
//   EyeOffIcon
// } from '@heroicons/react/solid'
const PasswordInput = ({
  label,
  name,
  placeholder,
  icon,
  hiddenEye,
  visibleEye,
  valid,
  value,
  setValue,
  check = false,
  errorMsg = false,
  maxLength = "",
  minLength = "",
  showIcon = false,
  ...rest
}) => {
  return (
    <div className="form__input form__input_default form__input-switch-pass">
      <span className="input_label">{label}</span>
      <div className="form__input_show_hide_pass">
        {hiddenEye !== undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer z_index ">
            {" "}
            {hiddenEye}
          </div>
        )}
        {visibleEye !== undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer z_index ">
            {" "}
            {visibleEye}
          </div>
        )}
        {icon && showIcon && (
          <span className="absolute bottom-6 left-5 z-10">{icon}</span>
        )}

        <input
          style={{ paddingRight: "26px", paddingLeft: icon && showIcon && 40 }}
          type="text"
          name={name}
          id={name}
          minLength={minLength}
          maxLength={maxLength}
          className={
            (valid === false && check === false ? "border-error " : "") +
            (icon !== undefined ? "pl-10 " : "pl-2 ") +
            " form-input-default form__input-switch"
          }
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          {...rest}
        />
      </div>
      {errorMsg && <div className="error_message">{errorMsg}</div>}
    </div>
  );
};

export default PasswordInput;
