export const configModalFlows = {
  TEST_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_add_wallet",
    },
    modal_test_cancel: {
      label: "modal_test_cancel",
    },
    test_modal_add_wallet: {
      label: "test_modal_add_wallet",
      next: "test_modal_import_wallet",
    },
    test_modal_import_wallet: {
      label: "test_modal_import_wallet",
      next: "test_modal_set_wallet_label",
    },
    test_modal_set_wallet_label: {
      label: "test_modal_set_wallet_label",
      next: "test_modal_import_wallet_key",
    },
    test_modal_import_wallet_key: {
      label: "test_modal_import_wallet_key",
      next: "test_modal_import_wallet_pin",
    },
    test_modal_import_wallet_pin: {
      label: "test_modal_import_wallet_pin",
      next: "test_modal_import_success",
    },
    test_modal_import_success: {
      label: "test_modal_import_success",
      next: "test_modal_cancel_import_wallet",
    },
    test_modal_cancel_import_wallet: {
      label: "test_modal_cancel_import_wallet",
    },
  },
  ADD_WALLET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_add_wallet",
    },
    modal_add_wallet: {
      label: "modal_add_wallet",
      next: "modal_import_wallet",
    },
    modal_generate_new_wallet: {
      label: "modal_generate_new_wallet",
      next: "modal_set_wallet_label",
    },
    modal_generate_add_wallet_popup: {
      label: "modal_generate_add_wallet_popup",
    },
    modal_generate_change_pin: {
      label: "modal_generate_change_pin",
    },
    modal_generate_wallet_generated: {
      label: "modal_generate_wallet_generated",
    },
    modal_generate_failed_wallet: {
      label: "modal_generate_failed_wallet",
    },
    modal_import_wallet: {
      label: "modal_import_wallet",
      next: "modal_set_wallet_label",
    },
    modal_set_wallet_label: {
      label: "modal_set_wallet_label",
      next: "modal_import_wallet_key",
    },
    modal_import_wallet_key: {
      label: "modal_import_wallet_key",
      next: "modal_import_wallet_pin",
    },
    modal_import_wallet_pin: {
      label: "modal_import_wallet_pin",
      next: "modal_import_success",
    },
    modal_import_success: {
      label: "modal_import_success",
    },
    modal_cancel_import_wallet: {
      label: "modal_cancel_import_wallet",
    },
    modal_generate_cancel_wallet: {
      label: "modal_generate_cancel_wallet",
    },
    modal_failed_import_wallet: {
      label: "modal_failed_import_wallet",
    },
  },
  REMOVE_WALLET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_remove_wallet_popup",
    },
    modal_remove_wallet_popup: {
      label: "modal_remove_wallet_popup",
      next: "modal_remove_enter_pin",
    },
    modal_remove_enter_pin: {
      label: "modal_remove_enter_pin",
    },
    modal_remove_cancel_wallet: {
      label: "modal_remove_cancel_wallet",
    },
    modal_remove_failed_wallet: {
      label: "modal_remove_failed_wallet",
    },
    modal_remove_success: {
      label: "modal_remove_success",
    },
  },
  SETTINGS_CHANGE_EMAIL: {
    CLOSED: {
      label: "CLOSED",
      // next: "modal_email_enter_pin_default"
      next: "modal_email_enter_pin",
    },
    // modal_email_enter_pin_default: {
    //   label: "modal_email_enter_pin_default",
    //   next: "modal_email_enter_pin"
    // },
    modal_email_enter_pin: {
      label: "modal_email_enter_pin",
      next: "modal_email_enter_secondary_pin",
    },
    modal_email_enter_secondary_pin: {
      label: "modal_email_enter_secondary_pin",
      next: "modal_email_updated",
    },
    modal_email_updated: {
      label: "modal_email_updated",
    },
    modal_cancel_email_address: {
      label: "modal_cancel_email_address",
    },
    modal_email_address_failed: {
      label: "modal_email_address_failed",
    },
  },
  SETTINGS_CHANGE_NAME: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_name_enter_pin",
    },
    modal_name_enter_pin: {
      label: "modal_name_enter_pin",
      next: "modal_name_is_updated",
    },
    modal_name_is_updated: {
      label: "modal_name_is_updated",
    },
    modal_cancel_edit_name: {
      label: "modal_cancel_edit_name",
    },
    modal_edit_name_failed: {
      label: "modal_edit_name_failed",
    },
  },
  SETTINGS_CHANGE_PROFILE_IMAGE: {
    CLOSED: {
      label: "CLOSED",
      next: "settings_change_profile_upload",
    },
    settings_change_profile_upload: {
      label: "settings_change_profile_upload",
    },
    settings_change_profile_cancel: {
      label: "settings_change_profile_cancel",
    },
  },
  SETTINGS_EXPORT_SEED_PHRASE: {
    CLOSED: {
      label: "CLOSED",
      next: "settings_export_seedphrase_otp",
    },
    settings_export_seedphrase_otp: {
      label: "settings_export_seedphrase_otp",
      next: "settings_export_seedphrase",
    },
    settings_export_seedphrase_pin: {
      label: "settings_export_seedphrase_pin",
      next: "settings_export_seedphrase",
    },
    settings_export_seedphrase: {
      label: "settings_export_seedphrase",
    },
    settings_export_seedphrase_cancel: {
      label: "settings_export_seedphrase_cancel",
    },
  },
  EXPORT_WALLET_PRIVATE_KEY_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "export_prvkey_enter_pin",
    },
    export_prvkey_enter_pin: {
      label: "export_prvkey_enter_pin",
      next: "modal_export_private_key",
    },
    export_prvkey: {
      label: "export_prvkey",
    },
    export_prvkey_cancel: {
      label: "export_prvkey_cancel",
    },
  },
  LINK_WITH_SAFLEID: {
    CLOSED: {
      label: "CLOSED",
      next: "link_to_safleid",
    },
    link_to_safleid: {
      label: "link_to_safleid",
      next: "link_wallet_pin",
    },
    update_link_to_safleid: {
      label: "update_link_to_safleid",
      next: "link_wallet_pin",
    },
    link_wallet_pin: {
      label: "link_wallet_pin",
    },
    link_wallet_sucess: {
      label: "link_wallet_sucess",
    },
    link_wallet_failed: {
      label: "link_wallet_failed",
    },
  },
  CHANGE_SAFLE_ID: {
    CLOSED: {
      label: "CLOSED",
    },
    change_safleid: {
      label: "change_safleid",
      next: "confirm_change_safleid",
    },
    confirm_change_safleid: {
      label: "confirm_change_safleid",
      next: "change_safleid_pin",
    },
    change_safleid_pin: {
      label: "change_safleid_pin",
      next: "change_safleid_success",
    },
    cancel_safleid_process: {
      label: "cancel_safleid_process",
    },
    change_safleid_success: {
      label: "change_safleid_success",
    },
    change_safleid_failed: {
      label: "change_safleid_failed",
    },
    cancel_change_safleid: {
      label: "cancel_change_safleid",
    },
  },
  EDIT_LABEL_WALLET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_edit_wallet_label",
    },
    modal_edit_wallet_label: {
      label: "modal_edit_wallet_label",
      next: "modal_edit_wallet_enter_pin",
    },
    modal_edit_wallet_enter_pin: {
      label: "modal_edit_wallet_enter_pin",
    },
    modal_edit_label_cancel_wallet: {
      label: "modal_edit_label_cancel_wallet",
    },
    modal_edit_label_failed_wallet: {
      label: "modal_edit_label_failed_wallet",
    },
    modal_edit_label_success_wallet: {
      label: "modal_edit_label_success_wallet",
    },
  },
  CHANGE_PIN_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_email_enter_pin",
    },
    modal_change_pin_enter_pin: {
      label: "modal_change_pin_enter_pin",
      next: "modal_change_pin_updated",
    },
    modal_change_pin_updated: {
      label: "modal_change_pin_updated",
    },
    modal_change_pin_cancel: {
      label: "modal_change_pin_cancel",
    },
    modal_change_pin_failed: {
      label: "modal_change_pin_failed",
    },
  },
  CHANGE_PASSWORD_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_change_password_enter_pin",
    },
    modal_change_password_enter_pin: {
      label: "modal_change_password_enter_pin",
      next: "modal_change_password_updated",
    },
    modal_change_password_updated: {
      label: "modal_change_password_updated",
    },
    modal_change_password_cancel: {
      label: "modal_change_password_cancel",
    },
    modal_change_password_failed: {
      label: "modal_change_password_failed",
    },
  },

  QR_CODE_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_qr_code",
    },
    modal_qr_code: {
      label: "modal_qr_code",
    },
  },
  BUY_TRANSAK_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_buy_transak",
    },
    modal_buy_transak: {
      label: "modal_buy_transak",
    },
  },
  FORGOT_PIN_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "enter_verification_code",
    },
    enter_verification_code: {
      label: "enter_verification_code",
      next: "enter_seed_phrase",
    },
    enter_seed_phrase: {
      label: "enter_seed_phrase",
      next: "set_new_pin",
    },
    set_new_pin: {
      label: "set_new_pin",
      next: "pin_changed_success",
    },
    pin_changed_success: {
      label: "pin_changed_success",
    },
    pin_changed_failed: {
      label: "pin_changed_failed",
    },
    forgot_pin_cancel: {
      label: "forgot_pin_cancel",
    },
  },

  BUY_ASSET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_buy_asset",
    },
    modal_buy_asset: {
      label: "modal_buy_asset",
      next: "modal_buy_crypro_asset",
    },
    modal_buy_crypro_asset: {
      label: "modal_buy_crypro_asset",
    },
    modal_buy_asset_failed: {
      label: "modal_buy_asset_failed",
    },
    modal_buy_asset_cancel: {
      label: "modal_buy_asset_cancel",
    },
  },
  RECEIVED_ASSET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_receive_asset",
    },
    modal_receive_asset: {
      label: "modal_receive_asset",
    },
    modal_receive_asset_failed: {
      label: "modal_receive_asset_failed",
    },
    modal_receive_asset_cancel: {
      label: "modal_receive_asset_cancel",
    },
  },
  SEND_ASSET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_send_asset",
    },
    modal_send_asset: {
      label: "modal_send_asset",
      next: "modal_send_asset_enter_pin",
    },
    modal_send_asset_enter_pin: {
      label: "modal_send_asset_enter_pin",
      next: "modal_send_asset_transaction_done",
    },
    modal_send_asset_transaction_done: {
      label: "modal_send_asset_transaction_done",
    },
    modal_send_asset_failed: {
      label: "modal_send_asset_failed",
    },
    modal_send_asset_cancel: {
      label: "modal_send_asset_cancel",
    },
  },
  ADD_CUSTOM_ASSET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_add_custom_token_asset",
    },
    modal_add_custom_token_asset: {
      label: "modal_add_custom_token_asset",
    },
    modal_add_custom_asset_failed: {
      label: "modal_add_custom_asset_failed",
    },
    modal_add_custom_asset_cancel: {
      label: "modal_add_custom_asset_cancel",
    },
  },
  RECEIVE_NFT_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_receive_nft",
    },
    modal_receive_nft: {
      label: "modal_receive_nft",
    },
  },
  SEND_NFT_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_send_nft",
    },
    modal_send_nft: {
      label: "modal_send_nft",
      next: "modal_send_nft_enter_pin",
    },
    modal_send_nft_enter_pin: {
      label: "modal_send_nft_enter_pin",
      next: "modal_send_nft_success",
    },
    modal_send_nft_success: {
      label: "modal_send_nft_success",
    },
  },
  SWAP_FLOW: {
    // CLOSED: {
    //   label: "CLOSED",
    //   next: "modal_swap_confirmation"
    // }, 
    // modal_swap_confirmation: {
    //   label: "modal_swap_confirmation",
    //   next: "modal_enter_pin"
    // }, 
    CLOSED: {
      label: "CLOSED",
      next: "modal_transaction_approval"
    }, 
    modal_transaction_approval: {
      label: "modal_transaction_approval",
      next: "modal_enter_pin_approval"
    }, 
    modal_enter_pin_approval: {
      label: "modal_enter_pin_approval",
      next: "modal_swap_confirmation"
    },
    modal_swap_confirmation: {
      label: "modal_swap_confirmation",
      next: "modal_enter_pin",
    },
    modal_enter_pin: {
      label: "modal_enter_pin",
      next: "modal_txn_done",
    },
    modal_txn_done: {
      label: "modal_txn_done",
    },
    modal_cancel_swap: {
      label: "modal_cancel_swap",
    },
  },
  RESTORE_WALLET_FLOW: {
    CLOSED: {
      label: "CLOSED",
      next: "modal_restore_wallet_popup",
    },
    modal_restore_wallet_popup: {
      label: "modal_restore_wallet_popup",
      next: "modal_restore_enter_pin",
    },
    modal_restore_enter_pin: {
      label: "modal_restore_enter_pin",
    },
    modal_restore_cancel_wallet: {
      label: "modal_restore_cancel_wallet",
    },
    modal_restore_failed_wallet: {
      label: "modal_restore_failed_wallet",
    },
    modal_restore_success: {
      label: "modal_restore_success",
    },
  },
};
