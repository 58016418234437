/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import DropdownNetworkItem from "../DropdownNetworkItem/DropdownNetworkItem";
import { useApp } from "./../../../helpers/AppProvider";

const DropdownNetwork = (props) => {
  const { appData, actions } = useApp();

  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(8);
  // eslint-disable-next-line no-unused-vars
  const [dropdown, setDropdown] = useState({
    network_name: "",
    network_color: "",
    chainId: 0,
  });
  const [chains, setChains] = useState([]);
  // const [currChain, setCurrChain] = useState(0);

  useEffect(() => {
    if (appData.chains && appData.chains.length) {
      const foundChains = appData.chains;
      setChains([
        {
          network_name: "All chains",
          network_color: "#29b6af",
          idx: 8,
          chainId: 0,
        },
        ...foundChains.map((e, ind) => {
          return {
            network_name: e.name,
            network_color: "#29b6af",
            idx: ind,
            chainId: e?.chainId,
          };
        }),
      ]);
    }
  }, [appData]);

  //populate an object with items
  const dummyAddress = [
    {
      // key : 1,
      network_name: "Ethereum Mainnet",
      network_color: "#29b6af",
    },
    {
      // key : 2,
      network_name: "Ethereum Testnet",
      network_color: "#ff4a8d",
    },
    {
      // key : 3,
      network_name: "Binaince Smart Chain Mainnet",
      network_color: "#d6d9dc",
    },
    {
      // key : 4,
      network_name: "ThaiChain",
      network_color: "#f6c343",
    },
    {
      // key : 5,
      network_name: "ThaiChain2",
      network_color: "#f6c343",
    },
    {
      // key : 6,
      network_name: "ThaiChain3",
      network_color: "#f6c343",
    },
    {
      // key : 7,
      network_name: "ThaiChain4",
      network_color: "#f6c343",
    },
  ];
  // first time load the first address in the object
  // or select the selected used address

  useEffect(() => {
    if (appData.chains && appData.chains.length) {
      //
      setDropdown(
        chains?.filter((chain) => chain?.chainId === appData.activeChain)[0]
      );
      setHighlightedItem(
        chains.find((i) => i.chainId === appData?.activeChain)?.idx
      );
    }
  }, [chains]);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes = "main__controls controls__dropdown ";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        actions.setAppData({ blockScroll: false });
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: true });
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: false });
      // document.body.classList.remove('no_scroll_dropdown');
    };
  }, [isActive]);

  let tm = false;

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    //
    // add class with selected
    // set the state for the dropdown with last value
    // setDropdown(addr);
    setDropdown(chains.find((i) => i.chainId === addr.chainId));
    setHighlightedItem(addr.idx);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    //
    if (tm) {
      clearTimeout(tm);
    }
    setTimeout(() => {
      actions.setAppData({
        blockScroll: false,
        activeChain: addr?.chainId,
      });
    }, 0);
    // document.body.classList.remove('no_scroll_dropdown');
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(dummyAddress.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, dummyAddress[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div className="dropdown__active__item" onClick={onClick}>
        <span
          className="item_ico bullet"
          style={{
            backgroundColor: chains.find((i) => i.idx === highlighted)
              ?.network_color,
            width: "12px",
            height: "10px",
          }}
        ></span>
        <span className="drp_name network_name">
          {chains.find((i) => i.idx === highlighted)?.network_name}
        </span>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
      </div>
      <div className="dropdown__list__items">
        {/* {} */}
        {chains.map((chain, index) => (
          <DropdownNetworkItem
            items={chain}
            highlight={chain.idx === highlighted}
            key={chain.idx.toString()}
            clickHandle={(p) => onClickItem(index, p)}
          />
        ))}
      </div>
    </div>
  );
};

export default DropdownNetwork;
