import React, { useRef, useEffect, useState } from "react";
import { useApp } from "./../../../helpers/AppProvider";

function transformArray(originalArray) {
  const newArray = [];
  let totalWorth = 0;
  const arrayColor = [
    "#A693FD",
    "#F7931A",
    "#2ca02c",
    "#bcbd22",
    "#c49c94",
    "#9edae5",
  ];

  const arryTooltip =[];
  if (originalArray.length > 0) {
    originalArray.forEach((item) => {
      if (item?.value) {
        totalWorth += item?.value;
      }
    });
  }

  // If array has 1 or 2 objects, directly copy them to the new array
  if (originalArray.length <= 2) {
    return originalArray
      .filter((item) => item?.value)
      .map((item, index) => ({
        name: item.name,
        value: item.value,
        description: `${(item.value / totalWorth) * 100}%`,
        graphRoundValue: `${Math.round((item.value / totalWorth) * 100)}%`,
        color: arrayColor[index] ? arrayColor[index] : null,
      }));
  }

  const itemsOver7Percent = [];
  let combinedValue = 0;

  // Iterate over the original array
  originalArray.forEach((item, index) => {
    // Check if the asset has more than 7% value
    if ((item.value / totalWorth) * 100 > 10) {
      itemsOver7Percent.push({
        name: item.name,
        value: item.value,
        description: `${((item.value / totalWorth) * 100).toFixed(2)}%`,
        graphRoundValue: `${Math.round((item.value / totalWorth) * 100)}%`,
        color: arrayColor[index] ? arrayColor[index] : null,
      });
    } else {
      combinedValue += item?.value;
    }
  });
  if (combinedValue) {
    itemsOver7Percent.push({
      name: "Others",
      value: combinedValue,
      description: `${((combinedValue / totalWorth) * 100).toFixed(2)}%`,
      graphRoundValue: `${Math.round((combinedValue / totalWorth) * 100)}%`,
      color: "#CBD7E9",
    });
  }

  // Check if there are items over 7% value
  if (itemsOver7Percent.length > 0) {
    return itemsOver7Percent;
  }
  return newArray;
}

const TokenStackedGraph = () => {
  const { appData, actions } = useApp();
  const [data, setData] = useState([]);
 const [otherData, setOtherData] = useState([]); 

  useEffect(() => {
    if (!appData?.priceData || !appData?.portfolioData) {
      return;
    }
    let assets;
    if (appData.activeWallet) {
      const AssetsInWallet = appData?.portfolioData[
        appData?.activeWallet
      ]?.activeChains
        ?.filter((chain) => chain !== "avalanche")
        ?.map((chain) => {
          let AssetsInChainInWallet;
          const accountType = appData?.accounts?.find(
            (account) => account?.address === appData?.activeWallet
          )?.type;
          if (accountType === "evm" && chain !== "bitcoin") {
            AssetsInChainInWallet = [
              ...appData?.portfolioData[appData?.activeWallet][chain]?.tokens,
            ];
          }
          AssetsInChainInWallet =
            AssetsInChainInWallet?.length > 0
              ? AssetsInChainInWallet.map((obj) => {
                  return {
                    ...obj,
                    chain: chain,
                    value: parseFloat(
                      appData?.priceData?.filter(
                        (item) => item?.symbol === obj?.symbol
                      )[0]?.price *
                        (parseFloat(obj?.balance) / Math.pow(10, obj?.decimals))
                    ),
                    // ?.toFixed(2),
                    // ?.replace(/\.?0+$/, ""),
                  };
                })
              : [];
          AssetsInChainInWallet.push({
            token_address: null,
            symbol:
              appData?.portfolioData[appData?.activeWallet][chain]?.symbol,
            name: appData?.portfolioData[appData?.activeWallet][chain]
              ?.nativeCurrency,
            logo: appData?.portfolioData[appData?.activeWallet][chain]
              ?.imageURL,
            thumbnail: null,
            decimals: null,
            balance:
              appData?.portfolioData[appData?.activeWallet][chain]?.balance,
            possible_spam: false,
            chain: chain,
            value: parseFloat(
              appData?.priceData?.filter(
                (item) =>
                  item?.symbol ===
                  appData?.portfolioData[appData?.activeWallet][chain]?.symbol
              )[0]?.price *
                parseFloat(
                  appData?.portfolioData[appData?.activeWallet][chain]?.balance
                )
            ),
            // ?.toFixed(2),
            // ?.replace(/\.?0+$/, ""),
          });
          return AssetsInChainInWallet;
        });
      assets = AssetsInWallet?.reduce((acc, obj) => {
        return acc?.concat(obj);
      }, []);
      assets = assets?.filter((item) => {
        if (!isNaN(item?.value)) {
          return {
            ...item,
            description: parseFloat(item?.value)?.toFixed(2),
          };
        }
      });
    } else {
      const AssetsInWallets = appData?.accounts
        // ?.filter((account) => account.type === "evm")
        ?.map((account) => {
          const AssetsInWallet = appData?.portfolioData[
            account?.address
          ]?.activeChains
            ?.filter((chain) => chain !== "avalanche")
            ?.map((chain) => {
              let AssetsInChainInWallet;
              if (account.type === "evm" && chain !== "bitcoin") {
                AssetsInChainInWallet = [
                  ...appData?.portfolioData[account?.address][chain]?.tokens,
                ];
              }
              AssetsInChainInWallet =
                AssetsInChainInWallet?.length > 0
                  ? AssetsInChainInWallet.map((obj) => {
                      return {
                        ...obj,
                        chain: chain,
                        value: parseFloat(
                          appData?.priceData?.filter(
                            (item) => item?.symbol === obj?.symbol
                          )[0]?.price *
                            (parseFloat(obj?.balance) /
                              Math.pow(10, obj?.decimals))
                        ),
                        // ?.toFixed(2),
                        // ?.replace(/\.?0+$/, ""),
                      };
                    })
                  : [];
              AssetsInChainInWallet.push({
                token_address: null,
                symbol: appData?.portfolioData[account?.address][chain]?.symbol,
                name: appData?.portfolioData[account?.address][chain]
                  ?.nativeCurrency,
                logo: appData?.portfolioData[account?.address][chain]?.imageURL,
                thumbnail: null,
                decimals: null,
                balance:
                  appData?.portfolioData[account?.address][chain]?.balance,
                possible_spam: false,
                chain: chain,
                value: parseFloat(
                  appData?.priceData?.filter(
                    (item) =>
                      item?.symbol ===
                      appData?.portfolioData[account?.address][chain]?.symbol
                  )[0]?.price *
                    parseFloat(
                      appData?.portfolioData[account?.address][chain]?.balance
                    )
                ),
                // ?.toFixed(2),
                // ?.replace(/\.?0+$/, ""),
              });
              return AssetsInChainInWallet;
            });
          return AssetsInWallet?.reduce((acc, obj) => {
            return acc?.concat(obj);
          }, []);
        });

      let unfilteredAssets =
        AssetsInWallets?.length > 0
          ? AssetsInWallets?.reduce((acc, obj) => {
              return acc?.concat(obj);
            })
          : [];

      unfilteredAssets =
        unfilteredAssets?.length > 0
          ? Object.values(
              unfilteredAssets.reduce((acc, token) => {
                const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
                if (!acc[key]) acc[key] = { ...token };
                else {
                  acc[key].balance = (
                    parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                  ).toString();
                  acc[key].value =
                    parseFloat(acc[key]?.value) + parseFloat(token?.value);
                }
                return acc;
              }, {})
            ).map((obj) => ({ ...obj }))
          : [];

      assets = unfilteredAssets?.filter((item) => {
        if (!isNaN(item?.value)) {
          return {
            ...item,
            description: parseFloat(item?.value)?.toFixed(2),
          };
        }
      });

      // setData(assets);
    }

    const uniqueTokensMap = new Map();

    // Iterate through the array and update the balance for duplicates
    assets?.forEach((token) => {
      const key = `${token.symbol}`;
      if (uniqueTokensMap.has(key)) {
        // Update the balance for duplicates
        uniqueTokensMap.get(key).balance += parseFloat(token?.balance);
        uniqueTokensMap.get(key).value += parseFloat(token?.value);
      } else {
        // Add a new entry for unique tokens
        uniqueTokensMap.set(key, {
          ...token,
          balance: parseFloat(token?.balance),
          value: parseFloat(token?.value),
        });
      }
    });

    // Convert the Map values back to an array
    assets = Array.from(uniqueTokensMap.values());
    setOtherData(assets);
    assets?.sort((a, b) => b?.value - a?.value);

    setData(transformArray(assets?.filter((item) => !item?.possible_spam)));
    NewFunction(transformArray(assets.filter((item) => !item?.possible_spam)));

  }, [appData.priceData, appData.portfolioData, appData.activeWallet]);

  const NewFunction = (originalArray) => {
    let totalWorth = 0;
    if (originalArray.length > 0) {
      originalArray.forEach((item) => {
        if (item?.value) {
          totalWorth += item?.value;
        }
      });
    }
    originalArray.forEach((item, index) => {
      // Check if the asset has more than 7% value
      if ((item.value / totalWorth) * 100 > 10) {
       return;
      }
    });
  }
  const NewArray = (name, description) => {
    const data1 = data.map((item) => item.name)
    let totalValue = data.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.value;
    }, 0);
    
    let result = otherData.filter(item => !data1.some(obj => obj=== item.name));
    let a = result.map((item, index) => ({
      name: item.name,
      value: item.value,
      description: `${((item.value / totalValue) * 100).toFixed(2)}%`,

    }));
    if(name !== 'Others'){
      return  <> {name} {description} </>;

    }
    else{
      return  <> {a.map((item) => <div>{item.name} {item.description}</div>)}</>;
    }
  }

  return (
      <div className="p-8">
          <div className="flex">
            {data.map((item, index) => (
              <div key={index} className={`h-16 tooltip`} style={{ backgroundColor:`${item.color}`, width:`${item.graphRoundValue}`}}>
                <div className="token-container"><span>{item.name} {item.description}</span></div>
                <span className="tooltiptext">{NewArray(item.name, item.description)}</span>
              </div>
            ))}
          </div>
        </div>
    );
};
export default TokenStackedGraph;
