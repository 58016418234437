import React, { useEffect }  from 'react';

import ModalActionConfirm from './../../dialogs/ModalActionConfirm';

import useModalNavigation, { useModalData } from '../../../helpers/useModalNavigation';
import { EXPORT_WALLET_PRIVATE_KEY_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import ModalExportKeys from '../../dialogs/ModalExportKeys';


const ModalExportPrivateKeyFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(EXPORT_WALLET_PRIVATE_KEY_FLOW);
    const [ modalData, setModalData ] = useModalData();

    const onClose = () => {
        setActiveModalNav(DEFAULT_MODAL_STATUS);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    

    return (
        <ModalExportKeys
            pvkey={modalData.pvkey}
            btnLabel="Done"
            onClose={onClose}
            callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
            modalCancel_label='export_prvkey_cancel'
        />
    )
}

export default ModalExportPrivateKeyFunctions;