import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import SidebarNotification from "../../common/SidebarNotification/SidebarNotification";
import SearchInput from "../../settings/SearchInput/SearchInput";
import AssetsTabsOverview from "../AssetsTabsOverview/AssetsTabsOverview";
import { useLocation } from "react-router-dom";
import NFTCard from "../NFTCard/NFTCard";
import { useApp } from "../../../helpers/AppProvider";
// import { useUser } from "../../../helpers/UserProvider";
import { delay_code } from "../../../helpers/generalHelper";
import Storage from "../../../helpers/Storage";

const NFTCollectionDetail = () => {
  const location = useLocation();
  const { actions, appData } = useApp();
  // const { userActions, userData } = useUser();
  const getAlreadySelectedWalletID =
    Storage.load("NFTSelectedWallet")?.selectedWallet;

  const [sidebarActive, setSidebarActive] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [nftCollectionItems, setNftColletionItems] = useState([]);

  useEffect(() => {
    // actions.setLoading(true, "Loading Vault");
    if (appData?.regStatus === "complete") {
      load_vault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.regStatus]);

  const load_vault = async () => {
    actions.setLoading(true, "Loading");

    await Promise.all([
      // actions.loadChains(),
      // actions.loadSupportedAssets(),
      // actions.loadSupportedCurrencies(),
      // actions.loadActiveCurrency(userData.user.token),
      // actions.loadUsdRate(),
      // userActions.loadVault(),
    ]);
    await delay_code(400);
    const acc = await appData?.accounts;
    const addreses = acc ? Object.values(acc) : {};
    if (appData?.wallets?.length < 1) {
      const walletChanges = await actions.loadWalletCardData(addreses);
      actions.setAppData(walletChanges);
    }
    actions.setLoading(false);
    actions.setLoading(false);
  };
  useEffect(() => {
    if (location?.state?.nftCollectionItems) {
      setNftColletionItems(location?.state?.nftCollectionItems);
    }
  }, [location]);

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {sidebarActive && <div className="mobile_blur"></div>}

        {/* <!-- main content --> */}
        <div className="main__content__wrp assets__overview-wrapper assets_overview_nft_wrp">
          <div className="assets__overview" data-to_show="all_assets">
            <AssetsTabsOverview btn="nft_collection" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "33px 33px",
                borderBottom: "3px solid #CBD7E9",
              }}
            >
              <div>
                <div style={{ fontSize: 18, fontWeight: 600 }}>
                  {nftCollectionItems[0]?.name}
                </div>
              </div>
              <div style={{ minWidth: "30%" }}>
                <SearchInput
                  title="Search item, collection, art etc"
                  search={searchInput}
                  handleFilter={searchHandler}
                />
              </div>
            </div>{" "}
            <div className="mt-7 ml-14 mr-14 grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-12 gap-y-5">
              {nftCollectionItems !== 0 &&
                nftCollectionItems
                  .filter((i) => {
                    return searchInput === ""
                      ? i
                      : i?.metadata?.name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase());
                  })
                  ?.map((item, index) => (
                    <NFTCard
                      items={item}
                      activeWallet={getAlreadySelectedWalletID}
                    />
                  ))}
            </div>
          </div>
        </div>

        <SidebarNotification />
      </div>
      {/*<div className="content_tab-nft-inner"></div>*/}
    </div>
  );
};

export default NFTCollectionDetail;

//const data = [{asset_nft_description
//	:
//	"suscipit blandit praesent facilisi. eu hendrerit exerci in qui"
//	asset_nft_image
//	:
//	"images/nft/nft_dummy_2.png"
//	asset_nft_title
//	:
//	"Reeves"}]
