import React, { useEffect } from "react";

const SeedWordsInput = ({ screen, seedPhrase, visible, editableSeeds, setEditableSeeds, errorMessage, pasteHandler = true }) => {
    const parentRef = React.createRef();

    const onPasteHandler = (e) => {
        if (pasteHandler) {
            const val = e.clipboardData.getData('Text').toLowerCase().split(' ');
            const inputs = parentRef.current.querySelectorAll('input');
            setTimeout(() => {
                for (var i = 0; i < 12; i++) {
                    if (val[i]?.length > 0) {
                        inputs[i].value = val[i].toLowerCase();
                        inputs[i].setAttribute('data-complete', true);
                    } else {
                        inputs[i].removeAttribute('data-complete');
                    }
                }
                setEditableSeeds( val.reduce( (acc,item,idx) => { acc[idx]=item;return acc;}, {}) );
                
                inputs[0].blur();
            }, 50);
            e.preventDefault();
        }
    }

    const onChange = (e) => {
        if (pasteHandler) {
            let complete = false;
            const value = e.target.value;
            const position = e.target.getAttribute('data-position');

            if (value.length > 1) {
                e.target.setAttribute('data-complete', true);
            } else {
                e.target.removeAttribute('data-complete');
            }

            setEditableSeeds(prevState => {
                if( value.length < 1 ){
                    prevState[position] = '';
                }
                return {...prevState, [position]: value}
            })
        }
    }

    useEffect(() => {
        // Reset seeds when process is reloaded
        if (pasteHandler) {
            setEditableSeeds(new Array(12).fill('').reduce( ( acc,item,idx) =>{ acc[idx]=item;return acc;},{}))
        }
    }, [])

    return (
        <>
            { screen === "enter_seed" &&
                <div className="modal_seed_phrase_wrp modal_enter_seed_phrase_wrp">
                    <div className="seed_phrase_box mr_16 mb_10 seed_phrase_box--active">
                        <span className="lbl">1</span>
                        <input type="text" defaultValue value="" name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">2</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">3</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box seed_phrase_box--blur">
                        <span className="lbl">4</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 mb_10 seed_phrase_box--blur">
                        <span className="lbl">5</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                        <span className="lbl">6</span>
                        <input type="text" defaultValue value="" name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">7</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box seed_phrase_box--blur">
                        <span className="lbl">8</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">9</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">10</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 seed_phrase_box--blur">
                        <span className="lbl">11</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box seed_phrase_box--blur">
                        <span className="lbl">12</span>
                        <input type="text" defaultValue name="seedWord" className="seed_phrase" />
                    </div>
                </div>
            }
            { screen === "seed_phrase" &&
                <div className="modal_seed_phrase_wrp">
                    <div className="seed_phrase_box mr_16 mb_10">
                        <span className="lbl">1</span>
                        <input type={visible} readOnly value={seedPhrase[0]} data-position={0} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">2</span>
                        <input type={visible} readOnly value={seedPhrase[1]} data-position={1} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">3</span>
                        <input type={visible} readOnly value={seedPhrase[2]} data-position={2} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box">
                        <span className="lbl">4</span>
                        <input type={visible} readOnly value={seedPhrase[3]} data-position={3} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16 mb_10">
                        <span className="lbl">5</span>
                        <input type={visible} readOnly value={seedPhrase[4]} data-position={4} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">6</span>
                        <input type={visible} readOnly value={seedPhrase[5]} data-position={5} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">7</span>
                        <input type={visible} readOnly value={seedPhrase[6]} data-position={6} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box">
                        <span className="lbl">8</span>
                        <input type={visible} readOnly value={seedPhrase[7]} data-position={7} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">9</span>
                        <input type={visible} readOnly value={seedPhrase[8]} data-position={8} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">10</span>
                        <input type={visible} readOnly value={seedPhrase[9]} data-position={9} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box mr_16">
                        <span className="lbl">11</span>
                        <input type={visible} readOnly value={seedPhrase[10]} data-position={10} name="seedWord" className="seed_phrase" />
                    </div>
                    <div className="seed_phrase_box">
                        <span className="lbl">12</span>
                        <input type={visible} readOnly value={seedPhrase[11]} data-position={11} name="seedWord" className="seed_phrase" />
                    </div>
                </div>
            }
            { screen === "seed_phrase_full" &&
                <>
                    <div className="modal_seed_phrase_wrp modal_enter_seed_phrase_wrp" ref={parentRef}>
                        <div className="seed_phrase_box mr_16 mb_10 seed_phrase_box--active">
                            <span className="lbl">1</span>
                            <input type="text" value={editableSeeds[0]} name="seedWord" data-position={0} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">2</span>
                            <input type="text" value={editableSeeds[1]} name="seedWord" data-position={1} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">3</span>
                            <input type="text" value={editableSeeds[2]} name="seedWord" data-position={2} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box seed_phrase_box--active">
                            <span className="lbl">4</span>
                            <input type="text" value={editableSeeds[3]} name="seedWord" data-position={3} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 mb_10 seed_phrase_box--active">
                            <span className="lbl">5</span>
                            <input type="text" value={editableSeeds[4]} name="seedWord" data-position={4} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">6</span>
                            <input type="text" value={editableSeeds[5]} name="seedWord" data-position={5} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">7</span>
                            <input type="text" value={editableSeeds[6]} name="seedWord" data-position={6} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box seed_phrase_box--active">
                            <span className="lbl">8</span>
                            <input type="text" value={editableSeeds[7]} name="seedWord" data-position={7} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">9</span>
                            <input type="text" value={editableSeeds[8]} name="seedWord" data-position={8} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">10</span>
                            <input type="text" value={editableSeeds[9]} name="seedWord" data-position={9} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box mr_16 seed_phrase_box--active">
                            <span className="lbl">11</span>
                            <input type="text" value={editableSeeds[10]} name="seedWord" data-position={10} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                        <div className="seed_phrase_box seed_phrase_box--active">
                            <span className="lbl">12</span>
                            <input type="text" value={editableSeeds[11]} name="seedWord" data-position={11} className="seed_phrase" onChange={onChange} onPaste={onPasteHandler} />
                        </div>
                    </div>
                    <div className="enter_seed_error">{ errorMessage }</div>
                </>
            }
            
        </>
    )
}

export default SeedWordsInput;
