import { useContext, useState } from "react";
import appContext from "./../context/globalContext";
import initialState from "./../context/initialStateGlobal";
import AppController from "./../controllers/AppController";
import { useCheckScroll } from "./generalHelper";

export const LIMIT_SAFLEID_CHANGE = 2;

const AppProvider = ({ children }) => {
  const state = useProvideApp();
  return <appContext.Provider value={state}>{children}</appContext.Provider>;
};

const useProvideApp = () => {
  const [appData, setAppData] = useState(initialState);
  const [notificationData, setNotificationData] = useState({});
  const actions = new AppController(appData, setAppData, setNotificationData);
  useCheckScroll(appData);

  return {
    appData,
    setAppData,
    actions,
    notificationData,
  };
};

const useApp = () => {
  return useContext(appContext);
};
export { useApp, AppProvider };
