import React, { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import ModalAddAssetFunctions from "./../../common/AssetsAddCustomTokenProcess/ModalAddAssetFunctions";
// import ModalAddAssetCancelFunctions from './../../common/AssetsAddCustomTokenProcess/ModalAddAssetCancelFunctions';
// import ModalAddAssetFailedFunctionstFunction from './../../common/AssetsAddCustomTokenProcess/ModalAddAssetFailedFunctions';

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  ADD_CUSTOM_ASSET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import Toast from "../../registration/Toast/Toast";

const AssetsAddCustomTokenProcess = (props) => {
  const { userData, userActions } = useUser();

  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      wallets = [],
    },
    setAppData,
  } = useApp() || {};

  const [setActiveModalNav, goNext, goBackward] = useModalNavigation(
    ADD_CUSTOM_ASSET_FLOW
  );
  // const [toastIsOpen, setToastIsOpen] = useState(false)
  const onClose = (bool) => {
    setActiveModalNav("CLOSED");
    props.hasOwnProperty("callbackModalClose") &&
      props.callbackModalClose(bool);
    //
  };

  const onCancel = (e) => {
    // setActiveModalNav('CLOSED');
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {activeModalLabel === "modal_add_custom_token_asset" && (
        <ModalAddAssetFunctions
          shouldRefresh={props.shouldRefresh}
          onClose={onClose}
          setToastIsOpen={(e) => props.setToastIsOpen(e)}
        />
      )}
      {/* <Toast
        title=""
        bodyText={'Token added successfully'}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        seedPhrase 
      /> */}
      {/* {activeModalLabel === "modal_add_custom_asset_failed" && <ModalAddAssetCancelFunctions onClose={onClose} />}
            {activeModalLabel === "modal_add_custom_asset_cancel" && <ModalAddAssetFailedFunctionstFunction onClose={onClose} />} */}
    </>
  );
};

export default AssetsAddCustomTokenProcess;
