import React, { useEffect, useState } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  SETTINGS_EXPORT_SEED_PHRASE,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";

const ModalEnterPinFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    SETTINGS_EXPORT_SEED_PHRASE
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [modalData, setModalData] = useModalData();
  const { userActions } = useUser();
  const { appData } = useApp();

  const onClose = () => {
    setActiveModalNav(DEFAULT_MODAL_STATUS);
  };
  const callbackOpenPopup = async (pinNo) => {
    const validatePin = await userActions.validatePin(pinNo);

    if (modalData.flow === "export_seed_phrase" && validatePin === true) {
      const mnemonicResp = await userActions.exportMnemonic(
        pinNo,
        appData?.accounts[0]?.address
      );
      setModalData({ mnemonic: mnemonicResp });
      setActiveModalNav("settings_export_seedphrase");
    } else {
      setErrorMessage("Incorrect pin");
    }
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = async (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  return (
    <ModalEnterPin
      onClose={onClose}
      onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="settings_export_seedphrase_cancel"
      modalNext_label="settings_export_seedphrase"
      errorMessage={errorMessage}
    />
  );
};

export default ModalEnterPinFunctions;
