import React, { useEffect }  from 'react';
import useModalNavigation, { useModalData } from '../../../helpers/useModalNavigation';
import { SETTINGS_EXPORT_SEED_PHRASE, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import ModalSeedPhrase from '../../dialogs/ModalSeedPhrase';
import { useUser } from '../../../helpers/UserProvider';


const ModalExportSeedPhraseFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SETTINGS_EXPORT_SEED_PHRASE);
    const [ modalData, setModalData ] = useModalData();
    
    const onClose = () => {
        setActiveModalNav(DEFAULT_MODAL_STATUS);
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    return (
        <ModalSeedPhrase 
            mnemonicPhrase={modalData.mnemonic}
            onClose={onClose}
            callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
            callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
            modalCancel_label='settings_export_seedphrase_cancel'
            modalNext_label='CLOSED'
            pasteHandler={false}
        />
    )
}

export default ModalExportSeedPhraseFunctions;