// import React, { useState ,useEffect , useRef } from 'react';

const FilterAssetMobileViewItem = (items) => {
  const item = items.items;

  return (
    <div
      className={
        "mobile_filter_option_item " + (items.highlight ? "selected" : "")
      }
      onClick={items.clickHandle}
    >
      <span className="label">{item.sort_name}</span>
      <span className="icon_selected icon-check-solid"></span>
    </div>
  );
};

export default FilterAssetMobileViewItem;
