import React, { useState } from "react";
import MainModal from "./../generic/MainModal";

const ModalSetWalletLabel = (props) => {
  const [editLabel, setEditLabel] = useState("");

  const handleChange = (event) => {
    setEditLabel(event.target.value);
    props.onChangeWalletLabel(event.target.value);
  };

  return (
    <MainModal
      // on close - display conformation first
      onClose={() =>
        props.callbackCancelGeneratePopup("modal_cancel_import_wallet")
      }
      extraClasses={[
        "modal_w440 modal_p30",
        "modal_dflex",
        "modal_wallet--1",
        "modal_set_wallet_label",
      ]}
      dataClass="modal_set_wallet_label"
    >
      {/* Modal content */}
      <div className="modal_head--1">
        <img src="images/wallets/set-wallet-label.svg" alt="" />
        <div className="modal_title">Set Wallet Label</div>
        <div className="modal_text">
          Enter Wallet Label, as per your choice Ex- Iva Ryan Wallet
        </div>
      </div>
      <div className="modal_wrapper p-5">
        <input
          type="text"
          placeholder="Enter Wallet Label"
          onChange={handleChange}
        />
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={() =>
              props.callbackCancelGeneratePopup("modal_cancel_import_wallet")
            }
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            disabled={editLabel?.trim().length === 0}
            onClick={() => props.callbackOpenPopup("modal_import_wallet_key")}
          >
            Next
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalSetWalletLabel;
