import React, { useEffect }  from 'react';

import ModalActionFailed from './../../dialogs/ModalActionFailed';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { SETTINGS_CHANGE_EMAIL, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalEmailFailedFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SETTINGS_CHANGE_EMAIL);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    
    const onRetry = () => {
        setActiveModalNav('modal_email_enter_pin');
    }

    return (
        <ModalActionFailed title="Email address update failed" 
        desc="" 
        dataClass="modal_action_failed1" 
        onClose={ onClose } 
        onOk={onClose}
        onRetry = {onRetry}
        iconErr={'images/wallets/update-failed-warning.svg'}
        /> 
    )
}

export default ModalEmailFailedFunctions;