/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import ModalEnterVerificationCode from "../../dialogs/ModalEnterVerificationCode";
import ModalEnterSeed from "../../dialogs/ModalEnterSeed";
import ModalSetNewPin from "../../dialogs/ModalSetNewPin";
import ModalPINChanged from "../../dialogs/ModalPINChanged";
import ModalCancelProcess from "../../dialogs/ModalCancelProcess";
import ModalWalletFailed from "../../dialogs/ModalWalletFailed";
import ModalActionConfirm from "../../dialogs/ModalActionConfirm";
import ModalActionFailed from "../../dialogs/ModalActionFailed";

import { useRecaptcha, useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import useModalNavigation from "../../../helpers/useModalNavigation";
import { FORGOT_PIN_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";

const ForgotPinProcess = () => {
  const {
    userData: { user: userData },
    userActions,
  } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
    },
  } = useApp() || {};
  const [setActiveModalNav, , goBackward] = useModalNavigation(FORGOT_PIN_FLOW);
  const [editableSeeds, setEditableSeeds] = useState(
    new Array(12).fill("").reduce((acc, item, idx) => {
      acc[idx] = item;
      return acc;
    }, {})
  );
  const [setAuthToken] = useState();
  const recaptchaRef = useRecaptcha();

  const [validation, setValidation] = useState(false);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = () => {
    setActiveModalNav("forgot_pin_cancel", true);
  };
  const callbackRetryOpenPopup = (popup) => {
    setActiveModalNav("set_new_pin");
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  useEffect(() => {}, [activeModalLabel]);

  const callbackCheckSeeds = async (e) => {
    e.preventDefault();
    const token = await recaptchaRef.current.executeAsync();
    const validMnems = await userActions.validateMnemonic(
      userData.safleID,
      Object.values(editableSeeds).join(" "),
      token
    );
    if (!validMnems) {
      setValidation("You have entered wrong seed phrase");
    } else {
      // change pin to new pin
      setActiveModalNav("set_new_pin");
    }
  };

  return (
    <>
      {
        // enter_verification_code
        // enter_seed_phrase
        // set_new_pin
        // pin_changed_success
      }

      {activeModalLabel === "enter_verification_code" && (
        <ModalEnterVerificationCode
          errorMessage={validation}
          setErrorMessage={setValidation}
          setAuthToken={setAuthToken}
          callbackOpenPopup={callbackOpenPopup}
          callbackCancelPopup={callbackCancelPopup}
          modalCancelLabel={"forgot_pin_cancel"}
          modalNextLabel={"enter_seed_phrase"}
          modalNavFlow={FORGOT_PIN_FLOW}
        />
      )}

      {activeModalLabel === "enter_seed_phrase" && (
        <ModalEnterSeed
          title="Enter Seed Phrase"
          errorMessage={validation}
          onClose={callbackCancelPopup}
          setValidation={setValidation}
          callbackOpenPopup={callbackCheckSeeds}
          modalCancelLabel="forgot_pin_cancel"
          modalNextLabel="set_new_pin"
          buttonText="Done"
          setEditableSeeds={setEditableSeeds}
          editableSeeds={editableSeeds}
        />
      )}

      {activeModalLabel === "set_new_pin" && (
        <ModalSetNewPin
          validation={validation}
          setValidation={setValidation}
          callbackOpenPopup={callbackOpenPopup}
          callbackCancelPopup={callbackCancelPopup}
          modalCancelLabel="forgot_pin_cancel"
          modalNextLabel="pin_changed_success"
          mnemonics={editableSeeds}
        />
      )}

      {activeModalLabel === "pin_changed_success" && (
        <ModalPINChanged onClose={onClose} />
      )}
      {activeModalLabel === "pin_changed_failed" && (
        <>
          {/* <ModalWalletFailed 
                    onClose={onClose} 
                    callbackOpenPopup={callbackOpenPopup}
                    validation={validation}
                    reason={'Change SafleId failed.'}
                    retryModal={'set_new_pin'}
                /> */}
          <ModalActionFailed
            title="PIN Update failed"
            desc={validation}
            dataClass="modal_action_failed2"
            onClose={onClose}
            callbackRetryPopup={callbackRetryOpenPopup}
            iconErr={"images/wallets/update-failed-warning.svg"}
          />
        </>
      )}

      {activeModalLabel === "forgot_pin_cancel" && (
        <>
          {/* <ModalCancelProcess 
                    onClose={onClose}  
                    goBackward={goBackward}
                    body="Are you sure do you want to cancel the forgot pin recovery?"
                /> */}
          <ModalActionConfirm
            title="Want to cancel change PIN"
            desc=""
            dataClass="modal_action_confirm1 modal_action_cancel_recover_pin"
            onClose={onClose}
            goBackward={goBackward}
            iconErr={"images/wallets/cancel_confirm.svg"}
          />
        </>
      )}
    </>
  );
};

export default ForgotPinProcess;
