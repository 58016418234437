import { useEffect, useState } from "react";
import { ArrowSmRightIcon } from "@heroicons/react/solid/";

const PinInput = ({
  timer = null,
  resendCode = null,
  onCompletePIN,
  pinLength = 6,
  disabled,
}) => {
  const [objPinValue, setObjPinValue] = useState({});
  const [completePin, setCompletePin] = useState(false);

  useEffect(() => {
    document.querySelector(".input-cell").focus();
  }, []);

  const onKeyDownInput = async (e) => {
    const editedElement = e.target;
    const indexElement = [...editedElement.parentElement.children].indexOf(
      editedElement
    );
    setCompletePin(false);

    if (typeof Number(e.key) === "number" && Number(e.key) > -1) {
      await setObjPinValue((prev) => ({
        ...prev,
        [indexElement]: Number(e.key),
      }));

      if (editedElement?.nextSibling) {
        editedElement.nextSibling.focus();
      } else {
        editedElement.blur();
        setCompletePin(true);
      }
    }

    if (e.key === "Backspace") {
      disabled(true);
      setObjPinValue((prev) => ({ ...prev, [indexElement]: "" }));

      if (editedElement?.previousSibling) {
        editedElement.previousSibling.focus();
      }
    }
  };

  useEffect(() => {
    if (completePin) {
      let pinValue = "";
      Object.keys(objPinValue).forEach((pinItem) => {
        pinValue += objPinValue[pinItem];
      });

      disabled(false);
      if (pinValue.length === pinLength) {
        onCompletePIN(pinValue);
      }
    } else {
      disabled(true);
    }
  }, [completePin, onKeyDownInput]);

  return (
    <>
      <div className="input-pin">
        <div className="input-areas">
          {[...Array(pinLength).keys()].map((key) => (
            <input
              key={key}
              id={`inputpin-${key}`}
              type="text"
              className={
                (objPinValue[key] !== undefined && objPinValue[key] !== ""
                  ? "filled "
                  : "") + "input-cell"
              }
              value={
                typeof objPinValue[key] === "number" ? objPinValue[key] : ""
              }
              onKeyDown={(e) => onKeyDownInput(e)}
              onChange={() => {}}
              maxLength="1"
            />
          ))}
        </div>

        {timer && <div className="input-pin-timer">01:30</div>}
        {resendCode && (
          <div className="input-pin-resend">
            Didn’t receive the code?{" "}
            <span>
              Resend Code <ArrowSmRightIcon />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default PinInput;
