const TokenContractAddressInput = ( { onChange, contractAddress, error = false }) => {

    return (
        <div className="form__input form__input_default">
            <span className="input_label">Token Contract Address</span>
            <div className="form__input_default">
                <input
                type="text"
                className={`form-input-default `+(error? 'error' : '')}
                placeholder="Enter Token Contract Address"
                onChange={ e=>onChange(e) }
                value={contractAddress}
                />
            </div>
        </div>
    )
}

export default TokenContractAddressInput;
