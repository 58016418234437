
const ContactSupportContent = () => {

  return (
    <div className="settings__overview_inner_content white_bg contact_support_content" data-show="contact_support">
      <div className="mobile_screen_title">Contact Support</div>
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_contact-support">
          <span className="contact-support-write-us">Write to us at</span>
          <a href="mailto:hello@getsafle.com" className="contact-support-write-email">hello@getsafle.com</a>
          <span className="contact-support-or">or</span>
          <div className="contact-support-socials">
            <a href={ process.env.REACT_APP_LINK_DISCORD } target="_blank"><span className="icon-discord-brands" /></a>
            <a href={ process.env.REACT_APP_LINK_TELEGRAM } target="_blank" className="tw"><span className="icon-telegram-plane-brands" /></a>
            <a href={ process.env.REACT_APP_LINK_TWITTER } target="_blank"><span className="icon-twitter-brands" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSupportContent;