import React, { useEffect } from "react";
import { usePopup } from "../../helpers/PopupProvider";

const MainModal = ({
  title,
  bodyClasses,
  onClose,
  extraClasses = [],
  extraModalContentClasses = [],
  dataClass,
  children,
  search = <></>,
}) => {
  const { popupData, setPopupData } = usePopup();

  useEffect(() => {
    setPopupData({ blockScroll: true });

    return () => {
      setPopupData({ blockScroll: false });
    };
  }, []);

  const handleClose = (e) => {
    onClose(e);
  };

  return (
    <div
      className={[...extraClasses, "modal__main", "modal"].join(" ")}
      style={{ display: "flex" }}
      data-class={dataClass}
    >
      <div className={[...extraModalContentClasses, "modal-content"].join(" ")}>
        <div className="modal-header">
          <div className="modal-header-heading">
            <span className="modal__close" onClick={handleClose}>
              <span className="icon-close"></span>
            </span>
            {title && <h2 className="modal_title">{title}</h2>}
          </div>
        </div>
        {search}
        <div
          className={bodyClasses ? "modal-body overflow-scroll" + bodyClasses : "modal-body overflow-scroll"}
        >
          {children}
        </div>
        <div className="modal-footer" data-show="false"></div>
      </div>
    </div>
  );
};

export default MainModal;
