import { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
//import { useModalNavigation } from "../../../helpers/useModalNavigation";
import { SEND_NFT_FLOW } from "../../../constants/ui";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import TooltipBlackBackground from "../TooltipBlackBackground/TooltipBlackBackground";
import NFTTag from "../../assets/NFTTag/NFTTag";

const NFTDetailsRight = ({ nftDetails, handleModal, address }) => {
  const { actions, appData } = useApp();
  const [modalData, setData] = useModalData();
  const [priceData, setPriceData] = useState({});

  const { metadata } = nftDetails;
  useEffect(() => {
    nftDetails && address && getPriceData();
  }, [nftDetails, address]);

  const getPriceData = async () => {
    try {
      // const payload = {
      //   publicAddress: address,
      //   contractAddress: nftDetails?.contractAddress,
      //   tokenId: nftDetails?.tokenId,
      //   chain:
      //     nftDetails?.chainId === 1
      //       ? "ethereum"
      //       : nftDetails?.chainid === 137
      //       ? "polygon"
      //       : "bsc",
      // };
      const price = 0; //await actions.getPriceData(payload);
      setPriceData(price);
    } catch (e) {}
  };

  return (
    <div className="assets__overview_single-nft-details">
      <div className="nft__details-top">
        <div className="nft__details-desc">
          <span className="nft__details-name">
            {nftDetails?.metadata?.name}
          </span>
          <span className="flex flex-row">
            {!metadata?.sandbox?.classification?.categories ? (
              <span className="nft__details-tag">NA</span>
            ) : (
              metadata?.sandbox?.classification?.categories.map((i) => (
                <span className="nft__details-tag mr-2">
                  <NFTTag category={i} />
                </span>
              ))
            )}
          </span>
        </div>
        <div className="nft__details-token truncate">
          <span>Token ID: </span>
          <span className="">{nftDetails?.tokenId}</span>
        </div>
        {/*<div className="nft__details-token-desc">
          <span>From easiest to hardest.</span>
        </div>*/}
        <div className="asset__coin-worth-asset">
          <span className="asset__coin-worth-label relative">
            Worth
            <TooltipBlackBackground
              hideAfter="3000"
              title=""
              description={<>Notional worth from last trades</>}
              stylePassed="ml-3"
            />
          </span>{" "}
        </div>
        {!priceData?.priceData ||
        priceData?.priceData === "No price data found" ? (
          <span className="text-slate-400">NA</span>
        ) : (
          <>
            <span className="asset__coin-worth-prefix">
              {priceData?.valueInCrypto} {priceData?.symbol}
            </span>
            <span className="asset__coin-worth-coin-price">
              ${priceData?.valueInUSD}
            </span>
            <span className="asset__coin-worth-gained red_hist">
              <span className="icon-grow"></span>
              <span className="icon-grow-copy"></span>
              <span className="asset__coin_asset-percent">0.79%</span>
            </span>
          </>
        )}
      </div>
      <div className="nft__details-action">
        <button
          className="btn-default btn-blue"
          onClick={() => {
            setData(nftDetails);
            handleModal("modal_send_nft");
          }}
        >
          Send
        </button>
      </div>
      <div className="nft__details-link">
        <ul>
          <li>
            <span
              className="label"
              style={{
                cursor: `${nftDetails?.tokenUrl ? "pointer" : "no-drop"}`,
              }}
            >
              Open NFT page
              {nftDetails?.tokenUrl && (
                <a href={nftDetails?.tokenUrl} target="_blank" rel="noreferrer">
                  <span class="icon-icon_eth_scan"></span>
                </a>
              )}
            </span>
          </li>
          <li>
            <span
              className="label"
              style={{
                cursor: `${
                  nftDetails?.metadata?.external_url ? "pointer" : "no-drop"
                }`,
              }}
            >
              Open on OpenSea.io
              {nftDetails?.metadata?.external_url && (
                <a
                  href={nftDetails?.metadata?.external_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span class="icon-icon_eth_scan"></span>
                </a>
              )}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NFTDetailsRight;
