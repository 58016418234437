import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useApp } from "../../../helpers/AppProvider";
import ROUTES from "../../../helpers/routes";
import { useUser } from "../../../helpers/UserProvider";

const ManageVaultHeader = ({ data }) => {
  const navigate = useNavigate();
  const { userActions } = useUser();
  const { actions } = useApp();
  //
  const [cloudStorage, setCloudStorage] = useState();
  const [headerData, setheaderData] = useState();

  useEffect(() => {
    if (data) {
      setCloudStorage(data?.userDetails?.vaultStorage);
      setheaderData([
        { name: "Vault Version", value: data?.userDetails?.version || "-" },
        { name: "Number of chains", value: data?.chainsCount || "-" },
        { name: "Active Wallets", value: data?.activeWallets?.length || "-" },
        {
          name: "Archived Wallets",
          value: data?.archivedWallets?.flat().length || "-",
        },
        {
          name: "Imported Wallets",
          value: data?.importedWallets?.length || "-",
        },
        { name: "Storage Type", value: "Cloud" },
        { name: "Recovery", value: "Mechanism" },
      ]);
    }
  }, [data]);

  const recoveryMechanism = (index) => {
    navigate(
      ROUTES.RECOVERY_MECHANISM,
      index === 5 && {
        state: { storage: true, cloudStorage: cloudStorage },
      }
    );
  };

  return (
    <div className="flex">
      {headerData?.map((i, index) => (
        <div
          className={`${
            (index === 5 || index === 6) &&
            "hover:border hover:border-[#0669F8]"
          } border border-[#CBD7E9] flex items-center flex-col justify-center hover:bg-[#F1F4FA] cursor-pointer hover:text-[#0669F8] text-[#8690A0] h-40 md:w-[14vw] lg:w-[14vw] xl:w-[12vw] 2xl:w-[12vw]`}
          style={{
            // conditionally applying styles to storage nd recovery mechanism
            borderBottomRightRadius: index === 4 ? 20 : 0,
            borderBottomLeftRadius: index === 5 ? 20 : 0,
            marginRight: index === 4 ? 15 : 0,
            background: (index === 5 || index === 6) && "#F1F4FA",
          }}
          onClick={() =>
            (index === 5 || index === 6) && recoveryMechanism(index)
          }
        >
          <span
            className="text-1xl font-medium pb-5"
            style={{ color: (index === 5 || index === 6) && "#0D203E" }}
          >
            {i?.name}
          </span>
          <span
            className="text-4xl font-bold"
            style={{ color: (index === 5 || index === 6) && "#0D203E" }}
          >
            {i?.value}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ManageVaultHeader;
