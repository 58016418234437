import React, { useState } from "react";
import ModalAddWallet from "../components/dialogs/ModalAddWallet";
import ModalImportWallet from "../components/dialogs/ModalImportWallet";
import ModalExportKeys from "../components/dialogs/ModalExportKeys";
import ModalImportSuccess from "../components/dialogs/ModalImportSuccess";
import ModalRemoveWallet from "../components/dialogs/ModalRemoveWallet";
import ModalWalletRemoved from "../components/dialogs/ModalWalletRemoved";
import ModalWalletGenerated from "../components/dialogs/ModalWalletGenerated";
import ModalSetWalletLabel from "../components/dialogs/ModalSetWalletLabel";
import ModalImportWalletKey from "../components/dialogs/ModalImportWalletKey";
import ModalGenerateNewWallet from "../components/dialogs/ModalGenerateNewWallet";
import ModalAddWalletPopup from "../components/dialogs/ModalAddWalletPopup";
import ModalChangePIN from "../components/dialogs/ModalChangePIN";
import ModalEditWalletLabel from "../components/dialogs/ModalEditWalletLabel";
import ModalConfirmRemove from "../components/dialogs/ModalConfirmRemove";
import ModalError from "../components/dialogs/ModalError";

const WalletsDialogsScreen = () => {
  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
    if (openedPopup === "modal_import_success") {
      window.location.reload();
    }
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  return (
    <>
      <div>
        <h1>Wallets dialogs</h1>
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_add_wallet")}
        >
          Add wallet
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_import_wallet")}
        >
          Import wallet
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_set_wallet_label")}
        >
          Set Wallet Label
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_import_wallet_key")}
        >
          Import Wallet Key
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_import_success")}
        >
          Import Success
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_generate_new_wallet")}
        >
          Genereate New Wallet
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_add_wallet_popup")}
        >
          Add Wallet Confirm
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_change_pin")}
        >
          Enter PIN
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_wallet_generated")}
        >
          Wallet Generated
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_export_keys")}
        >
          Export Private Keys
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_edit_wallet_label")}
        >
          Edit Wallet Label
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_remove_wallet_popup")}
        >
          ModalConfirmRemove - Wallet Remove
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_cancel_importing_wallet")}
        >
          ModalConfirmRemove - Wallet Import
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_cancel_importing_wallet")}
        >
          ModalConfirmRemove - Wallet Generate
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_wallet_removed")}
        >
          Wallet Removed
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_error")}
        >
          ModalError - Wallet Import
        </button>
        <br />
        <br />
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_error_generate")}
        >
          ModalError - Wallet Generate
        </button>
        <br />
        <br />
      </div>

      {openedPopup === "modal_set_wallet_label" && (
        <ModalSetWalletLabel onClose={onClose} />
      )}
      {openedPopup === "modal_add_wallet_popup" && (
        <ModalAddWalletPopup onClose={onClose} />
      )}
      {openedPopup === "modal_import_wallet_key" && (
        <ModalImportWalletKey onClose={onClose} />
      )}
      {openedPopup === "modal_generate_new_wallet" && (
        <ModalGenerateNewWallet onClose={onClose} />
      )}
      {openedPopup === "modal_change_pin" && (
        <ModalChangePIN onClose={onClose} />
      )}
      {openedPopup === "modal_edit_wallet_label" && (
        <ModalEditWalletLabel onClose={onClose} />
      )}
      {openedPopup === "modal_remove_wallet_popup" && (
        <ModalConfirmRemove
          dataClass="modal_remove_wallet_popup"
          title="Are you sure you want to archive this wallet?"
          primaryBtnText="Cancel"
          secBtnText="Confirm"
          onClose={onClose}
        />
      )}
      {openedPopup === "modal_cancel_importing_wallet" && (
        <ModalConfirmRemove
          dataClass="modal_cancel_importing_wallet"
          title="Cancel importing wallet?"
          primaryBtnText="Yes"
          secBtnText="No"
          onClose={onClose}
        />
      )}
      {openedPopup === "modal_cancel_generating_wallet" && (
        <ModalConfirmRemove
          dataClass="modal_cancel_generating_wallet"
          title="Cancel generating new wallet?"
          primaryBtnText="Yes"
          secBtnText="No"
          onClose={onClose}
        />
      )}
      {openedPopup === "modal_import_wallet" && (
        <ModalImportWallet onClose={onClose} />
      )}
      {openedPopup === "modal_import_success" && (
        <ModalImportSuccess onClose={onClose} />
      )}
      {openedPopup === "modal_export_keys" && (
        <ModalExportKeys onClose={onClose} />
      )}
      {openedPopup === "modal_add_wallet" && (
        <ModalAddWallet onClose={onClose} />
      )}
      {openedPopup === "modal_remove_wallet" && (
        <ModalRemoveWallet onClose={onClose} />
      )}
      {openedPopup === "modal_wallet_removed" && (
        <ModalWalletRemoved onClose={onClose} />
      )}
      {openedPopup === "modal_wallet_generated" && (
        <ModalWalletGenerated onClose={onClose} />
      )}
      {openedPopup === "modal_error" && <ModalError onClose={onClose} />}
      {openedPopup === "modal_error_generate" && (
        <ModalError
          onClose={onClose}
          dataClass="modal_error_generate"
          title="Wallet generation failed"
        />
      )}
    </>
  );
};

export default WalletsDialogsScreen;
