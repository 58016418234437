import React from 'react';
import MainModal from '../generic/MainModal';

const ModalError = ({ onClose, callbackOpenPopup, dataClass = "modal_error", title = "Wallet import failed", primaryBtnText = "OK", secondaryBtnText = "Retry" }) => {

    return (
        <MainModal
            onClose={onClose}
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet_import_failed']}
            dataClass={dataClass} >
            {/* Modal content */}
            <div className="modal_head">
                <img src="images/warning-black-24-dp.svg" alt="" className='modal_icon' />
                <div className="modal_title">{title}</div>
            </div>

            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered" >{primaryBtnText}</button>
                    <button className="btn-default btn-blue">{secondaryBtnText}</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalError;