// import { PencilIcon } from "@heroicons/react/solid";
import moment from "moment";
import React from "react";
import { RESTORE_WALLET_FLOW } from "../../constants/ui";
import { middleEllipsis } from "../../helpers/generalHelper";
import useModalNavigation, {
  useModalData,
} from "../../helpers/useModalNavigation";
import TooltipCopy from "../registration/TooltipCopy";

const ImportedWalletList = ({ item, openEnterPinModal }) => {
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);

  const openExportPvKeyHandler = () => {
    setModalData({ type: "export_pvkey", address: item.address });
    openEnterPinModal();
  };

  const openRestoreWalletHandler = () => {
    setActiveModalNav("modal_restore_wallet_popup");
    setModalData({ address: item.address });
  };

  const tooltipText = "";
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };

  return (
    <>
      {item && item.address && (
        <tr>
          <td className="text-left pl-8" width="9%">
            {typeof item?.label === "string"
              ? item?.label?.length > 20
                ? middleEllipsis(
                    item?.label,
                    parseInt(item?.label?.length * 0.1),
                    4
                  )
                : item?.label
              : item?.label?.polygon?.length > 20
              ? middleEllipsis(
                  item?.label?.polygon,
                  parseInt(item?.label?.polygon?.length * 0.1),
                  4
                )
              : item?.label?.polygon}
          </td>
          <td className="text-left pl-8" width="20%">
            {middleEllipsis(item?.address)}
          </td>
          <td className="text-center" width="18%">
            {item?.importedOn === "-"
              ? item?.importedOn
              : moment(item?.importedOn).format("DD-MM-YYYY, hh:mm")}
          </td>
          <td className="text-center" width="18%">
            {item?.archivedOn === "-"
              ? item?.archivedOn
              : moment(item?.archivedOn).format("DD-MM-YYYY, hh:mm")}
          </td>
          <td>
            {" "}
            <div className="aoc_table_col">
              <span className="filter_table_wallet_controls">
                <span className="filter_table_wallet-icon">
                  <span className="ilter_table_wallet-tooltiptext export-pkey ">
                    {/* <DuplicateIcon /> */}
                    <TooltipCopy
                      extraClasses="tooltip_registration tool_tip_vault_management cursor-pointer tooltip_webapp_copy"
                      textToDisplay={tooltipText}
                      imageToDisplay={<TooltipIconSvg />}
                      tooltipTextIn={tooltipTextIn}
                      tooltipTextInChange={tooltipTextInChange}
                      copiedText={item.address}
                      itemProps={item.address}
                      hideAfter="3000"
                    />
                  </span>
                </span>
                <span
                  onClick={() => openExportPvKeyHandler()}
                  className="filter_table_wallet-icon"
                >
                  <span className="filter_table_wallet-tooltiptext export-pkey">
                    Export Wallet{" "}
                  </span>
                  <img
                    src={"images/vault/vault_management_export_wallet.png"}
                    //width="34px"
                    //height="34px"
                    alt=""
                  />
                  {/*<span className="icon-icon-wallet-outline" />*/}
                </span>
                <span
                  className="filter_table_wallet-icon "
                  onClick={() => item.isDeleted && openRestoreWalletHandler()}
                  style={{ cursor: !item.isDeleted && "not-allowed" }}
                >
                  <span className="filter_table_wallet-tooltiptext">
                    {item?.isDeleted ? "Restore Wallet" : "Active Wallet"}
                  </span>
                  <img
                    src={"images/wallets/wallet-restore.svg"}
                    alt="restore"
                  />
                </span>
              </span>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ImportedWalletList;
