import { useState } from "react";
import ChangePasswordMobile from "../ChangePasswordMobile/ChangePasswordMobile";
import ChangePINMobile from "../ChangePINMobile/ChangePINMobile";
import CurrencyContent from "../CurrencyContent/CurrencyContent";
import LinkToSafleIdMobile from "../LinkToSafleIdMobile/LinkToSafleIdMobile";
import SettingsMenuItemMobile from "../SettingsMenuItemMobile/SettingsMenuItemMobile";
import BackToMenuMobile from "../BackToMenuMobile/BackToMenuMobile";
import GuardianSetupContent from "../GuardianSetupContent/GuardianSetupContent";
import ContactSupportContent from "../ContactSupportContent/ContactSupportContent";
import {
  CHANGE_SAFLE_ID,
  ADD_WALLET_FLOW,
  LINK_WITH_SAFLEID,
  SETTINGS_EXPORT_SEED_PHRASE,
} from "../../../constants/ui";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import ChangeSafleIdProcess from "../../common/ChangeSafleIdProcess/ChangeSafleIdProcess";
import AddWalletProcess from "../../common/AddWalletProcess/AddWalletProcess";
import { getSafleName } from "../../../helpers/vaultHelpers";
import { useUser } from "../../../helpers/UserProvider";
import SettingsExportSeedPhraseProcess from "../../common/SettingsExportSeedPhraseProcess/SettingsExportSeedPhraseProcess";
import Storage from "../../../helpers/Storage";
import LinkWalletSafleIdProcess from "../../common/LinkWalletSafleIdProcess/LinkWalletSafleIdProcess";

const SettingsOverviewMobile = (props) => {
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [subMenuActiveSafleId, setSubMenuActiveSafleId] = useState(false);
  const [subMenuActiveLegal, setSubMenuActiveLegal] = useState(false);
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();

  const storageData = Storage.load("user");

  const storageDataName = storageData.safleID;
  const storageDataToken = storageData.token;

  const { userData, setUserData, userActions } = useUser();
  const [modalData, setData] = useModalData();
  const [setModalOnChangeSafleId] = useModalNavigation(CHANGE_SAFLE_ID);
  const [setModalOnAddWallet] = useModalNavigation(ADD_WALLET_FLOW);
  // const [ setModalOnLinkWallet ] = useModalNavigation(LINK_WITH_SAFLEID);
  const [setExportSeedPhrase] = useModalNavigation(SETTINGS_EXPORT_SEED_PHRASE);

  const subMenuHandler = (type) => {
    setSubMenuActive(!subMenuActive);

    if (type === "safleid") {
      setSubMenuActiveSafleId(!subMenuActiveSafleId);
    } else if (type === "legal") {
      setSubMenuActiveLegal(!subMenuActiveLegal);
    }
  };

  const changeSafleId = () => {
    setModalOnChangeSafleId("change_safleid");
  };
  const addNewWalletAction = () => {
    setModalOnAddWallet("modal_add_wallet");
  };
  // const linkWalletSafleIdAction = (e) => {
  //
  //     setModalOnLinkWallet('link_to_safleid');
  // }
  const backupSeedsAction = async (e) => {
    const safleName = await getSafleName(storageDataToken);
    const confirm_email_otp = await userActions.resendEmailOTP(safleName.email);
    setUserName(safleName.name);
    setUserEmail(safleName.email);
    setData({
      type: "email-confirmation",
      safleID: storageDataName,
      email: safleName.email,
      flow: "export_seed_phrase",
    });

    setExportSeedPhrase("settings_export_seedphrase_otp");
  };

  return (
    <div className="d-none-desktop">
      {!props.linkSafleIdOpen &&
        !props.changePasswordMobileOpen &&
        !props.changePINMobileOpen &&
        !props.currencyContentOpen &&
        !props.guardianSetupOpen &&
        !props.contactSupportOpen && (
          <>
            <div className="settings_title_mobile">Account Settings</div>
            <div className="settings__overview_content_mobile">
              <SettingsMenuItemMobile
                icon="icon-settings-safle-logo"
                title="Manage SafleID"
                // text="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur."
                submenu={true}
                subMenuHandler={() => subMenuHandler("safleid")}
                subMenuActive={subMenuActiveSafleId}
                openSubMenuItem={props.setLinkSafleIdOpen}
                changeSafleId={changeSafleId}
                addNewWalletAction={addNewWalletAction}
                backupSeedsAction={backupSeedsAction}
              />
              <SettingsMenuItemMobile
                icon="icon-icon-lock-open"
                title="Change password"
                // text="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet."
                subMenuHandler={props.setChangePasswordMobileOpen}
              />
              <SettingsMenuItemMobile
                icon="icon-settings-icon-change-pin"
                title="Change PIN"
                // text="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit"
                subMenuHandler={props.setChangePINMobileOpen}
              />
              {/* <SettingsMenuItemMobile 
                            icon="icon-settings-manage-vault"
                            title="Manage vault"
                            text="Neque porro quisquam est qui dolorem ipsum quia dolor sit amet."
                        /> */}
              <SettingsMenuItemMobile
                icon="icon-settings-currency"
                title="Currency"
                // text="Neque porro quisquam est qui dolorem"
                subMenuHandler={props.setCurrencyContentOpen}
              />
              <SettingsMenuItemMobile
                icon="icon-settings-guardian-setup"
                title="Guardian setup"
                // text="Neque porro quisquam est qui dolorem ipsum quia dolor."
                subMenuHandler={props.setGuardianSetupOpen}
              />
              <SettingsMenuItemMobile
                icon="icon-settings-contact-support"
                title="Contact support"
                // text="Neque porro quisquam est qui dolorem"
                subMenuHandler={props.setContactSupportOpen}
              />
              <SettingsMenuItemMobile
                icon="icon-settings-terms"
                title="Legal"
                // text="Neque porro quisquam est qui dolorem"
                submenu={true}
                subMenuHandler={() => subMenuHandler("legal")}
                subMenuActiveLegal={subMenuActiveLegal}
              />
            </div>
          </>
        )}
      {props.linkSafleIdOpen && (
        <LinkToSafleIdMobile setLinkSafleIdOpen={props.setLinkSafleIdOpen} />
      )}
      {props.changePasswordMobileOpen && (
        <ChangePasswordMobile
          setChangePasswordMobileOpen={props.setChangePasswordMobileOpen}
        />
      )}
      {props.changePINMobileOpen && (
        <ChangePINMobile
          setChangePINMobileOpen={props.setChangePINMobileOpen}
        />
      )}
      {props.currencyContentOpen && (
        <BackToMenuMobile goBackMenu={props.setCurrencyContentOpen} />
      )}
      {props.currencyContentOpen && <CurrencyContent />}
      {props.guardianSetupOpen && (
        <BackToMenuMobile goBackMenu={props.setGuardianSetupOpen} />
      )}
      {props.guardianSetupOpen && <GuardianSetupContent />}
      {props.contactSupportOpen && (
        <BackToMenuMobile goBackMenu={props.setContactSupportOpen} />
      )}
      {props.contactSupportOpen && <ContactSupportContent />}

      <ChangeSafleIdProcess />
      <AddWalletProcess />
      <LinkWalletSafleIdProcess />
      <SettingsExportSeedPhraseProcess />
    </div>
  );
};

export default SettingsOverviewMobile;
