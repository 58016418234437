import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Button from "../Button/Button";
import "./../../../styles/registration/base.scss";
import "./../../../styles/registration/modal.scss";

import { XIcon } from "@heroicons/react/solid";
const Modal = ({
  isOpen = false,
  setOpen,
  title,
  extraClass = "",
  bodyText,
  bodyText2,
  bodyTextChrome,
  callToActionText,
  callToAction = false,
  icon = false,
}) => {
  return (
    // <Transition appear show={true} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={() => setOpen(false)}
      open={isOpen}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
      <div className="min-h-screen px-4 text-center">
        {/* <Transition.Child
          as={Fragment}
          enter="duration-700"
          enterFrom="-translate-y-20 opacity-0 sm:-translate-y-20 sm:translate-x-0"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="-translate-y-20 opacity-0 sm:translate-x-0"
        > */}
        <Dialog.Overlay className="fixed inset-0" />
        {/* </Transition.Child> */}

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        {/* <Transition.Child
          as={Fragment}
          enter="duration-700"
          enterFrom="-translate-y-20 opacity-0 sm:-translate-y-20 sm:translate-x-0"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="-translate-y-20 opacity-0 sm:translate-x-0"
        > */}
        <div
          className={
            "inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl relative " +
            extraClass
          }
        >
          <span
            className="absolute modal_close_top top-4 right-4 cursor-pointer"
            onClick={() => setOpen(false)}
          >
            {/* {!bodyText2 && */}
            <XIcon className="h-6 w-6 text-gray-500" />
            {/* } */}
          </span>
          <div className=" flex justify-center modal_centered_image h-16  my-6 w-full">
            {(icon && (
              <img alt="icon" src={`${process.env.PUBLIC_URL}/${icon}`} />
            )) || (
              <img
                alt="extension icon"
                src={`${process.env.PUBLIC_URL}/images/registration/img/extension-icon.svg`}
              />
            )}
          </div>
          <Dialog.Title
            as="h3"
            className="text-lg font-medium modal_centered_title leading-6 text-gray-900 text-center"
          >
            {title}
          </Dialog.Title>
          <div className="mt-2 modal_centered_body_text">
            {bodyText2 && (
              <p className="text-sm text-gray-500 text-center setupPinText">
                {bodyText}{" "}
                <img
                  className="setupPin__logo"
                  src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                />{" "}
                {bodyText2}
              </p>
            )}
            {!bodyText2 && (
              <p className="text-sm text-gray-500 text-center">{bodyText}</p>
            )}
            {bodyTextChrome && (
              <p className="text-sm text-gray-500 text-center install_text_under_heading">
                {bodyTextChrome}
              </p>
            )}
          </div>

          <div className="mt-4 install_chrome_footer">
            <Button
              type="button"
              text={callToActionText}
              fullwidth
              onClick={
                callToAction
                  ? () => {
                      setOpen(false);
                      callToAction();
                    }
                  : () => setOpen(false)
              }
            />
          </div>
        </div>
        {/* </Transition.Child> */}
      </div>
    </Dialog>
    // </Transition>
  );
};

export default Modal;
