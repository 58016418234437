/* eslint-disable react-hooks/exhaustive-deps */
import PasswordInput from "../PasswordInput/PasswordInput";

import PrimaryBtn from "../../common/PrimaryBtn/PrimaryBtn";
import React, { useState, useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import {
  useUser,
  useRecaptcha,
  // isValidEmail,
} from "../../../helpers/UserProvider";
import {
  generatePDKey,
  createPDKeyHash,
  encryptEncryptionKey,
  hashPassword,
} from "../../../helpers/vaultHelpers";

import { getSafleName } from "../../../helpers/vaultHelpers";
import SettingsChangePasswordProcess from "./../../common/SettingsChangePasswordProcess/SettingsChangePasswordProcess";
import {
  CHANGE_PASSWORD_FLOW,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import Storage from "../../../helpers/Storage";

import {
  // UserIcon,
  // ArrowNarrowRightIcon,
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon,
  // ArrowRightIcon,
} from "@heroicons/react/solid";
// import { delay_code } from "../../../helpers/generalHelper";

const ChangePasswordContent = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModal, goBackwardN] =
    useModalNavigation(CHANGE_PASSWORD_FLOW);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setData] = useModalData();

  const recaptchaRef = useRecaptcha();
  const { actions } = useApp();
  const { userData, userActions } = useUser();

  const [passCurrent, setPassCurrent] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [passCurrentVal, setPassCurrentVal] = useState("");
  const [passCurrentErrMsg, setPassCurrentErrMsg] = useState();
  const [passCurrentShown, setPassCurrentShown] = useState();

  const [passChangePass, setPassChangePass] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [passChangePassVal, setPassChangePassVal] = useState("");
  const [passChangePassErrMsg, setPassChangeErrMsg] = useState();
  const [passChangePassShown, setPassChangePassShown] = useState();

  const [passChangeConfirmPass, setPassChangeConfirmPass] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [passChangeConfirmPassVal, setPassChangeConfirmPassVal] = useState("");
  const [passChangePassConfirmErrMsg, setPassChangeConfirmErrMsg] = useState();
  const [passChangePassConfirmShown, setPassChangePassConfirmShown] =
    useState();

  const [checkOneLowerChar, setCheckOneLowerChar] = useState(false);
  const [checkOneUpperChar, setCheckOneUpperChar] = useState(false);
  const [checkOneNumber, setCheckOneNumber] = useState(false);
  const [checkMinLength, setCheckMinLength] = useState(false);

  const [isValid1, setIsValid1] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [isValid3, setIsValid3] = useState(false);

  const storageData = Storage.load("user");

  // const storageDataName = storageData.safleID;
  const storageDataToken = storageData.token;
  const storageDataVault = storageData.vault;

  const min_pass_lenght = 7;

  // const [disabledBtn, setDisabledBtn] = useState(false);
  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModal();
  }, []);

  useEffect(() => {
    inputsValid();
  }, [passCurrent, passChangePass, passChangeConfirmPass]);

  useEffect(() => {
    disabledBtn();
  }, [passCurrentErrMsg, passChangePassErrMsg, passChangePassConfirmErrMsg]);

  const setUpdatePassCurrent = (val) => {
    setPassCurrent(val);
    setPassCurrentVal(val);

    if (val) {
      setIsValid1(true);
    } else {
      setIsValid1(false);
    }
  };
  const setUpdatePassChange = (val) => {
    //

    let value = val;

    if (/[a-z]/.test(value)) setCheckOneLowerChar(true);
    else setCheckOneLowerChar(false);

    if (/[A-Z]/.test(value)) setCheckOneUpperChar(true);
    else setCheckOneUpperChar(false);

    if (/\d/.test(value)) setCheckOneNumber(true);
    else setCheckOneNumber(false);

    if (value.length > min_pass_lenght) {
      setCheckMinLength(true);
    } else {
      setCheckMinLength(false);
    }
    setPassChangePass(value);
    setPassChangePassVal(value);
  };
  const setUpdatePassChangeConfirm = (val) => {
    setPassChangeConfirmPass(val);
    setPassChangeConfirmPassVal(val);
  };

  useEffect(() => {
    if (
      passChangePass.length !== 0 &&
      checkOneLowerChar &&
      checkOneUpperChar &&
      checkOneNumber &&
      checkMinLength
    ) {
      setPassChangeErrMsg("");
      setIsValid2(true);
    } else {
      setIsValid2(false);
      if (passChangePass.length !== 0) {
        setPassChangeErrMsg(
          "Password should contain One lowercase character , one uppercase character, one number and 8 characters minimum"
        );
      }
    }
  }, [checkOneLowerChar, checkOneUpperChar, checkOneNumber, checkMinLength]);

  const inputsValid = () => {
    // setPassCurrentErrMsg('Error Password Input Current');
    // setPassChangeErrMsg('Error Password Input New');
    // setPassChangeConfirmErrMsg('Error Password Input Confirm');
    if (passChangePass !== passChangeConfirmPass && passChangeConfirmPass) {
      setPassChangeConfirmErrMsg(
        "Confirm Password dosen't match the New Password"
      );
      setIsValid3(false);
    } else {
      setPassChangeConfirmErrMsg("");
      if (passChangeConfirmPass) {
        setIsValid3(true);
      } else {
        setIsValid3(false);
      }
    }
    if (!passChangePass) {
      setPassChangeErrMsg("");
    }
  };

  const disabledBtn = () => {};

  const resetValues = (e = false) => {
    //reset values inputs
    e && e.preventDefault();
    setPassCurrent("");
    setPassChangePass("");
    setPassChangeConfirmPass("");
    //reset valids
    setIsValid1(false);
    setIsValid2(false);
    setIsValid3(false);
    //reset errors
    setPassChangeConfirmErrMsg(false);
    setPassChangeErrMsg(false);
    setPassCurrentErrMsg(false);
  };

  const confirmBtn = async (e) => {
    // actions.setLoading(true);
    const safleName = await getSafleName(storageDataToken);
    const safleId = safleName.safleId;
    const safleEmail = safleName.email;

    // otp, email, action = change-password , safleId , newPDKeyHash, oldPDKeyHash, hashedPassword, vault, encryptedEncryptionKey

    const oldPDK = await generatePDKey({
      safleID: safleId,
      password: passCurrent,
    });
    const oldPDKHash = await createPDKeyHash({ passwordDerivedKey: oldPDK });

    const newPDK = await generatePDKey({
      safleID: safleId,
      password: passChangePass,
    });
    const newPDKHash = await createPDKeyHash({ passwordDerivedKey: newPDK });

    const hashedPassword = await hashPassword({
      password: passChangePass,
      passwordDerivedKey: newPDK,
    });
    let encryptedEncryptionKey = await encryptEncryptionKey({
      passwordDerivedKey: newPDK,
      encryptionKey: userActions.getDecriptionKey(),
    });

    setData({
      type: "change_pass",
      email: safleEmail,
      action: "change-password",
      safleId: safleId,
      passNew: passChangePass,
      passCurrent: passCurrent,
      oldPDKHash: oldPDKHash,
      oldPDK: oldPDK,
      newPDKHash: newPDKHash,
      newPDK: newPDK,
      hashedPassword: hashedPassword,
      enk: encryptedEncryptionKey,
      vault: storageDataVault,
      token: storageDataToken,
    });
    //
    const tokenRec = await recaptchaRef.current.executeAsync();

    try {
      const respClick = await userActions.login(safleId, passCurrent, tokenRec);

      if (respClick?.statusCode === 201) {
        setPassCurrentErrMsg("");
        await userActions.changePasswordRequest(userData.user.token);
        setActiveModal("modal_change_password_enter_pin");
      }
    } catch (e) {
      setPassCurrentErrMsg("Incorect Password");
    }

    actions.setLoading(false);
    // // const request_change_pass = await userActions.validatePin(pinCurrent);
    // const request_change_pass = '';
    // if(request_change_pass?.error){
    //   //show error
    //   setPassCurrentErrMsg('Entered password is incorrect.')
    // }else{
    //   //continue
    //   // await userActions.resendEmailOTP(safleName.email);
    //   // setActiveModal('modal_change_password_enter_pin');
    // }
    // // setData({ new_email: tempUserEmail });
  };

  const cancelChange = (e) => {
    resetValues();
  };

  return (
    <div
      className="settings__overview_inner_content bs-0"
      data-show="change_pass"
    >
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_change_pin">
          <form
            className="form-default form-default-pass"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="form__input form_heading">Change Password</div>
            <PasswordInput
              label="Password"
              placeholder="Current Password"
              type={passCurrentShown ? "text" : "password"}
              // maxLength='6'
              // minLength='0'
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPassCurrentShown(true)}
                  className={
                    passCurrentShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPassCurrentShown(false)}
                  className={
                    !passCurrentShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={passCurrent}
              // valid={validPassword}
              setValue={setUpdatePassCurrent}
              // onChange={handleCurrentPin}
              errorMsg={passCurrentErrMsg}
            />

            <PasswordInput
              label="New Password"
              placeholder="Enter New Password"
              type={passChangePassShown ? "text" : "password"}
              // maxLength='6'
              // minLength='6'
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPassChangePassShown(true)}
                  className={
                    passChangePassShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPassChangePassShown(false)}
                  className={
                    !passChangePassShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={passChangePass}
              setValue={setUpdatePassChange}
              // valid={validPassword}
              // onChange={handleChangeNewPin}
              errorMsg={passChangePassErrMsg}
            />

            <PasswordInput
              label="Confirm New Password"
              placeholder="Confirm New Password"
              type={passChangePassConfirmShown ? "text" : "password"}
              // maxLength='6'
              // minLength='6'
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPassChangePassConfirmShown(true)}
                  className={
                    passChangePassConfirmShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPassChangePassConfirmShown(false)}
                  className={
                    !passChangePassConfirmShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={passChangeConfirmPass}
              setValue={setUpdatePassChangeConfirm}
              // valid={validPassword}
              // onChange={handleChangePinConfirm}
              errorMsg={passChangePassConfirmErrMsg}
            />

            <div className="form__input form__input_actions">
              <PrimaryBtn
                label={"Cancel"}
                classes={
                  "btn-default btn-white-with-border margin-left-0 order_1"
                }
                // onClick={onCancelHandle}
                onClick={resetValues}
              />
              <PrimaryBtn
                label={"Confirm"}
                classes={
                  "btn-default btn-blue margin-right-0 checkDisabled order_2"
                }
                onClick={confirmBtn}
                disabled={!(isValid1 && isValid2 && isValid3)}
              />
            </div>
          </form>
        </div>
      </div>
      <SettingsChangePasswordProcess
        onCompletProccess={cancelChange}
        onCancelProccess={cancelChange}
      />
    </div>
  );
};

export default ChangePasswordContent;
