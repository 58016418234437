import React from 'react';

class PrintedSeedPhraseModel extends React.Component {
    render() {
        return <>
            <div style={{ padding: 80, width: '100%', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <h1 style={{fontSize: 42, fontWeight: 600, marginBottom: 60 }}>Safle Vault seeds</h1>
               
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h2 style={{ fontSize: 38, display: 'flex', maxWidth: 1001, flexWrap: 'wrap' }}>{this.props?.seedPhrase?.map( (el, idx) => {
                        return (
                            <div key={idx} style={{ width: 250, display: 'flex', textAlign: 'center', lineHeight: 2 }}>
                                {`${idx+1}. ${el}`}
                            </div>
                        )
                    })}</h2>
                </div>
            </div>            
        
        </>;
    }
};

export default PrintedSeedPhraseModel;