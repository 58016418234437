import React from 'react';
import MainModal from '../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalActionFailed = ( { callbackOpenPopup, callbackRetryPopup, onClose, title, desc, dataClass, primaryBtnText = "Ok", secBtnText = "Retry" , onRetry = ''} ) => {

    return (
        <MainModal
        onClose={onClose}
        extraClasses={['modal_w440', 'modal_dflex', 'modal_action', 'modal_action_failed']} dataClass={dataClass}>

            <div className="modal_success_content">
                <ModalHeadIcon img="images/warning-black-24-dp.svg" />
                <div className="modal_title">{ title }</div>
                <div className="modal_desc">{ desc }</div>

                {/* <div className="modal_check_eth" >
                    Check on blockchain explorer&nbsp;&nbsp; <span className="icon-icon_eth_scan"></span>
                </div> */}
            </div>
            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                <button className="btn-default btn-white-bordered close_modal_cancel"
                    onClick={() => onClose()}
                >Ok</button>
                <button className="btn-default btn-blue "
                    // onClick={() => props.callbackOpenPopup(props.modalNext_label)}
                    onClick={callbackRetryPopup}
                   
                >Retry</button>
                    
                </div>
            </div>

        </MainModal>
    );
};

export default ModalActionFailed;
