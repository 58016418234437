import SettingsMenuItem from "../SettingsMenuItem/SettingsMenuItem";

const SettingsMenuMobile = ( { dataMenuTop, dataMenuBottom } ) => {

  return (
    <div className="settings__overview_aside-menu mobile_view">
      <div className="settings__overview_aside-menu-fake modal__trigger" data-target="modal_settings_menu">
        <div className="settings__overview_aside-menu-item active" data-goto="manage_safle">
          <span className="aside-menu-ico iconm-safle-icon" />
          <span className="aside-menu-label">Manage Safle ID</span>
        </div>
        <span className="fake-drp-arrow icon-chevron-down-solid-1" />
      </div>
      {/* The Modal */ }
      <div className="modal__main modal" data-class="settings_mobile_menu" data-modal="modal_settings_menu" style={ { display: 'none' } }>
        {/* Modal content */ }
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-header-heading">
              <span className="modal__close"><span className="icon-close" /></span>
            </div>
          </div>
          <div className="modal-body">
            <div className="settings__overview_aside-menu-wrp">
              {
                dataMenuTop.map( ( item, index ) => {
                  return (
                    <SettingsMenuItem
                      key={ index }
                      icon={ item.icon }
                      label={ item.label }
                    />
                  );
                } )
              }
              <div className="settings__overview_aside-menu-item-divider" />
              {
                dataMenuBottom.map( ( item, index ) => {
                  return (
                    <SettingsMenuItem
                      key={ index }
                      icon={ item.icon }
                      label={ item.label }
                    />
                  );
                } )
              }
            </div>
          </div>
          <div className="modal-footer" data-show="false">
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsMenuMobile;