import { useEffect, useState } from "react";

import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
import { delay_code, middleEllipsis } from "./../../../helpers/generalHelper";

// import WalletsListItem from "../WalletsListItem/WalletsListItem";
// import WalletListItemLoader from "../WalletsListItem/WalletsListItemLoader";
// import useModalNavigation from "../../../helpers/useModalNavigation";
// import {
//   EXPORT_WALLET_PRIVATE_KEY_FLOW,
//   QR_CODE_FLOW,
//   EDIT_LABEL_WALLET_FLOW,
// } from "../../../constants/ui";
import TokenIcon from "../../common/TokenIcon/TokenIcon";
import { checkIfWalletLinked } from "../../../helpers/walletHelpers";
import useModalNavigation from "../../../helpers/useModalNavigation";
import { LINK_WITH_SAFLEID } from "../../../constants/ui";
import Storage from "../../../helpers/Storage";
import TooltipCopy from "../../registration/TooltipCopy";

let tm = false;

const WalletsList = () => {
  const { userData, userActions } = useUser();
  const { appData, actions } = useApp();
  const [setModalOnLinkWallet] = useModalNavigation(LINK_WITH_SAFLEID);

  const [showWallets, setShowWallets] = useState(false);
  const [walletCount, setWalletCount] = useState(0);
  const [walletCards, setWalletCards] = useState([]);

  useEffect(() => {
    userActions.getUserById().then((user) => {
      loadWalletList();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData, showWallets, appData?.activeModal?.label]);

  useEffect(() => {
    // actions.setLoading(true, "Loading Vault");
    if (appData?.regStatus === "complete") {
      load_vault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.regStatus]);

  const load_vault = async () => {
    actions.setLoading(true, "Loading");
    await Promise.all([
      // actions.loadChains(),
      // actions.loadSupportedAssets(),
      // actions.loadSupportedCurrencies(),
      // actions.loadActiveCurrency(userData.user.token),
      // actions.loadUsdRate(),
      // userActions.loadVault(),
    ]);
    await delay_code(400);
    const acc = await appData?.accounts;
    const addreses = acc ? Object.values(acc) : {};
    //
    const wallet_changes = await actions.loadWalletCardData(addreses);
    actions.setAppData(wallet_changes);

    actions.setLoading(false);
  };

  useEffect(() => {
    if (appData.wallets && appData.wallets.length && !showWallets) {
      tm && clearTimeout(tm);
      tm = setTimeout(() => {
        setShowWallets(true);
        setWalletCount(appData.wallets.length);
        actions.setLoading(false);
      }, 500);
    }
    if (appData.wallets && appData.wallets.length !== walletCount) {
      setShowWallets(false);
      setWalletCount(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData]);

  const loadWalletList = async () => {
    if (appData.wallets && appData.wallets.length) {
      const filteredWallets = await Promise.all(
        appData.wallets
          .filter((e) => {
            if (appData.activeChain && appData.activeChain !== 0) {
              const curr = appData.chains.find(
                (chain) => chain?.chainId === appData?.activeChain
              );
              return e.chain === curr.chainId;
            }

            return e;
          })
          .map(async (i) => {
            i.buttonState =
              (await checkIfWalletLinked(i, userData?.user?.linkedWallets)) ||
              (i.isSafleCard && i.chain === 137)
                ? "linked"
                : "link";
            return i;
          })
      );

      setWalletCards(filteredWallets);
      //setWalletCards(filteredWallets?.filter(i => i.chain === 1));
    }
  };

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };
  const tooltipText = "";
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  return (
    <div className="assets__overview_content_tab content_wallets active">
      <div className="activity__asset-filter-wrapper">
        <div className="filter_table_body wallets_items_ctn ">
          {showWallets === true &&
            walletCards.map((item, index) => {
              return (
                <div className="wallet" key={index.toString()}>
                  <div className="wallet_label--flex w_40">
                    {/* <img src={item.tokenImg} alt="token icon" /> */}
                    <TokenIcon logo={actions.getChainLogo(item.chain) || ""} />
                    <div className="wallet_name--flex">
                      <div className="wallet_name">{item.label}</div>
                      <div className="wallet_chain">
                        <span>{item.chain_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="wallet_address--flex w_40">
                    <div className="wallet_address">
                      {middleEllipsis(item.address, 4, 4)}
                    </div>
                    <span className="pl-3 pt-2">
                      <TooltipCopy
                        extraClasses="tooltip_registration tool_tip_vault_management cursor-pointer tooltip_webapp_copy"
                        textToDisplay={tooltipText}
                        imageToDisplay={<TooltipIconSvg />}
                        tooltipTextIn={tooltipTextIn}
                        tooltipTextInChange={tooltipTextInChange}
                        copiedText={item.address}
                        itemProps={item.address}
                        hideAfter="3000"
                      />
                    </span>
                  </div>
                  <div className="w_20-right">
                    {(item?.isSafleCard || item.chain === 1) && (
                      <button
                        className={`wallet_link-btn ${item?.buttonState}`}
                        style={{
                          pointerEvents:
                            item?.buttonState === "linked" && "none",
                        }}
                        onClick={() => {
                          if (item?.buttonState === "linked") {
                            return;
                          }
                          Storage.save("selectedWalletForLink", item);
                          setModalOnLinkWallet("link_to_safleid");
                        }}
                      >
                        {item?.buttonState === "linked" ? "Linked" : "Link"}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          {/* {!showWallets &&
            new Array(9)
              .fill(1)
              .map((i, idx) => <WalletListItemLoader key={idx} idx={idx} />)} */}
        </div>
      </div>
    </div>
  );
};

export default WalletsList;
