import React, { useState, useEffect } from 'react';
import TokenIcon from "../../common/TokenIcon/TokenIcon";
// import AssetBadge from "../../common/AssetBadge/AssetBadge";
import {
  // copyToClipboard,
  formatXMinDecimals,
  // middleEllipsis,
} from "./../../../helpers/generalHelper";
// import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
import RecentActivityIcon from "../../common/RecentActivityIcon/RecentActivityIcon";
import RecentActivityStatus from "../../common/RecentActivityStatus/RecentActivityStatus";
import RecentActivityDetails from "../../common/RecentActivityDetails/RecentActivityDetails";
import moment from "moment";
// import Web3 from "web3";
// import { formatMoney, getCoinRate } from "../../../helpers/walletHelpers";
// const w3 = new Web3();
const ActivityHistoryItem = (props) => {
  const item = props.item;

  // const [price, setPrice] = useState(1);
  // const [isToggleActive, setToggleActive] = useState(false);
  const handleToggle = (e) => {
    // setToggleActive(!isToggleActive);
    props.clickHandle();
  };

  // useEffect(() => {
  //   calculatePrice().then((res) => {
  //     setPrice(res);
  //   });
  // }, []);
  // const { userData, userActions } = useUser();
  const { appData, actions } = useApp();
  const [usdtconversion, setUsdtconversion] = useState(0);
  // const calculatingFees = (fee = "", decimals = "") => {
  //   if (fee !== "") {
  //     const feeEth = w3.utils.fromWei(fee, "ether");
  //     //  const fiathFee = formatMoney(actions.activeCurrency(feeEth), decimals);
  //     const symbol = item?.symbol ? item?.symbol : "ETH";
  //     return parseFloat(feeEth).toFixed(5) + " " + symbol;
  //   } else {
  //     return "NA";
  //   }
  // };

  // const calculatePrice = async () => {
  //   const res = await getCoinRate(item.symbol);
  //   let price = res.data.data[item.symbol]?.quote["USD"]?.price || 1;
  //   return price;
  // };

  useEffect(() => {
    getUsDrate();
  }, [])

  const getUsDrate = async () => {
      const usdFeesRes = await actions.getAssetLatestPrice("BTC");
        setUsdtconversion(usdFeesRes.data.BTC.quote.USD.price)
    }

    const calculatePrice =  item?.type === "swap" || item?.type === "receive"
    ? (
        parseFloat(item?.received[0]?.value) /
        Math.pow(10, item?.received[0]?.decimals)
      )
        .toFixed(18)
        .replace(/\.?0+$/, "") < 0.000001
      ? "< 0.000001"
      : (
          parseFloat(item?.received[0]?.value) /
          Math.pow(10, item?.received[0]?.decimals)
        )
          .toFixed(8)
          .replace(/\.?0+$/, "")
    : item?.type === "send"
    ? (
        parseFloat(item?.sent[0]?.value) /
        Math.pow(10, item?.sent[0]?.decimals)
      )
        .toFixed(18)
        .replace(/\.?0+$/, "") < 0.000001
      ? "< 0.000001"
      : (
          parseFloat(item?.sent[0]?.value) /
          Math.pow(10, item?.sent[0]?.decimals)
        )
          .toFixed(8)
          .replace(/\.?0+$/, "")
    : item?.chain === "bitcoin"
    ? (parseFloat(Math.abs(item?.value)) / Math.pow(10, 8))
        .toFixed(8)
        .replace(/\.?0+$/, "")
    : 0
  return (
    <div
      className={
        "assets__overview_content-filtered_items_row " +
        (props.highlight ? "row_visible" : "row_hidden")
      }
      onClick={handleToggle}
    >
      <div className="sticky_mobile">
        <div className="sticky_mobile_rel">
          <div className="aoc_table_col" data-cls="activity_asset_name">
            <div className="asset__coin-image_wrp">
              {item?.type === "swap" || item?.type === "contract-call" ? (
                <div className="flex items-center justify-center">
                  <span>
                    <TokenIcon logo={item?.sent[0]?.logo_url} />
                  </span>
                  <span className="" style={{ marginLeft: -10, zIndex: 10 }}>
                    <TokenIcon logo={item?.received[0]?.logo_url} />
                  </span>
                </div>
              ) : item?.type === "send" ? (
                <TokenIcon logo={item?.sent[0]?.logo_url} />
              ) : item?.type === "receive" ? (
                <TokenIcon logo={item?.received[0]?.logo_url} />
              ) : item?.type === "approve" ? (
                <TokenIcon
                  logo={`https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${item?.sent[0]?.symbol}.png`}
                />
              ) : item?.type === "contract_exection" ? (
                <TokenIcon logo={"/images/nftdefault.png"} />
              ) : (
                <TokenIcon
                  logo={item?.logo ? item?.logo : actions.getNativeToken(1)}
                />
              )}
              {/*<div></div>*/}
              <div className="asset__coin-evaluated_at-row">
                <div className="asset__coin-asset-name">
                  {item?.type === "swap" || item.type === "contract-call"
                    ? !item?.name?.from && !item?.name?.to
                      ? "NA"
                      : `${item?.name?.from} to ${item?.name?.to}`
                    : item?.name}
                </div>
                {/* <AssetBadge coinPrefix={ 
                                    // actions.getNativeToken(1) 
                                    item.symbol
                                } /> */}
              </div>
            </div>
          </div>
          {/* <div className='aoc_table_col' data-cls='activity_asset_hidden_details'>
                       
                        <RecentActivityDetails 
                        type={'partner'} 
                        address={'0x29a86cdd454d6f98c7523d41eaa00c678ca93439'}
                        explorerLink={''}
                            />
                        
                    </div> */}
        </div>
      </div>
      <div className="scrollable_mobile">
        <div className="oac_table_col_visible">
          <div className="aoc_table_col" data-cls="activity_asset_name">
            <div className="asset__coin-image_wrp">
              {item?.type === "swap" ? (
                <div className="flex items-center justify-center">
                  <span>
                    <TokenIcon logo={item?.sent[0]?.logo_url} />
                  </span>
                  <span className="" style={{ marginLeft: -10, zIndex: 10 }}>
                    <TokenIcon logo={item?.received[0]?.logo_url} />
                  </span>
                </div>
              ) : item?.type === "send" ? (
                <TokenIcon logo={item?.sent[0]?.logo_url} />
              ) : item?.type === "receive" ? (
                <TokenIcon logo={item?.received[0]?.logo_url} />
              ) : item?.type === "approve" ? (
                <TokenIcon
                  logo={`https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${item?.sent[0]?.symbol}.png`}
                />
              ) : item?.type === "contract_execution" ? (
                <TokenIcon logo={"/images/currency/coin.svg"} />
              ) : item?.chain === "bitcoin" ? (
                <TokenIcon logo={"images/wallets/currency-bitcoin.svg"} />
              ) : (
                <TokenIcon logo={"/images/currency/coin.svg"} />
              )}
              <div className="asset__coin-evaluated_at-row">
                <div className="asset__coin-asset-name">
                  {item?.type === "swap"
                    ? `${item?.sent[0]?.symbol || "NA"} to ${
                        item?.received[0]?.symbol || "NA"
                      }`
                    : item?.type === "send" || item?.type === "approve"
                    ? item?.sent[0]?.name
                    : item?.type === "receive"
                    ? item?.received[0]?.name
                    : item?.type === "contract_execution"
                    ? "Smart Contract Execution"
                    : item?.chain === "bitcoin"
                    ? "BTC"
                    : item?.name}
                </div>
                {/* <AssetBadge coinPrefix={ 
                                    // actions.getNativeToken(1) 
                                    item.symbol
                                } /> */}
              </div>
            </div>
          </div>
          <div className="aoc_table_col" data-cls="activity_asset_time">
            <div className="asset__coin-asset-time">
              {moment
                .unix(item?.date || item?.timeStamp)
                .format("YYYY-MM-DD | hh:mm:ss") || "-"}
            </div>
          </div>
          <div className="aoc_table_col" data-cls="activity_asset_activity">
            {/* TYPE OF ACTIVITY Buy, Send, Receive,Swapped*/}
            {/*Failed True icons will become red Failed False icons will have default colors */}

            <RecentActivityIcon
              type={
                item?.chain === "bitcoin"
                  ? item?.value > 0
                    ? "receive"
                    : "send"
                  : item?.type
              }
              failed={
                item?.status === "completed" || item?.status === "success"
                  ? false
                  : true
              }
            />
          </div>
          <div className="aoc_table_col" data-cls="activity_asset_fees">
            <div className="activity_asset_price">
              {calculatePrice}{" "}

              <br />
              {`(${actions.getActiveCurrencySymbol()}${
                isNaN(
                  parseFloat(
                    actions
                      .activeCurrency(
                        item?.type === "swap" || item?.type === "receive"
                          ? (parseFloat(item?.received[0]?.value) /
                              Math.pow(10, item?.received[0]?.decimals)) *
                              parseFloat(item?.received[0]?.quoteRate)
                          : item?.type === "send" || item?.type === "approve"
                          ? (parseFloat(item?.sent[0]?.value) /
                              Math.pow(10, item?.sent[0]?.decimals)) *
                            parseFloat(item?.sent[0]?.quoteRate)
                          : item.chain === "bitcoin"
                          ? (parseFloat(Math.abs(parseFloat(item?.value))) / Math.pow(10, 8)) * usdtconversion
                          : 0
                        
                      )
                      ?.toFixed(8)
                  )
                )
                  ? 0
                  : actions
                      .activeCurrency(
                        item?.type === "swap" || item?.type === "receive"
                          ? (parseFloat(item?.received[0]?.value) /
                              Math.pow(10, item?.received[0]?.decimals)) *
                              parseFloat(item?.received[0]?.quoteRate)
                          : item?.type === "send" || item?.type === "approve"
                          ? (parseFloat(item?.sent[0]?.value) /
                              Math.pow(10, item?.sent[0]?.decimals)) *
                            parseFloat(item?.sent[0]?.quoteRate)
                            : item.chain === "bitcoin"
                            ? (parseFloat(Math.abs(item?.value)) / Math.pow(10, 8)) * usdtconversion
                            : 0
                      )
                      ?.toFixed(8)
                      .replace(/\.?0+$/, "")
              })`}
              {/*{item?.amount ? ( w3.utils.fromWei( item?.amount.toString(), 'ether' )) : "NA"}*/}
            </div>
            {/*<div className="activity_asset_price">0.077 ($29.57)</div>*/}
          </div>
          <div className="aoc_table_col" data-cls="activity_asset_amount">
            <div className="activity_asset_price">
                        {item.fee
                          ? item?.chain === "bitcoin"
                          ? (parseFloat(item?.fee) / Math.pow(10, 8)).toFixed(10)
                          : (parseFloat(item?.fee) / Math.pow(10, 18)).toFixed(10)
                          : "NA"}{" "}
                        {item?.chain === "eth"
                          ? "ETH"
                          : item?.chain === "bitcoin"
                          ? "BTC"
                          : appData?.chains?.filter(
                              (chain) => chain?.chain_name === item?.chain
                            )[0]?.details?.symbol}
                        <br />{" "}
                        {item.fee &&  item?.chain !== "velas" ? (
                      `(${actions.getActiveCurrencySymbol()}${actions.activeCurrency(
                        item?.chain === "eth"
                          ? parseFloat(item?.fee / Math.pow(10, 18)) *
                              parseFloat(
                                appData?.priceDataCoins?.find((data) => data?.symbol === "ETH")?.price
                              )
                          : item?.chain === "bitcoin"
                          ? usdtconversion * parseFloat(item?.fee / Math.pow(10, 8))
                          : parseFloat(
                              item?.fee / Math.pow(10, 18)) *
                              parseFloat(
                                appData?.priceDataCoins?.find(
                                  (data) =>
                                    data?.symbol ===
                                    appData?.chains?.filter(
                                      (chain) => chain?.chain_name === item?.chain
                                    )[0]?.details?.symbol
                                )?.price
                              )
                      )?.toFixed(3)})`
                    ) : ""
                  }

              {/*{item?.type === 'send' ? item?.real_fees.toFixed(5) : ''}*/}
              {/*(item?.type === 'send' || item?.type === 'receive') ? `($${item?.real_fees})` : */}
              {/* {item?.type === "send" || item?.type === "receive"
                ? `(${actions.getActiveCurrencySymbol()}${item?.real_fees})`
                : item?.real_fees ||
                  ((item?.type === "BUY" || item?.type === "SELL") &&
                    item?.fees)
                ? `
                (${actions.getActiveCurrencySymbol()}${formatXMinDecimals(
                    actions.activeCurrency(
                      (item.type === "swap"
                        ? item?.real_fees
                        : item?.type === "BUY" || item?.type === "SELL"
                        ? item?.fees + " " + item?.fiatValue
                        : item?.real_fees) * price
                    ),
                    4,
                    8
                  )})`
                : ""} */}
              {/*{item?.real_fees || ((item?.type === "BUY" || item?.type === "SELL")&& item?.fees) ?`
                (${actions.getActiveCurrencySymbol()}${formatXMinDecimals(actions.activeCurrency((item.type === "swap"
                ? item?.real_fees
                : item?.type === "BUY" || item?.type === "SELL"
                ? item?.fees + " " + item?.fiatValue
                : item?.real_fees) * price),4,8)})` : ''}*/}
            </div>
          </div>

          <div className="aoc_table_col" data-cls="activity_asset_status">
            <RecentActivityStatus
              failed={
                item?.status === "completed" ||
                item?.status === "success" ||
                item?.chain === "bitcoin"
                  ? false
                  : true
              }
            />
          </div>
          <div className="aoc_table_col" data-cls="activity_asset_view_details">
            <span className="icon-chevron-down-solid-1"></span>
          </div>
        </div>

        <div className="oac_table_col_hidden">
          {/* <div className="aoc_table_col" data-cls="activity_asset_name"></div> */}
          <div
            className="aoc_table_col"
            data-cls="activity_asset_hidden_details"
          >
            {/*
                        default
                        partner
                        */}
            <RecentActivityDetails
              type={
                item?.type === "swap"
                  ? "swap"
                  : item?.type === "BUY" || item?.type === "SELL"
                  ? "partner"
                  : item?.type === "receive" || item?.type === "send"
                  ? "default"
                  : item?.chain === "bitcoin"
                  ? "bitcoin"
                  : "contract-call"
              }
              txType={
                item?.chain === "bitcoin"
                  ? item?.value > 0
                    ? "receive"
                    : "send"
                  : item?.type
              }
              // type={'partner'}
              address={item?.chain === "bitcoin" ? "" : item?.to}
              explorerLink={
                item?.chain === "bitcoin"
                  ? "https://btcscan.org/tx/" + item?.hash
                  : appData.chains.find((chain) =>
                      chain.chain_name === "ethereum"
                        ? item?.chain === "eth"
                        : item?.chain === chain.chain_name
                    )?.details?.explorer +
                    "/tx/" +
                    item?.id
              }
              swapDetails={item?.type === "swap" && item}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityHistoryItem;
