import SettingsMenuDesktop from "../SettingsMenuDesktop/SettingsMenuDesktop";
import SettingsMenuMobile from "../SettingsMenuMobile/SettingsMenuMobile";

const SettingsMenu = ({ onPageChange, currentPage }) => {
  const dataMenuTop = [
    {
      icon: "icon-settings-safle-logo",
      label: "Manage Safle ID",
      path: "id-content",
    },
    {
      icon: "icon-settings-change-pass",
      label: "Change Password",
      path: "change-pass-content",
    },
    {
      icon: "icon-settings-icon-change-pin",
      label: "Change PIN",
      path: "change-pin-content",
    },
    // {
    //   icon: 'icon-settings-manage-vault',
    //   label: 'Manage Vault',
    //   path: 'manage-vault-content'
    // },
    {
      icon: "icon-settings-currency",
      label: "Currency",
      path: "currency-content",
    },
    {
      icon: "icon-settings-guardian-setup",
      label: "Guardian Setup",
      path: "guardian-setup-content",
    },
  ];
  const dataMenuBottom = [
    {
      icon: "icon-settings-contact-support",
      label: "Contact Support",
      path: "contact-support-content",
    },
    {
      icon: "icon-settings-terms",
      label: "Privacy and T&C",
      path: "privacy-content",
    },
  ];
  return (
    <div className="settings__overview_aside_content">
      <SettingsMenuMobile
        dataMenuTop={dataMenuTop}
        dataMenuBottom={dataMenuBottom}
      />
      <SettingsMenuDesktop
        dataMenuTop={dataMenuTop}
        dataMenuBottom={dataMenuBottom}
        changePage={(page) => onPageChange(page)}
        getCurrentPage={currentPage}
      />
    </div>
  );
};

export default SettingsMenu;
