import React from "react";
import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
import "./../../../styles/registration/popupMessage.scss";
// import helpers/users
import CssFix from "./../../../helpers/cssFix";
const PopupMessageVault = ({ history, saffleId, title, onClose }) => {
  const { userActions } = useUser();
  const { actions } = useApp();

  const logoutUser = async () => {
    await actions.logout();
    await userActions.logout();
  };

  return (
    <div
      className="coming_soom"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "54vw",
        height: "70vh",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%,-50%)",
        top: "50%",
        zIndex: 10000,
        background: "rgba(255,255,255,1)",
        borderRadius: 15,
        padding: "40px 100px",
        maxWidth: 1170,
        maxHeight: 904,
      }}
    >
      <div className="innerFixFixed">
        <CssFix />

        <img
          style={{
            position: "absolute",
            width: "15.7vw",
            height: "auto",
            right: "-5vw",
            bottom: "5vw",
            maxWidth: 238,
          }}
          alt="rocket"
          src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
        />
        <div className="flex flex-row w-[100%] justify-end items-center mt-[10px] py-[10px]">
          <div
            className=" flex flex-grow justify-center items-center"
            style={{ alignSelf: "center", right: "70px", top: "50px" }}
          >
            <img
              style={{ width: "72px", height: "30px", marginBottom: "0px" }}
              alt="safle logo"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
            />
          </div>
          <div
            className="popup_logout"
            style={{
              position: "absolute",
              right: "30px",
              top: "20px",
              cursor: "pointer",
              color: "#0d203e",
              fontWeight: "600",
            }}
            onClick={
              process.env.REACT_APP_ALLOW_BETA_ACCESS === "allowed"
                ? onClose
                : logoutUser
            }
          >
            {process.env.REACT_APP_ALLOW_BETA_ACCESS === "allowed" && (
              <span className="icon_pop_msg_close icon-close"></span>
            )}
            {process.env.REACT_APP_ALLOW_BETA_ACCESS !== "allowed" && (
              <span style={{ fontSize: "14px" }}>Logout</span>
            )}
          </div>
        </div>
        <div
          style={{
            margin: "auto 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="popup_text fadein"
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // wordBreak: "break-all",
            }}
          >
            <span
              className="popup_safleid"
              style={{
                fontSize: "48px",
                fontWeight: "600",
                backgroundColor: "#fff",
                backgroundImage:
                  "linear-gradient(45deg, #32c5ff, #b620e0, #f7b500)",
                backgroundSize: "100%",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {saffleId}
            </span>
            <h3
              className="popup_title"
              style={{
                fontSize: "28px",
                fontWeight: "600",
                color: "#0D203E",
                letterSpacing: "-0.3px",
                marginBottom: "10px",
              }}
            >
              {title}
            </h3>
            <div className="popup_message">
              <p>
                We've noticed that your Safle Vault is currently saved on your
                mobile device. Due to security considerations, it's not possible
                to transfer it to the Safle Web App at the moment.
              </p>
              <br />
              <p>
                We appreciate your patience, we will soon enable this for you.
              </p>
              {/* <div className="title">
                What’s new <span></span>
              </div> */}
              {/* <ul>
                <li>
                  Handy dashboard to get a bird's-eye view or dive deep into
                  your portfolio.
                </li>
                <li>Manage you asset watch list.</li>
                <li>Send and Receive tokens in Ethereum, Polygon.</li>
              </ul> */}
              {/* <div className="popup_networks">
                <span className="flex flex-wrap items-center">
                  Supported Networks:
                  <div className="flex flex-wrap">
                    <span className="network_item ml-3">
                      <img src="images/swap/eth-chain.svg" alt="chain icon" />
                      <span>Ethereum</span>
                    </span>
                    <span className="network_item ml-3">
                      <img
                        src="images/swap/polygon-chain.svg"
                        alt="chain icon"
                      />
                      <span>Polygon</span>
                    </span>
                    <span className="network_item ml-3 disabled">
                      <img
                        src="images/migration/cryptoIcons/color/btc.svg"
                        alt="chain icon"
                      />
                      <span>Bitcoin</span>
                    </span>
                    <span className="network_item ml-3 disabled">
                      <img
                        src="images/settings/binance-wallet.svg"
                        alt="chain icon"
                      />
                      <span>BSC</span>
                    </span>
                  </div>
                </span>
              </div> */}
            </div>
          </div>
          {/* <div
            className="fadein social-icons"
            style={{
              marginTop: "20px",
              width: "50%",
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <a
              href={`${process.env.REACT_APP_LINK_TWITTER}`}
              style={{ margin: "0 5px" }}
              target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px" }}
                alt="Twitter logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/twitter-icon.svg`}
              />
            </a>
            <a
              href={`${process.env.REACT_APP_LINK_MEDIUM}`}
              style={{ margin: "0 5px" }}
              target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px" }}
                alt="Medium logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/medium-icon.svg`}
              />
            </a>
            <a
              href={`${process.env.REACT_APP_LINK_DISCORD}`}
              style={{ margin: "0 5px" }}
              target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px" }}
                alt="Discord logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/discord-icon.svg`}
              />
            </a>
            <a
              href={`${process.env.REACT_APP_LINK_INSTAGRAM}`}
              style={{ margin: "0 5px" }}
              target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px" }}
                alt="Instagram logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/instagram-icon.svg`}
              />
            </a>
            <a
              href={`${process.env.REACT_APP_LINK_TELEGRAM}`}
              style={{ margin: "0 5px" }}
              target="_blank"
            >
              <img
                style={{ width: "20px", height: "20px" }}
                alt="Telegram logo"
                src={`${process.env.PUBLIC_URL}/images/registration/icons/telegram-icon.svg`}
              />
            </a>
          </div> */}

          {/* <a
            className="get-safle-btn fadein inline-flex px-4 py-2 text-base justify-center  items-center border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none"
            style={{ marginTop: "60px" }}
            href={`${process.env.REACT_APP_EXTENSION_LINK}`}
            target="_blank"
          >
            <img
              style={{ width: "20px", height: "20px", marginRight: "12px" }}
              alt="Medium logo"
              src={`${process.env.PUBLIC_URL}/images/registration/icons/safle-logo-initials.svg`}
            />
            Get Safle Extension
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default PopupMessageVault;
