import React from "react";
import MainModal from "./../generic/MainModal";
import WalletsOptionsList from "../common/WalletsOptionsList/WalletsOptionsList";

const ModalImportWallet = (props) => {
  // onClick={() => props.callbackOpenPopup('modal_import_wallet')}
  //
  // callbackCancelPopup

  return (
    <MainModal
      title="Select wallet type"
      // on close - display conformation first
      onClose={() =>
        props.callbackCancelGeneratePopup("modal_cancel_import_wallet")
      }
      extraClasses={["modal_w440", "modal_import_generate_wallet"]}
      dataClass="modal_import_wallet"
    >
      {/* Modal content */}
      <WalletsOptionsList callbackOpenPopup={props.callbackOpenPopup} />
    </MainModal>
  );
};

export default ModalImportWallet;
