import { useLocation } from "react-router-dom";
import ROUTES from "../../../../helpers/routes";
import CurrencySelector from "../CurrencySelector/CurrencySelector";
// import GasSelector from "../GasSelector/GasSelector";
import NetworkSelector from "../NetworkSelector/NetworkSelector";
import NotificationBadge from "../NotificationBadge/NotificationBadge";
import { useApp } from "../../../../helpers/AppProvider";
import { useUser } from "../../../../helpers/UserProvider";
import ModalEnterPin from "../../../../components/dialogs/ModalEnterPin";
import { useEffect, useState } from "react";
import { delay_code } from "../../../../helpers/generalHelper";

const ActionContainer = () => {
  const location = useLocation();
  const { appData, actions } = useApp();
  const { userActions, userData } = useUser();
  const [pin, setPin] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [pinValidated, setPinValidated] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successToast, setSuccessToast] = useState("");

  useEffect(() => {
    if (
      appData.regStatus === "not_initiated" ||
      appData.regStatus === "error"
    ) {
      setSuccessToast("");
      setOpenModal(true);
    }
    if (appData.regStatus === "pending") {
      setSuccessToast("waiting");
    }
    if (appData.regStatus === "complete" && successToast === "waiting") {
      setSuccessToast("done");
    }
  }, [appData.regStatus]);

  useEffect(() => {
    const checkStatusInterval = setInterval(async () => {
      if (successToast === "done") {
        setSuccessToast("");
      }
    }, 5000);

    return () => {
      clearInterval(checkStatusInterval); // Clear the interval when the component unmounts
    };
  }, [successToast]);

  useEffect(() => {
    async function retryRegistration() {
      if (pin) {
        const authToken = userData?.user?.token;

        const private_Key = (
          await userActions.exportPrivateKey(
            appData?.accounts[0]?.address,
            // enc,
            parseInt(pin),
            authToken
          )
        ).response;

        const trans = await userActions.relayTransaction({
          publicAddress: appData?.accounts[0]?.address,
          privateKey: private_Key?.privateKey,
          authToken: authToken,
        });
        await delay_code(500);
        if (
          trans?.response?.message === "Request successfully added to queue."
        ) {
          actions.setAppData({ regStatus: "pending" });
        }
      }
    }

    retryRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const networkSelectorShowList = [
    ROUTES.ASSETS,
    // ROUTES.WALLETS,
    // ROUTES.ACTIVITY,
    // ROUTES.NFTS,
    // ROUTES.NFTS_DETAIL,
  ];
  return (
    <div className="main__header__actions">
      {appData?.regStatus === "not_initiated" ||
      appData?.regStatus === "error" ? (
        <div className="flex flex-row items-center mr-[20px] border-[1px] border-[#F86565] py-[5px] px-[10px] bg-[#F8656533] rounded-md">
          <div className="mr-[10px]">
            <img
              className="h-[25px]"
              alt=""
              src={`${process.env.PUBLIC_URL}/images/swap/icon-error.svg`}
            />
          </div>
          <div className="text-[#F86565]">
            Your SafleID registration failed,{" "}
            <span
              className="text-[#0669F8]"
              onClick={() => {
                setOpenModal(true);
              }} //open pin popup
            >
              click to retry
            </span>
          </div>
        </div>
      ) : null}
      {appData?.regStatus === "pending" ? (
        <div className="flex flex-row items-center mr-[20px] border-[1px] border-[#EF982E] py-[5px] px-[10px] bg-[#EF982E33] rounded-md">
          <div className="mr-[10px]">
            <img
              className="h-[25px]"
              alt=""
              src={`${process.env.PUBLIC_URL}/images/registration/img/Icon_Pending.svg`}
            />
          </div>
          <div className="text-[#EF982E]">
            Your SafleID registration is in progress.
          </div>
        </div>
      ) : null}

      {appData?.regStatus === "complete" && successToast === "done" ? (
        <div className="flex flex-row items-center mr-[20px] border-[1px] border-[#00D192] py-[5px] px-[10px] bg-[#00D19233] rounded-md">
          <div className="mr-[10px]">
            <img
              className="h-[25px]"
              alt=""
              src={`${process.env.PUBLIC_URL}/images/registration/img/ok-icon.svg`}
            />
          </div>
          <div className="text-[#00D192]">
            Your SafleID is registered succesfully.
          </div>
        </div>
      ) : null}

      {networkSelectorShowList.includes(location.pathname) ? (
        <NetworkSelector />
      ) : null}
      {/* <GasSelector /> */}
      <CurrencySelector />
      <NotificationBadge />
      {openModal && (
        <ModalEnterPin
          onClose={() => setOpenModal(false)}
          callbackOpenPopup={(val) => {
            setPin(val);
            setPinValidated(true);
            setOpenModal(false);
            // getAcc(val);
          }}
          callbackCancelPopup={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default ActionContainer;
