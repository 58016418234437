import React from "react";
import MainModal from "../generic/MainModal";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import InputGroup from "../settings/InputGroup/InputGroup";

const ModalEditEmailMobile = ({
  onClose,
  dataClass,
  tempUserEmail,
  setTempUserEmail,
  confirmChangeEmail,
  setEditUserEmailMobile,
  primaryBtnText = "Cancel",
  secBtnText = "Confirm",
  goBackward,
}) => {
  //
  const onChange = (e) => {
    const value = e.target.value;
    setTempUserEmail(value);
  };
  return (
    <MainModal
      // onClose={onClose}
      onClose={() => setEditUserEmailMobile(false)}
      extraClasses={[
        "modal_w400",
        "modal_dflex",
        "modal_settings_edit_user_mobile",
      ]}
      dataClass={dataClass}
    >
      <div className="modal_title">Edit your email</div>

      <div className="input_ctn">
        <div className="input_label">New Email</div>
        <InputGroup
          value={tempUserEmail}
          onChange={(e) => onChange(e)}
          name={"settings_change_email_mobile"}
          placeholder="Enter New Email"
        />
      </div>

      <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
        <PrimaryBtn
          classes={"btn-default btn-white-bordered close_modal_cancel"}
          label={primaryBtnText}
          onClick={() => setEditUserEmailMobile(false)}
        />
        <PrimaryBtn
          classes={"btn-default btn-blue modal__trigger"}
          label={secBtnText}
          onClick={() => confirmChangeEmail()}
        />
      </div>
    </MainModal>
  );
};

export default ModalEditEmailMobile;
