import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { UserIcon, ArrowRightIcon } from "@heroicons/react/solid";
import "../../styles/registration/forgotPassword.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import { isValidEmail } from "../../helpers/generalHelper";
import Preloader from "../../components/common/Preloader/Preloader";
import InputGroup from "../../components/registration/InputGroup/InputGroup";
import Button from "../../components/registration/Button/Button";
import Footer from "../../components/registration/PublicFooter/PublicFooter";
import Modal from "../../components/registration/Modal/Modal";
import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import { useApp } from "../../helpers/AppProvider";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useFlowGate } from "../../components/flow/FlowGate";

const ForgotPassword = ({ history }) => {
  const { advance, initFlow } = useFlowGate();
  const { actions } = useApp();
  const { userActions } = useUser();
  const { setOtpStatus } = useRegistration();
  const recaptchaRef = useRecaptcha();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState();
  const [checkValidEmail, setCheckValidEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const slides = [
    {
      title: "Wallets",
      description: "Your SafleID is your metaverse identity",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide2@2x.png`,
    },
    {
      title: "Keyless Transactions",
      description:
        "Sign transactions on the go, with ease using PIN or Biometrics",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide3@2x.png`,
    },
    {
      title: "Blackbox",
      description: "6 layers of encryption to your digital assets",
      image: `${process.env.PUBLIC_URL}/images/registration/img/login-slide1@2x.png`,
    },
  ];
  useEffect(() => {
    initFlow("resetpassword");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    actions.setLoading(true);
    setEmailError(false);

    const emailValid = await userActions.validateSafleEmail(email);

    if (!emailValid?.data?.isRegistered) {
      if (emailValid.hasOwnProperty("data") && !emailValid.data.isRegistered) {
        setEmailError("This is not a registered email address");
      } else if (
        emailValid.hasOwnProperty("details") &&
        emailValid.details[0]?.name === "email"
      ) {
        setEmailError("Please enter a valid email address");
      } else {
        setEmailError(emailValid?.details[0]?.message);
      }
    } else {
      const token = await recaptchaRef.current.executeAsync();
      /* ------------- ------------------ */
      /* forgotPassword( email, recaptchaToken ) - sends pin verification email
            /* -------------------------------- */
      const resp = await userActions.forgotPassword(email, token);

      if (resp.statusCode === 201) {
        const dateNow = Date.now();
        const user_details = await userActions.getSafleUser(email);

        setOtpStatus({
          safleId: user_details.safleId,
          email,
          message: resp.message,
          startedAt: dateNow,
          otpType: "email-confirmation",
        });
        advance();
      } else if (resp.type === "field-validation") {
        setEmailError(resp.details[0].message);
      }
    }
    actions.setLoading(false);
  };

  const validateEmail = (mail) => {
    if (
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        mail
      ) &&
      /^\w+@(\w)+(\w+)?(\.(\w+)){1,2}$/.test(mail) &&
      0 < mail.length < 257
    ) {
      return true;
    }
    return false;
  };

  const handleEmail = (e) => {
    let value = e.target.value;

    if (/\s/.test(value)) {
      setCheckValidEmail(false);
    } else {
      if (isValidEmail(value) && validateEmail(value)) {
        setCheckValidEmail(true);
      } else {
        setCheckValidEmail(false);
      }
      setEmail(value);
    }
  };

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <Preloader />
            <div className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="flex">
                  <div className="w-full lg:w-2/5 lg:mr-12">
                    <div className="mb-8">
                      <img
                        alt="safle logo"
                        className="mb-4 h-8 w-20 hidden sm:block"
                        src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                      />
                      <p className="text-xs text-gray-400 flex items-center fw-500 pt--14">
                        Recovery Password
                        <span className="minus-dash"></span>
                      </p>
                    </div>
                    <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change">
                      Reset your <br /> Password
                    </h1>

                    <div className="hidden lg:flex bg-gray-100 border border-gray-100 rounded-md p-2">
                      <p className="text-xs">
                        Your 12 word seed phrase is required in order to
                        successfully reset your password. Please make sure you
                        have access to your unique seed phrase before
                        proceeding.
                      </p>
                    </div>
                    <div className="mb-5">
                      <InputGroup
                        label="Your Email *"
                        placeholder="Enter Your email"
                        defaultValue={email}
                        valid={checkValidEmail && emailError === false}
                        onKeyUp={handleEmail}
                        onChange={handleEmail}
                        errorMsg={
                          email?.length < 256
                            ? "Email must be valid"
                            : "Email cannot exceed 256 characters."
                        }
                        icon={
                          <UserIcon
                            className="h-5 w-5 text-gray-400 icon_dark_gray"
                            aria-hidden="true"
                          />
                        }
                      />
                      {emailError && (
                        <div className="error_message">{emailError}</div>
                      )}
                      {/* <div className='text-red-600 font-bold text-xs mt-2'>{emailError}</div> */}
                    </div>
                    {checkValidEmail && (
                      <Button
                        type="submit"
                        text="Submit"
                        size="lg"
                        fullwidth
                        onClick={handleSubmit}
                      />
                    )}
                    {!checkValidEmail && (
                      <Button
                        type="submit"
                        text="Submit"
                        size="lg"
                        fullwidth
                        onClick={handleSubmit}
                        disabled={true}
                        style={{ opacity: "0.35" }}
                      />
                    )}
                    <div className="my-6 flex justify-center md:justify-start text-xs mt-6 dont_have_account">
                      <span>Don't have an account?</span>
                      <Link
                        to={ROUTES.SIGNUP}
                        className="flex items-center dont_have_account_link"
                      >
                        Sign Up here
                        <ArrowRightIcon className="h-2 w-2 ml-1" />
                      </Link>
                    </div>
                  </div>

                  <div className="justify-center w-0.5 lg:h-96 lg:my-20 bg-gray-300 mx-10 hidden lg:block sign_up_middle_spacer"></div>
                  <div className="lg:block w-2/5 slider-size-fix lg:ml-12 flex justify-center add_to_chrome">
                    <div className="mb-20 flex justify-end">
                      <span
                        className="flex items-center text-blue-500 font-medium text-xs cursor-pointer"
                        onClick={() => setModalIsOpen(true)}
                      >
                        Add to Chrome
                        <ArrowRightIcon className="h-2 w-2 ml-1" />
                      </span>
                    </div>
                    <div className="w-full mx-auto">
                      <Slider
                        ref={(slider) => slider}
                        {...carouselSettings}
                        className="flex justify-center slick_sl"
                      >
                        {slides.map((slide, idx) => (
                          <div className="" key={idx}>
                            <div className="relative">
                              <img
                                alt="slide-1"
                                src={slide.image}
                                className="mx-auto mb-10 h-60"
                              />
                            </div>
                            <div className="flex flex-col justify-end">
                              <p className="text-2xl font-semibold text-center mb-2 slide_title">
                                {slide.title}
                              </p>
                              <p className="text-center text-sm w-auto slide_desc">
                                {slide.description}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Download"
        callToAction={() => {
          window.open(process.env.REACT_APP_EXTENSION_LINK);
        }}
        isOpen={modalIsOpen}
        extraClass={"install_safle_chrome"}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};

export default ForgotPassword;
