/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import DropdownWalletItem from "../DropdownWalletItem/DropdownWalletItem";
import { useApp } from "./../../../helpers/AppProvider";

//type = wamount / address

const DropdownWallet = (props) => {
  const { actions } = useApp();

  let dropdownType = "";
  let extraClass = "";
  if (props.compType && props.compType !== undefined) {
    dropdownType = props.compType;
  }
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  //populate an object with items
  const dummyAddress = [
    {
      // key : 1,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851964",
      wallet_ammount: "1",
      wallet_prefix: "ETH",
    },
    {
      // key : 2,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851965",
      wallet_ammount: "2",
      wallet_prefix: "ETH",
    },
    {
      // key : 3,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851966",
      wallet_ammount: "3",
      wallet_prefix: "ETH",
    },
    {
      // key : 4,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851967",
      wallet_ammount: "4",
      wallet_prefix: "ETH",
    },
    {
      // key : 5,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851968",
      wallet_ammount: "5",
      wallet_prefix: "ETH",
    },
    {
      // key : 6,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851969",
      wallet_ammount: "6",
      wallet_prefix: "ETH",
    },
    {
      // key : 7,
      wallet_address: "0x8fF74a97643028BCd4fA21fA73AB6BA54D851970",
      wallet_ammount: "7",
      wallet_prefix: "ETH",
    },
  ];
  // first time load the first address in the object
  // or select the selected used address
  useEffect(() => {
    setDropdown(dummyAddress[0]);
  }, []);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes = "main__controls controls__dropdown ";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        actions.setAppData({ blockScroll: false });
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      // document.body.classList.add('no_scroll_dropdown');
      actions.setAppData({ blockScroll: true });
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      // document.body.classList.remove('no_scroll_dropdown');
      actions.setAppData({ blockScroll: false });
    };
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    // add class with selected
    setHighlightedItem(index);
    // set the state for the dropdown with last value
    setDropdown(addr);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    actions.setAppData({ blockScroll: false });
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(dummyAddress.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, dummyAddress[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div className="dropdown__active__item" onClick={onClick}>
        <span className="item_ico icon-wallet-solid"></span>
        <span className="drp_name wallet_address">
          {dropdown.wallet_address}
        </span>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
        {dropdownType === "wamount" && (
          <span className="wallet_asset_amount">
            {dropdown.wallet_ammount} {dropdown.wallet_prefix}
          </span>
        )}
      </div>
      <div className="dropdown__list__items">
        {dummyAddress.map((dummyAddres, index) => (
          <DropdownWalletItem
            items={dummyAddres}
            drpType={dropdownType}
            highlight={index === highlighted}
            key={index}
            clickHandle={() => onClickItem(index, dummyAddres)}
          />
        ))}
      </div>
    </div>
  );
};

export default DropdownWallet;
