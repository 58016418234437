import React, { useEffect } from "react";

import ModalActionSuccess from "./../../dialogs/ModalActionSuccess";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
  RESTORE_WALLET_FLOW,
} from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";

const ModalSuccessFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);

  const { actions } = useApp();
  const { userActions } = useUser();

  const onClose = async () => {
    try {
      setActiveModalNav(DEFAULT_MODAL_STATUS);
      actions.setLoading(true, "loading");
      const acc = await userActions.getAccounts();
      const addreses = acc ? Object.values(acc) : {};
      await actions.refreshWalletCardData(addreses);
    } catch (e) {
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <ModalActionSuccess
      title="Your wallet is Restored"
      desc="The wallet has been successfully Restored"
      dataClass=""
      onClose={onClose}
    />
  );
};

export default ModalSuccessFunctions;
