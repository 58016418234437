import React, { useEffect } from "react";
import "./../../../styles/registration/otpInputs.scss";

const OTPInputs = ({
  numbers,
  setNumbers,
  errorCallback = false,
  autofocus = true,
  disabled = false,
  forceRefresh = false,
  otherParentClass = "",
  eyeIcon = false,
  hiddenEye,
  visibleEye,
  type,
}) => {
  let parentRef = React.useRef(null);
  let shouldGoBack = false;
  const inputPositions = {
    0: "first",
    1: "second",
    2: "third",
    3: "fourth",
    4: "fifth",
    5: "sixth",
  };
  useEffect(() => {
    if (autofocus) {
      setTimeout(() => {
        if (parentRef.current) {
          const inp = parentRef.current.querySelector("input");
          inp && inp.focus();
        }
      }, 40);
    }

    if (forceRefresh && parentRef.current) {
      Array.from(parentRef.current.querySelectorAll("input")).forEach((el) => {
        el.value = "";
      });
      parentRef.current && parentRef.current.querySelector("input").focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFunction = (e, posId) => {
    const position = inputPositions.hasOwnProperty(posId)
      ? inputPositions[posId]
      : null;
    if (position) {
      setNumbers({ ...numbers, [position]: e.target.value });
    }
  };
  const keyDown = (e) => {
    shouldGoBack = true;
    //
    if (e.currentTarget.value !== "") {
      //
      shouldGoBack = false;
    }
    const newVal = e.key;
    const re = /^[0-9\b]+$/;

    if (re.test(newVal)) {
      e.target.value = newVal;
      // e.target.dispatchEvent(new Event('onChange'));
      // e.target.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };
  const keyUp = (e) => {
    //
    if (e.keyCode === 17) {
      return;
    }
    const re = /^[0-9\b]+$/;
    if ((e.shiftKey && e.keyCode === 9) || e.shiftKey) {
      return;
    } else {
      if (e.target.value === "" || re.test(e.target.value)) {
        if (e.keyCode === 8) {
          if (shouldGoBack) {
            goBack(e);
          }
          shouldGoBack = false;
          e.currentTarget.value = "";
          // e.currentTarget.classList.remove('bg-blue-500');
          // e.currentTarget.classList.remove('text-white');
          // e.currentTarget.classList.remove('font-bold');
          e.currentTarget.classList.remove("filled");
          onChangeFunction(e, e.currentTarget.getAttribute("data-index"));
        } else {
          advance(e);
          onChangeFunction(e, e.currentTarget.getAttribute("data-index"));
          if (errorCallback) {
            errorCallback("");
          }
        }
      } else {
        e.preventDefault();
        e.target.value = "";
        if (errorCallback) {
          errorCallback("PIN cannot have alphabets or special characters.");
        }
        return;
      }
    }
  };

  const advance = (e) => {
    const index = parseInt(e.currentTarget.getAttribute("data-index"));
    const val = e.currentTarget.value;
    //
    const inputs = Array.from(parentRef.current.querySelectorAll("input"));
    const ref = index < 5 ? inputs[index + 1] : inputs[inputs.length - 1];

    if (ref && val !== "") {
      // e.currentTarget.classList.add('bg-blue-500');
      // e.currentTarget.classList.add('text-white');
      // e.currentTarget.classList.add('font-bold');
      e.currentTarget.classList.add("filled");

      setTimeout(() => {
        ref.focus();
        // setTimeout( () => {
        //     ref.classList.add('bg-blue-500');
        //     ref.classList.add('text-white');
        //     ref.classList.add('font-bold');
        // }, 100 );
      }, 10);
    }
  };
  const goBack = (e) => {
    const index = parseInt(e.currentTarget.getAttribute("data-index"));
    // const val = e.currentTarget.value;
    //
    const inputs = Array.from(parentRef.current.querySelectorAll("input"));
    const ref = index > 0 ? inputs[index - 1] : null;
    //

    if (ref) {
      ref.value = "";
      setTimeout(() => {
        // // ref.classList.remove('bg-blue-500');
        // ref.classList.remove('text-white');
        // ref.classList.remove('font-bold');
        ref.classList.remove("filled");

        setTimeout(() => {
          ref.focus();
        }, 10);
      }, 10);
    }
  };

  const pasteHandler = (e) => {
    const val = e.clipboardData.getData("Text").split("");
    const inputs = parentRef.current.querySelectorAll("input");

    if (val.length === 6) {
      setTimeout(() => {
        for (var i = 0; i < 6; i++) {
          inputs[i].value = val[i];
          // inputs[i].classList.add('bg-blue-500');
          // inputs[i].classList.add('text-white');
          // inputs[i].classList.add('font-bold');
          inputs[i].classList.add("filled");
          setNumbers((prev) => {
            const obj = Object.keys(prev).reduce((acc, el, idx) => {
              acc[el] = val[idx];
              return acc;
            }, {});
            return obj;
          });
        }
      }, 150);
    }
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  let extraParentClass =
    "mt-1 relative opt_inputs flex items-center h-16 w-1/6";
  if (otherParentClass !== "") {
    extraParentClass = otherParentClass;
  }

  return (
    <>
      <div className="flex mt-4 space-x-4" ref={parentRef}>
        {Object.entries(numbers).map(([position, value], idx) => {
          return (
            <div className={extraParentClass} key={idx}>
              <input
                type={type && type}
                defaultValue={value}
                maxLength={1}
                // onChange={(e) => onChangeFunction(e, position)}
                onInput={(e) => onChangeFunction(e, position)}
                // onChange={e => setNumbers({ ...numbers, [position]: e.target.value })}
                onKeyUp={keyUp}
                onKeyDown={keyDown}
                onPaste={pasteHandler}
                data-index={idx}
                className={
                  (numbers[position] !== "" ? "filled " : "") +
                  "block h-full w-full text-center text-xs md:text-sm otp_input"
                }
                // disabled={disabled}
              />
            </div>
          );
        })}
      </div>
      {eyeIcon && (
        <div>
          {hiddenEye}
          {visibleEye}
        </div>
      )}
    </>
  );
};

export default OTPInputs;
