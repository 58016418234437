import React from 'react';
import MainModal from '../generic/MainModal';

const ModalBuyCrypto = ( attrs) => {
    const props = attrs;
    const extraClass = props.extraClass;

    return (
        <MainModal extraClasses={["modal_w400", "modal_p30", "modal_mh400", "modal_dflex", "modal_success_msg", extraClass]} 
            onClose={()=>props.callbackCancelPopup(props.modalCancel_label)} 
            dataClass="modal_buy_transak">
            <div className="modal_succes_content">
                <div className="modal_succes_head text_align_left">
                    {props.title}
                </div>
                <div className="modal_succes_desc">
                    <div className="modal_succes_img">
                        <img src="images/transak_buy.png" alt="" />
                    </div>
                </div>
                <div className="modal_succes_content_actions">
                    <button className="btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16 close_modal_ok" 
                    onClick={()=> props.callbackOpenPopup(props.modalNext_label)}
                    >
                        Buy Now
                    </button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalBuyCrypto;
