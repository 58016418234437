import { useState } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import { WidgetPage } from "../components/lifi/Widget/widgetPage";
const LiFiScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {/* <!-- main content --> */}
        <div
          className="main__content__wrp token__swap-wrapper"
          style={{ position: "relative", backgroundColor: "#fff" }}
        >
          <WidgetPage />
        </div>
      </div>
      {sidebarActive && <div className="mobile_blur"></div>}
    </div>
  );
};
export default LiFiScreen;
