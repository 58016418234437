import React, { useEffect, useState } from "react";
import AddAssetItem from "../AddAssetItem/AddAssetItem";
import contractMetadata from "../../../helpers/contractMetadata";
const tokens = Object.values(contractMetadata);

const AddAssetList = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const filteredData = tokens.filter((el, i) => {
      if (props.filter === "") {
        return true;
      } else {
        return (
          (el.name && el.name.toLowerCase().includes(props.filter)) ||
          (el.symbol && el.symbol.toLowerCase().includes(props.filter))
        );
      }
    });
    setData(filteredData);
  }, [props.filter]);

  return (
    <div className="form__input_filter_results form__input_filter_assets from__assets_filter_results">
      {data.map((token, ind) => (
        <AddAssetItem tokenInfo={token} key={ind} onClick={props.onClick} />
      ))}
      {data.length === 0 && (
        <div style={{ padding: 40, height: 345, width: "100%" }}>
          <div className="flex justify-center align-center">
            <p className="text-sm text-gray-400 flex items-center fw-500 pt--14">
              No assets found.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAssetList;
