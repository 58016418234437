import React, { useState, useEffect } from "react";

import { useApp } from "./../../../helpers/AppProvider";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
  RESTORE_WALLET_FLOW,
} from "../../../constants/ui";

import ModalEnterPinFunctions from "./ModalEnterPinFunctions";

import Storage from "../../../helpers/Storage";
import {
  getSafleName,
  generateEncryptionKey,
} from "../../../helpers/vaultHelpers";

import { useUser } from "../../../helpers/UserProvider";
import ModalRestoreWalletFunctions from "./ModalRestoreWalletFunctions";
import ModalSuccessFunctions from "./ModalSuccessFunctions";
import ModalFailedFunctions from "./ModalFailedFunctions";
import ModalCancelFunctions from "./ModalCancelFunctions";

const RestoreWalletProcess = () => {
  const { userData, userActions } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      wallets = [],
    },
    setAppData,
  } = useApp() || {};

  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);

  const [modalData, setData] = useModalData();

  useEffect(() => {
    //const safleName = await getSafleName(storageDataToken);
    //setData({ type: "" });

    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {activeModalLabel === "modal_restore_wallet_popup" && (
        <ModalRestoreWalletFunctions />
      )}
      {activeModalLabel === "modal_restore_enter_pin" && (
        <ModalEnterPinFunctions />
      )}
      {activeModalLabel === "modal_restore_success" && (
        <ModalSuccessFunctions />
      )}
      {activeModalLabel === "modal_restore_failed_wallet" && (
        <ModalFailedFunctions />
      )}
      {activeModalLabel === "modal_restore_cancel_wallet" && (
        <ModalCancelFunctions />
      )}
      {/*{activeModalLabel === '' &&
                <ModalCancelFunctions />
            }
            {activeModalLabel === '' &&
                <ModalFailedFunctions />
            }
            {activeModalLabel === '' &&
                <ModalSuccesFunctions />*/}
      {/*}*/}
    </>
  );
};

export default RestoreWalletProcess;
