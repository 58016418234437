import { useState } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";

const DappScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {/* <!-- main content --> */}
        <div
          className="main__content__wrp settings-overview-wrapper"
          style={{ position: "relative", backgroundColor: "#fff" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 100,
              position: "absolute",
              left: "0",
              top: "0",
            }}
          >
            <div className="settings__overview_guardian_setup">
              <span className="msbico-planet_guardian" />
              <span className="coming_soon">Coming Soon</span>
              <div className="coming_soon_msg">
                We will notify you once this feature is ready 🙌
              </div>
            </div>
          </div>
        </div>

        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        {/* <!-- end aside notification --> */}
      </div>
      {sidebarActive && <div className="mobile_blur"></div>}
    </div>
  );
};

export default DappScreen;
