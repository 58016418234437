/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import ModalChangeID from "../../dialogs/ModalChangeID";
import ModalChangeIDConfirm from "../../dialogs/ModalChangeIDConfirm";
import ModalIDChanged from "../../dialogs/ModalIDChanged";
// import ModalWalletFailed from "../../dialogs/ModalWalletFailed";
// import ModalPinVerification from "./../../dialogs/ModalPinVerification";
import ModalEnterPin from "./../../dialogs/ModalEnterPin";
// import ModalWalletFailed from '../../dialogs/ModalWalletFailed';
import ModalActionConfirm from "./../../dialogs/ModalActionConfirm";
import ModalActionFailed from "./../../dialogs/ModalActionFailed";

import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import { CHANGE_SAFLE_ID, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
// import ModalCancelProcess from "../../dialogs/ModalCancelProcess";
// import { delay_code } from "../../../helpers/generalHelper";
import Storage from "../../../helpers/Storage";
import { useRecaptcha } from "../../../helpers/UserProvider";

const ChangeSafleIdProcess = () => {
  // const recaptchaRef = useRecaptcha();
  const { actions } = useApp();
  const { userData, userActions } = useUser();
  const recaptchaRef = useRecaptcha();

  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
    },
  } = useApp() || {};
  const [setActiveModalNav, , goBackward] = useModalNavigation(CHANGE_SAFLE_ID);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setData, reloadModalData] = useModalData();

  const [validation, setValidation] = useState(false);
  const [newSafleId, setNewSafleId] = useState(modalData?.newSafleId || "");

  const saveNewSafleId = (value) => {
    setData({ newSafleId: value });
    setNewSafleId(value);
  };

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackChangeID = (popup) => {
    setActiveModalNav("confirm_change_safleid");
  };
  const callbackChangeIDConfirm = (popup) => {
    setActiveModalNav("change_safleid_pin");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelGeneratePopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {
    //
    try {
      onSubmitSafleId(value);
    } catch (error) {
      actions.setLoading(false);
      callbackCancelGeneratePopup("change_safleid_failed");
    }
  };
  const callbackRetryOpenPopup = (popup) => {
    setActiveModalNav("change_safleid");
  };

  const onSubmitSafleId = async (pinValue) => {
    const stor = Storage.load("modalData");

    const password = stor.password;
    const userVault = userData?.user?.vault;

    if (!userVault) {
      setValidation("Your vault is not available. Please login again.");
    }

    if (pinValue && userVault) {
      //
      // return;
      let token = await recaptchaRef.current.executeAsync();
      actions.setLoading(true);
      const { result, error } = await userActions.changeSafleId(
        userData.user,
        modalData.newSafleId,
        pinValue,
        password,
        token
      );
      actions.setLoading(false);

      // return;
      if (!error) {
        if (!!result?.data) {
          setData({ transactionHash: result?.data?.transactionHash });
          if (result?.data?.token) {
            await userActions.updateVaultToken(result?.data?.token);
            await userActions.updateSafleID(modalData.newSafleId);
          }

          // if No Errors Navigate to Success modal
          callbackOpenPopup("change_safleid_success");
        } else {
          setValidation("Change Safle Id failed.");
          // if Errors Navigate to Failed modal
          callbackCancelGeneratePopup("change_safleid_failed");
        }
      } else {
        console.error("error from userActions.changeSafleId", error);
        setValidation("Something went wrong. Please try again later.");
        // if Errors Navigate to Failed modal
        callbackCancelGeneratePopup("change_safleid_failed");
      }
    } else {
      setValidation("Privatekey, Pin or any other input has wrong data.");
    }
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    setNewSafleId("");
  }, []);

  useEffect(() => {
    if (activeModalLabel === "change_safleid") {
      setNewSafleId("");
    }
  }, [activeModalLabel]);

  return (
    <>
      {
        // change_safleid:
        // confirm_change_safleid
        // change_safleid_pin:
        // change_safleid_success:
        // change_safleid_failed:
      }

      {activeModalLabel === "change_safleid" && (
        <ModalChangeID
          onClose={onClose}
          callbackOpenPopup={callbackChangeID}
          currentSafleId={userData?.user?.safleID}
          newSafleId={newSafleId}
          setNewSafleId={saveNewSafleId}
        />
      )}

      {activeModalLabel === "confirm_change_safleid" && (
        <ModalChangeIDConfirm
          onClose={() => callbackCancelGeneratePopup("cancel_change_safleid")}
          callbackOpenPopup={callbackChangeIDConfirm}
          newSafleId={newSafleId}
          currentSafleId={userData?.user?.safleID}
        />
      )}

      {activeModalLabel === "change_safleid_pin" && (
        <>
          {/* <ModalPinVerification
                    onClose={onClose}
                    submitAction={onSubmitSafleId}
                    callbackCancelPopup={(modalKey) => callbackCancelGeneratePopup(modalKey)}
                    modalCancel_label='cancel_change_safleid'
                    modalNext_label='settings_export_seedphrase'
                /> */}
          <ModalEnterPin
            onClose={(modalKey) => callbackCancelGeneratePopup(modalKey)}
            // onCompletePIN={onCompletePIN}
            callbackOpenPopup={onCompletePIN}
            callbackCancelPopup={(modalKey) =>
              callbackCancelGeneratePopup(modalKey)
            }
            modalCancel_label="cancel_change_safleid"
            modalNext_label="settings_export_seedphrase"
            labelContinue="Confirm"
          />
        </>
      )}
      {activeModalLabel === "change_safleid_success" && (
        <ModalIDChanged onClose={onClose} />
      )}
      {activeModalLabel === "change_safleid_failed" && (
        <>
          {/* <ModalWalletFailed 
                    onClose={onClose} 
                    callbackOpenPopup={callbackOpenPopup}
                    validation={validation}
                    reason={'Change SafleId failed.'}
                    retryModal={'change_safleid'}
                /> */}
          <ModalActionFailed
            title="safleID is not updated"
            desc={validation}
            dataClass="modal_action_failed2"
            onClose={onClose}
            callbackRetryPopup={callbackRetryOpenPopup}
            iconErr={"images/wallets/update-failed-warning.svg"}
          />
        </>
      )}

      {activeModalLabel === "cancel_change_safleid" && (
        <>
          {/* <ModalCancelProcess 
                    onClose={onClose}  
                    goBackward={goBackward}
                    body="Are you sure do you want to cancel the process of change safle id?"
                /> */}
          <ModalActionConfirm
            title="Want to cancel update safleID"
            desc=""
            dataClass="modal_action_confirm1 "
            onClose={onClose}
            goBackward={goBackward}
            iconErr={"images/wallets/cancel_confirm.svg"}
          />
        </>
      )}
    </>
  );
};

export default ChangeSafleIdProcess;
