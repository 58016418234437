import React, { useState } from "react";
import { useApp } from "./../../../../helpers/AppProvider";
import ModalChangeCurrency from "./../../../dialogs/header/ModalChangeCurrency";
// import initialState from "./../../../../context/initialStateGlobal";

const CurrencySelector = () => {
  const { appData } = useApp();
  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
    //
    //
  };
  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  //get from the global state the current currency
  // const filterCurrency = appData.activeCurrency;
  //
  //
  //get icon
  //get name

  return (
    <div
      className={
        "main__controls controls__dropdown controls__dropdown-currency header_dropdown_currency " +
        ""
        // " beta dark "
      }
    >
      <div
        className="header_currency_trigger modal__trigger"
        data-target="select_currency"
        onClick={() => openPopup("modal_change_currency")}
      >
        <span
          className="item_ico language lang "
          style={{
            backgroundImage: `url(${
              appData.activeCurrency &&
              appData.activeCurrency.hasOwnProperty("Country Flag")
                ? appData.activeCurrency["Country Flag"]
                : "images/flags/dollor.png"
            })`,
            // backgroundImage: `url(images/flags/dollor.png)`
          }}
        />
        <span className="drp_name lang_name">
          {appData.activeCurrency && appData.activeCurrency["Currency Code"]}
        </span>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1 color_white" />
      </div>

      {openedPopup === "modal_change_currency" && (
        <ModalChangeCurrency onClose={onClose} />
      )}
    </div>
  );
};

export default CurrencySelector;
