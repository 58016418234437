import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
// import Button from '../Button';
import Button from "./../../../components/registration/Button/Button";

import { XIcon, XCircleIcon } from "@heroicons/react/solid";
import { CheckCircleIcon } from "@heroicons/react/outline";

import "./style.scss";

const Toast = ({
  isOpen = false,
  setOpen,
  hideAfter = false,
  title,
  bodyText,
  icon = false,
  seedPhrase = false,
  positionAbsolute = false,
  customBody = false,
  customBodyContent,
}) => {
  if (hideAfter) {
    setTimeout(() => {
      setOpen(false);
    }, hideAfter);
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <div
        className={
          !customBody
            ? !seedPhrase
              ? "flex space-x-2 justify-center fixed top-5 left z-[200] left-1/2 transform -translate-x-1/2"
              : "flex space-x-2 justify-center absolute top-5 right-5 seed-phrase-toast-ctn z-[200] "
            : `flex space-x-2 justify-center absolute top-5 right-5 z-[200]`
        }
      >
        <Transition.Child
          as={Fragment}
          enter="duration-700"
          enterFrom="-translate-y-50 opacity-0 sm:-translate-y-20 sm:translate-x-0"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="-translate-y-20 opacity-0 sm:translate-x-0"
        >
          <div
            className="bg-white shadow-lg mx-auto w-100 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-md block"
            id="static-example"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-mdb-autohide="false"
          >
            {customBody ? (
              <div>{customBodyContent}</div>
            ) : seedPhrase ? (
              <div className="p-3 break-words seed-phrase-toast rounded-md">
                <CheckCircleIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
                {bodyText||'Your seed phrase has been regenerated'}
                <XCircleIcon
                  className="h-4 w-4 text-white"
                  aria-hidden="true"
                  onClick={() => setOpen(false)}
                />
              </div>
            ) : (
              <div className="p-3 bg-white break-words text-gray-700 rounded-md">
                {bodyText}
              </div>
            )}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};
export default Toast;
