const cssFix = () => {
    return(
    <>
        <style>
            {':root{ font-size: 16px; }'}
        </style>
    </>
    )
}

export default cssFix;
