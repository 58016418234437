import React, { useState, useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import {
  limitTitleFromMiddle,
  middleEllipsis,
} from "../../../helpers/generalHelper";
import { useUser } from "../../../helpers/UserProvider";
import Blockie from "../../common/Blockie/Blockie";
import ModalSwapSelectWallet from "../../dialogs/ModalSwapSelectWallet";

// const Vault = require('@getsafle/safle-vault');

const WalletSelector = () => {
  const { appData, actions } = useApp();
  const { userActions, userData } = useUser();

  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);
  const [activeWallet, setActiveWallet] = useState(null);

  useEffect(() => {
    // async function getAccounts() {
    const accounts = appData?.accounts; //await userActions.getAccounts();
    if (accounts && !appData.activeAccountAddress) {
      actions.setAppData({
        activeAccountAddress: accounts[0]?.address,
        activeWallet: accounts[0]?.address,
        activeChain: 1,
      });
    }
    // }
    // getAccounts();
  }, [appData.accounts]);
  // useEffect(() => {
  //   getAccounts();
  // },[])

  // const getAccounts = async ()=>{

  // const vault = new Vault({ vault, platform, storage });

  // const accounts = await vault.getAccounts(encryptionKey);
  // }

  useEffect(() => {
    if (appData.activeAccountAddress) {
      setActiveWallet(appData.activeAccountAddress);
      actions.setAppData({ activeWallet: appData.activeAccountAddress });
    } else {
      setActiveWallet(appData.accounts[0]?.address);
      actions.setAppData({ activeWallet: appData.accounts[0]?.address });
    }
  }, [appData.activeAccountAddress]);

  // useEffect(() => {
  //   if (appData.accounts) {
  //     const activeAccount = appData.accounts?.find(
  //       (account) => account.address === appData.activeAccountAddress
  //     );
  //     setTimeout(() => {
  //       setActiveWallet(activeAccount || Object.values(appData.accounts)[0]);
  //     }, 500);
  //   }
  // }, [appData.accounts, appData.activeAccountAddress]);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  return (
    <div
      className="main__controls controls__dropdown controls__dropdown-new-wallet"
      // style={{ zIndex: 0 }}
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-wallet-dropwdon-new"
        onClick={() => openPopup("modal_swap_select_wallet")}
      >
        <div className="wallet-address-new-name-content">
          {/* <img src="images/wallet-avatar-placeholer-small.svg" alt="" /> */}
          <div>
            {activeWallet ? (
              <Blockie
                size={6}
                address={activeWallet}
                label={
                  // typeof
                  appData?.accounts?.find(
                    (account) => account.address === activeWallet
                  )?.label
                  // === "string"
                  //   ? appData?.accounts?.find(
                  //       (account) => account.address === activeWallet
                  //     )?.label
                  //   : appData?.accounts?.find(
                  //       (account) => account.address === activeWallet
                  //     )?.label?.polygon
                }
              />
            ) : null}
          </div>
          {appData?.accounts && appData?.accounts?.length > 0 && (
            <span className="drp_name wallet_address wallet-name-new">
              {typeof appData?.accounts?.find(
                (account) => account?.address === activeWallet
              )?.label === "string"
                ? appData?.accounts?.find(
                    (account) => account?.address === activeWallet
                  )?.label
                : appData?.accounts?.find(
                    (account) => account?.address === activeWallet
                  )?.label?.polygon}
              {/* {activeWallet?.label[
              appData.chains[appData.activeChain || 0]?.chain_name
            ] || activeWallet?.label} */}
            </span>
          )}
        </div>
        <span
          className="drp_name wallet_address wallet-address-new "
          style={{ marginLeft: 45 }}
        >
          {limitTitleFromMiddle(activeWallet, 10)}
        </span>
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
      </div>
      {openedPopup === "modal_swap_select_wallet" && (
        <ModalSwapSelectWallet onClose={onClose} />
      )}
    </div>
  );
};

export default WalletSelector;
