// helper css
import { useState, useEffect } from "react";
import "./styles/webapp.scss";
import "./styles/helpers.scss";
import { useLocation } from "react-router-dom";
import PageTitleUpdater from "./components/common/PageTitleUpdater/PageTitleUpdater";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthGuard from "./helpers/authGuard";
import ROUTES from "./helpers/routes";
import { AppProvider } from "./helpers/AppProvider";
import { UserProvider } from "./helpers/UserProvider";
import { RegistrationProvider } from "./helpers/RegistrationProvider";
import Preloader from "./components/common/Preloader/Preloader";
import { FlowGate, LoggedInGate } from "./components/flow/FlowGate";

import SignInScreen from "./screens/registration/SignInScreen";
import DashboardScreen from "./screens/DashboardScreen";
import AssetsScreen from "./screens/AssetsScreen";
import AssetDetailScreen from "./screens/AssetDetailScreen";
import NFTsScreen from "./screens/NFTsScreen";
import NFTSDetailScreen from "./screens/NFTSDetailScreen";
import ActivityScreen from "./screens/ActivityScreen";
import WalletsScreen from "./screens/WalletsScreen";
import SettingsScreen from "./screens/SettingsScreen";
import SwapScreen from "./screens/SwapScreen";
import LiFiScreen from "./screens/LiFiScreen";
import DappScreen from "./screens/DappScreen";
import StakingScreen from "./screens/StakingScreen";
import DemoControlsScreen from "./screens/DemoControlsScreen";

import SignUpScreen from "./screens/registration/SignUpScreen";
import OTPRegistrationScreen from "./screens/registration/OTPVerificationRegistration";
import MnemonicPhraseScreen from "./screens/registration/MnemonicPhraseScreen";
import SetupPinScreen from "./screens/registration/SetupPINScreen";
import WalletDetailsScreen from "./screens/registration/WalletDetailsScreen";
import WalletTransactionScreen from "./screens/registration/WalletTransactionScreen";

import ForgotPasswordScreen from "./screens/registration/ForgotPassword";
import NewPasswordScreen from "./screens/registration/NewPasswordScreen";

import MigrationScreen from "./screens/registration/MigrationV2Screen";

import AssetsDialogScreen from "./screens/AssetsDialogsScreen";
import WalletsDialogsScreen from "./screens/WalletsDialogsScreen";
import SettingsDialogsScreen from "./screens/SettingsDialogsScreen";
import NotFound from "./screens/NotFound";
import MobileScreen from "./screens/MobileScreen";
import LoaderScreen from "./screens/LoaderScreen";

// import TestScreen from "./screens/TestScreen";
// import TestAppHelpers from "./screens/test/TestAppHelpers";

import SwapDialogsScreen from "./screens/SwapDialogsScreen";

// import {
//   Onboard1,
//   Onboard2,
//   Onboard3,
//   Onboard4,
// } from "./screens/test/TestRouteScreens";
import { PopupProvider } from "./helpers/PopupProvider";
import NFTCollectionDetail from "./components/assets/NFTCollectionDetail/NFTCollectionDetail";
import VaultScreen from "./screens/VaultScreen";
import RecoveryMechanism from "./components/common/ManageVaultHeader/RecoveryMechanism";

function App() {
  const [device, setDevice] = useState();

  useEffect(() => {
    // Check if the user is on a mobile device
    setDevice(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) || window.innerWidth < 600
        ? "mobile"
        : "desktop"
    );
  }, []);

  return device ? (
    device === "mobile" ? (
      <MobileScreen />
    ) : (
      <BrowserRouter>
        <UserProvider>
          <AppProvider>
            <PopupProvider>
              <RegistrationProvider>
                <Preloader />
                <PageTitleUpdater />
                <Routes>
                  <Route path={ROUTES.SIGNUP} element={<SignUpScreen />} />
                  <Route
                    path={ROUTES.OTP_VERIFICATION}
                    element={
                      <FlowGate default={ROUTES.FORGOT_PASS}>
                        <OTPRegistrationScreen />
                      </FlowGate>
                    }
                  />
                  <Route
                    path={ROUTES.MNEMONIC_PHRASE + "/:step_number"}
                    element={
                      <FlowGate default={ROUTES.SIGNUP}>
                        <MnemonicPhraseScreen />
                      </FlowGate>
                    }
                  />
                  <Route
                    path={ROUTES.SETUP_PIN}
                    element={
                      <FlowGate default={ROUTES.SIGNUP}>
                        <SetupPinScreen />
                      </FlowGate>
                    }
                  />
                  <Route
                    path={ROUTES.WALLET_DETAILS}
                    element={
                      <FlowGate default={ROUTES.SIGNUP}>
                        <WalletDetailsScreen />
                      </FlowGate>
                    }
                  />
                  <Route
                    path={ROUTES.WALLET_TRANSACTIONS}
                    element={<WalletTransactionScreen />}
                  />
                  <Route
                    path={ROUTES.WALLET_TRANSACTIONS + "/:transactionID"}
                    element={
                      <FlowGate default={ROUTES.SIGNUP}>
                        <WalletTransactionScreen />
                      </FlowGate>
                    }
                  />

                  <Route
                    path={ROUTES.FORGOT_PASS}
                    element={<ForgotPasswordScreen />}
                  />

                  <Route
                    path={ROUTES.NEW_PASSWORD}
                    element={
                      <FlowGate default={ROUTES.FORGOT_PASS}>
                        <NewPasswordScreen />
                      </FlowGate>
                    }
                  />

                  <Route
                    path={ROUTES.MIGRATION}
                    element={
                      <FlowGate default={ROUTES.MIGRATION}>
                        <MigrationScreen />
                      </FlowGate>
                    }
                  />

                  <Route
                    path={ROUTES.DASHBOARD}
                    element={
                      <AuthGuard>
                        {" "}
                        <DashboardScreen />{" "}
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.ASSETS}
                    element={
                      <AuthGuard>
                        <AssetsScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.ASSET_DETAIL}
                    element={
                      <AuthGuard>
                        <AssetDetailScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.NFTS}
                    element={
                      <AuthGuard>
                        <NFTsScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.NFT_COLLECTION_DETAIL}
                    element={
                      <AuthGuard>
                        <NFTCollectionDetail />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.NFTS_DETAIL}
                    element={
                      <AuthGuard>
                        <NFTSDetailScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.ACTIVITY}
                    element={
                      <AuthGuard>
                        <ActivityScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.WALLETS}
                    element={
                      <AuthGuard>
                        <WalletsScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.MANAGE_VAULT}
                    element={
                      <AuthGuard>
                        <VaultScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.RECOVERY_MECHANISM}
                    element={
                      <AuthGuard>
                        <RecoveryMechanism />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.SETTINGS}
                    element={
                      <AuthGuard>
                        <SettingsScreen />
                      </AuthGuard>
                    }
                  />

                  <Route
                    path={ROUTES.SWAP}
                    element={
                      <AuthGuard>
                        <SwapScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.DAPP}
                    element={
                      <AuthGuard>
                        <DappScreen />
                      </AuthGuard>
                    }
                  />
                  {process.env.REACT_APP_HIDE_LIFI === "false" && (
                    <Route
                      path={ROUTES.LIFI_ALL}
                      element={
                        <AuthGuard>
                          <LiFiScreen />
                        </AuthGuard>
                      }
                    />
                  )}

                  <Route
                    path={ROUTES.STAKING}
                    element={
                      <AuthGuard>
                        <StakingScreen />
                      </AuthGuard>
                    }
                  />
                  <Route
                    path={ROUTES.DEMOCONTROLS}
                    element={<DemoControlsScreen />}
                  />

                  <Route
                    path={ROUTES.MODALS_ASSETS}
                    element={<AssetsDialogScreen />}
                  />
                  <Route
                    path={ROUTES.MODALS_WALLETS}
                    element={<WalletsDialogsScreen />}
                  />
                  <Route
                    path={ROUTES.MODALS_SETTINGS}
                    element={<SettingsDialogsScreen />}
                  />
                  <Route
                    path={ROUTES.MODALS_SWAP}
                    element={<SwapDialogsScreen />}
                  />

                  <Route
                    path={ROUTES.HOME}
                    element={
                      <LoggedInGate>
                        <SignInScreen />
                      </LoggedInGate>
                    }
                  />

                  {/* test regflow screens - comment this when done */}
                  {/* <Route path={ROUTES.TEST} element={<TestScreen />} />
                <Route path={"/test-webapp"} element={<TestAppHelpers />} /> */}

                  {/* test regflow flow-gate */}
                  {/* <Route
                  path={"/onboarding/step4"}
                  element={
                    <FlowGate default="/onboarding">
                      <Onboard4 />
                    </FlowGate>
                  }
                />
                <Route
                  path={"/onboarding/step3"}
                  element={
                    <FlowGate default="/onboarding">
                      <Onboard3 />
                    </FlowGate>
                  }
                />
                <Route
                  path={"/onboarding/step2"}
                  element={
                    <FlowGate default="/onboarding">
                      <Onboard2 />
                    </FlowGate>
                  }
                />
                <Route path={"/onboarding"} element={<Onboard1 />} />

                <Route
                  path={"/otherflow1/step3"}
                  element={
                    <FlowGate default="/otherflow1">
                      <Onboard3 />
                    </FlowGate>
                  }
                />
                <Route
                  path={"/otherflow1/step2"}
                  element={
                    <FlowGate default="/otherflow1">
                      <Onboard2 />
                    </FlowGate>
                  }
                />
                <Route path={"/otherflow1"} element={<Onboard1 />} /> */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </RegistrationProvider>
            </PopupProvider>
          </AppProvider>
        </UserProvider>
      </BrowserRouter>
    )
  ) : (
    <LoaderScreen />
  );
}

export default App;
