const initialState = {
  loading: false,
  loadingMessage: false,
  portfolioData: [],
  nftData: [],
  regStatus: null,
  wallets: [],
  activeWallet: null,
  walletAssets: {},
  walletNFTs: {},
  walletTransactions: [],
  chains: [],
  nonEvmChains: [],
  activeChain: null,
  activeAssetChain: null,
  filteredChain: 0,
  activeAsset: null,
  assets: [],
  tokens: [],
  accounts: [],
  activeAccountAddress: null,
  supportedTokens: [],
  activeTokenA: null,
  activeTokenABalance: null,
  activeTokenB: null,
  activeTokenBBalance: null,
  fromTokenData: [],
  toTokenData: [],
  swapFromQuantity: 0,
  swapToQuantity: 0,
  swapSlippage: 1,
  exchangeRate: 0,
  txFee: 0,
  txId: "",
  txResult: undefined,
  dexLogos: {
    uniswap: `${process.env.PUBLIC_URL}/images/swap/uniswap-logo.svg`,
    oneInch: `${process.env.PUBLIC_URL}/images/swap/oneInch-logo.svg`,
  },
  activeDex: "oneInch",
  maxPriorityFeePerGas: 0,
  maxFeePerGas: 0,
  blockScroll: false,
  showSidebarNotification: false,
  transakSupportedCurrencies: [],
  approvalTxRes: null,
  swapTransactions: [],
};

export default initialState;
