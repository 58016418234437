import React, { useState, useEffect, useRef } from 'react';
import "./style.scss";

import { copyToClipboard } from './../../../helpers/vaultHelpers';

const TooltipCopy = ({ extraClasses = '', textToDisplay = '', imageToDisplay = false, tooltipTextIn = '', tooltipTextInChange = '', hideAfter = 3000, copiedText = null, setCopiedText = () => { } }) => {
  // const [ tooltipState, setTooltipState ] = useState( false );
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);
  const timerRef = useRef(null);


  useEffect(() => {
    setTooltipText(tooltipTextIn);
  }, []);

  const onClickTooltip = (e) => {
    e.preventDefault();
    //copy function of the address
    copyToClipboard(copiedText);
    //setState to add Opened Class
    setShowTooltip(true);
    //setState to change the text within the tooltip 
    setTooltipText(tooltipTextInChange);
    //timeout after 3000ms do something
    timerRef.current = setTimeout(() => {
      //setState to remove Opened Class
      setShowTooltip(false);
      setCopiedText(null);
      //setState to re-change the text to default one
      setTooltipText(tooltipTextIn);
    }, hideAfter);
  }

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);


  const activeClass = (copiedText && showTooltip) ? 'opened ' : ' ';
  const cssClasses = ' ';
  return (
    <div className={cssClasses + activeClass + extraClasses} onClick={(e) => onClickTooltip(e)}>
      {textToDisplay}
      {imageToDisplay}
      <span className="tooltiptext">
        <span className="icon_svg">
          <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <path d="M25 0C11.2 0 0 11.2 0 25s11.2 25 25 25 25-11.2 25-25S38.8 0 25 0z" fill="#1AAF5D" />
              <path fill="#FFF" d="M21.643 35 12 25.403l2.72-2.706 6.923 6.871L36.28 15 39 17.726z" />
            </g>
          </svg>
        </span>
        {tooltipText}
      </span>
    </div>
  )
}
export default TooltipCopy
