import React, { useEffect } from "react";
import FiatCurrenciesItem from "../FiatCurrenciesItem/FiatCurrenciesItem";

const FiatCurrenciesList = ( { data, search, selectCurrency, hideLabel=false } ) => {
  const placeholderFlag = 'url("images/currency/coin.svg")';
  //const placeholderFlag = 'url("images/flags/dollor.png")';

  const filteredCurrencies = data.filter((currency) => {
    return (currency['Currency Name']).toLowerCase().includes(search.toLowerCase()) || 
           (currency['Currency Code']).toLowerCase().includes(search.toLowerCase());
  });

  return !!filteredCurrencies?.length && (
    <>
    {!hideLabel &&<div className="form__input_popular_label">Flat Currencies</div>}
      <div className="form__input_filter_results fiat_currencies_list">
        {
          filteredCurrencies.map((item, index) => {

            const checkSymbol = item.hasOwnProperty('Currency Symbol');
            const checkFlag = item.hasOwnProperty('Country Flag');

            return (
              <FiatCurrenciesItem
                key={ index }
                icon={ checkFlag ? 'url(' + item['Country Flag'] + ')' : placeholderFlag }
                currencyName={ item['Currency Name'] }
                currencyPrefix={
                  checkSymbol ? item['Currency Code'] + ' - ' + item['Currency Symbol']
                    : item['Currency Code']
                }
                hasFlag={ checkFlag  }
                selectCurrency={selectCurrency}
              />
            );
          } )
        }
      </div>
    </>
  );
};

export default FiatCurrenciesList;