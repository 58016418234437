const FavoriteToggle = ({ star,handleWatchToggle }) => {
    return(
        <div className="assets__overview_row-watch"
            data-watch={ star ? "watch" : "" }
            onClick={(e)=>handleWatchToggle(e)}
            // title="Coming soon"
        >
            <span className="icon-star-solid" />
            <span className="icon-star-regular" />
        </div>
    );
  }
  
  export default FavoriteToggle;
  