
import PublicAddressGenerated from '../PublicAddressGenerated/PublicAddressGenerated';
import PrivateKeyGenerated from '../PrivateKeyGenerated/PrivateKeyGenerated';

const WalletGeneratedInfo = ({privKey, address}) => {
    return (
        <>
            <PublicAddressGenerated address={address}/>
            <PrivateKeyGenerated privKey={privKey}/>
        </>
    );
};

export default WalletGeneratedInfo;