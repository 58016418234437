// Add all lock flows into this structure. I.E. signup, migration, forgotpass, etc.

const flowConfig = {
  resetpassword: ["/forgot-pass", "/otp-verification", "/new-password"],
  signup: [
    "/signup",
    "/otp-verification",
    "/mnemonic-phrase/1",
    "/mnemonic-phrase/2",
    "/mnemonic-phrase/3",
    "/setup-pin",
    "/wallet-details",
    "/wallet-transaction",
    "/wallet-transaction/:any",
    "/dashboard",
  ],
  migration: ["/migration", "/setup-pin"],
};

export default flowConfig;
