import React from "react";

const DropDownNFTNetworkItem = ({ items, clickHandle, highlight }) => {
  return (
    <div
      className={
        "drop-link dropdown__active__item control-network-dropwdon-new " +
        (highlight ? "selected" : "")
      }
      onClick={clickHandle}
    >
      <div className="network-new-name-content">
        <img src={items?.network_logo} alt="" />
        <span className="drp_name network_name network-name-new">
          {items?.network_name}
        </span>
      </div>
    </div>
  );
};

export default DropDownNFTNetworkItem;
