import React, { useState, useEffect } from "react";

import { useApp } from "./../../../helpers/AppProvider";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

import ModalRemoveWalletFunctions from "./ModalRemoveWalletFunctions";
import ModalEnterPinFunctions from "./ModalEnterPinFunctions";
import ModalCancelFunctions from "./ModalCancelFunctions";
import ModalSuccesFunctions from "./ModalSuccesFunctions";
import ModalFailedFunctions from "./ModalFailedFunctions";

import Storage from "../../../helpers/Storage";
import {
  getSafleName,
  generateEncryptionKey,
} from "../../../helpers/vaultHelpers";

import { useUser } from "../../../helpers/UserProvider";

const RemoveWalletProcess = () => {
  const { userData, userActions } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      wallets = [],
    },
    setAppData,
  } = useApp() || {};

  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(REMOVE_WALLET_FLOW);

  const [modalData, setData] = useModalData();

  const storageData = Storage.load("user");

  const storageDataName = storageData.safleID;
  const storageDataToken = storageData.token;
  const storageDataVault = storageData.vault;

  useEffect(() => {
    // const safleName = await getSafleName(storageDataToken);
    setData({ type: "remove_wallet" });

    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {activeModalLabel === "modal_remove_wallet_popup" && (
        <ModalRemoveWalletFunctions />
      )}
      {activeModalLabel === "modal_remove_enter_pin" && (
        <ModalEnterPinFunctions />
      )}
      {activeModalLabel === "modal_remove_cancel_wallet" && (
        <ModalCancelFunctions />
      )}
      {activeModalLabel === "modal_remove_failed_wallet" && (
        <ModalFailedFunctions />
      )}
      {activeModalLabel === "modal_remove_success" && <ModalSuccesFunctions />}
    </>
  );
};

export default RemoveWalletProcess;
