import { useState, useRef } from "react";
import { useUser } from "../../../helpers/UserProvider";

const SafleSearchID = ({
  setAvailableSafleId,
  setValidation,
  setNewSafleId,
  setCountAttempts,
}) => {
  const timer = useRef();
  const { userActions } = useUser();
  const [loading, setLoading] = useState(false);

  const onChangeInput = async (e) => {
    const successStatuses = [200, 201];
    const inputValue = e.target.value;
    clearTimeout(timer.current);
    setValidation(null);

    if (inputValue.length > 3) {
      timer.current = setTimeout(async () => {
        setLoading(true);
        const validateResult = await userActions.validateSafleID(inputValue);

        if (successStatuses.indexOf(validateResult.statusCode) > -1) {
          const userAvailable =
            !!validateResult?.data?.isAvailable &&
            !validateResult?.data?.isReserved;
          const validation = userAvailable
            ? { type: "success", message: "Id is available." }
            : { type: "error", message: "Id not available." };
          setAvailableSafleId && setAvailableSafleId(userAvailable);
          setNewSafleId && setNewSafleId(inputValue);
          setValidation && setValidation(validation);
        } else {
          setValidation &&
            setValidation({
              type: "error",
              message: "This safleID contains invalid characters.",
            });
          //validateResult?.details[0]?.message});
        }
        setLoading(false);
      }, 800);
    }
  };

  return (
    <div className="form__input form__input-search input--change-safle-id-search">
      <span className="icon-search icon-search-black-24-dp" />
      <input
        type="text"
        className="form-input-default"
        placeholder="Search Safle ID"
        onChange={onChangeInput}
      />
      {loading && (
        <img
          src="/images/registration/loading.svg"
          className="my-1 loading-icon"
          alt="loading"
          width={40}
        />
      )}
    </div>
  );
};

export default SafleSearchID;
