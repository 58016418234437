import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { useApp } from "../../helpers/AppProvider";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import MainModal from "./../generic/MainModal";
import { formatMoney } from "../../helpers/walletHelpers";
import NFTImage from "../common/NFTImage/NFTImage";
import TooltipCopy from "../registration/TooltipCopy";
import moment from "moment";
import { useModalData } from "../../helpers/useModalNavigation";
import Storage from "../../helpers/Storage";

const w3 = new Web3();

const ModalTransactionDone = ({
  onClose,
  txnID,
  sendTo,
  trans = false,
  details = false,
  // below 3 are used in NFT transaction success
  compType,
  nftTxHash,
  nftTxData,
  nftTxStatus = null,
}) => {
  //const NFTTxStatus = Storage.load('sendNFTTransferDetails')
  const { appData, actions } = useApp();
  const [modalData, setData] = useModalData();

  const pendingSendTxId = appData.pendingSendTxId;
  const pendingSendConfirmation = appData.pendingSendConfirmation;
  const transactionResult = appData.transactionResult;

  const [amt, setAmt] = useState("");
  const [fees, setFees] = useState("");
  const [fiatFees, setFiatFees] = useState("");
  const [fiatAmt, setFiatAmt] = useState("");
  const [date, setDate] = useState("");
  const [url, setUrl] = useState("");
  const [feeToken, setFeeToken] = useState("");
  const [sentToken, setSentToken] = useState("");

  const [stateStatus, setStateStatus] = useState(true);
  const [nftGasUsed, setNftGasUsed] = useState({});
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    //timer
    actions.setLoading(true);

    const timeoutId = setTimeout(() => {
      actions.setLoading(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [transactionResult]);

  useEffect(() => {
    if (nftTxData) {
      const d = new Date();
      setDate(
        dd(d.getHours()) +
          ":" +
          dd(d.getMinutes()) +
          " - " +
          dd(d.getDate()) +
          "/" +
          dd(d.getMonth() + 1) +
          "/" +
          d.getFullYear()
      );
    }
  }, [nftTxData]);

  useEffect(() => {
    async function getFeeDetails() {
      if (compType === "NFT") {
        //setNFTSendStatus()
        //const txStatus = Storage.load('sendNFTTransferDetails')
        //setNFTTxStatus(txStatus && 'pending')
        return;
      }
      if (trans) {
        setStateStatus(false);
        return;
      }
      const pendingTx = modalData?.transactionDetails;
      setStateStatus(pendingSendConfirmation);
      setAmt(pendingTx?.value);

      const feePerGas = pendingTx?.maxPriorityFeePerGas;
      if (feePerGas) {
        setFees(parseFloat(feePerGas) / Math.pow(10, 18));
        setFiatAmt(
          actions.getActiveCurrencySymbol() +
            "" +
            formatMoney(actions.activeCurrency(amt), 18)
        );
        setFiatFees(
          actions.getActiveCurrencySymbol() +
            " " +
            formatMoney(
              actions.activeCurrency(parseInt(feePerGas) / Math.pow(10, 18)),
              18
            )
        );
      }
    }
    getFeeDetails();

    //}
  }, [trans, pendingSendConfirmation]);

  useEffect(() => {
    if (
      nftTxData?.gasUsed &&
      nftTxData?.effectiveGasPrice &&
      compType === "NFT"
    ) {
      const gasInWei = nftTxData?.gasUsed * nftTxData?.effectiveGasPrice;

      const gasInEth = parseFloat(gasInWei) / Math.pow(10, 18);

      setNftGasUsed({ gasInEth, gasInWei });
      //setNFTSendStatus(nftTxData.status)
    }
    // setNFTSendStatus(nftTxHash.status)
  }, [nftTxData]);

  const dd = (nr) => {
    return nr.toString().padStart(2, "0");
  };

  const get_details = async () => {
    try {
      if (details && !trans?.data?.hash) {
        const chain = details?.chain;
        if (
          chain?.details?.symbol === "ARB" ||
          chain?.details?.symbol === "OP"
        ) {
          chain.details.symbol = "ETH";
        }
        setSentToken(details?.token?.symbol);
        setFeeToken(chain?.details?.symbol);

        const d = new Date();
        const fees = trans
          ? w3.utils.fromWei(
              (trans.gasUsed * trans.effectiveGasPrice).toString(),
              "ether"
            )
          : null;
        const amt = details.value;
        setAmt(amt);
        setFees(fees);
        setFiatAmt(
          actions.getActiveCurrencySymbol() +
            "" +
            (
              parseFloat(formatMoney(actions.activeCurrency(amt), 18)) *
              parseFloat(Storage.load("modalData")?.info?.price)
            ).toFixed(18)
        );
        const usdtRategasfee = await actions.getChainNativeToUsd(
          chain?.chainId
        );

        setFiatFees(
          actions.getActiveCurrencySymbol() +
            "" +
            parseFloat(formatMoney(actions.activeCurrency(fees), 18)) *
              parseFloat(
                usdtRategasfee?.data?.data?.[chain?.details?.symbol]?.quote?.USD
                  ?.price
              ).toFixed(18)
        );
        setDate(
          dd(d.getHours()) +
            ":" +
            dd(d.getMinutes()) +
            " - " +
            dd(d.getDate()) +
            "/" +
            dd(d.getMonth() + 1) +
            "/" +
            d.getFullYear()
        );

        const url = txnID
          ? actions.getScanURL(chain?.chainId) + "/tx/" + txnID
          : pendingSendTxId
          ? actions.getScanURL(chain?.chainId) + "/tx/" + pendingSendTxId
          : null;
        setUrl(url);
      } else {
        setSentToken("BTC");
        setFeeToken("BTC");
        const d = new Date();
        setDate(
          dd(d.getHours()) +
            ":" +
            dd(d.getMinutes()) +
            " - " +
            dd(d.getDate()) +
            "/" +
            dd(d.getMonth() + 1) +
            "/" +
            d.getFullYear()
        );
        setAmt(details?.amount / Math.pow(10, 8));
        const usdFeesRes = await actions.getAssetLatestPrice("BTC");
        setFiatAmt(
          actions.getActiveCurrencySymbol() +
            "" +
            (
              parseFloat(
                formatMoney(
                  actions.activeCurrency(details?.amount / Math.pow(10, 8)),
                  8
                )
              ) * usdFeesRes?.data?.BTC?.quote?.USD?.price
            ).toFixed(8)
        );
        setFees((details?.txSize * details?.satPerByte) / Math.pow(10, 8));
        setFiatFees(
          actions.getActiveCurrencySymbol() +
            "" +
            (
              parseFloat(
                formatMoney(
                  actions.activeCurrency(
                    details?.txSize * details?.satPerByte
                  ) / Math.pow(10, 8),
                  8
                )
              ) * usdFeesRes?.data?.BTC?.quote?.USD?.price
            ).toFixed(8)
        );
        setUrl("https://btcscan.org/tx/" + transactionResult?.data?.hash);
      }
    } catch (error) {}
  };
  useEffect(() => {
    get_details();
  }, [trans, details, pendingSendTxId]);

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w440 modal_p30",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_txn_transaction",
        "modal_txn_transaction_modif",
      ]}
      dataClass="modal_send_txn"
    >
      <div className={"modal_succes_content"}>
        {
          compType === "NFT" ? (
            <ModalHeadIcon
              extraClasses="mb-9"
              img={
                nftTxStatus === null
                  ? "images/swap/icon-pending.svg"
                  : nftTxData?.status && nftTxStatus
                  ? "images/swap/icon-done.svg"
                  : nftTxStatus === false && "images/swap/icon-error.svg"
              }
            />
          ) : (
            <ModalHeadIcon
              img={
                stateStatus
                  ? "images/swap/icon-pending.svg"
                  : !stateStatus && trans?.status
                  ? "images/swap/icon-done.svg"
                  : "images/swap/icon-error.svg"
              }
            />
          )
          //compType === "NFT" ? <ModalHeadIcon extraClasses = 'mb-9' img={ NFTSendStatus === null ? "images/swap/icon-pending.svg":( NFTSendStatus && nftTxData?.status) ? "images/swap/icon-done.svg" : (nftTxData?.status === false) && "images/swap/icon-error.svg"}  /> : <ModalHeadIcon img={ stateStatus?"images/swap/icon-pending.svg":( !stateStatus && trans?.status) ? "images/swap/icon-done.svg" : "images/swap/icon-error.svg"}  />
        }
        {/* <ModalHeadIcon span="icon-check-circle-solid" /> */}
        <div className={`${compType === "NFT" && "mb-9"} modal_succes_head`}>
          {compType === "NFT"
            ? `Transaction ${
                nftTxStatus === null
                  ? "Pending"
                  : nftTxStatus
                  ? "Done"
                  : "Failed"
              }`
            : `Transaction ${
                stateStatus
                  ? "Pending"
                  : !stateStatus && trans?.status
                  ? "Done"
                  : "Failed"
              }`}
        </div>
        {compType === "NFT" && nftTxStatus === false && (
          <div className="px-3 py-7 mb-8 text-2xl font-medium text-[#EA3943] bg-[#FBCFD2] text-center rounded-md">
            Txn Failed Try again
          </div>
        )}
        {compType !== "NFT" && (
          <div className="modal_succes_desc pt_20">
            <span className="simple_text simple_text_centered">
              TXN ID: <br />
              <span
                className="simple_text_bolder"
                style={{
                  maxWidth: 290,
                  display: "inline-block",
                  wordBreak: "break-word",
                }}
              >
                {txnID || pendingSendTxId || transactionResult?.data?.hash}
              </span>
            </span>
            <span className="simple_text simple_text_centered">
              Sent To: <br />
              <span className="simple_text_bolder">
                {sendTo || modalData?.transactionDetails?.to || details?.to}
              </span>
            </span>
          </div>
        )}
      </div>
      {compType !== "NFT" && (
        <div className="modal_succes_content modal_succes_content_extra">
          <div className="modal_succes_desc">
            <span
              className="simple_text simple_text_centered"
              style={{ lineHeight: "16px" }}
            >
              <span className="simple_text_bolder">Amount:</span>
              <span className="simple_text_bolder">
                {" "}
                {parseFloat(amt?.toString()?.replace(/\.?0+$/, ""))} {sentToken}{" "}
                ( {fiatAmt?.toString()?.replace(/\.?0+$/, "")} )
              </span>
            </span>
            <span
              className="simple_text simple_text_centered"
              style={{ lineHeight: "16px" }}
            >
              <span className="simple_text_bolder">Fees: </span>
              <span className="simple_text_bolder">
                {" "}
                {fees?.toString()?.replace(/\.?0+$/, "")} {feeToken} ({" "}
                {fiatFees?.toString()?.replace(/\.?0+$/, "")} )
              </span>
            </span>
            <span
              className="simple_text simple_text_centered"
              style={{ lineHeight: "16px" }}
            >
              <span className="simple_text_bolder">Timestamp: </span>
              <span className="simple_text_bolder"> {date}</span>
            </span>
          </div>
        </div>
      )}
      {compType === "NFT" && (
        <div className="flex items-center justify-center flex-col mb-4 mx-8">
          <div className="pb-3" style={{ maxWidth: "80%" }}>
            <NFTImage img={nftTxData?.image} imgClasses="rounded-[20px]" />
          </div>
          {
            <div className="flex flex-col items-center mb-5">
              {
                <span className="pb-1 text-slate-400 text-xl">
                  Gas Fee:{" "}
                  {nftTxStatus ? (
                    <span className="font-medium text-[#000]">
                      {`${nftGasUsed?.gasInEth} ` +
                        appData?.chains?.find(
                          (chain) => chain?.chainId === nftTxData?.chainId
                        )?.details?.symbol}
                    </span>
                  ) : (
                    <span className="font-medium text-[#000]">
                      {(parseFloat(nftTxData?.gasLimit) *
                        parseFloat(nftTxData?.maxFeePerGas)) /
                        Math.pow(10, 9)}
                    </span>
                  )}
                </span>
              }
              <span
                className="pb-1 text-slate-400 text-xl text-ellipsis whitespace-nowrap overflow-hidden"
                style={{ maxWidth: "80%" }}
              >
                Sent To:{" "}
                {
                  <span className="font-medium text-[#000]">
                    {nftTxData?.sendTo}
                  </span>
                }
              </span>
              <span className="pb-2 text-slate-400 text-xl">
                Timestamp:{" "}
                <span className="font-medium text-[#000]">{date}</span>
              </span>
            </div>
          }
          <div
            className="w-10/12 mb-3 p-3 bg-slate-100 relative flex flex-col items-start justify-start"
            style={{ height: 60 }}
          >
            <span className="text-slate-400">TXN #:</span>
            <span className="text-center font-medium text-2xl whitespace-nowrap text-ellipsis overflow-hidden max-w-[90%]">
              {nftTxHash && nftTxHash}
            </span>
            <div className="receive_token_address_copy-wrp pt-10">
              <TooltipCopy
                extraClasses="tooltip_wallet_details tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy justify-center"
                textToDisplay={""}
                imageToDisplay={
                  <span
                    className="icon_copy_field icon-icon_copy"
                    style={{ color: "black" }}
                  ></span>
                }
                tooltipTextIn={"Copy to clipboard"}
                tooltipTextInChange={"Copied to clipboard"}
                copiedText={nftTxHash && nftTxHash}
                hideAfter="3000"
              />
            </div>
          </div>
        </div>
      )}
      <div className="footer_txn_gray">
        <PrimaryBtn
          classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16 close_modal_ok"
          label="Done"
          onClick={() => onClose()}
        />
        {url || nftTxData?.url || nftTxData ? (
          <div className="modal_check_eth">
            <a
              href={
                compType !== "NFT"
                  ? url
                  : nftTxData?.url
                  ? nftTxData?.url
                  : appData?.chains?.find(
                      (chain) => chain?.chainId === nftTxData?.chainId
                    )?.details?.explorer +
                    "/tx/" +
                    nftTxHash
              }
              target="_blank"
              rel="noreferrer"
            >
              Check on blockchain explorer{" "}
              <span className="icon-icon_eth_scan"></span>
            </a>
          </div>
        ) : null}
      </div>
    </MainModal>
  );
};

export default ModalTransactionDone;
