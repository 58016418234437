import React, { useEffect } from "react";
import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  EDIT_LABEL_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";

const ModalEnterPinFunctions = () => {
  const { userData, userActions } = useUser();
  const { appData, actions } = useApp();

  const [modalData, setModalData] = useModalData();
  const [setActiveModalNav, goNext, goBackward] = useModalNavigation(
    EDIT_LABEL_WALLET_FLOW
  );

  const onClose = () => {
    setActiveModalNav("modal_edit_label_cancel_wallet", true);
  };

  const callbackOpenPopup = async (pin) => {
    actions.setLoading(true);
    const newLabel = modalData?.newLabel;
    const address = modalData?.address;
    const accountType = appData?.accounts?.find(
      (account) => account?.address === modalData?.address
    )?.type;
    const pinNo = pin;
    try {
      const resp = await userActions.changeWalletLabel(
        address,
        parseInt(pinNo),
        newLabel,
        accountType
      );

      if (resp?.error) {
        setActiveModalNav("modal_edit_label_failed_wallet");
      }
      if (resp === true) {
        setTimeout(async () => {
          setActiveModalNav("CLOSED");

          // const acc = await userActions.getAccounts();
          // const addreses = acc ? Object.values(acc) : {};

          // await actions.refreshWalletCardData(addreses);
          await actions.setLoading(false);

          setActiveModalNav("modal_edit_label_success_wallet");
        }, 500);
      } else {
        actions.setLoading(false);
        setActiveModalNav("modal_edit_label_failed_wallet");
      }
    } catch (error) {
      actions.setLoading(false);
    }
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav("modal_edit_label_cancel_wallet", true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <ModalEnterPin
      onClose={onClose}
      callbackOpenPopup={callbackOpenPopup}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalEnterPinFunctions;
