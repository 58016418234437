import React, { useState, useEffect } from "react";
import {
  // ArrowNarrowRightIcon,
  // MinusIcon,
  ArrowRightIcon,
} from "@heroicons/react/solid";

import SignInForm from "../../components/registration/SignInForm";
import SignInSlider from "../../components/registration/Slider";
import Modal from "../../components/registration/Modal/Modal";
import Footer from "../../components/registration/Footer/Footer";
// import Preloader from '../../components/registration/Preloader/Preloader'
import CssFix from "./../../helpers/cssFix";
import "../../styles/registration/signIn.scss";
import Storage from "./../../helpers/Storage";
import initialStateRegistration from "../../context/initialStateRegistration";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useFlowGate } from "../../components/flow/FlowGate";

const SignIn = ({ history }) => {
  const { resetFlowGate } = useFlowGate();
  const { resetState, _regenKey } = useRegistration();
  useEffect(() => {
    document.title = "Login | safle";

    resetState();
    _regenKey().then((encryptionKeyResp) => {
      initialStateRegistration.encryptionKey = encryptionKeyResp;
      Storage.save("registration", initialStateRegistration);
    });
    Storage.remove("user");
    resetFlowGate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-auto bg-white shadow rounded-2xl relative ">
            <div className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24">
              <div className="flex">
                <div className="w-full lg:w-2/5 lg:mr-12">
                  <div className="mb-8">
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                    <p className="text-xs text-gray-400 flex items-center fw-500 pt--14 ">
                      User Login
                      <span className="minus-dash"></span>
                    </p>
                  </div>
                  <SignInForm history={history} />
                </div>

                <div className="justify-center w-0.5 lg:h-96 lg:my-20 bg-gray-300 mx-10 hidden lg:block sign_up_middle_spacer"></div>
                <div className="lg:block w-2/5 slider-size-fix lg:ml-12 lg:mb-40 flex justify-center add_to_chrome">
                  <div className="mb-20 flex justify-end">
                    <span
                      className="flex items-center text-blue-500  text-xs cursor-pointer"
                      onClick={() => setModalIsOpen(true)}
                    >
                      Add to Chrome
                      <ArrowRightIcon className="h-2 w-2 ml-1" />
                    </span>
                  </div>
                  <div className="w-full mx-auto ">
                    <SignInSlider />
                  </div>
                </div>
              </div>
            </div>
            <img
              alt="rocket"
              src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
              className="h-32 w-60 absolute -right-16 bottom-0 z-10 hidden lg:block"
            />
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Download"
        callToAction={() => {
          window.open(process.env.REACT_APP_EXTENSION_LINK);
        }}
        isOpen={modalIsOpen}
        extraClass={"install_safle_chrome"}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};

export default SignIn;
