import React, { useState ,useRef , useEffect } from 'react';

 

const TooltipBlackBackground = ({
  title,
  description,
  hideAfter = 3000,
  stylePassed,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const timerRef = useRef(null);

  const onClickTooltip = (e) => {
    e.preventDefault();
    setShowTooltip(true);
    //timeout after 3000ms do something
    timerRef.current = setTimeout(() => {
      //setState to remove Opened Class
      setShowTooltip(false);
    }, hideAfter);
  };
  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  const activeClass = showTooltip ? "active " : " ";
  const cssClasses = "tooltip ";
  return (
    <>
      <div
        className={cssClasses + activeClass + stylePassed}
        onClick={(e) => onClickTooltip(e)}
        // onClick={ () => tooltipActive ? setTooltipActive(false) : setTooltipActive(true) }
      >
        <span className="ico icon-info-circle-solid" />
        <span
          className="tooltiptext"
          // style={ tooltipActive ? { visibility: 'visible' } : { visibility: '' } }
        >
          <span className="title">{title}</span>
          <div className="text">{description}</div>
        </span>
      </div>
    </>
  );
};

export default TooltipBlackBackground;
