import React, { useState, useEffect } from "react";
// import TokenIcon from "../common/TokenIcon/TokenIcon";
import MainModal from "./../generic/MainModal";
import { useApp } from "./../../helpers/AppProvider";
// import { useUser } from "./../../helpers/UserProvider";
import Blockie from "../common/Blockie/Blockie";
import { limitTitleFromMiddle } from "../../helpers/generalHelper";

const ModalSwapSelectWallet = ({ onClose }) => {
  const { actions, appData } = useApp();
  // const { userActions } = useUser();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    async function getAccounts() {
      if (
        appData?.accounts?.filter((account) => account.type === "evm")?.length >
        0
      ) {
        const accounts = await appData?.accounts?.filter(
          (account) => account.type === "evm"
        );

        setAccounts(Object.values(accounts));
      }
    }
    getAccounts();
  }, [appData?.accounts]);

  const onClickItem = (activeAccountAddress) => {
    actions.setAppData({
      activeAccountAddress: activeAccountAddress,
      activeWallet: activeAccountAddress,
    });
    onClose();
  };

  return (
    <MainModal
      title="Select Wallet"
      onClose={() => onClose()}
      extraClasses={["modal_w400 modal_mh368 modal_swap_select_wallet"]}
      dataClass="modal_swap_select_wallet"
    >
      <div className="modal_chain_items">
        {accounts?.map((item, i) => (
          <div
            className="modal_wallet_item"
            key={i}
            onClick={() => onClickItem(item?.address)}
          >
            <div className="asset__coin form__input_filter_item" data-name={""}>
              <div className="token-swap-wallet-container">
                <div>
                  <Blockie
                    address={item?.address}
                    label={
                      typeof item?.label === "string"
                        ? item?.label
                        : item?.label?.polygon
                      // item?.label[
                      //   appData.chains[appData.activeChain || 0]?.chain_name
                      // ] || item?.label
                    }
                  />
                </div>
                <div className="asset__coin_content">
                  <div className="asset__coin_names">
                    <div className="wallet__name">
                      {
                        typeof item?.label === "string"
                          ? item?.label
                          : item?.label?.polygon
                        // item?.label[
                        //   appData.chains[appData.activeChain || 0]?.chain_name
                        // ] || item?.label
                      }
                    </div>
                    <div className="asset__coin-name">
                      {limitTitleFromMiddle(item.address, 10)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </MainModal>
  );
};

export default ModalSwapSelectWallet;
