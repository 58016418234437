import React, { useEffect } from "react";

import ModalRemoveWalletPopup from "./../../dialogs/ModalRemoveWalletPopup";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
  RESTORE_WALLET_FLOW,
} from "../../../constants/ui";
import ModalActionConfirm from "../../dialogs/ModalActionConfirm";

const ModalRestoreWalletFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    //setActiveModalNav("modal_restore_enter_pin");
    goNext();
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav();
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <ModalActionConfirm
      onClose={() => setActiveModalNav("modal_restore_enter_pin")}
      title={"Are you sure you want to restore the wallet?"}
      goBackward={() => setActiveModalNav("CLOSED")}
    />
  );
};

export default ModalRestoreWalletFunctions;
