import React from 'react';
import MainModal from '../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PinInput from '../common/PinInput/PinInput';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalPinConfirm = ({ onClose }) => {

    return (
        <MainModal extraClasses={["modal_w400", "modal_mh400", "modal_dflex", "modal_enter_pin", "modal_pin_confirm"]} onClose={onClose} dataClass="modal_enter_pin">
            <div className="modal_succes_content">
                <ModalHeadIcon img="images/icon-pin.svg" />
                <div className="modal_succes_head ">Enter verification code</div>
                <div className="modal_succes_desc">
                    <span className="simple_text simple_text_centered">We have sent you an email with OTP</span>
                </div>
                <div className="modal_pin_pad-wrp">
                    <PinInput timer={true} resendCode={true} />
                </div>
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <PrimaryBtn
                        classes="btn-default btn-white-bordered close_modal_cancel"
                        label="Cancel"
                    />
                    <PrimaryBtn
                        classes="btn-default btn-blue modal__trigger"
                        label="Verify"
                    />
                </div>
            </div>
        </MainModal>
    );
};

export default ModalPinConfirm;