import React, { useEffect }  from 'react';

import ModalActionFailed from './../../dialogs/ModalActionFailed';



import useModalNavigation from '../../../helpers/useModalNavigation';
import { REMOVE_WALLET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalFailedFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [setActiveModalNav, goNext, goBackward] = useModalNavigation(REMOVE_WALLET_FLOW);
    
    const onClose = () => {
        // setActiveModalNav(DEFAULT_MODAL_STATUS);
        setActiveModalNav(DEFAULT_MODAL_STATUS);
    };
    const callbackRetryPopup = (popup) => {
        setActiveModalNav('modal_remove_enter_pin');
    }

    const callbackOpenPopup = (popup) => {
        // setActiveModalNav(popup);
        // setActiveModalNav('modal_remove_cancel_wallet',true);
        setActiveModalNav(DEFAULT_MODAL_STATUS);
        
    };
    
    const callbackCancelPopup = (popup) => {
        // setActiveModalNav(popup);
        // setActiveModalNav('modal_remove_cancel_wallet',true);
        
        setActiveModalNav('modal_remove_cancel_wallet',true);
        
    };
    
   
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return (
        <ModalActionFailed title="Wallet remove failed"
            desc="" dataClass="modal_action_failed1"
            onClose={callbackCancelPopup}
            
           
            iconErr={'images/wallets/update-failed-warning.svg'}
            callbackOpenPopup={callbackOpenPopup} //close modal
            callbackRetryPopup={callbackRetryPopup} // redirect
            
        />
        
    )
}

export default ModalFailedFunctions;