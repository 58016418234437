import { ChevronRightIcon } from "@heroicons/react/outline";
import SettingsSubmenuItemMobile from "../SettingsSubmenuItemMobile/SettingsSubmenuItemMobile";

const SettingsMenuItemMobile = (props) => {
  return (
    <>
      <div
        onClick={props.subMenuHandler}
        className={
          props.submenu
            ? "settings_menu_item cursor-pointer"
            : "settings_menu_item"
        }
      >
        <div className="settings_menu_icon">
          <span className={props.icon}></span>
        </div>
        <div className="settings_menu_item_content">
          <div className="settings_item_title">{props.title}</div>
          <div className="settings_item_text">{props.text}</div>
        </div>
        {props.submenu && (
          <ChevronRightIcon
            className={
              props.subMenuActive || props.subMenuActiveLegal
                ? "chevron-right-icon icon-rotate"
                : "chevron-right-icon"
            }
          />
        )}
      </div>

      <div className="submenu-box-shadow">
        {props.subMenuActive && (
          <SettingsSubmenuItemMobile
            icon="icon-settings-safle-logo"
            title="Change SafleID"
            text="you can change your ID 3 times"
            titleClass="fs_14"
            onClickAction={props.changeSafleId}
          />
        )}
        {/* { props.subMenuActive &&
          <SettingsSubmenuItemMobile
            icon="icon-nav_wallet" 
            title="Generate Wallet"
            text="Generate wallets inside the vault" 
            titleClass="fs_14"
            onClickAction={props.addNewWalletAction}
          /> 
        } */}

        {/* // @TODO To be added at some point */}
        { props.subMenuActive &&
          <SettingsSubmenuItemMobile
            img="images/settings/mobile-icons/submenu/link.svg" 
            title="Link to SafleID"
            text="Link your wallet to SafleID" 
            titleClass="fs_14"
            setLinkSafleIdOpen={props.openSubMenuItem}
          /> 
        }
        {/* { props.subMenuActive &&
          <SettingsSubmenuItemMobile
            img="images/settings/mobile-icons/submenu/seeds.svg" 
            title="Backup Seeds"
            text="Backup and keep the seeds safe" 
            titleClass="fs_14"
            onClickAction={props.backupSeedsAction}
          /> 
        } */}

        {props.subMenuActiveLegal && (
          <SettingsSubmenuItemMobile
            icon="icon-settings-terms"
            title="Privacy Policy"
            titleClass="fs_16"
            link={process.env.REACT_APP_LINK_PRIVACY}
          />
        )}
        {props.subMenuActiveLegal && (
          <SettingsSubmenuItemMobile
            img="images/settings/mobile-icons/submenu/terms.svg"
            title="Terms &#38; Conditions"
            titleClass="fs_16"
            link={process.env.REACT_APP_LINK_TERMS}
          />
        )}
      </div>
    </>
  );
};

export default SettingsMenuItemMobile;
