const TokenDecimalInput = ( { decimal, onChange }) => {

    return (
        <div className="form__input form__input_default">
            <span className="input_label">Token Decimal</span>
            <div className="form__input_default">
                <input
                type="text"
                className="form-input-default"
                placeholder="Enter Token Decimal"
                onChange={ onChange }
                value={ decimal }
                />
            </div>
        </div>
    )
}

export default TokenDecimalInput;
