/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { CheckCircleIcon, LockClosedIcon } from "@heroicons/react/solid/esm";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from "../../components/registration/Button/Button";
import Modal from "../../components/registration/Modal/Modal";
import SeedsConfirm from "../../components/registration/SeedsConfirm/SeedsConfirm";
import Footer from "../../components/registration/Footer/Footer";
import InputGroup from "../../components/registration/InputGroup/InputGroup";
import OTPInputs from "../../components/registration/OTPInputs/OTPInputs";
import Preloader from "../../components/common/Preloader/Preloader";
import "../../styles/registration/base.scss";
import "../../styles/registration/newPassword.scss";
import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import { useApp } from "../../helpers/AppProvider";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useFlowGate } from "../../components/flow/FlowGate";
const min_pass_lenght = 8;
const initialValues = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
};

const NewPasswordScreen = () => {
  const { advance } = useFlowGate();
  const { actions } = useApp();

  let navigate = useNavigate();
  const {
    otpStatus,
    tempPassword,
    setTempPassword,
    mnemonic,
    setMnemonic,
    mnemonicArr,
    setMnemonicArr,
    setPin,
  } = useRegistration({});

  const recaptchaRef = useRecaptcha();
  const { userActions } = useUser();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [password, setPassword] = useState();
  const [checkOneLowerChar, setCheckOneLowerChar] = useState(false);
  const [checkOneUpperChar, setCheckOneUpperChar] = useState(false);
  const [checkOneNumber, setCheckOneNumber] = useState(false);
  const [checkMinLength, setCheckMinLength] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const [checkConfirmPassword, setCheckConfirmPassword] = useState(null);
  const [numbers, setNumbers] = useState(initialValues);
  const [confirm_numbers, setConfirmNumbers] = useState(initialValues);
  const [error, setError] = useState(false);
  const [pwChangeSuccess, setPwChangeSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [step, setStep] = useState(0);
  useEffect(() => {
    actions.setLoading(false);
  }, []);

  useEffect(() => {
    setError("");

    setMnemonic(Object.values(mnemonicArr).join(" "));
  }, [mnemonicArr]);

  useEffect(() => {
    if (!comparePIN()) {
      setPin(numbers);
    }
  }, [confirm_numbers, numbers]);

  const comparePIN = () => {
    const newPin = Object.values(numbers).join("");
    const confirmNewPin = Object.values(confirm_numbers).join("");
    if (
      confirmNewPin !== newPin ||
      newPin.length < 6 ||
      confirmNewPin.length < 6
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePassword = (e) => {
    let value = e.target.value;

    if (/[a-z]/.test(value)) setCheckOneLowerChar(true);
    else setCheckOneLowerChar(false);

    if (/[A-Z]/.test(value)) setCheckOneUpperChar(true);
    else setCheckOneUpperChar(false);

    if (/\d/.test(value)) setCheckOneNumber(true);
    else setCheckOneNumber(false);

    if (value.length >= min_pass_lenght) setCheckMinLength(true);
    else setCheckMinLength(false);

    if (
      value === confirmPassword &&
      value.length !== 0 &&
      confirmPassword.length !== 0 &&
      checkOneLowerChar &&
      checkOneUpperChar &&
      checkOneNumber &&
      checkMinLength
    )
      setCheckConfirmPassword(true);
    else setCheckConfirmPassword(false);

    setPassword(value);
    setTempPassword(value);
  };

  const handleConfirmPassword = (e) => {
    let value = e.target.value;
    if (
      value === password &&
      value.length !== 0 &&
      password.length !== 0 &&
      checkOneLowerChar &&
      checkOneUpperChar &&
      checkOneNumber &&
      checkMinLength
    ) {
      setCheckConfirmPassword(true);
    } else setCheckConfirmPassword(false);

    setConfirmPassword(value);
  };

  const setPinNumbers = (vals) => {
    let nums = numbers;
    let updated = Object.entries(vals).reduce((acc, el) => {
      if (el[1] !== "") {
        acc[el[0]] = el[1];
      } else {
        acc[el[0]] = "";
      }
      return acc;
    }, {});
    const new_numbers = { ...nums, ...updated };
    setNumbers(new_numbers);
  };
  const setConfirmPinNumbers = (vals) => {
    let nums = numbers;
    let updated = Object.entries(vals).reduce((acc, el) => {
      if (el[1] !== "") {
        acc[el[0]] = el[1];
      } else {
        acc[el[0]] = "";
      }
      return acc;
    }, {});
    const new_numbers = { ...nums, ...updated };
    setConfirmNumbers(new_numbers);
  };

  const handleSubmit = (e) => {
    setError(false);
    setStep(1);
  };

  const closeSuccessModal = (val) => {
    setPwChangeSuccess(val);
    if (val === false) {
      navigate(ROUTES.HOME, { replace: true });
      advance();
    }
  };

  const handleResetPass = async (e) => {
    actions.setLoading(true);
    setError("");

    try {
      const token = await recaptchaRef.current.executeAsync();

      const mnems = mnemonic;
      /* ------------- ------------------ */
      /* changePassword( email, safleId, password, mnemonics, pin, recaptchaToken ) - changes user password after being verified
            /* -------------------------------- */
      const safleID = await userActions.getSafleIdByEmail(otpStatus.email);

      /* ------------- ------------------ */
      /* validateMnemonic( safleId, mnemonics ) - validates mnemonics belong to safleId vault
            /* -------------------------------- */
      const valid = await userActions.validateMnemonic(safleID, mnems, token);

      if (valid) {
        setStep(2);
      } else {
        setError("You have entered wrong seed phrase");
      }
    } catch (e) {
      setError("You have entered wrong seed phrase");
    }

    actions.setLoading(false);
  };

  const handleVaultRestore = async () => {
    actions.setLoading(true);
    setError(false);

    const safle_mnems = mnemonic;
    const safle_pin = Object.values(numbers).join("");

    const safle_id = await userActions.getSafleIdByEmail(otpStatus.email);
    const safle_email = otpStatus.email;
    const safle_pass = tempPassword;

    const token = await recaptchaRef.current.executeAsync();
    /* ------------- ------------------ */
    /* changePassword( email, safleId, password, mnemonics, pin, recaptchaToken ) - changes user password after being verified
        /* -------------------------------- */
    if (safle_pin === "123456") {
      actions.setLoading(false);
      setError("You cannot  use symbols, letters or 123456 for your pin");
      return;
    }

    const resp = await userActions.changePassword(
      safle_email,
      safle_id,
      safle_pass,
      safle_mnems,
      parseInt(safle_pin),
      token
    );

    try {
      if (resp || resp.message === "Password changed succesfully") {
        actions.setLoading(false);
        setPwChangeSuccess(true);
      } else {
        actions.setLoading(false);
        setError("Something went wrong, please try again later.");
      }
    } catch (e) {
      actions.setLoading(false);
      setError("Something went wrong, please try again later.");
    }
  };

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/migration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          {step === 0 && (
            <div className="md:h-full bg-white shadow rounded-2xl relative ">
              <Preloader />
              <div
                className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
                style={{
                  backgroundImage:
                    "url(" +
                    `${process.env.PUBLIC_URL}/images/migration/img/reset_password_fake_slider.png` +
                    ")",
                  backgroundSize: "contain",
                  backgroundPosition: "100% bottom",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="flex">
                  <div className="w-full lg:w-3/5 lg:mr-6">
                    <div className="mb-8">
                      <img
                        alt="safle logo"
                        className="mb-4 h-8 w-20 hidden sm:block"
                        src={`${process.env.PUBLIC_URL}/images/migration/img/logo-dark.svg`}
                      />
                    </div>

                    <>
                      <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change">
                        Set new password
                      </h1>
                      <div className={"my-5"}>
                        <InputGroup
                          label="Password *"
                          placeholder="Enter password"
                          type={passwordShown ? "text" : "password"}
                          hiddenEye={
                            <EyeOffIcon
                              onClick={() => setPasswordShown(true)}
                              className={
                                passwordShown
                                  ? "h-5 w-5 text-gray-400 hidden"
                                  : "h-5 w-5 text-gray-400"
                              }
                              aria-hidden="true"
                            />
                          }
                          visibleEye={
                            <EyeIcon
                              onClick={() => setPasswordShown(false)}
                              className={
                                !passwordShown
                                  ? "h-5 w-5 text-gray-400 hidden"
                                  : "h-5 w-5 text-gray-400"
                              }
                              aria-hidden="true"
                            />
                          }
                          icon={
                            <LockClosedIcon
                              className="h-5 w-5 text-gray-400 icon_dark_gray"
                              aria-hidden="true"
                            />
                          }
                          value={password}
                          onChange={handlePassword}
                        />
                        <InputGroup
                          label="Confirm Password *"
                          placeholder="Confirm password"
                          type={confirmPasswordShown ? "text" : "password"}
                          hiddenEye={
                            <EyeOffIcon
                              onClick={() => setConfirmPasswordShown(true)}
                              className={
                                confirmPasswordShown
                                  ? "h-5 w-5 text-gray-400 hidden"
                                  : "h-5 w-5 text-gray-400"
                              }
                              aria-hidden="true"
                            />
                          }
                          visibleEye={
                            <EyeIcon
                              onClick={() => setConfirmPasswordShown(false)}
                              className={
                                !confirmPasswordShown
                                  ? "h-5 w-5 text-gray-400 hidden"
                                  : "h-5 w-5 text-gray-400"
                              }
                              aria-hidden="true"
                            />
                          }
                          icon={
                            <LockClosedIcon
                              className="h-5 w-5 text-gray-400 icon_dark_gray"
                              aria-hidden="true"
                            />
                          }
                          value={confirmPassword}
                          valid={checkConfirmPassword}
                          onChange={handleConfirmPassword}
                        />
                        <div className="my-2 justify-between grid grid-cols-2">
                          <div className="flex items-center">
                            <div className="h-4 w-4 flex items-center justify-center">
                              {checkOneLowerChar ? (
                                <CheckCircleIcon
                                  className={"h-4 w-4 text-blue-500"}
                                />
                              ) : (
                                <div
                                  className={
                                    "h-3 w-3 border border-gray-400 rounded-full"
                                  }
                                ></div>
                              )}
                            </div>
                            <div className="ml-1 text-sm">
                              <p
                                className={
                                  checkOneLowerChar
                                    ? " checkbox_label checked "
                                    : "checkbox_label "
                                }
                              >
                                One lowercase character
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="h-4 w-4 flex items-center justify-center">
                              {checkOneUpperChar ? (
                                <CheckCircleIcon
                                  className={"h-4 w-4 text-blue-500"}
                                />
                              ) : (
                                <div
                                  className={
                                    "h-3 w-3 border border-gray-400 rounded-full"
                                  }
                                ></div>
                              )}
                            </div>
                            <div className="ml-1 text-sm">
                              <p
                                className={
                                  checkOneUpperChar
                                    ? "checkbox_label checked "
                                    : "checkbox_label "
                                }
                              >
                                One uppercase character
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-2 justify-between grid grid-cols-2">
                          <div className="flex items-center">
                            <div className="h-4 w-4 flex items-center justify-center">
                              {checkOneNumber ? (
                                <CheckCircleIcon
                                  className={"h-4 w-4 text-blue-500"}
                                />
                              ) : (
                                <div
                                  className={
                                    "h-3 w-3 border border-gray-400 rounded-full"
                                  }
                                ></div>
                              )}
                            </div>
                            <div className="ml-1 text-sm">
                              <p
                                className={
                                  checkOneNumber
                                    ? "checkbox_label checked "
                                    : "checkbox_label "
                                }
                              >
                                One number
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="h-4 w-4 flex items-center justify-center">
                              {checkMinLength ? (
                                <CheckCircleIcon
                                  className={"h-4 w-4 text-blue-500"}
                                />
                              ) : (
                                <div
                                  className={
                                    "h-3 w-3 border border-gray-400 rounded-full"
                                  }
                                ></div>
                              )}
                            </div>
                            <div className="ml-1 text-sm">
                              <p
                                className={
                                  checkMinLength
                                    ? "checkbox_label checked "
                                    : "checkbox_label "
                                }
                              >
                                {min_pass_lenght} characters minimum
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Button
                        text="Submit"
                        size="lg"
                        fullwidth
                        extra_class="submit-button"
                        onClick={handleSubmit}
                        disabled={!checkConfirmPassword}
                      />
                    </>
                  </div>

                  <div className="justify-center w-0.5 lg:h-96 lg:my-20 bg-gray-300 mx-10 hidden lg:block sign_up_middle_spacer"></div>

                  <div className="lg:block w-2/5  lg:ml-12 flex justify-center pt-20">
                    <div className="w-full mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="md:h-full bg-white shadow rounded-2xl relative ">
              <Preloader />
              <div
                className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
                style={{
                  backgroundImage:
                    "url(" +
                    `${process.env.PUBLIC_URL}/images/migration/img/reset_password_fake_slider.png` +
                    ")",
                  backgroundSize: "contain",
                  backgroundPosition: "100% bottom",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="flex">
                  <div className="w-full lg:w-2/5 lg:mr-6">
                    <div className="mb-8">
                      <img
                        alt="safle logo"
                        className="mb-4 h-8 w-20 hidden sm:block"
                        src={`${process.env.PUBLIC_URL}/images/migration/img/logo-dark.svg`}
                      />
                    </div>
                    <>
                      <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change rp_h">
                        Enter Seed Phrase
                        <p className={"mt-4 text-xs text-left rp_p"}>
                          Enter your seed phrase to reset your password
                        </p>
                      </h1>

                      <div className={"mb-10"}>
                        <SeedsConfirm onUpdate={setMnemonicArr} />

                        {error && (
                          <div className="flex my-3 lg:block seed_error_msg">
                            {error}
                          </div>
                        )}
                      </div>
                      <Button
                        text="Confirm"
                        size="lg"
                        fullwidth
                        extra_class="submit-button"
                        onClick={handleResetPass}
                        disabled={mnemonicArr.filter((e) => e === "").length}
                      />
                    </>
                  </div>

                  <div className="justify-center w-0.5 lg:h-96 lg:my-20 bg-gray-300 mx-10 hidden lg:block sign_up_middle_spacer"></div>

                  <div className="lg:block w-2/5  lg:ml-12 flex justify-center pt-20">
                    <div className="w-full mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="md:h-full bg-white shadow rounded-2xl relative ">
              <Preloader />
              <div
                className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
                style={{
                  backgroundImage:
                    "url(" +
                    `${process.env.PUBLIC_URL}/images/migration/img/background-full@2x.png` +
                    ")",
                  backgroundSize: "contain",
                  backgroundPosition: "100% bottom",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="flex">
                  <div className="w-full lg:w-2/5 lg:mr-12">
                    <div className="mb-8">
                      <img
                        alt="safle logo"
                        className="mb-4 h-8 w-20 hidden sm:block"
                        src={`${process.env.PUBLIC_URL}/images/migration/img/logo-dark.svg`}
                      />
                    </div>

                    <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change rp_h">
                      Set new PIN
                      <p className={"mt-4 text-xs text-left rp_p"}>
                        Your PIN will be used to encrypt your Safle Vault and
                        sign transactions in dApps.
                      </p>
                    </h1>
                    <div className="mb-10">
                      {
                        <OTPInputs
                          autofocus
                          numbers={numbers}
                          setNumbers={setPinNumbers}
                        />
                      }
                      <div
                        className="flex my-3 pl-0 lg:block seed_error_msg"
                        style={{ marginLeft: 0 }}
                      >
                        {error}
                      </div>
                    </div>
                    <p className={"mt-4 text-xs text-left rp_p"}>
                      Enter PIN again
                    </p>
                    <div className="mb-10">
                      {
                        <OTPInputs
                          autofocus={false}
                          numbers={confirm_numbers}
                          setNumbers={setConfirmPinNumbers}
                        />
                      }
                      <div
                        className="flex my-3 pl-0 lg:block seed_error_msg"
                        style={{ marginLeft: 0 }}
                      >
                        {error}
                      </div>
                    </div>
                    <Button
                      text="Confirm"
                      size="lg"
                      fullwidth
                      onClick={handleVaultRestore}
                      disabled={comparePIN()}
                      style={{
                        opacity: comparePIN() ? 0.5 : 1,
                      }}
                    />
                    <div className="mb-10"></div>
                  </div>

                  <div className="justify-center w-0.5 lg:h-96 lg:my-20 mx-10 hidden lg:block"></div>

                  <div className="lg:block w-2/5  lg:ml-12 flex justify-center pt-20">
                    <div className="w-full mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Download"
        extraClass={"install_safle_chrome"}
        isOpen={modalIsOpen}
        setOpen={(val) => setModalIsOpen(val)}
      />
      <Modal
        title="Password is updated"
        bodyText={
          <>
            You can now login using the
            <br /> new password.
          </>
        }
        callToActionText="Ok"
        isOpen={pwChangeSuccess}
        extraClass={"pass_updated"}
        setOpen={(val) => closeSuccessModal(val)}
        icon={`images/registration/img/icon_done.svg`}
      />
    </div>
  );
};

export default NewPasswordScreen;
