import React from "react";
import { useApp } from "../../../helpers/AppProvider";

const Preloader = () => {
  const { appData } = useApp();
  // const loading = false;

  return (
    <>
      {appData.loading === true &&
        window.location.pathname !== "/dashboard" &&
        // window.location.pathname !== "/asset-detail" &&
        window.location.pathname !== "/lifi" && (
          <div className="fixed left-0 top-0 w-full h-full z-[1000] opacity-100 flex flex-col justify-center items-center">
            {/* <style>
            { `.fade {
              animation: fade 0.4s 0s ease;
            }
            .fade-bg {
              animation: fadebg 0.8s 0s ease;
            }
            @keyframes fadebg {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 0.30;
              }
            }
            @keyframes fade {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }`}
          </style> */}
            <div
              className="fade-bg absolute left-0 top-0 w-full h-full z-1 opacity-30"
              style={{ backgroundColor: "rgba(0,0,0,1)" }}
            ></div>
            <div className="fade p-4 bg-white border border-solid border-slate-100 rounded-xl z-10 flex flex-col items-center justify-center">
              {appData.loadingMessage && (
                <div className="text-[14px]">{appData.loadingMessage}</div>
              )}
              <img
                src="/images/registration/loading.svg"
                alt="loading"
                width={40}
                className="my-4"
              />
            </div>
          </div>
        )}
    </>
  );
};

export default Preloader;
