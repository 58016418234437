import React, { useState, useEffect, useRef } from "react";
import DropdownEllipsisItem from "../DropdownEllipsisItem/DropdownEllipsisItem";
import { useApp } from "./../../../helpers/AppProvider";

const DropdownEllipsis = (props) => {
  const { actions } = useApp();

  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  // const [dropdown, setDropdown] = useState(false);

  //populate an object with items

  const filteredItems = props.items;
  // first time load the first address in the object
  // or select the selected used address
  useEffect(() => {
    // setDropdown(filteredItems[0]);
  }, []);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes = "main__controls controls__dropdown-ellipsis ";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        // document.body.classList.remove('no_scroll_dropdown');
        actions.setAppData({ blockScroll: false });
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: true });
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: false });
      // document.body.classList.remove('no_scroll_dropdown');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    alert(addr.action_name);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    // document.body.classList.remove('no_scroll_dropdown');
    actions.setAppData({ blockScroll: false });
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(filteredItems.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //filteredItems[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, filteredItems[updateHighlightVal]);
      setHighlightedItem("");
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div className="ellipsis_trigger" onClick={onClick}>
        <span className="icon-ellipsis-v-solid"></span>
      </div>
      <div className="dropdown__list__items_ellipsis">
        {filteredItems.map((dummyAddres, index) => (
          <DropdownEllipsisItem
            items={dummyAddres}
            highlight={index === highlighted}
            key={index}
            clickHandle={() => onClickItem(index, dummyAddres)}
          />
        ))}
      </div>
    </div>
  );
};

export default DropdownEllipsis;
