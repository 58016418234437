import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
// import PinInput from "../common/PinInput/PinInput";
// import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import OTPInputs from "./../../components/registration/OTPInputs/OTPInputs";
import "../../styles/registration/otpVerification.scss";

// import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";

const initialValues = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
};

const ModalEnterPin = (attrs) => {
  const props = attrs;
  // const { actions } = useApp();
  const { userActions } = useUser();
  const [numbers, setNumbers] = useState(initialValues);
  // eslint-disable-next-line no-unused-vars
  const [forceRefresh, setForceRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verifyPin = async () => {
    try {
      const pinNo = Object.values(numbers).join("");
      const resp = await userActions.validatePin(pinNo);

      if (typeof resp !== "object" && resp === true) {
        props.callbackOpenPopup(pinNo);
      } else {
        if (resp?.error) {
          setErrorMessage(resp?.error);
        } else {
          setErrorMessage("Invalid PIN");
        }
      }
    } catch (e) {}
  };

  useEffect(() => {
    attrs.onCompletePIN && attrs.onCompletePIN(numbers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbers]);

  let confirmBtnLabel = "Verify";
  if (props && props?.labelContinue) {
    confirmBtnLabel = props?.labelContinue;
  }

  return (
    <MainModal
      onClose={() => props.onClose(props?.modalCancel_label)}
      extraClasses={[
        "modal_w440",
        "modal_mh400",
        "modal_dflex",
        "modal_enter_pin",
        "modal_pin_confirm",
        "modal_pin_otp_confirm",
      ]}
      dataClass="modal_enter_pin"
    >
      <div className="modal_succes_content">
        {/* <CssFix /> */}
        <ModalHeadIcon img={"images/icon-pin.svg"} />
        <div className="modal_succes_head ">Enter PIN to proceed</div>
        <div className="modal_pin_pad-wrp">
          <div className="otp_inputs_verify">
            {/* <PinInput onCompletePIN={props?.onCompletePIN} timer={true} resendCode={true} /> */}
            {/* <PinInput timer={true} resendCode={true} /> */}
            <OTPInputs
              numbers={numbers}
              setNumbers={setNumbers}
              // disabled={!counting}
              forceRefresh={forceRefresh}
              errorCallback={setErrorMessage}
              otherParentClass={
                "mt-1 relative opt_inputs flex items-center verifyOtpInput"
              }
            />
            <div className="flex my-3 lg:block seed_error_msg no_padding_left validation">
              {" "}
              &nbsp; {errorMessage || props.errorMessage || ""} &nbsp;
            </div>
          </div>
        </div>
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={() => props.callbackCancelPopup(props?.modalCancel_label)}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue "
            onClick={verifyPin}
            disabled={Object.values(numbers).join("").length < 6}
            style={{
              opacity: Object.values(numbers).join("").length < 6 ? 0.5 : 1,
            }}
          >
            {confirmBtnLabel}
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalEnterPin;
