const StatusBadge = ({ handleEnabled, enabled }) => {
  return (
    <span
      onClick={handleEnabled}
      className={`enable_disable ${enabled ? "enb" : ""}`}
    >
      {enabled ? "Enabled" : "Disabled"}
    </span>
  );
};

export default StatusBadge;
