class FeeEstimator {
  // constructor() {}

  feeTimeEstimate(chosen, chain) {
    const chainIdToNetwork = {
      1: "ethereum",
      137: "polygon",
    };

    const timeEstimates = {
      ethereum: {
        low: "Maybe in 30 seconds",
        medium: "Likely in < 30 seconds",
        high: "Very likely in < 15 seconds",
      },
      polygon: {
        low: "Maybe in 30 seconds",
        medium: "Likely in < 30 seconds",
        high: "Very likely in < 15 seconds",
      },
    };
    try {
      return timeEstimates[chainIdToNetwork[chain.chainId]][chosen];
    } catch (e) {
      return "Unknown seconds";
    }
  }

  async estimateFee(chain) {
    let activeChain = chain;
    const eth_node = activeChain.uri;

    try {
      let response;
      if (eth_node.indexOf("polygon-mumbai") !== -1) {
        return {
          estimatedBaseFee: 16,
          high: {
            maxWaitTimeEstimate: 10 * 1000,
            minWaitTimeEstimate: 5 * 1000,
            suggestedMaxFeePerGas: 250,
            suggestedMaxPriorityFeePerGas: 250,
          },
          medium: {
            maxWaitTimeEstimate: 30 * 1000,
            minWaitTimeEstimate: 10 * 1000,
            suggestedMaxFeePerGas: 180,
            suggestedMaxPriorityFeePerGas: 180,
          },
          low: {
            maxWaitTimeEstimate: 60 * 1000,
            minWaitTimeEstimate: 30 * 1000,
            suggestedMaxFeePerGas: 140,
            suggestedMaxPriorityFeePerGas: 140,
          },
        };
      } else if (eth_node.indexOf("polygon") !== -1) {
        //fetch gas for polygon
        const url = `https://gasstation.polygon.technology/`;
        let resp = await this.getRequest({ url });

        if (!resp) {
          resp = {
            fastest: 0,
            standard: 0,
            fast: 0,
          };
        }

        response = {
          estimatedBaseFee: "0",
          high: {
            maxWaitTimeEstimate: 10 * 1000,
            minWaitTimeEstimate: 5 * 1000,
            suggestedMaxFeePerGas: resp.fast,
            suggestedMaxPriorityFeePerGas: resp.fast,
          },
          medium: {
            maxWaitTimeEstimate: 30 * 1000,
            minWaitTimeEstimate: 10 * 1000,
            suggestedMaxFeePerGas: resp.standard,
            suggestedMaxPriorityFeePerGas: resp.standard,
          },
          low: {
            maxWaitTimeEstimate: 60 * 1000,
            minWaitTimeEstimate: 30 * 1000,
            suggestedMaxFeePerGas: resp.safeLow,
            suggestedMaxPriorityFeePerGas: resp.safeLow,
          },
        };
      } else if (activeChain.chainId === 42161) {
        // const chainId = activeChain.chainId;
        const url = `https://gas-api.metaswap.codefi.network/networks/42161/suggestedGasFees`;
        response = await this.getRequest({ url });
      } else {
        // const chainId = activeChain.chainId;
        const url = `https://gas-api.metaswap.codefi.network/networks/1/suggestedGasFees`;
        response = await this.getRequest({ url });
      }
      return response;
    } catch (e) {
      return null;
    }
  }

  async getRequest({ url }) {
    const resp = await fetch(url)
      .then((e) => e.json())
      .catch((e) => {
        console.error("error fetching estimats", e);
        return null;
      });
    return resp;
  }
}

export default FeeEstimator;
