// import TokenArrow from "../../../common/TokenArrow/TokenArrow";

const WalletDetails = ({
  countAssets,
  countNTFS,
  countChain,
  showChains = true,
}) => {
  //
  return (
    <div className="card_wallet-details">
      {countAssets !== null && (
        <div className="card_wallet-info-box number_tokens bg-[#cbd7e9]">
          <div className="info-box">
            <div className="info-box-icon ">
              <span className="icon-coin"></span>
            </div>
            <div className="info-box-count">
              <span className="count">{countAssets}</span>
              <span className="text paddings">
                {countAssets > 1 ? " Tokens" : " Token"}
              </span>
            </div>
          </div>
        </div>
      )}
      {countNTFS !== null && (
        <div className="card_wallet-info-box number_nft bg-[#cbd7e9]">
          {/* <div className="card_wallet-info-box number_nft tooltip_to_show" > */}
          {/* <div className='tooltip_description'>NFT available in wallet</div> */}
          <div
            className="info-box"
            //style={{ opacity: 0.5, filter: "grayscale(100%)" }}
            //title="Coming soon"
          >
            <div className="info-box-icon icon_png ">
              <span
                style={{ backgroundImage: 'url("images/nft-available.png")' }}
              >
                &nbsp;
              </span>
            </div>
            <div className="info-box-count ">
              {/* <div className='tooltip_description'>{countNTFS}</div> */}
              <span className="count">{countNTFS}</span>
              <span className="text paddings">
                {countNTFS !== null ? " NFTs" : " NFT"}
              </span>
            </div>
          </div>
        </div>
      )}
      {showChains && countChain > 0 && (
        <div className="card_wallet-info-box number_chain ">
          {/* <div className="card_wallet-info-box number_chain tooltip_to_show"> */}
          {/* <div className='tooltip_description'>Chains linked to this wallet</div> */}
          <div className="info-box">
            <div className="info-box-icon ">
              <span className="icon-chain"></span>
            </div>
            <div className="info-box-count ">
              <span className="count">{countChain} </span>
              <span className="text paddings">
                {countChain > 1 ? " Chains" : " Chain"}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletDetails;
