import { useState, useEffect } from "react";
import { useApp } from "../helpers/AppProvider";
// import { useUser } from "../helpers/UserProvider";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import SwapHistory from "../components/swap/SwapHistory/SwapHistory";
import SwapMain from "../components/swap/SwapMain/SwapMain";
import SwapProcess from "../components/common/SwapProcess/SwapProcess";

const SwapScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const { appData, actions } = useApp();
  // const { userData } = useUser();

  useEffect(() => {
    // actions.setLoading(true, "Loading Vault");
    // actions.setAppData({activeWallet:0, activeChain:0})
    if (appData?.regStatus === "complete") {
      load_vault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.regStatus]);

  const load_vault = async () => {
    // actions.setLoading(true, "Loading");
    // await Promise.all([
    //   // actions.loadChains(),
    //   actions.loadSupportedCurrencies(),
    //   actions.loadActiveCurrency(userData.user.token),
    //   // userActions.loadVault(),
    // ]);
    actions.setLoading(false);
  };

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {/* <!-- main content --> */}
        <div
          className="main__content__wrp token__swap-wrapper"
          style={{ position: "relative", backgroundColor: "#fff" }}
        >
          {process.env.REACT_APP_HIDE_SWAPS === "true" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                zIndex: 100,
                position: "absolute",
                left: "0",
                top: "0",
              }}
            >
              <div className="settings__overview_guardian_setup">
                <span className="msbico-planet_guardian" />
                <span className="coming_soon">Coming Soon</span>
                <div className="coming_soon_msg">
                  We will notify you once this feature is ready 🙌
                </div>
              </div>
            </div>
          )}
          {!(process.env.REACT_APP_HIDE_SWAPS === "true") && (
            <div
              className={
                "token__swap-inner" +
                (process.env.REACT_APP_HIDE_SWAPS === "true" ? " beta" : "")
              }
            >
              <div className="swp-left-column">
                <SwapMain />
              </div>
              <div className="swp-right-column">
                <SwapHistory />
              </div>
            </div>
          )}
        </div>

        <SwapProcess />
        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        {/* <!-- end aside notification --> */}
      </div>
      {sidebarActive && <div className="mobile_blur"></div>}
    </div>
  );
};

export default SwapScreen;
