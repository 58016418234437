import TrashIcon from '@heroicons/react/outline/TrashIcon';
import { PencilIcon } from '@heroicons/react/solid';
import React, { useState, useEffect, useRef } from "react";

const WalletItemControls = ({ openExportPvKeyHandler, openEditLabelHandler, startRemoveWalletFlow, isSafleWalletEth, isSafleCard }) => {
    const [clickedOutside, setClickedOutside] = useState(true);
    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef.current.contains(e.target)) {
            setClickedOutside(true);
        }
    };

    const handleClickInside = () => setClickedOutside(!clickedOutside);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    return(
        <div className="filter_table-row td wallet__actions-mobile" data-filter="actions">
            <div className={["main__controls controls__dropdown-ellipsis", !clickedOutside  ? " opened": ""].join(" ")}>
                <div className="ellipsis_trigger"  ref={myRef} onClick={handleClickInside}>
                <span className="icon-ellipsis-v-solid" />
                </div>
                <div className="dropdown__list__items_ellipsis">
                    <div onClick={openExportPvKeyHandler} className="modal__trigger" data-target="modal_private_key_enter_pin">
                        <div className="dropdown_item_icon">
                            <span class="icon-icon-wallet-outline"></span>
                        </div>
                        Export Private Key
                    </div>
                    <div onClick={openEditLabelHandler} className="modal__trigger" data-target="modal_private_key_enter_pin">
                        <div className="dropdown_item_icon">
                            {/* <PencilIcon /> */}
                            <span className="icon-pen-solid"></span>
                        </div>
                        Edit Wallet Label
                    </div>
                    { !(isSafleCard || isSafleWalletEth) &&
                        <div onClick={startRemoveWalletFlow} className="modal__trigger" data-target="modal_remove_wallet">
                            <div className="dropdown_item_icon">
                                {/* <TrashIcon />*/}
                                <span className="icon-trash-icon"></span>
                            </div>
                            Archive Wallet
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default WalletItemControls;