import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import Button from './../../../components/registration/Button/Button';
import Toast from './../../../components/registration/Toast/Toast';
import TooltipCopy from './../../../components/registration/TooltipCopy';


import { copyToClipboard, middleEllipsis } from './../../../helpers/vaultHelpers';

import './style.scss';
import { XIcon } from '@heroicons/react/solid';
const ModalReceive = ({
  title = 'Receive',
  balance = false,
  isOpen = false,
  setOpen,
  safleID,
  publicAddress,
  qrImage,
  icon = false
}) => {

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');


  const tooltipText = '';
  // const tooltipIcon = `${process.env.PUBLIC_URL}/assets/icons/file-icon.svg`;
  const tooltipTextIn = 'Copy to clipboard';

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>
  }
  const TooltipIconSvg2 = () => {
    return <span className="icon_copy_white icon-copy-90"></span>
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 zIndex1000 overflow-y-auto'
          onClose={() => setOpen(false)}
        >
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-40' />
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='duration-700'
              enterFrom='-translate-y-20 opacity-0 sm:-translate-y-20 sm:translate-x-0'
              enterTo='translate-y-0 opacity-100 sm:translate-x-0'
              leave='transition ease-in duration-300'
              leaveFrom='opacity-100'
              leaveTo='-translate-y-20 opacity-0 sm:translate-x-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='duration-700'
              enterFrom='-translate-y-20 opacity-0 sm:-translate-y-20 sm:translate-x-0'
              enterTo='translate-y-0 opacity-100 sm:translate-x-0'
              leave='transition ease-in duration-300'
              leaveFrom='opacity-100'
              leaveTo='-translate-y-20 opacity-0 sm:translate-x-0'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl relative'>
                <span
                  className='absolute top-4 right-4 cursor-pointer'
                  onClick={() => setOpen(false)}
                >
                  <XIcon className='h-6 w-6 text-gray-500' />

                </span>
                <div className='mt-5 flex flex-col items-center pl-4 pr-4'>
                  <div className='flex items-center w-full box-border mb-16'>
                    <div className='text-3xl leading-7 text-gray-900 font-semibold w-full text-center'>{title}</div>
                    {/* <div className='pt-2 pr-2 pb-2 pl-2 box-border cursor-pointer p-4 w-7/12 ml-auto bg-gray-100 border border-gray-300 rounded-md'>
                      <div className='flex items-center flex-1'>
                        <div>
                          <svg class="h-5 w-5 text-gray-500 mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />  <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" /></svg>
                        </div>
                        <span className='font-medium text-xs text-gray-700 text-right overflow-hidden truncate'>{middleEllipsis(publicAddress, 3)}</span>
                      </div>
                      {balance && <div className='font-medium text-sm font-semibold text-gray-600 text-right'>{balance} ETH</div>}
                    </div> */}
                  </div>
                  <div className='pb-2 flex'>
                    <div className='text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 '>{safleID}</div>

                    <div className='  cursor-pointer flex' >
                      <TooltipCopy extraClasses='tooltip_registration cursor-pointer w25 svg_no_margin' textToDisplay={tooltipText} imageToDisplay={<TooltipIconSvg />} tooltipTextIn={tooltipTextIn} tooltipTextInChange={tooltipTextInChange} copiedText={safleID} itemProps={safleID} hideAfter="3000" />

                    </div>
                  </div>
                  <div className='border border-gray-400 rounded-md bg-gray-100 p-3'>
                    <img className='w-48' src={qrImage} alt="scan this qr code" />
                  </div>
                  <div className='rounded-md bg_blue flex w-full mt-16 mb-4'>
                    <div className='text-sm text-gray-500 text-center flex flex-1 w-3/5  paddings  items-center'>
                      <div className='eth_icon_wrp'>
                        <img src={`${process.env.PUBLIC_URL}/images/registration/icons/eth-purple.svg`} className='eth_icon' alt="" />
                      </div>
                      <div className='truncate text-white text-addr-white-input '>
                        {/* {publicAddress} */}
                        {middleEllipsis(publicAddress, 6)}
                      </div>
                    </div>

                    <div className='ml-auto flex items-center justify-center  cursor-pointer '>
                      <TooltipCopy extraClasses='tooltip_registration cursor-pointer w42' textToDisplay={tooltipText} imageToDisplay={<TooltipIconSvg2 />} tooltipTextIn={tooltipTextIn} tooltipTextInChange={tooltipTextInChange} copiedText={publicAddress} itemProps={publicAddress} hideAfter="3000" />

                    </div>
                  </div>
                </div >
              </div >
            </Transition.Child >
          </div >
        </Dialog >
      </Transition >

      <Toast
        title=''
        bodyText={toastMessage}
        hideAfter='5000'
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
      />
    </>
  );
};

export default ModalReceive;
