/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import ROUTES from "./../../../helpers/routes";
import FavoriteToggle from "../FavoriteToggle/FavoriteToggle";
import AssetBadgeHistory from "../AssetBadgeHistory/AssetBadgeHistory";
import TokenIcon from "../../common/TokenIcon/TokenIcon";
import { useApp } from "./../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
// import { SafleID } from "@getsafle/safle-identity-wallet";
// import { fetchFavouriteTokens } from "../../../helpers/assetHelper";
import Toast from "../../registration/Toast/Toast";
// import contractMetadata from "../../../helpers/contractMetadata";
// const safleTokenController = require("@getsafle/custom-token-controller");

// const logoPrefix =
//   "https://raw.githubusercontent.com/MetaMask/contract-metadata/master/images/";
const MARKET_SERVICE_URL = process.env.REACT_APP_MARKET_SERVICE_URL;

const AssetHeader = ({
  name,
  symbol,
  favouriteTokens,
  fetchFavourites,
  addedTokens,
  selectedAsset,
  chain,
}) => {
  let navigate = useNavigate();
  const { appData, actions } = useApp();
  const [isWatchActive, setWatchActive] = useState();
  const [toastIsOpen, setToastIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [toastMessage, setToastMessage] = useState("");
  const [tokenData, setTokenData] = useState();
  const [selectedChain, setSelectedChain] = useState(0);

  const { userData } = useUser();
  useEffect(() => {
    setWatchActive(
      favouriteTokens?.filter((e) => !e?.isDeleted && e?.symbol === symbol)
        .length
    );
    getTokenInfo();
  }, [favouriteTokens, symbol]);

  useEffect(() => {
    //set chain to appData and
    if (appData?.chains?.length > 0 && chain) {
      let checkChainName = chain;
      // setTokenContractAddress(contractAddress);
      if (checkChainName === "eth" || !checkChainName) {
        checkChainName = "ethereum";
      }
      const activeChain = appData?.chains?.find(
        (chain) => chain?.chain_name === checkChainName
      );
      actions.setAppData({ activeChain: activeChain?.chainId });
      setSelectedChain(activeChain?.chainId);
    }
  }, [appData.chains, chain]);

  const getTokenInfo = async () => {
    const info = await fetch(`${MARKET_SERVICE_URL}/info?coin=${symbol}`).then(
      (e) => e.json()
    );
    setTokenData(info?.data?.data[symbol]);
  };
  // const goTo = (screen) => {
  //     navigate(-1)
  // }

  const handleWatchToggle = async (e) => {
    const item = tokenData;

    e.stopPropagation();
    if (isWatchActive) {
      const res = await actions.removeFavouriteToken(item, userData.user.token);
      setWatchActive(!isWatchActive);
      if (res.statusCode === 200) {
        setToastMessage(res.message);
        setToastIsOpen(true);
      } else if (res.details[0].message.indexOf("already deleted") !== -1) {
        setToastMessage(res.details[0].message);
        setToastIsOpen(true);
      }
      fetchFavourites();
    } else {
      setWatchActive(!isWatchActive);

      //
      // const info = await fetch(
      //   `${MARKET_SERVICE_URL}info?coin=${item.symbol}`
      // ).then((e) => e.json());

      // item.contractAddress =
      //   info?.data?.data[item?.symbol].contract_address?.filter(
      //     (e) =>
      //       e.platform?.coin?.slug === item?.info?.platform?.slug ||
      //       e.platform?.coin?.slug === item?.slug
      //   )[0]?.contract_address || "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
      // const envName =
      //   process.env.REACT_APP_ENV_MODE === "development" ||
      //   process.env.REACT_APP_ENV_MODE === "test"
      //     ? "testnet"
      //     : "mainnet"; // env value can be 'mainnet' or 'testnet'
      // const polygonRpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL;
      // const safleId = new SafleID(envName, polygonRpcUrl);
      // const userAddress = await safleId.getAddress(userData.user.safleID);
      // const activeChain = appData.chains[appData.activeChain || 0];

      // const tokenController = new safleTokenController.CustomTokenController({
      //   userAddress: userAddress,
      //   rpcURL: activeChain.uri,
      //   chain: activeChain.chain_name,
      // });

      // const tokenDetails = await tokenController.getTokenDetails(
      //   item.contractAddress
      // );
      // item.decimals = tokenDetails.decimal;

      // item.contractAddress =
      //   addedTokens.find((i) => i.symbol === item.symbol).contractAddress ||
      //   item.contractAddress;

      // if (!addedTokens.find((i) => i.symbol === item.symbol)) {
      //   const contractAddress = Object.keys(contractMetadata).find(
      //     (key) => contractMetadata[key] === item
      //   );
      //   const data = {
      //     chain: "ethereum",
      //     decimals: item.decimals,
      //     logo: logoPrefix + item.logo,
      //     name: item.name,
      //     symbol: item.symbol,
      //     contractAddress: contractAddress,
      //   };
      //   await actions.addCustomToken(data, userData.user.token);
      // }

      const res = await actions.addFavouriteToken(item, userData.user.token);
      if (res.statusCode === 400) {
        const message = res.details[0].message;
        // alert(message);
        setToastMessage(message);
        setToastIsOpen(true);
        return;
      } else {
        setToastMessage(res.message);
        setToastIsOpen(true);
      }

      fetchFavourites();
    }
  };

  return (
    <div className="assets__overview_top">
      <div className="assets__overview-single-back-listing ">
        <div
          className="assets__overview-single-back-btn back_assets"
          data-goto="assets-all-assets.html"
          onClick={() => navigate(-1)}
        >
          <span className="icon-arrow-back-black-24-dp" />
        </div>
        <div className="assets__overview-single-back asset__coin">
          <div className="flex flex-row items-center">
            {tokenData && <TokenIcon logo={tokenData?.logo} />}
            <div className="asset__coin-asset-name">
              {selectedAsset?.info?.name || tokenData?.name}
            </div>
            <AssetBadgeHistory symbol={symbol} />
            <FavoriteToggle
              handleWatchToggle={handleWatchToggle}
              star={isWatchActive}
            />
          </div>
          {symbol !== "BTC" && (
            <div className="assets__overview_nav_right flex flex-row border-[1px] border-[#95a9c0] items-center p-[5px] px-[10px] justify-center rounded-full">
              {/* <div className="relative">
              <img
                src="images/chainIcon.svg"
                alt="chainIcon"
                className="h-[16px]"
                onClick={() => actions.setAppData({ activeChain: 0 })}
              />
              <img
                src="images/Tick.svg"
                alt="Tick"
                className={`absolute h-[10px] top-[-5px] right-[-5px] ${
                  appData?.activeChain === 0 ? "" : "hidden"
                }`}
                // display={appData?.activeChain === 0 ? "block" : "none"}
              />
            </div> */}
              {appData?.chains?.map((chain, index) => {
                return (
                  <div className="relative tooltip">
                    <img
                      key={index}
                      src={chain?.details?.imageURL}
                      alt={chain?.chain_name}
                      className="h-[16px] ml-[5px]"
                      onClick={() => {
                        actions.setAppData({ activeChain: chain?.chainId });
                        setSelectedChain(chain?.chainId);
                      }}
                    />
                    <img
                      src="images/Tick.svg"
                      alt="Tick"
                      className={`absolute h-[10px] top-[-5px] right-[-5px] ${
                        selectedChain === chain?.chainId ? "block" : "hidden"
                      }`}
                      // display={
                      //   appData?.activeChain === chain?.chainId ? "block" : "none"
                      // }
                    />
                    <div className="tooltiptext">{chain?.chain_name}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Toast
        title=""
        bodyText={"Asset has been  successfully favourited"}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        seedPhrase
      />
    </div>
  );
};

export default AssetHeader;
