import { useState } from "react";
import VaultActiveWallet from "../../VaultLists/VaultActiveWallet";
// import SettingBox from "../SettingBox/SettingBox";
import ManageVaultTabs from "./ManageVaultTabs";
import ManageVaultHeader from "../../common/ManageVaultHeader/ManageVaultHeader";
import ArchivedWallets from "./ArchivedWallets.js";
import ImportedWallets from "./ImportedWallets";
import ExportedWallets from "./ExportedWallets";
import VaultLogs from "./VaultLogs";
// import { useUser } from "../../../helpers/UserProvider";
import { useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { delay_code } from "../../../helpers/generalHelper";
// let tm = false;

const ManageVaultContent = () => {
  const { actions, appData } = useApp();
  // const { userData, userActions } = useUser();

  const [vaultData, setVaultData] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    // actions.setLoading(true, "Loading Vault");
    if (appData?.regStatus === "complete") {
      load_vault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.regStatus]);
  useEffect(() => {
    if (appData?.wallets?.length > 0) {
      getVaultDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.wallets]);

  const load_vault = async () => {
    actions.setLoading(true, "Loading");
    await Promise.all([
      // userActions.loadVault(),
      // actions.loadChains(),
      // actions.loadSupportedAssets(),
      // actions.loadSupportedCurrencies(),
      // actions.loadActiveCurrency(userData.user.token),
      // actions.loadUsdRate(),
      // userActions.loadVault(),
    ]);
    await delay_code(400);
    const acc = await appData?.accounts;
    const addreses = acc ? Object.values(acc) : {};

    const wallet_changes = await actions.loadWalletCardData(addreses);
    actions.setAppData(wallet_changes);

    actions.setLoading(false);
  };

  const getVaultDetails = async () => {
    // actions.setLoading(true);
    try {
      const res = await actions.getVaultDetails();
      setVaultData(res);
    } catch (err) {
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-[#F7F9FB]" data-show="manage_id">
        <div>
          <ManageVaultHeader data={vaultData} />
        </div>
        <div className="mt-10 ">
          <ManageVaultTabs setTab={setTab} />
        </div>
      </div>

      {tab === 0 && (
        <VaultActiveWallet tab={tab} data={vaultData?.activeWallets} />
      )}
      {tab === 1 && (
        <ArchivedWallets tab={tab} data={vaultData?.archivedWallets} />
      )}
      {tab === 2 && (
        <ImportedWallets tab={tab} data={vaultData?.importedWallets} />
      )}
      {tab === 3 && (
        <ExportedWallets tab={tab} data={vaultData?.exportedWallets} />
      )}
      {tab === 4 && <VaultLogs tab={tab} />}
    </>
  );
};

export default ManageVaultContent;
