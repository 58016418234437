import { useState } from "react";
import Web3 from "web3";
import APIS from "./apis";
import Vault from "@getsafle/safle-vault";
import { SafleID } from "@getsafle/safle-identity-wallet";
import Storage from "./Storage";
import { LIMIT_SAFLEID_CHANGE } from "./AppProvider";
import { registeredCoinTypes } from "slip44";
import { saveVaultLogs } from "./vaultHelpers";
const safleId = require("@getsafle/safle-identity-wallet").SafleID;
const MAINNET_URL = "https://mainnet.infura.io/v3/";

export const useTokenBalances = () => {
  const [balances, setBalances] = useState({});
  const [balancesFiat, setBalancesFiat] = useState({});

  let promises = [];

  const chainFunction = async () => {
    const item = promises.shift();
    if (!item) {
      return null;
    }
    let quant;

    if (item.type === "balance") {
      quant = await new Promise((res, rej) => {
        setTimeout(() => {
          res(1 + ~~(Math.random() * 20));
        }, 100 + ~~(Math.random() * 400));
      });

      setBalances((state) => {
        return { ...state, [item.id]: quant };
      });
    } else {
      quant = await new Promise((res, rej) => {
        setTimeout(() => {
          res(item?.balance);
        }, 100 + ~~(Math.random() * 400));
      });

      setBalancesFiat((state) => {
        return { ...state, [item.id]: quant };
      });
    }
    if (promises.length) {
      setTimeout(chainFunction, 100);
    }
  };

  return {
    getWalletBalance: (id, address) => {
      promises.push({ id, address, type: "balance" });
      return balances.hasOwnProperty(id) ? balances[id] : null;
    },

    getWalletBalanceFiat: (id, address, balance) => {
      promises.push({ id, address, type: "fiat" });
      return balancesFiat.hasOwnProperty(id) ? balancesFiat[id] : null;
    },

    initLoading: () => {
      chainFunction();
    },
  };
};

export const getCoinRate = async (coinName, returnError = false) => {
  try {
    const url = process.env.REACT_APP_MARKET_SERVICE_URL;
    if (!url) throw new Error("URL required for getCoinRate.");
    const rate = await fetch(url + "/latest-price?coin=" + coinName).then((e) =>
      e.json()
    );
    return rate;
  } catch (e) {
    return returnError ? false : 1;
  }
};

export const getTokenBalance = (value, decimals) => {
  return parseFloat(value) / Math.pow(10, decimals).toFixed(decimals);
};

export const formatMoney = (
  number,
  decPlaces = 2,
  decSep = ".",
  thouSep = ","
) => {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces;
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  // eslint-disable-next-line no-use-before-define
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : "") +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "")
  );
};

export const generateWallet = async (
  userVault,
  encryptionKey,
  pinValue,
  chain,
  walletLabel = false,
  token
) => {
  //

  const v = new Vault({ vault: userVault, encryptionKey: encryptionKey });
  try {
    await v.restoreKeyringState(
      userVault,
      pinValue?.toString().padStart(6, "0"),
      encryptionKey
    );

    if (chain) {
      await v.changeNetwork(chain);
    }

    const resp = await v.addAccount(
      encryptionKey,
      pinValue?.toString().padStart(6, "0")
    );

    let vaultEnc = resp?.response?.vault;
    let prvKey = false;
    if (resp.response) {
      if (walletLabel) {
        await v.updateLabel(resp.response.address, encryptionKey, walletLabel);
      }

      const gettingLogs = await v.getLogs();
      await saveVaultLogs(
        gettingLogs?.logs,
        token,
        resp.response?.address,
        chain === "bitcoin" ? "bitcoin" : "ethereum"
      );
      prvKey = await v.exportPrivateKey(
        resp.response.address,
        pinValue?.toString().padStart(6, "0")
      );
    }

    return {
      newAcc: resp.response?.address,
      prvKey: prvKey?.response?.privateKey,
      error: resp?.error,
      vault: vaultEnc,
    };
  } catch (e) {
    console.log(e);
    return { newAcc: null, prvKey: null, error: e.message };
  }
};

export const linkWalletToSafleId = async (
  user,
  pin,
  publicAddress,
  privateKey,
  coinId,
  rpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL,
  chain = "ethereum",
  update = false
) => {
  try {
    const safleId = user.safleID;
    const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    const accountObject = await web3.eth.accounts.privateKeyToAccount(
      privateKey
    );
    const signedData = await accountObject.sign(publicAddress, privateKey);

    let params = {
      signedData,
      userAdd: publicAddress,
      safleId,
      coinId,
      chain,
    };
    const authToken = Storage.load("user").token;
    const url = update
      ? APIS.update_link_wallet_to_safleid
      : APIS.link_wallet_to_safleid;

    if (!update) {
      params.coinAddress = Storage.load("selectedWalletForLink").address;
    } else {
      params.oldCoinAddress = update.address;
      params.newCoinAddress = Storage.load("selectedWalletForLink").address;
    }

    const responseVault = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(params),
    })
      .then((resp) => {
        return resp.json();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });

    return { responseVault };
  } catch (error) {
    return { error };
  }
};

export const validatePrvKey = async (prvKey, rpcUrl = MAINNET_URL) => {
  try {
    const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    return web3.eth.accounts.privateKeyToAccount(prvKey);
  } catch (error) {
    return { error };
  }
};

export const exportPrivateKey = async (address, pinValue) => {
  const vault = new Vault();
  const { response: pKey, error } = await vault.exportPrivateKey(
    address,
    pinValue?.toString()?.padStart(6, "0")
  );
  return { pKey, error };
};

export const getChangeSalfeIdCount = async (address) => {
  const envName =
    process.env.REACT_APP_ENV_MODE === "development" ||
    process.env.REACT_APP_ENV_MODE === "test"
      ? "testnet"
      : "mainnet"; // env value can be 'mainnet' or 'testnet'
  const polygonRpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL;
  const safleId = new SafleID(envName, polygonRpcUrl);
  const updateCount = await safleId.getUpdateCount(address);

  if (!isNaN(parseInt(updateCount))) {
    const updatesRemaining = LIMIT_SAFLEID_CHANGE - updateCount;
    return updatesRemaining;
  } else {
    return { error: updateCount };
  }
};

export const _sanitizeTransaction = (config) => {
  try {
    const allowedParams = [
      "from",
      "to",
      "value",
      "gas",
      "gasPrice",
      "nonce",
      "maxPriorityFeePerGas",
      "maxFeePerGas",
      "data",
      "type",
      "chainId",
    ];
    let illegalAttr = false;
    for (var i in config) {
      if (allowedParams.indexOf(i) === -1) {
        illegalAttr = i;
      }
    }
    if (illegalAttr) {
      throw new Error(`Invalid transaction attribute "${illegalAttr}"`);
    }
    return config;
  } catch (e) {
    console.error(e.message);
    return false;
  }
};

const isString = (s) => {
  return typeof s === "string" || s instanceof String;
};
export const toBaseUnit = (value, decimals, BN) => {
  if (!isString(value)) {
    throw new Error("Pass strings to prevent floating point precision issues.");
  }
  const ten = new BN(10);
  const base = ten.pow(new BN(decimals));

  // Is it negative?
  let negative = value.substring(0, 1) === "-";
  if (negative) {
    value = value.substring(1);
  }

  if (value === ".") {
    throw new Error(
      `Invalid value ${value} cannot be converted to` +
        ` base unit with ${decimals} decimals.`
    );
  }

  // Split it into a whole and fractional part
  let comps = value.split(".");
  if (comps.length > 2) {
    throw new Error("Too many decimal points");
  }

  let whole = comps[0],
    fraction = comps[1];

  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > decimals) {
    throw new Error("Too many decimal places");
  }

  while (fraction.length < decimals) {
    fraction += "0";
  }

  whole = new BN(whole);
  fraction = new BN(fraction);
  let wei = whole.mul(base).add(fraction);

  if (negative) {
    wei = wei.neg();
  }

  return new BN(wei.toString(10), 10);
};

export const formatFiat = (value, decimals = 2) => {
  if (value.toString().indexOf(".") === -1) {
    return parseFloat(value.toString());
  }
  const b = value.toString().split(".");
  return parseFloat(b[0] + "." + b[1].substr(0, decimals));
};

export const checkIfWalletLinked = async (item, linkedWallets) => {
  try {
    const rpcURL = process.env.REACT_APP_WEB3_PROVIDER_URL;
    const safle = new safleId(
      process.env.REACT_APP_ENV_MODE === "development" ||
      process.env.REACT_APP_ENV_MODE === "test"
        ? "testnet"
        : "mainnet",
      rpcURL
    );
    const res = await safle.coinAddressToSafleId(item?.address);

    const isFoundInLinkedWallets = linkedWallets?.find(
      (linkedWallet) => linkedWallet.publicAddress === item.address
    );

    if (res.toString() === "This coin address is not registered.") {
      return false;
    }
    if (isFoundInLinkedWallets) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const getCoinId = (symb) => {
  return registeredCoinTypes.find(
    ([_coinType, _derivationPathComponent, symbol, _name]) => {
      return symbol === symb;
    }
  )[0];
};

export const checkWalletLinkedToSafleID = async (safleId) => {
  const envName =
    process.env.REACT_APP_ENV_MODE === "development" ||
    process.env.REACT_APP_ENV_MODE === "test"
      ? "testnet"
      : "mainnet"; // env value can be 'mainnet' or 'testnet'
  const polygonRpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL;
  const safle = new SafleID(envName, polygonRpcUrl);
  const publicAddress = await safle.getAddress(safleId);
  return publicAddress;
};
