import Header from '../components/common/Header/Header';
import Sidebar from '../components/common/Sidebar/Sidebar';
import SidebarNotification from '../components/common/SidebarNotification/SidebarNotification';

import DropdownWallet from '../components/common/DropdownWallet/DropdownWallet';
import DropdownNetwork from '../components/common/DropdownNetwork/DropdownNetwork';
import DropdownFilter from '../components/common/DropdownFilter/DropdownFilter';
import DropdownEllipsis from '../components/common/DropdownEllipsis/DropdownEllipsis';

// FILTER ITEMS objects
const sortBy = [
    {
        // key : 1, 
        sort_name: 'Sort by Market Cap',
    },
    {
        // key : 2, 
        sort_name: 'Rank',
    },
    {
        // key : 3, 
        sort_name: '% Change',
    },
    {
        // key : 4, 
        sort_name: 'Market Cap',
    },
    {
        // key : 5, 
        sort_name: 'Volume 24h',
    },
    {
        // key : 6, 
        sort_name: 'Circulating Supply',
    },
    {
        // key : 7, 
        sort_name: 'Price',
    },
    {
        // key : 8, 
        sort_name: 'Name',
    }
];

const filterCurrency = [
    {
        // key : 1, 
        sort_name: 'USD',
        currency_symbol: '$'

    },
    {
        // key : 2, 
        sort_name: 'EUR',
        currency_symbol: '$'
    },
    {
        // key : 3, 
        sort_name: 'GBP',
        currency_symbol: '$'
    },
    {
        // key : 4, 
        sort_name: 'INR',
        currency_symbol: '$'
    },
    {
        // key : 5, 
        sort_name: 'BRL',
        currency_symbol: '$'
    },
    {
        // key : 6, 
        sort_name: 'CAD',
        currency_symbol: '$'
    }
];
const filterTimeline = [
    {
        // key : 1, 
        sort_name: '% (1h)'

    },
    {
        // key : 2, 
        sort_name: 'Timeline'
    },
    {
        // key : 3, 
        sort_name: '% 1 H'
    },
    {
        // key : 4, 
        sort_name: '% 24 H'
    },
    {
        // key : 5, 
        sort_name: '% 7 D'
    }
];
const filterCoinTypes = [
    {
        // key : 1, 
        sort_name: 'All Cryptocurrencies'

    },
    {
        // key : 2, 
        sort_name: 'Asset Type'
    },
    {
        // key : 3, 
        sort_name: 'Coins'
    },
    {
        // key : 4, 
        sort_name: 'Tokens'
    }
];
const walletAction = [
    {
        // key : 1, 
        action_name: 'Remove Wallet'
    },
    {
        // key : 2, 
        action_name: 'Export Private Key'
    }

];
const DemoControlsScreen = () => {

    return (
        <div>
            <Header />
            <div className="main__content__wrapper">
                <Sidebar />
                {/* <!-- main content --> */}
                <div className='main__content__wrp settings-overview-wrapper'>
                    <div className="control_col">
                        <div className='control_row'>
                            <p>Type: wamount </p>
                            <p>Extra Class</p>
                            <DropdownWallet compType='wamount' extraClass='controls__dropdown-wallet controls__dropdown-wallet-wamount' />
                        </div>
                        <div className='control_row'>
                            <p>Type: empty </p>
                            <p>ClassExtra: empty</p>
                            <DropdownWallet extraClass='controls__dropdown-wallet' />
                        </div>
                        <div className='control_row'>
                            <p>Type: empty </p>
                            <p>ClassExtra: </p>
                            <DropdownWallet extraClass='controls__dropdown-wallet controls__dropdown-wallet-light' />
                        </div>
                    </div>
                    <div className="control_col">
                        <div className='control_row'>
                            <p>Extra Class</p>
                            <DropdownNetwork extraClass='controls__dropdown-network dropdown__light_blue margin__bottom-15' />
                        </div>
                        <div className='control_row'>

                            <p>ClassExtra: empty</p>
                            <DropdownNetwork extraClass='controls__dropdown-network' />
                        </div>
                        <div className='control_row'>

                            <p>ClassExtra: </p>
                            <DropdownNetwork extraClass='controls__dropdown-network dropdown_modal_network' />
                        </div>
                    </div>
                    <div className="control_col">
                        <div className='control_row'>
                            <DropdownFilter items={sortBy} extraClass='controls__dropdown_black disp_inline min_width_150' />
                        </div>
                        <div className='control_row'>
                            <DropdownFilter compType='currency' items={filterCurrency} extraClass='controls__dropdown_black disp_inline' />
                        </div>

                        <div className='control_row'>
                            <DropdownFilter items={filterTimeline} extraClass='controls__dropdown_black disp_inline' />
                        </div>
                        <div className='control_row'>
                            <DropdownFilter items={filterCoinTypes} extraClass='controls__dropdown_black disp_inline min_width_150' />
                        </div>

                    </div>
                    <div className="control_col">
                        <div className='control_row'>
                            <DropdownEllipsis items={walletAction} extraClass='' />
                        </div>
                    </div>
                </div>

                {/* <!-- end main content --> */}
                {/* <!-- aside notification --> */}
                <SidebarNotification />

                {/* <!-- end aside notification --> */}
            </div>
            <div className="drop_shadow"></div>
            <div className="drop_shadow_menu"></div>
        </div>
    );
};

export default DemoControlsScreen;