import React, { useEffect, useState } from "react";
import ModalEnterVerificationCode from "./../../dialogs/ModalEnterVerificationCode";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  SETTINGS_EXPORT_SEED_PHRASE,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useUser } from "../../../helpers/UserProvider";

const ModalEnterOtpFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    SETTINGS_EXPORT_SEED_PHRASE
  );
  const [errorMessage, setErrorMessage] = useState("");
  const { userActions } = useUser();
  // eslint-disable-next-line no-unused-vars
  const [modalData, setData] = useModalData();

  const onClose = () => {
    setActiveModalNav(DEFAULT_MODAL_STATUS);
  };
  const callbackOpenPopup = async (otp) => {
    let params = {
      otp,
      email: modalData.email,
      safleID: modalData.safleID,
      action: modalData.type,
    };
    const resp = await userActions.verifyEmailOTP(params);
    if (resp.statusCode === 201) {
      // continue to next modal
      setActiveModalNav("settings_export_seedphrase_pin");
    } else if (resp.statusCode === 400) {
      if (resp?.details[0]?.message === "Service Unavailable") {
        setErrorMessage("Service Unavailable");
      } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
        //Invalid OTP
        setErrorMessage("You have entered wrong verification code");
      } else {
        setErrorMessage(resp?.details[0]?.message);
      }
    }
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalEnterVerificationCode
      errorMessage={errorMessage}
      onClose={onClose}
      onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="settings_export_seedphrase_cancel"
      modalNext_label="settings_export_seedphrase"
    />
  );
};

export default ModalEnterOtpFunctions;
