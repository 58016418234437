/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PasswordInput from "../PasswordInput/PasswordInput";

import PrimaryBtn from "../../common/PrimaryBtn/PrimaryBtn";
import React, { useState, useEffect } from "react";
// import { useApp } from "../../../helpers/AppProvider";
import {
  useUser,
  // useRecaptcha,
  // isValidEmail,
} from "../../../helpers/UserProvider";

import { getSafleName } from "../../../helpers/vaultHelpers";
import SettingsChangePinProcess from "./../../common/SettingsChangePinProcess/SettingsChangePinProcess";
import {
  CHANGE_PIN_FLOW,
  FORGOT_PIN_FLOW,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import Storage from "../../../helpers/Storage";

import {
  // UserIcon,
  // ArrowNarrowRightIcon,
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon,
  // ArrowRightIcon,
} from "@heroicons/react/solid";

const ChangePINContent = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModal, goBackwardN] = useModalNavigation(CHANGE_PIN_FLOW);
  const [setModalOnForgotPin] = useModalNavigation(FORGOT_PIN_FLOW);
  // eslint-disable-next-line no-unused-vars
  const [modalData, setData] = useModalData();

  // const { appData, actions } = useApp();
  const { userData, userActions } = useUser();

  const [pinCurrent, setPinCurrent] = useState();
  const [pinCurrentVal, setPinCurrentVal] = useState();
  const [pinCurrentErrMsg, setPinCurrentErrMsg] = useState();
  const [pinCurrentShown, setPinCurrentShown] = useState();

  const [pinChangePin, setPinChangePin] = useState();
  const [pinChangePinVal, setPinChangePinVal] = useState();
  const [pinChangePinErrMsg, setPinChangeErrMsg] = useState();
  const [pinChangePinShown, setPinChangePinShown] = useState();

  const [pinChangeConfirmPin, setPinChangeConfirmPin] = useState();
  const [pinChangeConfirmPinVal, setPinChangeConfirmPinVal] = useState();
  const [pinChangePinConfirmErrMsg, setPinChangeConfirmErrMsg] = useState();
  const [pinChangePinConfirmShown, setPinChangePinConfirmShown] = useState();

  const [isValid1, setIsValid1] = useState(false);
  const [isValid2, setIsValid2] = useState(false);
  const [isValid3, setIsValid3] = useState(false);

  const storageData = Storage.load("user");

  const storageDataName = storageData.safleID;
  const storageDataToken = storageData.token;
  // const storageDataVault = storageData.vault;

  // const [disabledBtn, setDisabledBtn] = useState(false);
  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModal();
  }, []);

  useEffect(() => {
    inputsValid();
  }, [pinCurrent, pinChangePin, pinChangeConfirmPin]);

  useEffect(() => {
    disabledBtn();
  }, [pinCurrentErrMsg, pinChangePinErrMsg, pinChangePinConfirmErrMsg]);

  const onForgotPin = async (e) => {
    e.preventDefault();

    const email = await userActions.getEmailBySafleId(userData.user.safleID);
    userActions.resendEmailOTP(email, userData.user.safleID);
    setData({
      type: "forgot-pin",
      email: email,
      safleID: userData.user.safleID,
    });
    setModalOnForgotPin("enter_verification_code");
  };

  const setUpdatePinCurrent = (val) => {
    //
    const re = /^[0-9]{1,6}$/g;
    if (val === "" || (val.length > 0 && re.test(val) === true)) {
      setPinCurrent(val);
      setPinCurrentVal(val);
    } else {
      return;
    }
  };
  const setUpdatePinChange = (val) => {
    //

    const re = /^[0-9]{1,6}$/g;
    if (val === "" || (val.length > 0 && re.test(val) === true)) {
      setPinChangePin(val);
      setPinChangePinVal(val);
    } else {
      return;
    }
  };
  const setUpdatePinChangeConfirm = (val) => {
    //

    const re = /^[0-9]{1,6}$/g;
    if (val === "" || (val.length > 0 && re.test(val) === true)) {
      setPinChangeConfirmPin(val);
      setPinChangeConfirmPinVal(val);
    } else {
      return;
    }
  };

  const inputsValid = () => {
    if (pinCurrentVal?.length < 6) {
      setPinCurrentErrMsg(
        "PIN should be exactly 6 numbers and should not be 123456."
      );
      // setIsValid1(false);
    } else if (pinCurrentVal === "123456") {
      setPinCurrentErrMsg("PIN should should not be 123456.");
      // setIsValid1(false);
    } else {
      setPinCurrentErrMsg(false);
      // setIsValid1(true);
    }

    if (pinChangePinVal?.length < 6) {
      setPinChangeErrMsg(
        "PIN should be exactly 6 numbers and should not be 123456."
      );
      // setIsValid2(false);
    } else if (pinChangePinVal === "123456") {
      setPinChangeErrMsg("PIN should should not be 123456.");
      // setIsValid2(false);
    } else {
      setPinChangeErrMsg(false);
      // setIsValid2(true);
    }

    if (pinChangeConfirmPinVal === "123456") {
      setPinChangeConfirmErrMsg("PIN should should not be 123456.");
      //
      // setIsValid3(false);
    } else if (pinChangeConfirmPinVal === pinChangePinVal) {
      //
      setPinChangeConfirmErrMsg(false);
      // setIsValid3(false);
    } else {
      //
      // setIsValid3(true);
      setPinChangeConfirmErrMsg("New Pin and Confirm Pin missmatch");
    }
  };
  const disabledBtn = () => {
    if (
      pinCurrentVal?.length === 6 &&
      pinChangePinVal?.length === 6 &&
      pinChangeConfirmPinVal?.length === 6
    ) {
      //
      if (
        pinCurrentErrMsg === false &&
        pinChangePinErrMsg === false &&
        pinChangePinConfirmErrMsg === false
      ) {
        //
        setIsValid1(true);
        setIsValid2(true);
        setIsValid3(true);
      } else {
        //
        setIsValid1(false);
        setIsValid2(false);
        setIsValid3(false);
      }
    } else {
      //
      setIsValid1(false);
      setIsValid2(false);
      setIsValid3(false);
    }
  };

  const resetValues = (e = false) => {
    //reset values inputs
    e && e.preventDefault();
    setPinCurrent("");
    setPinChangePin("");
    setPinChangeConfirmPin("");
    //reset valids
    setIsValid1(false);
    setIsValid2(false);
    setIsValid3(false);
    //reset errors
    setPinChangeConfirmErrMsg(false);
    setPinChangeErrMsg(false);
    setPinCurrentErrMsg(false);
  };

  const confirmBtn = async (e) => {
    const safleName = await getSafleName(storageDataToken);
    //
    setData({
      type: "change_pin",
      safleID: storageDataName,
      // token:storageDataToken, vault:storageDataVault ,
      currentPin: pinCurrent,
      newPin: pinChangePin,
      email: safleName.email,
    });
    // const request_change_email = await userActions.changeEmailRequest(storageDataToken);
    //

    const request_change_pin = await userActions.validatePin(pinCurrent);

    //
    if (request_change_pin?.error) {
      //show error
      setPinCurrentErrMsg("Entered pin is incorrect.");
    } else {
      if (request_change_pin === true && pinCurrentVal === pinChangePin) {
        // pin should not be same as old pin

        setPinCurrentErrMsg(
          "The new pin should not be the same as the old one."
        );
      } else {
        //continue
        //
        await userActions.resendEmailOTP(safleName.email);
        setActiveModal("modal_change_pin_enter_pin");
      }
    }
    // setData({ new_email: tempUserEmail });
  };

  const cancelChange = (e) => {
    resetValues();
    // setActiveModal('modal_change_pin_enter_pin');
  };

  return (
    <div
      className="settings__overview_inner_content white_bg"
      data-show="change_pass"
    >
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_change_pin">
          <form
            className="form-default form-default-pass"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="form__input form_heading">Change PIN</div>
            <PasswordInput
              label="Current PIN"
              placeholder="Enter Current Pin"
              type={pinCurrentShown ? "text" : "password"}
              maxLength="6"
              // minLength='0'
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPinCurrentShown(true)}
                  className={
                    pinCurrentShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPinCurrentShown(false)}
                  className={
                    !pinCurrentShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={pinCurrent}
              // valid={validPassword}
              setValue={setUpdatePinCurrent}
              // onChange={handleCurrentPin}
              errorMsg={pinCurrentVal === pinChangePin ? "" : pinCurrentErrMsg}
            />

            <PasswordInput
              label="New PIN"
              placeholder="Enter New PIN"
              type={pinChangePinShown ? "text" : "password"}
              maxLength="6"
              minLength="6"
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPinChangePinShown(true)}
                  className={
                    pinChangePinShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPinChangePinShown(false)}
                  className={
                    !pinChangePinShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={pinChangePin}
              setValue={setUpdatePinChange}
              // valid={validPassword}
              // onChange={handleChangeNewPin}
              errorMsg={
                pinCurrentVal === pinChangePin
                  ? pinChangePinErrMsg || pinCurrentErrMsg
                  : pinChangePinErrMsg
              }
            />

            <PasswordInput
              label="Confirm New PIN"
              placeholder="Confirm new PIN"
              type={pinChangePinConfirmShown ? "text" : "password"}
              maxLength="6"
              minLength="6"
              icon={
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400 icon_dark_gray"
                  aria-hidden="true"
                />
              }
              hiddenEye={
                <EyeOffIcon
                  onClick={() => setPinChangePinConfirmShown(true)}
                  className={
                    pinChangePinConfirmShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              visibleEye={
                <EyeIcon
                  onClick={() => setPinChangePinConfirmShown(false)}
                  className={
                    !pinChangePinConfirmShown
                      ? "h-6 w-6 text-gray-400 hidden"
                      : "h-6 w-6 text-gray-400"
                  }
                  aria-hidden="true"
                />
              }
              value={pinChangeConfirmPin}
              setValue={setUpdatePinChangeConfirm}
              // valid={validPassword}
              // onChange={handleChangePinConfirm}
              errorMsg={pinChangePinConfirmErrMsg}
            />

            <div className="form__input form__input_actions">
              <PrimaryBtn
                label={"Confirm"}
                classes={
                  "btn-default btn-blue margin-right-0 checkDisabled order_2"
                }
                disabled={!(isValid1 && isValid2 && isValid3)}
                onClick={confirmBtn}
              />
              <PrimaryBtn
                label={"Cancel"}
                classes={
                  "btn-default btn-white-with-border margin-left-0 order_1"
                }
                // onClick={onCancelHandle}
                onClick={resetValues}
              />
            </div>
            <div className="form_forgot_pin">
              <div className="form__input form__input_divider" />
              <div className="form__input form__input_forgot">
                {/* <a href="/" className="modal__trigger" data-target="modal_forgot_pin">Forgot PIN?</a> */}
                <a
                  href="#"
                  onClick={(e) => onForgotPin(e)}
                  className="modal__trigger"
                  data-target="modal_forgot_pin"
                >
                  Forgot PIN?
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <SettingsChangePinProcess
        onCompletProccess={cancelChange}
        onCancelProccess={cancelChange}
      />
    </div>
  );
};

export default ChangePINContent;
