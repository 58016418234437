import React, { useEffect }  from 'react';
import { useApp } from '../../../helpers/AppProvider';
import { useUser } from '../../../helpers/UserProvider';

import ModalReceiveAssetFunctions from './../../common/AssetsReceiveAssetProcess/ModalReceiveAssetFunctions';
import ModalReceiveAssetFailedFunctions from './../../common/AssetsReceiveAssetProcess/ModalReceiveAssetFailedFunctions';
import ModalReceiveAssetCancelFunctions from './../../common/AssetsReceiveAssetProcess/ModalReceiveAssetCancelFunctions';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { RECEIVED_ASSET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';




const AssetsReceiveAssetProcess = (props) => {
    const { userData, userActions } = useUser();
    
    const { appData: { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}, wallets = []}, setAppData } = useApp() || {};

    const [ setActiveModalNav, goNext , goBackward ] = useModalNavigation(RECEIVED_ASSET_FLOW);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    
    const onCancel = (e) => {
        // setActiveModalNav('CLOSED');
    }
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    
    return (
        <>
            {
                // modal order:
                // ModalReceiveAssetFunctions -- modal_send_asset
                // ModalReceiveAssetFailedFunctions -- modal_send_asset_failed
                // ModalReceiveAssetCancelFunctions -- modal_send_asset_cancel
            }
            {activeModalLabel === 'modal_receive_asset' &&
                <ModalReceiveAssetFunctions 
                callbackOpenPopup={() => goNext() }
                />
                
            }
 
            {activeModalLabel === 'modal_receive_asset_failed' &&
                
                <ModalReceiveAssetFailedFunctions />
            }
            {activeModalLabel === 'modal_receive_asset_cancel' &&
                <ModalReceiveAssetCancelFunctions />
               
            }

        </>
    );
}

export default AssetsReceiveAssetProcess;