
import SettingBox from "../SettingBox/SettingBox";

const PrivacyContent = () => {
  return (
    <div className="settings__overview_inner_content " data-show="privacy_policy">
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_privacy_policy">
          <SettingBox
            title={ 'Privacy Policy' }
            description={ '' }
            icon={ ' msbico-privacy-policy' }
            buttonLabel={ 'View' }
            hasStatusBadge={ false }
            hasLink={ false }
            enabled={ true }
            onClickAction={ () => { window.open( process.env.REACT_APP_LINK_PRIVACY, '_blank' )}}
          />
          <SettingBox
            title={ 'Terms & Conditions' }
            description={ '' }
            icon={ ' msbico-terms-cond' }
            buttonLabel={ 'View' }
            hasStatusBadge={ false }
            hasLink={ false }
            enabled={ true }
            onClickAction={ () => { window.open( process.env.REACT_APP_LINK_TERMS, '_blank' )}}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyContent;