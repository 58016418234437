/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import WalletSelector from "../WalletSelector/WalletSelector";
import ChainSelector from "../ChainSelector/ChainSelector";
import FromTokenSelector from "../FromTokenSelector/FromTokenSelector";
import ToTokenSelector from "../ToTokenSelector/ToTokenSelector";
import SlippageRange from "../../common/SlippageRange/SlippageRange";
import SwapResume from "../SwapResume/SwapResume";
import { decimalCount, delay_code } from "../../../helpers/generalHelper";
// import { useUser } from "../../../helpers/UserProvider";
import useModalNavigation from "../../../helpers/useModalNavigation";
import { SWAP_FLOW } from "../../../constants/ui";
import Toast from "../../../components/registration/Toast/Toast";
import GasCalculation from "../../../components/common/GasFeeOptions/GasCalculation";

const Swaps = require("@getsafle/safle-swaps-v2").Swaps;
const Vault = require("@getsafle/safle-vault");
const Web3 = require("web3");

const SwapMain = () => {
  const { appData, actions } = useApp();
  // const { userData, userActions } = useUser();
  const vault = new Vault({});
  const web3 = new Web3();

  // useEffect(() => {
  //   // load_vault();
  // }, [] );
  const [ethError, setEthError] = useState(0);
  const [tokenError, setTokenError] = useState(0);
  const [setActiveModalNav] = useModalNavigation(SWAP_FLOW);
  const [availableSwap, setAvailableSwap] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [rateLoader, setRateLoader] = useState(false);

  const handleSwap = async () => {
    if (!appData.fromTokenData.length) return;
    const ethBalance = await vault.getBalance(
      appData.activeAccountAddress,
      (appData.chains
        ? appData.chains.filter(
            (chain) => chain?.chainId === appData?.activeChain
          )[0]
        : appData.chains[0]
      )?.uri
    );
    // actions.setLoading(true, "get estimatedGas");
    const activeChain = appData.chains
      ? appData.chains.filter(
          (chain) => chain?.chainId === appData?.activeChain
        )[0]
      : appData.chains[0];
    const safleSwaps = new Swaps({
      dex: appData.activeDex,
      rpcURL: activeChain && activeChain.uri,
      chain: activeChain && activeChain.chain_name,
    });
    let estimatGas;
    if (appData.toTokenData[0] && appData.fromTokenData[0]) {
      const config = {
        toContractAddress: appData.activeTokenB,
        toContractDecimal:
          appData.toTokenData[0] && appData.toTokenData[0]?.decimals,
        fromContractAddress: appData.activeTokenA,
        fromContractDecimal:
          appData.fromTokenData[0] && appData.fromTokenData[0]?.decimals,
        fromQuantity:
          appData.fromTokenData[0] &&
          web3.utils.toWei(
            parseFloat(appData.swapFromQuantity)
              ?.toFixed(appData.fromTokenData[0]?.decimals)
              .toString(),
            "ether"
          ),
        slippageTolerance: appData.swapSlippage,
      };

      estimatGas = await safleSwaps.getEstimatedGas(config);

      actions.setAppData({ estimatedGas: estimatGas });
    }
    // actions.setLoading(false);
    const fees = await GasCalculation({
      appData: appData,
      chainId: activeChain.chainId,
      token: activeChain?.details?.symbol,
      config: {
        toAddress: appData.activeTokenB,
        fromAddress: appData.activeTokenA,
        value: appData.swapFromQuantity,
        contractAddress: appData.activeTokenB,
      },
    });
    const gasPrice =
      parseInt(fees?.standard?.maxPriorityFeePerGas) +
      parseInt(fees?.estimatedBaseFee);
    const valueInWei = (
      parseInt(gasPrice.toFixed(9) * Math.pow(10, 9)) * fees?.gasLimit
    ) //estimatGas?.estimatedGas
      .toString();
    const valueInEth = valueInWei / Math.pow(10, 18);

    if (parseFloat(ethBalance.response.balance) < parseFloat(valueInEth)) {
      setEthError(valueInEth);
    } else {
      setEthError(0);
    }
    // const getBalance = await actions.getBalance(appData.activeTokenA);
    // const tokenBalance = (getBalance * 1) / (10 ** (appData.fromTokenData[0].decimals || 0));
    let tokenBalance;
    if (
      (appData?.fromTokenData.length &&
        appData?.fromTokenData[0].symbol === "ETH" &&
        appData.activeChain === 1) ||
      (appData?.fromTokenData.length &&
        appData?.fromTokenData[0].symbol === "MATIC" &&
        appData.activeChain === 137)
    ) {
      tokenBalance = await actions.getCoinBalance(
        appData?.activeAccountAddress,
        appData?.fromTokenData[0]?.decimals
      );
    } else {
      tokenBalance = await actions.getTokenBalance(
        appData?.activeAccountAddress,
        appData?.fromTokenData[0].address
      );
    }

    if (parseFloat(tokenBalance) < parseFloat(appData.swapFromQuantity)) {
      setTokenError(
        (
          parseFloat(appData.swapFromQuantity) - parseFloat(tokenBalance)
        ).toFixed(3)
      );
    } else {
      setTokenError(0);
    }
    if (appData.fromTokenData[0].name === "Ethereum") {
      if (
        parseFloat(ethBalance.response.balance) >
        parseFloat(valueInEth) + parseFloat(appData.swapFromQuantity)
      ) {
        // setActiveModalNav("modal_swap_confirmation")
        setActiveModalNav("modal_transaction_approval");
      }
    } else {
      if (
        parseFloat(ethBalance.response.balance) > parseFloat(valueInEth) &&
        parseFloat(tokenBalance) > parseFloat(appData.swapFromQuantity)
      ) {
        // setActiveModalNav("modal_swap_confirmation")
        setActiveModalNav("modal_transaction_approval");
      }
    }
  };

  // useEffect(() => {
  //   async function getAccounts() {
  //     const accounts = await userActions.getAccounts();
  //     await actions.setAppData({ accounts });
  //   }
  //   getAccounts();
  // }, [appData.activeChain || 0]);

  const load_vault = async () => {
    // const result = await Promise.all([
    //   //   actions.loadChains(),
    //   // actions.loadSupportedAssets(),
    //   // actions.loadSupportedCurrencies(),
    //   // actions.loadActiveCurrency(userData.user.token),
    //   // actions.loadUsdRate(),
    //   //   userActions.loadVault(),
    // ]);
    try {
      actions.setLoading(true, "Loading");

      await delay_code(1400);
      // const acc = await userActions.getAccounts();
      // const addreses = acc ? Object.values(acc) : {};
      // const wallet_changes = await actions.loadWalletCardData(addreses);
      // await actions.setAppData(wallet_changes);
    } catch (error) {
      actions.setLoading(false);
    } finally {
      actions.setLoading(false);
    }
  };

  useEffect(() => {
    if (appData?.regStatus === "complete") {
      load_vault();
    }

    if (appData && !appData?.swapFromQuantity) {
      setActiveModalNav("CLOSED");
    }
  }, [appData?.regStatus]);

  const getSupportedToken = async () => {
    if (appData.chains && appData.chains.length) {
      const activeChain =
        appData.chains && appData?.activeChain
          ? appData.chains.filter(
              (chain) => chain?.chainId === appData?.activeChain
            )[0]
          : appData.chains[0];
      const safleSwaps = new Swaps({
        dex: appData.activeDex,
        rpcURL: activeChain?.uri,
        chain: activeChain?.chain_name || "ethereum",
      });

      const supportedTokens = await safleSwaps.getSupportedTokens();

      supportedTokens?.tokens?.forEach((token) => {
        token.logoURI = token?.logoURI?.replace(
          "https://tokens.1inch.io/",
          "https://tokens-data.1inch.io/images/"
        );
      });

      actions.setAppData({ supportedTokens: supportedTokens.tokens });
    }
  };

  useEffect(() => {
    if (appData?.activeChain) {
      if (appData?.activeChain === 1 || appData?.activeChain === 137) {
      } else {
        actions.setAppData({ activeChain: 1 });
      }
    } else {
      actions.setAppData({ activeChain: 1 });
    }
  }, []);

  useEffect(() => {
    // if(!appData?.supportedTokens?.length){
    // actions.setAppData({ supportedTokens: [] });
    async function getTokens() {
      await getSupportedToken();
    }
    getTokens();
    // }
  }, [appData.chains, appData.activeChain, appData.activeDex]);

  const getExchangeRates = async () => {
    // actions.setLoading(true, "get exchangeRate");
    try {
      setRateLoader(false);
      if (
        appData.chains &&
        appData.chains.length &&
        appData.supportedTokens &&
        appData.supportedTokens.length &&
        appData.activeDex &&
        appData.swapSlippage
      ) {
        const slippage = appData.swapSlippage;
        const activeChain = appData.chains
          ? appData.chains.filter(
              (chain) => chain?.chainId === appData?.activeChain
            )[0]
          : appData.chains[0];
        const fromTokenData = appData.supportedTokens.filter(
          (token) =>
            appData.activeTokenA &&
            token.address &&
            token.address.toLowerCase() === appData.activeTokenA.toLowerCase()
        );

        const toTokenData = appData.supportedTokens.filter(
          (token) =>
            appData.activeTokenB &&
            token.address &&
            token.address.toLowerCase() === appData.activeTokenB.toLowerCase()
        );
        actions.setAppData({
          fromTokenData: fromTokenData,
          toTokenData: toTokenData,
        });
        const safleSwaps = new Swaps({
          dex: appData.activeDex,
          rpcURL: activeChain && activeChain.uri,
          chain: activeChain && activeChain.chain_name,
        });

        // const rates={};
        // const rates = await safleSwaps.getRates({

        const fromQuantity =
          fromTokenData[0] && 1 * 10 ** fromTokenData[0]?.decimals;
        try {
          const rates = await safleSwaps.getExchangeRates({
            // walletAddress:appData.accounts[0]?.address,
            toContractAddress: appData.activeTokenB,
            toContractDecimal: toTokenData[0] && toTokenData[0]?.decimals,
            fromContractAddress: appData.activeTokenA,
            fromContractDecimal: fromTokenData[0] && fromTokenData[0]?.decimals,
            fromQuantity: fromQuantity?.toString(),
            slippageTolerance: slippage,
          });

          if (rates) {
            actions.setAppData({
              exchangeRate:
                rates?.toTokenAmount / 10 ** toTokenData[0]?.decimals,
            });
          }
          // if (rates.error) {
          //   setToastIsOpen(true);
          //   setToastMessage(rates.error.message);
          //   actions.setAppData({ exchangeRate: 0 });
          // } else {
          // const rate = rates.filter(
          //   (rate) => rate.dexInstance === appData.activeDex
          // );
          // actions.setAppData({
          //   exchangeRate:
          //     rate[0].rate.toTokenAmount / 10 ** toTokenData[0].decimals,
          // });

          // }
        } catch (error) {
          setToastIsOpen(true);
          setToastMessage(error.message);
          actions.setAppData({ exchangeRate: 0 });
          actions.setLoading(false);
        }
      } else {
        // actions.setAppData({ exchangeRate: 0 });
      }
      actions.setLoading(false);
    } catch (error) {
    } finally {
      setRateLoader(true);
    }
  };

  useEffect(() => {
    getExchangeRates();
  }, [
    appData.activeChain,
    appData.activeDex,
    appData.activeTokenA,
    appData.activeTokenB,
    appData.supportedTokens,
    // appData.chains,

    appData.swapSlippage,
  ]);

  useEffect(() => {
    if (
      appData.activeTokenA &&
      appData.activeTokenB &&
      appData.swapFromQuantity &&
      appData.swapToQuantity &&
      appData?.fromTokenData[0]?.decimals &&
      appData?.toTokenData[0]?.decimals
    ) {
      if (
        decimalCount(appData.swapFromQuantity) >
          appData?.fromTokenData[0]?.decimals ||
        decimalCount(appData.swapToQuantity) > appData?.toTokenData[0]?.decimals
      ) {
        setAvailableSwap(false);
      } else {
        setAvailableSwap(true);
      }
    }
  }, [
    appData.activeTokenA,
    appData.activeTokenB,
    appData.swapFromQuantity,
    appData.swapToQuantity,
    appData?.fromTokenData[0]?.decimals,
    appData?.toTokenData[0]?.decimals,
  ]);

  const handleExchangeToken = () => {
    const currentTokenA =
      appData.activeTokenA || Object.keys(appData.assets)[0];
    const currentTokenB =
      appData.activeTokenB || Object.keys(appData.assets)[1];

    actions.setAppData({ activeTokenA: currentTokenB });
    actions.setAppData({ activeTokenB: currentTokenA });
  };

  return (
    <div>
      <div className="swap-controls-holder">
        <ChainSelector />
        <WalletSelector />
      </div>
      <div className="token__swap-top">
        <span className="sheading sheading-14">SWAP</span>
      </div>
      <div className="token__swap-exchange-token token-swap-exchange-tkn">
        <FromTokenSelector />
        <div className="from_to_field_wrapper swap-field-to-wrapper">
          <div
            className="from_to_field_swap"
            onClick={() => handleExchangeToken()}
          />
        </div>
        <ToTokenSelector />
      </div>
      <SlippageRange />
      <SwapResume />
      {ethError !== 0 && (
        <div className="token-swap-balance-error">
          Not enough {appData.activeChain === 1 ? "ETH" : "MATIC"} to pay for
          gas fees. You need {ethError}{" "}
          {appData.activeChain === 1 ? "ETH" : "MATIC"} .
        </div>
      )}
      {tokenError !== 0 && (
        <div className="token-swap-balance-error">
          Not enough balance to swap. You need {tokenError} more.
        </div>
      )}
      <div className="token_swap-bottom">
        <button
          className="btn-default btn-blue modal__trigger"
          data-target="modal_token_swap"
          disabled={!availableSwap || !rateLoader || !appData.swapFromQuantity}
          // onClick={() => setActiveModalNav("modal_swap_confirmation")}
          onClick={() => handleSwap()}
        >
          Swap Now
        </button>
        <span>By swapping, you agree to the Terms &amp; Conditions</span>
      </div>

      <Toast
        title=""
        icon={true}
        bodyText={toastMessage}
        hideAfter={5000}
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
      />
    </div>
  );
};

export default SwapMain;
