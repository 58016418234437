/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApp } from "../../../../helpers/AppProvider";
import { useUser } from "../../../../helpers/UserProvider";

const LogoMobile = () => {
  const loc = useLocation();
  const navigate = useNavigate();
  const { userActions } = useUser();
  const { actions } = useApp();

  const redirectToHome = async (e) => {
    e.preventDefault();
    if (loc.pathname === "/dashboard") {
      const acc = await userActions.getAccounts();
      const addreses = acc ? Object.values(acc) : {};
      actions.refreshWalletCardData(addreses);
    } else {
      navigate("/dashboard", { replace: true });
    }
  };

  return (
    <div className="main__header__logo header_logo_mobile">
      <a href="#" title="logo" onClick={redirectToHome}>
        <img src="/images/safle-logo-mobile.svg" alt="logo" />
      </a>
    </div>
  );
};

export default LogoMobile;
