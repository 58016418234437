// import React, { useState ,useEffect , useRef } from 'react';

const DropdownEllipisItem = (items) => {
  const item = items.items;
  //

  return (
    <div
      className={" " + (items.highlight ? "selected" : "")}
      onClick={items.clickHandle}
    >
      <span className="">{item.action_name}</span>
    </div>
  );
};

export default DropdownEllipisItem;
