// import ActivityList from "./ActivityList/ActivityList";
import { useApp } from "../../../helpers/AppProvider";
import { useEffect, useState } from "react";
import ActivityIcon from "../../common/ActivityIcon/ActivityIcon";

const RecentActivity = () => {
  const { appData, actions } = useApp();
  const [transactionsList, setTransactionsList] = useState([]);
  // const [chainTransaction, setChainTransaction] = useState();

  const navigate = (chainName, id) => {
    console.log(chainName, id);
    const explorer =
      chainName === "bitcoin"
        ? "https://btcscan.org"
        : appData.chains.find((chain) => chain.chain_name === chainName)
            ?.details?.explorer;
    const url = explorer + "/tx/" + id;
    window.open(url, "_blank");
  };

  const updateTokenLogo = async (transactions) => {
    let txns = await Promise.all(
      transactions?.map(async (txn) => {
        let updatedSent;
        let updatedReceived;
        if (txn?.sent?.length > 0) {
          updatedSent = {
            ...txn?.sent[0],
            logo_url: await returnImageUrL(
              txn?.sent[0]?.logo_url,
              txn?.sent[0]?.symbol
            ),
          };
        }
        if (txn?.received?.length > 0) {
          updatedReceived = {
            ...txn?.received[0],
            logo_url: await returnImageUrL(
              txn?.received[0]?.logo_url,
              txn?.received[0]?.symbol
            ),
          };
        }
        return {
          ...txn,
          sent: txn?.sent?.length > 0 ? [updatedSent] : null,
          received: txn?.received?.length > 0 ? [updatedReceived] : null,
        };
      })
    );
    setTransactionsList(txns);
  };

  const returnImageUrL = async (imagePath, symbol) => {
    if (imagePath) {
      const res = await actions.urlValidation(imagePath);
      if (res) {
        return imagePath;
      }
    }
    if (symbol) {
      const checkAnotherUrl = await actions.urlValidation(
        `https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${symbol}.png`
      );
      if (checkAnotherUrl) {
        return `https://safle-crypto-logos-48x48.s3.jp-tok.cloud-object-storage.appdomain.cloud/${symbol}.png`;
      }
    }

    return "/images/currency/coin.svg";
  };

  useEffect(() => {
    if (appData.portfolioData) {
      let transactions = [];
      if (appData.activeWallet) {
        // wallet specific
        transactions =
          appData.portfolioData[appData.activeWallet]?.transactions;
      } else {
        // all wallets
        const transactionsInWallet = appData.accounts.map((account) => {
          return appData.portfolioData[account.address]
            ? appData.portfolioData[account.address]?.transactions
            : [];
        });
        transactions = transactionsInWallet.reduce((acc, obj) => {
          return acc.concat(obj);
        }, []);
      }
      updateTokenLogo(transactions);
      setTransactionsList(transactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.portfolioData, appData.activeWallet]);

  const convertTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${hours}:${minutes}:${seconds} | ${day}-${month}-${year}`;

    return formattedDateTime;
  };

  return (
    <>
      <div className="dashboard__overview-column dashboard__overview-activity-cont">
        <div className="dashboard__overview-column-inner">
          <div className="dashboard__overview-column-heading flex flex-row justify-between items-center bg-[#F7F9FB] py-[17px] px-[20px] rounded-t-[8px]">
            <div>RECENT ACTIVITY</div>
            <div>
              {/* <select
                value={chainTransaction}
                onChange={(e) => setChainTransaction(e.target.value)}
                className="bg-[#F7F9FB] border border-[#E5E5E5] rounded-[5px] px-[10px] py-[5px] text-black"
              >
                <option value="">All Chains</option>
                {appData.chains.map((chain) => {
                  return (
                    <option
                      value={
                        chain.chain_name === "ethereum"
                          ? "eth"
                          : chain.chain_name
                      }
                      disabled={chain.chain_name === "mantle"}
                    >
                      {chain.chain_name === "bsc"
                        ? chain.chain_name.toUpperCase()
                        : chain.chain_name.charAt(0).toUpperCase() +
                          chain.chain_name.slice(1)}
                    </option>
                  );
                })}
              </select> */}
            </div>
          </div>
          <div className="dashboard__overview-column-inner-scroll flex flex-col divide-y overflow-y-scroll h-[522px]">
            {/* <ActivityList /> */}
            {transactionsList &&
              transactionsList?.length > 0 &&
              transactionsList?.map((txItem, index) => {
                return (
                  <div
                    key={index}
                    className="p-[13px] flex flex-row justify-between items-center text-[12px]"
                  >
                    <div className="flex flex-row items-center">
                      <img
                        className="w-[25px] h-[25px] rounded-full"
                        src={
                          txItem?.sent?.length > 0
                            ? txItem?.sent?.[0]?.logo_url
                            : txItem?.received?.length > 0
                            ? txItem?.received?.[0]?.logo_url
                            : txItem?.chainName
                            ? appData.chains.find((chain) =>
                                txItem?.chainName === "eth"
                                  ? chain.chain_name === "ethereum"
                                  : chain.chain_name === txItem?.chainName
                              )?.details?.imageURL
                            : txItem?.type === "bitcoin"
                            ? "images/wallets/currency-bitcoin.svg"
                            : ""
                        }
                        alt={txItem?.sent && txItem?.sent[0]?.name}
                      />
                      <div className=" pl-[10px] break-words">
                        {txItem?.sent
                          ? parseFloat(
                              txItem?.sent[0]?.value /
                                Math.pow(10, txItem?.sent[0]?.decimals)
                            )
                              .toFixed(4)
                              .replace(/\.?0+$/, "") < 0.000001
                            ? "< 0.000001"
                            : parseFloat(
                                // eslint-disable-next-line no-mixed-operators
                                txItem?.sent[0]?.value /
                                  Math.pow(10, txItem?.sent[0]?.decimals)
                              ) // eslint-disable-next-line no-mixed-operators
                                .toFixed(8)
                                .replace(/\.?0+$/, "")
                          : txItem?.received
                          ? parseFloat(
                              parseFloat(
                                txItem?.received[0]?.value /
                                  Math.pow(10, txItem?.received[0]?.decimals)
                              )
                                .toFixed(8)
                                .replace(/\.?0+$/, "")
                            ) < 0.000001
                            ? "< 0.000001"
                            : parseFloat(
                                // eslint-disable-next-line no-mixed-operators
                                txItem?.received[0]?.value /
                                  Math.pow(10, txItem?.received[0]?.decimals)
                              ) // eslint-disable-next-line no-mixed-operators
                                .toFixed(8)
                                .replace(/\.?0+$/, "")
                          : txItem?.type === "bitcoin"
                          ? parseFloat(
                              Math.abs(txItem?.value) / Math.pow(10, 8)
                            )
                          : 0}{" "}
                        {txItem?.sent
                          ? txItem?.sent[0]?.symbol
                          : txItem?.received
                          ? txItem?.received[0]?.symbol
                          : txItem?.type === "bitcoin"
                          ? "BTC"
                          : ""}
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="h-[12px] pr-[5px] mb-[15px]">
                        <ActivityIcon
                          type={
                            txItem?.type === "bitcoin"
                              ? txItem?.value > 0
                                ? "receive"
                                : "send"
                              : txItem?.type
                          }
                        />
                      </div>
                      <div className="pr-[10px]">
                        {txItem?.type === "contract_execution"
                          ? "SCE"
                          : txItem?.type === "bitcoin"
                          ? txItem?.value > 0
                            ? "Receive"
                            : "Send"
                          : txItem?.type?.charAt(0)?.toUpperCase() +
                            txItem?.type?.slice(1)}
                      </div>
                      <div>
                        {convertTimestamp(txItem?.date || txItem?.timeStamp)}
                      </div>
                      <div
                        className="recent__activity_bsc"
                        onClick={() =>
                          txItem?.type === "bitcoin"
                            ? navigate(txItem?.type, txItem?.hash)
                            : navigate(
                                txItem?.chainName === "eth"
                                  ? "ethereum"
                                  : txItem?.chainName,
                                txItem?.id
                              )
                        }
                      >
                        <span className="icon-icon_eth_scan"></span>
                      </div>
                    </div>
                  </div>
                );
              })}
            {!transactionsList?.length && (
              <div className="p-[20px] w-[100%] flex flex-row justify-center">
                No Transactions
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentActivity;
