import { useState, useEffect } from "react";
import AssetChartCandleStick from "../../assets/AssetChartCandleStick/AssetChartCandleStick";
import moment from "moment";
import { useApp } from "../../../helpers/AppProvider";

const AssetDetailGraph = ({ initialData, loadingState }) => {
  const [showCandles, setCandles] = useState(false);
  const [range, setRange] = useState(3);
  const [rangeData, setRangeData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const { appData } = useApp();

  useEffect(() => {
    // setRangeData([...initialData])
    onRangeSelect(3);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const onRangeSelect = (id) => {
    setRange(id);
    //
    const to = moment().format("YYYY-MM-DD");
    const from = getRange(id);
    const data = initialData?.filter(function (item) {
      return (
        moment(item.date).format("YYYY-MM-DD") >= from &&
        moment(item.date).format("YYYY-MM-DD") <= to
      );
    });

    if (data?.length) {
      setLoading(true);
      setRangeData(data);
      setLoading(false);
    }
  };

  const getRange = (id) => {
    switch (id) {
      case 0:
        return moment().subtract(2, "d").format("YYYY-MM-DD");
      case 1:
        return moment().subtract(1, "w").format("YYYY-MM-DD");
      case 2:
        return moment().subtract(1, "month").format("YYYY-MM-DD");
      case 3:
        return moment().subtract(1, "y").format("YYYY-MM-DD");
      default:
        return moment().subtract(2, "d").format("YYYY-MM-DD");
    }
  };
  return (
    <div className="assets__overview_single-graph">
      <div className="assets__overview_single-graph-candles">
        {!loading && rangeData?.length && (
          <AssetChartCandleStick
            showCandles={showCandles}
            initialData={rangeData}
          />
        )}
        {!rangeData?.length && (
          <div className="h-[350px]">
            <div className="fade p-4 rounded-xl z-10 flex flex-col items-center justify-center h-[100%]">
              {" "}
              {loadingState && (
                <img
                  src="/images/registration/loading.svg"
                  alt="loading"
                  width={40}
                  className="my-4"
                />
              )}
              {!loadingState && <div>No data found</div>}
            </div>
          </div>
        )}
      </div>
      <div className="assets__overview_single-graph-actions">
        <div
          className={`btn-candle btn-candle-chart ${
            showCandles ? "active" : ""
          }`}
          onClick={() => setCandles(true)}
        >
          {/* <span className="icon-chart-line-solid"></span> */}
          <span className="icon-graph-candle"></span>
        </div>
        <div
          className={`btn-candle btn-linear-chart ${
            !showCandles ? "active" : ""
          }`}
          onClick={() => setCandles(false)}
        >
          {/* <span className="icon-chart-line-solid"></span> */}
          <span className="icon-graph-lines"></span>
        </div>
        <div
          className={`btn-candle btn-today ${
            range.toString() === "0" ? "active" : ""
          }`}
          onClick={() => onRangeSelect(0)}
        >
          Today
        </div>
        <div
          className={`btn-candle btn-weekly ${
            range.toString() === "1" ? "active" : ""
          }`}
          onClick={() => onRangeSelect(1)}
        >
          W
        </div>
        <div
          className={`btn-candle btn-monthly ${
            range.toString() === "2" ? "active" : ""
          }`}
          onClick={() => onRangeSelect(2)}
        >
          M
        </div>
        <div
          className={`btn-candle btn-yearly ${
            range.toString() === "3" ? "active" : ""
          }`}
          onClick={() => onRangeSelect(3)}
        >
          Y
        </div>
      </div>
    </div>
  );
};

export default AssetDetailGraph;
