// import React from "react";
import Web3 from "web3";
import { ERC20ABI } from "../../../helpers/erc20Abi";
import { erc721ABI } from "../../../constants/erc721-abi";
import { erc1155ABI } from "../../../constants/erc1155-abi";
import calculateGasAbi from "../../../helpers/calculateGasAbi";
// import { useApp } from "../../../helpers/AppProvider";
import { toBaseUnit } from "../../../helpers/walletHelpers";

const _normalizeAddress = (addr) => {
  return addr.substr(0, 2) + addr.substr(-40).toLowerCase();
};

const GasCalculation = async ({ appData, chainId, token, config }) => {
  const activeChain = appData.chains.find(
    (i) => i.chainId === (appData?.activeChain || chainId)
  );

  const w3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));
  let fees;

  //type 1 Transactions
  if (
    chainId === 56 ||
    chainId === 5000 ||
    chainId === 106 ||
    chainId === 42161 ||
    chainId === 10
  ) {
    // type 1 native transactions
    if (
      (token?.symbol === "BNB" && chainId === 56) ||
      (token?.symbol === "MNT" && chainId === 5000) ||
      (token?.symbol === "VLX" && chainId === 106) ||
      (token?.symbol === "ETH" && chainId === 42161) ||
      (token?.symbol === "ETH" && chainId === 10)
    ) {
      const gasPrice = await w3.eth.getGasPrice();
      const gasEstimate = await w3.eth.estimateGas({
        to:
          _normalizeAddress(config.toAddress) ||
          _normalizeAddress(config.fromAddress),
        from: _normalizeAddress(config.fromAddress),
        value: w3.utils.numberToHex(
          w3.utils.toWei(
            parseFloat(isNaN(config.value) ? 0 : config.value).toString(),
            "ether"
          )
        ),
      });

      fees = {
        slow: {
          maxFeePerGas: w3.utils.fromWei(parseInt(gasPrice).toString(), "gwei"),
          maxPriorityFeePerGas: w3.utils.fromWei(
            parseInt(gasPrice).toString(),
            "gwei"
          ),
        },
        standard: {
          maxFeePerGas: w3.utils.fromWei(
            parseInt(
              parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
            ).toString(),
            "gwei"
          ),
          maxPriorityFeePerGas: w3.utils.fromWei(
            parseInt(
              parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
            ).toString(),
            "gwei"
          ),
        },
        fast: {
          maxFeePerGas: w3.utils.fromWei(
            parseInt(
              parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
            ).toString(),
            "gwei"
          ),
          maxPriorityFeePerGas: w3.utils.fromWei(
            parseInt(
              parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
            ).toString(),
            "gwei"
          ),
        },
        gasLimit: gasEstimate,
        estimatedBaseFee: 0,
      };
    } else {
      // type 1 NFT transactions
      if (token === "nft") {
        let gasPrice;
        let gasEstimate;
        let tokenContract;
        try {
          gasPrice = await w3.eth.getGasPrice();
          if (config?.erc721 || config.type === "ERC721") {
            tokenContract = new w3.eth.Contract(
              erc721ABI,
              config.contractAddress || config.contract
            );
            gasEstimate = await tokenContract.methods
              .safeTransferFrom(
                config?.wallet,
                config?.to || config?.wallet,
                config?.tokenId
              )
              .estimateGas({ from: config?.wallet });
          } else {
            tokenContract = new w3.eth.Contract(
              erc1155ABI,
              config.contractAddress || config.contract
            );
            gasEstimate = await tokenContract.methods
              .safeTransferFrom(
                config?.wallet,
                config?.to || config?.wallet,
                config?.tokenId,
                1,
                0
              )
              .estimateGas({ from: config?.wallet });
          }
          gasEstimate = gasEstimate * 2;
        } catch (e) {
          console.log(e, "error");
          gasPrice = await w3.eth.getGasPrice();
          gasEstimate = 21000;
        }

        fees = {
          slow: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(gasPrice).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(gasPrice).toString(),
              "gwei"
            ),
          },
          standard: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
          },
          fast: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
          },
          gasLimit: gasEstimate,
          estimatedBaseFee: 0,
        };
      } // type 1 ERC20 transactions
      else {
        let gasPrice;
        let gasEstimate;
        try {
          const tokenContract = new w3.eth.Contract(
            ERC20ABI,
            config.contractAddress
          );
          let decimals = await tokenContract.methods.decimals().call();

          const val = config.value
            ? toBaseUnit(
                parseFloat(config.value).toFixed(decimals).toString(),
                decimals,
                w3.utils.BN
              )
            : 0;

          //

          const contractInstance = new w3.eth.Contract(
            calculateGasAbi,
            config.contractAddress
          );
          gasEstimate = await contractInstance.methods
            .transfer(config.toAddress || config.fromAddress, val)
            .estimateGas({ from: config.fromAddress });
          // const encData = await tokenContract.methods
          //   .transfer(
          //     config.toAddress,
          //     (isNaN(config.value) ? 0 : config.value).toFixed(decimals)
          //   )
          //   .encodeABI();

          gasPrice = await w3.eth.getGasPrice();
          // gasEstimate = await w3.eth.estimateGas({
          //   to: _normalizeAddress(config.contractAddress),
          //   from: _normalizeAddress(config.fromAddress),
          //   data: encData,
          // });
        } catch (e) {
          console.log(e);
          gasPrice = await w3.eth.getGasPrice();
          gasEstimate = 21000;
        }

        fees = {
          slow: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(gasPrice).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(gasPrice).toString(),
              "gwei"
            ),
          },
          standard: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.05 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
          },
          fast: {
            maxFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
            maxPriorityFeePerGas: w3.utils.fromWei(
              parseInt(
                parseFloat(gasPrice) + parseFloat(0.1 * parseFloat(gasPrice))
              ).toString(),
              "gwei"
            ),
          },
          gasLimit: gasEstimate,
          estimatedBaseFee: 0,
        };
      }
    }
  }

  // type 2 Transactions
  if (chainId === 1 || chainId === 137) {
    // type 2 native transactions
    if (
      (token?.symbol === "ETH" && chainId === 1) ||
      (token?.symbol === "MATIC" && chainId === 137)
    ) {
      //   const block = await w3.eth.getBlock("latest");

      const gasLimit = await w3.eth.estimateGas({
        to:
          _normalizeAddress(config.toAddress) ||
          _normalizeAddress(config.fromAddress),
        from: _normalizeAddress(config.fromAddress),
        value: w3.utils.numberToHex(
          (isNaN(config.value) ? 0 : config.value * Math.pow(10, 18)).toString()
        ),
      });

      //   const gasLimit = (block.gasLimit / block.transactions.length).toFixed(0);

      if (token?.symbol === "MATIC" && chainId === 137) {
        const url = process.env.REACT_APP_POLYGON_GAS;
        const resp = await fetch(url).then((e) => e.json());

        fees = {
          slow: {
            maxFeePerGas: parseFloat(resp?.safeLow?.maxFee),
            maxPriorityFeePerGas: parseFloat(resp?.safeLow?.maxPriorityFee),
          },
          standard: {
            maxFeePerGas: parseFloat(resp?.standard?.maxFee),
            maxPriorityFeePerGas: parseFloat(resp?.standard?.maxPriorityFee),
          },
          fast: {
            maxFeePerGas: parseFloat(resp?.fast?.maxFee),
            maxPriorityFeePerGas: parseFloat(resp?.fast?.maxPriorityFee),
          },
          gasLimit: gasLimit,
          estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
        };
      }
      if (token?.symbol === "ETH" && chainId === 1) {
        const url = process.env.REACT_APP_ETH_GAS;
        const resp = await fetch(url).then((e) => e.json());
        fees = {
          slow: {
            maxFeePerGas: parseFloat(resp?.low?.suggestedMaxFeePerGas),
            maxPriorityFeePerGas: parseFloat(
              resp?.low?.suggestedMaxPriorityFeePerGas
            ),
          },
          standard: {
            maxFeePerGas: parseFloat(resp?.medium?.suggestedMaxFeePerGas),
            maxPriorityFeePerGas: parseFloat(
              resp?.medium?.suggestedMaxPriorityFeePerGas
            ),
          },
          fast: {
            maxFeePerGas: parseFloat(resp?.high?.suggestedMaxFeePerGas),
            maxPriorityFeePerGas: parseFloat(
              resp?.high?.suggestedMaxPriorityFeePerGas
            ),
          },
          gasLimit: gasLimit,
          estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
        };
      }
    } else {
      // type 2 NFT transactions
      if (token === "nft") {
        let gasEstimate;
        let tokenContract;
        try {
          if (config?.erc721 || config.type === "ERC721") {
            tokenContract = new w3.eth.Contract(
              erc721ABI,
              config?.contractAddress || config?.contract
            );
            gasEstimate = await tokenContract.methods
              .safeTransferFrom(
                config?.wallet,
                config?.to || config?.wallet,
                config?.tokenId
              )
              .estimateGas({ from: config?.wallet });
          } else {
            tokenContract = new w3.eth.Contract(
              erc1155ABI,
              config?.contractAddress || config?.contract
            );
            gasEstimate = await tokenContract.methods
              .safeTransferFrom(
                config?.wallet,
                config?.to || config?.wallet,
                config?.tokenId,
                1,
                0
              )
              .estimateGas({ from: config?.wallet });
          }
          gasEstimate = gasEstimate * 2;
        } catch (e) {
          console.log(e);
          gasEstimate = 21000;
        }

        if (chainId === 137) {
          const url = process.env.REACT_APP_POLYGON_GAS;
          const resp = await fetch(url).then((e) => e.json());

          fees = {
            slow: {
              maxFeePerGas: parseFloat(resp?.safeLow?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.safeLow?.maxPriorityFee),
            },
            standard: {
              maxFeePerGas: parseFloat(resp?.standard?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.standard?.maxPriorityFee),
            },
            fast: {
              maxFeePerGas: parseFloat(resp?.fast?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.fast?.maxPriorityFee),
            },
            gasLimit: gasEstimate,
            estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
          };

          //     fees.gasLimit = gasLimit;
          //     fees.highPrice = resp?.fast;
          //     fees.standardPrice = resp?.standard;
          //     fees.lowPrice = resp?.safeLow;
        }
        if (chainId === 1) {
          const url = process.env.REACT_APP_ETH_GAS;
          const resp = await fetch(url).then((e) => e.json());
          fees = {
            slow: {
              maxFeePerGas: parseFloat(resp?.low?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.low?.suggestedMaxPriorityFeePerGas
              ),
            },
            standard: {
              maxFeePerGas: parseFloat(resp?.medium?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.medium?.suggestedMaxPriorityFeePerGas
              ),
            },
            fast: {
              maxFeePerGas: parseFloat(resp?.high?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.high?.suggestedMaxPriorityFeePerGas
              ),
            },
            gasLimit: gasEstimate,
            estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
          };
        }
      } // type 2 ERC20 transactions
      else {
        let gasEstimate;
        try {
          const tokenContract = new w3.eth.Contract(
            ERC20ABI,
            config.contractAddress
          );
          let decimals = await tokenContract.methods.decimals().call();

          const val = config.value
            ? toBaseUnit(
                parseFloat(config.value).toFixed(decimals).toString(),
                decimals,
                w3.utils.BN
              )
            : 0;

          //

          const contractInstance = new w3.eth.Contract(
            calculateGasAbi,
            config.contractAddress
          );
          gasEstimate = await contractInstance.methods
            .transfer(config.toAddress || config.fromAddress, val)
            .estimateGas({ from: config.fromAddress });
        } catch (e) {
          console.log(e);
          gasEstimate = 21000;
        }

        if (chainId === 137) {
          const url = process.env.REACT_APP_POLYGON_GAS;
          const resp = await fetch(url).then((e) => e.json());

          fees = {
            slow: {
              maxFeePerGas: parseFloat(resp?.safeLow?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.safeLow?.maxPriorityFee),
            },
            standard: {
              maxFeePerGas: parseFloat(resp?.standard?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.standard?.maxPriorityFee),
            },
            fast: {
              maxFeePerGas: parseFloat(resp?.fast?.maxFee),
              maxPriorityFeePerGas: parseFloat(resp?.fast?.maxPriorityFee),
            },
            gasLimit: gasEstimate,
            estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
          };

          //     fees.gasLimit = gasLimit;
          //     fees.highPrice = resp?.fast;
          //     fees.standardPrice = resp?.standard;
          //     fees.lowPrice = resp?.safeLow;
        }
        if (chainId === 1) {
          const url = process.env.REACT_APP_ETH_GAS;
          const resp = await fetch(url).then((e) => e.json());
          fees = {
            slow: {
              maxFeePerGas: parseFloat(resp?.low?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.low?.suggestedMaxPriorityFeePerGas
              ),
            },
            standard: {
              maxFeePerGas: parseFloat(resp?.medium?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.medium?.suggestedMaxPriorityFeePerGas
              ),
            },
            fast: {
              maxFeePerGas: parseFloat(resp?.high?.suggestedMaxFeePerGas),
              maxPriorityFeePerGas: parseFloat(
                resp?.high?.suggestedMaxPriorityFeePerGas
              ),
            },
            gasLimit: gasEstimate,
            estimatedBaseFee: parseFloat(resp?.estimatedBaseFee),
          };
        }
      }
    }
  }
  // console.log(fees);
  return fees;
};

export default GasCalculation;
