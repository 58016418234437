import React from "react";
import TokenIcon from "../common/TokenIcon/TokenIcon";
import MainModal from "./../generic/MainModal";
import { useApp } from "./../../helpers/AppProvider";

const ModalSwapSelectChain = ({ onClose }) => {
  const { appData, actions } = useApp();

  // const assetItems = [
  //     {
  //         title: 'Ethereum',
  //         icon: `${ process.env.PUBLIC_URL }/images/swap/eth-chain.svg`,
  //     },
  //     {
  //         title: 'Polygon',
  //         icon: `${ process.env.PUBLIC_URL }/images/swap/polygon-chain.svg`,
  //     },
  //     {
  //         title: 'BSC',
  //         icon: `${ process.env.PUBLIC_URL }/images/swap/bnb-chain.svg`,
  //     },
  // ];

  const onClickItem = (item) => {
    actions.setAppData({ activeChain: item.chainId });
    onClose();
  };

  return (
    <MainModal
      title="Select Chain"
      onClose={onClose}
      extraClasses={["modal_w400 modal_mh368 modal_swap_select_chain"]}
      dataClass="modal_swap_select_chain"
    >
      <div className="modal_chain_items">
        {appData.chains
          .filter(
            (item) =>
              item.chain_name === "ethereum" || item.chain_name === "polygon"
          )
          .map((item, i) => (
            <div
              className="modal_wallet_item"
              key={i}
              onClick={() => onClickItem(item)}
            >
              <div
                className="asset__coin form__input_filter_item"
                data-name={""}
              >
                <TokenIcon logo={item.details.imageURL} />
                <div className="asset__coin_content">
                  <div className="asset__coin_names">
                    <div className="asset__coin-name asset__coin-name--generate">
                      {item.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </MainModal>
  );
};

export default ModalSwapSelectChain;
