/* eslint-disable react/jsx-no-target-blank */
import ActionContainer from "./ActionContainer/ActionContainer";
import Logo from "./Logo/Logo";
import LogoMobile from "./LogoMobile/LogoMobile";
import MobileNavBadge from "./MobileNavBadge/MobileNavBadge";
import Toast from "../../registration/Toast/Toast";
import { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";

const Header = ({ sidebarActive, setSidebarActive }) => {
  const { notificationData, actions } = useApp();
  const [toastOpen, setToastOpen] = useState(false);
  useEffect(() => {
    if (notificationData.txConfirmation) {
      // setTimeout( () => setToastOpen( true ), 1000 );
      setToastOpen(true);
      setTimeout(() => {
        actions.setNotification({ txConfirmation: false });
        setToastOpen(false);
      }, 9000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);
  return (
    <div className="main__header">
      <span className="right_border_radius_fake_relative">
        <span className="right_border_radius_fake"></span>
      </span>
      <MobileNavBadge
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <Logo />
      <LogoMobile />
      <ActionContainer />

      <div
        className="seed-phrase-toast-ctn transaction-success"
        style={{ position: "absolute", right: 0, top: 0 }}
      >
        <Toast
          title=""
          bodyText={
            <div style={{ textAlign: "center" }}>
              Transaction succeded.{" "}
              <a href={notificationData.txDetails} target="_blank">
                View details &rarr;
              </a>
            </div>
          }
          // hideAfter={10000}
          isOpen={toastOpen}
          setOpen={(val) => setToastOpen(val)}
          seedPhrase={false}
        />
      </div>
    </div>
  );
};

export default Header;
