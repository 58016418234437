import React, { useState, useEffect } from "react";

import { useUser } from "./../../../../helpers/UserProvider";
// import { maxChars } from "./../../../../helpers/generalHelper";

// import ModalWalletDetails from "../../../dashboard/dialogs/ModalWalletDetails/ModalWalletDetails";
import ModalWalletSafleID from "../../../dashboard/dialogs/ModalWalletSafleID/ModalWalletSafleID";
import { useApp } from "../../../../helpers/AppProvider";

const UserProfileBlock = ({ children }) => {
  const { appData } = useApp();
  const { userData, userActions } = useUser();
  const [imgSrc, setImgSrc] = useState(false);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [popup, setPopup] = useState("");
  // const blockieImg = React.createRef();
  const item = appData.safleCard || {};

  const getIdFontSize = (idLength) => {
    if (idLength <= 10) {
      return "24px";
    } else if (idLength > 10) {
      return `${(23 * 10) / idLength}px`; // calculate dynamic size by length id
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const load_data = async () => {
    const profile_img = await userActions.getProfileImg();
    if (profile_img.indexOf("data:") !== -1) {
      setImgSrc(profile_img);
    } else {
      setImgSrc(profile_img + `?${~~(Math.random() * 1000)}`);
    }

    setTimeout(() => {
      setFadeClass("fade-in");
    }, 100);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    load_data();
  }, [userData.user]);

  const showPopup = () => {
    setPopup("modal_safleid_details");
  };
  const closePopup = () => {
    setTimeout(() => setPopup(""), 0);
  };

  return (
    <div className="user__box min-h-[225px]" onClick={showPopup}>
      <div className="user__box-blockies">
        <div className="mx-auto user__box-blockies_img">
          {imgSrc && (
            <img src={imgSrc} className={"mx-auto " + fadeClass} alt="" />
          )}
        </div>
      </div>
      <div className="user__box-name">
        <span>Safle ID</span>
        <span
          className="colored"
          title={userData.user.safleID}
          style={{ fontSize: getIdFontSize(userData.user.safleID.length) }}
        >
          {" "}
          {userData.user.safleID}
        </span>
      </div>

      {popup === "modal_safleid_details" && (
        // <ModalWalletDetails items={item} onClose={closePopup} useModalData={false} />
        <ModalWalletSafleID
          addr={item.address}
          onClose={closePopup}
          safleID={userData.user.safleID}
        />
      )}
    </div>
  );
};

export default UserProfileBlock;
