import React, { useEffect, useState } from "react";
// import { useUser } from "../../helpers/UserProvider";

import MainModal from "../generic/MainModal";
import SafleSearchID from "../common/SafleSearchID/SafleSearchID";
import Tip from "../common/Tip/Tip";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import { getChangeSalfeIdCount } from "../../helpers/walletHelpers";
import { useApp, LIMIT_SAFLEID_CHANGE } from "../../helpers/AppProvider";

const ModalChangeID = ({
  onClose,
  callbackOpenPopup,
  currentSafleId = "",
  newSafleId,
  setNewSafleId,
}) => {
  const { appData, actions } = useApp();
  // const { userData, userActions } = useUser();

  const [countAttempts, setCountAttempts] = useState("");
  const [availableSafleId, setAvailableSafleId] = useState(null);
  const [validation, setValidation] = useState({});

  const load_remaining_attempts = () => {
    async function loadRemAttempts() {
      if (appData?.accounts?.length > 0) {
        const acc = await appData?.accounts;
        //
        const addreses = acc ? Object.values(acc) : {};

        const wallets = await actions.getWalletCards(addreses);

        const address = wallets?.safleCard?.address;
        const remaning = address
          ? await getChangeSalfeIdCount(address)
          : LIMIT_SAFLEID_CHANGE;

        //

        setCountAttempts(remaning);
      }
    }

    loadRemAttempts();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    load_remaining_attempts();
  }, [appData?.accounts]);

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w440",
        "modal_p30",
        "modal_dflex",
        "modal_change_safle",
      ]}
      dataClass="modal_change_id"
    >
      <div className="modal_succes_content">
        <div className="modal_succes_head pt_60">Change Safle ID</div>
        <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered">
            You can update your SafleID to a new one. <br /> Just search below
            to check availability.
          </span>
        </div>
        <div className=" modal--padding">
          <div className="change_safle_ids-wrp">
            <div className="change_safle_row mb-30">
              <div className="change_safle_top_label">
                <span>Safle ID</span>
              </div>
              <div className="change_safle_top_search">
                <SafleSearchID
                  setNewSafleId={setNewSafleId}
                  setAvailableSafleId={setAvailableSafleId}
                  setValidation={setValidation}
                  setCountAttempts={setCountAttempts}
                />
              </div>
            </div>

            {newSafleId && (
              <>
                <div className="change_safle_row">
                  <div className="change_safle_current">
                    <div className="change_safle_left_side">Available</div>
                    <div className="change_safle_right_side">
                      {availableSafleId}
                      <span
                        className={availableSafleId === true ? "blue" : "red"}
                      >
                        {newSafleId}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="change_safle_row_divider" />
                <div className="change_safle_row mb-10">
                  <div className="change_safle_current">
                    <div className="change_safle_left_side">
                      Current Safle ID
                    </div>
                    <div className="change_safle_right_side">
                      <span className="blueish">{currentSafleId}</span>
                    </div>
                  </div>
                </div>
                <div className="change_safle_row">
                  <div className="change_safle_current">
                    <div className="change_safle_left_side">Remaining</div>
                    <div className="change_safle_right_side">
                      <span
                        className="default"
                        style={{
                          color: countAttempts === 0 ? "red" : "inherit",
                        }}
                      >
                        {countAttempts}{" "}
                        {countAttempts > 1 ? "updates" : "update"}*
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {validation?.type && (
            <Tip text={validation.message} extraStyle={validation.type} />
          )}

          <Tip
            text={`Tip: Change your safle ID if it is required, you can only change your safle ID, only ${LIMIT_SAFLEID_CHANGE} times`}
          />
        </div>
        <div className="modal_succes_content_actions ">
          <PrimaryBtn
            classes={"btn-default btn-blue modal__trigge"}
            label="Next"
            disabled={
              !availableSafleId ||
              countAttempts === 0 ||
              (validation && validation?.type === "error")
            }
            onClick={() => callbackOpenPopup("confirm_change_safleid")}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalChangeID;
