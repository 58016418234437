// import React, { useState ,useEffect , useRef } from 'react';

const AssetsTheadFilterItems = (items) => {
  const item = items.items;

  return (
    <div
      style={{ paddingLeft: 0 }}
      onClick={() => items?.items?.filter_callback()}
      className={
        "drop-link dropdown__active__item control-filters-dropdown-item " +
        (items.highlight ? "selected" : "")
      }
    >
      <div className="filter-content">
        <span className={item.filter_icon}></span>
        <span className="drp_name filter_name">{item.filter_label}</span>
      </div>
    </div>
  );
};

export default AssetsTheadFilterItems;
