import React, { useEffect } from "react";

import ModalActionFailed from "./../../dialogs/ModalActionFailed";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  CHANGE_PASSWORD_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const ModalChangePasswordFailedFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] =
    useModalNavigation(CHANGE_PASSWORD_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  const onRetry = () => {
    setActiveModalNav("modal_change_pin_enter_pin");
  };

  return (
    <ModalActionFailed
      title="Password updated failed"
      desc=""
      dataClass="modal_action_failed1"
      onClose={onClose}
      callbackOpenPopup={onClose}
      callbackRetryPopup={onRetry}
      iconErr={"images/wallets/update-failed-warning.svg"}
    />
  );
};

export default ModalChangePasswordFailedFunctions;
