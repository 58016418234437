function updateStretcher(_a) {
    var boundary = _a.boundary, stretcher = _a.stretcher, size = _a.size;
    // Reset stretcher
    stretcher.style.width = "0px";
    stretcher.style.height = "0px";
    // Try to fit the size by width:
    stretcher.style.width = Math.max(boundary.clientWidth, size.width) + "px";
    // After that try to fit the size by height and resulted width:
    var ratio = size.width / size.height;
    stretcher.style.height = Math.max(boundary.clientHeight, stretcher.clientWidth / ratio) + "px";
    stretcher.style.width = stretcher.clientHeight * ratio + "px";
}

export { updateStretcher };
