/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import DropdownFilterItem from "../DropdownFilterItem/DropdownFilterItem";
import { useApp } from "./../../../helpers/AppProvider";
import SearchInput from "../../settings/SearchInput/SearchInput";

const DropdownFilter = (props) => {
  const { actions } = useApp();
  let dropdownType = "";
  if (props.compType && props.compType !== undefined) {
    dropdownType = props.compType;
  }
  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState("");

  //populate an object with items

  const filteredItems = props.items;
  // first time load the first address in the object
  // or select the selected used address
  useEffect(() => {
    setDropdown(filteredItems[0]);
  }, []);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes = "main__controls controls__dropdown ";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);
        actions.setAppData({ blockScroll: false });
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: true });
      // document.body.classList.add('no_scroll_dropdown');
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      actions.setAppData({ blockScroll: false });
      // document.body.classList.remove('no_scroll_dropdown');
    };
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, item) => {
    // add class with selected
    setHighlightedItem(index);
    // set the state for the dropdown with last value
    setDropdown(item);

    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    actions.setAppData({ blockScroll: false });
    // document.body.classList.remove('no_scroll_dropdown');
    props.onSelectAction && props.onSelectAction(item);
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(filteredItems.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //filteredItems[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, filteredItems[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };

  let title = "";
  if (props?.title) {
    title = props.title;
  } else {
    title = dropdown.sort_name;
  }

  const handleFilter = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="flex items-baseline flex-col">
      <div
        className="aoc_table_col"
        data-cls="asset_price"
        style={{
          fontWeight: 400,
          fontSize: 8,
          color: "#8690A0",
          marginBottom: 5,
        }}
      >
        <span>{title}</span>
      </div>
      <div
        className={finalDrpClasses}
        ref={dropdownRef}
        onKeyUp={dropdownMoveUp}
        tabIndex="0"
      >
        <div className="dropdown__active__item" onClick={onClick}>
          <span className="drp_name lang_name">
            {typeof highlighted === "number"
              ? filteredItems[highlighted]?.sort_name
              : title}
          </span>
          <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
        </div>
        <div className="dropdown__list__items">
          {dropdownType === "currency" && (
            <div className="search_currency_filtered">
              <SearchInput
                search={search}
                handleFilter={handleFilter}
                title="Search"
              />
            </div>
          )}
          <div className="position_relative_inner">
            {filteredItems.map((dummyAddres, index) => (
              <DropdownFilterItem
                items={dummyAddres}
                showSymbol={props.showSymbol}
                drpType={dropdownType}
                highlight={index === highlighted}
                key={index}
                clickHandle={() => onClickItem(index, dummyAddres)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownFilter;
