import { useContext, useState } from "react";
import popupContext from "./../context/popupContext";
import initialState from "./../context/initialStatePopup";

import { useCheckScroll } from "./generalHelper";

const PopupProvider = ({ children }) => {
  const state = useProvidePopup();
  return (
    <popupContext.Provider value={state}>{children}</popupContext.Provider>
  );
};

const useProvidePopup = () => {
  const [popupData, setPopupData] = useState(initialState);

  useCheckScroll(popupData);

  return {
    popupData,
    setPopupData,
  };
};

const usePopup = () => {
  return useContext(popupContext);
};
export { usePopup, PopupProvider };
