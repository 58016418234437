import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import AssetsTabsOverview from "../components/assets/AssetsTabsOverview/AssetsTabsOverview";
import ActivityHistory from "../components/assets/ActivityHistory/ActivityHistory";
import { useApp } from "../helpers/AppProvider";
import { useEffect, useState } from "react";

const ActivityScreen = () => {
  const { appData, actions } = useApp();
  const [alltransactions, setAlltransactions] = useState([]);
  const [transactionCount, setTransactionCount] = useState(0);
  const [endCalls, setEndCalls] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sidebarActive, setSidebarActive] = useState(false);

  async function getWalletTransactions() {
    let activeWallet;
    if (appData.activeWallet) {
      activeWallet = appData.activeWallet;
    } else {
      activeWallet = appData?.accounts[0]?.address;
    }
    actions
      .getAllTransactions(
        activeWallet,
        appData.activeChain,
        10,
        transactionCount
      )
      .then((result) => {
        if (appData.activeChain) {
          let chainName = appData.chains.filter(
            (chain) => chain.chainId === appData.activeChain
          )[0]?.chain_name;
          if (chainName === "ethereum") {
            chainName = "eth";
          }
          const transactionListByChains = result[chainName]?.transactions?.map(
            (obj) => {
              return { ...obj, chain: chainName };
            }
          );
          if (transactionListByChains?.flat()?.length === 0) {
            setEndCalls(true);
          }
          let newTransactionList = alltransactions?.concat(
            transactionListByChains?.flat()
          );
          setAlltransactions(newTransactionList);
          if(result?.activeChains?.length === 0) {
              setEndCalls(true);
            }
        } else {
          const transactionListByChains = result?.activeChains?.map(
            (chainName) => {
              return result[chainName]?.transactions?.map((obj) => {
                return { ...obj, chain: chainName };
              });
            }
          );
          if (transactionListByChains?.flat()?.length === 0) {
            setEndCalls(true);
          }
          let newTransactionList = alltransactions?.concat(
            transactionListByChains?.flat()
          );
          setAlltransactions(newTransactionList);
        }
        setTransactionCount(transactionCount + 10);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  useEffect(() => {
    if (transactionCount === 0) {
      getWalletTransactions();
      actions.getPriceDataNativeCoins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionCount]);

  useEffect(() => {
    setEndCalls(false);
    setAlltransactions([]);
    setTransactionCount(0);
  }, [appData.activeWallet, appData.activeChain]);

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {sidebarActive && <div className="mobile_blur"></div>}
        {/* <!-- main content --> */}
        <div className="main__content__wrp assets__overview-wrapper">
          <div className="assets__overview" data-to_show="all_assets">
            <AssetsTabsOverview
              btn="activity"
              search={searchData}
              setSearchData={setSearchData}
              startDate={startDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              endDate={endDate}
            />
            {process.env.REACT_APP_HIDE_SWAPS !== "true" && (
              <ActivityHistory
                alltransactions={alltransactions}
                search={searchData}
                startDate={startDate}
                endDate={endDate}
                activityCall={getWalletTransactions}
                endCalls={endCalls}
              />
            )}
            {process.env.REACT_APP_HIDE_SWAPS === "true" && (
              <div
                className="assets__overview_content"
                style={{ height: "100%", width: "100%", position: "relative" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    zIndex: 100,
                    position: "absolute",
                    left: "0",
                    top: "0",
                  }}
                >
                  <div className="settings__overview_guardian_setup">
                    <span className="msbico-planet_guardian" />
                    <span className="coming_soon">Coming Soon</span>
                    <div className="coming_soon_msg">
                      We will notify you once this feature is ready 🙌
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        {/* <!-- end aside notification --> */}
      </div>
    </div>
  );
};

export default ActivityScreen;
