import React, { useEffect }  from 'react';
import { useApp } from '../../../helpers/AppProvider';
import { useUser } from '../../../helpers/UserProvider';
import ModalBuyAssetPurchaseFunctions from './../../common/AssetsBuyAssetProcess/ModalBuyAssetPurchaseFunctions';
import ModalBuyCryptoFunctions from './../../common/AssetsBuyAssetProcess/ModalBuyCryptoFunctions';
import ModalBuyAssetFailedFunctions from './../../common/AssetsBuyAssetProcess/ModalBuyAssetFailedFunctions';
import ModalBuyAssetCancelFunctions from './../../common/AssetsBuyAssetProcess/ModalBuyAssetCancelFunctions';


import useModalNavigation from '../../../helpers/useModalNavigation';
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';




const AssetsBuyAssetProcess = (props) => {
    const { userData, userActions } = useUser();
    
    const { appData: { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}, wallets = []}, setAppData } = useApp() || {};

    const [ setActiveModalNav, goNext , goBackward ] = useModalNavigation(SEND_ASSET_FLOW);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    
    const onCancel = (e) => {
        // setActiveModalNav('CLOSED');
    }
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    
    return (
        <>
            
            
            {activeModalLabel === 'modal_buy_asset' &&
                <ModalBuyAssetPurchaseFunctions onClose={onClose} />
            }
            {activeModalLabel === 'modal_buy_crypro_asset' &&
                <ModalBuyCryptoFunctions onClose={onClose} />
            }
            
          
            {activeModalLabel === 'modal_buy_asset_failed' &&
                <ModalBuyAssetFailedFunctions />
            }
            
            {activeModalLabel === 'modal_buy_asset_cancel' &&
                <ModalBuyAssetCancelFunctions />
            }

        </>
    );
}

export default AssetsBuyAssetProcess;