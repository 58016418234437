// import React, { useState ,useEffect , useRef } from 'react';

const DropdownWalletItem = (items) => {
  const item = items.items;
  //
  let drpType = "";
  if (items.drpType && items.drpType !== undefined) {
    drpType = items.drpType;
  }

  return (
    <div
      className={"drop-link " + (items.highlight ? "selected" : "")}
      onClick={items.clickHandle}
    >
      <span className="item_ico icon-wallet-solid"></span>
      <span className="drp_name wallet_address">{item.wallet_address}</span>
      {drpType === "wamount" && (
        <span className="wallet_asset_amount">
          {item.wallet_ammount} {item.wallet_prefix}
        </span>
      )}
    </div>
  );
};

export default DropdownWalletItem;
