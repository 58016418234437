import { useState } from 'react';
import AssetBadge from '../AssetBadge/AssetBadge';
import TokenIcon from '../TokenIcon/TokenIcon';
import PrimaryBtn from '../PrimaryBtn/PrimaryBtn';
import InformationCircleIcon from '@heroicons/react/solid/InformationCircleIcon';

const WalletOptionItem = (props) => {
    //{ props, name, icon, abbr, wallets }

    const name = props.name;
    const icon = props.icon;
    const abbr = props.abbr;
    const wallets = props.wallets;


    
  const [ tooltipActive, setTooltipActive ] = useState(false);


    return (
        <div className="import_wallet_generate-row">
            <div className="asset__coin form__input_filter_item" data-name={name}>
                <TokenIcon logo={icon} />
                <div className="asset__coin_content">
                    <div className="asset__coin_names">
                        {/* <div className="asset__coin-name">{name}</div> */}
                        <div className="asset__coin-name asset__coin-name--generate">
                    {name}
                  </div>
                        <div className="asset__coin-info flex flex-row items-center">
                        <AssetBadge coinPrefix={abbr} />
                        {props.abbr === "EVM" && (
                        <div className="tooltip"
                            onMouseEnter={()=> setTooltipActive(true)}
                            onMouseLeave={()=> setTooltipActive(false)}
                            >

                        <span className="ico icon-info-circle-solid" />
                            {/* {type.code ==="EVM" ? ( ) :()} */}
                        <span className="tooltiptext" 
                          style={
                            tooltipActive
                              ? { visibility: "visible" }
                              : { visibility: " " }
                          }>
                          <span className="max-fee-title">EVM</span>
                          <div className="max-fee-text">
                            Ethereum Virtual Machine compatible
                          </div>
                        </span>
                      </div>
                    )}
                    </div>
                    </div>
                    <div className="asset__coin-text">{wallets}</div>
                </div>
            </div>
            <div className="import_wallet_generate-action">

                <button
                    className={'btn-default btn-white-with-border btn-white-with-border-bh modal__trigger'}
                    data-target="modal_change_safle_user"
                    onClick={() => props.callbackOpenPopup('modal_set_wallet_label')}
                >
                    Private Key
                </button>
                {/* <PrimaryBtn classes="btn-default btn-white-with-border btn-white-with-border-bh modal__trigger" label="Private Key" /> */}
                {/* <PrimaryBtn classes="btn-default btn-white-with-border btn-white-with-border-bh" label="Seed Phrase" /> */}
            </div>
        </div>
    );
}

export default WalletOptionItem;