import React, { useState } from "react";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import MainModal from "../generic/MainModal";
import SeedWordsInput from "../common/SeedWordsInput/SeedWordsInput";
import TooltipCopy from "../registration/TooltipCopy/index";
import SeedPhraseVisibleButton from "../common/SeedPhraseVisibleButton/SeedPhraseVisibleButton";
import PrintSeedPhraseButton from "../common/PrintSeedPhraseButton/PrintSeedPhraseButton";

const ModalSeedPhrase = (props) => {
  const seedPhrase = props.mnemonicPhrase
    ? props.mnemonicPhrase.split(" ")
    : [""];
  const address = props.address || "";

  const [seedsVisible, setSeedsVisible] = useState("password");
  const [eyeIcon, setEyeIcon] = useState("EyeHide.svg");

  const toggleSeedsVisibility = () => {
    if (seedsVisible === "password") {
      setSeedsVisible("text");
      setEyeIcon("Eye-Show.svg");
    } else {
      setSeedsVisible("password");
      setEyeIcon("EyeHide.svg");
    }
  };

  return (
    <MainModal
      onClose={() => props.callbackCancelPopup(props.modalCancel_label)}
      extraClasses={[
        "modal_w440",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_seed_phrase",
      ]}
      dataClass="modal_asset_added"
    >
      <div className="modal_succes_content">
        <ModalHeadIcon img="images/import-wallet.svg" />
        <div className="modal_succes_head">Seed Phrase</div>
        <SeedWordsInput
          seedPhrase={seedPhrase}
          visible={seedsVisible}
          pasteHandler={props.pasteHandler}
          screen="seed_phrase"
        />
        <div className="seed-phrase_controls">
          <div className="images">
            <SeedPhraseVisibleButton
              toggleSeedsVisibility={toggleSeedsVisibility}
              icon={eyeIcon}
              cssClasses=" tooltip_display_seedphrase tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy tooltip_seed_phrase_copy"
              tooltipText={"Show/Hide"}
            />
            <TooltipCopy
              extraClasses="tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy tooltip_seed_phrase_copy"
              textToDisplay={""}
              imageToDisplay={
                <img
                  src="images/settings/copy-icon.svg"
                  alt="copy to clipboard"
                />
              }
              tooltipTextIn={"Copy to clipboard"}
              tooltipTextInChange={"Copied to clipboard"}
              copiedText={seedPhrase.join(" ")}
              hideAfter="3000"
            />
            <PrintSeedPhraseButton
              seedPhrase={seedPhrase}
              address={address}
              cssClasses=" tooltip_print tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy tooltip_seed_phrase_copy"
              tooltipText={"Print"}
            />
          </div>
        </div>
      </div>
      <div className="modal_succes_content_actions">
        <PrimaryBtn
          onClick={() => props.callbackOpenPopup(props.modalNext_label)}
          classes="btn-default btn-blue btn-fw600 close_modal_ok"
          label="Done"
        />
      </div>
    </MainModal>
  );
};

export default ModalSeedPhrase;
