/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import ModalLinkWallet from "../../dialogs/ModalLinkWallet";
import ModalLinkWalletSuccess from "../../dialogs/ModalLinkWalletSuccess";
import ModalWalletFailed from "../../dialogs/ModalWalletFailed";
import ModalChangePIN from "../../dialogs/ModalChangePIN";
import ModalWarning from "../../dialogs/ModalWarning";

import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import { getCoinId, linkWalletToSafleId } from "../../../helpers/walletHelpers";
import useModalNavigation from "../../../helpers/useModalNavigation";
import { LINK_WITH_SAFLEID, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import { useRegistration } from "../../../helpers/RegistrationProvider";
import Storage from "../../../helpers/Storage";
// import Preloader from "../../common/Preloader/Preloader";

const LinkWalletSafleIdProcess = () => {
  const { userData, userActions } = useUser();

  const { encryptionKey } = useRegistration();
  const {
    actions,
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      ...appData
    },
  } = useApp() || {};
  const [setActiveModalNav] = useModalNavigation(LINK_WITH_SAFLEID);

  const [pinValue, setPinValue] = useState("");
  const [validation, setValidation] = useState(false);

  const onClose = () => {
    setActiveModalNav("CLOSED");
    Storage.remove("selectedWalletForLink");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelGeneratePopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  const onCompletePIN = (pinVal) => {
    setValidation(false);
    setPinValue(pinVal);
  };

  const checkDuplicateWalletChain = () => {
    const selectedWallet = Storage.load("selectedWalletForLink");
    const item = appData.wallets.find(
      (i) =>
        i.address !== selectedWallet.address &&
        i.chain === selectedWallet?.chain &&
        i.buttonState === "linked"
    );

    return item;
  };

  const linkWalletProcess = async () => {
    // const selectedWallet = Storage.load("selectedWalletForLink");
    const duplicateWalletChain = checkDuplicateWalletChain();
    // return
    // if(duplicateWalletChain){
    // setActiveModalNav('update_link_to_safleid')
    // }
    try {
      onLinkWalletToSafleId(duplicateWalletChain);
    } catch (error) {
      actions.setLoading(false);
      callbackCancelGeneratePopup("link_wallet_failed");
    }
  };

  const onLinkWalletToSafleId = async (update = false) => {
    try {
      actions.setLoading(true);

      const userVault = userData?.user?.vault;
      const selectedWallet = Storage.load("selectedWalletForLink");
      const coin = appData.chains.find(
        (i) => i.details.chainId === selectedWallet.chain
      );

      const coinId = getCoinId(coin.details.symbol) + 1;

      const chain = appData.chains.find(
        (i) => i.chainId === selectedWallet.chain
      )?.chain_name;
      const encryptionKey = userActions.getDecriptionKey();
      if (!userVault) {
        setValidation("Your vault is not available. Please login again.");
      }

      if (pinValue && userVault) {
        const publicAddress = await userActions.getAccountReg(
          userVault,
          Number(pinValue),
          encryptionKey
        );

        const { privateKey } = (
          await userActions.exportPrivateKey(
            publicAddress,
            // encryptionKey,
            parseInt(pinValue)
          )
        ).response;

        const user = userData?.user;

        // return;

        const { responseVault, error } = await linkWalletToSafleId(
          user,
          Number(pinValue),
          publicAddress,
          privateKey,
          coinId.toString(),
          process.env.REACT_APP_WEB3_PROVIDER_URL,
          chain,
          update
        );

        if (!error) {
          if (responseVault.statusCode === 201) {
            // setAppData({wallets: [...oldWalletsList, lastAddedWallet ]});

            // if No Errors Navigate to Success modal
            callbackOpenPopup("link_wallet_sucess");
            actions.setLoading(false);
          } else {
            setValidation("No valid wallet linked.");
            // if Errors Navigate to Failed modal
            callbackCancelGeneratePopup("link_wallet_failed");
            actions.setLoading(false);
          }
        } else {
          setValidation(error);
          // if Errors Navigate to Failed modal
          callbackCancelGeneratePopup("link_wallet_failed");
          actions.setLoading(false);
        }
      } else {
        setValidation("Privatekey, Pin or any other input has wrong data.");
        actions.setLoading(false);
      }
    } catch (error) {
      actions.setLoading(false);
      callbackCancelGeneratePopup("link_wallet_failed");
      throw new Error(error);
    }
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {
        // link_to_safleid:
        // link_wallet_pin:
        // link_wallet_sucess:
        // link_wallet_failed:
      }
      {/* <Preloader /> */}
      {activeModalLabel === "link_to_safleid" && (
        <ModalLinkWallet
          onClose={onClose}
          callbackOpenPopup={callbackOpenPopup}
          checkDuplicateWalletChain={checkDuplicateWalletChain}
        />
      )}
      {activeModalLabel === "link_wallet_pin" && (
        <ModalChangePIN
          validation={validation}
          onCompletePIN={onCompletePIN}
          submitAction={() => linkWalletProcess()}
          callbackCancelGeneratePopup={onClose}
          customTitle={"Enter PIN to proceed"}
        />
      )}
      {activeModalLabel === "update_link_to_safleid" && (
        <ModalWarning onClose={onClose} callbackOpenPopup={callbackOpenPopup} />
      )}
      {activeModalLabel === "link_wallet_sucess" && (
        <ModalLinkWalletSuccess onClose={onClose} />
      )}
      {activeModalLabel === "link_wallet_failed" && (
        <ModalWalletFailed
          onClose={onClose}
          callbackOpenPopup={callbackOpenPopup}
          validation={validation}
          reason={"Wallet linked to SafleId failed."}
          retryModal={"link_to_safleid"}
        />
      )}
    </>
  );
};

export default LinkWalletSafleIdProcess;
