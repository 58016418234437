import React from "react";
import {
  CheckCircleIcon,
  // XCircleIcon,
  ExclamationCircleIcon,
  // EyeIcon,
  // EyeOffIcon,
} from "@heroicons/react/solid";

const InputGroup = ({
  label,
  name,
  placeholder,
  icon,
  hiddenEye,
  visibleEye,
  valid,
  check = false,
  errorMsg = false,
  ...rest
}) => {
  //
  return (
    <div className="input-group mt-4 relative">
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 sign_in_label"
      >
        {label}
      </label>

      <div className="mt-3 relative rounded-md shadow-sm border border-gray-300 flex items-center h-10 no_border">
        {icon !== undefined && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ">
            {" "}
            {icon}
          </div>
        )}
        {hiddenEye !== undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer ">
            {" "}
            {hiddenEye}
          </div>
        )}
        {visibleEye !== undefined && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer ">
            {" "}
            {visibleEye}
          </div>
        )}
        <input
          style={{ paddingRight: "26px" }}
          type="text"
          name={name}
          id={name}
          className={
            (valid === false && check === false ? "border-error " : "") +
            (icon !== undefined ? "pl-10 " : "pl-2 ") +
            " default-input block h-full w-full text-xs md:text-sm border_light_gray rounded-md"
          }
          placeholder={placeholder}
          {...rest}
        />

        {valid === false && check === false && hiddenEye === undefined && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none ">
            {" "}
            <ExclamationCircleIcon
              className="h-4 w-4 error_color"
              aria-hidden="true"
            />{" "}
          </div>
        )}
        {valid && check === false && hiddenEye === undefined && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none ">
            {" "}
            <CheckCircleIcon
              className="h-4 w-4 text-green-600"
              aria-hidden="true"
            />{" "}
          </div>
        )}
        {check && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none ">
            <img
              style={{ width: "20px", height: "20px" }}
              alt="Loading"
              src={`${process.env.PUBLIC_URL}/images/registration/img/loading-icon.svg`}
            />
          </div>
        )}
      </div>
      {valid === false && check === false && errorMsg && (
        <div className="error_message">{errorMsg}</div>
      )}
      {/* <p className="text-xs absolute -bottom-4 text-red-500" style={{ fontSize: '11px' }}>{errorMsg}</p> */}
    </div>
  );
};

export default InputGroup;
