import { Navigate, useLocation } from "react-router-dom";
import { useApp } from "../helpers/AppProvider";
import { useEffect } from "react";
import Storage from "./Storage";
import { useUser } from "./UserProvider";

const AuthGuard = ({ children }) => {
  let location = useLocation();
  const user = Storage.load("user");
  const { appData, actions } = useApp();
  const { userActions } = useUser();

  async function getStatus() {
    const status = await actions.getRegistrationStatus();
    actions.setAppData({
      regStatus: status?.safleIdRegistrationStatus,
    });
  }

  useEffect(() => {
    const checkStatusInterval = setInterval(async () => {
      if (appData.regStatus !== "complete") {
        await getStatus();
        clearInterval(checkStatusInterval);
      }
      if (appData.regStatus === "pending" || !appData.regStatus) {
      } else {
        clearInterval(checkStatusInterval); // Stop the interval when status is complete
      }
    }, 10000);

    return () => {
      clearInterval(checkStatusInterval); // Clear the interval when the component unmounts
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.regStatus]);

  useEffect(() => {
    async function startUpMethods() {
      if (user.token) {
        await actions.loadChains();
        await userActions.loadVault();
        await actions.getAccounts();
        await actions.loadSupportedCurrencies();
        await actions.loadActiveCurrency(user.token);
        await getStatus();
      }
    }
    startUpMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.token]);

  if (!user.token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default AuthGuard;
