import React from 'react';
import MainModal from '../generic/MainModal';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';

const ModalActionSuccess = (attrs) => {
    // { onClose, title, desc, dataClass ,callbackOpenPopup, modalNext_label } 
    const props = attrs;
    return (
        <MainModal
        onClose={props.onClose}
        extraClasses={['modal_w440', 'modal_dflex', 'modal_action', 'modal_action_success']} dataClass={props.dataClass}>

            <div className="modal_success_content">
                <ModalHeadIcon img="images/wallets/icon-done.svg" />
                <div className="modal_title">{ props.title }</div>
                <div className="modal_desc">{ props.desc }</div>
            </div>
            <div className="modal_footer">
                <PrimaryBtn
                    classes={ 'btn-default btn-blue' }
                    label='OK'
                    onClick={props.onClose}
                    // onClick={() => props.callbackOpenPopup(props.modalNext_label)}
                    
                />
            </div>

        </MainModal>
    );
};

export default ModalActionSuccess;
