// import { useUser } from "./../../../helpers/UserProvider";
import { useApp } from "./../../../helpers/AppProvider";
import { useEffect } from "react";
import ROUTES from "../../../helpers/routes";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLogoutConfirm from "../../dialogs/ModalLogoutConfirm";
import { useState } from "react";
import UserProfileBlock from "./components/UserProfileBlock";
import Logo from "../Header/Logo/Logo";
import { XIcon } from "@heroicons/react/solid";
import ModalChangeCurrency from "../../dialogs/header/ModalChangeCurrency";

const Sidebar = ({ sidebarActive, setSidebarActive }) => {
  const { appData, actions } = useApp();
  const [logoutModalActive, setLogoutModalActive] = useState(false);
  const [openedPopup, setOpenedPopup] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const navigateTo = (e, path) => {
    e.preventDefault();
    actions.setLoading(false);
    navigate(path, { replace: true });
  };
  const activePage = (path) => {
    if (!sidebarActive) {
      document.body.style.overflowY = "scroll";
    }
    return location.pathname.indexOf(path) !== -1 ? "active" : "";
  };

  const closeSidebar = (e) => {
    setSidebarActive(false);
    document.body.style.overflowY = "scroll";
  };

  const onClose = () => {
    setLogoutModalActive(false);
  };

  // pass this on all dialogs, in order to close from X btn
  const onClosePopup = () => {
    setOpenedPopup(false);
  };
  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };
  const assetActiveClass = () => {
    const asset_listing = activePage(ROUTES.ASSETS);
    const nft_listing = activePage(ROUTES.NFTS);
    const recent_listing = activePage(ROUTES.ACTIVITY);

    return asset_listing === "active"
      ? asset_listing
      : nft_listing === "active"
      ? nft_listing
      : recent_listing === "active"
      ? recent_listing
      : "";
  };
  const onClickNotification = (e) => {
    closeSidebar(e);
    e.preventDefault();
    //click on the badge use the function above
    actions.setAppData({
      showSidebarNotification: !appData.showSidebarNotification,
    });
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }
  , [location.pathname]);

  return (
    <div
      className={
        sidebarActive
          ? "main__content__aside_left aside_menu active "
          : "main__content__aside_left aside_menu "
      }
    >
      <div className="main__content__aside_navigation">
        <div className="sidebar_header__mobile">
          <Logo />
          <XIcon onClick={(e) => closeSidebar(e)} />
        </div>
        <UserProfileBlock />
        <div className="main__menu main__menu-scoll overflow-y-scroll">
          <div className="main__menu main__navigation">
            <div
              className={"main__menu-item " + activePage(ROUTES.DASHBOARD)}
              onClick={(e) => navigateTo(e, ROUTES.DASHBOARD)}
            >
              <span className="main__menu-item-icon icon-nav_dashboard fs_25" />
              <span className="main__menu-item-name">Dashboard</span>
            </div>
            <div
              className={"main__menu-item " + assetActiveClass()}
              onClick={(e) => navigateTo(e, ROUTES.ASSETS)}
            >
              <span className="main__menu-item-icon icon-nav_assets fs_25" />
              <span className="main__menu-item-name">Assets</span>
            </div>
            <div
              className={"main__menu-item " + activePage(ROUTES.WALLETS)}
              onClick={(e) => navigateTo(e, ROUTES.WALLETS)}
            >
              <span className="main__menu-item-icon icon-nav_wallet fs_25" />
              <span className="main__menu-item-name">Wallets</span>
            </div>
            <div
              className={"main__menu-item " + activePage(ROUTES.SWAP)}
              onClick={(e) => navigateTo(e, ROUTES.SWAP)}
            >
              <span className="main__menu-item-icon icon-nav_swap fs_25" />
              <span className="main__menu-item-name">Swap</span>
            </div>
            {process.env.REACT_APP_HIDE_LIFI === "false" && (
              <div
                className={"main__menu-item " + activePage(ROUTES.LIFI)}
                onClick={(e) => navigateTo(e, ROUTES.LIFI)}
              >
                <span className="main__menu-item-icon" />
                <img src="../images/navbar_icon_LiFI.svg" alt="LiFi" />
                <span className="main__menu-item-name">LiFi</span>
              </div>
            )}
            <div
              className={"main__menu-item " + activePage(ROUTES.DAPP)}
              onClick={(e) => navigateTo(e, ROUTES.DAPP)}
            >
              <span className="main__menu-item-icon icon-nav_daps fs_25" />
              <span className="main__menu-item-name">Dapp Browser</span>
            </div>
            <div
              className={"main__menu-item " + activePage(ROUTES.STAKING)}
              onClick={(e) => navigateTo(e, ROUTES.STAKING)}
            >
              <span className="main__menu-item-icon icon-nav_stacking fs_25" />
              <span className="main__menu-item-name">Staking</span>
            </div>
            <div
              className={"main__menu-item " + activePage(ROUTES.MANAGE_VAULT)}
              onClick={(e) => navigateTo(e, ROUTES.MANAGE_VAULT)}
            >
              <span className="main__menu-item-icon icon-settings-manage-vault fs_25" />
              <span className="main__menu-item-name">Manage Vault</span>
            </div>
          </div>
          <div className="main__menu main__navigation-divider" />
          <div className="main__menu main__navigation ">
            <div className="main__menu-item main__menu-item-mobile">
              <div className="notification_mobile_icon">
                <span className="icon-bell-regular"></span>
              </div>

              <div
                className="notification_mobile"
                onClick={onClickNotification}
              >
                <span className="main__menu-item-name">Notification</span>
                <div className="notification_active"></div>
              </div>
            </div>

            <div
              onClick={() => openPopup("modal_change_currency")}
              className="main__menu-item main__menu-item-mobile"
            >
              <div
                className="currency_mobile_icon"
                style={{
                  backgroundImage: `url(${
                    appData.activeCurrency &&
                    appData.activeCurrency.hasOwnProperty("Country Flag")
                      ? appData.activeCurrency["Country Flag"]
                      : "images/flags/dollor.png"
                  })`,
                }}
              ></div>
              <div className="currency_mobile">
                <span className="main__menu-item-name">Currency</span>
                <div className="currency_prefix">
                  {appData.activeCurrency &&
                    appData.activeCurrency["Currency Code"]}
                </div>
              </div>
            </div>

            <div
              className={"main__menu-item " + activePage(ROUTES.SETTINGS)}
              onClick={(e) => navigateTo(e, ROUTES.SETTINGS)}
            >
              <span className="main__menu-item-icon icon-nav_settings fs_25" />
              <span className="main__menu-item-name">Settings</span>
            </div>

            <div
              className="main__menu-item main__menu-logout"
              onClick={() => setLogoutModalActive(true)}
            >
              <span className="main__menu-item-icon fs_20 icon-nav_logout" />
              <span className="main__menu-item-name">Logout</span>
            </div>
          </div>
        </div>
      </div>
      {logoutModalActive && <ModalLogoutConfirm onClose={onClose} />}
      {openedPopup === "modal_change_currency" && (
        <ModalChangeCurrency onClose={onClosePopup} />
      )}
    </div>
  );
};
export default Sidebar;
