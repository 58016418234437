import React, { useState, useEffect } from "react";

import Button from "../../components/registration/Button/Button";
import OTPInputs from "../../components/registration/OTPInputs/OTPInputs";
import Preloader from "../../components/common/Preloader/Preloader";
import Footer from "../../components/registration/Footer/Footer";
import Modal from "./../../components/registration/Modal/Modal";
import InlineToast from "./../../components/registration/InlineToast/InlineToast";
import CssFix from "../../helpers/cssFix";
import "../../styles/registration/toast.scss";
import ROUTES from "./../../helpers/routes";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../helpers/AppProvider";

import { useRecaptcha, useUser } from "../../helpers/UserProvider";
import { useRegistration } from "../../helpers/RegistrationProvider";
import Storage from "../../helpers/Storage";

import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import initialState from "../../context/initialStateGlobal";

const initialValues = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
};

const SetupPinScreen = () => {
  const { actions } = useApp();
  const { userActions } = useUser();

  const recaptchaRef = useRecaptcha();
  let navigate = useNavigate();

  const {
    mnemonic,
    setUserVault,
    setPin,
    oldVersionUser,
    resetState,
    tempPassword,
    otpStatus,
    newPasswordToken,
  } = useRegistration();

  const [numbers, setNumbers] = useState(initialValues);
  const [APICount, setAPICount] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [confirmNumbers, setConfirmNumbers] = useState(initialValues);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [toastMessage, setToastMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPinErrorMessage, setConfirmPinErrorMessage] = useState("");
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [vaultSent, setVaultSent] = useState();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  // eslint-disable-next-line no-useless-escape
  const symbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const letters = /[a-z]/gi;

  useEffect(() => {
    setButtonDisabled(
      !Object.keys(initialValues).every(
        (i) =>
          numbers[i] !== "" &&
          confirmNumbers[i] !== "" &&
          numbers[i] === confirmNumbers[i]
      )
    );
  }, [numbers, confirmNumbers]);

  const modalOkHandler = async (val) => {
    actions.setLoading(true);
    const user_token = await recaptchaRef.current.executeAsync();
    const safleID = await userActions.getSafleIdByEmail(oldVersionUser.email);
    await userActions.login(safleID, oldVersionUser.password, user_token);
    Storage.remove("userType");
    const token = await recaptchaRef.current.executeAsync();
    await userActions.retrieveVault(safleID, oldVersionUser.password, token);
    resetState();
    Storage.save("registration", initialState);
    setModalIsOpen(false);
    navigate(ROUTES.DASHBOARD, { replace: true });
  };

  const handleSetPin = async () => {
    actions.setLoading(true);
    const pin = `${numbers.first}${numbers.second}${numbers.third}${numbers.fourth}${numbers.fifth}${numbers.sixth}`;

    if (oldVersionUser && oldVersionUser.email) {
      if (pin === "123456") {
        actions.setLoading(false);
        setErrorMessage(
          "PIN cannot have alphanumeric, special characters or 123456 for your pin."
        );
        return;
      }
      let token = await recaptchaRef.current.executeAsync();
      try {
        const resp = await userActions.migrateUser(
          oldVersionUser.email,
          oldVersionUser.password,
          parseInt(pin),
          mnemonic,
          token
        );

        if (resp?.statusCode === 201) {
          try {
            let user_token = await recaptchaRef.current.executeAsync();
            const loginResp = await userActions.login(
              resp?.safleID,
              oldVersionUser.password,
              user_token
            );
            const res = await userActions.signUpProcessFromMigration(
              resp?.vault,
              pin,
              resp?.enc,
              loginResp?.token
            );
            if (res.response.statusCode === 201) {
              setModalIsOpen(true);
            }
          } catch (e) {}
        } else {
          actions.setLoading(false);
          setErrorMessage(resp?.details[0]?.message);
        }
        actions.setLoading(false);
      } catch (err) {
        actions.setLoading(false);
        setErrorMessage(err);
      }
    } else {
      try {
        let token = await recaptchaRef.current.executeAsync();
        await userActions.retrieveEncKey(
          newPasswordToken,
          otpStatus.safleId,
          tempPassword,
          token
        );
        const retrieveEncryptionKey = Storage.load("encrypted_key");
        const { response, error } = await userActions.generateVault(
          retrieveEncryptionKey?.encryption_key,
          pin,
          mnemonic,
          newPasswordToken
        );
        if (error) {
          actions.setLoading(false);
          setErrorMessage(error);
        }
        if (!symbols.test(pin) && !letters.test(pin) && pin !== "123456") {
          setUserVault(response);
          setPin(pin);
          saveVault(response, pin);
          actions.setLoading(false);
        } else {
          actions.setLoading(false);
          setErrorMessage(
            "PIN cannot have alphanumeric, special characters or 123456 for your pin."
          );
        }
      } catch (err) {
        actions.setLoading(false);
        setErrorMessage(err);
      }
    }
  };

  const saveVault = async (user_vault, pin) => {
    actions.setLoading(true);
    const safle_id = otpStatus.safleId;
    const safle_pass = tempPassword;
    const authToken = newPasswordToken;

    if (!vaultSent) {
      /*check if error and is vault SET else don't sent vault again*/
      /* -------------------------------- */
      /* sendVault( vaultString, safle_id, password, recaptchaToken ) - sends the vault to safle cloud
      /* -------------------------------- */
      try {
        const saveVault = await userActions.sendVault(
          user_vault,
          safle_id,
          safle_pass,
          authToken
        );

        if (!saveVault || saveVault.statusCode !== 201) {
          setAPICount(APICount + 1);
          actions.setLoading(false);
        } else {
          setVaultSent(true);
          setToastIsOpen(false);
          actions.setLoading(false);
        }
        Storage.save("activeFlow", {
          currentRoute: "/wallet-details",
          flow: "signup",
        });
        navigate(ROUTES.WALLET_DETAILS, { state: { pin } });
      } catch (e) {
        actions.setLoading(false);
        setError(
          "An error has occured when storing the vault. Please contact Safle Support."
        );
      }
    }
  };

  useEffect(() => {
    if (APICount < 3 && APICount > 0) {
      setToastIsOpen(true);
    }
    if (APICount === 3) {
      setToastIsOpen(true);
    }
  }, [APICount]);

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          {/* Be sure to use this with a layout container that is full-width on mobile */}
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <Preloader />
            <div
              className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/registration/img/background-full@2x.png` +
                  ")",
                backgroundSize: "contain",
                backgroundPosition: "100% bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex">
                <div className="w-full lg:w-2/5 lg:mr-12">
                  <div className="mb-8">
                    <img
                      alt="safle logo"
                      className="mb-4 h-8 w-20 hidden sm:block"
                      src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
                    />
                  </div>
                  <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change">
                    Setup PIN
                    <p className={"mt-4 text-xs text-left setup_pin_p"}>
                      Your PIN will be used to encrypt your Safle Vault and sign
                      transactions in dApps.
                    </p>
                  </h1>
                  <div className="mb-6">
                    <OTPInputs
                      numbers={numbers}
                      setNumbers={setNumbers}
                      errorCallback={setErrorMessage}
                      eyeIcon
                      visibleEye={
                        <EyeIcon
                          className={"h-5 w-5 text-gray-400"}
                          style={{
                            position: "relative",
                            bottom: 40,
                            left: "105%",
                          }}
                        />
                      }
                    />
                    {errorMessage && (
                      <div className="flex my-3 lg:block seed_error_msg no_padding_left">
                        {errorMessage}
                      </div>
                    )}
                  </div>
                  <div className="mb-6">
                    <p className="text-xs font-semibold set_confirm_pin_p">
                      Enter PIN again
                    </p>
                    <OTPInputs
                      numbers={confirmNumbers}
                      setNumbers={setConfirmNumbers}
                      errorCallback={setConfirmPinErrorMessage}
                      autofocus={false}
                      eyeIcon={true}
                      type={showConfirmPin ? "text" : "password"}
                      hiddenEye={
                        <EyeOffIcon
                          onClick={() => setShowConfirmPin(true)}
                          className={
                            showConfirmPin
                              ? "cursor-pointer hidden"
                              : "h-5 w-5 text-black-400 cursor-pointer"
                          }
                          style={{
                            position: "relative",
                            bottom: 40,
                            left: "105%",
                          }}
                        />
                      }
                      visibleEye={
                        <EyeIcon
                          onClick={() => setShowConfirmPin(false)}
                          className={
                            !showConfirmPin
                              ? " cursor-pointer hidden"
                              : "h-5 w-5 text-black-400 cursor-pointer"
                          }
                          style={{
                            position: "relative",
                            bottom: 40,
                            left: "105%",
                          }}
                        />
                      }
                    />
                    {confirmPinErrorMessage && (
                      <div className="flex my-3 lg:block seed_error_msg no_padding_left">
                        {confirmPinErrorMessage}
                      </div>
                    )}
                  </div>
                  {toastIsOpen && (
                    <InlineToast
                      title="Error message."
                      bodyText={toastMessage}
                      isOpen={toastIsOpen}
                    />
                  )}

                  <Button
                    text="Confirm"
                    size="lg"
                    style={{ opacity: buttonDisabled ? 0.5 : 1 }}
                    disabled={buttonDisabled}
                    fullwidth
                    onClick={handleSetPin}
                  />
                </div>

                <div className=" justify-center w-0.5 lg:h-96 lg:my-20 mx-10 hidden lg:block"></div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {modalIsOpen && (
        <Modal
          title="Congrats!"
          bodyTex2t="You have successfully set up PIN for your Account"
          bodyText={"You have successfully set up PIN for your"}
          bodyText2={"Account"}
          callToActionText="Go to Dashboard"
          extraClass="setupPin"
          isOpen={modalIsOpen}
          setOpen={(val) => modalOkHandler(val)}
          icon={`images/registration/img/icon_done.svg`}
        />
      )}
    </div>
  );
};

export default SetupPinScreen;
