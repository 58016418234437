import SidebarNotificationItem from '../SidebarNotificationItem/SidebarNotificationItem';
import React, { useState, useEffect, useRef } from 'react';

// import { useApp } from './../../../helpers/AppProvider';
// import { useAppChange } from './../../../helpers/generalHelper';

const SidebarNotificationGroup = (props) => {
	// const { appData, setAppData } = useApp();

	const currentDate = 'Today'; // get the date | current date
	const items = props.items; // get the items from object map


	return (

		<div className='notification__group'>
			<h5>{currentDate}</h5>
			{items?.map((items, index) => (
				<SidebarNotificationItem items={items} key={index} />
			))}


		</div>

	);
};

export default SidebarNotificationGroup;