import React, { useEffect } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  REMOVE_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
  RESTORE_WALLET_FLOW,
} from "../../../constants/ui";

import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";

const ModalEnterPinFunctions = () => {
  const { userActions } = useUser();
  const { appData, actions } = useApp();

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);
  const [modalData, setData] = useModalData();
  const onClose = () => {
    setActiveModalNav("modal_restore_cancel_wallet", true);
  };
  const callbackOpenPopup = async (pin) => {
    try {
      actions.setLoading(true);
      const chain = modalData.chain;
      const pinNo = pin;

      const accounts = await actions.getAccounts(true);

      const accountType = accounts?.find(
        (account) => account?.address === modalData?.address
      )?.type;

      const resp = await userActions.restoreWallet(
        modalData.address,
        parseInt(pinNo),
        accountType
      );

      if (resp === true) {
        //setTimeout(async () => {
        setActiveModalNav("modal_restore_success", true);
        actions.setLoading(false);
        //}, 200);
      } else {
        actions.setLoading(false);
        setActiveModalNav("modal_restore_failed_wallet");
      }
    } catch (err) {}
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //

  return (
    <ModalEnterPin
      onClose={(modalKey) => onClose(modalKey)}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="modal_restore_cancel_wallet"
    />
  );
};

export default ModalEnterPinFunctions;
