import React, { useEffect }  from 'react';
import { useApp } from '../../../helpers/AppProvider';
import { useUser } from '../../../helpers/UserProvider';


import ModalWalletDetails from './../../dashboard/dialogs/ModalWalletDetails/ModalWalletDetails';
import ModalWalletSafleID from './../../dashboard/dialogs/ModalWalletSafleID/ModalWalletSafleID';
import { QR_CODE_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import useModalNavigation, { useModalData } from '../../../helpers/useModalNavigation';



const QRProcess = () => {
    const { userData } = useUser();
    const { appData: { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {}, wallets = []}, setAppData } = useApp() || {};

    const [ setActiveModalNav, goNext , goBackward ] = useModalNavigation(QR_CODE_FLOW);
   
    const onClose = () => {
        setActiveModalNav(DEFAULT_MODAL_STATUS);
    };
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    return (
        <>
            {
                // modal order:
                // ModalWalletSafleID
            }
            
            {activeModalLabel === 'modal_qr_code2' &&
                <ModalWalletSafleID 
                onClose={onClose}
                // callbackOpenPopup={() => goNext() }
                />
            } 
             {activeModalLabel === 'modal_qr_code' &&
                <ModalWalletDetails 
                useModalData={true}
                onClose={onClose}
                // callbackOpenPopup={() => goNext() }
                />
            }
            

            


        </>
    );
}

export default QRProcess;