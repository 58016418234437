/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import AssetBadgeHistory from "../AssetBadgeHistory/AssetBadgeHistory";

import AssetBadge from "../../common/AssetBadge/AssetBadge";
import AssetsActionsHistory from "../AssetsActionsHistory/AssetsActionsHistory";
import FavoriteToggle from "../FavoriteToggle/FavoriteToggle";
// import GraphPriceThumbnail from "../GraphPriceThumbnail/GraphPriceThumbnail";
import PercentWorthGained from "../PercentWorthGained/PercentWorthGained";
import TokenIcon from "../../common/TokenIcon/TokenIcon";

import { formatMoney } from "./../../../helpers/walletHelpers";
import { useApp } from "./../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  BUY_TRANSAK_FLOW,
  RECEIVED_ASSET_FLOW,
  SEND_ASSET_FLOW,
} from "../../../constants/ui";

// import { getCoinRate } from "../../../helpers/walletHelpers";
// import { formatXMinDecimals } from "../../../helpers/generalHelper";
import ROUTES from "../../../helpers/routes";
// import { SafleID } from "@getsafle/safle-identity-wallet";
// import contractMetadata from "../../../helpers/contractMetadata";
// const safleTokenController = require("@getsafle/custom-token-controller");
// const logoPrefix =
//   "https://raw.githubusercontent.com/MetaMask/contract-metadata/master/images/";
// const MARKET_SERVICE_URL = process.env.REACT_APP_MARKET_SERVICE_URL;

const AssetsHistoryItem = ({
  item,
  addedTokens,
  histState = "up",
  favState = false,
  onRemoveFavToken = null,
  onAddFavToken = null,
  setFilteredData,
  assetTypeFilterKey,
  sortByKey,
  setToastIsOpen,
  setToastMessage,
}) => {
  const search = useLocation().search;
  const navigate = useNavigate();
  // added query param for testing purposes (display additional values when ?debug=true)
  const debug = new URLSearchParams(search).get("debug");

  const { userData } = useUser();
  const {
    appData,
    // eslint-disable-next-line no-unused-vars
    appData: { loading },
    actions,
  } = useApp();

  const [isWatchActive, setWatchActive] = useState(false);
  // const [assetWorth, setAssetWorth] = useState(0);
  // const [fiatAssetWorth, setFiatAssetWorth] = useState(0);

  // const [assetAmount, setAssetAmount] = useState(0);
  const icon = item.logo || item.info?.logo;

  const [isMobile, setIsMobile] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();
  const [setActiveBuyTransakModalNav] = useModalNavigation(BUY_TRANSAK_FLOW);
  const [setActiveModalNav] = useModalNavigation(SEND_ASSET_FLOW);
  const [setReceiveModalNav] = useModalNavigation(RECEIVED_ASSET_FLOW);
  const [isCurrencySupportsChain, setIsCurrencySupportsChain] = useState(true);
  const setActiveAsset = () => {
    const commonChainContractAddress = appData?.chains
      ?.map((chain) => {
        return item?.contract_address?.filter(
          (chain_address) =>
            chain_address?.platform?.coin?.symbol === chain?.details?.symbol
        );
      })
      ?.flat();

    let chainContractAddressArray = [];
    // eslint-disable-next-line array-callback-return
    commonChainContractAddress?.map((chainContractItem) => {
      chainContractAddressArray?.push({
        chain:
          chainContractItem?.platform?.coin?.name === "BNB"
            ? "bsc"
            : chainContractItem?.platform?.coin?.name === "Ethereum"
            ? "eth"
            : chainContractItem?.platform?.coin?.name?.toLowerCase(),
        token_address: chainContractItem?.contract_address,
      });
    });
    actions.setAppData({
      activeAsset: item.symbol,
      activeAssetChain: chainContractAddressArray[0]?.chain,
    });
    setModalData({
      ...item,
      symbol: item?.symbol,
      chain: chainContractAddressArray[0]?.chain,
      chainContractAddress: chainContractAddressArray,
      contractAddress: chainContractAddressArray[0]?.token_address,
    });
  };
  const openBuyTransakModal = (e) => {
    e.stopPropagation();
    setActiveBuyTransakModalNav("modal_buy_transak");
    setActiveAsset();
  };
  const openSendAssetModal = (e) => {
    e.stopPropagation();
    setActiveModalNav("modal_send_asset");
    setActiveAsset();
  };
  const openReceiveAssetModal = (e) => {
    e.stopPropagation();
    setReceiveModalNav("modal_receive_asset");
    setActiveAsset();
  };
  const isSupportedCurrency = useMemo(() => {
    const commonChainContractAddress = appData?.chains
      ?.map((chain) => {
        return item?.contract_address?.filter(
          (chain_address) =>
            chain_address?.platform?.coin?.symbol === chain?.details?.symbol
        );
      })
      ?.flat();

    let chainContractAddressArray = [];
    // eslint-disable-next-line array-callback-return
    commonChainContractAddress?.map((chainContractItem) => {
      chainContractAddressArray?.push({
        chain:
          chainContractItem?.platform?.coin?.name === "BNB"
            ? "bsc"
            : chainContractItem?.platform?.coin?.name === "Ethereum"
            ? "eth"
            : chainContractItem?.platform?.coin?.name?.toLowerCase(),
        token_address: chainContractItem?.contract_address,
      });
    });
    if (chainContractAddressArray?.length > 0 || item?.symbol === "BTC") {
      setIsCurrencySupportsChain(true);
    } else {
      setIsCurrencySupportsChain(false);
    }
    return appData.transakSupportedCurrencies?.find(
      (el) => el.symbol === item.symbol && el.symbol !== "BTC"
    );
  }, [appData.transakSupportedCurrencies, item.symbol]);

  useEffect(() => {
    const resizeFn = () => {
      if (window.innerWidth < 1241) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", resizeFn);
    resizeFn();

    return () => {
      window.removeEventListener("resize", resizeFn);
    };
  }, []);

  // useEffect(async () => {
  //   setFiatAssetWorth(0);
  //   setAssetAmount(0);
  //   const res = await actions.getRate(item.symbol);
  //   const nowFiat =
  //     actions.getActiveCurrencySymbol() +
  //     "" +
  //     formatMoney(actions.activeCurrency(res * assetWorth), 2);
  //   setFiatAssetWorth(nowFiat);
  //   if (item.price > 0) {
  //     // const change = rate.data.data[item.symbol].quote['USD'].percent_change_24h;
  //     setAssetAmount(
  //       actions.getActiveCurrencySymbol() +
  //         "" +
  //         formatMoney(
  //           (item.dailyChange / 100) * actions.activeCurrency(item.price),
  //           2
  //         )
  //     );
  //   }
  // }, [assetWorth, item.dailyChange, item.price, appData.activeCurrency?.rate]);

  // Call AssetWorth only after loading is done.
  // useEffect(() => {
  //   if (
  //     !loading &&
  //     appData.hasOwnProperty("wallets") &&
  //     appData.hasOwnProperty("chains") &&
  //     appData?.wallets?.length > 0
  //   ) {
  //     const assetW = actions._isNativeToken(item)
  //       ? actions.getNativeWorth(item.symbol)
  //       : actions.getAssetWorth(item.symbol);
  //     setAssetWorth(formatXMinDecimals(assetW, 4, 7));
  //   }
  // }, [loading, assetTypeFilterKey, sortByKey, appData.activeCurrency?.rate]);

  useEffect(() => {
    setWatchActive(favState);
  }, []);

  const handleWatchToggle = async (e) => {
    e.stopPropagation();

    if (isWatchActive) {
      const res = await actions.removeFavouriteToken(item, userData.user.token);
      setWatchActive(!isWatchActive);
      if (res.statusCode === 200) {
        setToastMessage(res.message);
        setToastIsOpen(true);
      } else if (res.details[0].message.indexOf("already deleted") !== -1) {
        setToastMessage(res.details[0].message);
      }
      onRemoveFavToken && onRemoveFavToken();
    } else {
      setWatchActive(!isWatchActive);

      // if (!item.contractAddress) {
      //   const info = await fetch(
      //     `${MARKET_SERVICE_URL}info?coin=${item.symbol}`
      //   ).then((e) => e.json());
      //   if (info?.data?.data) {
      //     item.contractAddress = info?.data?.data[
      //       item?.symbol
      //     ]?.contract_address?.filter(
      //       (e) =>
      //         e.platform?.coin?.slug === item?.info?.platform?.slug ||
      //         e.platform?.coin?.slug === item?.slug
      //     )[0]?.contract_address;
      //   } else {
      //     item.contractAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
      //   }
      // }

      // const envName =
      //   process.env.REACT_APP_ENV_MODE === "development" ||
      //   process.env.REACT_APP_ENV_MODE === "test"
      //     ? "testnet"
      //     : "mainnet"; // env value can be 'mainnet' or 'testnet'
      // const polygonRpcUrl = process.env.REACT_APP_WEB3_PROVIDER_URL;
      // const safleId = new SafleID(envName, polygonRpcUrl);
      // const userAddress = await safleId.getAddress(userData.user.safleID);
      // const activeChain = appData.chains[appData.activeChain || 0];

      // const tokenController = new safleTokenController.CustomTokenController({
      //   userAddress: userAddress,
      //   rpcURL: activeChain.uri,
      //   chain: activeChain.chain_name,
      // });

      // const tokenDetails = await tokenController.getTokenDetails(
      //   item.contractAddress
      // );
      // item.decimals = tokenDetails.decimal;

      // if (!addedTokens.find((i) => i.symbol === item.symbol)) {
      //   const contractAddress = Object.keys(contractMetadata).find(
      //     (key) => contractMetadata[key]?.symbol === item.symbol
      //   );
      //   const data = {
      //     chain: "ethereum",
      //     decimals: item.decimals,
      //     logo: logoPrefix + item.logo,
      //     name: item.name || item.info.name,
      //     symbol: item.symbol,
      //     contractAddress: contractAddress,
      //   };
      //   await actions.addCustomToken(data, userData.user.token);
      // }

      const res = await actions.addFavouriteToken(item, userData.user.token);
      if (res.statusCode === 400) {
        const message = res.details[0].message;
        setToastMessage(message);
        setToastIsOpen(true);
        return;
      } else {
        setToastMessage(res.message);
        setToastIsOpen(true);
      }

      onAddFavToken && onAddFavToken();
    }
  };

  const goTo = (screen) => {
    if (item?.symbol === "BTC") {
      navigate(screen, {
        state: {
          symbol: item?.symbol,
          chain: "bitcoin",
          chainContractAddress: [],
          // contractAddress: "",
        },
      });
    }
    const commonChainContractAddress = appData?.chains
      ?.map((chain) => {
        return item?.contract_address?.filter(
          (chain_address) =>
            chain_address?.platform?.coin?.symbol === chain?.details?.symbol
        );
      })
      ?.flat();

    let chainContractAddressArray = [];
    // eslint-disable-next-line array-callback-return
    commonChainContractAddress?.map((chainContractItem) => {
      chainContractAddressArray?.push({
        chain:
          chainContractItem?.platform?.coin?.name === "BNB"
            ? "bsc"
            : chainContractItem?.platform?.coin?.name === "Ethereum"
            ? "eth"
            : chainContractItem?.platform?.coin?.name?.toLowerCase(),
        token_address: chainContractItem?.contract_address,
      });
    });

    if (chainContractAddressArray?.length > 0) {
      navigate(screen, {
        state: {
          symbol: item?.symbol,
          chain: chainContractAddressArray[0]?.chain,
          chainContractAddress: chainContractAddressArray,
          contractAddress: chainContractAddressArray[0]?.token_address,
        },
      });
    }
  };
  return (
    <div
      className="assets__overview_content-filtered_items_row cursor-pointer"
      onClick={() => goTo(ROUTES.ASSET_DETAIL)}
    >
      <div className="sticky_mobile">
        <div className="aoc_table_col" data-cls="asset_name">
          <FavoriteToggle
            star={isWatchActive}
            handleWatchToggle={handleWatchToggle}
          />
          <div className="asset__coin-image_wrp">
            <TokenIcon logo={icon ? icon : actions.getNativeToken(1)} />
            <div className="asset__coin-evaluated_at-row">
              <div className="asset__coin-asset-name">{item.name}</div>
              <AssetBadge coinPrefix={item.symbol} />
            </div>
          </div>
        </div>
      </div>
      <div className="scrollable_mobile">
        <div className="aoc_table_col" data-cls="asset_favorite">
          <FavoriteToggle
            star={isWatchActive}
            handleWatchToggle={handleWatchToggle}
          />
        </div>
        <div className="aoc_table_col" data-cls="asset_name">
          <div className="asset__coin-image_wrp">
            <TokenIcon logo={icon ? icon : actions.getNativeToken(1)} />
            <div className="asset__coin-evaluated_at-row">
              <div className="asset__coin-asset-name" title={item.name}>
                {item.info?.name || item.name}
              </div>
              <AssetBadge coinPrefix={item?.info?.symbol || item.symbol} />
            </div>
          </div>
        </div>
        <div className="aoc_table_col" data-cls="asset_price">
          <div className="asset__coin-asset-price">
            {actions.getActiveCurrencySymbol() +
              "" +
              formatMoney(
                actions.activeCurrency(item.price),
                item.price > 1 ? 2 : 5
              )}

            {debug && (
              <>
                <br />
                price: ${item?.price}
              </>
            )}
            {debug && (
              <>
                <br />
                marketCap: ${item?.marketCap}
              </>
            )}
            {debug && (
              <>
                <br />
                dailyChange: ${item?.dailyChange}
              </>
            )}
            {debug && (
              <>
                <br />
                circulatingSupply: ${item?.circulatingSupply}
              </>
            )}
            {debug && (
              <>
                <br />
                volume24h: ${item?.volume24h}
              </>
            )}
          </div>
        </div>
        {/* <div className="aoc_table_col" data-cls="asset_activity">
          <GraphPriceThumbnail symbol={item.symbol} />
        </div>
        <div className="aoc_table_col" data-cls="asset_worth">
          <div className="asset__coin-worth-asset">
            <span className="asset__coin-worth-prefix">
              {assetWorth} {item.symbol}
            </span>
            <span className="asset__coin-worth-coin-price">
              {fiatAssetWorth}
            </span>
          </div>
        </div> */}
        <div className="aoc_table_col" data-cls="asset_changes">
          <div className="asset_changes_inner">
            <PercentWorthGained
              hist={
                sortByKey ===
                  ("" ||
                    "name" ||
                    "price" ||
                    "market_cap" ||
                    "volume_24h" ||
                    "circulating_supply") || !sortByKey
                  ? item?.percent_change_24h > 0
                    ? true
                    : false
                  : item?.[sortByKey] > 0
                  ? true
                  : false
              } //item?.[sortByKey] > 0 ? true : false}
              percent={
                sortByKey ===
                  ("" ||
                    "name" ||
                    "price" ||
                    "market_cap" ||
                    "volume_24h" ||
                    "circulating_supply") || !sortByKey
                  ? item?.percent_change_24h?.toFixed(2)
                  : item?.[sortByKey]?.toFixed(2)
              } //item?.[sortByKey]?.toFixed(2)}
            />
            {/* <div className="asset__coin-asset-price">{assetAmount}</div> */}
          </div>
        </div>
        {!isMobile && (
          <div
            className="aoc_table_col"
            data-cls="asset_actions"
            onClick={(e) => e.stopPropagation()}
          >
            <AssetsActionsHistory
              item={item}
              openBuyTransakModal={openBuyTransakModal}
              openSendAssetModal={openSendAssetModal}
              openReceiveAssetModal={openReceiveAssetModal}
              setActiveAsset={setActiveAsset}
              isSupportedCurrency={isSupportedCurrency}
              isCurrencySupportsChain={isCurrencySupportsChain}
            />
          </div>
        )}
      </div>
      {isMobile && (
        <>
          {" "}
          <AssetsActionsHistory
            openBuyTransakModal={openBuyTransakModal}
            openSendAssetModal={openSendAssetModal}
            openReceiveAssetModal={openReceiveAssetModal}
            setActiveAsset={setActiveAsset}
            isSupportedCurrency={isSupportedCurrency}
            isCurrencySupportsChain={isCurrencySupportsChain}
          />
          <FavoriteToggle star={favState} />
        </>
      )}
    </div>
  );
};

export default AssetsHistoryItem;
