/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { delay_code } from "../../../helpers/generalHelper";
import { useUser } from "../../../helpers/UserProvider";
import { checkIfWalletLinked } from "../../../helpers/walletHelpers";
import BackToMenuMobile from "../BackToMenuMobile/BackToMenuMobile";
import LinkToSafleIdWalletItemMobile from "../LinkToSafleIdWalletItemMobile/LinkToSafleIdWalletItemMobile";
let tm = false;

const LinkToSafleIdMobile = (props) => {
  const { userData, userActions } = useUser();
  const { appData, actions } = useApp();

  const [showWallets, setShowWallets] = useState(false);
  const [walletCount, setWalletCount] = useState(0);
  const [walletCards, setWalletCards] = useState([]);

  useEffect(() => {
    // actions.setLoading(true, "Loading Vault");
    if (appData?.regStatus === "complete") {
      load_vault();
    }
  }, [appData?.regStatus]);

  //   const openEnterPinModal = () => setActiveModalNav("export_prvkey_enter_pin");
  //   const openEditLabelModal = () =>
  //     setActiveEditLabelModalNav("modal_edit_wallet_label");
  //   const openQRModal = () => setActiveQRModalNav("modal_qr_code");

  const load_vault = async () => {
    actions.setLoading(true, "Loading");
    await Promise.all([
      // actions.loadChains(),
      // actions.loadSupportedAssets(),
      // actions.loadSupportedCurrencies(),
      // actions.loadActiveCurrency(userData.user.token),
      // actions.loadUsdRate(),
      // userActions.loadVault(),
    ]);
    await delay_code(400);
    const acc = await appData?.accounts;
    const addreses = acc ? Object.values(acc) : {};
    //
    const wallet_changes = await actions.loadWalletCardData(addreses);
    actions.setAppData(wallet_changes);

    actions.setLoading(false);
  };

  useEffect(() => {
    userActions.getUserById().then((user) => {
      loadWalletList();
    });
  }, [appData, showWallets, appData.activeModal?.label]);

  useEffect(() => {
    if (appData.wallets && appData.wallets.length && !showWallets) {
      tm && clearTimeout(tm);
      tm = setTimeout(() => {
        setShowWallets(true);
        setWalletCount(appData.wallets.length);
        actions.setLoading(false);
      }, 500);
    }
    if (appData.wallets && appData.wallets.length !== walletCount) {
      setShowWallets(false);
      setWalletCount(false);
    }
  }, [appData]);

  const loadWalletList = async () => {
    if (appData.wallets && appData.wallets.length) {
      const filteredWallets = await Promise.all(
        appData.wallets
          .filter((e) => {
            if (appData.activeChain && appData.activeChain !== 0) {
              const curr = appData.chains[appData.activeChain - 1];
              return e.chain === curr.chainId;
            }

            return e;
          })
          .map(async (i) => {
            let state = await checkIfWalletLinked(
              i,
              userData?.user?.linkedWallets
            );
            i.buttonState = state ? "linked" : "link";
            i.linked = state;
            return i;
          })
      );
      setWalletCards(filteredWallets);
      //setWalletCards(filteredWallets?.filter(i => i.chain === 1));
    }
  };

  return (
    <div className="link_safleid_mobile">
      <BackToMenuMobile goBackMenu={props.setLinkSafleIdOpen} />
      <div className="settings_screen_name_mobile">Link to SafleID</div>
      <div className="wallets_container">
        {walletCards.map(function (item, i) {
          return <LinkToSafleIdWalletItemMobile item={item} />;
        })}
      </div>
    </div>
  );
};

export default LinkToSafleIdMobile;
