import React from 'react'

const Button = ({ text, size = 'md', fullwidth, ...rest }) => {
  const sizeClasses = {
    xs: 'px-2.5 py-1.5 text-xs',
    sm: 'px-3 py-2 text-sm',
    md: 'px-4 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
    xl: 'px-6 py-3 text-base',
  }

  return (
    <button
      type={rest.type || 'button'}
      className={`inline-flex ${sizeClasses[size]} ${fullwidth ? 'w-full justify-center' : ''
        } ${rest.extra_class ? rest.extra_class : ''} items-center border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`}
      {...rest}

    >
      {text}
    </button>
  )
}

export default Button
