import React, { useState } from 'react';

const MobilePortfolioSwitch = () => {

  const tabList = [
    {
      text: 'My Portfolio',
    },
    {
      text: 'Recent Activity',
    },
  ];

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <>
      <div className="dashboard__overview__tablet tabs_navigation">
        <div className="dashboard__tabs_navigation_wrp">
          <ul>
            {
              tabList.map((tab, index) => {
                const activeCls = index === currentTab ? 'active' : '';
                return (
                  <li
                    key={index}
                    onClick={() => setCurrentTab(index)}>
                    <span className={activeCls}>{tab.text}</span>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobilePortfolioSwitch;