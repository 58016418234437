import React, { useEffect } from "react";

import ModalEnterVerificationCode from "./../../dialogs/ModalEnterVerificationCode";

// import ModalEnterPin from "./../../dialogs/ModalEnterPin";
// import { useApp } from "../../../helpers/AppProvider";
// import { useUser, isValidEmail } from "../../../helpers/UserProvider";
// import Storage from "../../../helpers/Storage";

import useModalNavigation from // useModalData,
"../../../helpers/useModalNavigation";
import {
  SETTINGS_CHANGE_EMAIL,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const ModalPinConfirmFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] = useModalNavigation(
    SETTINGS_CHANGE_EMAIL
  );
  // const [modalData, setData] = useModalData();

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };

  // ON VERIFY CLICK
  const callbackOpenPopup = (next) => {
    goNext();
  };

  //on CANCEL CLICK
  const callbackCancelPopup = (popup) => {
    setActiveModalNav("modal_cancel_email_address", true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    //send request with
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  return (
    <ModalEnterVerificationCode
      onClose={onClose}
      onOkHandler={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalPinConfirmFunctions;
