/* eslint-disable no-template-curly-in-string */
import blockchainInfo from "./../helpers/blockchains";
import Web3Store from "./../helpers/web3Store";
import currenciesInfo from "./../helpers/currencies";
import {
  getCoinRate,
  getTokenBalance,
  toBaseUnit,
  formatFiat,
} from "./../helpers/walletHelpers";
import { delay_code, formatXDecimals } from "../helpers/generalHelper";
import APIS from "../helpers/apis";
import Storage from "../helpers/Storage";
import Web3 from "web3";
import calculateGasAbi from "./../helpers/calculateGasAbi";
import tokenDataAbi from "./../helpers/tokenDataAbi";
import moment from "moment";
import { ERC20ABI } from "../helpers/erc20Abi";
import { erc721ABI } from "../constants/erc721-abi.js";
import { erc1155ABI } from "../constants/erc1155-abi.js";
import initialState from "../context/initialStateGlobal";
import uniq from "lodash/uniq";

const Vault = require("@getsafle/safle-vault");
const safleTokenController = require("@getsafle/custom-token-controller");
const safleTransactionsController = require("@getsafle/transaction-controller");
const safleAssetController = require("@getsafle/asset-controller");
const safleNftController = require("@getsafle/nft-controller");
const MARKET_SERVICE_URL = process.env.REACT_APP_MARKET_SERVICE_URL;
const WALLETS_URL = process.env.REACT_APP_WALLETS_URL;
//enums
let PolygonChainId = 137;

class AppController {
  constructor(appData, setData, setNotificationFn) {
    this.web3Store = new Web3Store();

    this.appData = appData;
    this.ethPriceUSD = [];
    this.setNotificationData = (data) => {
      setNotificationFn(data);
    };
    this.setAppData = (changes, updateGlobal = true) => {
      const tmp = { ...this.appData };
      this.appData = { ...tmp, ...changes };
      if (updateGlobal) {
        setData((state) => {
          return { ...state, ...this.appData };
        });
      }
    };
    this.vault = new Vault({
      vault: Storage.load("user").vault,
      encryptionKey: Storage.load("user")?.decriptionKey
        ? Object.values(Storage.load("user")?.decriptionKey)
        : null,
    });
  }

  setLoading(flag, message = false) {
    this.setAppData({ loading: flag, loadingMessage: message });
  }

  // load chains
  async loadChains() {
    const chainRes = {
      ethereum: [
        {
          network: "ethereum-mainnet",
          rpc: "https://mainnet.infura.io/v3/${INFURA_API_KEY}",
          chainId: 1,
          explorer: "https://etherscan.io",
          nativeCurrency: "Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
        {
          network: "ropsten",
          rpc: "https://ropsten.infura.io/v3/${INFURA_API_KEY}",
          chainId: 3,
          explorer: "https://ropsten.etherscan.io",
          nativeCurrency: "Ropsten Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
        {
          network: "kovan",
          rpc: "https://kovan.infura.io/v3/${INFURA_API_KEY}",
          chainId: 42,
          explorer: "https://kovan.etherscan.io",
          nativeCurrency: "Kovan Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
        {
          network: "rinkeby",
          rpc: "https://rinkeby.infura.io/v3/${INFURA_API_KEY}",
          chainId: 4,
          explorer: "https://rinkeby.etherscan.io",
          nativeCurrency: "Rinkeby Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
        {
          network: "goerli",
          rpc: "https://goerli.infura.io/v3/${INFURA_API_KEY}",
          chainId: 420,
          explorer: "https://goerli.etherscan.io",
          nativeCurrency: "Goerli Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
        {
          network: "sepolia",
          rpc: "https://sepolia.infura.io/v3/${INFURA_API_KEY}",
          chainId: 11155111,
          explorer: "https://sepolia.etherscan.io",
          nativeCurrency: "Sepolia Ether",
          symbol: "ETH",
          imageURL:
            "https://assets.coingecko.com/coins/images/279/large/ethereum.png",
        },
      ],
      polygon: [
        {
          network: "polygon-mainnet",
          rpc: "https://polygon-rpc.com",
          chainId: 137,
          explorer: "https://polygonscan.com",
          nativeCurrency: "Matic",
          symbol: "MATIC",
          imageURL:
            "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png",
        },
        {
          network: "mumbai",
          rpc: "https://rpc-mumbai.matic.today",
          chainId: 80001,
          explorer: "https://mumbai.polygonscan.com",
          nativeCurrency: "Matic",
          symbol: "MATIC",
          imageURL:
            "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png",
        },
      ],
      velas: [
        {
          network: "velas-mainnet",
          rpc: "https://evmexplorer.velas.com/rpc",
          chainId: 106,
          explorer: "https://evmexplorer.velas.com",
          nativeCurrency: "Velas",
          symbol: "VLX",
          imageURL:
            "https://assets.coingecko.com/coins/images/9651/large/velas.png",
        },
        {
          network: "testnet",
          rpc: "https://evmexplorer.testnet.velas.com/rpc",
          chainId: 111,
          explorer: "https://evmexplorer.testnet.velas.com",
          nativeCurrency: "Velas",
          symbol: "VLX",
          imageURL:
            "https://assets.coingecko.com/coins/images/9651/large/velas.png",
        },
      ],
      bsc: [
        {
          network: "bsc-mainnet",
          rpc: "https://bsc-dataseed.binance.org",
          chainId: 56,
          explorer: "https://bscscan.com",
          nativeCurrency: "BNB",
          symbol: "BNB",
          imageURL:
            "https://assets.coingecko.com/coins/images/13804/large/Binnace.png",
        },
        {
          network: "testnet",
          rpc: "https://data-seed-prebsc-1-s1.binance.org:8545",
          chainId: 97,
          explorer: "https://testnet.bscscan.com",
          nativeCurrency: "BNB",
          symbol: "BNB",
          imageURL:
            "https://assets.coingecko.com/coins/images/13804/large/Binnace.png",
        },
      ],
      mantle: [
        {
          network: "mantle-mainnet",
          rpc: "http://rpc.mantle.xyz/",
          chainId: 5000,
          explorer: "http://explorer.mantle.xyz/",
          nativeCurrency: "MNT",
          symbol: "MNT",
          imageURL:
            "https://s2.coinmarketcap.com/static/img/coins/64x64/27075.png",
        },
        {
          network: "testnet",
          rpc: "https://rpc.testnet.mantle.xyz",
          chainId: 5001,
          explorer: "https://explorer.testnet.mantle.xyz",
          nativeCurrency: "MNT",
          symbol: "MNT",
          imageURL:
            "https://s2.coinmarketcap.com/static/img/coins/64x64/27075.png",
        },
      ],
      optimism: [
        {
          network: "optimism-mainnet",
          rpc: "https://optimism-mainnet.infura.io/v3/${INFURA_API_KEY}",
          chainId: 10,
          explorer: "https://optimistic.etherscan.io",
          nativeCurrency: "OP",
          symbol: "OP",
          imageURL:
            "https://assets.coingecko.com/coins/images/25244/large/Optimism.png",
        },
        {
          network: "goerli",
          rpc: "https://optimism-goerli.infura.io/v3/${INFURA_API_KEY}",
          chainId: 420,
          explorer: "https://goerli-optimism.etherscan.io",
          nativeCurrency: "OP",
          symbol: "OP",
          imageURL:
            "https://assets.coingecko.com/coins/images/25244/large/Optimism.png",
        },
      ],
      arbitrum: [
        {
          network: "arbitrum-mainnet",
          rpc: "https://arbitrum-mainnet.infura.io/v3/${INFURA_API_KEY}",
          chainId: 42161,
          explorer: "https://arbiscan.io",
          nativeCurrency: "ARB",
          symbol: "ARB",
          imageURL:
            "https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg",
        },
        {
          network: "goerli",
          rpc: "https://arbitrum-goerli.infura.io/v3/${INFURA_API_KEY}",
          chainId: 421613,
          explorer: "https://testnet.arbiscan.io",
          nativeCurrency: "ARB",
          symbol: "ARB",
          imageURL:
            "https://assets.coingecko.com/coins/images/16547/large/photo_2023-03-29_21.47.00.jpeg",
        },
      ],
    };

    const chains = Object.values(chainRes).reduce((acc, item) => {
      acc.push(...item);
      return acc;
    }, []);

    const chainArr = [];
    for (let i in blockchainInfo) {
      const currChain = chains.find((e) => e.chainId === parseInt(i));
      if (currChain) {
        chainArr.push({
          chainId: currChain.chainId,
          ...blockchainInfo[i],
          uri:
            currChain.chainId === 1 ||
            currChain.chainId === 137 ||
            currChain.chainId === 10 ||
            currChain.chainId === 42161
              ? blockchainInfo[i].uri + process.env.REACT_APP_INFURA_KEY
              : currChain.chainId === 5000
              ? "https://rpc.mantle.xyz/"
              : blockchainInfo[i].uri,
          details: currChain,
        });
      }
    }

    const nonEvmChains = [
      {
        network: "bitcoin",
        // rpc: "https://ropsten.infura.io/v3/${INFURA_API_KEY}",
        // explorer: "https://ropsten.etherscan.io",
        nativeCurrency: "Bitcoin",
        symbol: "BTC",
        imageURL:
          "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
      },
    ];

    this.setAppData({
      chains: chainArr,
      nonEvmChains: nonEvmChains,
      activeChain: this.appData.activeChain
        ? this.appData.activeChain
        : chainArr.indexOf(chainArr.find((e) => e.chainId === 1)),
    });

    return true;
  }

  getActiveChain() {
    return this.appData.chains[this.appData.activeChain];
  }

  getChainLogo(chainId) {
    const currChain =
      this.appData.chains &&
      this.appData.chains.find((e) => e.chainId === chainId);
    return currChain ? currChain?.details?.imageURL : false;
  }

  getNodeFor(chain_name) {
    if (this.appData.chains) {
      const chain = this.appData.chains.find((e) => e.name === chain_name);
      if (chain?.chain_name) {
        return this.web3Store.getNodeFor(chain.chain_name, chain.uri);
      }
    }
    return false;
  }

  getActiveWallet() {
    if (this.appData.activeWallet !== null) {
      return this.appData.activeWallet;
    }
    return false;
  }

  /* wallet area cards - main loading functionality */
  async loadWalletCardData(addreses) {
    let globalChanges = {};
    const wallets = await this.getWalletCards(addreses);

    globalChanges = {
      wallets: [{ ...wallets.safleCard, isSafleCard: true }, ...wallets.cards],
      safleCard: wallets.safleCard,
    };

    globalChanges = {
      wallets: globalChanges.wallets.map((item, idx) => {
        return {
          ...item,
        };
      }),
      safleCard: {
        ...globalChanges.safleCard,
      },
    };

    return globalChanges;
  }

  async refreshWalletCardData(addreses) {
    this.setAppData({
      wallets: [],
      safleCard: null,
      walletAssets: [],
      walletTransactions: [],
      walletNFTs: [],
    });

    delay_code(1000);
    const changes = await this.loadWalletCardData(addreses);

    delay_code(1000);
    this.setAppData(changes);
  }

  async getWalletCards(addreses) {
    let cards = [];
    let count = 1;
    let safleCard = null;
    for (var i in this.appData.chains) {
      for (var j in addreses) {
        if (j === 0 && this.appData.chains[i].chainId === PolygonChainId) {
          safleCard = {
            id: 0,
            address: addreses[j].address,
            chain: this.appData.chains[i].chainId,
            chain_name: this.appData.chains[i].name,
            label: "SafleID Wallet",
          };
        } else {
          cards.push({
            id: count,
            address: addreses[j].address,
            chain: this.appData.chains[i].chainId,
            chain_name: this.appData.chains[i].name,
            label: addreses[j].hasOwnProperty("label")
              ? typeof addreses[j]?.label !== "string"
                ? this.appData.chains[i].chainId === 1
                  ? addreses[j].label?.ethereum
                  : this.appData.chains[i].chainId === 137
                  ? addreses[j].label?.polygon
                  : this.appData.chains[i].chainId === 56
                  ? addreses[j].label?.bsc
                  : this.appData.chains[i].chainId === 106
                  ? addreses[j].label?.velas
                  : this.appData.chains[i].chainId === 10
                  ? addreses[j].label?.optimism
                  : this.appData.chains[i].chainId === 42161
                  ? addreses[j].label?.arbitrum
                  : this.appData.chains[i].chainId === 5000
                  ? addreses[j].label?.mantle
                  : ""
                : addreses[j].label
              : "Wallet " + count,
            isSafleCard: false,
          });
          count++;
        }
      }
    }
    return {
      cards,
      safleCard,
    };
  }

  async getWalletBalance(address, network, returnEth = true) {
    const activeChain = this.appData.chains.find((i) => i.name === network);
    const web3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));

    try {
      let bal = 0;
      bal = await web3.eth.getBalance(address); // native coin
      bal = web3.utils.fromWei(bal, "ether");
      return bal;
    } catch (error) {}
  }

  async getWalletBalanceFiat(symbol, network, balance) {
    if (!symbol) {
      return 0;
    }
    if (balance === 0) {
      return 0;
    }
    if (!this.appData.chains) {
      return "";
    }

    const rate = await this.getRate(symbol);
    const balanceFiat = balance * rate;
    return formatFiat(balanceFiat);
  }

  async getWalletAssets(wallet) {
    const chain = this.appData.chains.find((e) => e.name === wallet.chain_name);

    if (chain && (chain.chainId === 1 || chain.chainId === 137)) {
      const assetController = new safleAssetController.AssetController({
        rpcURL: chain.uri,
        chain: chain.chain_name,
      });
      const assetResp = await assetController.detectTokens({
        userAddress: wallet.address,
        tokenType: "erc20",
      });
      let tokenBalance = [...Object.values(assetResp)];
      return tokenBalance;
    }
    return [];
  }

  async getWalletAssetsFiat(walletAssets, walletId) {
    let save = false;
    if (walletAssets && walletAssets[walletId]) {
      const afAssets = [...walletAssets[walletId]];
      if (afAssets.length) {
        for (var i in afAssets) {
          save = true;
          const balanceFiat = await getCoinRate(afAssets[i].symbol, true);
          if (balanceFiat) {
            let rate = 0;
            let balance = 0;
            try {
              rate =
                balanceFiat?.data?.data[afAssets[i].symbol]?.quote?.USD?.price;
              balance = getTokenBalance(
                afAssets[i].balance,
                afAssets[i].decimal
              );
            } catch (error) {}

            afAssets[i].balanceFiat = formatFiat(balance * rate);
            if ((balance * rate).toString().indexOf("e") !== -1) {
              afAssets[i].balanceFiat = formatFiat("0.00");
            }
          }
        }
      }
      if (save) {
        return afAssets;
      }
    }
    return [];
  }

  async getWalletAssetsWorth(walletAssets, wallet, balanceFiat) {
    const walletId = wallet.id;
    const wassets = [...walletAssets[walletId]];
    let sum = 0.0;
    for (var i in wassets) {
      if (wassets[i].hasOwnProperty("balanceFiat")) {
        sum += wassets[i].balanceFiat;
      }
    }
    return sum + balanceFiat;
  }

  async getAllGainLoss() {
    if (this.appData.wallets && this.appData.wallets.length) {
      const gain_losses = this.appData.wallets.map((e) => {
        return this.getAssetGainLoss(e, true);
      });
      const calc_sums = await Promise.all(gain_losses);
      const { last, current } = calc_sums.reduce(
        (acc, item) => {
          acc.current +=
            parseFloat(item.current) === 0 ? 0 : parseFloat(item.current);
          acc.last += parseFloat(item.last) === 0 ? 0 : parseFloat(item.last);
          return acc;
        },
        { current: 0, last: 0 }
      );

      const delta = (current - last) / last;
      return isNaN(delta) ? 0 : (delta * 100).toFixed(2);
    }

    return "0";
  }

  async getAssetGainLoss(wallet, returnBalances = false) {
    const totalWorth = formatFiat(wallet.assetsWorth);

    const mainSymbol = this.appData.chains.find(
      (e) => e.name === wallet.chain_name
    )?.details?.symbol;
    const wbal = await this.getWalletBalance(wallet.address, wallet.chain_name);

    const assets = this.appData?.walletAssets.hasOwnProperty(wallet.id)
      ? [...this.appData.walletAssets[wallet.id]]
      : [];
    let asset_symbols = [];
    let asset_arr = [];
    let asset_worth_hist = 0.0;

    if (assets.length) {
      asset_symbols = assets.map((e) => e.symbol);
      asset_arr = assets.map(
        (e) => parseFloat(e?.balance) / Math.pow(10, e?.decimal)
      );
      if (mainSymbol) {
        asset_symbols.unshift(mainSymbol);
        asset_arr.unshift(wbal);
      }
      const ts = new Date().getTime() - 25 * 3600 * 1000;
      let rates = await this.getTokenRatesHistory(asset_symbols, ts);
      asset_worth_hist = asset_arr.reduce((acc, item, idx) => {
        acc =
          parseFloat(acc) +
          parseFloat(rates[asset_symbols[idx]]) * parseFloat(item);
        return acc;
      }, 0.0);
    } else {
      const ts = new Date().getTime() - 25 * 3600 * 1000;
      let rates = await this.getTokenRatesHistory([mainSymbol], ts);
      asset_symbols.unshift(mainSymbol);
      asset_worth_hist = [wbal].reduce((acc, item, idx) => {
        acc =
          parseFloat(acc) +
          parseFloat(rates[asset_symbols[idx]]) * parseFloat(item);
        return acc;
      }, 0.0);
    }
    asset_worth_hist = formatFiat(asset_worth_hist);
    const delta = (totalWorth - asset_worth_hist) / asset_worth_hist;
    if (returnBalances) {
      return { last: asset_worth_hist, current: totalWorth };
    }
    return isNaN(delta) ? 0 : (delta * 100).toFixed(2);
  }

  async getTokenRatesHistory(symbols, ts) {
    const cmcURL =
      APIS.historical_listing +
      `?symbol=${symbols.join(",")}&timestamp=${ts}&interval=hourly`;

    try {
      const resp = await fetch(cmcURL, {
        headers: {
          "X-CMC_PRO_API_KEY": process.env.REACT_APP_CMC_API_KEY,
        },
      }).then((e) => e.json());
      let respArray;
      if (symbols.length === 1) {
        return {
          [resp.data.data.symbol]: resp.data.data.quotes[0].quote.USD.price,
        };
      } else {
        respArray = resp.data.data;
        const keys = Object.keys(respArray);
        const obj = Object.values(respArray);
        return obj.reduce((acc, item, idx) => {
          const key = keys[idx];
          acc[key] = item.quotes[0].quote.USD.price;
          return acc;
        }, {});
      }
    } catch (e) {
      return false;
    }
  }

  getTokenIcon(address) {
    const token = this.appData.assets.hasOwnProperty(address)
      ? this.appData.assets[address]
      : {};
    return token?.logo || "";
  }

  async getWalletNFT(wallet, refreshNFTData = false) {
    if (
      this.appData.walletNFTs &&
      this.appData.walletNFTs.hasOwnProperty(wallet.id) &&
      !refreshNFTData
    ) {
      return this.appData.walletNFTs[wallet.id];
    } else {
      const chain = this.appData.chains.find(
        (e) => e.name === wallet.chain_name
      );

      if (chain && (chain.chainId === 1 || chain.chainId === 137)) {
        const nftController = new safleNftController.NftController();
        const resp = await nftController.detectNFTs({
          publicAddress: wallet?.address,
          chain: chain?.chain_name,
        });
        if (!resp?.response?.data) {
          return [];
        }
        const tokenBalance = Object.values(resp?.response?.data);
        const wAssets = this.appData.walletNFTs
          ? { ...this.appData.walletNFTs, [wallet.id]: tokenBalance }
          : { [wallet.id]: tokenBalance };

        this.setAppData({ walletNFTs: wAssets });
        return tokenBalance;
      }
      return [];
    }
  }

  async getWalletConnectedChains() {
    return this.appData.chains && this.appData.chains.length;
  }

  async getWalletTransactions(address, chain) {
    const transactionsController =
      new safleTransactionsController.TransactionController();
    try {
      if (!chain === "ethereum" || !chain === "polygon-mainnet") {
        return [];
      }
      const apiKey = process.env.REACT_APP_UNMARSHALL_KEY;
      const chainParams = chain === "polygon-mainnet" ? "polygon" : "ethereum";
      const transactions = await transactionsController.getTransactions({
        address: address,
        fromBlock: 0,
        network: chainParams,
        apiKey,
      });

      if (typeof transactions === "string") {
        return [];
      }
      return transactions;
    } catch (e) {
      return { response: [] };
    }
  }

  async getPriceInUSD(nativeTokenSymbol) {
    if (!this.ethPriceUSD?.hasOwnProperty(nativeTokenSymbol)) {
      let res = await fetch(
        `${MARKET_SERVICE_URL}/latest-price?coin=${nativeTokenSymbol}`
      ).then((e) => e.json());
      const rate =
        res.data?.data[nativeTokenSymbol?.toUpperCase()]?.quote?.USD?.price;
      this.ethPriceUSD[nativeTokenSymbol] = isNaN(rate) ? 0 : rate;
    }
    return this.ethPriceUSD[nativeTokenSymbol] || 0;
  }

  async getAssetLatestPrice(nativeTokenSymbol) {
    if (!MARKET_SERVICE_URL)
      return new Error("URL REQUIRED FOR getAssetLatestPrice.");

    let res = await fetch(
      `${MARKET_SERVICE_URL}/latest-price?coin=${nativeTokenSymbol}`
    ).then((e) => e.json());
    return res.data;
  }

  getNativeToken(chainId) {
    const chain =
      this.appData.chains &&
      this.appData.chains.find((e) => e.chainId === chainId);
    return chain?.details?.symbol;
  }

  getAssetWorth(symbol) {
    if (this.appData.hasOwnProperty("walletAssets")) {
      let sum = 0.0;
      for (let i in this.appData.walletAssets) {
        let foundTokenBalance = [
          ...this.appData.walletAssets[i].filter((e) => e.symbol === symbol),
        ];
        if (foundTokenBalance.length) {
          foundTokenBalance = foundTokenBalance.reduce((acc, el) => {
            acc += parseFloat(
              getTokenBalance(parseFloat(el?.balance), el?.decimal)
            );
            return acc;
          }, 0.0);
          sum += foundTokenBalance;
        }
      }
      return sum;
    }
    return 0;
  }

  getNativeWorth(symbol) {
    if (this.appData.hasOwnProperty("chains")) {
      const chain = this.appData.chains.find(
        (e) => e.details.symbol === symbol
      );
      let sum = 0.0;

      let foundWallets = this.appData.wallets.filter(
        (e) => e.chain_name === chain.name
      );
      foundWallets = uniq(foundWallets, "id");

      for (var i in foundWallets) {
        sum += parseFloat(foundWallets[i].balance);
      }
      return formatXDecimals(sum, 6);
    }

    return 0;
  }

  getScanURL(chainId) {
    const chain =
      this.appData.chains &&
      this.appData.chains.find((e) => e.chainId === chainId);
    return chain?.details?.explorer;
  }

  async getAccounts(showDeleted = false) {
    if (Storage.load("user")?.vault) {
      let decKey = Storage.load("user")?.decriptionKey
        ? Object.values(Storage.load("user")?.decriptionKey)
        : null;
      let accounts = await this.vault.getVaultDetails(decKey);

      let allAcctsArr = [];

      for (const type in accounts.response) {
        const wallets = accounts.response[type].generatedWallets;
        for (const index in wallets) {
          const wallet = wallets[index];
          allAcctsArr.push({
            address: wallet.address,
            isDeleted: wallet.isDeleted,
            isImported: wallet.isImported,
            label: wallet.label,
            type: type,
          });
        }
      }
      for (const type in accounts.response) {
        const wallets = accounts.response[type].importedWallets;
        for (const index in wallets) {
          const wallet = wallets[index];
          allAcctsArr.push({
            address: wallet.address,
            isDeleted: wallet.isDeleted,
            isImported: wallet.isImported,
            label: wallet.label,
            type: type,
          });
        }
      }
      if (!showDeleted) {
        allAcctsArr = allAcctsArr?.filter((account) => !account?.isDeleted);
      }

      this.setAppData({ accounts: allAcctsArr });

      return allAcctsArr;
    }
    return [];
  }

  async getDashboardPortfolioData() {
    const addresses = this.appData?.accounts;

    let result = await Promise.all(
      addresses.map(async (item) => {
        let res = await fetch(
          `${process.env.REACT_APP_PORTFOLIO_SERVICE_URL}/address/${item.address}?transactions=true&nft=true` //
        ).then((e) => e.json());

        if (item?.type === "bitcoin") {
          res.data.transactions = res.data.transactions.map((transaction) => ({
            type: "bitcoin",
            ...transaction,
          }));
        }

        // if (item.address === "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r") {
        //   res.data = {
        //     activeChains: ["bitcoin"],
        //     transactions: [
        //       {
        //         type: "bitcoin",
        //         hash: "f4abd229165a778ebeb3bd5f50f8ddffafc363cf65f591078d15aea0b6be0be2",
        //         fee: 30956,
        //         value: -30956,
        //         timeStamp: 1705659486,
        //         transactionIndex: 7953282309035458,
        //         from: [
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //         ],
        //         to: [
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //         ],
        //       },
        //       {
        //         type: "bitcoin",
        //         hash: "bfd3651a4650fe20a5f2359e6efb82dd71129cc60ee7cc6c3e687f91e340341f",
        //         fee: 30956,
        //         value: -30956,
        //         timeStamp: 1705651925,
        //         transactionIndex: 1097897197318125,
        //         from: [
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //         ],
        //         to: [
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //         ],
        //       },
        //       {
        //         type: "bitcoin",
        //         hash: "88c5d3971fc7f9a35e1745e3fd5e35a26bc5ba46e9100f38b8c2640f3a63d7ef",
        //         fee: 21588,
        //         value: -21588,
        //         timeStamp: 1705387003,
        //         transactionIndex: 8438667576208536,
        //         from: ["bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r"],
        //         to: [
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //         ],
        //       },
        //       {
        //         type: "bitcoin",
        //         hash: "4d78b4d073052a024b08f19ece5cbd3a2ee91e63b3b5639045403327a063b5e0",
        //         fee: 212400,
        //         value: 100000,
        //         timeStamp: 1705315564,
        //         transactionIndex: 7906229284562536,
        //         from: ["bc1qm34lsc65zpw79lxes69zkqmk6ee3ewf0j77s3h"],
        //         to: [
        //           "bc1qk6rzzx6jyzt7qsn4y5jejck8a0y84sg4dv6gqn",
        //           "bc1qm34lsc65zpw79lxes69zkqmk6ee3ewf0j77s3h",
        //           "14fPEdBZ6veffdrHnChvvYuwrQVDCEDE8z",
        //           "bc1qaerk84txnej74ucasunun3mdnujxzyxl67c9sw",
        //           "bc1qa7ty6yunkmy854y6dnuzsrnyf39xne5p7j5jk7",
        //           "bc1qr4usjv32j4sdatsmjvhrq52nqv6pmc0k4mtvdq",
        //           "bc1qazm8enugd9s9len5j7nhhc52ugynmstudr3yyn8mg0dy3j3mhpvsg8zyc6",
        //           "bc1q6mtsn4n5lax94sa0v83vw0gvsd6fmfjp2997cc",
        //           "bc1p9uhyjeda60nejzndyzd7yg4g58ctyy40yjmqkss6d6egp039453q4al5z7",
        //           "3LJJvufQdnfG4Nv36hTtPTPam7pd9aiCea",
        //           "bc1p45xz44d4fxywt6g3p5cs93c2a8588s0jxen5gcwm7r97xyht5xrqnaxd0x",
        //           "3DJKZx9vx6wPF3SrD9kGUojbotx9LufR37",
        //           "35H6Cq4xttAhkYxSjw7Q5k4f37Uaz4CvBq",
        //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
        //           "bc1plx7hrlgn8gmz7urgajtzk7uevqngswm8umjfac4kf9r7gkttzezslt2phs",
        //           "1BJmWNmPKc5iaznJqRHHX2dpQMjY2BtkbT",
        //           "1Pp1Tzp43p2AdJSDZfiJiQQ3P2EdABF98A",
        //           "bc1qjl2g7p66wxha4jvaaczu9sacmakzz08x0nhj5t",
        //           "33sBFv5gqLFY4kmSZYeUYDqgoBHbhMCuCy",
        //           "bc1q2gcqgg0frghnwxvdavznrzrav38wl4raps8mqv",
        //           "bc1qj8fjrd8gfa4cmss924lln6qd3n6e4ge4ce5vd9",
        //           "3DGApTLmSj7YzL5rp4heNWGRhmH7Z8DNtx",
        //           "bc1qktledw73hcnllfe44a0dw4c48azs7nwenzjwx0",
        //           "bc1qq6s0fh033m50pe92xh57h23ljv8typfajl5rgz",
        //         ],
        //       },
        //     ],
        //     bitcoin: {
        //       network: "bitcoin",
        //       nativeCurrency: "BTC",
        //       symbol: "BTC",
        //       imageURL:
        //         "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1696501400",
        //       balance: "0.00016500",
        //       balanceInSats: 16500,
        //     },
        //   };
        // }

        const addressData = [];
        addressData[item.address] = res.data;
        return addressData;
      })
    );
    result = result.reduce((acc, el) => {
      acc = { ...acc, ...el };
      return acc;
    }, {});

    this.setAppData({ portfolioData: result });

    return result;
  }

  async getNFTsdata() {
    const addresses = this.appData?.accounts
      ?.filter((account) => account.type === "evm")
      ?.map((e) => e.address);

    let result = await Promise.all(
      addresses.map(async (item) => {
        let res = await fetch(
          `${process.env.REACT_APP_NFT_SERVICE_URL}/address/${item}/nft`
        ).then((e) => e.json());
        const addressData = [];
        addressData[item] = res.data;
        return addressData;
      })
    );
    result = result.reduce((acc, el) => {
      acc = { ...acc, ...el };
      return acc;
    }, {});

    this.setAppData({ nftData: result });

    return result;
  }

  async getTokenData() {
    const addresses = this.appData?.accounts?.map((e) => e.address);

    let result = await Promise.all(
      addresses.map(async (item) => {
        let res = await fetch(
          `https://dev-asset.getsafle.com/address/${item}/tokens`
        ).then((e) => e.json());
        const addressData = [];
        addressData[item] = res.data;
        return addressData;
      })
    );
    result = result.reduce((acc, el) => {
      acc = { ...acc, ...el };
      return acc;
    }, {});

    this.setAppData({ tokenData: result });

    return result;
  }

  async getPriceDataForCoins() {
    const AssetsInWallets = this.appData?.accounts?.map((account) => {
      const AssetsInWallet = this.appData?.portfolioData
        ? Object.keys(this.appData?.portfolioData)?.length > 0
          ? this.appData?.portfolioData[account?.address]?.activeChains
              ?.filter((chain) => chain !== "avalanche")
              ?.map((chain) => {
                let AssetsInChainInWallet;
                if (account.type === "evm" && chain !== "bitcoin") {
                  AssetsInChainInWallet = [
                    ...this.appData?.portfolioData[account?.address][chain]
                      ?.tokens,
                  ];
                }
                AssetsInChainInWallet =
                  AssetsInChainInWallet?.length > 0
                    ? AssetsInChainInWallet?.map((obj) => {
                        return { ...obj, chain: chain };
                      })
                    : [];
                AssetsInChainInWallet.push({
                  token_address: null,
                  symbol:
                    this.appData?.portfolioData[account?.address][chain]
                      ?.symbol,
                  name: this.appData?.portfolioData[account?.address][chain]
                    ?.nativeCurrency,
                  logo: this.appData?.portfolioData[account?.address][chain]
                    ?.imageURL,
                  thumbnail: null,
                  decimals: null,
                  balance:
                    this.appData?.portfolioData[account?.address][chain]
                      ?.balance,
                  possible_spam: false,
                  chain: chain,
                });
                return AssetsInChainInWallet;
              })
          : []
        : [];
      return AssetsInWallet?.reduce((acc, obj) => {
        return acc.concat(obj);
      }, []);
    });

    let unfilteredAssets =
      AssetsInWallets?.length > 0
        ? AssetsInWallets?.reduce((acc, obj) => {
            return acc?.concat(obj);
          })
        : [];

    unfilteredAssets =
      unfilteredAssets?.length > 0
        ? Object.values(
            unfilteredAssets.reduce((acc, token) => {
              const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
              if (!acc[key]) acc[key] = { ...token };
              else
                acc[key].balance = (
                  parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                ).toString();
              return acc;
            }, {})
          ).map((obj) => ({ ...obj }))
        : [];

    const assets = unfilteredAssets;
    let assetsSymbol = assets.map((asset) => asset?.symbol);
    assetsSymbol = [...new Set(assetsSymbol)];

    const tokenBatches = [];
    for (let i = 0; i < assetsSymbol.length; i += 50) {
      tokenBatches.push(assetsSymbol.slice(i, i + 50));
    }

    if (tokenBatches?.length < 0) {
      return;
    }
    const allResults = [];

    if (tokenBatches?.length > 0) {
      Promise.all(
        tokenBatches?.map(async (batch) => {
          let res = await fetch(
            `${
              process.env.REACT_APP_MARKET_SERVICE_URL
            }/tokens-latest-price?tokens=${batch?.join(",").toLowerCase()}`
          ).then((e) => e.json());
          if (res?.data) {
            return res?.data;
          }
        })
      )
        .then((batchResults) => {
          batchResults.forEach((batchResult) => {
            allResults.push(...batchResult);
          });
        })
        .then(() => {
          // Now, 'allResults' contains the aggregated data for all tokens
          this.setAppData({ priceData: allResults });
        });
    }
  }

  async getPriceDataNativeCoins() {
    let coinSymbol = ["ETH", "MATIC", "ARB", "OP", "BNB", "VLX", "MNT"];
    // coinSymbol = this.appData?.chains?.map((chain) => chain)?.details?.symbol;
    if (coinSymbol?.length > 0) {
      let res = await fetch(
        `${
          process.env.REACT_APP_MARKET_SERVICE_URL
        }/tokens-latest-price?tokens=${coinSymbol?.join(",").toLowerCase()}`
      ).then((e) => e.json());
      if (res?.data) {
        this.setAppData({ priceDataCoins: res?.data });
      }
    }
  }

  async getAssetInfo(symbol, publicAddress, chainName, contractAddress) {
    let res;
    if (!contractAddress) {
      res = await fetch(
        `${process.env.REACT_APP_ASSET_INFO_SERVICE_URL}/asset/${symbol}?address=${publicAddress}&chain=${chainName}&transactions=true`
      ).then((e) => e.json());
    } else {
      res = await fetch(
        `${process.env.REACT_APP_ASSET_INFO_SERVICE_URL}/asset/${symbol}?address=${publicAddress}&chain=${chainName}&transactions=true&contractAddress=${contractAddress}`
      ).then((e) => e.json());
    }
    // if (chainName === "bitcoin") {
    //   res.data = {
    //     info: {
    //       name: "Bitcoin",
    //       symbol: "BTC",
    //       imageUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    //       circulatingSupply: 19579962,
    //       price: 43052.40313156362,
    //       percentChange1h: -1.10271819,
    //       percentChange24h: -1.76128246,
    //       percentChange7d: 3.31082483,
    //       percentChange30d: 14.46055592,
    //       marketCap: 842964417324.6967,
    //       volume24h: 18375522763.679386,
    //       percentChangeVolume: 35.9785,
    //       ohlcvData: [
    //         {
    //           time_open: "2023-11-26T00:00:00.000Z",
    //           time_close: "2023-11-26T23:59:59.999Z",
    //           time_high: "2023-11-26T08:35:00.000Z",
    //           time_low: "2023-11-26T17:02:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37796.82886433745,
    //               high: 37820.30134246359,
    //               low: 37162.749153516874,
    //               close: 37479.122293661945,
    //               volume: 13744796068.31,
    //               market_cap: 732864171270.83,
    //               timestamp: "2023-11-26T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-11-27T00:00:00.000Z",
    //           time_close: "2023-11-27T23:59:59.999Z",
    //           time_high: "2023-11-27T01:08:00.000Z",
    //           time_low: "2023-11-27T16:02:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37454.191955258146,
    //               high: 37559.35455034673,
    //               low: 36750.12710453502,
    //               close: 37254.168854249416,
    //               volume: 19002925719.53,
    //               market_cap: 728495474098.44,
    //               timestamp: "2023-11-27T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-11-28T00:00:00.000Z",
    //           time_close: "2023-11-28T23:59:59.999Z",
    //           time_high: "2023-11-28T19:43:00.000Z",
    //           time_low: "2023-11-28T05:39:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37247.991174823546,
    //               high: 38368.47995486516,
    //               low: 36891.08936198884,
    //               close: 37831.08686845,
    //               volume: 21696137014.15,
    //               market_cap: 739813120655.74,
    //               timestamp: "2023-11-28T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-11-29T00:00:00.000Z",
    //           time_close: "2023-11-29T23:59:59.999Z",
    //           time_high: "2023-11-29T08:58:00.000Z",
    //           time_low: "2023-11-29T15:14:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37826.10552174302,
    //               high: 38366.112996583346,
    //               low: 37612.63162335785,
    //               close: 37858.49405482494,
    //               volume: 20728546657.66,
    //               market_cap: 740379866134.15,
    //               timestamp: "2023-11-29T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-11-30T00:00:00.000Z",
    //           time_close: "2023-11-30T23:59:59.999Z",
    //           time_high: "2023-11-30T04:16:00.000Z",
    //           time_low: "2023-11-30T15:50:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37861.11888270345,
    //               high: 38141.75354720202,
    //               low: 37531.14162854772,
    //               close: 37712.747771632465,
    //               volume: 18115982627.42,
    //               market_cap: 737560917365.82,
    //               timestamp: "2023-11-30T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-01T00:00:00.000Z",
    //           time_close: "2023-12-01T23:59:59.999Z",
    //           time_high: "2023-12-01T16:35:00.000Z",
    //           time_low: "2023-12-01T00:28:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 37718.00962644962,
    //               high: 38954.11042677053,
    //               low: 37629.35891204924,
    //               close: 38688.748361191225,
    //               volume: 23512784001.94,
    //               market_cap: 756690015947.53,
    //               timestamp: "2023-12-01T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-02T00:00:00.000Z",
    //           time_close: "2023-12-02T23:59:59.999Z",
    //           time_high: "2023-12-02T21:54:00.000Z",
    //           time_low: "2023-12-02T00:32:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 38689.27861716844,
    //               high: 39678.93728660055,
    //               low: 38652.59567621822,
    //               close: 39476.33328037219,
    //               volume: 15534035611.94,
    //               market_cap: 772125221563.13,
    //               timestamp: "2023-12-02T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-03T00:00:00.000Z",
    //           time_close: "2023-12-03T23:59:59.999Z",
    //           time_high: "2023-12-03T23:11:00.000Z",
    //           time_low: "2023-12-03T03:13:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 39472.20747117243,
    //               high: 40135.604896908866,
    //               low: 39298.16552366375,
    //               close: 39978.39181654488,
    //               volume: 15769696321.58,
    //               market_cap: 781986059221.04,
    //               timestamp: "2023-12-03T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-04T00:00:00.000Z",
    //           time_close: "2023-12-04T23:59:59.999Z",
    //           time_high: "2023-12-04T22:20:00.000Z",
    //           time_low: "2023-12-04T00:00:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 39978.62864779512,
    //               high: 42371.74944058906,
    //               low: 39978.62864779512,
    //               close: 41980.099479767974,
    //               volume: 39856129826.57,
    //               market_cap: 821180324321.75,
    //               timestamp: "2023-12-04T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-05T00:00:00.000Z",
    //           time_close: "2023-12-05T23:59:59.999Z",
    //           time_high: "2023-12-05T22:30:00.000Z",
    //           time_low: "2023-12-05T07:45:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 41986.264347015014,
    //               high: 44408.665735092596,
    //               low: 41421.14738527545,
    //               close: 44080.647951063314,
    //               volume: 36312154534.7,
    //               market_cap: 862310043283.5,
    //               timestamp: "2023-12-05T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-06T00:00:00.000Z",
    //           time_close: "2023-12-06T23:59:59.999Z",
    //           time_high: "2023-12-06T14:29:00.000Z",
    //           time_low: "2023-12-06T11:35:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 44080.02425018693,
    //               high: 44265.770402622264,
    //               low: 43478.08131594225,
    //               close: 43746.44535725917,
    //               volume: 29909761585.63,
    //               market_cap: 855807598358.2,
    //               timestamp: "2023-12-06T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-07T00:00:00.000Z",
    //           time_close: "2023-12-07T23:59:59.999Z",
    //           time_high: "2023-12-07T04:40:00.000Z",
    //           time_low: "2023-12-07T09:28:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43769.131074245546,
    //               high: 44042.58802402473,
    //               low: 42880.650128739006,
    //               close: 43292.66423920804,
    //               volume: 27635760670.97,
    //               market_cap: 846962790499.37,
    //               timestamp: "2023-12-07T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-08T00:00:00.000Z",
    //           time_close: "2023-12-08T23:59:59.999Z",
    //           time_high: "2023-12-08T21:26:00.000Z",
    //           time_low: "2023-12-08T10:21:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43293.13494739611,
    //               high: 44705.51500941775,
    //               low: 43125.29510350429,
    //               close: 44166.60063818234,
    //               volume: 24421116686.73,
    //               market_cap: 864097723185.33,
    //               timestamp: "2023-12-08T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-09T00:00:00.000Z",
    //           time_close: "2023-12-09T23:59:59.999Z",
    //           time_high: "2023-12-09T05:31:00.000Z",
    //           time_low: "2023-12-09T23:36:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 44180.0209431358,
    //               high: 44361.25735015471,
    //               low: 43627.597180390476,
    //               close: 43725.9858124315,
    //               volume: 17368210171.26,
    //               market_cap: 855518589113.84,
    //               timestamp: "2023-12-09T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-10T00:00:00.000Z",
    //           time_close: "2023-12-10T23:59:59.999Z",
    //           time_high: "2023-12-10T21:10:00.000Z",
    //           time_low: "2023-12-10T23:22:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43728.38348009053,
    //               high: 44034.624968777265,
    //               low: 43593.28379530635,
    //               close: 43779.697287995696,
    //               volume: 13000481418.42,
    //               market_cap: 856608048216.73,
    //               timestamp: "2023-12-10T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-11T00:00:00.000Z",
    //           time_close: "2023-12-11T23:59:59.999Z",
    //           time_high: "2023-12-11T00:10:00.000Z",
    //           time_low: "2023-12-11T19:42:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43792.01785836507,
    //               high: 43808.373055668526,
    //               low: 40234.57833453312,
    //               close: 41243.83331462401,
    //               volume: 40632672038.1,
    //               market_cap: 807028644888.58,
    //               timestamp: "2023-12-11T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-12T00:00:00.000Z",
    //           time_close: "2023-12-12T23:59:59.999Z",
    //           time_high: "2023-12-12T08:49:00.000Z",
    //           time_low: "2023-12-12T18:00:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 41238.73338997509,
    //               high: 42048.30378185716,
    //               low: 40667.56185095765,
    //               close: 41450.222190466484,
    //               volume: 24779520131.83,
    //               market_cap: 811106735270.16,
    //               timestamp: "2023-12-12T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-13T00:00:00.000Z",
    //           time_close: "2023-12-13T23:59:59.999Z",
    //           time_high: "2023-12-13T22:16:00.000Z",
    //           time_low: "2023-12-13T04:21:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 41468.46586209618,
    //               high: 43429.780621333666,
    //               low: 40676.8681612393,
    //               close: 42890.74073359007,
    //               volume: 26797884673.86,
    //               market_cap: 839333709178.59,
    //               timestamp: "2023-12-13T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-14T00:00:00.000Z",
    //           time_close: "2023-12-14T23:59:59.999Z",
    //           time_high: "2023-12-14T17:49:00.000Z",
    //           time_low: "2023-12-14T13:31:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 42884.26269172501,
    //               high: 43390.86037350178,
    //               low: 41767.0905401243,
    //               close: 43023.97197419326,
    //               volume: 25578530177.94,
    //               market_cap: 841983433932.16,
    //               timestamp: "2023-12-14T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-15T00:00:00.000Z",
    //           time_close: "2023-12-15T23:59:59.999Z",
    //           time_high: "2023-12-15T00:30:00.000Z",
    //           time_low: "2023-12-15T15:47:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43028.25069052711,
    //               high: 43087.82377286698,
    //               low: 41692.968038325074,
    //               close: 41929.75839290097,
    //               volume: 19639442461.7,
    //               market_cap: 820610949396.45,
    //               timestamp: "2023-12-15T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-16T00:00:00.000Z",
    //           time_close: "2023-12-16T23:59:59.999Z",
    //           time_high: "2023-12-16T16:45:00.000Z",
    //           time_low: "2023-12-16T00:40:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 41937.74096800421,
    //               high: 42664.94513555632,
    //               low: 41723.11346624134,
    //               close: 42240.119082050114,
    //               volume: 14386729589.96,
    //               market_cap: 826722808111.63,
    //               timestamp: "2023-12-16T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-17T00:00:00.000Z",
    //           time_close: "2023-12-17T23:59:59.999Z",
    //           time_high: "2023-12-17T17:15:00.000Z",
    //           time_low: "2023-12-17T23:38:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 42236.10834491327,
    //               high: 42359.49611908532,
    //               low: 41274.54351788462,
    //               close: 41364.665918236955,
    //               volume: 16678702876,
    //               market_cap: 809628537784.36,
    //               timestamp: "2023-12-17T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-18T00:00:00.000Z",
    //           time_close: "2023-12-18T23:59:59.999Z",
    //           time_high: "2023-12-18T21:49:00.000Z",
    //           time_low: "2023-12-18T10:38:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 41348.202089156875,
    //               high: 42720.29712722866,
    //               low: 40530.2585192877,
    //               close: 42623.5383250276,
    //               volume: 25224642007.51,
    //               market_cap: 834312073585.64,
    //               timestamp: "2023-12-18T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-19T00:00:00.000Z",
    //           time_close: "2023-12-19T23:59:59.999Z",
    //           time_high: "2023-12-19T02:01:00.000Z",
    //           time_low: "2023-12-19T17:59:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 42641.510213260655,
    //               high: 43354.29659651157,
    //               low: 41826.33515168842,
    //               close: 42270.526139511014,
    //               volume: 23171001281,
    //               market_cap: 827447113190.4,
    //               timestamp: "2023-12-19T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-20T00:00:00.000Z",
    //           time_close: "2023-12-20T23:59:59.999Z",
    //           time_high: "2023-12-20T15:15:00.000Z",
    //           time_low: "2023-12-20T02:09:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 42261.30013864567,
    //               high: 44275.58751248132,
    //               low: 42223.81786147004,
    //               close: 43652.24866801767,
    //               volume: 27868908174.14,
    //               market_cap: 854532316613.74,
    //               timestamp: "2023-12-20T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-21T00:00:00.000Z",
    //           time_close: "2023-12-21T23:59:59.999Z",
    //           time_high: "2023-12-21T13:56:00.000Z",
    //           time_low: "2023-12-21T02:51:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43648.124930630205,
    //               high: 44240.66912781174,
    //               low: 43330.05264638347,
    //               close: 43869.15085699366,
    //               volume: 22452766168.52,
    //               market_cap: 858819785954.74,
    //               timestamp: "2023-12-21T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-22T00:00:00.000Z",
    //           time_close: "2023-12-22T23:59:59.999Z",
    //           time_high: "2023-12-22T06:35:00.000Z",
    //           time_low: "2023-12-22T14:30:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 43868.98642235633,
    //               high: 44367.95577178414,
    //               low: 43441.96795230248,
    //               close: 43997.901409954924,
    //               volume: 21028503216.23,
    //               market_cap: 861384314118.89,
    //               timestamp: "2023-12-22T23:59:59.999Z",
    //             },
    //           },
    //         },
    //         {
    //           time_open: "2023-12-23T00:00:00.000Z",
    //           time_close: "2023-12-23T23:59:59.999Z",
    //           time_high: "2023-12-23T00:02:00.000Z",
    //           time_low: "2023-12-23T04:13:00.000Z",
    //           quote: {
    //             USD: {
    //               open: 44012.19863207335,
    //               high: 44015.69864113365,
    //               low: 43351.355701980894,
    //               close: 43739.542137223485,
    //               volume: 13507796558.22,
    //               market_cap: 856371815373.69,
    //               timestamp: "2023-12-23T23:59:59.999Z",
    //             },
    //           },
    //         },
    //       ],
    //     },
    //     balance: 10,
    //     txns: [
    //       {
    //         hash: "88c5d3971fc7f9a35e1745e3fd5e35a26bc5ba46e9100f38b8c2640f3a63d7ef",
    //         fee: 21588,
    //         value: -21588,
    //         timeStamp: 1705386990,
    //         transactionIndex: 8438667576208536,
    //         from: ["bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r"],
    //         to: [
    //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
    //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
    //         ],
    //       },
    //       {
    //         hash: "4d78b4d073052a024b08f19ece5cbd3a2ee91e63b3b5639045403327a063b5e0",
    //         fee: 212400,
    //         value: 100000,
    //         timeStamp: 1705315571,
    //         transactionIndex: 7906229284562536,
    //         from: ["bc1qm34lsc65zpw79lxes69zkqmk6ee3ewf0j77s3h"],
    //         to: [
    //           "bc1qk6rzzx6jyzt7qsn4y5jejck8a0y84sg4dv6gqn",
    //           "bc1qm34lsc65zpw79lxes69zkqmk6ee3ewf0j77s3h",
    //           "14fPEdBZ6veffdrHnChvvYuwrQVDCEDE8z",
    //           "bc1qaerk84txnej74ucasunun3mdnujxzyxl67c9sw",
    //           "bc1qa7ty6yunkmy854y6dnuzsrnyf39xne5p7j5jk7",
    //           "bc1qr4usjv32j4sdatsmjvhrq52nqv6pmc0k4mtvdq",
    //           "bc1qazm8enugd9s9len5j7nhhc52ugynmstudr3yyn8mg0dy3j3mhpvsg8zyc6",
    //           "bc1q6mtsn4n5lax94sa0v83vw0gvsd6fmfjp2997cc",
    //           "bc1p9uhyjeda60nejzndyzd7yg4g58ctyy40yjmqkss6d6egp039453q4al5z7",
    //           "3LJJvufQdnfG4Nv36hTtPTPam7pd9aiCea",
    //           "bc1p45xz44d4fxywt6g3p5cs93c2a8588s0jxen5gcwm7r97xyht5xrqnaxd0x",
    //           "3DJKZx9vx6wPF3SrD9kGUojbotx9LufR37",
    //           "35H6Cq4xttAhkYxSjw7Q5k4f37Uaz4CvBq",
    //           "bc1qh04fmz80qh0ghdph6d9d7a0ak4tapatrk2nu2r",
    //           "bc1plx7hrlgn8gmz7urgajtzk7uevqngswm8umjfac4kf9r7gkttzezslt2phs",
    //           "1BJmWNmPKc5iaznJqRHHX2dpQMjY2BtkbT",
    //           "1Pp1Tzp43p2AdJSDZfiJiQQ3P2EdABF98A",
    //           "bc1qjl2g7p66wxha4jvaaczu9sacmakzz08x0nhj5t",
    //           "33sBFv5gqLFY4kmSZYeUYDqgoBHbhMCuCy",
    //           "bc1q2gcqgg0frghnwxvdavznrzrav38wl4raps8mqv",
    //           "bc1qj8fjrd8gfa4cmss924lln6qd3n6e4ge4ce5vd9",
    //           "3DGApTLmSj7YzL5rp4heNWGRhmH7Z8DNtx",
    //           "bc1qktledw73hcnllfe44a0dw4c48azs7nwenzjwx0",
    //           "bc1qq6s0fh033m50pe92xh57h23ljv8typfajl5rgz",
    //         ],
    //       },
    //     ],
    //     publicAddress: "bc1q62u3sv7svt87pcvxnc33hcdzg6qq7fa039tczr",
    //     chain: "bitcoin",
    //   };
    // }
    return res?.data;
  }

  async getAllTransactions(publicAdd, chainId, limit, offset) {
    let res;
    if (publicAdd) {
      if (chainId === 0) {
        res = await fetch(
          `${process.env.REACT_APP_TRANSACTION_SERVICE_URL}/address/${publicAdd}/transactions?limit=${limit}&offset=${offset}`
        ).then((e) => e.json());
        return res?.data;
      } else {
        let chainName = this.appData.chains.find(
          (e) => e.chainId === chainId
        )?.chain_name;
        if (chainName === "ethereum") {
          chainName = "eth";
        }

        res = await fetch(
          `${process.env.REACT_APP_TRANSACTION_SERVICE_URL}/address/${publicAdd}/transactions?chain=${chainName}&limit=${limit}&offset=${offset}`
        ).then((e) => e.json());
        return res?.data;
      }
    }
    return [];
  }

  async getAllAssets({ tokenType, sortKey, offset, limit }) {
    const res = await fetch(
      `${process.env.REACT_APP_MARKET_SERVICE_URL}/asset?${
        tokenType ? "filter_key=" + tokenType + "&" : ""
      }${
        sortKey ? "sort_key=" + sortKey + "&" : ""
      }offset=${offset}&limit=${limit}`
    ).then((e) => e.json());
    return res?.data;
  }

  async urlValidation(url) {
    try {
      const proxyBypassUrl = `${process.env.REACT_APP_PROXY_URL}?url=`;
      const res = await fetch(proxyBypassUrl + url, {
        method: "GET",
      });
      if (res?.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async urlValidationWithHeaders(url, headers) {
    try {
      const proxyBypassUrl = `${process.env.REACT_APP_PROXY_URL}`;
      const res = await fetch(proxyBypassUrl, {
        method: "POST",
        body: JSON.stringify({
          url: url,
          headers: headers,
        }),
        // headers: {
        //   Authorization: `Bearer ${token}`,
        //   "Content-Type": "application/json",
        // },
      });
      if (res?.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  async getLiFiTransactions(walletAddress) {
    if (walletAddress) {
      const res = await fetch(
        `${process.env.REACT_APP_LIFI_API}/${walletAddress}`
      ).then((e) => e.json());
      return res;
    }
  }

  //dashboard chart data
  async getChartData(wallet) {
    const portfolio = await this.getPortfolio(wallet);
    const worth = portfolio.assets;
    const total = worth.reduce((acc, item) => {
      acc += parseFloat(item.balanceFiat);
      return acc;
    }, 0);
    let allWorth = worth.map((el, idx) => {
      return {
        date: idx,
        name: el.symbol,
        valueFiat: el.balanceFiat,
        value:
          el.balanceFiat !== 0
            ? parseFloat(el.balanceFiat / total).toFixed(6)
            : 0,
      };
    });
    const filteredSum = allWorth.reduce((acc, el) => {
      acc += parseFloat(el.value);
      return acc;
    }, 0.0);
    if (allWorth.length > 0) {
      const other = {
        name: "Other",
        value: 1 - filteredSum,
        date: allWorth.length,
      };
      allWorth = [...allWorth, other];
    }

    return allWorth;
  }

  getPortfolio(wallet) {
    const port = this.appData?.walletAssets[wallet.id]
      ? [...this.appData.walletAssets[wallet.id]]
      : [];
    const activeChain = this.appData.chains.find(
      (e) => e.chainId === wallet.chain
    );
    const native = {
      balance: wallet.balance,
      balanceFiat: wallet.balanceFiat,
      tokenAddress: null,
      symbol: activeChain?.details?.symbol,
      logo: activeChain?.details?.imageURL,
      isNative: true,
    };
    return {
      assets: [native, ...port],
      nativeToken: activeChain?.details?.symbol,
      chain: activeChain?.chainId,
    };
  }

  getBalanceAssets(wallet, hideLowAssets = false) {
    const port = this.getPortfolio(wallet);

    if (port.assets.length) {
      let orderedAssets = port.assets.map((e) => {
        if (!e.isNative) {
          e.logo = this.getTokenIcon(e.tokenAddress);
        } else {
          e.chain = port.chain;
        }
        return e;
      });

      if (hideLowAssets) {
        const sum = orderedAssets.reduce((acc, item) => {
          acc = parseFloat(acc) + parseFloat(item.balanceFiat);
          return acc;
        }, 0.0);
        orderedAssets = orderedAssets.filter((e) => e.balanceFiat / sum > 0.01);
      }

      return orderedAssets;
    }

    return [];
  }

  //transaction sending
  async sendTransaction(config, pin, vault = false, enckey) {
    if (config && !config?.satPerByte) {
      const chain = config.chain;
      const w3 = this.getNodeFor(chain.name);
      const nonce = await w3.eth.getTransactionCount(config.from);
      let rawTx;

      if (
        config?.chain?.chainId === 56 ||
        config?.chain?.chainId === 5000 ||
        config?.chain?.chainId === 106 ||
        config?.chain?.chainId === 42161 ||
        config?.chain?.chainId === 10
      ) {
        if (
          (config?.token?.symbol === "BNB" && config?.chain?.chainId === 56) ||
          (config?.token?.symbol === "MNT" &&
            config?.chain?.chainId === 5000) ||
          (config?.token?.symbol === "VLX" && config?.chain?.chainId === 106) ||
          (config?.token?.symbol === "ETH" &&
            config?.chain?.chainId === 42161) ||
          (config?.token?.symbol === "ETH" && config?.chain?.chainId === 10)
        ) {
          const gasEstimate = config?.gasLimit;

          rawTx = {
            to: this._normalizeAddress(config.to),
            from: this._normalizeAddress(config.from),
            value: w3.utils.numberToHex(
              w3.utils.toWei(config.value.toString(), "ether")
            ),
            gasLimit: gasEstimate, //w3.utils.numberToHex(config.gasLimit),
            gasPrice:
              w3.utils.numberToHex(
                parseInt(config.maxFeePerGas * Math.pow(10, 9)).toString()
              ) ||
              w3.utils.numberToHex(
                parseInt(
                  config.maxPriorityFeePerGas * Math.pow(10, 9)
                ).toString()
              ),
            nonce: nonce,
            chainId: chain.chainId,
          };
        } else {
          const tokenContract = await new w3.eth.Contract(
            tokenDataAbi,
            config.contractAddress
          );
          let decimals = await tokenContract.methods.decimals().call();
          const encData = await this._generateTokenData(
            config.contractAddress,
            config.to,
            config.value,
            chain.name,
            decimals
          );

          const gasEstimate = config?.gasLimit;

          rawTx = {
            to: this._normalizeAddress(config.contractAddress),
            from: this._normalizeAddress(config.from),
            value: "0x0",
            gasLimit: gasEstimate, //w3.utils.numberToHex(config.gasLimit),
            gasPrice:
              w3.utils.numberToHex(
                parseInt(config.maxFeePerGas * Math.pow(10, 9)).toString()
              ) ||
              w3.utils.numberToHex(
                parseInt(
                  config.maxPriorityFeePerGas * Math.pow(10, 9)
                ).toString()
              ),
            nonce: nonce,
            data: encData,
            chainId: chain.chainId,
          };
        }
      }
      if (config?.chain?.chainId === 1 || config?.chain?.chainId === 137) {
        if (
          (config?.token?.symbol === "ETH" && config?.chain?.chainId === 1) ||
          (config?.token?.symbol === "MATIC" && config?.chain?.chainId === 137)
        ) {
          rawTx = {
            to: this._normalizeAddress(config.to),
            from: this._normalizeAddress(config.from),
            value: w3.utils.numberToHex(
              w3.utils.toWei(config.value.toString(), "ether")
            ),
            gasLimit: w3.utils.numberToHex(config.gasLimit),
            maxPriorityFeePerGas: w3.utils.numberToHex(
              parseInt(config.maxPriorityFeePerGas * Math.pow(10, 9)).toString()
            ),
            maxFeePerGas: w3.utils.numberToHex(
              parseInt(config.maxFeePerGas * Math.pow(10, 9)).toString()
            ),
            nonce: nonce,
            chainId: chain.chainId,
          };
        } else {
          try {
            const tokenContract = await new w3.eth.Contract(
              tokenDataAbi,
              config.contractAddress
            );
            let decimals = await tokenContract.methods.decimals().call();

            const encData = await this._generateTokenData(
              config.contractAddress,
              config.to,
              config.value,
              chain.name,
              decimals
            );
            rawTx = {
              to: config.contractAddress,
              from: this._normalizeAddress(config.from),
              value: "0x0",
              gasLimit: w3.utils.numberToHex(parseInt(config.gasLimit)),
              maxPriorityFeePerGas: w3.utils.numberToHex(
                parseInt(
                  config.maxPriorityFeePerGas * Math.pow(10, 9)
                ).toString()
              ),
              maxFeePerGas: w3.utils.numberToHex(
                parseInt(config.maxFeePerGas * Math.pow(10, 9)).toString()
              ),
              nonce: w3.utils.toHex(nonce),
              data: encData,
              chainId: chain.chainId,
              type: "0x2",
            };
          } catch (error) {
            console.log(
              "🚀 ~ file: AppController.js ~ line 990 ~ AppController ~ sendTransaction ~ error",
              error
            );

            this.setLoading(false);
            return { error };
          }
        }
      }

      try {
        const signedTx = await this._signTransaction(
          rawTx,
          pin,
          chain,
          vault,
          enckey
        );

        this.setLoading(false);

        this.setAppData({ pendingSendConfirmation: true });

        var txHash;
        const tx = w3.eth.sendSignedTransaction(signedTx);
        tx.on("confirmation", (count, receipt) => {
          this.setAppData({ transactionResult: receipt });
          if (count > 0) {
            this.setNotificationData({
              txConfirmation: true,
              txDetails:
                this.getScanURL(chain.chainId) +
                "/tx/" +
                receipt.transactionHash,
            });
            tx.off("confirmation");
          }
        });
        const self = this;
        tx.once("transactionHash", function (hash) {
          txHash = hash;

          self.setAppData({ pendingSendTxId: txHash });
        });
        tx.on("error", (error) => {
          console.log(error);
          self.setAppData({ transactionResult: error.message });
        });
      } catch (e) {
        console.log(e);
        this.setLoading(false);
        return { e };
      }
    } else {
      this.setLoading(false);

      this.setAppData({ pendingSendConfirmation: true });

      const proxyBypasser = process.env.REACT_APP_PROXY_URL;
      const headers = {
        "API-KEY": process.env.REACT_APP_SOCHAIN_API_KEY,
      };
      const chain = { chain_name: "bitcoin" };
      const rawTx = {
        from: config?.from,
        to: config?.to,
        amount: config?.amount,
        satPerByte: config?.satPerByte,
      };
      const TransactionHex = await this._signTransaction(
        rawTx,
        pin,
        chain,
        vault,
        enckey
      );
      const params = {
        url:
          process.env.REACT_APP_SOCHAIN_BASE_URL + `/broadcast_transaction/BTC`,
        headers: headers,
        data: {
          tx_hex: TransactionHex,
        },
      };
      // const authToken = Storage.load("user")?.token;
      const self = this;
      await fetch(proxyBypasser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(params),
      })
        .then((r) => r.json())
        .then((resp) => {
          if (resp?.error) {
            self.setAppData({ transactionResult: resp?.error });
            return false;
          }
          self.setAppData({ pendingSendTxId: resp?.data?.hash });
          self.setAppData({ transactionResult: resp });
          return resp;
        })
        .catch((err) => {
          self.setAppData({ transactionResult: err?.message });
          return false;
        });
    }
  }

  setNotification(data) {
    this.setNotificationData(data);
  }

  async _signTransaction(rawTx, pin, chain, vault, enckey) {
    let signedTx, chainName, signed;
    chainName = chain.chain_name;
    vault.changeNetwork(chainName);

    signed = await vault.signTransaction(
      rawTx,
      pin?.toString()?.padStart(6, "0"),
      chainName !== "bitcoin" ? chain.uri : null
    );

    signedTx = signed.response;

    return signedTx;
  }

  _isNativeToken(token) {
    const chain =
      this.appData.hasOwnProperty("chains") &&
      this.appData.chains.find(
        (e) =>
          e.chain_name === token?.info?.slug ||
          e.chain_name === token?.name?.toLowerCase() ||
          e.details?.symbol?.toLowerCase() === token?.name?.toLowerCase()
      );
    return chain ? chain.details.symbol === token.symbol : false;
  }
  async _calculateTokenGas(
    contractAddress,
    fromAddress,
    sendToAddress,
    value,
    decimals,
    chain_name
  ) {
    try {
      const w3 = this.getNodeFor(chain_name);

      const val = toBaseUnit(
        parseFloat(value).toFixed(decimals).toString(),
        decimals,
        w3.utils.BN
      );

      const contractInstance = new w3.eth.Contract(
        calculateGasAbi,
        contractAddress
      );
      let gas = await contractInstance.methods
        .transfer(sendToAddress, val)
        .estimateGas({ from: fromAddress });

      return gas;
    } catch (e) {
      return 0;
    }
  }

  async _generateTokenData(contractAddress, to, value, chain_name, decimals) {
    try {
      const val = decimals
        ? (
            parseFloat(value).toFixed(decimals) * Math.pow(10, decimals)
          ).toString()
        : (parseFloat(value).toFixed(18) * Math.pow(10, 18)).toString();
      const w3 = this.getNodeFor(chain_name);

      const contractInstance = new w3.eth.Contract(ERC20ABI, contractAddress);
      const data = await contractInstance.methods.transfer(to, val).encodeABI();
      return data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  _normalizeAddress(addr) {
    return addr.substr(0, 2) + addr.substr(-40).toLowerCase();
  }

  //transaction end
  getNativeAssets() {
    const res = this.vault.getSupportedChains();
    const assets = Object.values(res.response)
      .flat()
      .map((i) => Object.keys(i))
      .flat();
    return assets;
  }

  //assets
  async loadSupportedAssets(loadNativeTokens = true) {
    let res = await fetch(process.env.REACT_APP_ASSETS_JSON_URL)
      .then((e) => e.json())
      .catch((e) => {
        return false;
      });
    // this.getNativeAssets()

    const supported = this.getNativeAssets();
    const correlatedSymbols = {
      WETH: { symbol: "ETH", name: "Ethereum" },
    };
    let assets = {};
    //
    for (var i in supported) {
      if (res?.chains?.hasOwnProperty(supported[i])) {
        const tokens = res.chains[supported[i]].CONTRACT_MAP;
        for (const j in tokens) {
          if (correlatedSymbols.hasOwnProperty(tokens[j].symbol)) {
            const name = correlatedSymbols[tokens[j].symbol].name;
            const symb = correlatedSymbols[tokens[j].symbol]?.symbol;
            //
            tokens[j].correlatedSymbol = tokens[j].symbol;
            tokens[j].symbol = symb;
            tokens[j].correlatedName = tokens[j].name;
            tokens[j].name = name;
            tokens[j].isCoin = true;
            tokens[j].logo = correlatedSymbols[tokens[j].symbol]?.logo;
          }
          tokens[j].chain = supported[i];
        }

        if (tokens) {
          assets = { ...assets, ...tokens };
        }
      }
    }
    if (loadNativeTokens) {
      const coins = this.getNativeCoins().reduce((acc, el) => {
        acc[el.chain.toString().padStart(32, "0")] = {
          ...el,
          token: "3",
          address: null,
        };
        return acc;
      }, {});
      assets = { ...assets, ...coins };
    }
    this.setAppData({ assets });
    return true;
  }
  async loadTopAssets() {
    let res = await fetch(process.env.REACT_APP_ASSETS_JSON_URL)
      .then((e) => e.json())
      .catch((e) => false);
    return res;
  }

  getTokenName(symbol) {
    const assets = this.appData.hasOwnProperty("assets")
      ? { ...this.appData.assets }
      : {};

    const token = Object.values(assets).find((e) => e.symbol === symbol);

    return token?.name;
  }

  getTokenNetworks(symbol) {
    try {
      const chainAvail = this.appData.chains.map((chain) => {
        return chain.chain_name;
      });

      return chainAvail;
    } catch (e) {}
  }

  getNativeCoins() {
    const asset_name = {
      polygon: "Matic",
      ethereum: "Ethereum",
    };
    if (this.appData.hasOwnProperty("chains")) {
      const nativeCoins = [];

      for (var i in this.appData.chains) {
        const coin = this.appData.chains[i].details;
        nativeCoins.push({
          erc20: true,
          chain: coin.network.split("-").shift(),
          decimals: 1,
          logo: coin.imageURL,
          name: asset_name[this.appData.chains[i].chain_name],
          symbol: coin.symbol,
          isCoin: true,
        });
      }
      return nativeCoins;
    }
    return [];
  }

  async getTransakSupportedCurrencies() {
    let res = await fetch(
      "https://api-stg.transak.com/api/v2/currencies/crypto-currencies"
    ).then((e) => e.json());
    this.setAppData({ transakSupportedCurrencies: res.response });
  }

  //currencies
  async loadSupportedCurrencies() {
    // get default currency
    const defaultCurrency = {
      id: "USD",
      "Currency Code": "USD",
      "Currency Name": "US Dollar",
      Region: "UNITED STATES OF AMERICA",
      rate: 1,
    };

    const activeCurrency = currenciesInfo.find(
      (e) => e.id === defaultCurrency.id
    );
    if (activeCurrency) {
      this.setAppData({
        currencies: currenciesInfo,
        activeCurrency: activeCurrency.id,
      });
    } else {
      this.setAppData({
        currencies: currenciesInfo,
        activeCurrency: defaultCurrency,
      });
    }
    return true;
  }

  async getSupportedCurrencies(loadPrices = false) {
    return this.appData.currencies;
  }
  async saveActiveCurrency(currency, token) {
    try {
      //
      let saveResp = await fetch(APIS.currencySave, {
        method: "PATCH",
        body: JSON.stringify({
          name: currency["Currency Name"],
          symbol: currency["Currency Code"],
        }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((e) => e.json());

      if (saveResp.statusCode === 400) {
        saveResp = await fetch(APIS.currencySave, {
          method: "POST",
          body: JSON.stringify({
            name: currency["Currency Name"],
            symbol: currency["Currency Code"],
          }),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then((e) => e.json());
      }

      const stor = Storage.load("settings");
      stor.activeCurrency = currency;
      Storage.save("settings", stor);

      const activeCurr = currency;
      activeCurr.rate = await this.getCurrencyRate(currency["Currency Code"]);

      this.setAppData({ activeCurrency: activeCurr });
    } catch (e) {
      return false;
    }
  }

  async loadActiveCurrency(token) {
    const stor = Storage.load("settings") || {};
    if (!stor.hasOwnProperty("activeCurrency")) {
      let activeCurr;

      try {
        const loadResp = await fetch(APIS.currencySave, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }).then((e) => e.json());

        activeCurr = this.appData.currencies.find(
          (e) => e["Currency Name"] === loadResp.data.currency.name
        );
        activeCurr.rate = await this.getCurrencyRate(
          activeCurr["Currency Code"]
        );
      } catch (e) {
        activeCurr = {
          id: "USD",
          "Currency Code": "USD",
          "Currency Name": "US Dollar",
          Region: "UNITED STATES OF AMERICA",
          rate: 1,
        };
      }

      stor.activeCurrency = activeCurr;
      Storage.save("settings", stor);
    } else {
      const cr = await this.getCurrencyRate(
        stor.activeCurrency["Currency Code"]
      );

      stor.activeCurrency.rate = cr;
    }

    this.setAppData({ activeCurrency: stor.activeCurrency });
  }

  // rates
  async loadUsdRate() {
    const url = process.env.REACT_APP_MARKET_SERVICE_URL;
    try {
      const usdRate = await fetch(url + "/conversion-rate?symbol=USD").then(
        (e) => e.json()
      );

      this.setAppData({
        usdRate: {
          value: parseFloat(usdRate?.data?.rates[usdRate?.data?.base]),
          timestamp: usdRate?.data?.timestamp,
        },
      });
    } catch (e) {
      return true;
    }
    return true;
  }

  async getRate(coinName, network, address = false) {
    const rate = await getCoinRate(coinName);
    if (rate?.data?.data) {
      const price = rate?.data?.data[coinName]?.quote?.USD?.price;
      return price || 0;
    } else {
      return 0;
    }
  }

  async getCurrencyRate(symbol) {
    const url = process.env.REACT_APP_MARKET_SERVICE_URL;
    try {
      const rate = await fetch(url + "/conversion-rate?symbol=" + symbol).then(
        (e) => e.json()
      );

      return rate?.data?.rates[symbol];
    } catch (e) {
      return 1;
    }
  }

  getActiveCurrencyRate() {
    return this.appData.hasOwnProperty("activeCurrency")
      ? parseFloat(this.appData.activeCurrency?.rate)
      : 1;
  }
  getActiveCurrencySymbol() {
    return this.appData.activeCurrency
      ? this.appData.activeCurrency.id === "USD"
        ? "$"
        : this.appData.activeCurrency["Currency Symbol"]
      : "$";
  }

  activeCurrency(amount) {
    const mult = this.getActiveCurrencyRate();
    return amount * mult;
  }

  async getOhlcvData(symbol) {
    const res = await fetch(`${MARKET_SERVICE_URL}ohlcv?coin=${symbol}`).then(
      (e) => e.json()
    );
    return res.data;
  }

  async favouriteToken(item, token) {
    const url = process.env.REACT_APP_WALLETS_URL;
    const res = await fetch(`${url}/wallet/user-favourite`, {
      method: "POST",
      body: JSON.stringify({
        ...item,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return res;
  }

  async addCustomToken(tokenInfo, bearerToken) {
    let contractAddress = tokenInfo.hasOwnProperty("contractAddress")
      ? tokenInfo.contractAddress
      : tokenInfo.hasOwnProperty("address")
      ? tokenInfo.address
      : null;
    if (!contractAddress) {
      const info = await fetch(
        `${MARKET_SERVICE_URL}info?coin=${tokenInfo.symbol}`
      ).then((e) => e.json());
      if (info.data.data) {
        contractAddress = info.data.data[
          tokenInfo.symbol
        ]?.contract_address.filter(
          (e) => e.platform.coin?.slug === tokenInfo.chain
        )[0]?.contract_address;
      }
    }

    const res = await fetch(`${WALLETS_URL}/wallet/user-token`, {
      method: "POST",
      body: JSON.stringify({
        tokens: [
          {
            chain: tokenInfo.chain,
            decimals: tokenInfo.decimals,
            image: tokenInfo.logo,
            name: tokenInfo.name,
            symbol: tokenInfo.symbol,
            contractAddress: tokenInfo.contractAddress
              ? tokenInfo.contractAddress
              : tokenInfo.address
              ? tokenInfo.address
              : contractAddress,
          },
        ],
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
    }).then((e) => e.json());

    return res;
  }

  async addFavouriteToken(tokenInfo, bearerToken) {
    const res = await fetch(`${WALLETS_URL}/wallet/user-favourite`, {
      method: "POST",
      body: JSON.stringify({
        name: tokenInfo.slug,
        symbol: tokenInfo.symbol,
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
    }).then((e) => e.json());
    return res;
  }

  async removeFavouriteToken(tokenInfo, bearerToken) {
    const res = await fetch(`${WALLETS_URL}/wallet/user-favourite`, {
      method: "DELETE",
      body: JSON.stringify({
        symbol: tokenInfo.symbol,
      }),
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
    }).then((e) => e.json());
    return res;
  }

  async getFavouriteTokens(bearerToken) {
    const res = await fetch(`${WALLETS_URL}/wallet/user-favourite`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        "Content-Type": "application/json",
      },
    }).then((e) => e.json());
    return res;
  }

  async getCustomTokenDetails(contractAddress, chainName) {
    const node = this.getNodeFor(chainName);
    if (node.utils.isAddress(contractAddress)) {
      try {
        const contract = new node.eth.Contract(tokenDataAbi, contractAddress);
        const decimals = await contract.methods.decimals().call();
        const symbol = await contract.methods.symbol().call();
        return { symbol, decimals };
      } catch (e) {
        return "invalid";
      }
    } else {
      return "invalid";
    }
  }

  async getBalance(contracAddress) {
    const activeChain =
      this.appData.activeChain !== null
        ? this.appData.chains[this.appData.activeChain]
        : this.appData.chains[0];
    const userAddress =
      this.appData.activeAccountAddress ||
      (this.appData.accounts &&
        Object.values(this.appData.accounts)[0].address);
    const tokenController = new safleTokenController.CustomTokenController({
      userAddress: userAddress,
      rpcURL: activeChain.uri,
      chain: activeChain.chain_name,
    });

    const tokenDetails = await tokenController.getTokenBalance(contracAddress);

    return tokenDetails[0];
  }

  async getPriorityOptionValues(chainId) {
    if (chainId === 1) {
      const priorityOptionValues = await fetch(
        "https://gas-api.metaswap.codefi.network/networks/1/suggestedGasFees"
      ).then((r) => r.json());
      return priorityOptionValues;
    }
    if (chainId === 137) {
      const priorityOptionValues = await fetch(
        "https://gasstation.polygon.technology/v2"
      ).then((r) => r.json());
      return priorityOptionValues;
    }
  }

  async getEthToUsd() {
    const price = await fetch(
      MARKET_SERVICE_URL + "/latest-price?coin=ETH"
    ).then((e) => e.json());
    return price;
  }

  async getChainNativeToUsd(chainId) {
    const price = await fetch(
      MARKET_SERVICE_URL +
        "/latest-price?coin=" +
        this.getNativeToken(chainId === 10 || chainId === 42161 ? 1 : chainId)
    ).then((e) => e.json());
    return price;
  }

  async getSwapHistory(token) {
    const url = `${process.env.REACT_APP_SWAP_URL}/token-swap/transaction`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((e) => e.json());
      return resp;
    } catch (e) {
      return e;
    }
  }

  async saveSwapTransaction(data, method = "POST") {
    const url = `${process.env.REACT_APP_SWAP_URL}/token-swap/transaction`;
    try {
      const resp = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${Storage.load("user").token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((e) => e.json());
      return resp;
    } catch (e) {
      return e;
    }
  }

  async getRampHistory(token) {
    const url = `${process.env.REACT_APP_RAMP_SERVICE}/orders`;

    try {
      const rampTx = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((e) => e.json());
      return rampTx;
    } catch (e) {
      return e;
    }
  }

  async logout() {
    await this.setAppData(initialState);
  }

  async fetchNotifications(limit, offset = 0, status) {
    try {
      const res = await fetch(
        APIS.get_notifications +
          `?limit=${limit}&offset=${offset}&status=${status}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Storage.load("user").token,
          },
        }
      )
        .then((e) => e.json())
        .catch((e) => {
          throw new Error(e);
        });

      if (res.statusCode === 200) {
        let data = res.data;
        let items = data?.map((i) => {
          let p = {
            ...i.notification,
            text: i?.notification?.data?.title,
            time_ago: moment(i?.notification?.time).fromNow(),
          };

          return p;
        });
        return items;
      } else {
        throw new Error(res);
      }
    } catch (e) {
      //   return returnError ? false : 1;
    }
  }

  async updateUserNotification(notification) {
    const res = await fetch(
      `${APIS.update_user_notification}/${notification.notificationId}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          data: notification.data,
          type: notification.type,
          status: notification.status,
        }),
        headers: {
          Authorization: `Bearer ${Storage.load("user").token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return res;
  }
  async getCoinBalance(acccountAddress, decimals) {
    const activeChain = this.appData.chains
      ? this.appData.chains.filter(
          (chain) => chain?.chainId === this.appData?.activeChain
        )[0]
      : this.appData.chains[0];

    const web3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));

    const balance = await web3.eth.getBalance(acccountAddress);
    const coinBalance = (balance * 1) / 10 ** (decimals || 0);
    return coinBalance;
  }
  async getTokenBalance(accountAddress, selectedTokenAddress) {
    const activeChain = this.appData.chains
      ? this.appData.chains.filter(
          (chain) => chain?.chainId === this.appData?.activeChain
        )[0]
      : this.appData.chains[0];

    const web3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));

    const contractInstance = new web3.eth.Contract(
      ERC20ABI,
      selectedTokenAddress
    );
    const balanceInWei = await contractInstance.methods
      .balanceOf(accountAddress)
      .call();
    const decimals = await contractInstance.methods.decimals().call();
    const decimalAdjustedBalance = balanceInWei / Math.pow(10, decimals);
    return decimalAdjustedBalance;
  }

  async getPriceData(payload) {
    try {
      const { publicAddress, contractAddress, tokenId, chain } = payload;
      const nftController = new safleNftController.NftController();
      const res = await nftController.getPriceData([
        { publicAddress, contractAddress, tokenId, chain },
      ]);
      return res;
    } catch (e) {}
  }

  async getGasFees(chainId) {
    const GAS_FEES_APIS = {
      POLYGON_MAINNET: "https://gasstation.polygon.technology/v2",
      POLYGON_TESTNET: "https://gasstation-testnet.polygon.technology/v2",
      ETHEREUM: `https://gas-api.metaswap.codefi.network/networks/${1}/suggestedGasFees`,
    };

    let resp;
    //for ethereum nd polygon networks
    if (chainId === 1 || chainId === 137) {
      let rpcUrl = "";
      if (chainId === 1) {
        rpcUrl = GAS_FEES_APIS?.ETHEREUM;
      } else if (chainId === 137) {
        rpcUrl = GAS_FEES_APIS?.POLYGON_MAINNET;
      } else {
        rpcUrl = `https://gas-api.metaswap.codefi.network/networks/1/suggestedGasFees`;
      }
      resp = await fetch(rpcUrl)
        .then((e) => e.json())
        .catch((e) => {
          console.error("error fetching estimats", e);
          return e;
        });
    }
    return JSON.stringify(resp);
  }

  async sendNftTxn(payload, setTxHash) {
    const {
      activeChain,
      vaultString,
      pin,
      enckey,
      contractAddress,
      tokenId,
      from,
      to,
      maxPriorityFee,
      maxFeePerGas,
    } = payload;

    //filtering rpcurl nd chainid
    const chain = this.appData?.chains?.find((i) => {
      return i?.chainId === activeChain;
    });

    try {
      const web3 = new Web3(new Web3.providers.HttpProvider(chain?.uri));
      await this.vault.restoreKeyringState(
        vaultString,
        pin?.toString()?.padStart(6, "0"),
        enckey
      );
      const contractInstance = payload?.isErc721
        ? new web3.eth.Contract(erc721ABI, contractAddress)
        : new web3.eth.Contract(erc1155ABI, contractAddress);
      const encodedABI = payload?.isErc721
        ? await contractInstance.methods
            .safeTransferFrom(from, to, tokenId.toString())
            .encodeABI()
        : await contractInstance.methods
            .safeTransferFrom(from, to, tokenId.toString(), 1, 0)
            .encodeABI();
      const gasLimit = payload?.gasLimit;
      const count = await web3.eth.getTransactionCount(from);
      const nonce = await web3.utils.toHex(count);
      let rawTx;
      if (activeChain === 1 || activeChain === 137) {
        rawTx = {
          to: contractAddress,
          from,
          value: "0x0",
          gasLimit: Math.trunc(gasLimit),
          maxPriorityFeePerGas: web3.utils.numberToHex(
            parseInt(maxPriorityFee * Math.pow(10, 9)).toString()
          ),
          maxFeePerGas: web3.utils.numberToHex(
            parseInt(maxFeePerGas * Math.pow(10, 9)).toString()
          ),
          data: encodedABI,
          nonce,
          type: "0x2",
          chainId: chain?.chainId,
        };
      }
      if (
        activeChain === 56 ||
        activeChain === 5000 ||
        activeChain === 106 ||
        activeChain === 42161 ||
        activeChain === 10
      ) {
        rawTx = {
          to: contractAddress,
          from,
          value: "0x0",
          gasLimit: Math.trunc(gasLimit),
          gasPrice:
            web3.utils.numberToHex(
              parseInt(maxPriorityFee * Math.pow(10, 9)).toString()
            ) ||
            web3.utils.numberToHex(
              parseInt(maxFeePerGas * Math.pow(10, 9)).toString()
            ),
          data: encodedABI,
          nonce,
          chainId: chain?.chainId,
        };
      }

      this.vault.changeNetwork(chain?.chain_name);
      const signedTransaction = await this.vault.signTransaction(
        rawTx,
        pin?.toString()?.padStart(6, "0"),
        chain?.uri
      );

      if (!signedTransaction.response || signedTransaction.error) {
        return { status: false };
      }
      const txHash = await web3.utils.sha3(signedTransaction.response);

      if (txHash) {
        setTxHash(txHash);
      }

      const tx = await web3.eth
        .sendSignedTransaction(signedTransaction.response)
        .once("receipt", (receipt) => {
          const gasInWei = receipt.gasUsed * receipt.effectiveGasPrice;
          const gasInEth = Web3.utils.fromWei(gasInWei, "ether");

          return { ...receipt, status: receipt?.status, gasInWei, gasInEth };
        });

      return tx;
    } catch (e) {
      console.log(e);
      return { status: false };
    }
  }

  async getVaultLogsHistory(token) {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LOGS_SERVICE_URL}?page=1&limit=100`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => response.json());
      return res.statusCode === 200 && res;
    } catch (e) {
      return e;
    }
  }
  async getVaultDetails() {
    try {
      const vaultUser = Storage.load("user");
      let vaultTableData = {
        activeWallets: [],
        archivedWallets: [],
        importedWallets: [],
        exportedWallets: [],
        vaultLogs: [],
        userDetails: "-",
        chainsCount: 0,
      };
      await this.vault.getAccounts(Object.values(vaultUser?.decriptionKey));

      // get all the wallets
      const vaultDetails = await this.vault.getVaultDetails(
        Object.values(vaultUser?.decriptionKey)
      );
      //get Active chains
      let chains = await this.vault.getActiveChains();
      chains = {
        response: chains?.response?.filter(
          (item) =>
            item?.chain !== "avalanche" &&
            item?.chain !== "base" &&
            item?.chain !== "zkEVM"
        ),
      };

      vaultTableData.chainsCount = chains.response.length;

      //get all logs
      const getAllLogsRes = await this.getVaultLogsHistory(vaultUser?.token);

      //fetching timestamp of activity from get all logs API
      const getWalletDataFromGetLogs = (address, activity) => {
        const filteredData = getAllLogsRes?.doc.filter((logItem) => {
          return logItem?.address === address && activity === logItem?.action;
        });
        return filteredData;
      };

      if (vaultDetails?.response) {
        // const generatedWallets = vaultDetails?.response?.evm?.generatedWallets
        //   ? Object.values(vaultDetails?.response?.evm?.generatedWallets)
        //   : [];
        // const importedWallets = vaultDetails?.response?.evm?.importedWallets
        //   ? Object.values(vaultDetails?.response?.evm?.importedWallets)
        //   : [];
        const allWallets = [];

        for (const type in vaultDetails.response) {
          const wallets = vaultDetails.response[type].generatedWallets;
          for (const index in wallets) {
            const wallet = wallets[index];
            allWallets.push({
              address: wallet.address,
              isDeleted: wallet.isDeleted,
              isImported: wallet.isImported,
              label: wallet.label,
              type: type,
            });
          }
        }
        for (const type in vaultDetails.response) {
          const wallets = vaultDetails.response[type].importedWallets;
          for (const index in wallets) {
            const wallet = wallets[index];
            allWallets.push({
              address: wallet.address,
              isDeleted: wallet.isDeleted,
              isImported: wallet.isImported,
              label: wallet.label,
              type: type,
            });
          }
        }

        // const allWallets = [...generatedWallets, ...importedWallets];
        const chainsRes = chains.response?.filter(
          (chain) => chain.symbol !== "BTC"
        );
        const nonEvmChains = chains.response?.filter(
          (chain) => chain.symbol === "BTC"
        );

        const getSafleCardWallet = (addr) => {
          const safleWallet = this.appData?.wallets.find((i) => {
            return i?.chain === 137 && i?.isSafleCard && i?.address === addr;
          });
          return safleWallet?.address ? true : false;
        };
        //using reduce to prepare the table data
        allWallets
          ?.filter((wallet) => wallet?.type === "evm")
          ?.reduce(async (delAcc, delEl, index) => {
            chainsRes?.reduce((chainAcc, chainCur) => {
              !delEl?.isDeleted &&
                (chainAcc["activeWallets"][
                  vaultTableData?.activeWallets?.length
                ] = {
                  ...delEl,
                  address: delEl.address,
                  label:
                    (chainCur?.chain === "polygon" &&
                    getSafleCardWallet(delEl?.address)
                      ? "SafleID Wallet"
                      : delEl.label[chainCur?.chain]) || delEl?.label,
                  canBeArchived: getSafleCardWallet(delEl?.address)
                    ? true
                    : false,
                  chainName: chainCur?.chain,
                  type: delEl?.isImported ? "Imported" : "Generated",
                  createdAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      delEl?.isImported ? "import-wallet" : "add-wallet"
                    )?.[0]?.timestamp || "-",
                  count: 0,
                });
              delEl?.isDeleted &&
                (chainAcc["archivedWallets"][
                  vaultTableData?.archivedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl.address,
                  label: delEl.label[chainCur?.chain] || delEl?.label,
                  chainName: chainCur?.chain,
                  type: delEl?.isImported ? "imported" : "Generated",
                  createdAt:
                    getWalletDataFromGetLogs(delEl?.address, "add-wallet")?.[0]
                      ?.timestamp || "-",
                  archivedAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "delete-wallet"
                    )?.[0]?.timestamp || "-",
                  count: 0,
                });

              delEl?.isImported &&
                (chainAcc["importedWallets"][
                  vaultTableData?.importedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl?.address,
                  label: delEl?.label[chainCur?.chain] || delEl?.label,
                  importedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "import-wallet"
                    )?.[0]?.timestamp || "-",
                  archivedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "delete-account"
                    )?.[0]?.timestamp || "-",
                });
              getWalletDataFromGetLogs(
                delEl?.address,
                "export-private-key"
              )?.[0]?.timestamp &&
                (chainAcc["exportedWallets"][
                  vaultTableData?.exportedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl?.address,
                  label:
                    chainCur?.chain === "polygon" &&
                    getSafleCardWallet(delEl?.address)
                      ? "SafleID Wallet"
                      : delEl?.label[chainCur?.chain] || delEl?.label,
                  canBeArchived: getSafleCardWallet(delEl?.address)
                    ? true
                    : false,
                  importedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "import-wallet"
                    )?.[0]?.timestamp || "-",
                  exportedAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "export-private-key"
                    )?.[0]?.timestamp || "-",
                });

              return chainAcc;
            }, vaultTableData);

            return delAcc;
          }, vaultTableData);

        allWallets
          ?.filter((wallet) => wallet?.type !== "evm")
          ?.reduce(async (delAcc, delEl, index) => {
            nonEvmChains?.reduce((chainAcc, chainCur) => {
              !delEl?.isDeleted &&
                (chainAcc["activeWallets"][
                  vaultTableData?.activeWallets?.length
                ] = {
                  ...delEl,
                  address: delEl.address,
                  label:
                    (chainCur?.chain === "polygon" &&
                    getSafleCardWallet(delEl?.address)
                      ? "SafleID Wallet"
                      : delEl.label[chainCur?.chain]) || delEl?.label,
                  canBeArchived: getSafleCardWallet(delEl?.address)
                    ? true
                    : false,
                  chainName: chainCur?.chain,
                  type: delEl?.isImported ? "Imported" : "Generated",
                  createdAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      delEl?.isImported ? "import-wallet" : "add-wallet"
                    )?.[0]?.timestamp || "-",
                  count: 0,
                });
              delEl?.isDeleted &&
                (chainAcc["archivedWallets"][
                  vaultTableData?.archivedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl.address,
                  label: delEl.label[chainCur?.chain] || delEl?.label,
                  chainName: chainCur?.chain,
                  type: delEl?.isImported ? "imported" : "Generated",
                  createdAt:
                    getWalletDataFromGetLogs(delEl?.address, "add-wallet")?.[0]
                      ?.timestamp || "-",
                  archivedAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "delete-wallet"
                    )?.[0]?.timestamp || "-",
                  count: 0,
                });

              delEl?.isImported &&
                (chainAcc["importedWallets"][
                  vaultTableData?.importedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl?.address,
                  label: delEl?.label[chainCur?.chain] || delEl?.label,
                  importedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "import-wallet"
                    )?.[0]?.timestamp || "-",
                  archivedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "delete-account"
                    )?.[0]?.timestamp || "-",
                });
              getWalletDataFromGetLogs(
                delEl?.address,
                "export-private-key"
              )?.[0]?.timestamp &&
                (chainAcc["exportedWallets"][
                  vaultTableData?.exportedWallets?.length
                ] = {
                  ...delEl,
                  address: delEl?.address,
                  label:
                    chainCur?.chain === "polygon" &&
                    getSafleCardWallet(delEl?.address)
                      ? "SafleID Wallet"
                      : delEl?.label[chainCur?.chain] || delEl?.label,
                  canBeArchived: getSafleCardWallet(delEl?.address)
                    ? true
                    : false,
                  importedOn:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "import-wallet"
                    )?.[0]?.timestamp || "-",
                  exportedAt:
                    getWalletDataFromGetLogs(
                      delEl?.address,
                      "export-private-key"
                    )?.[0]?.timestamp || "-",
                });

              return chainAcc;
            }, vaultTableData);

            return delAcc;
          }, vaultTableData);
      }

      //get Vault version from auth/me API
      const getVersionAndStorage = await this.getUserByToken();
      vaultTableData["userDetails"] = getVersionAndStorage;

      return vaultTableData;
    } catch (e) {
      return e;
    }
  }

  async getRegistrationStatus() {
    try {
      const token =
        Storage.load("user")?.token ||
        Storage.load("registration")?.newPasswordToken;
      const url = `${process.env.REACT_APP_AUTH_URL}/auth/registration-status`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }).then((e) => e.json());

      if (res?.statusCode === 200) {
        return res?.data;
      }
    } catch (e) {}
  }

  async getUserByToken() {
    try {
      const token = Storage.load("user")?.token;
      const url = `${process.env.REACT_APP_AUTH_URL}/auth/me`;
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }).then((e) => e.json());

      if (res?.statusCode === 200) {
        return res?.data;
      }
    } catch (e) {}
  }
}

export default AppController;
