import React, { useEffect, useState } from "react";
import MainModal from "./../generic/MainModal";
import { useApp } from "../../helpers/AppProvider";
import transakSDK from "@transak/transak-sdk";
import Storage from "../../helpers/Storage";
import DropdownNetworkNew from "../common/DropdownNetworkNew/DropdownNetworkNew";
import DropdownWalletNew from "../common/DropdownWalletNew/DropdownWalletNew";
import { useModalData } from "../../helpers/useModalNavigation";
// import WalletSelector from "../swap/WalletSelector/WalletSelector";
// const chainIdToNetwork = {
//   1: "ethereum",
//   137: "polygon",
// };
const ModalAssetPurchase = (props) => {
  const { onClose } = props;
  const { appData, actions } = useApp();
  const [modalData, setData] = useModalData();

  const [availNetworks, setNetworks] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [chain, setChain] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  useEffect(() => {
    const availNetworks = actions.getTokenNetworks(appData.activeAsset);
    // .then((availNetworks) => {
    //   console.log(
    //     "🚀 ~ file: ModalSendAssetFunctions.js ~ line 42 ~ useEffect ~ availNetworks",
    //     availNetworks
    //   );
    //
    setNetworks(availNetworks);
    setSelectedNetwork(availNetworks[0]);
    // });
    // setSendToken( modalData.sendToken );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chainChanged = (activeChain) => {
    const chain = appData?.chains?.filter((chain) => {
      if (appData?.activeAssetChain === "eth") {
        return chain.chain_name === "ethereum";
      } else {
        return chain.chain_name === appData?.activeAssetChain;
      }
    })[0];
    // appData.chains.find(
    //   (e) => e.name === activeChain.network_name
    // );

    // const token = getTokenOnChain(props.token?.symbol, chain.chainId);
    // setSendToken( token );
    setChain(chain);
    actions.setAppData({ activeWalletChain: chain });
  };
  // const getTokenOnChain = (tokenSymbol, chainId) => {
  //   const chosenChain = chainIdToNetwork[chainId];
  //   const keys = Object.keys(appData.assets);
  //   const tok = Object.values(appData.assets)
  //     .map((e, idx) => {
  //       return { ...e, address: keys[idx] };
  //     })
  //     .find((e) => e.symbol === tokenSymbol && e.chain === chosenChain);

  //   return tok;
  // };

  const onBuy = () => {
    onClose();

    const settings = {
      apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
      environment: process.env.REACT_APP_TRANSAK_ENVIRONMENT,
      defaultCryptoCurrency: appData.activeAsset,
      themeColor: "000000", // App theme color
      defaultFiatCurrency:
        appData.activeCurrency && appData.activeCurrency["Currency Code"],
      hostURL: window.location.origin,
      widgetHeight: "550px",
      widgetWidth: "450px",
      network: selectedNetwork,
      walletAddress: walletAddress.wallet_address,
    };

    const transak = new transakSDK(settings);
    transak.init();

    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, async (orderData) => {
      const url = process.env.REACT_APP_RAMP_SERVICE;

      await fetch(url + "/orders", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + Storage.load("user").token,
        },
        body: JSON.stringify(orderData.status),
      }).then((e) => e.json());
      transak.close();
    });
  };
  // const props = attrs;
  // const extraClass = props.extraClass;

  return (
    <MainModal
      extraModalContentClasses={["modal_w620"]}
      extraClasses={[
        "modal_w620",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_success_msg",
      ]}
      onClose={onClose}
      dataClass="modal_buy_asset_transk"
    >
      <div className="modal_succes_content cursor_pointer">
        <div className="modal_succes_head mt-9 text-5xl font-medium">
          {props.title}
        </div>
        <div
          className={`flex row ${
            modalData?.symbol !== "BTC" ? "justify-between" : "justify-center"
          } align-center mt-12 pt-6 gap-x-2`}
        >
          {modalData?.symbol !== "BTC" && (
            <div className="basis-2/4">
              <DropdownNetworkNew
                extraClass=" min-w-full  extra_height"
                noBlockScroll={false}
                showLabel={false}
                networks={availNetworks}
                onSelect={(e) => chainChanged(e)}
              />
            </div>
          )}
          <div className="basis-2/4">
            {/* <WalletSelector   onSelect={setWalletAddress}/> */}
            <DropdownWalletNew
              extraClass="min-w-full items-start h-[6.5rem] dropdown_wallet_assets"
              compType="buyAssets"
              icon="images/wallet-icon.svg"
              noBlockScroll={false}
              onSelect={setWalletAddress}
              symbol={modalData?.symbol}
              //onSelect={addressChanged}
            />
          </div>
        </div>
        <div className="modal_succes_desc my-12 flex justify-center">
          <img src="images/transak.png" alt="" onClick={onBuy} />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalAssetPurchase;
