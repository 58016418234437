import TooltipCopy from '../../registration/TooltipCopy';

const TooltipIconSvg = () => {
    return <img className="icon_copy_field icon-content-copy-black-24-dp" src="images/wallets/copy-icon.svg" alt="" />
}

const PublicAddressGenerated = ({address=''}) => {
    return (      
        <div className="modal_wallet_import-wrp">
            <div className="wallet_import_used">
                <span className="gas_used_label">Public Address:</span>
            </div>
            <div className="input_text_copy">
                <span className="input_copy"><span className="to_copy qty_short">{address}</span></span>
                <TooltipCopy 
                    extraClasses='tooltip_registration cursor-pointer tooltip_webapp_copy private_key_copy' 
                    imageToDisplay={<TooltipIconSvg />} 
                    tooltipTextIn={'Copy to clipboard'} 
                    tooltipTextInChange={'Copied to clipboard'} 
                    copiedText={address}  
                    hideAfter="3000"
                />
                {/* share button */}
                {/* <div className="share_wallet_address">
                    <img src="images/share-icon.svg" alt="Share your public address" />
                </div> */}
            </div>
        </div>     
    );
  };
  
  export default PublicAddressGenerated;