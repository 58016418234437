import React from 'react';
import MainModal from '../generic/MainModal';

const ModalCancelProcess = ( { onClose, goBackward, body, icon } ) => {

    return (
        <MainModal
            onClose={() => goBackward()}
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet_generate_cancel']}
            dataClass="modal_set_wallet_label" >
            {/* Modal content */}
            <div className="modal_head">
                <img src={icon ? icon : "images/wallets/icon_delete.svg"} alt="" className='modal_icon' />
                <div className="modal_title">{body}</div>
            </div>

            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered" onClick={() => onClose()}>Yes</button>
                    <button className="btn-default btn-blue" onClick={() => goBackward()}>No</button>
                </div>
            </div>
        </MainModal>
    )
};

export default ModalCancelProcess;