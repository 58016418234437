import { useState } from "react";
import { useApp } from "../../../helpers/AppProvider";

const FiatCurrenciesItem = ({
  keyProp,
  icon,
  currencyName,
  currencyPrefix,
  hasFlag,
  selectCurrency = false,
}) => {
  const { appData } = useApp();
  const [currencyType, setCurrencyType] = useState("");
  const [inputActive, setInputActive] = useState(false);

  const handleChange = () => {
    setCurrencyType(currencyName);
    !inputActive ? setInputActive(true) : setInputActive(false);
    selectCurrency && selectCurrency(currencyName);
  };

  return (
    <div
      key={keyProp}
      onClick={() => handleChange()}
      className="select__currency form__input_filter_item "
      data-name={currencyName}
    >
      <div className="select__currency-input">
        <input
          className="modal_generate_new_wallet_checkbox"
          checked={
            appData.activeCurrency
              ? appData.activeCurrency["Currency Name"] === currencyName
              : false
          }
          onChange={() => setCurrencyType(currencyName)}
          value={currencyType}
          type="radio"
          name="radio"
        />
      </div>
      <div className="select__currency-image ">
        <div
          className="select__currency-image-icon"
          style={{
            backgroundImage: icon,
            //filter: hasFlag ? 'none' : 'grayscale(1)',
            //opacity: hasFlag ? '1' : '0.6'
          }}
        />
      </div>
      <div className="select__currency-details">
        <div className="select__currency-name">{currencyName}</div>
        <div className="select__currency_prefix">{currencyPrefix}</div>
      </div>
    </div>
  );
};

export default FiatCurrenciesItem;
