const QRCode = ({qrImage}) => {

    return (
        <div className="modal_qr_code">
            {/* <img src="images/qr-code.svg" alt="" /> */}
            <img className='' style={{width: '200px', height: "auto"}} src={qrImage} alt="scan this qr code" />
        </div>
    )
}

export default QRCode;
