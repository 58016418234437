import { useApp } from "./../../../helpers/AppProvider";
import React, { useState, useEffect, useRef } from "react";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  BUY_ASSET_FLOW,
  SEND_ASSET_FLOW,
  RECEIVED_ASSET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import Storage from "../../../helpers/Storage";

const AssetsActionsHistory = ({
  openBuyTransakModal,
  setActiveAsset,
  isSupportedCurrency,
  isCurrencySupportsChain,
  openSendAssetModal,
  openReceiveAssetModal,
}) => {
  return (
    <div className="assets__overview_row-actions w-[100%]">
      <button
        onClick={(e) => {
          setActiveAsset();
          openBuyTransakModal(e);
        }}
        disabled={!Boolean(isSupportedCurrency) || !isCurrencySupportsChain}
        className="btn-default btn-gray btn-gray-border  modal__trigger"
        data-target="modal_buy_asset_transk"
      >
        Buy
      </button>
      <button
        onClick={(e) => {
          setActiveAsset();
          openSendAssetModal(e);
        }}
        disabled={!isCurrencySupportsChain}
        className="btn-default btn-gray btn-gray-border modal__trigger"
        data-target="modal_asset_send"
      >
        Send
      </button>
      <button
        onClick={(e) => {
          setActiveAsset();
          openReceiveAssetModal(e);
        }}
        disabled={!isCurrencySupportsChain}
        className="btn-default btn-gray btn-gray-border modal__trigger"
        data-target="modal_receive_asset"
      >
        Receive
      </button>
    </div>
  );
};

export default AssetsActionsHistory;
