import moment from "moment";
import React, { useEffect, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import Storage from "../../../helpers/Storage";
import VaultListHeader from "../../VaultLists/VaultListHeader";
import VaultlogsList from "../../VaultLists/VaultlogsList";

const VaultLogs = ({ tab }) => {
  const { actions } = useApp();
  const token = Storage.load("user")?.token;
  const [logs, setLogs] = useState();

  useEffect(() => {
    getVaultLogs();
  }, []);

  const getVaultLogs = async () => {
    actions.setLoading(true, "Getting Vault Logs");
    try {
      const vaultLogsRes = await actions.getVaultLogsHistory(token);
      if (vaultLogsRes?.statusCode === 400) {
        vaultLogsRes?.error[0]?.name === "userId"
          ? alert(vaultLogsRes?.error[0]?.message)
          : alert(vaultLogsRes?.error[0]?.message);
      } else if (vaultLogsRes?.statusCode === 200) {
        const logData = vaultLogsRes?.doc?.map((i, index) => {
          return {
            timestamp: i?.timestamp,
            //moment.unix(i?.timestamp).format("DD-MM-YYYY, hh:mm"),
            activity: i?.activity,
            action: i?.action,
            address: i?.address,
            platform: i?.platform,
            Where: i?.storage.join(","),
          };
        });
        logData.sort((a, b) => b.timestamp - a.timestamp);
        setLogs(logData);
      }
    } catch (e) {
    } finally {
      actions.setLoading(false);
    }
  };

  return (
    <div>
      <div className="assets__overview_content_tab content_wallets active">
        <div className="activity__asset-filter-wrapper">
          <div className="filter_table_body wallets_items_ctn vault_active_wallet">
            <table width={"100%"}>
              <VaultListHeader tab={tab} />
              {
                <tbody>
                  {logs &&
                    logs?.map((item, index) => {
                      return (
                        <VaultlogsList
                          key={index.toString()}
                          item={item}
                          index={index}
                          // openEnterPinModal={openEnterPinModal}
                          // openEditLabelModal={openEditLabelModal}
                          // openQRModal={openQRModal}
                        />
                      );
                    })}
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VaultLogs;
