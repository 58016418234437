import AssetsBalanceList from "./../AssetsBalanceList/AssetsBalanceList";
// import AssetsPortfolioList from "./../AssetsPortfolioList/AssetsPortfolioList";
import NFTBalanceList from "./../NFTBalanceList/NFTBalanceList";
import RecentActivity from "./../RecentActivity/RecentActivity";
import TokenStackedGraph from "./../TokenStackedGraph/TokenStackedGraph";

import React, { useState, useEffect } from "react";
import { useApp } from "./../../../helpers/AppProvider";
// import { useUser } from "./../../../helpers/UserProvider";
import { useLocation } from "react-router-dom";
import { formatMoney } from "../../../helpers/walletHelpers";

// let len = 0;

const DashboardHome = ({ refresh, refreshDate }) => {
  // const { userActions, userData } = useUser();
  const { appData, actions } = useApp();
  const [timePassed, setTimePassed] = useState();
  const [walletTotalBalance, setWalletTotalBalance] = useState();

  const [activeMobileContent, setActiveMobileContent] = useState("nfts");

  const location = useLocation();
  const currency = actions.getActiveCurrencySymbol();

  useEffect(() => {
    if (location?.state) {
      actions.setAppData({ activeWallet: location.state.activeWallet });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.activeWallet]);

  //on click reset to all assets
  const resetToAllAssets = (event) => {
    actions.setAppData({ activeWallet: null });
  };

  const getTimePassedFromTimestamp = (oldTimestamp) => {
    const updateTimePassed = () => {
      const now = Date.now();
      const timeDifferenceInSeconds = Math.floor((now - oldTimestamp) / 1000);

      if (timeDifferenceInSeconds < 60) {
        return "Just now";
      } else if (timeDifferenceInSeconds < 3600) {
        const minutesPassed = Math.floor(timeDifferenceInSeconds / 60);
        return `${minutesPassed} minute${minutesPassed > 1 ? "s" : ""} ago`;
      } else if (timeDifferenceInSeconds < 86400) {
        const hoursPassed = Math.floor(timeDifferenceInSeconds / 3600);
        return `${hoursPassed} hour${hoursPassed > 1 ? "s" : ""} ago`;
      } else {
        const daysPassed = Math.floor(timeDifferenceInSeconds / 86400);
        return `${daysPassed} day${daysPassed > 1 ? "s" : ""} ago`;
      }
    };

    const updateTime = () => {
      const timePass = updateTimePassed();
      setTimePassed(timePass); // You can update the UI here with the new timePassed value
    };

    updateTime(); // Initial display
    setInterval(updateTime, 60000); // Update time every 1 minute (60000 milliseconds)
  };
  // useEffect( () => {
  //   const sum = appData.walletAssets.reduce( (acc,item)=>{
  //     acc+= item.balance;
  //     return acc;
  //   }, 0.00 );
  //   if( sum !==len ){
  //     len = sum;
  //
  //     // for( var i in appData.wallets ){
  //     //
  //     // }
  //   }

  // }, [ appData ]);

  useEffect(() => {
    if (refreshDate) {
      getTimePassedFromTimestamp(refreshDate);
    }
  }, [refreshDate]);

  useEffect(() => {
    if (!appData?.priceData || !appData?.portfolioData) {
      return;
    }
    let unfilteredAssets;
    if (appData?.activeWallet) {
      const AssetsInWallet = appData?.portfolioData[
        appData?.activeWallet
      ]?.activeChains
        ?.filter((chain) => chain !== "avalanche")
        ?.map((chain) => {
          let AssetsInChainInWallet;
          const accountType = appData?.accounts?.find(
            (account) => account?.address === appData?.activeWallet
          )?.type;
          if (accountType === "evm" && chain !== "bitcoin") {
            AssetsInChainInWallet = [
              ...appData?.portfolioData[appData?.activeWallet][chain]?.tokens,
            ];
          }
          AssetsInChainInWallet =
            AssetsInChainInWallet?.length > 0
              ? AssetsInChainInWallet.map((obj) => {
                  return {
                    ...obj,
                    chain: chain,
                    value: parseFloat(
                      appData?.priceData?.filter(
                        (item) => item?.symbol === obj?.symbol
                      )[0]?.price *
                        (parseFloat(obj?.balance) / Math.pow(10, obj?.decimals))
                    ),
                    // ?.toFixed(2),
                    // ?.replace(/\.?0+$/, ""),
                  };
                })
              : [];
          AssetsInChainInWallet.push({
            token_address: null,
            symbol:
              appData?.portfolioData[appData?.activeWallet][chain]?.symbol,
            name: appData?.portfolioData[appData?.activeWallet][chain]
              ?.nativeCurrency,
            logo: appData?.portfolioData[appData?.activeWallet][chain]
              ?.imageURL,
            thumbnail: null,
            decimals: null,
            balance:
              appData?.portfolioData[appData?.activeWallet][chain]?.balance,
            possible_spam: false,
            chain: chain,
            value: parseFloat(
              appData?.priceData?.filter(
                (item) =>
                  item?.symbol ===
                  appData?.portfolioData[appData?.activeWallet][chain]?.symbol
              )[0]?.price *
                parseFloat(
                  appData?.portfolioData[appData?.activeWallet][chain]?.balance
                )
            ),
            // ?.toFixed(2),
            // ?.replace(/\.?0+$/, ""),
          });
          return AssetsInChainInWallet;
        });
      unfilteredAssets = AssetsInWallet?.reduce((acc, obj) => {
        return acc?.concat(obj);
      }, []);
      // eslint-disable-next-line array-callback-return
      unfilteredAssets = unfilteredAssets?.filter((item) => {
        if (!isNaN(item?.value)) {
          return {
            ...item,
            description: parseFloat(item?.value)?.toFixed(2),
          };
        }
      });
    } else {
      const AssetsInWallets = appData?.accounts?.map((account) => {
        const AssetsInWallet = appData?.portfolioData[
          account?.address
        ]?.activeChains
          ?.filter((chain) => chain !== "avalanche")
          ?.map((chain) => {
            let AssetsInChainInWallet;
            if (chain !== "bitcoin" && account.type === "evm") {
              AssetsInChainInWallet = [
                ...appData?.portfolioData[account?.address][chain]?.tokens,
              ];
            }
            AssetsInChainInWallet =
              AssetsInChainInWallet?.length > 0
                ? AssetsInChainInWallet.map((obj) => {
                    return {
                      ...obj,
                      chain: chain,
                      value: parseFloat(
                        appData?.priceData?.filter(
                          (item) => item?.symbol === obj?.symbol
                        )[0]?.price *
                          (parseFloat(obj?.balance) /
                            Math.pow(10, obj?.decimals))
                      ),
                      // ?.toFixed(2),
                      // ?.replace(/\.?0+$/, ""),
                    };
                  })
                : [];
            AssetsInChainInWallet.push({
              token_address: null,
              symbol: appData?.portfolioData[account?.address][chain]?.symbol,
              name: appData?.portfolioData[account?.address][chain]
                ?.nativeCurrency,
              logo: appData?.portfolioData[account?.address][chain]?.imageURL,
              thumbnail: null,
              decimals: null,
              balance: appData?.portfolioData[account?.address][chain]?.balance,
              possible_spam: false,
              chain: chain,
              value: parseFloat(
                appData?.priceData?.filter(
                  (item) =>
                    item?.symbol ===
                    appData?.portfolioData[account?.address][chain]?.symbol
                )[0]?.price *
                  parseFloat(
                    appData?.portfolioData[account?.address][chain]?.balance
                  )
              ),
              // ?.toFixed(2),
              // ?.replace(/\.?0+$/, ""),
            });
            return AssetsInChainInWallet;
          });
        return AssetsInWallet?.reduce((acc, obj) => {
          return acc?.concat(obj);
        }, []);
      });

      unfilteredAssets =
        AssetsInWallets?.length > 0
          ? AssetsInWallets?.reduce((acc, obj) => {
              return acc?.concat(obj);
            })
          : [];

      unfilteredAssets =
        unfilteredAssets?.length > 0
          ? Object.values(
              unfilteredAssets.reduce((acc, token) => {
                const key = `${token?.name}-${token?.symbol}-${token?.chain}`;
                if (!acc[key]) acc[key] = { ...token };
                else {
                  acc[key].balance = (
                    parseFloat(acc[key]?.balance) + parseFloat(token?.balance)
                  ).toString();
                  acc[key].value =
                    parseFloat(acc[key]?.value) + parseFloat(token?.value);
                }
                return acc;
              }, {})
            ).map((obj) => ({ ...obj }))
          : [];
    }
    unfilteredAssets = unfilteredAssets?.filter((item) => !item?.possible_spam);

    let totalBalance = 0;
    for (let i = 0; i < unfilteredAssets?.length; i++) {
      totalBalance += isNaN(unfilteredAssets[i]?.value)
        ? 0.0
        : parseFloat(unfilteredAssets[i]?.value);
    }
    setWalletTotalBalance(totalBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.priceData, appData.portfolioData, appData.activeWallet]);

  return (
    <div className="dashboard__overview-wrp ">
      <div className="dashboard_all_assets-wrp">
        {appData.accounts
          ? appData.accounts.length > 0 && (
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row align-start">
                  <button
                    className={
                      "btn-default btn-reset " +
                      (appData.activeWallet === null ? "btn-disabled" : "")
                    }
                    onClick={resetToAllAssets}
                  >
                    ALL ASSETS
                  </button>
                  <div className="dashboard_wallet_info_card_container flex justify-center items-center  ml-12 border-l-2 ">
                    <span className="icon_info icon-info-circle-solid ml-12"></span>

                    <div className="dashboard_wallet_info_card px-4 py-3 ml-5">
                      Click on a wallet card to see details below
                    </div>
                  </div>
                </div>
                <div className="pl-[10px] flex flex-row items-center">
                  {timePassed && (
                    <div className="dashboard_wallet_info_card_container flex justify-center items-center ">
                      <div className="dashboard_wallet_info_card px-4 py-3 ml-5">
                        {/* Refreshed {getTimePassedFromTimestamp(refreshDate)} */}
                        Refreshed {timePassed}
                      </div>
                    </div>
                  )}
                  <div>
                    <button
                      className={
                        "btn-default btn-reset " +
                        (appData?.loading ? "btn-disabled" : "")
                      }
                      onClick={() => refresh(true)}
                    >
                      {appData?.loading ? "LOADING" : "REFRESH"}
                    </button>
                  </div>
                </div>
              </div>
            )
          : null}
      </div>
      <div className="dashboard__overview-content dashboard__overview-desktop flex flex-col">
        <div className="px-[20px] py-[10px] w-[100%] outline outline-1 outline-[#CBD7E9] rounded mb-[20px]">
          <div>TOTAL WORTH</div>
          <div className="flex flex-row items-center py-[8px]">
            <span className="text-[24px]">
              {currency}{" "}
              {formatMoney(
                actions.activeCurrency(walletTotalBalance?.toFixed(2))
              )}
            </span>
            {/* <span className="text-[18px] pl-[10px]"> 12%</span> */}
          </div>
          <div>
            <TokenStackedGraph />
          </div>
        </div>
        <div className="flex flex-row w-[100%]">
          <div className="dashboard__overview-tab dashboard__overview-portfolio w-[60%]">
            {/* <AssetsPortfolioList /> */}
            <NFTBalanceList />
            <AssetsBalanceList />
          </div>
          <div className="dashboard__overview-tab dashboard__overview-recent-activity w-[40%]">
            <RecentActivity />
          </div>
        </div>
      </div>

      <div className="dashboard__overview-mobile">
        <div className="px-[20px] py-[10px] w-[100%] outline outline-1 outline-[#CBD7E9] rounded mb-[20px]">
          <div>TOTAL WORTH</div>
          <div className="flex flex-row items-center py-[8px]">
            <span className="text-[24px]">
              {currency}{" "}
              {formatMoney(
                actions.activeCurrency(walletTotalBalance?.toFixed(2))
              )}
            </span>
            {/* <span className="text-[18px] pl-[10px]"> 12%</span> */}
          </div>
          <div>
            <TokenStackedGraph />
          </div>
        </div>
        <div className="dashboard_mobile_nav">
          <div
            onClick={() => setActiveMobileContent("nfts")}
            className={
              activeMobileContent === "nfts" ? "nav_btn active" : "nav_btn"
            }
          >
            NFTs
          </div>
          <div
            onClick={() => setActiveMobileContent("balance")}
            className={
              activeMobileContent === "balance" ? "nav_btn active" : "nav_btn"
            }
          >
            ASSETS BALANCE
          </div>
          <div
            onClick={() => setActiveMobileContent("activity")}
            className={
              activeMobileContent === "activity" ? "nav_btn active" : "nav_btn"
            }
          >
            ACTIVITY
          </div>
        </div>
        <div className="dashoard__content">
          {activeMobileContent === "nfts" && <NFTBalanceList />}
          {activeMobileContent === "balance" && <AssetsBalanceList />}
          {activeMobileContent === "activity" && <RecentActivity />}
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
