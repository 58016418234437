import SearchInput from "../../settings/SearchInput/SearchInput";
import { useApp } from "../../../helpers/AppProvider";
import { useEffect, useState } from "react";
import NFTCard from "../NFTCard/NFTCard";
import NFTGroup from "../NFTGroup/NFTGroup";
import Storage from "../../../helpers/Storage";

const NFTList = () => {
  const { appData } = useApp();
  const [searchInput, setSearchInput] = useState("");
  const [NFTData, setNFTData] = useState(null);

  useEffect(() => {
    const activeWallets =
      appData?.activeWallet || appData?.accounts[0]?.address;

    let NFTs = [];
    if (!appData.nftData) {
      return;
    }
    if (activeWallets) {
      // NFTs for the wallet
      if (appData?.activeChain) {
        //   // NFTs for the chain for the wallet
        const activeChainName = appData?.chains
          ?.map((chain) => {
            return chain?.chainId === appData?.activeChain
              ? chain?.chain_name === "ethereum"
                ? "eth"
                : chain?.chain_name
              : "";
          })
          ?.reduce((acc, obj) => {
            return acc + obj;
          }, "");
        NFTs = appData?.nftData[activeWallets]?.[activeChainName]?.nfts?.length
          ? appData?.nftData[activeWallets]?.[activeChainName]?.nfts?.map(
              (obj) => {
                return {
                  ...obj,
                  chain: activeChainName,
                  wallet: activeWallets,
                };
              }
            )
          : [];
      } else {
        // NFTs for all chains for the wallet
        const NFTinChain = appData?.nftData[activeWallets]?.activeChains?.map(
          (chain) => {
            return appData.nftData[activeWallets][chain]?.nfts?.length
              ? appData.nftData[activeWallets][chain]?.nfts?.map((obj) => {
                  return { ...obj, chain: chain, wallet: activeWallets };
                })
              : [];
          }
        );
        NFTs = NFTinChain?.reduce((acc, obj) => {
          return acc.concat(obj);
        }, []);
      }
    }

    setNFTData(NFTs);
  }, [
    appData?.accounts,
    appData?.nftData,
    appData?.activeWallet,
    appData?.activeChain,
  ]);

  const searchHandler = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div
      className="assets__overview_content assets__overview_content_nft"
      style={{ height: "100%" }}
    >
      <div
        className="assets__overview_content_tab content_tab-nft active"
        data-view="all__nft"
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        {/* {process.env.REACT_APP_HIDE_SWAPS === "true" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 100,
              position: "absolute",
              left: "0",
              top: "0",
            }}
          >
            <div className="settings__overview_guardian_setup">
              <span className="msbico-planet_guardian" />
              <span className="coming_soon">Coming Soon</span>
              <div className="coming_soon_msg">
                We will notify you once this feature is ready 🙌
              </div>
            </div>
          </div>
        )} */}

        {/* {'use class nft-inner-empty if no items'} */}
        {NFTData && NFTData?.length > 0 ? (
          <div className="content_tab-nft-inner">
            <div className="content_tab-nft-inner-empty dn">
              <div className="nft__item-empty">
                <div className="nft__item-empty-img">
                  <img src="images/no-files.svg" alt="" />
                </div>
                <div className="nft__item-empty-desc">
                  <span className="big">Nothing yet</span>
                  <span className="small">Collectibles will appear here</span>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "33px 33px",
                borderBottom: "3px solid #CBD7E9",
              }}
            >
              <div style={{ fontSize: 18, fontWeight: 600 }}>
                Explore Collection
              </div>
              <div style={{ minWidth: "30%" }}>
                <SearchInput
                  title="Search item, collection, art etc"
                  search={searchInput}
                  handleFilter={searchHandler}
                />
              </div>
            </div> */}
            <div
              className="m-9 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-8 "
              style={{ maxWidth: "100%" }}
            >
              {NFTData &&
                Object.keys(NFTData)
                  ?.filter((nft) => {
                    return searchInput === ""
                      ? nft
                      : nft.toLowerCase().includes(searchInput.toLowerCase());
                  })
                  .map((key, index) => (
                    <NFTCard name={key} items={NFTData[key]} />
                  ))}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-col h-full">
            <div className="mb-3">
              <img src={"images/nft/nft_empty.svg"} alt="" />
            </div>
            <span className="font-medium text-3xl">Nothing yet</span>
            <span className="text-slate-400">
              Collectibles will appear here
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTList;
