import FilterAssetDropdown from "../FilterAssetDropdown/FilterAssetDropdown";
import FilterAssetToggle from "../FilterAssetToggle/FilterAssetToggle";
import DropdownFilter from "../../common/DropdownFilter/DropdownFilter";

// FILTER ITEMS objects
const sortBy = [
  {
    key: "",
    sort_name: "Sort By",
  },
  {
    key: "name",
    sort_name: "Name",
  },
  {
    key: "price",
    sort_name: "Price",
  },

  {
    key: "market_cap",
    sort_name: "Market Cap",
  },
  {
    key: "volume_24h",
    sort_name: "Volume 24h",
  },
  {
    key: "circulating_supply",
    sort_name: "Circulating Supply",
  },
];

const filterCurrency = [
  {
    // key : 1,
    sort_name: "USD",
    currency_symbol: "$",
  },
  {
    // key : 2,
    sort_name: "EUR",
    currency_symbol: "$",
  },
  {
    // key : 3,
    sort_name: "GBP",
    currency_symbol: "$",
  },
  {
    // key : 4,
    sort_name: "INR",
    currency_symbol: "$",
  },
  {
    // key : 5,
    sort_name: "BRL",
    currency_symbol: "$",
  },
  {
    // key : 6,
    sort_name: "CAD",
    currency_symbol: "$",
  },
  {
    // key : 1,
    sort_name: "USD",
    currency_symbol: "$",
  },
  {
    // key : 2,
    sort_name: "EUR",
    currency_symbol: "$",
  },
  {
    // key : 3,
    sort_name: "GBP",
    currency_symbol: "$",
  },
  {
    // key : 4,
    sort_name: "INR",
    currency_symbol: "$",
  },
  {
    // key : 5,
    sort_name: "BRL",
    currency_symbol: "$",
  },
  {
    // key : 6,
    sort_name: "CAD",
    currency_symbol: "$",
  },

  {
    // key : 1,
    sort_name: "USD",
    currency_symbol: "$",
  },
  {
    // key : 2,
    sort_name: "EUR",
    currency_symbol: "$",
  },
  {
    // key : 3,
    sort_name: "GBP",
    currency_symbol: "$",
  },
  {
    // key : 4,
    sort_name: "INR",
    currency_symbol: "$",
  },
  {
    // key : 5,
    sort_name: "BRL",
    currency_symbol: "$",
  },
  {
    // key : 6,
    sort_name: "CAD",
    currency_symbol: "$",
  },
];

const filterTimeline = [
  {
    key: "",
    sort_name: "% Change Timeline",
  },
  {
    key: "percent_change_1h",
    sort_name: "Hourly Change",
  },
  {
    key: "percent_change_24h",
    sort_name: "Daily Change",
  },
  {
    key: "percent_change_7d",
    sort_name: "Weekly Change",
  },
  {
    key: "percent_change_30d",
    sort_name: "Monthly Change",
  },
  {
    key: "percent_change_60d",
    sort_name: "2 Months Change",
  },
  {
    key: "percent_change_90d",
    sort_name: "Quarterly Change",
  },
  // {
  //     // key : 4,
  //     sort_name: '24 hour'
  // },
  // {
  //     // key : 5,
  //     sort_name: '7 days'
  // },
  // {
  //     // key : 4,
  //     sort_name: '1 month'
  // },
  // {
  //     // key : 4,
  //     sort_name: '1 year'
  // }
];

const filterCoinTypes = [
  {
    key: "",
    sort_name: "All Cryptocurrencies",
  },

  {
    key: "coin",
    sort_name: "Coins",
  },
  {
    key: "token",
    sort_name: "Tokens",
  },
];

const AssetsFilters = ({
  onSortAction,
  onFilterByTypeAction,
  setFavouriteFilter,
  favouriteFilter,
}) => {
  return (
    <div className="content_tab-assets-sort">
      {/* <FilterAssetToggle
        extraClass="dropdown_filters"
        setFavouriteFilter={setFavouriteFilter}
        favouriteFilter={favouriteFilter}
      /> */}
      {/* <FilterAssetDropdown title="Sort by Market Cap" dropdownItems="" />
            <FilterAssetDropdown title="USD" dropdownItems="currency" />
            <FilterAssetDropdown title="% (1h)" dropdownItems="time" />
            <FilterAssetDropdown title="All Cryptocurrencies" dropdownItems="crypto" /> */}
      {/* <DropdownFilter compType='currency' showSymbol={false} items={filterCurrency} extraClass='controls__dropdown_black controls__dropdown_white disp_inline dropdown_currency_input dropdown_filters soon'/> */}
      <DropdownFilter
        title={"Sort By"}
        items={sortBy}
        extraClass="controls__dropdown_black controls__dropdown_white disp_inline min_width_150 dropdown_filters"
        onSelectAction={onSortAction}
      />

      <DropdownFilter
        title={"% Change Timeline"}
        items={filterTimeline}
        extraClass="controls__dropdown_black controls__dropdown_white disp_inline dropdown_filters"
        onSelectAction={onSortAction}
      />
      <DropdownFilter
        title={"Asset Type"}
        items={filterCoinTypes}
        extraClass="controls__dropdown_black controls__dropdown_white disp_inline min_width_150 dropdown_filters"
        onSelectAction={onFilterByTypeAction}
      />
    </div>
  );
};
export default AssetsFilters;
