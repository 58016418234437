import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import PrintedSeedPhraseModel from '../PrintedSeedPhraseModel/PrintedSeedPhraseModel';

const PrintSeedPhraseButton = (props) => {
    
    let componentRef = useRef();

    return (
        <div className={props.cssClasses}>
            <ReactToPrint 
                trigger={() => <img src="images/settings/print-icon.svg" alt="print seed phrase" />}
                content={() => componentRef}
                documentTitle='Safle Seed Phrase Backup'
                pageStyle='print'
            />
            <span className="tooltiptext">
                {props.tooltipText}
            </span>
            <div style={{ display: "none" }}>
                <PrintedSeedPhraseModel seedPhrase={props.seedPhrase} address={props.address} ref={(el) => (componentRef = el)} />
            </div>
        </div>
    );
};

export default PrintSeedPhraseButton;