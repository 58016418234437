import { useState, useEffect } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import AssetsTabsOverview from "../components/assets/AssetsTabsOverview/AssetsTabsOverview";
import AllAssetsList from "../components/assets/AllAssetsList/AllAssetsList";
import BuyTransakProcess from "../components/common/BuyTransakProcess/BuyTransakProcess";
import { useApp } from "../helpers/AppProvider";
import { useUser } from "../helpers/UserProvider";

import AssetsSendAssetProcess from "../components/common/AssetsSendAssetProcess/AssetsSendAssetProcess";
import AssetsBuyAssetProcess from "../components/common/AssetsBuyAssetProcess/AssetsBuyAssetProcess";
import AssetsReceiveAssetProcess from "../components/common/AssetsReceiveAssetProcess/AssetsReceiveAssetProcess";

const AssetsScreen = () => {
  const { appData, actions } = useApp();
  const { userActions } = useUser();
  const [sidebarActive, setSidebarActive] = useState(false);
  useEffect(() => {
    if (appData?.regStatus === "complete") {
      userActions.getUserById();
      actions.getTransakSupportedCurrencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.regStatus]);

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {/* <!-- main content --> */}
        <div className="main__content__wrp assets__overview-wrapper">
          <div className="assets__overview" data-to_show="all_assets">
            <AssetsTabsOverview btn="asset" />
            <AllAssetsList />
          </div>
        </div>
        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        {/* <!-- end aside notification --> */}
      </div>
      {sidebarActive && <div className="mobile_blur"></div>}
      <AssetsBuyAssetProcess />
      <AssetsSendAssetProcess />
      <AssetsReceiveAssetProcess />
      <BuyTransakProcess />
    </div>
  );
};

export default AssetsScreen;
