import React from "react";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import InputGroup from "../settings/InputGroup/InputGroup";

const ModalEditNameMobile = ({
  onClose,
  dataClass,
  confirmChangeName,
  setEditUserNameMobile,
  tempUserName,
  setTempUserName,
  primaryBtnText = "Cancel",
  secBtnText = "Confirm",
  goBackward,
}) => {
  //
  const onChange = (e) => {
    const value = e.target.value;
    setTempUserName(value);
  };
  return (
    <MainModal
      // onClose={onClose}
      onClose={() => setEditUserNameMobile(false)}
      extraClasses={[
        "modal_w400",
        "modal_dflex",
        "modal_settings_edit_user_mobile",
      ]}
      dataClass={dataClass}
    >
      <div className="modal_title">Edit your name</div>

      <div className="input_ctn">
        <div className="input_label">New Name</div>
        <InputGroup
          value={tempUserName}
          onChange={(e) => onChange(e)}
          name={"settings_change_name_mobile"}
          placeholder="Enter New Name"
        />
      </div>

      <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
        <PrimaryBtn
          classes={"btn-default btn-white-bordered close_modal_cancel"}
          label={primaryBtnText}
          onClose={() => setEditUserNameMobile(false)}
        />
        <PrimaryBtn
          classes={"btn-default btn-blue modal__trigger"}
          label={secBtnText}
          onClick={() => confirmChangeName()}
        />
      </div>
    </MainModal>
  );
};

export default ModalEditNameMobile;
