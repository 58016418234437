const WalletAreaPreload = () => {
  return (
    <div className="dashboard__overview-wallets version-two preloader">
      <div className="wallet_selected">
        <div className="dashboard__overview_card">
          <div className="card_wallet-network">
            <div
              className="card_wallet-network-image cycle"
              data-anim-offset="1"
            >
              <div className="card_wallet-network-image-icon"></div>
            </div>
            <div
              className="card_wallet-network-details cycle"
              data-anim-offset="2"
            >
              <div className="card_wallet-network-name">
                <span className="title-box cycle" data-anim-offset="3"></span>
                <div className="card_wallet-network-actions">
                  <div className="edit" data-button="true"></div>
                  <div className="details" data-button="true">
                    <div
                      className="details_relative"
                      style={{ pointerEvents: "none" }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="card_wallet-network-address">
                <span className="addr-box cycle" data-anim-offset="4"></span>
              </div>
            </div>
          </div>
          <div className="card_wallet-asset">
            <div
              className="card_wallet-asset-worth-wrp cycle"
              data-anim-offset="1"
            ></div>
            <div
              className="card_wallet-asset-worth-nr cycle"
              data-anim-offset="2"
            ></div>
          </div>
          <div className="card_wallet-details">
            <div className="card_wallet-info-box number_tokens ">
              <div className="info-box">
                <div className="info-box-icon "></div>
                <div className="info-box-count">
                  <span className="count"></span>
                  <span className="text paddings"></span>
                </div>
              </div>
            </div>

            <div className="card_wallet-info-box number_nft ">
              <div className="info-box">
                <div className="info-box-icon"></div>
                <div className="info-box-count ">
                  <span className="count"></span>
                  <span className="text paddings"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard__overview-wallets-listing">
        <div
          className="dashboard__overview-wallets-listing-left"
          id="listing_cards_left"
        >
          <span className="icon-chevron-down-solid-1"></span>
        </div>
        <div
          className="dashboard__overview-wallets-listing-right"
          id="listing_cards_right"
        >
          <span className="icon-chevron-down-solid-1"></span>
        </div>

        <div className="dashboard__overview-wallets-listing-scrollable">
          {new Array(6).fill("").map((item, index) => (
            <div className="dashboard__overview_card" key={index}>
              <div className="card_wallet-network">
                <div
                  className="card_wallet-network-image cycle"
                  data-anim-offset="3"
                >
                  <div className="card_wallet-network-image-icon"></div>
                </div>
                <div
                  className="card_wallet-network-details cycle"
                  data-anim-offset="4"
                >
                  <div className="card_wallet-network-name">
                    <span className="title-box"></span>
                    <div
                      className="card_wallet-network-actions cycle"
                      data-anim-offset="1.5"
                    >
                      <div className="edit" data-button="true"></div>
                      <div
                        className="details cycle"
                        data-button="true"
                        data-anim-offset="2.5"
                      >
                        <div
                          className="details_relative"
                          style={{ pointerEvents: "none" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="card_wallet-network-address">
                    <span
                      className="addr-box cycle"
                      data-anim-offset="2"
                    ></span>
                  </div>
                </div>
              </div>
              <div className="card_wallet-asset">
                <div
                  className="card_wallet-asset-worth-wrp cycle"
                  data-anim-offset="3"
                ></div>
                <div
                  className="card_wallet-asset-worth-ln1 cycle"
                  data-anim-offset="4"
                ></div>
                <div
                  className="card_wallet-asset-worth-ln2 cycle"
                  data-anim-offset="1"
                ></div>
              </div>
              <div className="card_wallet-details">
                <div
                  className="card_wallet-info-box number_tokens cycle"
                  data-anim-offset="2"
                >
                  <div className="info-box">
                    <div className="info-box-icon "></div>
                    <div className="info-box-count">
                      <span className="count"></span>
                      <span className="text paddings"></span>
                    </div>
                  </div>
                </div>

                <div
                  className="card_wallet-info-box number_nft cycle"
                  data-anim-offset="3"
                >
                  <div className="info-box">
                    <div className="info-box-icon"></div>
                    <div className="info-box-count ">
                      <span className="count"></span>
                      <span className="text paddings"></span>
                    </div>
                  </div>
                </div>
                <div
                  className="card_wallet-info-box number_chain cycle"
                  data-anim-offset="4"
                >
                  <div className="info-box">
                    <div className="info-box-icon "></div>
                    <div className="info-box-count ">
                      <span className="count"> </span>
                      <span className="text paddings"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WalletAreaPreload;
