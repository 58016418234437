import React, { useState, useCallback, useEffect } from "react";
import ReactSlider from "react-slider";
import { useApp } from "../../helpers/AppProvider";

const SlippageRangeInput = () => {
  const { appData, actions } = useApp();
  const [customVisible, setCustomVisible] = useState(false);
  const [rangeValue, setRangeValue] = useState(appData.swapSlippage);
  const [conversionRate, setConversionRate] = useState(0)

  const handleChange = useCallback(
    (e) => {
      setRangeValue(e.target.value);
      actions.setAppData({ swapSlippage: e.target.value });
    },
    [actions]
  );

  useEffect(() => {
    const fromTokenData = appData.activeTokenA && appData.supportedTokens && appData.supportedTokens.filter(
      (token) => token.address.toLowerCase() === appData.activeTokenA.toLowerCase()
    );
    const toTokenData = appData.activeTokenB && appData.supportedTokens && appData.supportedTokens.filter(
      (token) => token.address.toLowerCase() === appData.activeTokenB.toLowerCase()
    );

    if(fromTokenData && fromTokenData.length && toTokenData && toTokenData.length)
      setConversionRate("1.00 " + fromTokenData[0].symbol + " = " + Math.round(appData.exchangeRate * 100) / 100 + " " + toTokenData[0].symbol);
    else
      setConversionRate("Please select tokens.");
  },[appData.activeTokenA, 
    appData.activeTokenB, 
    appData.supportedTokens, 
    appData.exchangeRate])

  return (
    <>
      <div className="modal_slipage flex items-start justify-between">
        <div className="modal_slipage_max flex items-center justify-between">
          <div className="modal_fee_price">Maximum Slipage: {" "} <span>
          {rangeValue}%
          </span></div>
          
        </div>
        <div className="modal_slipage_max flex items-center justify-between">
          <div className="modal_fee_price">Conversion Rate: {" "} <span> <br/>
          {conversionRate}
          </span></div>
          
        </div>
        {/* <div className="modal_slipage_custom flex items-center justify-between">
          <div className="modal_slipage_custom_label">Custom</div>
          <div className="control__switch-input">
            <label className="control__switch-switch">
              <input
                onClick={() =>
                  customVisible
                    ? setCustomVisible(customVisible)
                    : setCustomVisible(true)
                }
                type="checkbox"
              />
              <span className="slider round" />
            </label>
          </div>
        </div> */}
      </div>

      {/* {customVisible && (
        <div className="swap-slippage-row">
          <div className="swap-splippage-range">
            <ReactSlider
              className="customSlider"
              marks={[10, 20, 30, 40, 50]}
              markClassName="customSlider-mark"
              min={1}
              max={50}
              step={0.5}
              thumbClassName="customSlider-thumb"
              trackClassName="customSlider-track"
              renderThumb={(props, state) => (
                <div {...props}>{state.valueNow}</div>
              )}
              value={rangeValue}
              defaultValue={1}
              onChange={(value) => {
                setRangeValue(value);
                actions.setAppData({ swapSlippage: value });
              }}
              renderMark={(props) => {
                if (props.key < rangeValue) {
                  props.className =
                    "customSlider-mark customSlider-mark-before";
                } else if (props.key === rangeValue) {
                  props.className =
                    "customSlider-mark customSlider-mark-active";
                }
                return <span {...props} />;
              }}
            />
          </div>
        </div>
      )} */}
    </>
  );
};

export default SlippageRangeInput;
