const initialState = {
  userEmail: null,
  encryptionKey: null,
  oldVersionUser: null,
  otpStatus: { email: null, message: null, startedAt: null },
  signupName: "",
  mnemonic: null,
  mnemonicArr: [],
  randomNumbers: null,
  tempPassword: false,
  newPasswordToken: null,
  userVault: null,
  signUpCompleted: null,
  pinValidated: null,
};

export default initialState;
