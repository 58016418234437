import WalletOptionItem from '../WalletOptionItem/WalletOptionItem';
import { walletTypes } from '../../../constants/wallets'

const WalletsOptionsList = (props) => {

    return (
        <div className="form__inner_filter_assets">
            <div className=" form__input_filter_assets import_wallet_generate-wrp">
                {walletTypes.map((item, key) => {
                    return (
                        <>
                            {!item.disabled && <WalletOptionItem key={key}
                                name={item.label}
                                icon={item.icon}
                                abbr={item.code}
                                wallets={item.wallets}
                                callbackOpenPopup={(event) => props.callbackOpenPopup(event, item.code )}
                            />}
                            {item.disabled && <div style={{opacity: 0.4, pointerEvents:'none'}}>
                                <WalletOptionItem 
                                    key={key} 
                                    name={item.label} 
                                    icon={item.icon}
                                    abbr={item.code}
                                    wallets={item.wallets} 
                                    callbackOpenPopup={()=>{}}
                                />
                                </div>}
                        </>
                    )
                })
                }
            </div>
        </div>
    )
}

export default WalletsOptionsList;
