import { useMemo, useState, useEffect } from "react";
import { LiFiWidget, WidgetEvent, useWidgetEvents } from "@lifi/widget";
import Storage from "../../../helpers/Storage";
import { useApp } from "../../../helpers/AppProvider";
import ModalEnterPin from "../../dialogs/ModalEnterPin";
import WalletSelector from "../../swap/WalletSelector/WalletSelector";
import moment from "moment";
const Vault = require("@getsafle/safle-vault");
const Web3 = require("web3");

export const WidgetPage = () => {
  const { appData, actions } = useApp();
  const [openModal, setOpenModal] = useState(false);
  const widgetEvents = useWidgetEvents();
  const [pin, setPin] = useState();
  const [transactions, setTransactions] = useState([]);
  const [activeTransaction, setActiveTranaction] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getTrx() {
      setLoading(true);
      if (appData?.activeWallet) {
        const trxList = await actions.getLiFiTransactions(
          appData?.activeWallet
        );
        setTransactions(
          trxList?.transactions?.sort(
            (a, b) => b?.receiving?.timestamp - a?.receiving?.timestamp
          )
        );
        setLoading(false);
      }
    }

    getTrx();
  }, [appData.activeWallet]);

  useEffect(() => {
    const onReviewTransactionPageEntered = (open) => {
      if (!pin) {
        setOpenModal(true);
      } else {
        setOpenModal(false);
      }
    };

    widgetEvents.on(
      WidgetEvent.ReviewTransactionPageEntered,
      onReviewTransactionPageEntered
    );

    return () => widgetEvents.all.clear();
  }, [widgetEvents]);

  const vault = new Vault({
    vault: Storage.load("user").vault,
    encryptionKey: Storage.load("user")?.decriptionKey
      ? Object.values(Storage.load("user")?.decriptionKey)
      : null,
  });

  const handlePinConfirm = async (pinValue) => {
    if (!pinValue) {
      setOpenModal(true);
    } else {
      setPin(pinValue);
      setOpenModal(false);
    }
  };

  const widgetConfig = useMemo(() => {
    //we are giving custom methods
    vault.getAddress = function () {
      return appData?.activeWallet
        ? appData?.activeWallet
        : appData?.accounts[0]?.address;
    };
    vault.getChainId = function () {
      return appData?.activeChain ? appData?.activeChain : 1; // return selected chain or ethereum
    };
    vault.sendTransaction = async function (params) {
      await vault.restoreKeyringState(
        Storage.load("user").vault,
        parseInt(pin).toString()?.padStart(6, "0"),
        Storage.load("user")?.decriptionKey
          ? Object.values(Storage.load("user")?.decriptionKey)
          : null
      );
      const activeChain = appData?.chains?.find(
        (chain) => chain?.chainId === params?.chainId
      );

      const w3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));
      await vault.changeNetwork(activeChain?.chain_name);
      params.nonce = await w3.eth.getTransactionCount(params.from);

      const signed = await vault.signTransaction(
        params,
        !isNaN(pin)
          ? Number(pin)?.toString()?.padStart(6, "0")
          : pin?.toString()?.padStart(6, "0"),
        activeChain?.uri
      );

      const signedTx = signed?.response;

      const send = await w3.eth.sendSignedTransaction(signedTx);
      setPin("");
      return { hash: send?.transactionHash };
    };
    return {
      walletManagement: {
        signer: vault,
        connect: async () => {
          return vault;
        },
        switchChain: async (reqChainId) => {
          const chainName = appData?.chains?.find(
            (chain) => chain?.chainId === reqChainId
          )?.chain_name;
          vault?.changeNetwork(chainName);
          actions.setAppData({ activeChain: reqChainId });
          vault.getChainId = () => {
            return reqChainId;
          };
          return vault;
        },
        containerStyle: {
          border: "1px solid rgb(234, 234, 234)",
          borderRadius: "16px",
          width: "200px",
        },
      },
      variant: "default",
      subvariant: "split",
      chains: {
        allow: [1, 10, 56, 106, 137, 5000, 42161],
      },
      appearance: "light",
      hiddenUI: ["appearance", "poweredBy", "walletMenu", "history"],
    };
  }, [appData?.activeWallet, appData?.activeChain, pin]);
  return (
    <div className="w-full flex justify-center">
      <div className="p-10">
        <div className="px-4">
          <WalletSelector />
        </div>
        <div className="mt-8 shadow-2xl rounded-lg">
          <LiFiWidget integrator="getSafle" config={widgetConfig} />
        </div>
      </div>
      <div className="my-8 mt-[10.5rem] divide-y shadow-2xl rounded-lg w-[50%] h-[68.3rem]">
        <div className="px-10 py-8 h-[9%]">LI.FI HISTORY</div>
        <div className="divide-y h-[91%] overflow-scroll">
          {transactions?.map((transaction) => {
            return (
              <div className="p-10">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row items-center">
                    <div>
                      <img
                        className="h-[24px] w-[24px]"
                        src={transaction?.sending?.token?.logoURI}
                        alt="arrow-right"
                      />
                    </div>
                    <div className="pl-[1rem] pr-[1rem] font-bold">
                      {(
                        parseInt(transaction?.sending?.amount) /
                        Math.pow(
                          10,
                          parseInt(transaction?.sending?.token?.decimals)
                        )
                      )
                        .toFixed(5)
                        .replace(/\.?0+$/, "")}{" "}
                      {transaction?.sending?.token?.name}
                    </div>
                    <div className=" pr-[1rem]">
                      <img
                        src="/images/swap/swap-arrow-gray.svg"
                        alt="arrow-right"
                      />
                    </div>
                    <div>
                      <img
                        className="h-[24px] w-[24px]"
                        src={transaction?.receiving?.token?.logoURI}
                        alt="arrow-right"
                      />
                    </div>
                    <div className="pl-[1rem] pr-[1rem] font-bold">
                      {(
                        parseInt(transaction?.receiving?.amount) /
                        Math.pow(
                          10,
                          parseInt(transaction?.receiving?.token?.decimals)
                        )
                      )
                        ?.toFixed(5)
                        ?.replace(/\.?0+$/, "")}{" "}
                      {transaction?.receiving?.token?.name}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      transaction?.transactionId === activeTransaction
                        ? setActiveTranaction("")
                        : setActiveTranaction(transaction?.transactionId);
                    }}
                  >
                    {transaction?.transactionId === activeTransaction ? (
                      <div className="origin-center rotate-180">
                        <div className="icon-chevron-down-solid-1 cursor-pointer" />
                      </div>
                    ) : (
                      <div>
                        <div className="icon-chevron-down-solid-1 cursor-pointer" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-col w-[65%]">
                    <div className="pt-[2rem] pb-[1rem] text-[12px] text-[#8690A0] flex flex-row">
                      <div>Wallet: </div>
                      <div className="truncate pl-[0.4rem] text-[#0D203E] break-words">
                        {transaction?.toAddress}
                      </div>
                    </div>
                    {transaction?.transactionId === activeTransaction && (
                      <div className="pb-[1rem] text-[12px] text-[#8690A0] flex flex-row">
                        <div>TxnHash:</div>
                        <div className="pl-[0.4rem] text-[#0D203E] break-words w-[85%]">
                          {transaction?.transactionId}
                        </div>
                      </div>
                    )}
                    {/* {transaction?.transactionId === activeTransaction && (
                      <div>Slippage</div>
                    )} */}
                    {transaction?.transactionId === activeTransaction && (
                      <div
                        className="text-lg text-[#0669F8] cursor-pointer"
                        onClick={() => {
                          window.open(transaction?.lifiExplorerLink, "_blank");
                        }}
                      >
                        Check on blockchain Explorer
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-[35%]">
                    <div className="pt-[1.5rem] pb-[1rem] pl-[1rem] pr-[1rem] text-lg text-[#8690A0] flex flex-row items-center">
                      <div>Type: </div>
                      <div className=" text-[#0669F8] border-0 border-[#0669F8] bg-[#0669F833] px-4 py-1 rounded-3xl">
                        {transaction?.sending?.chainId ===
                        transaction?.receiving?.chainId
                          ? "Swap"
                          : "Bridge"}
                      </div>
                    </div>
                    {transaction?.transactionId === activeTransaction && (
                      <div className="pb-[1rem] pl-[1rem] pr-[1rem] text-lg text-[#8690A0] flex flex-row">
                        <div>Time: </div>
                        <div className="pl-[0.4rem] text-[#0D203E]">
                          {moment
                            .unix(transaction?.receiving?.timestamp)
                            ?.format("MMM D, YYYY h:mm A")}
                        </div>
                      </div>
                    )}
                    {/* {transaction?.transactionId === activeTransaction && (
                      <div>Gas Fees</div>
                    )} */}
                    {transaction?.transactionId === activeTransaction && (
                      <div className="pb-[1rem] pt-[1.5rem] pl-[1rem] text-lg text-[#8690A0] flex flex-row">
                        <div>Network: </div>
                        <div className="pl-[0.4rem] text-[#0D203E]">
                          {transaction?.sending?.chainId ===
                          transaction?.receiving?.chainId
                            ? appData?.chains?.find(
                                (chain) =>
                                  chain?.chainId ===
                                  transaction?.sending?.chainId
                              )?.chain_name === "bsc"
                              ? appData?.chains
                                  ?.find(
                                    (chain) =>
                                      chain?.chainId ===
                                      transaction?.sending?.chainId
                                  )
                                  ?.chain_name?.toUpperCase()
                              : appData?.chains
                                  ?.find(
                                    (chain) =>
                                      chain?.chainId ===
                                      transaction?.sending?.chainId
                                  )
                                  ?.chain_name?.charAt(0)
                                  ?.toUpperCase() +
                                appData?.chains
                                  ?.find(
                                    (chain) =>
                                      chain?.chainId ===
                                      transaction?.sending?.chainId
                                  )
                                  ?.chain_name?.slice(1)
                            : (appData?.chains?.find(
                                (chain) =>
                                  chain?.chainId ===
                                  transaction?.sending?.chainId
                              )?.chain_name === "bsc"
                                ? appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.sending?.chainId
                                    )
                                    ?.chain_name?.toUpperCase()
                                : appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.sending?.chainId
                                    )
                                    ?.chain_name?.charAt(0)
                                    ?.toUpperCase() +
                                  appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.sending?.chainId
                                    )
                                    ?.chain_name?.slice(1)) +
                              " to " +
                              (appData?.chains?.find(
                                (chain) =>
                                  chain?.chainId ===
                                  transaction?.receiving?.chainId
                              )?.chain_name === "bsc"
                                ? appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.receiving?.chainId
                                    )
                                    ?.chain_name.toUpperCase()
                                : appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.receiving?.chainId
                                    )
                                    ?.chain_name?.charAt(0)
                                    ?.toUpperCase() +
                                  appData?.chains
                                    ?.find(
                                      (chain) =>
                                        chain?.chainId ===
                                        transaction?.receiving?.chainId
                                    )
                                    ?.chain_name?.slice(1))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          {!transactions?.length && !loading && (
            <div className="w-[100%] flex justify-center pt-4">
              No transactions done.
            </div>
          )}
          {!transactions?.length && loading && (
            <div className="w-[100%] flex justify-center pt-4">
              <img
                src="/images/registration/loading.svg"
                alt="loading"
                width={40}
                className="my-4"
              />
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <ModalEnterPin
          callbackOpenPopup={(val) => {
            handlePinConfirm(val);
          }}
          callbackCancelPopup={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};
