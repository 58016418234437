import React, { useState, useEffect } from "react";
import MainModal from "../../generic/MainModal";

import ModalCurrencyItem from "./ModalCurrencyItem";
import { useApp } from "./../../../helpers/AppProvider";
// import initialState from "./../../../context/initialStateGlobal";
import { useUser } from "../../../helpers/UserProvider";

import PopularCurrenciesList from "../../settings/PopularCurrenciesList/PopularCurrenciesList";

const ModalChangeCurrency = ({ onClose }) => {
  const { actions, appData } = useApp();
  const { userData } = useUser();

  //

  // const currentCurrencyActive = appData.currencies?.indexOf(appData.activeCurrency);
  const dataPopularCurrencies = appData.currencies?.filter(
    (curr) => curr?.popular === "true"
  );

  const activeCurencyIdx = appData.currencies
    ? appData.currencies.indexOf(
        appData.currencies.find((e) => e.id === appData.activeCurrency.id)
      )
    : {};

  const [highlighted, setHighlightedItem] = useState(activeCurencyIdx || 0);
  // const [curr, setCurr] = useState(false);

  //dummyCurrency
  //see initialStateGlobal
  const filterCurrency = appData.currencies || [];
  // const filterCurrencyActive = appData.activeCurrency;

  const [search, setNewSearch] = useState("");

  const filtered = !search
    ? filterCurrency
    : filterCurrency.filter(
        (filterCurr) =>
          filterCurr["Currency Name"]
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          filterCurr["Currency Code"]
            .toLowerCase()
            .includes(search.toLowerCase())
      );

  const onClickItem = async (index, curr) => {
    // add class with selected

    setHighlightedItem(index);
    // const rate = await actions.getCurrencyRate(curr.id);
    // //
    // curr.rate = rate;
    // actions.setAppData({ activeCurrency: curr });
    const token = userData.user.token;
    actions.saveActiveCurrency(curr, token);
    onClose();
  };
  useEffect(() => {
    setHighlightedItem(appData.activeCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainModal
      onClose={onClose}
      title={"Select Currency"}
      extraClasses={["modal_change_currency_extra"]}
      dataClass="select_currency"
      search={
        <>
          <div className="form__inner_filter_header">
            <div className="form__input form__input-search">
              <input
                type="text"
                className="form-input-default"
                placeholder="Search"
                onKeyUp={(e) => setNewSearch(e.target.value)}
              />
              <span className="icon-search icon-search-black-24-dp" />
            </div>
          </div>
        </>
      }
    >
      <div className="form__inner_filter_assets">
        <div className="most_popular_currency">
          <span className="label">Popular Currencies</span>
          <PopularCurrenciesList
            data={dataPopularCurrencies}
            onClick={onClickItem}
            popularCurrencyModal={true}
            activeCurrency={appData?.activeCurrency["Currency Name"]}
          />
        </div>
        {filtered && filtered.length !== 0 && (
          <div className="most_popular_currency flat_currency_title">
            <span className="label">Flat Currencies</span>
          </div>
        )}
        <div
          className="form__input_filter_results form__input_filter_currency"
          // onKeyUp={dropdownMoveUp}
          tabIndex="0"
        >
          {filtered && filtered.length !== 0 && (
            <>
              {filtered &&
                filtered.length &&
                filtered.map((currency, index) => (
                  <ModalCurrencyItem
                    items={currency}
                    highlight={index === highlighted}
                    key={index}
                    clickHandle={() => onClickItem(index, currency)}
                  />
                ))}
            </>
          )}
          {filtered && filtered.length === 0 && (
            <div className="no_currency_found">&nbsp;</div>
          )}
        </div>
      </div>
    </MainModal>
  );
};

export default ModalChangeCurrency;
