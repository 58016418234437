import React from 'react';
const InlineToast = ( {
  isOpen = false,
  title,
  bodyText,
} ) => {
  return (
    <div className="flex mb-6 p-3 rounded-md block bg-red-500">
      <div>
        <svg className="h-5 w-5 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round">  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2" />  <line x1={15} y1={9} x2={9} y2={15} />  <line x1={9} y1={9} x2={15} y2={15} /></svg>
      </div>
      <div className="mx-auto text-sm pl-3 pointer-events-auto bg-clip-padding" aria-live="assertive" aria-atomic="true" data-mdb-autohide="false">                
        <div className="text-white">
          <span className='text-base'>{ title }</span><br />
          <span className='pr-12 block' style={{ fontSize: '12px', lineHeight: '16px' }}>{ bodyText }</span>
        </div>
      </div>
    </div>
  );
};

export default InlineToast;
