/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from "react";
import MainModal from "./../generic/MainModal";
import { useApp } from "../../helpers/AppProvider";
import TooltipCopy from "../registration/TooltipCopy";
// import { ChevronDownIcon } from "@heroicons/react/outline/";
import ConvertToken from "../swap/ConvertToken";
import SlippageRangeInput from "../swap/SlippageRangeInput";
// import { Priority } from "react-advanced-cropper";
import { copyToClipboard, delay_code } from "../../helpers/generalHelper";
import GasFeeOptions from "../common/GasFeeOptions/GasFeeOptions";
import GasCalculation from "../common/GasFeeOptions/GasCalculation";
const Web3 = require("web3");
// const Swaps = require("@getsafle/safle-swaps-v2").Swaps;
// const { KeyringController } = require("@getsafle/vault-eth-controller");
const Swap = require("@getsafle/safle-swaps-v2").Swaps;

const ModalSwapConfirmation = ({ onClose, callbackOpenPopup }) => {
  const { appData, actions } = useApp();
  // const web3 = new Web3();

  // const [advanceVisible, setAdvanceVisible] = useState(false);
  // const [inputState, setInputState] = useState(true);
  // const [priorityOption, setPriorityOption] = useState({});
  const [custGasLimit, setCustGasLimit] = useState(21000);
  // eslint-disable-next-line no-unused-vars
  const [maxFee, setMaxFee] = useState({ ETH: 0, USD: 0 });
  // const [timeLimit, setTimeLimit] = useState(30);
  const [loading, setLoading] = useState(true);
  const [gasFees, setGasFees] = useState();
  const [submitEnabled, setSubmitEnabled] = useState(false);
  // const [customMaxFee, setCustomMaxFee] = useState();
  const [customPriorityFee, setCustomPriorityFee] = useState();
  const [gasFeeOption, setGasFeeOption] = useState("standard");
  const [chosenGasLimit, setChosenGasLimit] = useState(21000);

  const handleGas = (gasLimit, priorityFee) => {
    setCustGasLimit(gasLimit);
    setCustomPriorityFee(priorityFee);
  };

  useEffect(() => {
    async function feeCalc() {
      if (gasFees && gasFeeOption) {
        await maxFeeCalculator(gasFees);
      }
    }
    feeCalc();
  }, [gasFeeOption, custGasLimit, customPriorityFee, gasFees]);

  useEffect(() => {
    const getGasFees = async () => {
      const gasFeesRes = await GasCalculation({
        appData: appData,
        chainId: appData.chains?.find((e) => e?.chainId === appData.activeChain)
          ?.chainId,
        token: appData?.fromTokenData[0],
        config: {
          toAddress: appData?.activeAccountAddress,
          fromAddress: appData?.activeAccountAddress,
          value: appData?.swapFromQuantity,
          contractAddress: appData?.fromTokenData[0]?.address,
        },
      });

      setGasFees(gasFeesRes);
      setCustGasLimit(gasFeesRes?.gasLimit);
      setCustomPriorityFee(gasFeesRes?.standard?.maxPriorityFeePerGas);
    };

    getGasFees();
  }, [
    appData?.fromTokenData,
    appData?.activeChain,
    appData?.activeAccountAddress,
    appData?.swapFromQuantity,
  ]);

  const maxFeeCalculator = async (gasFee) => {
    try {
      setLoading(true);
      // const controller = new KeyringController({});
      const activeChain = appData.chains
        ? appData.chains.filter(
            (chain) => chain?.chainId === appData?.activeChain
          )[0]
        : appData.chains[0];
      const swaps = new Swap({
        dex: appData.activeDex,
        rpcURL: activeChain.uri,
        chain: activeChain.chain_name,
      });

      const config = {
        walletAddress: appData.activeAccountAddress,
        toContractAddress: appData.activeTokenB,
        toContractDecimal:
          appData.toTokenData[0] && appData.toTokenData[0].decimals,
        fromContractAddress: appData.activeTokenA,
        fromContractDecimal:
          appData.fromTokenData[0] && appData.fromTokenData[0].decimals,
        fromQuantity: parseInt(
          parseFloat(appData.swapFromQuantity)?.toFixed(
            appData.fromTokenData[0].decimals
          ) * Math.pow(10, appData.fromTokenData[0].decimals)
        ).toString(),
        slippageTolerance: appData.swapSlippage,
        toQuantity: parseInt(
          parseFloat(appData.swapToQuantity)?.toFixed(
            appData.toTokenData[0].decimals
          ) * Math.pow(10, appData.toTokenData[0].decimals)
        ).toString(),
      };
      const w3 = new Web3(new Web3.providers.HttpProvider(activeChain?.uri));
      if (!config) {
        return;
      }
      await delay_code(2000);
      const rawTx = await swaps.getRawTransaction(config);
      if (typeof rawTx === "object" && rawTx?.gasLimit) {
        setChosenGasLimit(rawTx?.gasLimit);
        // const gasLimit =
        //   gasFeeOption === "custom" ? custGasLimit : gasFee?.gasLimit;
        const maxPriorityFeePerGas =
          gasFeeOption === "custom"
            ? customPriorityFee
            : gasFee?.[gasFeeOption]?.maxPriorityFeePerGas;
        const maxFeePerGas =
          gasFeeOption === "custom"
            ? parseFloat(customPriorityFee) + gasFee?.estimatedBaseFee
            : gasFee?.[gasFeeOption]?.maxFeePerGas;

        rawTx.gasLimit =
          gasFeeOption === "custom" ? custGasLimit : rawTx.gasLimit;
        rawTx.maxPriorityFeePerGas = w3.utils.toHex(
          parseInt(maxPriorityFeePerGas * Math.pow(10, 9)).toString()
        );
        rawTx.maxFeePerGas = w3.utils.toHex(
          parseInt(maxFeePerGas * Math.pow(10, 9)).toString()
        );
      }
      if (typeof rawTx === "string") {
        throw new Error(rawTx);
      }

      if (rawTx?.error) {
        throw new Error(rawTx?.error);
      }
      // actions.setAppData(rawTx)
      actions.setAppData({ approvalRawTxRes: rawTx });
      // const fees = await controller.getFees(rawTx, w3);

      const valueInWei = parseInt(
        rawTx?.maxFeePerGas * rawTx?.gasLimit * Math.pow(10, 9)
      ).toString();

      const valueInEth = isNaN(valueInWei)
        ? 0
        : w3.utils.fromWei(valueInWei, "ether");

      const data = actions.getChainNativeToUsd(activeChain?.chainId);
      // const data = actions.getEthToUsd();
      data.then((value) => {
        setMaxFee({
          ETH: valueInEth,
          USD:
            activeChain?.chainId === 1
              ? (value.data.data.ETH.quote.USD.price * valueInEth).toFixed(2)
              : (value.data.data.MATIC.quote.USD.price * valueInEth).toFixed(2),
        });
        activeChain?.chainId === 1
          ? actions.setAppData({
              ethToUsd: value.data.data.ETH.quote.USD.price,
            })
          : actions.setAppData({
              ethToUsd: value.data.data.MATIC.quote.USD.price,
            });
      });
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);

      actions.setAppData({ txResult: false, swapError: error.message });
      callbackOpenPopup("modal_txn_done");
    } finally {
      setLoading(false);
    }
  };

  // const handleFee = (e) => {
  //   actions.setAppData({ maxPriorityFeePerGas: e.target.value });
  // };

  const handleChange = (e) => {
    // setAdvanceEditable(e.target.value)
    setGasFeeOption(e);
  };

  const walletData = useMemo(() => {
    const activeWallet = Object.values(appData.accounts).find(
      (account) => account.address === appData.activeAccountAddress
    );

    if (activeWallet) {
      return {
        label:
          typeof activeWallet.label === "string"
            ? activeWallet.label
            : activeWallet.label?.polygon,
        // activeWallet.label[
        //   appData.chains[appData.activeChain || 0]?.chain_name
        // ] || activeWallet.label,
        address: activeWallet.address,
      };
    } else {
      if (appData.accounts && Object.values(appData.accounts).length) {
        return {
          label:
            typeof Object.values(appData.accounts)[0]?.label === "string"
              ? Object.values(appData.accounts)[0]?.label
              : Object.values(appData.accounts)[0]?.label?.polygon,
          // Object.values(appData.accounts)[0]?.label[
          //   appData.chains[appData.activeChain || 0]?.chain_name
          // ] || Object.values(appData.accounts)[0]?.label,
          address: Object.values(appData.accounts)[0].address,
        };
      }
    }
  }, [appData.activeAccountAddress, appData.accounts]);

  const rate = useMemo(() => {
    const fromTokenData =
      appData.supportedTokens &&
      appData.supportedTokens.filter(
        (token) => token.address === appData.activeTokenA
      );
    const toTokenData =
      appData.supportedTokens &&
      appData.supportedTokens.filter(
        (token) => token.address === appData.activeTokenB
      );

    if (fromTokenData.length && toTokenData.length) {
      const result = [
        fromTokenData[0] && fromTokenData[0].logoURI,
        toTokenData[0] && toTokenData[0].logoURI,
        appData.swapFromQuantity +
          " " +
          (fromTokenData[0] && fromTokenData[0].symbol),
        appData.swapToQuantity +
          " " +
          (toTokenData[0] && toTokenData[0].symbol),
      ];
      return result;
    } else {
      return null;
    }
  }, [
    appData.activeTokenA,
    appData.activeTokenB,
    appData.supportedTokens,
    appData.swapFromQuantity,
    appData.swapToQuantity,
  ]);

  const validCustomGas = (gasEstimate, prioFee) => {
    if (
      gasFeeOption !== "custom" ||
      (gasFeeOption === "custom" &&
        custGasLimit >= gasEstimate &&
        customPriorityFee >= prioFee)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    function buttonEnabler() {
      if (
        appData?.swapFromQuantity &&
        appData?.swapToQuantity &&
        appData?.activeTokenA &&
        appData?.activeTokenB &&
        gasFees &&
        validCustomGas(gasFees.gasLimit, gasFees?.slow?.maxPriorityFeePerGas)
      ) {
        setSubmitEnabled(true);
      } else {
        setSubmitEnabled(false);
      }
    }
    buttonEnabler();
  }, [
    gasFees,
    appData?.swapFromQuantity,
    appData?.swapToQuantity,
    appData?.activeTokenA,
    appData?.activeTokenB,
    custGasLimit,
    customPriorityFee,
    gasFeeOption,
  ]);

  return (
    <MainModal
      title="Confirmation"
      onClose={onClose}
      extraClasses={["modal_w440 modal_mh500 modal_swap_confirmation"]}
      dataClass="modal_swap_confirmation"
    >
      <div>
        <div className="modal--padding">
          <div className="modal_wallet_info">
            <div
              className="modal_wallet_info_name "
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {typeof walletData?.label === "string"
                ? walletData?.label
                : walletData?.label?.polygon}
              {/* {walletData?.label[
                appData.chains[appData.activeChain || 0]?.chain_name
              ] || walletData?.label} */}
            </div>
            <div
              className="flex items-center justify-between"
              onClick={() => copyToClipboard(walletData?.address)}
            >
              <div className="modal_wallet_info_address">
                {walletData && walletData.address}
              </div>
              {/* <img
                src="images/swap/copy-icon.svg"
                alt="Copy to clipboard icon"
              /> */}
              <TooltipCopy
                extraClasses="tooltip_wallet_details tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy justify-center"
                textToDisplay={""}
                imageToDisplay={
                  <span className="icon_copy_field icon-icon_copy"></span>
                }
                tooltipTextIn={"Copy to clipboard"}
                tooltipTextInChange={"Copied to clipboard"}
                copiedText={walletData.address}
                hideAfter="3000"
              />
            </div>
          </div>
          <ConvertToken
            fromTokenImg={rate && rate[0]}
            fromTokenBalance={rate && rate[2]}
            toTokenImg={rate && rate[1]}
            toTokenBalance={rate && rate[3]}
          />
        </div>
        <div className="px-[30px] pt-[20px]">
          <GasFeeOptions
            chain={appData.chains.find(
              (e) => e?.chainId === appData.activeChain
            )}
            chosenGas={chosenGasLimit}
            // estimator={estimator}
            // loading={feesLoading}
            tokenSymbol={
              appData.chains.find((e) => e?.chainId === appData.activeChain)
                ?.details?.symbol
            }
            // onAdvancedChange={() => {}}
            onGasChange={handleChange}
            onCustomGas={handleGas}
            token={appData?.fromTokenData[0]}
            toAddress={appData?.activeAccountAddress}
            fromAddress={appData?.activeAccountAddress}
            value={appData?.swapFromQuantity}
            contractAddress={appData?.fromTokenData[0]?.address}
          />
        </div>


        <SlippageRangeInput />
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue modal__trigger"
            data-target="modal_change_safle_id_txn"
            disabled={!submitEnabled && loading}
            onClick={() => callbackOpenPopup("modal_enter_pin")}
            // onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalSwapConfirmation;
