import React, { useEffect, useState } from "react";
import GasFeeOptions from "../common/GasFeeOptions/GasFeeOptions";
import MainModal from "../generic/MainModal";
import InputEmail from "../common/InputEmail/InputEmail";
import NFTImage from "../common/NFTImage/NFTImage";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import { useModalData } from "../../helpers/useModalNavigation";
import { checkWalletLinkedToSafleID } from "../../helpers/walletHelpers";
import { useApp } from "../../helpers/AppProvider";
// import FeeEstimator from "../../helpers/FeeEstimator";
// import { delay_code } from "../../helpers/generalHelper";
// import { useUser } from "../../helpers/UserProvider";
import GasCalculation from "../common/GasFeeOptions/GasCalculation";
const Web3 = require("web3");
// const Vault = require("@getsafle/safle-vault");

const ModalSendNFT = ({ handleModal, chainId }) => {
  // const estimator = new FeeEstimator();
  const [safleId, setSafleId] = useState();
  const [modalData, setData] = useModalData();
  const [disableID, setDisableID] = useState();
  const { actions, appData } = useApp();
  // const { userData, userActions } = useUser();
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [toAddress, setToAddress] = useState("");
  const [chosenFee, setChosenFee] = useState("standard");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [fees, setFees] = useState(null);
  const [gasFeesError, setGasFeesError] = useState(false);
  const [chosenGas, setChosenGas] = useState(21000);
  const [custGasOptions, setCustGasOptions] = useState({
    gasLimit: 0,
    prioFee: 0,
  });
  const [maxFee, setMaxFee] = useState();
  const [priorityFee, setPriorityFee] = useState();
  const [tokenSymbol, setTokenSymbol] = useState();

  useEffect(() => {
    if (typeof chainId === "number" && appData.chains) {
      const chain = appData.chains.find((e) => e?.chainId === chainId);
      setTokenSymbol(chain?.details?.symbol);
      get_fees(chainId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, appData.chains]);

  useEffect(() => {
    if (chosenFee && fees) {
      //set gas Fees as per api response keys of getGasFees
      // for polygon
      setMaxFee(`${fees?.[chosenFee]?.maxFeePerGas}`);
      setPriorityFee(`${fees?.[chosenFee]?.maxPriorityFeePerGas}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFee]);

  const gasChangeHandler = (val) => {
    //converting variable of gasFeeOptins to Api response (keys are different in polygon gas and ethereum gas)
    setChosenFee(val);
  };

  const custGasChangeHandler = (gasLimit, prioFee) => {
    setCustGasOptions({ gasLimit: gasLimit, prioFee: prioFee });
  };

  const get_fees = async (chainId) => {
    actions.setLoading(true, "loading");
    try {
      const gasFeesRes = await GasCalculation({
        appData: appData,
        chainId: chainId,
        token: "nft",
        config: modalData,
      });
      setMaxFee(gasFeesRes?.standard?.maxFeePerGas);
      setPriorityFee(gasFeesRes?.standard?.maxPriorityFeePerGas);
      setChosenGas(gasFeesRes?.gasLimit);

      setFees(gasFeesRes);
    } catch (e) {
    } finally {
      actions.setLoading(false);
    }
  };

  const modalConfirmHandler = async () => {
    actions.setLoading(true);
    let pAddr;
    if (toAddress.indexOf("0") === -1) {
      const res = await checkWalletLinkedToSafleID(toAddress);
      pAddr = res;
    } else {
      Web3.utils.isAddress(toAddress)
        ? (pAddr = toAddress)
        : (pAddr = "This address is not valid.");
      // pAddr = toAddress;
    }
    actions.setLoading(false);
    if (
      pAddr === "SafleID is not registered." ||
      pAddr === "This address is not valid."
    ) {
      setErrorMsg(pAddr);
      return;
    }
    setErrorMsg("");
    setData({
      maxFee:
        chosenFee === "custom" &&
        parseFloat(maxFee) < parseFloat(custGasOptions?.prioFee)
          ? custGasOptions?.prioFee
          : maxFee,
      maxPriorityFee:
        chosenFee === "custom" ? custGasOptions?.prioFee : priorityFee,
      gasLimit: chosenFee === "custom" ? custGasOptions?.gasLimit : chosenGas,
      to: pAddr,
    });
    handleModal("next");
  };

  // Function to handle changes in the "toAddress" field
  const handleToAddressChange = (e) => {
    // Remove spaces and special characters using regex
    const cleanedInput = e.target.value.replace(/[^a-zA-Z0-9]+/g, "");
    setToAddress(cleanedInput);
  };

  const handleInputChange = (input) => {
    const modifiedInput = input.replace(/[^a-zA-Z0-9]+/g, "");
    setSafleId(modifiedInput);
  };

  const handleRegisteredSafleID = async (value) => {
    if (!value || value === " ") return;
    const linkedPublicAddress = await checkWalletLinkedToSafleID(value);
    if (linkedPublicAddress.indexOf("0x") === -1) {
      setError("Invalid safleId");
      setToAddress("");
      setDisableID(false);
    } else {
      setError("");
      setSuccessMsg("SafleID is registered");
      setDisableID(true);
      setToAddress(linkedPublicAddress);
    }
  };

  const validCustomGas = (gasEstemate, prioFee) => {
    return (
      // chosenFee === "custom" &&
      custGasOptions.gasLimit >= gasEstemate &&
      custGasOptions.prioFee >= prioFee
    );
  };

  const checkGasFees = async () => {
    const chain = appData.chains.find((e) => e?.chainId === chainId);
    const chainNativeBalance = await actions.getAssetInfo(
      chain?.details?.symbol,
      modalData?.wallet,
      chain?.chain_name === "ethereum" ? "eth" : chain?.chain_name
    );

    const gasFees =
      chosenFee === "custom"
        ? custGasOptions.gasLimit *
          (parseFloat(custGasOptions.prioFee) +
            parseFloat(fees?.estimatedBaseFee))
        : chosenGas *
          (parseFloat(fees[chosenFee].maxPriorityFeePerGas) +
            parseFloat(fees?.estimatedBaseFee));
    if (
      parseFloat(chainNativeBalance?.balance) >=
      parseFloat(gasFees) / Math.pow(10, 9)
    ) {
      setGasFeesError(false);
    } else {
      setGasFeesError(true);
    }

    return (
      parseFloat(chainNativeBalance?.balance) >=
      parseFloat(gasFees) / Math.pow(10, 9)
    );
  };
  useEffect(() => {
    async function buttonEnabler() {
      const w3 = new Web3();
      if (
        toAddress.indexOf("0x") !== -1 &&
        w3.utils.isAddress(toAddress) &&
        validCustomGas(fees.gasLimit, fees?.slow?.maxPriorityFeePerGas) &&
        (await checkGasFees())
      ) {
        setSubmitEnabled(true);
      } else {
        setSubmitEnabled(false);
      }
    }
    buttonEnabler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // feesLoading,
    fees,
    toAddress,
    chosenFee,
    custGasOptions,
  ]);

  return (
    <MainModal
      extraClasses={[
        "modal_w420",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_nft_send",
      ]}
      onClose={() => {
        handleModal("CLOSED");
      }}
      dataClass="modal_nft_send"
    >
      <div className="modal_succes_content">
        <div className="modal_succes_desc">
          <div className="modal_nft_asset">
            <div style={{ fontWeight: 600, fontSize: 18 }}>
              {modalData?.metadata?.name}

              {/*Foodoo Lackasire*/}
            </div>
            <div
              className="nft__item-box"
              style={{ marginTop: 30, border: "none" }}
            >
              <NFTImage img={modalData?.metadata?.image} />
            </div>
          </div>
        </div>
        <div className="modal_send_rows">
          <InputEmail
            placeholder="Enter SafleID"
            label="To"
            value={safleId}
            errorMsg={error}
            successMsg={successMsg}
            onChange={(e) => {
              setError("");
              setToAddress("");
              setSuccessMsg("");
              setDisableID(false);
              setSafleId(e.target.value);
              handleInputChange(e.target.value);
            }}
            checkValueCallback={(r) => {
              handleRegisteredSafleID(r);
            }}
            checkButton="Check"
          />
        </div>

        <div class="line-text relative text-center py-2">OR</div>

        <div className="modal_send_rows">
          <InputEmail
            placeholder="Enter Wallet Address"
            value={toAddress}
            onChange={(e) => {
              setSafleId("");
              setError("");
              handleToAddressChange(e);
            }} // Use the custom handler
            errorMsg={errorMsg}
            disabled={disableID}
          />
        </div>
        <GasFeeOptions
          fees={fees}
          tokenSymbol={tokenSymbol}
          onGasChange={gasChangeHandler}
          chosenFee={chosenFee}
          chosenGas={chosenGas}
          compType={chainId !== 1 && "NFT"}
          NFTChainId={chainId}
          //setCustomGas={setCustomGas}
          //estimator={estimator}
          //onAdvancedChange={advancedChange}
          chain={appData.chains.find((e) => e?.chainId === chainId)}
          onCustomGas={custGasChangeHandler}
          type="nft"
          modalData={modalData}
        />
        {gasFeesError && (
          <div className="pb-[15px] text-medium text-red-500 font-semibold">
            Insufficient Gas Fees
          </div>
        )}
      </div>
      <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
        <PrimaryBtn
          classes="btn-default btn-white-bordered close_modal_cancel"
          label="Cancel"
          onClick={() => handleModal("CLOSED")}
        />
        <PrimaryBtn
          classes="btn-default btn-blue modal__trigger"
          label="Confirm"
          disabled={!submitEnabled}
          onClick={modalConfirmHandler}
        />
      </div>
    </MainModal>
  );
};

export default ModalSendNFT;
