import axios from "axios";
import { useEffect } from "react";

export async function postRequest({ params, url, authToken }) {
  try {
    const response = await axios({
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: params,
    });

    return { response: response.data };
  } catch (error) {
    return { error: error.response.data };
  }
}

export const delay_code = async (ms) => {
  return new Promise((res) =>
    setTimeout(() => {
      res();
    }, ms)
  );
};

export const useAppChange = (prop, cb) => {
  useEffect(() => {
    cb(prop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop]);
};

export const useCheckScroll = (prop) => {
  useEffect(() => {
    if (prop.blockScroll) {
      if (!document.body.classList.contains("no_scroll_dropdown")) {
        document.body.classList.add("no_scroll_dropdown");
        window.scrollTo(0, 0);
      }
    } else {
      if (document.body.classList.contains("no_scroll_dropdown")) {
        document.body.classList.remove("no_scroll_dropdown");
        window.scrollTo(0, 0);
      }
    }
  }, [prop]);
};
export const formatPrice = (price, nums) => {
  if (parseInt(price) === 0) {
    nums += 8;
  }
  let str = price.toString();
  str = str.slice(0, str.indexOf(".") + nums + 1);
  return Number(str);
};
export const formatXDecimals = (price, nums) => {
  let str = price?.toString();
  str = str?.slice(0, str?.indexOf(".") + nums + 1);
  return Number(str);
};

export const formatXMinDecimals = (price, nums = 2, max = 8) => {
  if (!price) {
    return 0;
  }
  let str = price.toString();
  const place = str.indexOf(".");
  if (str.slice(0, place) === "0" && place < max) {
    str = str.slice(0, place) + "." + str.slice(place + 1, max);
  } else {
    str = str.slice(0, str.indexOf(".") + nums + 1);
  }

  return Number(str);
};

export const unEvenEllipsis = (
  text,
  firstNum = 6,
  secondNum = 4,
  dotnumber = 3
) => {
  return (
    text.slice(0, firstNum) +
    "".padStart(dotnumber, ".") +
    text.slice(-secondNum)
  );
};

export const middleEllipsis = (text, split = 3, dotnumber = 3) => {
  if (!text) {
    return;
  }
  const sz = Math.floor(text.length / split);

  return text.slice(0, sz) + "".padStart(dotnumber, ".") + text.slice(-sz);
};

export const limitTitleFromMiddle = (title, maxLength) => {
  if (title?.length > maxLength) {
    return (
      title.substring(0, maxLength / 2) +
      "..." +
      title.substring(title.length - maxLength / 2)
    );
  }
  return title;
};

export const maxChars = (text, maxChars) => {
  const sub = text.substr(0, maxChars);
  return sub.length > text.length ? sub + ".." : sub;
};

export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(?=.{0,256}$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const isValidName = (name) => {
  // keep in mind o_o will work
  // eslint-disable-next-line no-useless-escape
  const re = /^[A-Za-z0-9\s\-]+$/;
  return re.test(String(name));
};
export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const convertIFPStoHttpUrl = (url) => {
  let imageUrl = url;
  if (imageUrl) {
    if (imageUrl.includes("ipfs:")) {
      imageUrl = imageUrl.replace("ipfs://", "https://ipfs.io/ipfs/");
    }
    if (imageUrl.includes("/ipfs/ipfs")) {
      imageUrl = imageUrl.replace("/ipfs/ipfs", "/ipfs");
    }
  }
  return imageUrl;
};
export function decimalCount(num) {
  const converted = num.toString();
  if (converted.includes(".")) {
    return converted.split(".")[1].length;
  }
  return 0;
}
