import {ChevronLeftIcon} from '@heroicons/react/outline/' 

const BackToMenuMobile = (props) => {
  
  return (
    <div className="settings_back_menu">
        <ChevronLeftIcon />
        <span onClick={() => props.goBackMenu(false)} className="back_text">Back to Menu</span>
    </div>
  );
};

export default BackToMenuMobile;