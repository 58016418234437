import React, { useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
// import { useUser } from "../../../helpers/UserProvider";

import ModalPinConfirmFunctions from "./../../common/SettingsChangePasswordProcess/ModalPinConfirmFunctions";
import ModalChangePasswordUpdatedFunctions from "./../../common/SettingsChangePasswordProcess/ModalChangePasswordUpdatedFunctions";
import ModalChangePasswordFailedFunctions from "./../../common/SettingsChangePasswordProcess/ModalChangePasswordFailedFunctions";
import ModalChangePasswordCancelFunctions from "./../../common/SettingsChangePasswordProcess/ModalChangePasswordCancelFunctions";

// import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import {
  CHANGE_PASSWORD_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

import useModalNavigation from "../../../helpers/useModalNavigation";

const SettingsChangePasswordProcess = (props) => {
  // const { userData } = useUser();
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      // wallets = [],
    },
    // setAppData,
  } = useApp() || {};

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(CHANGE_PASSWORD_FLOW);

  const onChangePinUpdate = (e) => {
    setActiveModalNav("CLOSED");
    props.onCompletProccess && props.onCompletProccess();
  };
  const onCancel = (e) => {
    setActiveModalNav("CLOSED");
    props.onCancelProccess && props.onCancelProccess();
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {
        // modal order:
        // ModalPinConfirm -- modal_email_enter_pin
        // ModalActionSuccess -- modal_email_updated
        // ModalActionFailed -- modal_email_address_failed
        // ModalActionConfirm -- modal_cancel_email_address
      }

      {activeModalLabel === "modal_change_password_enter_pin" && (
        <ModalPinConfirmFunctions callbackOpenPopup={() => goNext()} />
      )}
      {activeModalLabel === "modal_change_password_updated" && (
        <ModalChangePasswordUpdatedFunctions
          onChangePinUpdate={onChangePinUpdate}
        />
      )}
      {activeModalLabel === "modal_change_password_failed" && (
        <ModalChangePasswordFailedFunctions />
      )}
      {activeModalLabel === "modal_change_password_cancel" && (
        <ModalChangePasswordCancelFunctions onCancelProccess={onCancel} />
      )}
    </>
  );
};

export default SettingsChangePasswordProcess;
