/* eslint-disable react-hooks/exhaustive-deps */
import AssetsHistoryItem from "../AssetsHistoryItem/AssetsHistoryItem";
import { useEffect, useState } from "react";
import { useApp } from "./../../../helpers/AppProvider";
// import AssetsHistoryFilterHeadings from "../AssetsHistoryFilterHeadings/AssetsHistoryFilterHeadings";
// import AssetsTheadFilter from "../AssetsTheadFilter/AssetsTheadFilter";
import { useUser } from "../../../helpers/UserProvider";
// import { formatMoney, getCoinRate } from "../../../helpers/walletHelpers";
// import { formatXMinDecimals } from "../../../helpers/generalHelper";
import { fetchFavouriteTokens } from "../../../helpers/assetHelper";
import Toast from "../../registration/Toast/Toast";
import InfiniteScroll from "react-infinite-scroll-component";
// import orderBy from "lodash/orderBy";
// import { uniq, uniqBy } from "lodash";
// const MARKET_SERVICE_URL = process.env.REACT_APP_MARKET_SERVICE_URL;
// const WALLET_SERVICE_URL = process.env.REACT_APP_WALLETS_URL;

const AssetsHistoryList = (props) => {
  const { actions } = useApp();
  const { userData } = useUser();
  // const [assets, setAssets] = useState([]);
  // const [ loading, setLoading ] = useState( true );
  const [favouriteTokens, setFavouriteTokens] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [addedTokens, setAddedTokens] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  // const [filteredDataCopy, setFilteredDataCopy] = useState([]);

  const [toastIsOpen, setToastIsOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [assetsCount, setAssetsCount] = useState(0);
  const [endCalls, setEndCalls] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);

  // const [favouriteData, setFavouriteData] = useState([]);
  const {
    // shouldRefresh,
    // search = "",
    sortByKey,
    assetTypeFilterKey,
    show_beta,
    // favouriteFilter,
    // sortOrder,
  } = props;

  async function getAssets() {
    actions
      .getAllAssets({
        tokenType: assetTypeFilterKey,
        sortKey: sortByKey,
        offset: assetsCount,
        limit: 10,
      })
      .then((result) => {
        setAssetsCount(assetsCount + 10);

        if (result?.length === 0) {
          setEndCalls(true);
        }
        let newFilteredData = filteredData?.concat(result);
        setFilteredData(newFilteredData);
        // if (appData.activeChain) {
        //   let chainName = appData.chains.filter(
        //     (chain) => chain.chainId === appData.activeChain
        //   )[0]?.chain_name;
        //   if (chainName === "ethereum") {
        //     chainName = "eth";
        //   }
        //   const transactionListByChains = result[chainName]?.transactions?.map(
        //     (obj) => {
        //       return { ...obj, chain: chainName };
        //     }
        //   );
        //   if (transactionListByChains?.flat()?.length === 0) {
        //     setEndCalls(true);
        //   }
        //   let newTransactionList = alltransactions?.concat(
        //     transactionListByChains?.flat()
        //   );
        //   setAlltransactions(newTransactionList);
        // } else {
        //   const transactionListByChains = result?.activeChains?.map(
        //     (chainName) => {
        //       return result[chainName]?.transactions?.map((obj) => {
        //         return { ...obj, chain: chainName };
        //       });
        //     }
        //   );
        //   if (transactionListByChains?.flat()?.length === 0) {
        //     setEndCalls(true);
        //   }
        //   let newTransactionList = alltransactions?.concat(
        //     transactionListByChains?.flat()
        //   );
        //   setAlltransactions(newTransactionList);
        // }
        // setTransactionCount(transactionCount + 10);
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }

  useEffect(() => {
    if (assetsCount === 0) {
      getAssets();
    }
  }, [assetsCount]);

  useEffect(() => {
    setEndCalls(false);
    setFilteredData([]);
    setAssetsCount(0);
  }, [assetTypeFilterKey, sortByKey]);

  useEffect(() => {
    fetchFavourites();
  }, []);

  //   useEffect(async () => {
  //     getUserCustomToken()
  //       .then((response) => {
  //         return response;
  //       })
  //       .then((res) => {
  //         return fetchFavourites().then((res) => {
  //           return res;
  //         });
  //       });

  //     return () => {
  //       setFilteredData([]);
  //       setFilteredDataCopy([]);
  //     };
  //   }, [shouldRefresh, userData.user.token]);

  //   useEffect(() => {
  //     // if(addedTokens.length && favouriteTokens.length){
  //     getAllAssets().then((res) => {});
  //     // }
  //   }, [addedTokens, favouriteTokens]);

  // useEffect(async()=>{
  //     if(favouriteTokens.length && addedTokens.length){

  //         // get_assets()
  //         // await getAllAssets()
  //     }

  // },[favouriteTokens,addedTokens])

  //   useEffect(() => {
  //     if (favouriteTokens.length) {
  //       if (favouriteFilter !== undefined && favouriteFilter) {
  //         setFilteredData(
  //           filteredDataCopy.filter((i) => isFavouriteToken(i.symbol))
  //         );
  //       } else {
  //         setFilteredData(filteredDataCopy);
  //       }
  //     }
  //   }, [favouriteFilter]);

  const fetchFavourites = () => {
    const res = fetchFavouriteTokens(userData).then((res) => {
      // setAddedTokens(res.addedTokens)
      setFavouriteTokens(res?.data?.favourites);
      return res;
    });
    setFavouriteTokens([]);
    return res;
  };

  //   const getPaginatedData = (data, page_number = 0, page_size = 10) => {
  //     let arr = [...data];
  //     return arr.slice((page_number - 1) * page_size, page_number * page_size);
  //   };

  //   const fetchData = () => {
  //     const currPage = currentPage + 1;
  //     setTimeout(() => {
  //       const data = getPaginatedData([...assets], currPage, 10);
  //       setFilteredData([...filteredData, ...data]);
  //       setCurrentPage(currPage);
  //     }, 1500);
  //   };
  //   const getUserCustomToken = async () => {
  //     const res = await fetch(WALLET_SERVICE_URL + "/wallet/user-token", {
  //       method: "GET",
  //       headers: {
  //         Authorization: "Bearer " + userData.user.token,
  //       },
  //     }).then((res) => {
  //       return res.json();
  //     });
  //     if (res.data) {
  //       let items = res.data.tokens;
  //       const data_res = await Promise.all(
  //         items.map(async (i) => {
  //           i.logo = i.image;
  //           i.isAddedToken = true;
  //           try {
  //             const data = await getCoinRate(i.symbol);
  //             const info = await fetch(
  //               `${MARKET_SERVICE_URL}info?coin=${i.symbol}`
  //             ).then((e) => e.json());

  //             if (!data.error || data.data.length) {
  //               const assetW = actions.getAssetWorth(i.symbol);
  //               return {
  //                 ...i,
  //                 ...data.data.data[i.symbol],
  //                 info: info?.data?.data
  //                   ? info.data.data[i.symbol]
  //                   : { ...data.data.data[i.symbol] },
  //                 marketCap: data.data.data[i.symbol]?.quote["USD"]?.market_cap,
  //                 dailyChange:
  //                   data.data.data[i.symbol]?.quote["USD"]?.percent_change_24h,
  //                 volume24h: data.data.data[i.symbol]?.quote["USD"]?.volume_24h,
  //                 circulatingSupply: data.data.data[i.symbol]?.circulating_supply,
  //                 price: data.data.data[i.symbol]?.quote["USD"]?.price,
  //                 worth: formatMoney(
  //                   parseFloat(
  //                     data.data.data[i.symbol]?.quote["USD"]?.price.toFixed(20)
  //                   ) * assetW,
  //                   20
  //                 ),
  //                 isAddedToken: true,
  //               };
  //             } else
  //               return {
  //                 ...i,
  //                 info: info.data.data ? info.data.data[i.symbol] : i,
  //                 isAddedToken: true,
  //               };
  //           } catch (error) {
  //             return { ...i, info: i, isAddedToken: true };
  //           }
  //         })
  //       );
  //       setAddedTokens(data_res.reverse());
  //       return res;
  //     } else {
  //       setAddedTokens([]);
  //       return res;
  //     }
  //   };

  //   const getAllAssets = async () => {
  //     let currPage = 1;
  //     setCurrentPage(currPage);

  //     // setLoading( true );
  //     await fetch(MARKET_SERVICE_URL)
  //       .then((res) => {
  //         return res.json();
  //       })
  //       .then(async (res) => {
  //         let allAssets = [...res.data];
  //         allAssets = await allAssets.reduce(async (acc, item) => {
  //           const newItem = new Promise(async (resolve) => {
  //             item.marketCap = item.price?.quote["USD"].market_cap;
  //             item.dailyChange = item.price?.quote["USD"].percent_change_24h;
  //             item.volume24h = item.price?.quote["USD"].volume_24h;
  //             item.circulatingSupply = item.price?.circulating_supply;
  //             item.price = item.price?.quote["USD"].price;
  //             const assetW = actions._isNativeToken(item)
  //               ? actions.getNativeWorth(item.symbol)
  //               : actions.getAssetWorth(item.symbol);
  //             item.worth = formatMoney(
  //               parseFloat((await actions.getRate(item.symbol)).toFixed(20)) *
  //                 assetW,
  //               20
  //             );
  //             resolve(item);
  //           });
  //           return { ...(await acc), [item.symbol]: await newItem };
  //         }, {});

  //         let sortedAssets = Object.values(allAssets)
  //           .concat(addedTokens)
  //           .filter(Boolean)
  //           .map((i) => {
  //             if (isFavouriteToken(i.symbol)) {
  //               i.isFavourite = true;
  //             } else {
  //               i.isFavourite = false;
  //             }

  //             if (isAddedToken(i.symbol)) {
  //               i.isAddedToken = true;
  //               i.isCustom = true;
  //             }

  //             if (i.info?.category === "coin") {
  //               i.isCoin = true;
  //             } else {
  //               i.isCoin = false;
  //             }

  //             return i;
  //           });

  //         const uniqs = uniqBy([...sortedAssets], "symbol");
  //         sortedAssets = orderBy(
  //           uniqs,
  //           ["worth", "isFavourite", "isCustom"],
  //           ["desc", "desc", "asc"]
  //         );
  //         // sortedAssets= sortedAssets.sort(((x, y) =>{
  //         //     return Number((x.isFavourite)) === Number((y.isFavourite)) ? 0 : Number((x.isFavourite)) ? -1 : 1;

  //         //   } ))

  //         //TODO ! total wallet balance of each asset should be added here
  //         //todo ! refer swa 32/72 balance for token and native coins

  //         setAssets(sortedAssets);
  //         const data = [...getPaginatedData([...sortedAssets], currPage)];

  //         setFilteredData(data);
  //         setFilteredDataCopy(data);
  //       })
  //       .finally(() => {
  //         // setLoading( false );
  //       });
  //   };

  const isFavouriteToken = (symbol) => {
    const result = favouriteTokens?.filter(
      (e) => !e?.isDeleted && e?.symbol === symbol //
    );
    return result?.length ? result?.length : false;
  };
  //   const isAddedToken = useCallback(
  //     (symbol) => {
  //       if (addedTokens.length) {
  //         const result = addedTokens?.filter(
  //           (e) => !e.isDeleted && e.symbol === symbol
  //         );
  //         return result.length;
  //       }
  //       return 0;
  //     },
  //     [addedTokens]
  //   );

  //   const mySort = (desc) => {
  //     const mod = desc ? -1 : 1;
  //     return function (a, b) {
  //       const lca = a[sortByKey],
  //         lcb = b[sortByKey];
  //       const val = lca > lcb ? 1 * mod : lca < lcb ? -1 * mod : 0;
  //       return val;
  //     };
  //   };

  // ACTION OF FILTERS
  //   useEffect(() => {
  //     let filteredResults = [...assets];
  //     if (search === "" || !sortByKey || !assetTypeFilterKey) {
  //       setFilteredData(filteredDataCopy);
  //     }
  //     if (search || sortByKey || assetTypeFilterKey) {
  //       // SEARCHING BY KEYWORD
  //       if (search !== "") {
  //         filteredResults = filteredResults.filter(
  //           (el) =>
  //             el.info?.name?.toLowerCase().includes(search) ||
  //             el.name?.toLowerCase().includes(search) ||
  //             el.symbol?.toLowerCase().includes(search)
  //         );
  //       }

  //       // FILTER BY ASSET TYPE
  //       if (assetTypeFilterKey) {
  //         filteredResults = filteredResults.filter((el) => {
  //           if (assetTypeFilterKey === "coins") {
  //             return el.isCoin === true;
  //           } else if (assetTypeFilterKey === "tokens") {
  //             return !el?.isCoin;
  //           }
  //         });
  //       }

  //       // SORTING (ASC)
  //       if (sortByKey) {
  //         filteredResults = [...filteredResults].sort(mySort(sortOrder));
  //         // filteredResults = filteredResults.sort(function(a, b) {
  //         //     var x = a[sortByKey];
  //         //     var y = b[sortByKey];
  //         //     if ( x < y ){
  //         //         return -1;
  //         //     }
  //         //     if ( x > y ){
  //         //         return 1;
  //         //     }
  //         //     return 0;
  //         // });
  //       }

  //       if (!filteredResults.length && search === "") {
  //         setFilteredData(filteredDataCopy);
  //       } else {
  //         setFilteredData(filteredResults);
  //       }
  //     }
  //   }, [search, sortByKey, assetTypeFilterKey]);

  const onAddFav = async () => {
    await fetchFavourites();
  };
  const onRemoveFav = async () => {
    await fetchFavourites();
  };

  return (
    <>
      <div
        className={
          "assets__overview_content-filtered_items " + (show_beta ? "beta" : "")
        }
      >
        <div className="sticky_mobile_header">
          <div className="assets__overview_content-filtered_items_row assets__overview_content-filtered_items_heading">
            <div className="sticky_mobile gray_bg">
              <div className="aoc_table_col" data-cls="asset_name">
                {/* <AssetsTheadFilter filterName={'Asset Name'} items={filterAssetName} /> */}
                <span className="assets_icon_favorite_mobile icon-star-solid"></span>
                <span>Asset Name</span>
                {/* <AssetsTheadFilter filterName={'Asset Name'} items={filterAssetName} /> */}
                {/* <AssetsHistoryFilterHeadings icon={'desc'}/> */}
              </div>
            </div>
            <div className="scrollable_mobile">
              <div className="aoc_table_col" data-cls="asset_favorite">
                <span>Favourite</span>
                {/* <AssetsHistoryFilterHeadings icon={'asc'}/> */}
              </div>
              <div className="aoc_table_col" data-cls="asset_name">
                <span>Asset Name</span>
                {/* <AssetsTheadFilter filterName={'Asset Name'} items={filterAssetName} /> */}
                {/* <AssetsHistoryFilterHeadings icon={'desc'}/> */}
              </div>
              <div className="aoc_table_col" data-cls="asset_price">
                <span>Asset Price</span>
                {/* <AssetsTheadFilter filterName={'Asset price'} items={filterAssetPrice} /> */}
                {/* <AssetsHistoryFilterHeadings icon={''}/> */}
              </div>
              {/* <div className="aoc_table_col" data-cls="asset_activity"> */}
              {/* <span>7 days activity</span> */}
              {/* <AssetsTheadFilter filterName={'7 days activity'} items={filterAsset7DayActivity} /> */}
              {/* <AssetsHistoryFilterHeadings icon={''}/> */}
              {/* </div> */}
              {/* <div className="aoc_table_col" data-cls="asset_worth"> */}
              {/* <span>Assets</span> */}
              {/* <AssetsTheadFilter filterName={'Assets'} items={filterAssetName} /> */}
              {/* <AssetsHistoryFilterHeadings icon={''}/> */}
              {/* </div> */}
              <div className="aoc_table_col" data-cls="asset_changes">
                <span>% change</span>
                {/* <AssetsHistoryFilterHeadings icon={''}/> */}
              </div>
              <div className="aoc_table_col" data-cls="asset_actions">
                <span>Actions</span>
                {/* <AssetsHistoryFilterHeadings icon={''}/> */}
              </div>
            </div>
          </div>
        </div>
        <div className="scrollable_mobile_inner_fileter_items">
          <InfiniteScroll
            dataLength={filteredData?.length} //This is important field to render the next data
            next={getAssets}
            hasMore={!endCalls}
            loader={
              <div className="fade p-4 bg-white border border-solid border-slate-100 rounded-xl z-10 flex flex-col items-center justify-center">
                {" "}
                <img
                  src="/images/registration/loading.svg"
                  alt="loading"
                  width={40}
                  className="my-4"
                />
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {filteredData &&
              filteredData?.map((item, index) => (
                <AssetsHistoryItem
                  key={item?.info?.id?.toString() + index.toString()}
                  itemIndex={index}
                  filteredData={filteredData}
                  item={item}
                  favState={isFavouriteToken(item.symbol)}
                  onRemoveFavToken={onRemoveFav}
                  onAddFavToken={onAddFav}
                  setFilteredData={setFilteredData}
                  assetTypeFilterKey={assetTypeFilterKey}
                  sortByKey={sortByKey}
                  setToastIsOpen={setToastIsOpen}
                  addedTokens={addedTokens}
                  setToastMessage={setToastMessage}
                />
              ))}
          </InfiniteScroll>
        </div>
      </div>

      <Toast
        title=""
        bodyText={toastMessage}
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        seedPhrase
      />
    </>
  );
};

export default AssetsHistoryList;
