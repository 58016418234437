import React from "react";
import { RECEIVE_NFT_FLOW } from "../../../constants/ui";
import useModalNavigation from "../../../helpers/useModalNavigation";
const ReceiveNFTBtn = () => {
  const [setActiveModal] = useModalNavigation(RECEIVE_NFT_FLOW);

  return (
    <div
      className="assets__add_new"
      onClick={() => {
        setActiveModal("modal_receive_nft");
      }}
    >
      <button
        className="btn-default btn-add btn-icon-assets modal__trigger "
        data-target="modal_receive_asset"
      />
      <div className="asset-add-new-label">Receive NFT</div>
    </div>
  );
};

export default ReceiveNFTBtn;
