const SettingsSubmenuItemMobile = (props) => {
  return (
    <>
      <div onClick={() => props.setLinkSafleIdOpen(true)}>
        <div onClick={props.onClickAction} className="settings_submenu_item">
          { !props.img &&
            <div className="settings_submenu_icon">
              <span className={props.icon}></span>
            </div>
          }
          { props.img && 
            <div className="settings_submenu_icon">
              <img src={props.img} />
            </div>
          }
          <div className="w-100">
            <div className="submenu--flex">
              <div>
                <div className={"settings_submenu_title " + props.titleClass}>{props.title}</div>
                <div className="settings_submenu_text">{props.text}</div>
              </div>
              {props.link && <a className="submenu_anchor" target="_blank" href={props.link}>View</a>}
            </div>
            <div className="submenu-border"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsSubmenuItemMobile;