import React from 'react';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';

const ModalRemoveWallet = ( { onClose } ) => {

    return (
        <MainModal onClose={ onClose } title=" " extraClasses={ [ 'modal_w420 modal_p30', 'modal_dflex', 'modal_remove_wallets' ] } dataClass="modal_remove_wallet" >
            <div className="modal_succes_content">
                <ModalHeadIcon img="images/icon-delete.svg" />
                <div className="modal_succes_head pt_20 mb_60">Are you sure you want<br /> to archive wallet?</div>
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <PrimaryBtn classes="btn-default btn-blue modal__trigger" label="Confirm" />
                    <PrimaryBtn classes="btn-default btn-white-bordered close_modal_cancel" label="Cancel" />
                </div>
            </div>
        </MainModal>
    );
};

export default ModalRemoveWallet;