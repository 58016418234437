import React, { useEffect, useState } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";

// import AddWalletProcess from "../components/common/AddWalletProcess/AddWalletProcess";
import SiteContent from "../components/common/SiteContent/SiteContent";
// import AddWalletButton from "../components/common/AddWalletButton/AddWalletButton";
// import WalletsList from "../components/wallets/WalletsList/WalletsList";
import ExportWalletPrivateKeyProcess from "../components/common/ExportWalletPrivateKeyProcess/ExportWalletPrivateKeyProcess";
import RemoveWalletProcess from "../components/common/RemoveWalletProcess/RemoveWalletProcess";
// import EditLabelWalletProcess from "../components/common/EditLabelWalletProcess/EditLabelWalletProcess";
// import QRProcess from "../components/common/QRProcess/QRProcess";
// import WalletSearchBar from "../components/common/WalletSearchBar/WalletSearchBar";
import { useUser } from "../helpers/UserProvider";
import ManageVaultContent from "../components/settings/ManageVaultContent/ManageVaultContent";
// import { useApp } from "../helpers/AppProvider";
// import { delay_code } from "../helpers/generalHelper";
import RestoreWalletProcess from "../components/common/RestoreWalletProcess/RestoreWalletProcess";

const VaultScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);

  const { userActions } = useUser();
  // const { appData, actions } = useApp();

  useEffect(() => {
    userActions.getUserById().then((user) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const load_vault = async () => {
  //   actions.setLoading(true, "Loading");
  //   await Promise.all([
  //     // userActions.loadVault(),
  //     // actions.loadChains(),
  //     // actions.loadSupportedAssets(),
  //     actions.loadSupportedCurrencies(),
  //     actions.loadActiveCurrency(userData.user.token),
  //     actions.loadUsdRate(),
  //     // userActions.loadVault(),
  //   ]);
  //   await delay_code(400);
  //   const acc = await appData?.accounts;
  //   const addreses = acc ? Object.values(acc) : {};
  //   const wallet_changes = await actions.loadWalletCardData(addreses);
  //   actions.setAppData(wallet_changes);

  //   actions.setLoading(false);
  // };

  return (
    <>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <div>
        <div className="main__content__wrapper">
          <Sidebar
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          {/* main content */}
          <SiteContent extraClass="assets__overview-wrapper wallets__overview-wrapper">
            <div className="assets__overview" data-to_show="all_assets">
              {/* <div className="assets__overview_top">
                <div
                  className="assets__overvieew_tab_navigation columns-2 xs:columns-1"
                  style={{ paddingBottom: 4 }}
                >
                  
                </div>
              </div> */}
              <div className="assets__overview_content">
                <ManageVaultContent />
              </div>
            </div>
          </SiteContent>
          {/* end main content */}
          {/* aside notification */}
          <SidebarNotification />
          {/* end aside notification */}
        </div>
        <div className="main__footer" />
        <div className="drop_shadow" />
        <div className="drop_shadow_menu" />
      </div>

      {sidebarActive && <div className="mobile_blur"></div>}
      <ExportWalletPrivateKeyProcess />
      <RemoveWalletProcess />
      <RestoreWalletProcess />
    </>
  );
};

export default VaultScreen;
