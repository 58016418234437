
import React, { useState, useEffect }  from 'react';

import { useApp } from './../../../helpers/AppProvider';
import useModalNavigation from '../../../helpers/useModalNavigation';
import { EDIT_LABEL_WALLET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';
import ModalCancelFunctions from './../../common/EditLabelWalletProcess/ModalCancelFunctions';
import ModalEditWalletLabelFunctions from './../../common/EditLabelWalletProcess/ModalEditWalletLabelFunctions';
import ModalFailedFunctions from './../../common/EditLabelWalletProcess/ModalFailedFunctions';
import ModalEnterPinFunctions from './../../common/EditLabelWalletProcess/ModalEnterPinFunctions';
import ModalSuccessFunctions from './../../common/EditLabelWalletProcess/ModalSuccessFunctions';



const EditLabelWalletProcess = () => {

    const { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {} } = useApp().appData || {};
    const [setActiveModalNav, goNext , goBackward] = useModalNavigation(EDIT_LABEL_WALLET_FLOW);

    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };

    useEffect(() => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return (
        <>
            {activeModalLabel === 'modal_edit_wallet_label' &&
                <ModalEditWalletLabelFunctions />
            }
            {activeModalLabel === 'modal_edit_wallet_enter_pin' &&
                <ModalEnterPinFunctions />
            }
          
            {activeModalLabel === 'modal_edit_label_cancel_wallet' &&
                <ModalCancelFunctions />
            }
            {activeModalLabel === 'modal_edit_label_failed_wallet' &&
                <ModalFailedFunctions />
            }
            {activeModalLabel === 'modal_edit_label_success_wallet' &&
                <ModalSuccessFunctions />
            }
            
        </>
    );
}

export default EditLabelWalletProcess;