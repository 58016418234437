import React from 'react';
import MainModal from './../generic/MainModal';
import { walletTypes } from '../../constants/wallets';

const ModalAddWalletPopup = (props) => {
    const selectedWalletType = walletTypes.find(type => type.code === props.walletType)

    return (
        <MainModal
            // on close - display conformation first
            onClose={() => props.callbackCancelGeneratePopup('modal_generate_cancel_wallet')} 
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet--1', 'modal_add_wallet_popup']}
            dataClass="modal_add_wallet_popup" >
            {/* Modal content */}
            <div className="modal_head--1">
                <img src="images/wallets/add-wallet-confirm.svg" alt="" />
                <div className="modal_title">Add Wallet</div>
                {selectedWalletType &&
                <div className="modal_text modal_text_mb">You are generating a {selectedWalletType?.label}</div>}
            </div>
            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered close_modal_cancel"
                        onClick={() => props.callbackCancelGeneratePopup('modal_generate_cancel_wallet')}
                    >Cancel</button>
                    <button className="btn-default btn-blue modal__trigger"
                    onClick={() => props.callbackOpenPopup('modal_generate_change_pin')}
                    >Confirm</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalAddWalletPopup;