import React, { useState ,useEffect , useRef } from 'react';

const DropdownNetworkItemNew = (items) => {
  const item = items.items;

  return (
    <div
      className={
        "drop-link dropdown__active__item control-network-dropwdon-new " +
        (items.highlight ? "selected" : "")
      }
      onClick={items.clickHandle}
    >
      <div className="network-new-name-content">
        <img src={item.network_logo} alt="" />
        <span className="drp_name network_name network-name-new">
          {item.network_name.charAt(0).toUpperCase() + item.network_name.slice(1)}
        </span>
      </div>
      {items.highlight && (
        <div className=" absolute tick-button top-[1.5rem] right-[2rem]" style={{ color: "#0669f8", border: "none" }}> {/* Add a class for styling if needed */}
          {/* Add your tick icon or content here */}
          &#10004;
        </div>
      )}
    </div>
  );
};


export default DropdownNetworkItemNew;
