import { LINK_WITH_SAFLEID } from '../../../constants/ui';
import { useApp } from '../../../helpers/AppProvider';
import Storage from '../../../helpers/Storage';
import useModalNavigation from '../../../helpers/useModalNavigation';
import TokenIcon from '../../common/TokenIcon/TokenIcon';
import TooltipCopy from '../../registration/TooltipCopy';

const LinkToSafleIdWalletItemMobile = (props) => {
    const {item}   = props
  const { appData, actions } = useApp();
  const [setModalOnLinkWallet] = useModalNavigation(LINK_WITH_SAFLEID);

    return (
        <div className="wallet_item">
              <TokenIcon logo={actions.getChainLogo(item.chain) || ""} />
            <div className="w-100">
                <div className="wallet_details">
                    <div className="d-flex">
                        <div className="wallet_name">{item.label}</div>
                        <div className="wallet_prefix">{item.chain_name}</div>
                    </div>
                    {(item?.isSafleCard || item.chain === 1 ) && <div  onClick={() => {
                        if (item?.linked) {
                          return;
                        }
                        Storage.save("selectedWalletForLink", item);
                        setModalOnLinkWallet("link_to_safleid");
                      }}
                       className={item.linked || (item.isSafleCard && item?.chain === 137 )? "wallet_status linked pointer-events-none" : "wallet_status link"}
                       >{item.linked || (item.isSafleCard && item?.chain === 137 ) ? "Linked" : "Link"}</div>}
                </div>
                <div className="wallet_address_ctn">
                    <div className="wallet_address">{item.address}</div>
                    <TooltipCopy 
                        extraClasses='tooltip_registration cursor-pointer tooltip_webapp_copy private_key_copy' 
                        imageToDisplay={<span className="icon_copy_field icon-icon_copy"></span>} 
                        tooltipTextIn={'Copy to clipboard'} 
                        tooltipTextInChange={'Copied to clipboard'} 
                        copiedText={item.address}
                        hideAfter="3000"
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkToSafleIdWalletItemMobile;