import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import AssetsTabsOverview from "../components/assets/AssetsTabsOverview/AssetsTabsOverview";

import NFTList from "../components/assets/NFTList/NFTList";
import { useApp } from "../helpers/AppProvider";
import { useEffect, useState } from "react";
import ModalNFTReceive from "../components/dialogs/ModalNFTReceive";
import { DEFAULT_MODAL_STATUS, RECEIVE_NFT_FLOW } from "../constants/ui";
import useModalNavigation from "../helpers/useModalNavigation";
import Storage from "../helpers/Storage";

const NFTsScreen = () => {
  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
    },
    actions,
    appData,
  } = useApp() || {};

  const [setActiveModalNav] = useModalNavigation(RECEIVE_NFT_FLOW);

  const [sidebarActive, setSidebarActive] = useState(false);

  useEffect(() => {
    Storage.remove("NFTSelectedWallet");
    if (appData?.regStatus === "complete") {
      load_vault();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.accounts, appData?.regStatus]);

  const load_vault = async () => {
    actions.setLoading(true, "Loading NFTs");

    await Promise.all([actions.getNFTsdata()]);

    actions.setLoading(false);
  };

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {sidebarActive && <div className="mobile_blur"></div>}
        {/* <!-- main content --> */}

        <div className="main__content__wrp assets__overview-wrapper assets_overview_nft_wrp">
          <div className="assets__overview" data-to_show="all_assets">
            <AssetsTabsOverview btn="nft" />
            {process.env.REACT_APP_HIDE_SWAPS !== "true" && <NFTList />}
          </div>
        </div>
        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        {/* <!-- end aside notification --> */}
      </div>
      {activeModalLabel === "modal_receive_nft" && (
        <ModalNFTReceive onClose={() => setActiveModalNav("CLOSED")} />
      )}
    </div>
  );
};

export default NFTsScreen;
