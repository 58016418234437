import { useState } from "react";
import StatusBadge from "../StatusBadge/StatusBadge";
import { useLocation } from "react-router";
import ROUTES from "../../../helpers/routes";
import { useEffect } from "react";

const SettingBoxIcon = ({ icon, hasStatusBadge, enabled }) => {
  const location = useLocation();
  const [active, setActive] = useState(enabled);

  useEffect(() => {
    setActive(enabled);
  }, [enabled]);
  function handleEnabled() {
    location.pathname !== ROUTES.RECOVERY_MECHANISM && setActive(!active);
  }

  return (
    <div className={icon + " manage-safle-box-ico"}>
      {hasStatusBadge && (
        <StatusBadge handleEnabled={handleEnabled} enabled={active} />
      )}
    </div>
  );
};

export default SettingBoxIcon;
