import React from 'react';
import MainModal from '../generic/MainModal';

const ModalConfirmRemove = ( { onClose, title, primaryBtnText, secBtnText, dataClass }) => {

    return (
        <MainModal 
            onClose={onClose} 
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet--1', 'modal_remove_wallet_popup']} 
            dataClass={dataClass} >            
            {/* Modal content */}
            <div className="modal_head--1">
                <img src="images/wallets/icon-delete.svg" alt="" />
                <div className="modal_title">{title}</div>
            </div>
            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered close_modal_cancel">{primaryBtnText}</button>
                    <button className="btn-default btn-blue modal__trigger" data-target="modal_change_safle_id_txn">{secBtnText}</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalConfirmRemove;