import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApp } from "../../../helpers/AppProvider";
import {
  useUser,
  useRecaptcha,
  // isValidEmail,
} from "../../../helpers/UserProvider";
import {
  UserIcon,
  // ArrowNarrowRightIcon,
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon,
  ArrowRightIcon,
} from "@heroicons/react/solid";

import InputGroup from "../InputGroup/InputGroup";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import ROUTES from "../../../helpers/routes";
// import { delay_code } from "../../../helpers/generalHelper";
import { useRegistration } from "../../../helpers/RegistrationProvider";
import Storage from "../../../helpers/Storage";
import { useFlowGate } from "../../../components/flow/FlowGate";
import { SIGNUP_FLOW } from "../../../constants/signupFlow";
// import MainModal from "../../../components/generic/MainModal";
import PopupMessageVault from "../../../components/registration/PopupMessage/PopupMessageVault";

const SignInForm = () => {
  const { advance, initFlow } = useFlowGate();
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [checkValidEmail, setCheckValidEmail] = useState(null);
  const [safleIdError, setSafleIdError] = useState();
  const [saflePasswordError, setSaflePasswordError] = useState();
  const [password, setPassword] = useState();
  const [validPassword, setValidPassword] = useState(null);
  // const [ toastIsOpen, setToastIsOpen ] = useState( false );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [vaultPopup, setVaultPopup] = useState(false);
  const {
    // oldVersionUser,
    setOldVersionUser,
    setOtpStatus,
    setTempPassword,
    setNewPasswordToken,
    setSignUpCompleted,
    setSignupName,
    setPinValidated,
    // setUserVault,
    // userVault,
  } = useRegistration();

  // let timeout = false;

  const { actions } = useApp();
  const { userActions, OtpStatus } = useUser();
  const recaptchaRef = useRecaptcha();

  const handleSubmit = async () => {
    setValidPassword(true);
    setSafleIdError("");
    setSaflePasswordError("");
    if (!email) {
      setSafleIdError("Please enter registered email id or safle id");
      // setSafleIdError('Wrong safle ID, Please type correct one');
      setCheckValidEmail(false);
      setValidPassword(false);
      actions.setLoading(false);

      return false;
    }
    if (!password) {
      setSaflePasswordError("The password entry cannot be empty");
      setValidPassword(false);
      actions.setLoading(false);

      return false;
    }

    try {
      actions.setLoading(true, "Logging in");
      let token = await recaptchaRef.current.executeAsync();
      const resp_version = await userActions.getSafleUser(email);
      // const verify_pass_resp = await userActions.verifyPassword(email, password, token);
      //

      if (resp_version.statusCode === 400) {
        setSafleIdError("This email or safleId is not registered.");
      }
      // else if (!verify_pass_resp) {
      //   setSaflePasswordError('Wrong password, Please type correct one.');
      // }

      //
      //verify if its v0 or v1
      // if v1 then go to dashboard
      if (resp_version.version === "v0") {
        token = await recaptchaRef.current.executeAsync();
        //to check whether entered password is correct
        //  pass true to userActions.login to pass password as plain password instead of PDKeyHash
        const loginResp = await userActions.login(
          resp_version.safleId,
          password,
          token,
          true
        );
        if (loginResp?.details[0]?.message === SIGNUP_FLOW.INCORRECT_PWD) {
          //this will run if password is incorrect
          setSaflePasswordError("Incorrect password, Please type correct one.");
          actions.setLoading(false);
          return;
        }

        actions.setLoading(false);

        setSafleIdError("");
        setCheckValidEmail(true);
        setModalIsOpen(true);
        setOldVersionUser({
          email: resp_version.email,
          password,
          safleId: resp_version.safleId,
        });
      } else {
        try {
          actions.setLoading(true, "Logging in");
          Storage.save("userType", { type: "signin" });
          token = await recaptchaRef.current.executeAsync();
          const loginResp = await userActions.login(
            resp_version.safleId,
            password,
            token
          );

          if (loginResp.statusCode === 201) {
            if (
              SIGNUP_FLOW.SAFLEID_NOT_PUSHED_BLOCKCHAIN ===
              loginResp?.data?.message
            ) {
              const encVault = await userActions.retrieveVaultAddress(
                resp_version.safleId,
                password,
                loginResp?.data?.token
              );
              if (encVault) {
                Storage.save("activeFlow", {
                  currentRoute: "/setup-pin",
                  flow: "signup",
                });

                await userActions.getAccounts();

                setPinValidated(false);
                setOtpStatus({
                  safleId: resp_version.safleId,
                  email: resp_version.email,
                });
                setTempPassword(password);
                setNewPasswordToken(loginResp?.data?.token);
                setSignupName(resp_version.safleId);
              } else {
                setVaultPopup(true);
                setSafleIdError("Vault of this user is not stored.");
              }
              // setUserVault(userData.user.vault);
              //setEncrypt

              actions.setLoading(false);
              advance();
              return;
            }
          }

          if (loginResp.statusCode === 400) {
            if (
              SIGNUP_FLOW.UNVERIFIER_EMAIL === loginResp?.details[0]?.message
            ) {
              const dateNow = Date.now();
              setTempPassword(password);
              setSignUpCompleted(false);
              setSignupName(loginResp?.details[0]?.userName);
              setOtpStatus({
                safleId: resp_version.safleId,
                email: resp_version.email,
                startedAt: dateNow,
                otpType: "email-verification",
              });
              Storage.save("activeFlow", {
                currentRoute: "/signup",
                flow: "signup",
              });

              await userActions.resendEmailOTP(
                resp_version?.email,
                resp_version?.safleId
              );
            }
            if (
              SIGNUP_FLOW.VAULT_NOT_SAVED === loginResp?.details[0]?.message
            ) {
              setSignUpCompleted(false);
              setOtpStatus({
                ...OtpStatus,
                safleId: resp_version?.safleId,
                email: resp_version?.email,
              });
              Storage.save("activeFlow", {
                currentRoute: "/otp-verification",
                flow: "signup",
              });
              setTempPassword(password);
              setNewPasswordToken(loginResp?.token);
            }
            if (
              SIGNUP_FLOW.SAFLEID_NOT_PUSHED_BLOCKCHAIN ===
              loginResp?.details[0]?.message
            ) {
              Storage.save("activeFlow", {
                currentRoute: "/setup-pin",
                flow: "signup",
              });
              await userActions.retrieveVaultAddress(
                resp_version.safleId,
                password,
                loginResp.token
              );

              await userActions.getAccounts();

              setPinValidated(false);
              setOtpStatus({
                safleId: resp_version.safleId,
                email: resp_version.email,
              });
              setTempPassword(password);
              setNewPasswordToken(loginResp.token);
              setSignupName(loginResp?.details[0]?.userName);
              // setUserVault(userData.user.vault);
              //setEncrypt
            }
            if (SIGNUP_FLOW.EXPIRED_USER === loginResp?.details[0]?.message) {
              setSafleIdError("User data expired. Please register again");
            }
            if (SIGNUP_FLOW.DELETED_USER === loginResp?.details[0]?.message) {
              setSafleIdError("User has been deleted");
            }
            if (SIGNUP_FLOW.INCORRECT_PWD === loginResp?.details[0]?.message) {
              setSaflePasswordError(
                "Incorrect password, Please type correct one."
              );
            }
            actions.setLoading(false);
            advance();
            return;
          }

          actions.setLoading(true, "Loading Vault");

          token = await recaptchaRef.current.executeAsync();
          const encVault = await userActions.retrieveVault(
            resp_version.safleId,
            password,
            token
          );
          if (encVault) {
            //}
            //
            setTimeout(() => {
              actions.setLoading(false);
              navigate(ROUTES.DASHBOARD, { replace: true });
            }, 900);
          } else {
            actions.setLoading(false);
            setVaultPopup(true);
            setSafleIdError("Vault of this user is not stored.");
          }

          // actions.setLoading(false);
        } catch (e) {
          actions.setLoading(false);

          //setSaflePasswordError("Wrong password, Please type correct one.");
        }
      }
    } catch (e) {
      actions.setLoading(false);
    }
  };
  const fakehandleEmail = async (e) => {
    let value = e.target.value;
    setEmail(value);
    //removed the verification because of the loading time
  };

  // const handleEmail = async (e) => {
  //   let value = e.target.value;

  //   if (timeout) {
  //     clearTimeout(timeout);
  //     timeout = false;
  //   }
  //   setTimeout(async () => {
  //     if (value.indexOf("@") !== -1) {
  //       //email
  //       const checkSafleEmail = await userActions.validateSafleEmail(value);
  //       if (!checkSafleEmail.hasOwnProperty("data")) {
  //         setCheckValidEmail(false);
  //       } else {
  //         setCheckValidEmail(
  //           checkSafleEmail?.data?.isRegistered ? true : false
  //         );
  //       }
  //     } else {
  //       //safle id
  //       if (value.length > 5) {
  //         const checkSafleEmail = await userActions.validateSafleID(value);

  //         if (!checkSafleEmail.hasOwnProperty("data")) {
  //           setCheckValidEmail(false);
  //         } else {
  //           setCheckValidEmail(
  //             checkSafleEmail?.data?.isAvailable ? false : true
  //           );
  //         }
  //       } else {
  //         setCheckValidEmail(false);
  //       }
  //     }
  //   }, 100);

  //   setEmail(value);
  // };

  const handlePassword = (e) => {
    let value = e.target.value;

    setPassword(value);
    if (saflePasswordError) {
      setSaflePasswordError(false);
      setValidPassword(true);
      // handleEmail({ target: { value: email } });
    }
    if (value.length === 0) {
      setValidPassword(false);
      setSaflePasswordError("The password entry cannot be empty");
    }
  };
  const modalOkHandler = (val) => {
    initFlow("migration");
    navigate(ROUTES.MIGRATION, { replace: true });

    // initFlow('migration');
    // advance();
  };

  return (
    <div>
      <h1 className="text-md md:text-2xl text-gray-700 font-bold border-solid border-b-2 border-gray-200 pb-4 mb-6 color_light_blue border_change">
        {/* ‘Simple’ when it comes to Security? 'Get Safle*/}
        Safe and simple access to blockchain and crypto
      </h1>
      <form onSubmit={(e) => e.preventDefault()}>
        <InputGroup
          label="Your SafleID / Email *"
          placeholder="Your SafleID / Email *"
          icon={
            <UserIcon
              className="h-5 w-5 text-gray-400 icon_dark_gray"
              aria-hidden="true"
            />
          }
          value={email}
          valid={checkValidEmail}
          // onChange={handleEmail}
          onChange={fakehandleEmail}
        />
        <div className="error_message">{safleIdError}</div>
        <InputGroup
          label="Password *"
          placeholder="Enter Password *"
          type={passwordShown ? "text" : "password"}
          icon={
            <LockClosedIcon
              className="h-5 w-5 text-gray-400 icon_dark_gray"
              aria-hidden="true"
            />
          }
          hiddenEye={
            <EyeOffIcon
              onClick={() => setPasswordShown(true)}
              className={
                passwordShown
                  ? "h-5 w-5 text-gray-400 hidden"
                  : "h-5 w-5 text-gray-400"
              }
              aria-hidden="true"
            />
          }
          visibleEye={
            <EyeIcon
              onClick={() => setPasswordShown(false)}
              className={
                !passwordShown
                  ? "h-5 w-5 text-gray-400 hidden"
                  : "h-5 w-5 text-gray-400"
              }
              aria-hidden="true"
            />
          }
          value={password}
          valid={validPassword}
          onChange={handlePassword}
        />
        <div className="error_message">{saflePasswordError}</div>
        <div className="my-6 flex justify-end forgot_password_text">
          <Link to={ROUTES.FORGOT_PASS} className="text-xs">
            Forgot Password?
          </Link>
        </div>
        <Button
          type="submit"
          text="Sign in"
          size="lg"
          fullwidth
          onClick={handleSubmit}
        />
      </form>
      <div className="my-6 flex justify-center md:justify-start text-xs mt-6 dont_have_account">
        <span>Don't have an account?</span>
        <Link
          to={ROUTES.SIGNUP}
          className="flex items-center dont_have_account_link"
        >
          Sign Up here
          <ArrowRightIcon className="h-2 w-2 ml-1" />
        </Link>
      </div>

      {modalIsOpen && (
        <Modal
          title={
            <>
              You need to upgrade
              <br /> to the new version of app
            </>
          }
          // bodyText="You can now login using the new password."
          callToActionText="Upgrade now"
          extraClass="upgradeNewVersion"
          isOpen={modalIsOpen}
          setOpen={(val) => setModalIsOpen(val)}
          // setOpen={(val) => modalOkHandler(val)}
          callToAction={(val) => modalOkHandler(val)}
          icon={`images/registration/img/icon_done.svg`}
        />
      )}
      {vaultPopup && (
        <PopupMessageVault
          history={""}
          saffleId={""}
          title=""
          onClose={() => setVaultPopup(false)}
        />
      )}
    </div>
  );
};

export default SignInForm;
