const ActivityIcon = ({ type, color }) => {
  return (
    <>
      {/*
      sent      --- <span className="icon-activity_sent"></span>
      received  --- <span className="icon-activity_received"></span>
      swapped   --- <span className="icon-activity_swapped"></span>
      bought    --- <span className="icon-activity_bought"></span>
      */}

      <div className="recent__activity_type">
        {type === "receive" && (
          <span className="icon-activity_received color_green"></span>
        )}
        {type === "send" && (
          <span className="icon-activity_sent color_blue_dark"></span>
        )}
        {type === "swap" && (
          <span className="icon-activity_swapped color_blue_light"></span>
        )}
        {type === "approve" && (
          <img
            src="/images/approval.svg"
            alt="approval"
            style={{ width: "20px" }}
          />
        )}
        {type === "contract_execution" && (
          <img
            src="/images/scontractexec.svg"
            alt="approval"
            style={{ width: "12px" }}
          />
        )}
      </div>
    </>
  );
};

export default ActivityIcon;
