/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useApp } from "../../helpers/AppProvider";

import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import MainModal from "./../generic/MainModal";
import SeedWordsInput from "../common/SeedWordsInput/SeedWordsInput";

const ModalEnterSeed = ({
  onClose,
  title,
  buttonText,
  editableSeeds,
  errorMessage,
  setEditableSeeds,
  callbackOpenPopup,
  setValidation,
}) => {
  const { actions } = useApp();
  const [submitEnabled, setSubmitEnabled] = useState(false);

  // const handlePinComplete = ( seeds ) => {
  //     // if( Object.values( editableSeeds ).filter(e=>e.length>0).length === 12 ){
  //     //
  //     //     setSubmitEnabled( true );
  //     // } else {
  //     //     setSubmitEnabled( false );
  //     // }
  // }

  useEffect(() => {
    actions.setLoading(false);
  }, []);

  useEffect(() => {
    setValidation && setValidation(false);
    if (
      Object.values(editableSeeds).filter((e) => e.length > 0).length === 12
    ) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [editableSeeds]);

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w400",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_seed_phrase",
        "modal_enter_seed",
      ]}
      dataClass="modal_enter_seed"
    >
      <div className="modal_succes_content">
        <ModalHeadIcon img="images/import-wallet.svg" />
        <div className="modal_succes_head">{title}</div>
        <SeedWordsInput
          screen="seed_phrase_full"
          editableSeeds={editableSeeds}
          setEditableSeeds={setEditableSeeds}
          errorMessage={errorMessage}
        />
      </div>
      <div className="modal_succes_content_actions">
        <PrimaryBtn
          classes="btn-default btn-blue btn-fw600 close_modal_ok"
          onClick={callbackOpenPopup}
          label={buttonText}
          disabled={!submitEnabled}
          extraStyle={{ opacity: submitEnabled ? 1 : 0.5 }}
        />
      </div>
    </MainModal>
  );
};

export default ModalEnterSeed;
