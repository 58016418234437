import React, { useEffect, useState } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";

import AddWalletProcess from "../components/common/AddWalletProcess/AddWalletProcess";
import SiteContent from "../components/common/SiteContent/SiteContent";
import AddWalletButton from "../components/common/AddWalletButton/AddWalletButton";
import WalletsList from "../components/wallets/WalletsList/WalletsList";
import ExportWalletPrivateKeyProcess from "../components/common/ExportWalletPrivateKeyProcess/ExportWalletPrivateKeyProcess";
import RemoveWalletProcess from "../components/common/RemoveWalletProcess/RemoveWalletProcess";
import EditLabelWalletProcess from "../components/common/EditLabelWalletProcess/EditLabelWalletProcess";
import QRProcess from "../components/common/QRProcess/QRProcess";
import WalletSearchBar from "../components/common/WalletSearchBar/WalletSearchBar";
import { useUser } from "../helpers/UserProvider";
import LinkWalletSafleIdProcess from "../components/common/LinkWalletSafleIdProcess/LinkWalletSafleIdProcess";

const WalletsScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);
  const [walletSearchKeyword, setWalletSearchKeyword] = useState("");

  const { userActions } = useUser();

  useEffect(() => {
    userActions.getUserById().then((user) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />

      <div>
        <div className="main__content__wrapper">
          <Sidebar
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          {/* main content */}
          <SiteContent extraClass="assets__overview-wrapper wallets__overview-wrapper">
            <div className="assets__overview" data-to_show="all_assets">
              <div className="assets__overview_top">
                <div
                  className="assets__overvieew_tab_navigation columns-2 xs:columns-1"
                  style={{ paddingBottom: 4 }}
                >
                  <div className="w-full flex justify-start">
                    <AddWalletButton withLabel={true} />
                  </div>
                  <div className="  flex justify-end px-10">
                    <WalletSearchBar
                      title="Search wallet"
                      setFilter={setWalletSearchKeyword}
                      assetSearchHandler={setWalletSearchKeyword}
                      filter={walletSearchKeyword}
                      filterEmpty={() => setWalletSearchKeyword("")}
                    />
                  </div>
                </div>
              </div>
              <div className="assets__overview_content">
                <WalletsList walletSearchKeyword={walletSearchKeyword} />
              </div>
            </div>
          </SiteContent>
          {/* end main content */}
          {/* aside notification */}
          <SidebarNotification />
          {/* end aside notification */}
        </div>
        <div className="main__footer" />
        <div className="drop_shadow" />
        <div className="drop_shadow_menu" />
      </div>
      <QRProcess />
      <AddWalletProcess />
      <LinkWalletSafleIdProcess />
      <ExportWalletPrivateKeyProcess />
      <EditLabelWalletProcess />
      <RemoveWalletProcess />
      {sidebarActive && <div className="mobile_blur"></div>}
    </>
  );
};

export default WalletsScreen;
