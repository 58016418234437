import { useEffect, useState } from "react";
import PercentWorthGained from "../PercentWorthGained/PercentWorthGained";
import { formatXMinDecimals } from "../../../helpers/generalHelper";
import { useApp } from "../../../helpers/AppProvider";
import { formatMoney } from "../../../helpers/walletHelpers";
// import { getTokenBalance } from "../../../helpers/walletHelpers";

const AssetDetailWorth = ({ latestPrice, assetAmount, symbol }) => {
  const { appData, actions } = useApp();
  // eslint-disable-next-line no-unused-vars
  const [price, setPrice] = useState(0);
  const [dailyChange, setDailyChange] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [worth, setWorth] = useState(0);

  useEffect(() => {
    if (latestPrice) {
      const price = formatXMinDecimals(latestPrice?.price, 4, 8);
      const dailyChange = formatXMinDecimals(
        latestPrice?.percentChange24h,
        4,
        8
      );
      setPrice(price);
      setDailyChange(dailyChange);
    }
    load_data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestPrice, symbol]);

  // const calculateWorth = () => {
  //   const found = appData.wallets.find((i) => i.symbol === symbol);
  // };

  const load_data = async () => {
    // const activeWallet = actions.getActiveWallet();
    if (appData.walletAssets && appData.walletAssets.length) {
      let assetArray = [];
      for (let i in appData.wallets) {
        assetArray = assetArray.concat(
          actions.getBalanceAssets(appData.wallets[i], false)
        );
      }
      assetArray = Object.values(
        assetArray.reduce((acc, item, idx) => {
          const key = item.tokenAddress
            ? item.tokenAddress
            : item.symbol + "-" + item.chain?.toString();
          if (acc.hasOwnProperty(key)) {
            acc[key] = {
              ...acc[key],
              balance: parseFloat(acc[key].balance) + parseFloat(item.balance),
              balanceFiat:
                parseFloat(acc[key].balanceFiat) + parseFloat(item.balanceFiat),
            };
          } else {
            item.balance = parseFloat(item.balance);
            item.balanceFiat = item.balanceFiat === 0 ? 0.0 : item.balanceFiat;
            acc[key] = item;
          }
          return acc;
        }, {})
      );
      setWorth(assetArray.find((i) => i.symbol === symbol));
    } else {
      // setBalanceItems( false );
    }
  };

  return (
    <div className="assets__overview_single-worth w-[100%]">
      <div className="asset__coin-worth-asset">
        <span className="asset__coin-worth-label show_desktop">Worth</span>
        <span className="asset__coin-worth-prefix">
          {isNaN(assetAmount) || assetAmount === null
            ? "0"
            : parseFloat(assetAmount) < 0.000001
            ? "< 0.000001"
            : parseFloat(assetAmount)
                ?.toFixed(6)
                .replace(/\.?0+$/, "")}{" "}
          {symbol}
        </span>
        <span className="asset__coin-worth-coin-price asset__coin-worth-coin-price-mobile">
          {actions.getActiveCurrencySymbol()}
          {formatMoney(
            actions.activeCurrency(
              formatXMinDecimals(assetAmount * latestPrice?.price, 4, 8)
            )
          )}
        </span>
        <span className="asset__coin-worth-coin-price asset__coin-worth-coin-price-desktop">
          {actions.getActiveCurrencySymbol()}
          {formatMoney(
            actions.activeCurrency(
              parseFloat(assetAmount * latestPrice?.price)?.toFixed(2)
            )
          )}
        </span>
        <PercentWorthGained
          hist={dailyChange > 0 ? true : false}
          percent={dailyChange}
        />
      </div>
    </div>
  );
};

export default AssetDetailWorth;
