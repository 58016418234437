import React, { useEffect } from "react";

import ModalEnterVerificationCode from "./../../dialogs/ModalEnterVerificationCode";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  SETTINGS_CHANGE_NAME,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const ModalPinConfirmFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] =
    useModalNavigation(SETTINGS_CHANGE_NAME);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //

  return (
    <ModalEnterVerificationCode
      onClose={onClose}
      onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      // callbackOpenPopup={(event) => callbackOpenPopup(event)}
      // callbackCancelPopup={(event) => callbackCancelPopup(event)}
      modalCancel_label="modal_cancel_edit_name"
      modalNext_label="modal_name_is_updated"
    />
  );
};

export default ModalPinConfirmFunctions;
