import React, { useEffect } from "react";
import { useApp } from "../../../helpers/AppProvider";
// import { useUser } from '../../../helpers/UserProvider';

import ModalSendAssetFunctions from "./../../common/AssetsSendAssetProcess/ModalSendAssetFunctions";
import ModalSendAssetEnterPinFunctions from "./../../common/AssetsSendAssetProcess/ModalSendAssetEnterPinFunctions";
import ModaSendAssetTransactionFunctions from "./../../common/AssetsSendAssetProcess/ModaSendAssetTransactionFunctions";
import ModalSendAssetFailedFunctions from "./../../common/AssetsSendAssetProcess/ModalSendAssetFailedFunctions";
import ModalSendAssetCancelFunctions from "./../../common/AssetsSendAssetProcess/ModalSendAssetCancelFunctions";

import useModalNavigation from "../../../helpers/useModalNavigation";
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import Storage from "../../../helpers/Storage";

const AssetsSendAssetProcess = (props) => {
  // const { userData, userActions } = useUser();

  const {
    appData: {
      activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {},
      wallets = [],
    },
    setAppData,
  } = useApp() || {};

  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(SEND_ASSET_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };

  const onCancel = (e) => {
    // setActiveModalNav('CLOSED');
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <>
      {
        // modal order:
        // ModalAssetSend -- modal_send_asset
        // ModalEnterPin -- modal_send_asset_enter_pin
        // ModalTransactionDone -- modal_send_asset_transaction_done
        // ModalActionFailed -- modal_send_asset_failed
        // ModalActionConfirm -- modal_send_asset_cancel
      }
      {activeModalLabel === "modal_send_asset" && (
        <ModalSendAssetFunctions callbackOpenPopup={() => goNext()} />
      )}

      {activeModalLabel === "modal_send_asset_enter_pin" && (
        <ModalSendAssetEnterPinFunctions
        // callbackOpenPopup={() => goNext() }
        />
      )}
      {activeModalLabel === "modal_send_asset_transaction_done" && (
        <ModaSendAssetTransactionFunctions />
      )}
      {activeModalLabel === "modal_send_asset_failed" && (
        <ModalSendAssetFailedFunctions />
      )}
      {activeModalLabel === "modal_send_asset_cancel" && (
        <ModalSendAssetCancelFunctions />
      )}
    </>
  );
};

export default AssetsSendAssetProcess;
