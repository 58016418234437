import React, { useEffect } from "react";

// import ModalAssetPurchase from './../../dialogs/ModalAssetPurchase';
import ModalBuyCrypto from "./../../dialogs/ModalBuyCrypto";

import useModalNavigation from "../../../helpers/useModalNavigation";
import { BUY_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";

const ModalBuyCryptoFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(BUY_ASSET_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };
  const callbackOpenPopup = (popup) => {
    // setActiveModalNav(popup);
    setActiveModalNav("CLOSED");
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  //

  return (
    <ModalBuyCrypto
      onClose={onClose}
      extraClass="modal_buy_crypto"
      title={"Buy cripto to your wallet"}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      modalCancel_label="modal_buy_asset_cancel"
      modalNext_label="someotherpopup"
    />
  );
};

export default ModalBuyCryptoFunctions;
