import React from "react";
import MainModal from "./../generic/MainModal";
import WalletGeneratedInfo from "../common/WalletGeneratedInfo/WalletGeneratedInfo";
import WalletGeneratedActions from "../common/WalletGeneratedActions/WalletGeneratedActions";
import useModalNavigation from "../../helpers/useModalNavigation";
import { LINK_WITH_SAFLEID } from "../../constants/ui";
import { useApp } from "../../helpers/AppProvider";
import Storage from "../../helpers/Storage";
import { checkIfWalletLinked } from "../../helpers/walletHelpers";
import { useUser } from "../../helpers/UserProvider";

const ModalWalletGenerated = (props) => {
  const { appData } = useApp();
  const { userActions } = useUser();

  const [setModalOnLinkWallet] = useModalNavigation(LINK_WITH_SAFLEID);

  const linkSafleIdWalletProcess = async () => {
    userActions.getUserById().then((user) => {
      loadWalletList(user.linkedWallets);
    });
  };

  const loadWalletList = async (linkedWallets) => {
    if (appData.wallets && appData.wallets.length) {
      await Promise.all(
        appData.wallets
          .filter((e) => {
            if (appData.activeChain && appData.activeChain !== 0) {
              const curr = appData.chains[appData.activeChain - 1];
              return e.chain === curr.chainId;
            }

            return e;
          })
          .map(async (i) => {
            i.buttonState = (await checkIfWalletLinked(
              i,
              linkedWallets,
              appData
            ))
              ? "linked"
              : "link";
            return i;
          })
      );
    }
    const getNewWalletDetails = appData.wallets.find((i) => {
      return i.address === props.newAddress;
    });
    Storage.save("selectedWalletForLink", getNewWalletDetails);
    setModalOnLinkWallet("link_to_safleid");
  };
  return (
    <MainModal
      // on close - display conformation first
      onClose={() =>
        props.callbackCancelGeneratePopup("modal_generate_cancel_wallet")
      }
      title=" "
      extraClasses={[
        "modal_w420 modal_p30",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_wallet_generated",
      ]}
      dataClass="modal_wallet_generated"
    >
      <div className="modal_succes_content">
        <div className="modal_succes_ico">
          <img src="images/wallets/icon-done.svg" alt="" />
        </div>
        <div className="modal_succes_head">Wallet Generated</div>
        <div className="modal_succes_desc mb_20">
          <WalletGeneratedInfo
            privKey={props?.privateKey}
            address={props?.newAddress}
          />
        </div>
        <WalletGeneratedActions
          skipAction={() => props.onClose()}
          safleWalletLinkAction={linkSafleIdWalletProcess}
        />
      </div>
    </MainModal>
  );
};

export default ModalWalletGenerated;
