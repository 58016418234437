import { ArrowRightIcon } from "@heroicons/react/outline";

const ConvertToken = ({
  fromTokenImg,
  fromTokenBalance,
  toTokenImg,
  toTokenBalance,
}) => {
  return (
    <div className="modal_convert flex flex-col justify-evenly divide-y-2">
      <div className="modal_convert--icon flex flex-1 p-5 items-center justify-stretch">
        <img src={fromTokenImg} alt="" />
        <div className="modal_convert_balance">{fromTokenBalance}</div>
      </div>
      <div className="absolute left-[200px] bg-slate-200 h-12 w-12 rounded-full">
        <ArrowRightIcon className="modal_convert_arrow rotate-90 relative left-2 top-[4px]" />
      </div>
      <div className="modal_convert--icon flex flex-1 p-5 items-center justify-between">
        <img src={toTokenImg} alt="" />
        <div className="modal_convert_balance">{toTokenBalance}</div>
      </div>
    </div>
  );
};

export default ConvertToken;
