import React, { useEffect, useState, useMemo } from "react";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import MainModal from "./../generic/MainModal";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import ConvertToken from "../swap/ConvertToken";
import { useApp } from "../../helpers/AppProvider";
import { copyToClipboard } from "../../helpers/generalHelper";
import TooltipCopy from "../registration/TooltipCopy";
import { formatMoney } from "../../helpers/walletHelpers";

const ModalSwapTxnDone = ({ onClose }) => {
  const { appData, actions } = useApp();
  const [date, setDate] = useState();
  const currency = actions.getActiveCurrencySymbol();

  const walletData = useMemo(() => {
    const activeWallet = Object.values(appData.accounts).find(
      (account) => account.address === appData.activeAccountAddress
    );

    if (activeWallet) {
      return {
        label:
          activeWallet.label[
            appData.chains[appData.activeChain || 0]?.chain_name
          ] || activeWallet.label,
        address: activeWallet.address,
      };
    } else {
      if (appData.accounts && Object.values(appData.accounts).length) {
        return {
          label:
            Object.values(appData.accounts)[0].label[
              appData.chains[appData.activeChain || 0].chain_name
            ] || Object.values(appData.accounts)[0].label,
          address: Object.values(appData.accounts)[0].address,
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.activeAccountAddress, appData.accounts]);

  const dd = (nr) => {
    return nr.toString().padStart(2, "0");
  };

  useEffect(() => {
    if (appData.txResult === true) {
      const d = new Date();
      setDate(
        dd(d.getHours()) +
          ":" +
          dd(d.getMinutes()) +
          " - " +
          dd(d.getDate()) +
          "/" +
          dd(d.getMonth() + 1) +
          "/" +
          d.getFullYear()
      );
    }
  }, [appData.txResult]);

  const convertAddress = (address, cut = 20) => {
    if (address) {
      return address?.slice(0, cut) + " . . . " + address.slice(-7);
    }
    return "";
  };

  const rate = useMemo(() => {
    const fromTokenData =
      appData.supportedTokens &&
      appData.supportedTokens.filter(
        (token) => token.address === appData.activeTokenA
      );
    const toTokenData =
      appData.supportedTokens &&
      appData.supportedTokens.filter(
        (token) => token.address === appData.activeTokenB
      );

    if (fromTokenData.length && toTokenData.length) {
      const result = [
        fromTokenData[0] && fromTokenData[0].logoURI,
        toTokenData[0] && toTokenData[0].logoURI,
        appData.swapFromQuantity +
          " " +
          (fromTokenData[0] && fromTokenData[0].symbol),
        appData.swapToQuantity +
          " " +
          (toTokenData[0] && toTokenData[0].symbol),
      ];
      return result;
    } else {
      return null;
    }
  }, [
    appData.activeTokenA,
    appData.activeTokenB,
    appData.supportedTokens,
    appData.swapFromQuantity,
    appData.swapToQuantity,
  ]);

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w440 modal_p30",
        "modal_mh400 modal_dflex",
        "modal_success_msg",
        "modal_wallet_generated",
        "modal_txn_done",
      ]}
      dataClass="modal_txn_done"
    >
      <div className="modal_succes_content">
        <ModalHeadIcon
          img={
            appData.txResult === undefined
              ? "images/swap/icon-pending.svg"
              : appData.txResult === true
              ? "images/swap/icon-done.svg"
              : "images/swap/icon-error.svg"
          }
        />
        <div className="modal_succes_head">
          {appData.txResult === undefined
            ? "Transaction Pending"
            : appData.txResult
            ? "Transaction Done"
            : "Transaction Failed"}
        </div>
        {appData.txId && (
          <div className="modal_subtitle">
            Txn Id <span> {convertAddress(appData.txId)}</span>
          </div>
        )}
        <div className="modal_succes_desc mb_20">
          <div className="modal_wallet_import-wrp">
            <div className="input_text_copy">
              <div className="copy_wallet_name whitespace-nowrap text-ellipsis overflow-hidden">
                {walletData?.label?.ethereum || walletData?.label}
              </div>
              <span className="input_copy">
                <span className="to_copy qty_short">
                  {convertAddress(walletData?.address, 10)}
                </span>
              </span>
              <div onClick={() => copyToClipboard(walletData?.address)}>
                {/* <img
                  className="icon_copy_field icon-content-copy-black-24-dp"
                  src="images/wallets/copy-icon.svg"
                  alt=""
                /> */}
                <TooltipCopy
                  extraClasses="tooltip_wallet_details tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy justify-center"
                  textToDisplay={""}
                  imageToDisplay={
                    <span className="icon_copy_field icon-icon_copy"></span>
                  }
                  tooltipTextIn={"Copy to clipboard"}
                  tooltipTextInChange={"Copied to clipboard"}
                  copiedText={walletData.address}
                  hideAfter="3000"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal_convert_ctn">
          <ConvertToken
            fromTokenImg={rate && rate[0]}
            fromTokenBalance={rate && rate[2]}
            toTokenImg={rate && rate[1]}
            toTokenBalance={rate && rate[3]}
          />
        </div>
        {appData.swapError && (
          <div className="token-swap-balance-error">
            {JSON.stringify(appData.swapError)}
          </div>
        )}

        {appData.txFee && (
          <div className="modal_txn_done_fee">
            Fee{" "}
            <span>
              {appData.txFee?.toFixed(5)}{" "}
              {appData.activeChain === 1 ? "ETH" : "MATIC"} (
              {formatMoney(
                actions.activeCurrency(
                  (appData.txFee * appData.ethToUsd)?.toFixed(2)
                )
              )}{" "}
              {currency})
            </span>
          </div>
        )}

        {date && (
          <div className="modal_txn_done_fee">
            Timestamp: <span>{date}</span>
          </div>
        )}
        <div className="modal_footer">
          <div className="modal_succes_content_actions">
            <PrimaryBtn
              classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600"
              label={
                appData.txId === undefined
                  ? "OK"
                  : !appData.txId
                  ? "Retry"
                  : "Done"
              }
              onClick={() => onClose()}
            />
            {appData.txId ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                className="modal_check-on"
                target="_blank"
                href={
                  appData.activeChain === 137
                    ? "https://polygonscan.com/tx/" + appData.txId
                    : "https://etherscan.io/tx/" + appData.txId
                }
              >
                Check on blockchain explorer
                <img src="images/swap/check-on-eth.svg" alt="" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalSwapTxnDone;
