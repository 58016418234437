import React, { useState, useEffect, useContext, useRef } from "react";
import registrationContext from "../context/registrationContext";
import RecaptchaContext from "../context/RecaptchaContext";
import ReCAPTCHA from "react-google-recaptcha";
import Storage from "./Storage";
import initialState from "./../context/initialStateRegistration";

import { generateEncryptionKey } from "./vaultHelpers";

const REG_STORAGE_KEY = "registration";

function RegistrationProvider({ children }) {
  const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
  const recaptchaRef = useRef(null);
  const user = useProvideRegistration();

  return (
    <RecaptchaContext.Provider value={recaptchaRef}>
      <registrationContext.Provider value={user}>
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={SITE_KEY} />
        {children}
      </registrationContext.Provider>
    </RecaptchaContext.Provider>
  );
}

function useProvideRegistration() {
  const loadedState = Storage.load(REG_STORAGE_KEY) || initialState;

  //state for registration
  const [encryptionKey, setEncryptionKey] = useState(loadedState.encryptionKey);

  const [userEmail, setUserEmail] = useState(loadedState.userEmail);
  const [oldVersionUser, setOldVersionUser] = useState(
    loadedState.oldVersionUser
  );
  const [otpStatus, setOtpStatus] = useState(loadedState.otpStatus);
  const [signupName, setSignupName] = useState(loadedState.signupName);
  const [mnemonic, setMnemonic] = useState(loadedState.mnemonic);
  const [mnemonicArr, setMnemonicArr] = useState(loadedState.mnemonicArr);
  const [randomNumbers, setRandomNumbers] = useState(loadedState.randomNumbers);
  const [tempPassword, setTempPassword] = useState(loadedState.tempPassword);
  const [newPasswordToken, setNewPasswordToken] = useState(
    loadedState.newPasswordToken
  );
  const [pin, setPin] = useState(loadedState.pin);
  const [userVault, setUserVault] = useState(loadedState.userVault);
  const [signUpCompleted, setSignUpCompleted] = useState(
    loadedState.signUpCompleted
  );
  const [pinValidated, setPinValidated] = useState(loadedState.pinValidated);

  const reg_init = () => {
    async function regInit() {
      const stor = Storage.load(REG_STORAGE_KEY) || initialState;
      if (stor.encryptionKey === null) {
        const encryptionKeyResp = await generateEncryptionKey();
        setEncryptionKey(encryptionKeyResp);

        stor.encryptionKey = encryptionKeyResp;
        Storage.save(REG_STORAGE_KEY, stor);
      }
    }
    regInit();
  };
  useEffect(() => {
    reg_init();
  }, []);
  //functions for useRegistration
  const _regenKey = (k) => {
    return generateEncryptionKey(k);
  };

  const resetOtpStatus = () => {
    setOtpStatus({ message: null });
  };

  const resetState = (leaveEmail = false) => {
    if (!leaveEmail) {
      setUserEmail(initialState.userEmail);
    }
    generateEncryptionKey().then((encryptionKeyResp) => {
      setEncryptionKey(encryptionKeyResp);
    });
    // setLoading(false);
    setOldVersionUser(initialState.oldVersionUser);
    setOtpStatus(initialState.otpStatus);
    setSignupName(initialState.signupName);
    setMnemonic(initialState.mnemonic);
    setMnemonicArr(initialState.mnemonicArr);
    setRandomNumbers(initialState.randomNumbers);
    setTempPassword(initialState.randomNumbers);
    setNewPasswordToken(initialState.newPassworToken);
    setPin(initialState.pin);
    setUserVault(initialState.userVault);
    setSignUpCompleted(initialState.signUpCompleted);
    setPinValidated(initialState.pinValidated);
  };

  const decorateFunctions = (data, storage_keys) => {
    const exports = data;
    for (var i in storage_keys) {
      const fn = exports[i];
      const key = storage_keys[i];
      exports[i] = (val) => {
        fn(val);
        const dat = Storage.load(REG_STORAGE_KEY) || initialState;
        const stor = { ...dat, ...{ [key]: val } };
        Storage.save(REG_STORAGE_KEY, stor);
      };
    }
    return exports;
  };

  const saveStorage = {
    setUserEmail: "userEmail",
    setOldVersionUser: "oldVersionUser",
    setEncryptionKey: "encryptionKey",
    setOtpStatus: "otpStatus",
    setUserLoginState: "userLoginState",
    setSignupName: "signupName",
    setMnemonic: "mnemonic",
    setMnemonicArr: "mnemonicArr",
    setRandomNumbers: "randomNumbers",
    setTempPassword: "tempPassword",
    setNewPasswordToken: "newPasswordToken",
    setPin: "pin",
    setUserVault: "userVault",
    setSignUpCompleted: "signUpCompleted",
    setPinValidated: "pinValidated",
  };

  const providedData = {
    userEmail,
    encryptionKey,
    otpStatus,
    oldVersionUser,
    setOldVersionUser,
    setOtpStatus,
    resetOtpStatus,
    mnemonicArr,
    setMnemonicArr,
    mnemonic,
    setMnemonic,
    randomNumbers,
    setRandomNumbers,
    tempPassword,
    setTempPassword,
    newPasswordToken,
    setNewPasswordToken,
    signUpCompleted,
    setSignUpCompleted,
    pinValidated,
    setPinValidated,
    pin,
    setPin,
    userVault,
    setUserVault,
    signupName,
    setSignupName,
    _regenKey,
  };
  return { ...decorateFunctions(providedData, saveStorage), resetState };
}

function useRegistration() {
  return useContext(registrationContext);
}

function useRecaptcha() {
  return useContext(RecaptchaContext);
}

export { RegistrationProvider, useRecaptcha, useRegistration };
