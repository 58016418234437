import ActivitySearchAndDate from "../../common/ActivitySearchAndDate/ActivitySearchAndDate";
import DropdownNFTWallet from "../../common/DropdownNFTWallet/DropdownNFTWallet";
import SearchInput from "../../settings/SearchInput/SearchInput";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../helpers/AppProvider";
import { delay_code } from "../../../helpers/generalHelper";
import ROUTES from "../../../helpers/routes";
import { useUser } from "../../../helpers/UserProvider";
//import DropdownNFTWallet from "../../common/DropdownNFTWallet/DropdownNFTWallet";
import AddAssetBtn from "../AddAssetBtn/AddAssetBtn";
import AddTokenBtn from "../AddTokenBtn/AddTokenBtn";
import AssetsTabs from "../AssetsTabs/AssetsTabs";
import ReceiveNFTBtn from "../ReceiveNFTBtn/ReceiveNFTBtn";
import NetworkSelectionNew from "../../common/NetworkSelectionNew/NetworkSelectionNew";
import { useEffect } from "react";
// // import DropdownChain from "../../common/DropdownChain/DropdownChain";
const AssetsTabsOverview = ({
  btn,
  setSelectedWalletDetails,
  search,
  setSearchData,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const { actions, appData, setAppData } = useApp();
  const { userActions } = useUser();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(appData?.chains?.length > 0){
  //     const activeChain = appData?.chains?.find(
  //       (item) => item?.chain_name === "ethereum"
  //     );
  //     actions.setAppData({ activeAssetChain: "eth", activeChain: activeChain?.chainId});
  //   }
  // }, [appData?.chains]);

  return (
    <div className="assets__overview_top">
      <div className="assets__overvieew_tab_navigation">
        {btn === "nft" && (
          <div
            className="assets__overview_nav_left"
            style={{ display: btn === "activity" ? "none" : "flex" }}
          >
            <span style={{ textAlign: "left", width: 166 }}>
              {/* {btn === "asset" && <AddAssetBtn />} */}
              {btn === "nft" && <ReceiveNFTBtn />}
              {btn === "nft_collection" && (
                <img
                  src={"images/nft/NFT_back_arrow.svg"}
                  alt=""
                  className="pl-12 cursor-pointer"
                  onClick={() => navigate(-1)}
                />
              )}
            </span>

            <span className="assets__tab_separator"></span>
          </div>
        )}

        <div className="assets__overview_nav_right flex">
          {(btn === "activity" || btn === "nft") &&
            appData?.accounts?.find(
              (account) => appData?.activeWallet === account?.address
            )?.type !== "bitcoin" && (
              <NetworkSelectionNew
                extraClass="dropdown_network_assets assets__overview_nav_right"
                noBlockScroll={false}
                isNetworkOnly={true}
                networks={appData.chains?.map((chain) => {
                  return chain.chain_name;
                })}
              />
            )}

          {(btn === "nft" ||
            btn === "nft_collection" ||
            btn === "activity") && (
            <div className="assets__overview_nav_right pt-4 drpWallet">
              <DropdownNFTWallet
                extraClass="controls__dropdown-wallet controls__dropdown-wallet-nft"
                setSelectedWalletDetails={setSelectedWalletDetails}
              />
            </div>
          )}
        </div>

        {btn !== "nft_collection" && <AssetsTabs btn={btn} />}
        {/* <AssetsTabs /> */}
      </div>
    </div>
  );
};

export default AssetsTabsOverview;
