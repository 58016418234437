import React, { useEffect, useState } from "react";

// import ModalActionSuccess from './../../dialogs/ModalActionSuccess';
import ModalTransactionDone from "./../../dialogs/ModalTransactionDone";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";

const ModaSendAssetTransactionFunctions = (props) => {
  const [modalData, setData] = useModalData();
  const { appData } = useApp();

  const [trans, setTrans] = useState(false);
  const [details, setDetails] = useState(false);

  useEffect(() => {
    if (appData.transactionResult) {
      setTrans(appData.transactionResult);
    }
    if (modalData.transactionDetails) {
      setDetails(modalData.transactionDetails);
    }
  }, [modalData, appData]);

  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(SEND_ASSET_FLOW);

  const onClose = () => {
    setActiveModalNav("CLOSED");
    // props.onChangePinUpdate && props.onChangePinUpdate();
  };
  const callbackOpenPopup = (popup) => {
    // setActiveModalNav(popup);
    setActiveModalNav("CLOSED");
  };
  const callbackCancelPopup = (popup) => {
    // setActiveModalNav(popup, true);
    setActiveModalNav("CLOSED");
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //

  return (
    <>
      <ModalTransactionDone
        txnID={trans && (trans?.transactionHash || trans?.data?.hash)}
        sendTo={trans && trans.to}
        onClose={onClose}
        trans={trans}
        details={details}
        callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
        callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
        modalCancel_label="modal_close"
        modalNext_label="modal_close"
      />
      {/* <ModalActionSuccess 
                title="PIN updated" 
                desc="" 
                dataClass="modal_action_success1" 
                onClose={onClose}
                callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
                // modalNext_label='modal_email_address_failed'
                // callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
                /> */}
    </>
  );
};

export default ModaSendAssetTransactionFunctions;
