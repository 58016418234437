import React, { useState, useEffect } from "react";
import CopyAddress from "./../../../common/CopyAddress/CopyAddress";
import TooltipCopy from "../../../registration/TooltipCopy";
import QRCode from "./../../../common/QRCode/QRCode";
import MainModal from "./../../../generic/MainModal";
import { copyToClipboard } from "./../../../../helpers/vaultHelpers";
import cssFix from "./../../../../helpers/cssFix";
import QRCodeImage from "qrcode";

const ModalWalletSafleID = ({ safleID, addr, onClose }) => {
  const [qrImage, setQrImage] = useState("");

  const generateQrAddress = async () => {
    var durl = await QRCodeImage.toDataURL(`ethereum:${addr}`, {
      errorCorrectionLevel: "L",
      mode: "alphanumeric",
      margin: 0,
    });
    // $el.find('.qr_area').html(`<img src="${durl}" alt="scan this qr code" />`);
    setQrImage(durl);
  };
  useEffect(() => {
    generateQrAddress();
  }, []);

  const copySafleID = (e) => {
    e.preventDefault();
    copyToClipboard(safleID);
  };

  return (
    <MainModal
      extraClasses={[
        "modal_w400",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_wallet_details",
        "modal_ovv",
      ]}
      onClose={() => onClose && onClose()}
      dataClass="modal_wallet_safleid"
    >
      <cssFix />
      <div className="modal_wallet_details_content">
        <div className="modal_wallet_details_rows mb_30">
          <div className="modal__receive__heading">SafleID wallet</div>
        </div>
        <div className="modal_wallet_details_row ">
          <div className="user__box-name">
            <span className="colored">{safleID}</span>

            {/* <span className="icon_copy_field icon-icon_copy" onClick={copySafleID}></span> */}
            <TooltipCopy
              extraClasses="tooltip_wallet_details tooltip_registration tooltip_seedphrase cursor-pointer tooltip_webapp_copy justify-center tooltip_safle_wallet_id_fix"
              textToDisplay={""}
              imageToDisplay={
                <span className="icon_copy_field icon-icon_copy"></span>
              }
              tooltipTextIn={"Copy to clipboard"}
              tooltipTextInChange={"Copied to clipboard"}
              copiedText={safleID}
              hideAfter="3000"
            />
          </div>
        </div>
        <div className="modal_wallet_details_row mb-30">
          <QRCode qrImage={qrImage} />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalWalletSafleID;
