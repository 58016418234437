import React, { useState } from 'react';
import MainModal from '../generic/MainModal';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import { ArrowDownIcon } from '@heroicons/react/outline';
import { useRecaptcha, useUser } from '../../helpers/UserProvider';
import PasswordInput from "../settings/PasswordInput/PasswordInput";

import {
  LockClosedIcon,
  EyeIcon,
  EyeOffIcon
} from '@heroicons/react/solid';
import { useApp } from '../../helpers/AppProvider';
import { useModalData } from '../../helpers/useModalNavigation';

const ModalChangeIDConfirm = ( { onClose, callbackOpenPopup, newSafleId='', currentSafleId='' } ) => {
  const recaptchaRef = useRecaptcha();
  const { userData, userActions } = useUser();
  const { appData, actions } = useApp();
  const [ modalData, setData ] = useModalData();

  const [ password, setPassword] = useState('');
  const [ passwordShown, setPasswordShown ] = useState( false );
  const [ passwordErrMsg, setPasswordErrMsg ] = useState('');

  const verifyPassword = async () => {
    actions.setLoading( true );
    const token = await recaptchaRef.current.executeAsync();
    const resp = await userActions.verifyPassword( userData.user.safleID, password, token );
    actions.setLoading( false );

    if( resp ){
      setData({password: password });
      callbackOpenPopup('change_safleid_pin');
    } else {
      setPasswordErrMsg('Password is not correct.');
    }
  }

  return (
    <MainModal onClose={ onClose } extraClasses={ [ 'modal_w440', 'modal_p30', 'modal_dflex', 'modal_change_safle', 'modal_change_safle_confirm' ] } dataClass="modal_change_id_confirm" >
      <div className="modal_succes_content">
        <div className="modal_succes_head pt_60">Update SafleID</div>
        <div className="modal_succes_desc">
          <span className="simple_text simple_text_centered">This SafleID is available! Press confirm <br/> to get it.</span>
        </div>
        <div className="modal--padding modal_content">
            <div className="modal-row old-safleid-ctn">
                <div className="modal-label">Old SafleID</div>
                <div className="modal-safleid old-safleid">{currentSafleId}</div>
            </div>
            <ArrowDownIcon className="modal-row-divider" />
            <div className="modal-row new-safleid-ctn">
                <div className="modal-label">New SafleID</div>
                <div className="modal-safleid new-safleid"><span>{newSafleId}</span></div>
            </div>
              <div className="settings__overview_change_pin" style={{position: 'relative', paddingTop: 40}}>
                <div className="form-default">
                  <PasswordInput  
                    label='Enter password to confirm'
                    placeholder="Enter Password"
                    type={passwordShown ? 'text' : 'password'}
                    icon={
                      <img src = {'images/settings/changeIDPasswordLock.svg'} alt='' />
                      //<LockClosedIcon
                      //  className='h-5 w-5 text-gray-400 icon_dark_gray text-[2xl]'
                      //  aria-hidden='true'
                      ///>
                    }
                    showIcon = {true}
                    hiddenEye={<EyeOffIcon onClick={() => setPasswordShown(true)} className={passwordShown ? 'h-6 w-6 text-gray-400 hidden' : 'h-6 w-6 text-gray-400'} aria-hidden='true' />}
                    visibleEye={<EyeIcon onClick={() => setPasswordShown(false)} className={!passwordShown ? 'h-6 w-6 text-gray-400 hidden' : 'h-6 w-6 text-gray-400'} aria-hidden='true' />}
                    value={password}
                    setValue={setPassword}
                    // valid={validPassword}
                    // onChange={handleChangeNewPin} 
                    errorMsg={passwordErrMsg}
                />
                </div>
              </div>
        </div>
        <div className="modal_succes_content_actions ">
          <PrimaryBtn
            classes={ 'btn-default btn-blue modal__trigge' }
            label='Confirm'
            onClick={() => verifyPassword() /*callbackOpenPopup('change_safleid_pin')*/}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalChangeIDConfirm;