import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../components/registration/Footer/Footer";
import Modal from "../../components/registration/Modal/Modal";

import Seeds from "./../../components/registration/Seeds/Seeds";
import Toast from "../../components/registration/Toast/Toast";
import Button from "../../components/registration/Button/Button";
import "../../styles/registration/mnemonicPhrase.scss";
import "../../styles/registration/toast.scss";

import CssFix from "./../../helpers/cssFix";
import ROUTES from "./../../helpers/routes";
import { generateRandomNumber } from "../../helpers/vaultHelpers";
import { useRegistration } from "../../helpers/RegistrationProvider";
import { useUser } from "../../helpers/UserProvider";
import { useApp } from "../../helpers/AppProvider";
import { useFlowGate } from "../../components/flow/FlowGate";

export const MnemonicStep1 = ({ history }) => {
  const { advance } = useFlowGate();
  const { signupName } = useRegistration();
  const [accepted, setAccepted] = React.useState(false);

  const onAccept = (e) => {
    setAccepted(e.currentTarget.checked);
  };
  const onSubmit = (e) => {
    advance();
  };

  return (
    <div className="w-full lg:w-2/5 lg:mr-12 relative">
      <div className="mb-8">
        <img
          alt="safle logo"
          className="mb-4 h-8 w-20 hidden sm:block"
          src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
        />
      </div>
      <h1 className="lg:text-justify font-medium mnemonic_border mnemonic_h pb-4 mb-6">
        Hi {signupName}
        <p className={"mt-4 text-xs text-left mnemonic_p"}>
          We are going to display your seed phrase in the next step. Seed phrase
          is the only way to recover your wallet. Keep it safe &amp; secure.
        </p>
      </h1>
      <div className="mt-5 flex items-start">
        <div className="flex items-center h-5">
          <input
            id="offers"
            name="offers"
            type="checkbox"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            onChange={onAccept}
          />
        </div>
        <div className="ml-3 text-sm mb-4">
          <label
            htmlFor="offers"
            className="text-sm font-smaller mnemonic_label_checkbox"
          >
            I understand
          </label>
        </div>
      </div>
      {!accepted && (
        <Button
          text="Show seed phrase"
          disabled="disabled"
          size="lg"
          fullwidth
          style={{ opacity: 0.4 }}
        />
      )}
      {accepted && (
        <Button
          text="Show seed phrase"
          size="lg"
          fullwidth
          onClick={() => onSubmit()}
        />
      )}
    </div>
  );
};

export const MnemonicStep2 = ({ history }) => {
  const { advance } = useFlowGate();
  const { userActions } = useUser();
  const { mnemonicArr, setMnemonicArr, setMnemonic, setRandomNumbers } =
    useRegistration();

  const [toastIsOpen, setToastIsOpen] = useState(false);
  useEffect(() => {
    async function getMnemonic() {
      const phrases = await userActions.getMnemonic();
      setMnemonic(phrases);
      setMnemonicArr(phrases.split(" "));
    }

    getMnemonic();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRegenerateSeed = async () => {
    const phrases = await userActions.getMnemonic();
    setMnemonic(phrases);
    setMnemonicArr(phrases.split(" "));
    setToastIsOpen(true);
  };

  const handleNext = () => {
    const { response } = generateRandomNumber();
    setRandomNumbers(response);
    advance();
  };

  return (
    <div className="w-full lg:w-2/5 lg:mr-12 pb-10">
      <div className="mb-8">
        <img
          alt="safle logo"
          className="mb-4 h-8 w-20 hidden sm:block"
          src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
        />
      </div>
      <h1 className="lg:text-justify font-medium mnemonic_border mnemonic_h pb-4 mb-6">
        Your seed phrase
        <p className={"mt-4 text-xs text-left mnemonic_p"}>
          Seed phrase is the only way to recover your wallet. Keep it safe &amp;
          secure.
        </p>
      </h1>
      {mnemonicArr && (
        <Seeds
          withBlur={false}
          mnemonic={mnemonicArr}
          handleRegenerateSeed={handleRegenerateSeed}
        />
      )}
      <Button text="Next" size="lg" fullwidth onClick={handleNext} />
      <Toast
        title=""
        bodyText=""
        hideAfter="5000"
        isOpen={toastIsOpen}
        setOpen={(val) => setToastIsOpen(val)}
        seedPhrase={true}
      />
    </div>
  );
};

export const MnemonicStep3 = ({ history }) => {
  const { advance } = useFlowGate();
  const { actions } = useApp();
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const { mnemonicArr, randomNumbers } = useRegistration();
  const [confirmSeeds, setConfirmSeeds] = useState({});

  const en = Object.values(confirmSeeds).filter((e) => e !== "").length !== 2;

  useEffect(() => {
    actions.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateConfirm = (nr, val) => {
    setError(null);
    const d = confirmSeeds;
    d[nr] = val;
    setConfirmSeeds({ ...d });
  };
  const handleConfirmSeeds = () => {
    setError(null);
    let seedsOk = true;
    for (var i in confirmSeeds) {
      if (confirmSeeds[i] !== mnemonicArr[i]) {
        seedsOk = false;
      }
    }

    if (!seedsOk) {
      setError("You have entered wrong seed phrase");
    } else {
      navigate(ROUTES.SETUP_PIN, { replace: true });
      advance();
    }
  };
  return (
    <div className="w-full lg:w-2/5 lg:mr-12 pb-10">
      <div className="mb-8">
        <img
          alt="safle logo"
          className="mb-4 h-8 w-20 hidden sm:block"
          src={`${process.env.PUBLIC_URL}/images/registration/img/logo-dark.svg`}
        />
      </div>
      <h1 className="lg:text-justify font-medium mnemonic_border mnemonic_h pb-4 mb-6">
        Verify seed phrase
        <p className={"mt-4 text-xs text-left mnemonic_p"}>
          Please enter the corresponding word from your seed phrase in the blank
          spaces.
        </p>
      </h1>
      <Seeds
        withBlur={true}
        mnemonic={[]}
        randomNumbers={randomNumbers}
        setConfirmSeeds={updateConfirm}
        confirmSeeds={confirmSeeds}
      />

      {error !== null && (
        <div className="flex my-3 lg:block seed_error_msg">{error}</div>
      )}
      <div className="mt-6"></div>
      <Button
        text="Submit"
        size="lg"
        disabled={en}
        style={{ opacity: en ? 0.5 : 1 }}
        fullwidth
        onClick={handleConfirmSeeds}
      />
    </div>
  );
};

const MnemonicPhraseScreen = ({ history }) => {
  const { step_number } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const getStep = (step_number) => {
    var compononent2Return = null;
    if (step_number === 1) {
      compononent2Return = <MnemonicStep1 history={history} />;
    } else if (step_number === 2) {
      compononent2Return = <MnemonicStep2 history={history} />;
    } else if (step_number === 3) {
      compononent2Return = <MnemonicStep3 history={history} />;
    }
    return compononent2Return;
  };

  return (
    <div>
      <CssFix />
      <div className="bg_linear_inner z-0">
        <div className="max-w-6xl mx-auto px-4 py-12 sm:px-20 lg:px-8">
          <div className="flex justify-center mb-2">
            <img
              alt="safle logo"
              className="mb-4 h-8 w-20 md:hidden sm:block"
              src={`${process.env.PUBLIC_URL}/images/registration/img/logo.svg`}
            />
          </div>
          <div className="md:h-full bg-white shadow rounded-2xl relative ">
            <div
              className="py-8 px-5 sm:py-12 lg:pt-12 lg:pb-0 sm:px-12 lg:px-24 rounded-2xl"
              style={{
                backgroundImage:
                  "url(" +
                  `${process.env.PUBLIC_URL}/images/registration/img/img2@2x.png` +
                  ")",
                backgroundSize: "40%",
                backgroundPosition: "100% bottom",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex">
                {getStep(parseInt(step_number))}
                <div className="justify-center w-0.5 lg:h-96 lg:my-20 mx-10 hidden lg:block "></div>
              </div>
            </div>
            <img
              alt="rocket"
              src={`${process.env.PUBLIC_URL}/images/registration/img/rocket.png`}
              className="h-22 w-40 absolute -right-8 top-32 z-10 hidden md:block"
            />
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        title="Install Safle Wallet for Chrome"
        bodyText=""
        bodyTextChrome={
          "To continue the work of your browser you should install the extension."
        }
        callToActionText="Install"
        extraClass={"install_safle_chrome"}
        isOpen={modalIsOpen}
        setOpen={(val) => setModalIsOpen(val)}
      />
    </div>
  );
};

export default MnemonicPhraseScreen;
