/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import Header from "../components/common/Header/Header";
import Sidebar from "../components/common/Sidebar/Sidebar";
import AssetHeader from "../components/assets/AssetHeader/AssetHeader";
import AssetDetailGraph from "../components/assets/AssetDetailGraph/AssetDetailGraph";
import AssetDetailWorth from "../components/assets/AssetDetailWorth/AssetDetailWorth";
import AssetDetailHistory from "../components/assets/AssetDetailHistory/AssetDetailHistory";

import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";
import { useApp } from "../helpers/AppProvider";
import { useUser } from "../helpers/UserProvider";
import ActivityHistoryItem from "../components/assets/ActivityHistoryItem/ActivityHistoryItem";
import ActivityHistoryItemFake from "../components/assets/ActivityHistoryItemFake/ActivityHistoryItemFake";
import { useLocation } from "react-router-dom";
import { fetchFavouriteTokens } from "../helpers/assetHelper";
import useModalNavigation, {
  useModalData,
} from "../helpers/useModalNavigation";
import {
  BUY_TRANSAK_FLOW,
  RECEIVED_ASSET_FLOW,
  SEND_ASSET_FLOW,
} from "../constants/ui";
import AssetsActionsHistory from "../components/assets/AssetsActionsHistory/AssetsActionsHistory";
import AssetsBuyAssetProcess from "../components/common/AssetsBuyAssetProcess/AssetsBuyAssetProcess";
import AssetsSendAssetProcess from "../components/common/AssetsSendAssetProcess/AssetsSendAssetProcess";
import AssetsReceiveAssetProcess from "../components/common/AssetsReceiveAssetProcess/AssetsReceiveAssetProcess";
import BuyTransakProcess from "../components/common/BuyTransakProcess/BuyTransakProcess";
import DropdownWalletNew from "../components/common/DropdownWalletNew/DropdownWalletNew";

const AssetDetailScreen = () => {
  const location = useLocation();
  const { symbol, chain, chainContractAddress } = location.state;

  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();
  // eslint-disable-next-line no-unused-vars
  const [tokenData, setTokenData] = useState();

  const [ohlcvData, setOhlcvData] = useState(null);
  const name = symbol; // to do
  const { appData, actions } = useApp();
  const { userData } = useUser();
  const [highlighted, setHighlightedItem] = useState(false);
  const [favouriteTokens, setFavouriteTokens] = useState([]);
  const [assetActivity, setAssetActivity] = useState([]);
  const [setActiveBuyTransakModalNav] = useModalNavigation(BUY_TRANSAK_FLOW);
  const [setActiveModalNav] = useModalNavigation(SEND_ASSET_FLOW);
  const [setReceiveModalNav] = useModalNavigation(RECEIVED_ASSET_FLOW);
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [userCustomTokens, setUserCustomTokens] = useState([]);
  const [selectedAssetInfo, setSelectedAssetInfo] = useState();
  const [balanceAsset, setBalanceAsset] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const resizeFn = () => {
      if (window.innerWidth < 1020) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", resizeFn);
    resizeFn();

    return () => {
      window.removeEventListener("resize", resizeFn);
    };
  }, []);

  const openBuyTransakModal = (e) => {
    e.stopPropagation();
    setActiveBuyTransakModalNav("modal_buy_transak");
  };
  const openSendAssetModal = (e) => {
    e.stopPropagation();
    setActiveModalNav("modal_send_asset");
  };
  const openReceiveAssetModal = (e) => {
    e.stopPropagation();
    setReceiveModalNav("modal_receive_asset");
  };
  const setActiveAsset = () => {
    actions.setAppData({ activeAsset: symbol, activeAssetChain: chain });
    setModalData({ ...location.state, ...selectedAsset });
  };

  const isSupportedCurrency = useMemo(() => {
    return appData.transakSupportedCurrencies?.find(
      (el) => el.symbol === symbol && el.symbol !== "BTC"
    );
  }, [appData.transakSupportedCurrencies, symbol]);

  const onClickRow = (e) => {
    setHighlightedItem(e === highlighted ? false : e);
  };

  useEffect(() => {
    if (appData?.regStatus === "complete") {
      actions.getTransakSupportedCurrencies();
      actions.getPriceDataNativeCoins();
    }
  }, [appData?.regStatus]);

  useEffect(() => {
    const fetchBalance = async () => {
      setLoading(true);

      if (appData?.accounts?.length > 0 && appData?.chains?.length > 0) {
        const publicAddress = appData?.activeWallet
          ? appData?.activeWallet
          : appData?.accounts[0]?.address;
        const chainName = appData?.chains?.find((chainItem) =>
          appData?.activeChain
            ? chainItem?.chainId === appData?.activeChain
            : chainItem?.chain_name === (chain === "eth" ? "ethereum" : chain)
        );

        let checkChainName = chainName?.chain_name;
        if (checkChainName === "ethereum") {
          checkChainName = "eth";
        }
        if (symbol === "BTC") {
          checkChainName = "bitcoin";
        }

        const tokenContractAddress = chainContractAddress?.find(
          (item) => item?.chain === checkChainName
        )?.token_address;

        let assetInfo;
        if (symbol === "BTC") {
          assetInfo = await actions.getAssetInfo(
            symbol,
            publicAddress,
            checkChainName
          );
        } else {
          if (
            (chainName?.details?.symbol === symbol &&
              symbol !== "ARB" &&
              symbol !== "OP") ||
            (symbol === "ETH" &&
              (chainName?.details?.symbol === "ARB" ||
                chainName?.details?.symbol === "OP"))
          ) {
            assetInfo = await actions.getAssetInfo(
              symbol,
              publicAddress,
              checkChainName
            );
          } else {
            assetInfo = await actions.getAssetInfo(
              symbol,
              publicAddress,
              checkChainName,
              tokenContractAddress
            );
            if (!tokenContractAddress) {
              assetInfo = { ...assetInfo, balance: 0, txns: [] };
            }
          }
        }
        setOhlcvData(assetInfo?.info?.ohlcvData);
        setSelectedAssetInfo(assetInfo?.info);
        setSelectedAsset(assetInfo);
        let upDatedAssetInfo = assetInfo?.txns?.map((obj) => {
          return { ...obj, chain: checkChainName };
        });
        setAssetActivity(upDatedAssetInfo);

        setBalanceAsset(assetInfo?.balance);
        setLoading(false);
      }
    };

    fetchBalance();
  }, [appData.accounts, appData.activeWallet, appData.activeChain]);

  const getCandleStickOhlcvData = (ohlcvData) => {
    const res = ohlcvData?.map((quotes) => {
      let res = Object.values(quotes?.quote)[0];
      res.date = new Date(res.timestamp);
      return res;
    });
    return res;
  };
  useEffect(() => {
    ohlcvData && getCandleStickOhlcvData(ohlcvData);
  }, [ohlcvData]);

  const fetchFavourites = () => {
    const res = fetchFavouriteTokens(userData).then((res) => {
      setFavouriteTokens(res?.data?.favourites);
      return res;
    });
    setFavouriteTokens([]);
    return res;
  };

  useEffect(() => {
    fetchFavourites();
  }, []);

  return (
    <div>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div className="main__content__wrapper asset_single">
        <Sidebar
          sidebarActive={sidebarActive}
          setSidebarActive={setSidebarActive}
        />
        {/* <!-- main content --> */}
        {sidebarActive && <div className="mobile_blur"></div>}
        <div className="main__content__wrp assets__overview-wrapper assets__overview-single">
          <div className="assets__overview">
            <AssetHeader
              name={name}
              symbol={symbol}
              favouriteTokens={favouriteTokens}
              tokenData={tokenData}
              fetchFavourites={fetchFavourites}
              addedTokens={userCustomTokens}
              selectedAsset={selectedAsset}
              chain={chain}
            />
            <div className="assets__overview_content">
              <div className="assets_overview_single_top_content">
                <div className="assets__overview_single-details-mobile-worth"></div>
                <AssetDetailGraph
                  initialData={getCandleStickOhlcvData(ohlcvData)}
                  loadingState={loading}
                />
                <div className="assets__overview_single-details">
                  <div
                    className={
                      isMobile
                        ? "flex flex-row items-center"
                        : "flex flex-col items-center w-[100%] justify-center"
                    }
                  >
                    <div
                      className={
                        isMobile ? "flex-1 mb-[10px]" : "w-[100%] pb-[10px]"
                      }
                    >
                      <DropdownWalletNew
                        extraClass="dropdown_wallet_assets w-[100%]"
                        compType="sendAssets"
                        icon="images/wallet-icon.svg"
                        noBlockScroll={false}
                        symbol={symbol}
                        onSelect={(e) =>
                          actions.setAppData({
                            activeWallet: e?.wallet_address,
                          })
                        }
                      />
                    </div>
                    <AssetDetailWorth
                      latestPrice={selectedAssetInfo}
                      symbol={symbol}
                      assetAmount={balanceAsset}
                    />

                    <div
                      className={`${
                        !isMobile
                          ? "aoc_table_col order-3"
                          : "aoc_table_col mb-[10px] flex-1"
                      }`}
                      data-cls={`${
                        !isMobile ? "asset_actions" : "asset_actions"
                      }`}
                    >
                      <AssetsActionsHistory
                        item={{ symbol, chain }}
                        openBuyTransakModal={openBuyTransakModal}
                        openSendAssetModal={openSendAssetModal}
                        openReceiveAssetModal={openReceiveAssetModal}
                        setActiveAsset={setActiveAsset}
                        isSupportedCurrency={isSupportedCurrency}
                        isCurrencySupportsChain={chain ? true : false}
                      />
                    </div>
                    {!isMobile && (
                      <AssetDetailHistory
                        latestPrice={selectedAssetInfo}
                        ohlcvData={ohlcvData}
                        symbol={symbol}
                      />
                    )}
                  </div>
                  {isMobile && (
                    <AssetDetailHistory
                      latestPrice={selectedAssetInfo}
                      ohlcvData={ohlcvData}
                      symbol={symbol}
                    />
                  )}
                </div>
              </div>
              <div className="assets_overview_single_bottom_content">
                <div className="assets__overview_content_tab content_tab-activity show">
                  <div className={"assets__overview_content-filtered_items "}>
                    <div className="sticky_phantom_left">
                      {assetActivity?.length !== 0 &&
                        assetActivity
                          ?.flat()
                          ?.map((item, index) => (
                            <ActivityHistoryItemFake
                              key={index}
                              highlight={
                                index !== false && index + 1 === highlighted
                              }
                              item={item}
                            />
                          ))}
                    </div>
                    <div className="sticky_items_wrapper">
                      <div className="sticky_mobile_header">
                        <div className="assets__overview_content-filtered_items_row assets__overview_content-filtered_items_heading">
                          <div className="sticky_mobile gray_bg">
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_name"
                            >
                              <span>Asset Name</span>
                            </div>
                          </div>
                          <div className="scrollable_mobile">
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_name"
                            >
                              <span>Asset Name</span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_time"
                            >
                              <span>Time </span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_activity"
                            >
                              <span>Type Activity</span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_amount"
                            >
                              <span>Amount</span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_fees"
                            >
                              <span>Fees</span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_status"
                            >
                              <span>Status</span>
                            </div>
                            <div
                              className="aoc_table_col"
                              data-cls="activity_asset_view_details"
                            >
                              <span>View Details</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="scrollable_mobile_inner_fileter_items">
                        {assetActivity?.length > 0 &&
                          assetActivity
                            ?.flat()
                            ?.map((item, index) => (
                              <ActivityHistoryItem
                                key={index}
                                highlight={
                                  index !== false && index + 1 === highlighted
                                }
                                item={item}
                                clickHandle={() => onClickRow(index + 1)}
                              />
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end main content --> */}
        {/* <!-- aside notification --> */}
        <SidebarNotification />
        <AssetsBuyAssetProcess />
        <AssetsSendAssetProcess />
        <AssetsReceiveAssetProcess />
        <BuyTransakProcess />
        {/* <!-- end aside notification --> */}
      </div>
    </div>
  );
};

export default AssetDetailScreen;
