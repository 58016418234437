import React, { useEffect, useState } from "react";
import MainModal from "./../generic/MainModal";

import CheckIcon from "@heroicons/react/solid/CheckIcon";

const ModalAddWallet = (props) => {
  const [importGenerate, setImportGenerate] = useState("default");
  const [importCls, setImportCls] = useState("default");
  const [generateCls, setgenerateCls] = useState("default");

  useEffect(() => {
    if (importGenerate === "import") {
      setImportCls("modal_button_active");
      setgenerateCls("");
    }
    if (importGenerate === "generate") {
      setImportCls("");
      setgenerateCls("modal_button_active");
    }
    if (importGenerate === "default") {
      setImportCls("");
      setgenerateCls("");
    }
  }, [importGenerate]);

  return (
    <MainModal
      // on first modal we don't need a confirmation as we've not choosing any option
      onClose={() => props.onClose()}
      extraClasses={[
        "modal_w520 modal_p30",
        "modal_dflex",
        "modal_add_wallet",
        "modal_add_wallet_select",
      ]}
      dataClass="modal_add_wallet"
    >
      {/* Modal content */}
      <div className="modal_add_wallet_title">Choose Your Wallet</div>
      <div className="modal_add_wallet_wrapper p-5">
        <div
          // onClick={() => clickBox('import')}
          onClick={() =>
            importGenerate === "import"
              ? setImportGenerate("default")
              : setImportGenerate("import")
          }
          className={
            "modal_add_wallet_col modal_add_wallet_col_white modal__trigger " +
            importCls
          }
        >
          <div className="modal_button_check">
            <CheckIcon />
          </div>
          <div className="modal__add_wallet_ico ">
            <img src="images/import-wallet_pop.svg" alt="" />
          </div>
          <div className="modal__add_wallet_label">Import Wallet</div>
        </div>
        <div
          // onClick={() => clickBox('generate')}
          onClick={() =>
            importGenerate === "generate"
              ? setImportGenerate("default")
              : setImportGenerate("generate")
          }
          className={
            "modal_add_wallet_col modal_add_wallet_col_white modal__trigger " +
            generateCls
          }
        >
          <div className="modal_button_check">
            <CheckIcon />
          </div>
          <div className="modal__add_wallet_ico">
            <img src="images/generate-wallet_pop.svg" alt="" />
          </div>
          <div className="modal__add_wallet_label">
            <span className="desktop">Generate new</span>
            <span className="mobile">Add wallet</span>
          </div>
        </div>
      </div>
      <div className="modal_add_wallet_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={() => props.onClose()}
          >
            <span className="desktop">Cancel</span>
            <span className="mobile">Back</span>
          </button>

          <button
            className={
              "btn-default btn-blue modal__trigger " +
              (importGenerate !== "default" ? "" : " disable_btn ")
            }
            onClick={() =>
              importGenerate === "import"
                ? props.callbackOpenPopup("modal_import_wallet")
                : props.callbackOpenPopup("modal_generate_new_wallet")
            }
          >
            Confirm
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalAddWallet;
