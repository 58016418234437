import React, { useState, useEffect, useRef } from "react";
// import { useApp } from "./../../../helpers/AppProvider";
import { useNavigate } from "react-router-dom";
import ROUTES from "./../../../helpers/routes";
import { ReactComponent as All } from "../Icons/all.svg";
import { ReactComponent as NFT } from "../Icons/nft.svg";
import { ReactComponent as Activity } from "../Icons/activity.svg";

const AssetsTabs = (attrs) => {
  let navigate = useNavigate();
  const props = attrs;

  // const { actions, appData } = useApp();
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    "main__controls controls__dropdown controls__dropdown-filters-assets " +
    isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(!isActive);
      }
    };
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isActive]);

  // on click item in dropdown do this ;
  // const onClickItem = (index, action_callback) => {
  //   setIsActive(!isActive);
  //   actions.setAppData({ ...appData, blockScroll: false });
  // };

  const goTo = (screen) => {
    navigate(screen, { replace: true });
  };

  return (
    <>
      <div className="assets_view_top_navigation_desktop">
        <div
          className={
            "whitespace-nowrap	assets__view" +
            (props.btn === "asset" ? " active" : "")
          }
          onClick={() => goTo(ROUTES.ASSETS)}
        >
          <All className="assets__view__icon" /> All Assets
        </div>
        <div
          className={"assets__view" + (props.btn === "nft" ? " active" : "")}
          onClick={() => goTo(ROUTES.NFTS)}
        >
          <NFT className="assets__view__icon" /> NFT
        </div>
        <div
          className={
            "assets__view" + (props.btn === "activity" ? " active" : "")
          }
          onClick={() => goTo(ROUTES.ACTIVITY)}
        >
          <Activity className="assets__view__icon" /> Activity
        </div>
      </div>
      <div className="assets_view_top_navigation_mobile">
        <div
          className={finalDrpClasses}
          ref={dropdownRef}
          tabIndex="0"
          onClick={onClick}
        >
          <div className="dropdown__active__item filters_assets_trigger">
            {props.btn === "asset" && (
              <div className="nav_icon_holder">
                <span className="icon_nav_size icon-assets_all"></span>
                <span className="label">All Assets</span>
                <span className="icon-icon-chevron"></span>
              </div>
            )}
            {props.btn === "nft" && (
              <div className="nav_icon_holder">
                <span className="icon_nav_size icon-assets_nft"></span>
                <span className="label">NFT</span>
                <span className="icon-icon-chevron"></span>
              </div>
            )}
            {props.btn === "activity" && (
              <div className="nav_icon_holder">
                <span className="icon_nav_size icon-assets_actvity"></span>
                <span className="label">Activity</span>
                <span className="icon-icon-chevron"></span>
              </div>
            )}
          </div>
          <div className="dropdown__list__items dropdown__list__items_filters_assets">
            <div
              className={
                "assets__view_mobile" + (props.btn === "asset" ? " active" : "")
              }
              onClick={() => goTo(ROUTES.ASSETS)}
            >
              <span className="icon_nav_size icon-assets_all"></span>
              <span className="label">All Assets</span>
            </div>
            <div
              className={
                "assets__view_mobile" + (props.btn === "nft" ? " active" : "")
              }
              onClick={() => goTo(ROUTES.NFTS)}
            >
              <span className="icon_nav_size icon-assets_nft"></span>
              <span className="label">NFT</span>
            </div>
            <div
              className={
                "assets__view_mobile" +
                (props.btn === "activity" ? " active" : "")
              }
              onClick={() => goTo(ROUTES.ACTIVITY)}
            >
              <span className="icon_nav_size icon-assets_actvity"></span>
              <span className="label">Activity</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetsTabs;
