import React, { useEffect } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import { SEND_ASSET_FLOW, DEFAULT_MODAL_STATUS } from "../../../constants/ui";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import Storage from "../../../helpers/Storage";

const ModalSendAssetEnterPinFunctions = () => {
  const { actions } = useApp();
  const { userData, userActions } = useUser();
  const [modalData, setData] = useModalData();
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(SEND_ASSET_FLOW);

  const onClose = () => {
    // setActiveModalNav('CLOSED');
    setActiveModalNav("modal_send_asset_cancel", true);
  };
  const callbackOpenPopup = async (pin) => {
    actions.setAppData({
      transactionResult: null,
      pendingSendTxId: null,
      pendingSendConfirmation: true,
    });

    setActiveModalNav("modal_send_asset_transaction_done");
    //send transaction

    if (modalData.hasOwnProperty("transactionDetails")) {
      try {
        const vault = await userActions.getTransactionVault(pin);
        const enckey = userActions.getDecriptionKey(); // decrypted encryption key

        const trans = await actions.sendTransaction(
          modalData.transactionDetails,
          pin,
          vault,
          enckey
        );
      } catch (e) {
        setData({ transactionResult: e.message });
        actions.setAppData({ transactionResult: e.message });
        // setActiveModalNav('modal_send_asset_failed');
      } finally {
        actions.setLoading(false);
      }
    }

    // setActiveModalNav(popup);
    // setActiveModalNav('modal_send_asset_transaction_done');
  };

  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);

  return (
    <ModalEnterPin
      onClose={onClose}
      // onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="modal_send_asset_cancel"
      // modalNext_label='modal_send_asset_transaction_done'
    />
  );
};

export default ModalSendAssetEnterPinFunctions;
