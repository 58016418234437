import React, { useEffect }  from 'react';

import ModalActionFailed from './../../dialogs/ModalActionFailed';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { SETTINGS_CHANGE_NAME, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalNameUpdatedFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SETTINGS_CHANGE_NAME);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    

    return (
        <ModalActionFailed title="Name is not updated" 
        desc="" dataClass="modal_action_failed2" 
        onClose={ onClose } 
        iconErr={'images/wallets/update-failed-warning.svg'}
        /> 
    )
}

export default ModalNameUpdatedFunctions;