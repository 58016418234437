import React, { useState } from 'react';
import ModalProfileUpdate from '../components/dialogs/ModalProfileUpdate';
import ModalChangeID from '../components/dialogs/ModalChangeID';
import ModalPinConfirm from '../components/dialogs/ModalPinConfirm';
import ModalSeedPhrase from '../components/dialogs/ModalSeedPhrase';
import ModalChangeIDColored from '../components/dialogs/ModalChangeIDColored';
import ModalChangeIDConfirm from '../components/dialogs/ModalChangeIDConfirm';
import ModalEnterSeed from '../components/dialogs/ModalEnterSeed';
import ModalActionSuccess from '../components/dialogs/ModalActionSuccess';
import ModalActionFailed from '../components/dialogs/ModalActionFailed';
import ModalActionConfirm from '../components/dialogs/ModalActionConfirm';
import ModalSetNewPin from '../components/dialogs/ModalSetNewPin';
import ModalChangeIDTxnDone from '../components/dialogs/ModalChangeIDTxnDone';
import ModalLinkWallet from '../components/dialogs/ModalLinkWallet';

const SettingsDialogScreen = () => {

  // openedPopup state hook
  const [ openedPopup, setOpenedPopup ] = useState( null );

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup( false );
  };

  // function for opening the popup
  const openPopup = ( popup ) => {
    setOpenedPopup( popup );
  };

  return (
    <>
      <div>
        <h1>Settings dialogs</h1>

        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_profile_update0' ) }>Profile Update0</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_profile_update1' ) }>Profile Update1</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_profile_update2' ) }>Profile Update2</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_profile_update3' ) }>Profile Update3</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_profile_update4' ) }>Profile Update4</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_enter_pin' ) }>ModalPinConfirm</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_change_id' ) }>ModalChangeID</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_change_id_colored' ) }>ModalChangeIDColored</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_change_id_confirm' ) }>ModalChangeIDConfirm</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_seed_phrase' ) }>ModalSeedPhrase</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_enter_seed' ) }>ModalEnterSeed</button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_success1' ) }>ModalActionSuccess - Email ID </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_success2' ) }>ModalActionSuccess - Name </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_success3' ) }>ModalActionSuccess - Password </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_success4' ) }>ModalActionSuccess - PIN </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_success5' ) }>ModalActionSuccess - Successful </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_failed1' ) }>ModalActionFailed - Email </button>
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_failed2' ) }>ModalActionFailed - Name </button>
        <br /><br />
        { <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_confirm' ) }>ModalActionConfirm - Email</button> }
        <br /><br />
        { <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_action_confirm1' ) }>ModalActionConfirm - Edit Name</button> }
        <br /><br />
        { <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_set_new_pin' ) }>ModalSetNewPin</button> }
        <br /><br />
        { <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_changeid_txn_done' ) }>ModalChangeIDTxnDone</button> }
        <br /><br />
        <button className="control__button btn_blue" onClick={ () => openPopup( 'modal_link_to_safle_id' ) }>Link SafleID</button>
        <br /><br />
        
      </div>

      {/* example of popup */ }

      { openedPopup === 'modal_profile_update0' && <ModalProfileUpdate dataClass="modal_profile_update0" modalState={0} onClose={ onClose } /> }
      { openedPopup === 'modal_profile_update1' && <ModalProfileUpdate dataClass="modal_profile_update1" modalState={1} onClose={ onClose } /> }
      { openedPopup === 'modal_profile_update2' && <ModalProfileUpdate dataClass="modal_profile_update2" modalState={2} onClose={ onClose } /> }
      { openedPopup === 'modal_profile_update3' && <ModalProfileUpdate dataClass="modal_profile_update3" modalState={3} onClose={ onClose } /> }
      { openedPopup === 'modal_profile_update4' && <ModalProfileUpdate dataClass="modal_profile_update4" modalState={4} onClose={ onClose } /> }
      { openedPopup === 'modal_enter_pin' && <ModalPinConfirm onClose={ onClose } /> }
      { openedPopup === 'modal_change_id' && <ModalChangeID onClose={ onClose } /> }
      { openedPopup === 'modal_change_id_colored' && <ModalChangeIDColored onClose={ onClose } /> }
      { openedPopup === 'modal_change_id_confirm' && <ModalChangeIDConfirm onClose={ onClose } /> }
      { openedPopup === 'modal_seed_phrase' && <ModalSeedPhrase onClose={ onClose } /> }
      { openedPopup === 'modal_enter_seed' && <ModalEnterSeed title="Enter Seed Phrase" buttonText="Done" onClose={ onClose } /> }
      { openedPopup === 'modal_action_success1' && <ModalActionSuccess title="Email ID is updated" desc="You can now login using the new password." dataClass="modal_action_success1" onClose={ onClose } /> }
      { openedPopup === 'modal_action_success2' && <ModalActionSuccess title="Name is updated" desc="You can now login using the new password." dataClass="modal_action_success2" onClose={ onClose } /> }
      { openedPopup === 'modal_action_success3' && <ModalActionSuccess title="Password updated" desc="" dataClass="modal_action_success3" onClose={ onClose } /> }
      { openedPopup === 'modal_action_success4' && <ModalActionSuccess title="PIN updated" desc="" dataClass="modal_action_success4" onClose={ onClose } /> }
      { openedPopup === 'modal_action_success5' && <ModalActionSuccess title="Successful" desc="You can now login using the new password." dataClass="modal_action_success5" onClose={ onClose } /> }
      { openedPopup === 'modal_action_failed1' && <ModalActionFailed title="Email address update failed" desc="" dataClass="modal_action_failed1" onClose={ onClose } /> }
      { openedPopup === 'modal_action_failed2' && <ModalActionFailed title="Name is not updated" desc="" dataClass="modal_action_failed2" onClose={ onClose } /> }
      { openedPopup === 'modal_action_confirm' && <ModalActionConfirm title="Cancel updating Email address?" desc="" dataClass="modal_action_failed1" onClose={ onClose } /> }
      { openedPopup === 'modal_action_confirm1' && <ModalActionConfirm title="Cancel edit name" desc="" dataClass="modal_action_confirm1" onClose={ onClose } /> }
      { openedPopup === 'modal_set_new_pin' && <ModalSetNewPin onClose={ onClose } /> } 
      { openedPopup === 'modal_changeid_txn_done' && <ModalChangeIDTxnDone onClose={ onClose } /> } 
      { openedPopup === 'modal_link_to_safle_id' && <ModalLinkWallet onClose={ onClose } /> } 

    </>
  );
};

export default SettingsDialogScreen;