import React, { useState }  from 'react';
import MainModal from './../generic/MainModal';

const ModalEditWalletLabel = ( attrs ) => {
    const props = attrs;
    const [editLabel , setEditLabel] = useState('');


    const editLabelConfirm = () => {
        // do checks here else show error
        const labelCheck = editLabel;
        
        props.callbackOpenPopup(labelCheck);
    };

    const handleChange = (event) => {
        setEditLabel(event.target.value);
    }

    return (
        <MainModal 
            onClose={props.onClose} 
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet--1', 'modal_edit_wallet_label']} 
            dataClass="modal_edit_wallet_label" >            
            {/* Modal content */}
            <div className="modal_head--1">
                <img src="images/wallets/set-wallet-label.svg" alt="" />
                <div className="modal_title">Edit Wallet Label</div>
                <div className="modal_text">Enter Wallet Label, as per your choice Ex- Iva Ryan Wallet</div>
            </div>
            <div className="modal_wrapper p-5">
                <input type="text" placeholder="Enter Wallet Label" onChange={handleChange} />                
            </div>
            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
                    <button className="btn-default btn-white-bordered close_modal_cancel"
                        onClick={props.callbackCancelPopup}
                    >Cancel</button>
                    <button className="btn-default btn-blue "
                        disabled={ editLabel?.trim().length === 0 }
                        onClick={editLabelConfirm}
                    >Save</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalEditWalletLabel;