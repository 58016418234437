import SidebarNotificationGroup from "../SidebarNotificationGroup/SidebarNotificationGroup";

import React, { useState, useEffect, useRef } from "react";

import { useApp } from "./../../../helpers/AppProvider";
import { useAppChange } from "./../../../helpers/generalHelper";

const SidebarNotification = (props) => {
  const { appData, actions } = useApp();

  const sidebarNotif = useRef(null);

  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const [items, setItems] = useState([]);

  useAppChange(appData, (changes) => {
    if (changes.showSidebarNotification) {
      setIsSidebarActive(true);
    } else {
      setIsSidebarActive();
    }
  });

  useEffect(() => {
    async function getNotif() {
      const getNotifications = async () => {
        const limit = 10;
        const offset = 0;
        const _status = "unread";
        let data = await actions.fetchNotifications(limit, offset, _status);
        setItems(data);
      };
      if (isSidebarActive) {
        getNotifications();
      }
    }
    getNotif();
  }, [isSidebarActive]);

  // used for the click outside of the notification container  to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        sidebarNotif.current !== null &&
        !sidebarNotif.current.contains(e.target)
      ) {
        //if outside remove class show_notif from body
        setIsSidebarActive(false);
        actions.setAppData({ showSidebarNotification: false });
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isSidebarActive) {
      window.addEventListener("click", pageClickEvent);
      actions.setAppData({ showSidebarNotification: true });
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      actions.setAppData({ showSidebarNotification: false });
    };
  }, [isSidebarActive]);

  const addressDummy = "0x8fF74a97643028BCd4fA21fA73AB6BA54D851964";
  const assetDummy = {
    asset_prefix: "ETH",
    asset_worth: "0.0005",
    asset_icon: "images/currency/currency-ethereum.svg",
  };
  const notifGroups = [
    {
      // key : 1,
      type: "settings",
      text: "mPin is enable",
      time_ago: "1 min",
    },
    {
      // key : 2,
      type: "settings",
      text: "Biometric is enable",
      time_ago: "20 min",
    },
    {
      // key : 2,
      type: "transaction",
      asset_item: assetDummy,
      from: addressDummy,
      time_ago: "30 min",
    },
    {
      // key : 3,
      type: "transaction",
      asset_item: assetDummy,
      from: addressDummy,
      time_ago: "30 min",
    },
    {
      // key : 4,
      type: "transaction",
      asset_item: assetDummy,
      from: addressDummy,
      time_ago: "30 min",
    },
    {
      // key : 4,
      type: "login",
      site_logo: "images/currency/currency-ethereum.svg",
      site_name: "Transak",
      time_ago: "30 min",
    },
    {
      // key : 5,
      type: "login",
      site_logo: "images/transak_logo.svg",
      site_name: "",
      time_ago: "30 min",
    },
  ];
  const readAllNotif = (e) => {
    e.preventDefault();
    //clear notification ... what about the notif that needs Approve or reject....will you reject them automaticaly?
  };

  return (
    <div
      className="main__content__aside_right aside_notification"
      ref={sidebarNotif}
      tabIndex="0"
    >
      <div className="notification__heading">
        <h4>Notifications</h4>
        <div className="mark_all_read" onClick={readAllNotif}>
          Mark all as read
        </div>
      </div>
      <div className="notification__inner">
        <SidebarNotificationGroup items={items} />
      </div>
    </div>
  );
};

export default SidebarNotification;
