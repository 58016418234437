import React, { useState, useEffect } from "react";
import EnterAmountInput from "../common/EnterAmountInput/EnterAmountInput";
import InputEmail from "../common/InputEmail/InputEmail";
import { SafleID } from "@getsafle/safle-identity-wallet";
import GasFeeOptions from "../common/GasFeeOptions/GasFeeOptions";
import GasFeeOptionsBitcoin from "../common/GasFeeOptions/GasFeeOptionsBitcoin";
import DropdownNetworkNew from "../common/DropdownNetworkNew/DropdownNetworkNew";
import DropdownWalletNew from "../common/DropdownWalletNew/DropdownWalletNew";
// import NetworkSelectorModal from "../common/NetworkSelectorModal/NetworkSelectorModal";
import SendToInput from "../common/SendToInput/SendToInput";
// import WalletSelectorModal from "../common/WalletSelectorModal/WalletSelectorModal";
import { checkWalletLinkedToSafleID } from "../../helpers/walletHelpers";
import MainModal from "../generic/MainModal";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import { useApp } from "../../helpers/AppProvider";
// import { getTokenBalance } from "../../helpers/walletHelpers";
import { delay_code } from "./../../helpers/generalHelper";
import { useUser } from "../../helpers/UserProvider";
import { useModalData } from "../../helpers/useModalNavigation";
import FeeEstimator from "../../helpers/FeeEstimator";
import Web3Store from "../../helpers/web3Store";
import Web3 from "web3";
import tokenDataAbi from "../../helpers/tokenDataAbi";
import GasCalculation from "../common/GasFeeOptions/GasCalculation";
const bitcoinjs = require("bitcoinjs-lib"); // const chainIdToNetwork = {
//   1: "ethereum",
//   137: "polygon",
//   42161: "arbitrum",
// };

const estimator = new FeeEstimator();
let w3 = new Web3();
const web3Store = new Web3Store();
// const wb3 = new Web3(
//   new Web3.providers.HttpProvider(
//     "https://polygon-mainnet.infura.io/v3/2c3f34885f064b28a4af2c1dd5c8751d"
//   )
// );

const ModalAssetSend = (attrs) => {
  const { appData, actions } = useApp();
  const { userActions } = useUser();
  const [modalData, setData] = useModalData();

  const props = attrs;

  // eslint-disable-next-line no-unused-vars
  const [percentageActive, setPercentageActive] = useState(null);
  // const [ sendToken, setSendToken ] = useState( attrs.token );
  const sendToken = attrs.token;
  const [safleId, setSafleId] = useState();
  // const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [disableID, setDisableID] = useState();
  const [error, setError] = useState("");
  const [walletAddress, setWalletAddress] = useState(false);
  const [chain, setChain] = useState(false);
  const [walletBalance, setWalletBalance] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [feesLoading, setFeesLoading] = useState(false);

  const [amount, setAmount] = useState(null);
  const [toAddress, setToAddress] = useState("");
  const [updatedAddress, setUpdatedAddress] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [tokenActive, setTokenActive] = useState(false);
  // const [currentFee, setCurrentFee] = useState(0);
  const [fees, setFees] = useState(null);
  const [chosenFee, setChosenFee] = useState("standard");
  const [percentagesdisabled, setPercentagesdisabled] = useState(false);
  const [chosenGas, setChosenGas] = useState(21000);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [gasFeesError, setGasFeesError] = useState(false);
  const [custGasOptions, setCustGasOptions] = useState({
    gasLimit: 0,
    prioFee: 0,
  });
  let intv = false;

  const getWalletTokenBalance = async (sendToken) => {
    const accountType = appData?.accounts?.find(
      (account) => account?.address === walletAddress
    )?.type;
    if (accountType === "evm") {
      if (walletAddress && chain) {
        if (
          (sendToken?.symbol === chain?.details?.symbol &&
            sendToken?.symbol !== "ARB" &&
            sendToken?.symbol !== "OP") ||
          (sendToken?.symbol === "ETH" &&
            (chain?.details?.symbol === "ARB" ||
              chain?.details?.symbol === "OP"))
        ) {
          const assetInfo = await actions.getAssetInfo(
            sendToken?.symbol,
            walletAddress,
            chain?.chain_name === "ethereum" ? "eth" : chain?.chain_name
            // contractAddress
          );
          if (assetInfo?.balance) {
            setWalletBalance(assetInfo?.balance);
          } else {
            setWalletBalance(0);
          }
          setContractAddress("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
          //

          //   const wbal = await actions.getWalletBalance(walletAddress, chain.name);
          //
          //   setWalletBalance(wbal);
        } else {
          const contractAddress = sendToken?.chainContractAddress?.filter(
            (item) => {
              if (item?.chain === "eth") {
                return chain?.chain_name === "ethereum";
              } else {
                return chain?.chain_name === item?.chain;
              }
            }
          )[0]?.token_address;
          if (contractAddress) {
            const assetInfo = await actions.getAssetInfo(
              sendToken?.symbol,
              walletAddress,
              chain?.chain_name === "ethereum" ? "eth" : chain?.chain_name,
              contractAddress
            );
            const tokenContract = await new w3.eth.Contract(
              tokenDataAbi,
              contractAddress
            );
            let decimals = await tokenContract.methods.decimals().call();
            if (assetInfo?.balance) {
              setWalletBalance(assetInfo?.balance?.toFixed(decimals));
            } else {
              setWalletBalance(0);
            }
            setContractAddress(contractAddress);
          } else {
            setWalletBalance(0);
            setContractAddress("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
          }
          // const acc = await userActions.getAccounts();
          // const addrs = acc ? Object.values(acc) : [];
          // console.log(
          //   "🚀 ~ file: ModalAssetSend.js ~ line 83 ~ getWalletTokenBalance ~ addrs",
          //   addrs
          // );
          //
          // const wallet = addrs
          //   .map((e, idx) => {
          //     return { ...e, id: idx, chain_name: chain.name };
          //   })
          //   .find((e) => e.address === walletAddress);
          // console.log(
          //   "🚀 ~ file: ModalAssetSend.js ~ line 87 ~ getWalletTokenBalance ~ wallet",
          //   wallet
          // );

          // if (wallet) {
          //   const tokenBalance = await actions.getWalletAssets(wallet);
          //
          //   if (tokenBalance.length) {
          //     // const found = tokenBalance.find( e => e.tokenAddress === sendToken.address );
          //     //

          //     // if( found ){
          //     //

          //     const activeToken = tokenBalance.filter(
          //       (item) => sendToken.symbol === item.symbol
          //     );
          //
          //     setTokenActive(activeToken[0]);
          //     if (activeToken.length) {
          //       setWalletBalance(
          //         getTokenBalance(
          //           parseFloat(activeToken[0]?.balance), //needs fix
          //           activeToken[0]?.decimal
          //         )
          //       );
          //       setContractAddress(activeToken[0]?.tokenAddress);
          //     } else {
          //       setWalletBalance(0);
          //     }
          //     return;
          //     // }
          //   }
          // }
          // setWalletBalance(0);
        }
      }
    }
    if (accountType === "bitcoin") {
      const assetInfo = await actions.getAssetInfo(
        sendToken?.symbol,
        walletAddress,
        "bitcoin"
      );
      setWalletBalance(assetInfo?.balance);
      setContractAddress("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
    }
  };

  useEffect(() => {
    setSafleId("");
    setToAddress("");
    setAmount("");
    setSubmitEnabled(false);
    setSuccessMsg("");
    setError("");
    getWalletTokenBalance(sendToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, sendToken, chain]);

  const getNodeFor = (chain_name) => {
    if (appData.chains) {
      const chain = appData.chains.find((e) => e.name === chain_name);
      if (chain?.chain_name) {
        return web3Store.getNodeFor(chain.chain_name, chain.uri);
      }
    }
    return false;
  };

  // const calc_gas = async () => {
  //   if (sendToken && walletAddress && toAddress) {
  //     if (
  //       sendToken.symbol === chain.details.symbol ||
  //       (sendToken.symbol === "ETH" &&
  //         (chain.details.symbol === "ARB" || chain.details.symbol === "OP"))
  //     ) {
  //       var block = await w3.eth.getBlock("latest");

  //       var gasLimit = (block.gasLimit / block.transactions.length).toFixed(0);
  //       setChosenGas(gasLimit);
  //     } else {
  //       const sendToAddress = updatedAddress ? updatedAddress : toAddress;
  //       let decimals;

  //       const tokenContract = await new w3.eth.Contract(
  //         tokenDataAbi,
  //         contractAddress
  //       );
  //       decimals = await tokenContract.methods.decimals().call();
  //       const gas = await actions._calculateTokenGas(
  //         contractAddress,
  //         walletAddress,
  //         sendToAddress,
  //         amount,
  //         decimals,
  //         chain.name
  //       );
  //       setChosenGas(gas);
  //     }
  //   }
  // };
  // useEffect(calc_gas, [
  //   sendToken,
  //   walletAddress,
  //   toAddress,
  //   amount,
  //   chain,
  //   updatedAddress,
  //   tokenActive,
  // ]);

  const addressChanged = (activeAddress) => {
    setWalletAddress(activeAddress?.wallet_address);
    setWalletBalance(
      w3.utils.fromWei(activeAddress?.wallet_amount?.toString(), "ether")
    );
  };

  const chainChanged = (activeChain) => {
    const chain = appData.chains.find(
      (e) => e.name === activeChain?.network_name
    );

    // const token = getTokenOnChain( props.token?.symbol, chain.chainId );

    // const symbol = appData.activeAsset;
    // const assets = appData.assets;
    // const keys = Object.keys(assets);
    // const token = Object.values(assets)
    //   .map((e, idx) => {
    //     return { ...e, address: keys[idx] };
    //   })
    //   .find((e) => e.symbol === symbol);

    // setSendToken( token );
    setSafleId("");
    setToAddress("");
    setAmount("");
    setSubmitEnabled(false);
    setSuccessMsg("");
    setError("");
    setChain(chain);
    w3 = getNodeFor(chain?.name);
    actions.setAppData({ activeWalletChain: chain });
  };

  // const getTokenOnChain = (tokenSymbol, chainId) => {
  //   const chosenChain = chainIdToNetwork[chainId];

  //   const keys = Object.keys(appData.assets);

  //   const tok = Object.values(appData.assets)
  //     .map((e, idx) => {
  //       return { ...e, address: keys[idx] };
  //     })
  //     .find((e) => e.symbol === tokenSymbol && e.chain === chosenChain);

  //   return tok;
  // };

  // const changedAmount = (amount) => {
  //   setAmount(parseFloat(amount));
  // };

  const changedAmount = (amount) => {
    setAmount(amount);
  };

  useEffect(() => {
    if (walletBalance <= 0) {
      setPercentagesdisabled(true);
    } else {
      setPercentagesdisabled(false);
    }
  }, [walletBalance]);

  const changedToAddress = async (toAddress) => {
    setToAddress(toAddress);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkSafleID = async () => {
    //
    const response = await fetch(
      process.env.REACT_APP_CHECKING_SERVICE_URL +
        "/checking/check-safleid/" +
        toAddress,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((r) => r.json())
      .then((resp) => {
        return resp;
      })
      .catch((err) => {});
    if (response?.statusCode === 200) {
      if (response?.data?.isAvailable === false) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isValidSegWitAddress = (address) => {
    try {
      return bitcoinjs?.address.fromBech32(address) !== null;
    } catch (error) {
      return false;
    }
  };

  //debouncing for search
  useEffect(() => {
    let timer = setTimeout(async () => {
      if (sendToken?.symbol === "BTC") {
        if (isValidSegWitAddress(toAddress)) {
          setIsValidAddress(true);
        } else {
          setIsValidAddress(false);
        }
      } else {
        let resp = await checkWalletLinkedToSafleID(toAddress);
        setIsValidAddress(resp);
        if (!resp || resp === "This SafleID is not registered.") {
          if (w3.utils.isAddress(toAddress)) {
            setIsValidAddress(true);
          } else {
            setIsValidAddress(false);
          }
          setUpdatedAddress("");
        } else {
          setUpdatedAddress(resp);
        }
      }
    }, 800);

    return () => clearTimeout(timer);
  }, [toAddress, checkSafleID]);

  const setAmountPercentage = async (index) => {
    let decimals;

    if (contractAddress !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
      const tokenContract = await new w3.eth.Contract(
        tokenDataAbi,
        contractAddress
      );
      decimals = await tokenContract.methods.decimals().call();
    }
    decimals
      ? setAmount(
          parseFloat(percentages[index].percentage * walletBalance).toFixed(
            decimals
          )
        )
      : setAmount(
          parseFloat(percentages[index].percentage * walletBalance).toFixed(
            sendToken.symbol === "BTC" ? 8 : 18
          )
        );
  };
  const percentages = [
    { value: "25%", percentage: 0.25 },
    { value: "50%", percentage: 0.5 },
    { value: "100%", percentage: 1 },
  ];

  const get_fees = async () => {
    if (sendToken.symbol === "BTC") {
      setFeesLoading(true);

      const response = await userActions.getBTCFees({
        from: walletAddress,
        to: walletAddress,
        amount: 0,
      });

      setFees(response.fees);
      setChosenGas(response.transactionSize);
      setFeesLoading(false);
    } else {
      if (chain && chosenFee !== "custom") {
        setFeesLoading(true);
        const fees = await GasCalculation({
          appData: appData,
          chainId: chain.chainId,
          token: sendToken,
          config: {
            toAddress: updatedAddress ? updatedAddress : toAddress,
            fromAddress: walletAddress,
            value: isNaN(amount) ? 0 : parseFloat(amount),
            contractAddress,
          },
        });
        await delay_code(1500);

        setFees(fees);
        setChosenGas(fees.gasLimit);
        setFeesLoading(false);
      }
    }
  };
  useEffect(() => {
    if (walletAddress) {
      get_fees();
    }
    // clearInterval( intv );
    // intv = setInterval( get_fees, 15000 );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chain,
    amount,
    updatedAddress,
    toAddress,
    walletAddress,
    contractAddress,
    sendToken,
    appData.activeChain,
  ]);

  const gasFeesBalanceCheck = async () => {
    const chainNativeBalance = await actions.getAssetInfo(
      chain?.details?.symbol,
      walletAddress,
      chain?.chain_name === "ethereum" ? "eth" : chain?.chain_name
    );
    const gasFees =
      chosenFee === "custom"
        ? custGasOptions.gasLimit *
          (parseFloat(custGasOptions.prioFee) +
            parseFloat(fees?.estimatedBaseFee))
        : chosenGas *
          (parseFloat(fees[chosenFee].maxPriorityFeePerGas) +
            parseFloat(fees?.estimatedBaseFee));

    if (
      parseFloat(chainNativeBalance?.balance) >=
      parseFloat(gasFees) / Math.pow(10, 9)
    ) {
      setGasFeesError(false);
    } else {
      setGasFeesError(true);
    }

    return (
      parseFloat(chainNativeBalance?.balance) >=
      parseFloat(gasFees) / Math.pow(10, 9)
    );
  };

  const validAmount = async () => {
    if (sendToken?.symbol !== "BTC") {
      if (
        (sendToken?.symbol === chain?.details?.symbol &&
          sendToken?.symbol !== "OP" &&
          sendToken?.symbol !== "ARB") ||
        (sendToken?.symbol === "ETH" &&
          (chain?.details?.symbol === "ARB" || chain?.details?.symbol === "OP"))
      ) {
        const gasFees =
          chosenFee === "custom"
            ? custGasOptions.gasLimit *
              (parseFloat(custGasOptions.prioFee) +
                parseFloat(fees?.estimatedBaseFee))
            : chosenGas *
              (parseFloat(fees[chosenFee].maxPriorityFeePerGas) +
                parseFloat(fees?.estimatedBaseFee));
        if (
          parseFloat(amount) > 0.0 &&
          parseFloat(amount) < parseFloat(walletBalance) &&
          parseFloat(walletBalance) <=
            parseFloat(amount) + parseFloat(gasFees) / Math.pow(10, 9)
        ) {
          setGasFeesError(true);
        } else {
          setGasFeesError(false);
        }
        return (
          parseFloat(amount) > 0.0 &&
          parseFloat(walletBalance) >=
            parseFloat(amount) + parseFloat(gasFees) / Math.pow(10, 9)
        );
      } else {
        if (await gasFeesBalanceCheck()) {
          return (
            parseFloat(amount) > 0.0 &&
            parseFloat(walletBalance) >= parseFloat(amount)
          );
        } else {
          return false;
        }
      }
    } else {
      const gasFees =
        chosenFee === "custom"
          ? custGasOptions.gasLimit * parseFloat(custGasOptions.prioFee)
          : // parseFloat(fees?.estimatedBaseFee))
            chosenGas * parseFloat(fees[chosenFee].satPerByte);
      // (parseFloat(fees[chosenFee].maxPriorityFeePerGas) +
      //   parseFloat(fees?.estimatedBaseFee));
      if (
        parseFloat(amount) > 0.0 &&
        parseFloat(amount) < parseFloat(walletBalance) &&
        parseFloat(walletBalance) <=
          parseFloat(amount) + parseFloat(gasFees) / Math.pow(10, 9)
      ) {
        setGasFeesError(true);
      } else {
        setGasFeesError(false);
      }
      return (
        parseFloat(amount) > 0.0 &&
        parseFloat(walletBalance) >=
          parseFloat(amount) + parseFloat(gasFees) / Math.pow(10, 9)
      );
    }
  };
  const validCustomGas = (prioFee, gasEstemate) => {
    return gasEstemate
      ? custGasOptions.gasLimit >= gasEstemate &&
          custGasOptions.prioFee >= prioFee
      : custGasOptions.prioFee >= prioFee;
  };

  useEffect(() => {
    async function buttonEnabler() {
      if (
        !feesLoading &&
        (sendToken?.symbol === "BTC" ? true : chain) &&
        isValidAddress &&
        amount &&
        (sendToken?.symbol === "BTC"
          ? validCustomGas(fees?.slow?.satPerByte)
          : validCustomGas(fees?.slow?.maxPriorityFeePerGas, fees.gasLimit)) &&
        (await validAmount())
      ) {
        setSubmitEnabled(true);
      } else {
        setSubmitEnabled(false);
      }
    }

    buttonEnabler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    feesLoading,
    fees,
    chain,
    isValidAddress,
    amount,
    walletAddress,
    chosenFee,
    custGasOptions,
  ]);

  const gasChangeHandler = (chosenFee) => {
    setChosenFee(chosenFee);
  };
  const custGasChangeHandler = (gasLimit, prioFee) => {
    setCustGasOptions({ gasLimit: gasLimit, prioFee: prioFee });
  };

  const advancedChange = (open) => {
    if (open) {
      clearInterval(intv);
    } else {
      // setChosenFee("standard");
      get_fees();
      // clearInterval( intv );
      // intv = setInterval( get_fees, 15000 );
    }
  };

  const sendTransaction = async () => {
    let decimals;
    let config;

    const bigNum =
      parseFloat(amount.toString().split("-")[1]) +
      parseFloat(amount.toString().split("-")[0].split("e")[0].length - 2);

    if (sendToken.symbol === "BTC") {
      config = {
        from: walletAddress,
        to: updatedAddress ? updatedAddress : toAddress,
        amount: parseInt(
          (amount.toString().indexOf("e") > -1
            ? amount.toFixed(bigNum < 8 ? bigNum : 8)
            : amount) * Math.pow(10, 8)
        ),
        txSize: chosenFee === "custom" ? custGasOptions.gasLimit : chosenGas,
        satPerByte:
          chosenFee === "custom"
            ? custGasOptions.prioFee
            : parseFloat(fees[chosenFee].satPerByte),
      };
    } else {
      if (contractAddress !== "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE") {
        const tokenContract = await new w3.eth.Contract(
          tokenDataAbi,
          contractAddress
        );
        decimals = await tokenContract.methods.decimals().call();
      }

      config = {
        chosenFee: chosenFee,
        from: walletAddress,
        to: updatedAddress ? updatedAddress : toAddress,
        value: decimals
          ? amount.toString().indexOf("e") > -1
            ? amount.toFixed(bigNum < decimals ? bigNum : decimals)
            : amount
          : amount.toString().indexOf("e") > -1
          ? amount.toFixed(bigNum < 18 ? bigNum : 18)
          : amount,

        gasLimit: chosenFee === "custom" ? custGasOptions.gasLimit : chosenGas,
        maxPriorityFeePerGas:
          chosenFee === "custom"
            ? custGasOptions.prioFee
            : parseFloat(fees[chosenFee].maxPriorityFeePerGas),
        maxFeePerGas:
          chosenFee === "custom"
            ? parseFloat(custGasOptions.prioFee) +
              parseFloat(fees?.estimatedBaseFee)
            : parseFloat(fees[chosenFee].maxFeePerGas),
        token: sendToken,
        data: "0x0",
        type: "0x2",
        contractAddress: contractAddress,
        chain: chain,
        decimals: tokenActive?.decimal,
      };
    }
    setData({ transactionDetails: config });

    props.callbackOpenPopup(props.modalNext_label);
  };
  const closePopup = () => {
    clearInterval(intv);
    props.callbackCancelPopup(props.modalCancel_label);
  };
  const getTokenName = () => {
    //
    return (
      modalData?.info?.name ||
      modalData?.data?.data[sendToken?.symbol]?.name ||
      modalData?.name ||
      modalData?.symbol
    );
  };

  // function handlePercentageActive(index) {
  //   if (percentageActive === index) {
  //     setPercentageActive(index);
  //   } else {
  //     setPercentageActive(disableID ? index : null);
  //   }
  // }

  const getTokenSymbol = () => {
    if (sendToken?.hasOwnProperty("correlatedSymbol")) {
      return attrs.token.chain === chain.chain_name
        ? sendToken?.symbol
        : sendToken?.correlatedSymbol;
    }
    return sendToken?.symbol;
  };
  const getChainSymbol = () => {
    return chain?.details?.symbol;
  };

  const handleInputChange = (input) => {
    const modifiedInput = input.replace(/[^a-zA-Z0-9]+/g, "");
    setSafleId(modifiedInput);
  };

  const handleRegisteredSafleID = async (value) => {
    if (!value || value === " ") return;
    const linkedPublicAddress = await checkWalletLinkedToSafleID(value);
    if (linkedPublicAddress.indexOf("0x") === -1) {
      setError("Invalid safleId");
      setToAddress("");
      setDisableID(false);
    } else {
      setError("");
      setSuccessMsg("SafleID is registered");
      setDisableID(true);
      setToAddress(linkedPublicAddress);
    }
  };

  return (
    <MainModal
      extraClasses={[
        "modal_w440",
        "modal_p30",
        "modal_mh400",
        "modal_dflex",
        "modal_success_msg",
        "modal_mh500",
        "modal_wallet--1",
        "modal_send_asset",
        "modal_receive_asset",
        "modal_send_asset_dropdown_fix",
      ]}
      onClose={closePopup}
      title={"Send " + getTokenName()}
      dataClass="modal_asset_send"
    >
      <div className="modal_succes_content modal_receive_content">
        {modalData?.symbol !== "BTC" && (
          <div className="modal_send_rows ">
            <DropdownNetworkNew
              extraClass="dropdown_network_assets"
              noBlockScroll={false}
              networks={attrs.networks}
              onSelect={(e) => chainChanged(e)}
            />
          </div>
        )}
        <div className="modal_send_rows margin_bottom_20">
          <DropdownWalletNew
            extraClass="dropdown_wallet_assets"
            compType="sendAssets"
            icon="images/wallet-icon.svg"
            noBlockScroll={false}
            onSelect={addressChanged}
            symbol={modalData?.symbol}
          />
        </div>
        {modalData?.symbol !== "BTC" && (
          <div className="modal_send_rows">
            <InputEmail
              placeholder="Enter SafleID"
              label="To"
              value={safleId}
              errorMsg={error}
              successMsg={successMsg}
              onChange={(e) => {
                setError("");
                setToAddress("");
                setSuccessMsg("");
                setDisableID(false);
                setSafleId(e.target.value);
                handleInputChange(e.target.value);
              }}
              checkValueCallback={(r) => {
                handleRegisteredSafleID(r);
              }}
              checkButton="Check"
            />
          </div>
        )}
        {modalData?.symbol !== "BTC" && (
          <div className="pb-[2rem]">
            <div class="line-text relative text-center py-2">OR</div>
          </div>
        )}

        <div className="modal_send_rows">
          <SendToInput
            value={toAddress}
            setValue={changedToAddress}
            isValidAddress={isValidAddress}
            disabled={disableID}
            onChange={(e) => {
              setSafleId("");
              setError("");
              changedToAddress(e.target.value);
            }}
          />
        </div>
        <div className="modal_send_rows no_margin_bottom">
          <EnterAmountInput
            value={amount}
            setValue={changedAmount}
            error={
              parseFloat(amount) > parseFloat(walletBalance) ||
              parseFloat(amount) < 0
            }
            gasFeesError={gasFeesError}
            onChange={(e) => {
              changedAmount();
            }}
          />
        </div>
        <div className="from_to_field_wrapper no_margin_bottom">
          <div className="swap-balance-amount">
            <div className="from_to_field_balance swap-field-balance">
              <span className="item_ico icon-Navbar_icon_Wallet_deActive1" />
              <span className="from_to_field_label swap-field-to-label">
                Balance:
              </span>
              <span className="from_to_field_amount">
                <span className="coin_val">
                  {walletBalance === 0 ? 0.0 : walletBalance}
                  {/* {formatXDecimals(walletBalance, 6)} */}
                </span>
                <span className="coin_prefix">{getTokenSymbol()}</span>
              </span>
            </div>
            <ul className="filter-percentage-amount">
              {percentages.map((item, index) => {
                return (
                  <li
                    className={percentageActive === index ? "active" : null}
                    onClick={() => {
                      if (!percentagesdisabled) {
                        setAmountPercentage(index);
                        setPercentageActive(index);
                      }
                    }}
                    key={index}
                    style={{
                      opacity: percentagesdisabled ? 0.5 : 1,
                    }}
                  >
                    {item.value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {fees && sendToken.symbol !== "BTC" && (
          <GasFeeOptions
            chain={chain}
            fees={fees}
            chosenFee={chosenFee}
            chosenGas={chosenGas}
            estimator={estimator}
            loading={feesLoading}
            tokenSymbol={getChainSymbol()}
            onAdvancedChange={advancedChange}
            onGasChange={gasChangeHandler}
            onCustomGas={custGasChangeHandler}
            token={sendToken}
            toAddress={updatedAddress ? updatedAddress : toAddress}
            fromAddress={walletAddress}
            value={isNaN(amount) ? 0 : parseFloat(amount)}
            contractAddress={contractAddress}
          />
        )}
        {fees && sendToken.symbol === "BTC" && (
          <GasFeeOptionsBitcoin
            chain={"bitcoin"}
            fees={fees}
            chosenFee={chosenFee}
            chosenGas={chosenGas}
            estimator={estimator}
            loading={feesLoading}
            tokenSymbol={"BTC"}
            onAdvancedChange={advancedChange}
            onGasChange={gasChangeHandler}
            onCustomGas={custGasChangeHandler}
            token={"BTC"}
            toAddress={updatedAddress ? updatedAddress : toAddress}
            fromAddress={walletAddress}
            value={isNaN(amount) ? 0 : parseFloat(amount)}
            contractAddress={contractAddress}
          />
        )}
      </div>
      <div className="modal_footer_extra_gray">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <PrimaryBtn
            classes="btn-default btn-white-bordered close_modal_cancel"
            onClick={() => props.callbackCancelPopup(props.modalCancel_label)}
            label="Cancel"
          />
          <PrimaryBtn
            disabled={!submitEnabled}
            classes="btn-default btn-blue modal__trigger"
            onClick={() => sendTransaction()}
            label="Proceed"
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalAssetSend;
