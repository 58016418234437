
const SearchInput = ({ handleFilter, search ,title="Search Currency"}) => {

  return (
    <div className="form__input form__input-search">
      <input value={ search } type="text" className="form-input-default" placeholder={title}
        onChange={ handleFilter } />
      <span className="icon-search icon-search-black-24-dp" />
    </div>
  );
};

export default SearchInput;