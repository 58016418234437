/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";

// import UserEmail from "../UserEmail/UserEmail";
// import UserName from "../UserName/UserName";
// import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";
import UserAvatar from "../UserAvatar/UserAvatar";
// import CssFix from "./../../../helpers/cssFix";
import { getSafleName } from "../../../helpers/vaultHelpers";

import SettingsChangeEmailProcess from "./../../common/SettingsChangeEmailProcess/SettingsChangeEmailProcess";
import SettingsChangeNameProcess from "./../../common/SettingsChangeNameProcess/SettingsChangeNameProcess";
import InputGroup from "./../../../components/settings/InputGroup/InputGroup";

import {
  SETTINGS_CHANGE_EMAIL,
  SETTINGS_CHANGE_NAME,
  // DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";

// import Storage from "../../../helpers/Storage";
import ModalEditEmailMobile from "../../dialogs/ModalEditEmailMobile";
import ModalEditNameMobile from "../../dialogs/ModalEditNameMobile";

const UserInfoOverview = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalName, goBackwardN] =
    useModalNavigation(SETTINGS_CHANGE_NAME);
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalEmail, goBackwardE] = useModalNavigation(
    SETTINGS_CHANGE_EMAIL
  );

  // eslint-disable-next-line no-unused-vars
  const [modalData, setData] = useModalData();

  // const { appData, actions } = useApp();
  const { userData, userActions } = useUser();

  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();

  const [tempUserName, setTempUserName] = useState(null);
  const [tempUserEmail, setTempUserEmail] = useState(null);

  const [editUserEmail, setEditUserEmail] = useState(false);
  const [editUserEmailMobile, setEditUserEmailMobile] = useState(false);
  const [editUserName, setEditUserName] = useState(false);
  const [editUserNameMobile, setEditUserNameMobile] = useState(false);

  const [userEmailError, setUserEmailError] = useState("");

  const [checkValidEmail, setCheckValidEmail] = useState(null);

  // const storageData = Storage.load("user");

  // const storageDataName = storageData.safleID;
  // const userData.user.token = storageData.token;
  // const storageDataVault = storageData.vault;

  // setData({ safleID:'', token: '', old_email: '', new_email: '', vault:'' });

  useEffect(() => {
    async function fetchData() {
      const safleName = await getSafleName(userData.user.token);
      setUserName(safleName.name);
      setUserEmail(safleName.email);
      setTempUserEmail("");
      setUserEmailError("");
    }
    fetchData();
  }, [userData]);

  let timeout = false;

  const editUserEmailTrigger = (e) => {
    if (window.innerWidth > 1240) {
      if (editUserEmail) {
        // hide email
        setEditUserEmail(false);
      } else {
        // show email
        setEditUserName(false);
        setEditUserEmail(true);
      }
    } else {
      setEditUserEmailMobile(true);
    }
  };

  const editUserNameTrigger = (e) => {
    if (window.innerWidth > 1240) {
      if (editUserName) {
        // hide email
        setTempUserName("");
        setEditUserName(false);
      } else {
        // show email
        setEditUserEmail(false);
        setEditUserName(true);
      }
    } else {
      setEditUserNameMobile(true);
    }
  };

  const cancelChange = (e) => {
    setTempUserName("");
    setEditUserEmail(false);
    setEditUserName(false);
    setUserEmailError(false);
    setTempUserEmail("");
    setCheckValidEmail();
  };

  const validateEmail = (mail) => {
    return String(mail)
      .toLowerCase()
      .match(
        /^(?=.{0,256}$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // if (
    //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    //     mail
    //   ) &&
    //   /^\w+@(\w)+(\w+)?(\.(\w+)){1,2}$/.test(mail) &&
    //   0 < mail.length < 257
    // ) {
    //   return true;
    // }
    // return false;
  };

  const onChangeEmail = async (e) => {
    let value = e.target.value.trim().toLowerCase();
    const words = value.split(" ");
    value = words.join("");
    if (timeout) {
      clearTimeout(timeout);
      timeout = false;
    }

    setTimeout(async () => {
      if (validateEmail(value)) {
        const checkSafleEmail = await userActions.validateSafleEmail(value);
        if (checkSafleEmail?.statusCode === 200) {
          if (checkSafleEmail?.data?.isRegistered) {
            setCheckValidEmail(false);
            setUserEmailError("This email is already used.");
          } else {
            setCheckValidEmail(true);
            setUserEmailError("");
          }
        } else {
          setCheckValidEmail(false);
          setUserEmailError("Service unavailable. Please try later.");
        }
      } else {
        if (value.length > 256) {
          setCheckValidEmail(false);
          setUserEmailError("Email cannot exceed 256 characters.");
        } else if (value.length > 0) {
          setCheckValidEmail(false);
          setUserEmailError(
            "The email you entered is not in the correct format. Please check."
          );
        } else {
          setCheckValidEmail();
          setUserEmailError("");
        }
      }
    }, 100);

    setTempUserEmail(value);
  };

  const onChangeName = (e) => {
    let val = e.target.value;
    let regex = /^(?!\s)[A-Za-z0-9\s]+$/;

    if (val === "") {
      setTempUserName(val);
    }
    if (val.length > 0 && val.length <= 64 && regex.test(val) === true) {
      setTempUserName(val);
    } else {
      return;
    }
  };

  const confirmChangeEmail = async (e) => {
    const safleName = await getSafleName(userData.user.token);
    setData({
      type: "reset_email",
      otpType: "otp_old_email",
      safleName: safleName.name,
      safleID: userData.user.safleID,
      token: userData.user.token,
      old_email: safleName.email,
      vault: userData.user.vault,
      new_email: tempUserEmail,
    });
    await userActions.changeEmailRequest(userData.user.token);
    // const get_safle_email_by_id = await userActions.getEmailBySafleId(storageDataName);

    setActiveModalEmail("modal_email_enter_pin");
  };

  const confirmChangeName = async (e) => {
    const newName = tempUserName;
    const changeName = await userActions.changeNameRequest(
      userData.user.token,
      newName
    );
    if (changeName?.data?.token) {
      const changeToken = changeName?.data?.token;
      await userActions.updateVaultToken(changeToken);

      const safleName = await getSafleName(changeToken);
      setUserName(safleName.name);
      cancelChange();
    }

    // setActiveModalName('modal_name_enter_pin');
    setActiveModalName("modal_name_is_updated");
  };

  const confirmChangeNameMobile = async (e) => {
    await confirmChangeName();
    setEditUserNameMobile(false);
  };

  const confirmChangeEmailMobile = async (e) => {
    await confirmChangeEmail();
    setEditUserEmailMobile(false);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalEmail();
  }, [setActiveModalEmail]);

  return (
    <div className="settings__overview_top">
      {/* <CssFix /> */}
      <div className="settings__overview_user-ifs">
        <UserAvatar />
        <div className="settings__overview_user_info">
          <div className="settings__overview_user_name">
            <span>{userName}</span>
            <span
              className="settings_edit_pen icon-pen-solid"
              onClick={editUserNameTrigger}
            ></span>
          </div>
          <div className="settings__overview_user_emails">
            <span className="settings__overview_user_emailss">{userEmail}</span>
            <span
              className="settings_edit_pen icon-pen-solid"
              onClick={editUserEmailTrigger}
            ></span>
          </div>
        </div>
      </div>
      {editUserEmail && (
        <div className="settings__overview_user_email active">
          <div className="settings__overview_user_email-label">New Email</div>
          <div className="settings__overview_user_email_inputs">
            <InputGroup
              name={"email_change"}
              placeholder="Enter New Email"
              value={tempUserEmail}
              valid={checkValidEmail}
              onChange={(e) => onChangeEmail(e)}
              errorMsg={userEmailError}
            />
            {checkValidEmail && (
              <button
                className="btn-default btn-blue cls2"
                onClick={confirmChangeEmail}
              >
                Confirm
              </button>
            )}
            {!checkValidEmail && (
              <button className="btn-default btn-blue cls2" disabled>
                Confirm
              </button>
            )}
            <button
              className="btn-default btn-gray btn-cancel-change cls2"
              onClick={cancelChange}
            >
              <span className="icon-xmark-solid"></span>
            </button>
          </div>
        </div>
      )}

      {editUserName && (
        <div className="settings__overview_user_email active">
          <div className="settings__overview_user_email-label">New Name</div>
          <div className="settings__overview_user_email_inputs">
            <InputGroup
              name={"name_change"}
              placeholder="Enter New Name"
              value={tempUserName}
              // valid={checkValidEmail}
              onChange={(e) => onChangeName(e)}
              // errorMsg={userEmailError}
            />

            <button
              className="btn-default btn-blue cls2"
              onClick={confirmChangeName}
              disabled={!tempUserName?.trim()}
            >
              Confirm
            </button>
            <button
              className="btn-default btn-gray btn-cancel-change cls2"
              onClick={cancelChange}
            >
              <span className="icon-xmark-solid"></span>
            </button>
          </div>
        </div>
      )}

      {editUserEmailMobile && (
        <ModalEditEmailMobile
          setEditUserEmailMobile={setEditUserEmailMobile}
          confirmChangeEmail={confirmChangeEmailMobile}
          setTempUserEmail={setTempUserEmail}
          tempUserEmail={tempUserEmail}
        />
      )}
      {editUserNameMobile && (
        <ModalEditNameMobile
          setEditUserNameMobile={setEditUserNameMobile}
          confirmChangeName={confirmChangeNameMobile}
          setTempUserName={setTempUserName}
          tempUserName={tempUserEmail}
        />
      )}

      <SettingsChangeEmailProcess onCompletProccess={cancelChange} />
      <SettingsChangeNameProcess />
    </div>
  );
};

export default UserInfoOverview;
