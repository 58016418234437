import React, { useEffect }  from 'react';

import ModalActionConfirm from './../../dialogs/ModalActionConfirm';

import useModalNavigation from '../../../helpers/useModalNavigation';
import { SETTINGS_CHANGE_EMAIL, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalEmailCancelFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [ setActiveModalNav, , goBackward ] = useModalNavigation(SETTINGS_CHANGE_EMAIL);
    
    const onClose = () => {
        setActiveModalNav('CLOSED');
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav(popup);
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav(popup, true);
    };
    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);
    

    return (
        <ModalActionConfirm 
        title="Cancel updating Email address?" 
        desc="" 
        dataClass="modal_action_failed1" 
        onClose={onClose} 
        goBackward={goBackward}
        
        iconErr={'images/wallets/cancel_confirm.svg'}
        /> 
    )
}

export default ModalEmailCancelFunctions;