import { useState } from "react";
import SettingBox from "../SettingBox/SettingBox";

import AddWalletProcess from "../../../components/common/AddWalletProcess/AddWalletProcess";
import LinkWalletSafleIdProcess from "../../../components/common/LinkWalletSafleIdProcess/LinkWalletSafleIdProcess";
import ChangeSafleIdProcess from "../../../components/common/ChangeSafleIdProcess/ChangeSafleIdProcess";
import SettingsExportSeedPhraseProcess from "../../common/SettingsExportSeedPhraseProcess/SettingsExportSeedPhraseProcess";
import useModalNavigation from "../../../helpers/useModalNavigation"; // } //   useModalData, // , {
import {
  // ADD_WALLET_FLOW,
  // SETTINGS_EXPORT_SEED_PHRASE,
  // LINK_WITH_SAFLEID,
  CHANGE_SAFLE_ID,
} from "../../../constants/ui";
// import { useUser } from "../../../helpers/UserProvider";
// import Storage from "../../../helpers/Storage";
// import { getSafleName } from "../../../helpers/vaultHelpers";
import { LIMIT_SAFLEID_CHANGE } from "../../../helpers/AppProvider";
// import TokenIcon from "../../common/TokenIcon/TokenIcon";
// import { useEffect } from "react";
// import { delay_code } from "./../../../helpers/generalHelper";
import SettingsIDContentWallets from "../SettingsIDContentWallets/SettingsIDContentWallets";
const SettingsIDcontent = () => {
  // const wallets = [
  //   {
  //     tokenImg: "images/wallets/bitcoin-wallet.svg",
  //     walletName: "Wallet 2",
  //     walletLabel: "Bitcoin",
  //     buttonState: "linked",
  //   },
  //   {
  //     tokenImg: "images/settings/polygon-wallet.svg",
  //     walletName: "Dev’s Wallet",
  //     walletLabel: "Polygon",
  //     buttonState: "linked",
  //   },
  //   {
  //     tokenImg: "images/settings/binance-wallet.svg",
  //     walletName: "Apoorv Wallet 2",
  //     walletLabel: "Binance Smart Chain",
  //     buttonState: "link",
  //   },
  //   {
  //     tokenImg: "images/settings/eth-chain.svg",
  //     walletName: "Eth Wallet",
  //     walletLabel: "Ethereum",
  //     buttonState: "linked",
  //   },
  //   {
  //     tokenImg: "images/wallets/bitcoin-wallet.svg",
  //     walletName: "BTC Wallet 1",
  //     walletLabel: "Bitcoin",
  //     buttonState: "link",
  //   },
  //   {
  //     tokenImg: "images/settings/polygon-wallet.svg",
  //     walletName: "New Wallet",
  //     walletLabel: "Polygon",
  //     buttonState: "link",
  //   },
  //   {
  //     tokenImg: "images/settings/binance-wallet.svg",
  //     walletName: "Wallet 3",
  //     walletLabel: "Binance Smart Chain",
  //     buttonState: "linked",
  //   },
  //   {
  //     tokenImg: "images/settings/eth-chain.svg",
  //     walletName: "Eth Dev’s Wallet 2",
  //     walletLabel: "Ethereum",
  //     buttonState: "linked",
  //   },
  // ];
  // const { appData, actions } = useApp();

  const [walletsVisible, setWalletsVisible] = useState(false);
  // const [setModalOnAddWallet] = useModalNavigation(ADD_WALLET_FLOW);
  // const [setModalOnLinkWallet] = useModalNavigation(LINK_WITH_SAFLEID);
  const [setModalOnChangeSafleId] = useModalNavigation(CHANGE_SAFLE_ID);
  // const [setExportSeedPhrase] = useModalNavigation(SETTINGS_EXPORT_SEED_PHRASE);
  // const { userData, setUserData, userActions } = useUser();
  // const [modalData, setData] = useModalData();
  // const [userName, setUserName] = useState();
  // const [userEmail, setUserEmail] = useState();

  // const storageData = Storage.load("user");

  // const storageDataName = storageData.safleID;
  // const storageDataToken = storageData.token;

  const changeSafleId = () => {
    setModalOnChangeSafleId("change_safleid");
  };
  // const addNewWalletAction = () => {
  //   setModalOnAddWallet("modal_add_wallet");
  // };

  // const linkWalletSafleIdAction = (e) => {
  //
  //   setModalOnLinkWallet("link_to_safleid");
  // };
  // const backupSeedsAction = async (e) => {
  //   const safleName = await getSafleName(storageDataToken);
  //   const confirm_email_otp = await userActions.resendEmailOTP(safleName.email);
  //   setUserName(safleName.name);
  //   setUserEmail(safleName.email);
  //   setData({
  //     type: "email-confirmation",
  //     safleID: storageDataName,
  //     email: safleName.email,
  //     flow: "export_seed_phrase",
  //   });

  //   setExportSeedPhrase("settings_export_seedphrase_otp");
  // };

  const onWalletVisible = () => {
    setWalletsVisible(true);
  };

  return (
    <>
      {!walletsVisible && (
        <div
          className="settings__overview_inner_content bs-0"
          data-show="manage_id"
        >
          <div className="settings__overview_inner-scroll">
            <div className="settings__overview_manage-safle">
              <SettingBox
                title={"Safle ID"}
                description={`you can change your ID ${LIMIT_SAFLEID_CHANGE} times`}
                icon={"msbico-change-id"}
                buttonLabel={"Change ID"}
                onClickAction={changeSafleId}
                enabled={true}
              />
              {/* <SettingBox
                title={ 'Generate Wallet' }
                description={ 'Generate wallets inside the vault' }
                icon={ 'msbico-generate-wallet' }
                buttonLabel={ 'Generate Now' }
                onClickAction={addNewWalletAction}
              /> */}
              <SettingBox
                title={"Link to SafleID"}
                description={"Link your wallet to SafleID"}
                icon={"msbico-link-wallet"}
                buttonLabel={"Coming Soon"}
                enabled={false}
                // onClickAction={() => onWalletVisible()}
                // extraClass="beta branded"
              />
              {/* <SettingBox
                title={ 'Backup Seeds' }
                description={ 'Backup and keep the seeds safe' }
                icon={ 'msbico-backup-now' }
                buttonLabel={ 'Backup Now' }
                onClickAction={backupSeedsAction}
              /> */}
            </div>
          </div>
        </div>
      )}

      {walletsVisible && (
        <div
          className="settings__overview_inner_content bg-white settings__link_wallet"
          // onClick={() => setWalletsVisible(false)}
          data-show=""
        >
          <div className="settings__overview_inner-scroll">
            <div className="link_wallet_header">
              <div className="link_wallet_label w_40">
                <div className="flex flex-row items-center justify-start">
                  <span
                    className="icon-arrow-back-black-24-dp text-3xl font-bold cursor-pointer"
                    onClick={() => setWalletsVisible(false)}
                  />
                  <span className="pl-12">Wallet Label</span>
                </div>
              </div>
              <div className="link_wallet_label w_40">Wallet Address</div>
              <div className="link_wallet_label w_20-right">
                Linked to SafleID?
              </div>
            </div>
            <div className="wallets_ctn">
              <SettingsIDContentWallets />
            </div>
          </div>
        </div>
      )}
      <AddWalletProcess />
      <LinkWalletSafleIdProcess />
      <ChangeSafleIdProcess />
      <SettingsExportSeedPhraseProcess />
    </>
  );
};

export default SettingsIDcontent;
