import React, { useEffect, useState } from "react";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  EXPORT_WALLET_PRIVATE_KEY_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";
import { useUser } from "../../../helpers/UserProvider";
import { useApp } from "../../../helpers/AppProvider";
import Storage from "../../../helpers/Storage";

const ModalEnterPinFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    EXPORT_WALLET_PRIVATE_KEY_FLOW
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [modalData, setModalData] = useModalData();
  const { userActions } = useUser();
  const { actions, appData } = useApp();
  // const enc = Storage.load("user")?.decriptionKey;
  const onClose = () => {
    setActiveModalNav(DEFAULT_MODAL_STATUS);
  };
  const callbackOpenPopup = async (pinNo) => {
    // const validatePin = await userActions.validatePin(pinNo);

    if (modalData.type === "export_pvkey") {
      const accounts = await actions.getAccounts(true);
      //&& validatePin === true
      const accountType = accounts?.find(
        (account) => account?.address === modalData?.address
      )?.type;
      const pvkey = await userActions.exportPrivateKey(
        modalData.address,
        // enc,
        parseInt(pinNo),
        null,
        accountType
      );
      await userActions.updateVaultData(
        pvkey?.response?.vault
          ? pvkey?.response?.vault
          : Storage.load("user").vault
      );
      if (!pvkey?.error) {
        setErrorMessage("");
        setModalData({ pvkey: pvkey.response.privateKey });
        setActiveModalNav("export_prvkey");
      } else {
        setErrorMessage(pvkey?.error);
      }
    }
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //

  return (
    <ModalEnterPin
      onClose={onClose}
      onCompletePIN={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
      modalCancel_label="export_prvkey_cancel"
      modalNext_label="export_prvkey"
      errorMessage={errorMessage}
    />
  );
};

export default ModalEnterPinFunctions;
