// import SidebarNotificationItem from '../SidebarNotificationItem/SidebarNotificationItem';
import React, { useState } from "react";
// import { Transition } from "@headlessui/react";

import { useApp } from "./../../../helpers/AppProvider";
import { middleEllipsis } from "./../../../helpers/generalHelper";

const SidebarNotificationItem = (props) => {
  // delete the notification
  // TO DO

  //hide the notification
  const [isRemoved, setIsRemoved] = useState(false);
  const [isRemovedClass, setIsRemovedClass] = useState(false);

  const { actions } = useApp();

  //there are 3 types
  // setting enable / disable
  // transaction request
  // login request
  //TO DO the time ago

  const onBtnAccept = (e) => {
    e.preventDefault();
  };
  const onBtnReject = (e) => {
    e.preventDefault();
  };

  // useEffect(() => {
  // 	setTimeout(() => {
  // 		setIsRemovedClass(true);
  // 	}, 500);
  // }, [isRemovedClass]);

  const onBtnClose = async (e) => {
    const item = props.items;

    e.preventDefault();

    setIsRemoved(true);
    setTimeout(() => {
      setIsRemovedClass(true);
    }, 250);
    try {
      await actions.updateUserNotification(item);
    } catch (error) {}
  };

  function itemDetails() {
    const item = props.items;
    //
    let label_notif = "";

    if (item.type === "settings") {
      return (
        <div
          className="notification__item"
          data-show={isRemoved}
          data-removed={isRemovedClass}
        >
          <div className="notification__item-top">
            <div className="notification__item-name">{item.text}</div>
            <div className="notification__item-time">{item.time_ago}</div>
            <div className="notification__item-close" onClick={onBtnClose}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "transaction") {
      label_notif = "Transaction Request";
      const asset_icon = item.asset_item.asset_icon;
      const asset_prefix = item.asset_item.asset_prefix;
      const asset_worth = item.asset_item.asset_worth;
      const from = middleEllipsis(item.from, 9);
      return (
        <div
          className="notification__item"
          data-show={isRemoved}
          data-removed={isRemovedClass}
        >
          <div className="notification__item-top">
            <div className="notification__item-name">{label_notif}</div>
            <div className="notification__item-time">{item.time_ago}</div>
            <div className="notification__item-close" onClick={onBtnClose}>
              <span className="icon-close"></span>
            </div>
          </div>
          <div className="notification__item-detail">
            <div className="asset__coin notification__item-coin-request">
              <div className="asset__coin-image">
                <div
                  className="asset__coin-image-icon"
                  style={{
                    backgroundImage: `url(${asset_icon})`,
                  }}
                ></div>
              </div>
              <div className="asset__coin-value">{asset_worth}</div>
              <div className="asset__coin_prefix">{asset_prefix}</div>
              <div className="asset__coin-address">
                form:{" "}
                <span className="address_short" title={item.from}>
                  {from}
                </span>
              </div>
            </div>
            <div className="notification__item-actions">
              <button className="btn-default btn-blue" onClick={onBtnAccept}>
                Accept
              </button>
              <button
                className="btn-default btn-white-bordered"
                onClick={onBtnReject}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "login") {
      label_notif = "Login Request";
      const site_logo = item.site_logo;
      const site_name = item.site_name;

      return (
        <div
          className="notification__item"
          data-show={isRemoved}
          data-removed={isRemovedClass}
        >
          <div className="notification__item-top">
            <div className="notification__item-name">{label_notif}</div>
            <div className="notification__item-time">{item.time_ago}</div>
            <div className="notification__item-close" onClick={onBtnClose}>
              <span className="icon-close"></span>
            </div>
          </div>
          <div className="notification__item-detail">
            {item.site_logo && item.site_name && (
              <div className="notification__item-request">
                <div className="asset__request-image">
                  <div
                    className="asset__request-image-icon no-border-radius"
                    style={{
                      backgroundImage: `url(${site_logo})`,
                    }}
                  ></div>
                </div>
                <div className="asset__request_name">{site_name}</div>
              </div>
            )}
            {item.site_logo && !item.site_name && (
              <div className="notification__item-request">
                {/*use this if ONLY Logo*/}
                <div className="asset__request-image asset__request-image-img">
                  <div className="asset__request-image-icon no-border-radius ">
                    <img src={`${site_logo}`} alt="Safle" />
                  </div>
                </div>
                <div className="asset__request_name">{site_name}</div>
              </div>
            )}

            <div className="notification__item-actions">
              <button className="btn-default btn-blue" onClick={onBtnAccept}>
                Accept
              </button>
              <button
                className="btn-default btn-white-bordered"
                onClick={onBtnReject}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      //else
      //do whatever you need

      return (
        <div
          className="notification__item"
          data-show={isRemoved}
          data-removed={isRemovedClass}
        >
          <div className="notification__item-top">
            <div className="notification__item-name">{item.text}</div>
            <div className="notification__item-time">{item.time_ago}</div>
            <div className="notification__item-close" onClick={onBtnClose}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>
      );
    }
  }

  return itemDetails();
};

export default SidebarNotificationItem;
