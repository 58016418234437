const TokenSymbolInput = ( { symbol, onChange } ) => {

    return (
        <div className="form__input form__input_default">
            <span className="input_label">Token Symbol</span>
            <div className="form__input_default">
                <input
                type="text"
                className="form-input-default"
                placeholder="Enter Token Symbol"
                onChange={onChange}
                value={symbol}
                />
            </div>
        </div>
    )
}

export default TokenSymbolInput;
