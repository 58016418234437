import DropdownNetwork from '../../../common/DropdownNetwork/DropdownNetwork';

const NetworkSelector = () => {
    return(
        <DropdownNetwork extraClass='controls__dropdown-network dropdown__light_blue header_dropdown_network ' />
        // <div className="main__controls controls__dropdown controls__dropdown-network margin__right-15">
        //     <div className="dropdown__active__item">
        //         <span
        //             className="item_ico bullet"
        //             style={{ backgroundColor: "#29b6af" }}
        //         />
        //         <span className="drp_name network_name">
        //             Ethereum Mainnet asdf asdf asdf asdf asdf
        //         </span>
        //         <span className="dropdown_arrow_down icon-chevron-down-solid-1" />
        //     </div>
        //     <div className="dropdown__list__items">
        //         <a href="/#" className="drop-link selected">
        //             <span
        //             className="item_ico bullet"
        //             style={{ backgroundColor: "#29b6af" }}
        //             />
        //             <span className="drp_name network_name">Ethereum Mainnet</span>
        //         </a>
        //         <a href="/#" className="drop-link">
        //             <span
        //             className="item_ico bullet"
        //             style={{ backgroundColor: "#ff4a8d" }}
        //             />
        //             <span className="drp_name network_name">Ethereum Testnet</span>
        //         </a>
        //         <a href="/#" className="drop-link">
        //             <span
        //             className="item_ico bullet"
        //             style={{ backgroundColor: "#d6d9dc" }}
        //             />
        //             <span className="drp_name network_name">
        //             Binaince Smart Chain Mainnet
        //             </span>
        //         </a>
        //         <a href="/#" className="drop-link">
        //             <span
        //             className="item_ico bullet"
        //             style={{ backgroundColor: "#f6c343" }}
        //             />
        //             <span className="drp_name network_name">ThaiChain</span>
        //         </a>
        //     </div>
        // </div>
    );
}

export default NetworkSelector;