// import React, { useState, useEffect, useRef } from 'react';
import { useApp } from "./../../../../helpers/AppProvider";
import { useAppChange } from "./../../../../helpers/generalHelper";

const NotificationBadge = () => {
  const { appData, actions } = useApp();

  //click on the badge change classes on body
  // document.body.classList
  useAppChange(appData, (changes) => {
    if (changes.showSidebarNotification) {
      if (!document.body.classList.contains("show_notif")) {
        document.body.classList.add("show_notif");
      }
    } else {
      if (document.body.classList.contains("show_notif")) {
        document.body.classList.remove("show_notif");
      }
    }
  });

  const onClick = (e) => {
    e.preventDefault();
    //click on the badge use the function above
    actions.setAppData({ showSidebarNotification: !appData.showSidebarNotification });
  };

  return (
    <div
      className="main__controls button_notification"
      title="Coming Soon"
      onClick={onClick}
    >
      {/* onClick={onClick} > */}
      <span className="icon-bell-regular" style={{ fontSize: 16 }} />
      <span
        className="notif_green"
        style={{ fontSize: 12, marginLeft: 2, marginTop: -1 }}
      />
    </div>
  );
};

export default NotificationBadge;
