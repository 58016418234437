import React from "react";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";

const ModalActionConfirm = ({
  onClose,
  title,
  desc,
  dataClass,
  primaryBtnText = "Yes",
  secBtnText = "No",
  goBackward,
  iconErr = "images/wallets/cancel_confirm.svg",
}) => {
  //
  return (
    <MainModal
      // onClose={onClose}
      onClose={goBackward}
      extraClasses={[
        "modal_w440",
        "modal_dflex",
        "modal_action",
        "modal_action_confirm",
      ]}
      dataClass={dataClass}
    >
      <div className="modal_success_content">
        <ModalHeadIcon img={iconErr} />
        <div className="modal_title" style={{ paddingBottom: 25 }}>
          {title}
        </div>
        <div className="modal_desc">{desc}</div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <PrimaryBtn
            classes={"btn-default btn-white-bordered close_modal_cancel"}
            label={primaryBtnText}
            onClick={onClose}
          />
          <PrimaryBtn
            classes={"btn-default btn-blue modal__trigger"}
            label={secBtnText}
            onClick={goBackward}
          />
        </div>
      </div>
    </MainModal>
  );
};

export default ModalActionConfirm;
