import React from 'react';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from '../generic/MainModal';

const ModalLinkWalletSuccess = ( { onClose } ) => {

    return (
        <MainModal onClose={ onClose } extraClasses={ [ ' modal_w400 modal_p30 ', 'modal_mh400 modal_dflex', 'modal_success_msg' ] } dataClass="modal_asset_added" >
            <div className="modal_succes_content">
            <div className="pt-16 ">
              <ModalHeadIcon span="icon-check-circle-solid" />
            </div>
            <div className="modal_succes_head text-[24px]">Successful</div>
            <div className="modal_succes_desc">
              <span className="simple_text simple_text_centered">
                You have successfully linked the <br />
                wallet to your SafleID
              </span>
            </div>
            </div>
            <div className="modal_succes_content_actions">
                <PrimaryBtn classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16 close_modal_ok" label="Ok" onClick={() => onClose()}/>
            </div>
        </MainModal>
    );
};

export default ModalLinkWalletSuccess;