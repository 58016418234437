import React, { useState, useMemo, useEffect } from 'react';
import { useApp } from '../../../helpers/AppProvider';
import ModalSwapDexSelect from '../../dialogs/ModalSwapDexSelect';



const SwapResume = () => {
  const { appData } = useApp();

  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);

  const [rate, setRate] = useState("");

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  const fromTokenData = appData.activeTokenA && appData.supportedTokens && appData.supportedTokens.filter(
    (token) => token.address.toLowerCase() === appData.activeTokenA.toLowerCase()
  );
  const toTokenData = appData.activeTokenB && appData.supportedTokens && appData.supportedTokens.filter(
    (token) => token.address.toLowerCase() === appData.activeTokenB.toLowerCase()
  );

  useEffect(() => {
    if(fromTokenData && fromTokenData.length && toTokenData && toTokenData.length)
      setRate("1.00 " + fromTokenData[0].symbol + " = " + Math.round(appData.exchangeRate * 100) / 100 + " " + toTokenData[0].symbol);
    else
      setRate("Please select tokens.");
  },[appData.activeTokenA, 
    appData.activeTokenB, 
    appData.supportedTokens, 
    appData.exchangeRate])

  // useEffect(() => {
  //   if(fromTokenData && fromTokenData.length && toTokenData && toTokenData.length)
  //     setRate("1.00 " + fromTokenData[0].symbol + " = " + Math.round(appData.exchangeRate * 100) / 100 + " " + toTokenData[0].symbol);
  //   else
  //     setRate("Please select tokens.");
  // }, [])

  const logo = useMemo(() => {
    if(appData.dexLogos) {
      return appData.dexLogos[appData.activeDex];
    } else {
      return null;
    }
  }, [appData.activeDex, appData.dexLogos]);

  // const rate = useMemo(() => {
  //   const fromTokenData = appData.supportedTokens && appData.supportedTokens.filter(
  //     (token) => token.address === appData.activeTokenA
  //   );
  //   const toTokenData = appData.supportedTokens && appData.supportedTokens.filter(
  //     (token) => token.address === appData.activeTokenB
  //   );
    
  //   if(fromTokenData && fromTokenData.length && toTokenData && toTokenData.length)
  //     return "1.00 " + fromTokenData[0].symbol + " = " + Math.round(appData.exchangeRate * 100) / 100 + " " + toTokenData[0].symbol;
  //   else
  //     return "Please select tokens.";
  // }, [appData.activeTokenA, appData.activeTokenB, appData.supportedTokens, appData.exchangeRate]);

  return (
    <div className='swap-slippage-row'>
      <div className='swap-summary-box'>
        <div className='swap-resume'>
          { rate }
        </div>
        <div className='swap-exchange-resume'>
          <div className='exchange'>
            <img src={ logo } alt="" />
            <span>{ appData.activeDex }</span>
          </div>
          {/* <img
            className='swap-edit-exchange'
            src="images/swap/icon-edit-exchange.svg"
            alt="" 
            onClick={() => openPopup('modal_swap_dex_select')}
          /> */}
        </div>
      </div>
      {openedPopup === 'modal_swap_dex_select' && <ModalSwapDexSelect onClose={onClose} />}
    </div>
  )
};

export default SwapResume;