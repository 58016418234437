import React, { useState } from "react";
import "./style.css";

const tabs = [
  //icon-wallet-solid, icon-nav_wallet, icon-icon-wallet-outline, icon-Navbar_icon_Wallet_deActive1, icon-Navbar_icon_Wallet_deActive, icon-navbar-icon-wallet-de-active
  //icon-navbar-icon-wallet-de-active
  {
    tabName: "Active Wallets",
    //icon: "icon-nav_wallet",
    icon: "images/wallets/wallet-active.svg",
  },
  {
    tabName: "Archived Wallets",
    icon: "images/vault/archive_wallet.svg",
  },
  {
    tabName: "Imported Wallets",
    icon: "images/wallets/wallet-import.svg",
  },
  {
    tabName: "Exported Wallets",
    icon: "images/wallets/wallet-export.svg",
  },
  {
    tabName: "Vault logs",
    //icon: "icon-nav_wallet",
    icon: "images/wallets/wallet-active.svg",
  },
];
const ManageVaultTabs = ({ setTab }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTabIndex(index);
    setTab(index);
  };

  return (
    <div>
      <div class="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex justify-start items-center text-[#8690A0]">
          {tabs?.map((tab, index) => (
            <li
              className={`${
                selectedTabIndex === index ? "selected-tab" : "tab"
              }`}
              style={{
                marginRight: "-20px",
                zIndex: selectedTabIndex === index ? 10 : 5 - index,
              }}
            >
              <div
                key={index}
                className="h-full flex justify-center items-center cursor-pointer text-2xl font-medium"
                onClick={() => handleTabClick(index)}
              >
                <span
                  className="mr-4"
                  //className={`main__menu-item-icon ${tab?.icon} fs_28 mr-4 `}
                >
                  <img src={tab?.icon} alt="" className={selectedTabIndex === index && "vault_active_tab_icon"}/>
                </span>
                <span className="">{tab.tabName}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ManageVaultTabs;
