// import React, { useState ,useEffect , useRef } from 'react';

const DropdownMobileFilterItem = (items) => {
  const item = items.items;
  //

  return (
    <div
      className={
        "drop-link dropdown__active__item control-filter-mobile-item " +
        (items.highlight ? "selected" : "")
      }
      onClick={items.clickHandle}
    >
      <div className="network-new-name-content">
        <span className="drp_name ">{item.filter_label}</span>
      </div>
    </div>
  );
};

export default DropdownMobileFilterItem;
