import React, { useState, useCallback, useEffect } from 'react';
import HideLowBalance from '../../dashboard/HideLowBalance/HideLowBalance';
import ReactSlider from 'react-slider';
import { useApp } from './../../../helpers/AppProvider';
const Swap = require("@getsafle/safle-swaps-v2");
let swap;

const SlippageRange = () => {
	const { appData, actions } = useApp();

  const [isChecked, setIsChecked] = useState(false);
  const [rangeValue, setRangeValue] = useState(appData.swapSlippage);

  const handleChecked = useCallback((isChecked) => {
    setIsChecked(!isChecked);
  }, []);

  const handleChange = useCallback((e) => {
    if(e.target.value > 50){
      e.target.value = 50
    }
    
    if(e.target.value< 1){
      e.target.value = 1
    }
    
    setRangeValue(e.target.value);
    actions.setAppData({ swapSlippage: e.target.value });
  }, [actions]);


  useEffect(()=>{
    let timer = setTimeout(async() => {
      actions.setAppData({ swapSlippage: rangeValue });
    }, 200);

    return ()=> clearTimeout(timer)
  },[rangeValue])


  return (
    <>
      <div className='swap-slippage-row'>
        <div className='slippage-content-box'>
          <span className="from_to_field_label swap-field-to-label">Maximum Slippage</span>
          {/* <input type="number" className="slippage-input" value={rangeValue} onChange={handleChange} min="1" max="50"/> */}
          <span className="from_to_field_label swap-field-to-label ml-2">{rangeValue}%</span>
        </div>
        {/* <div className='swap-slippage-custom'>
          <HideLowBalance
            title={'Custom'}
            handleChecked={handleChecked}
            isChecked={isChecked} />
        </div> */}
      </div>
      {
        // isChecked &&
        <div className='swap-slippage-row'>
          <div className='swap-splippage-range'>
            <ReactSlider
              className="customSlider"
              marks={[10, 20, 30, 40, 50]}
              markClassName="customSlider-mark"
              min={1}
              max={50}
              step={1}
              thumbClassName="customSlider-thumb"
              trackClassName="customSlider-track"
              renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
              value={rangeValue}
              defaultValue={1}
              onChange={(value) => {
                setRangeValue(value);
                
              }}
              renderMark={(props) => {
                if (props.key < rangeValue) {
                  props.className = "customSlider-mark customSlider-mark-before";
                } else if (props.key === rangeValue) {
                  props.className = "customSlider-mark customSlider-mark-active";
                }
                return <span {...props} />;
              }}
            />
          </div>
        </div>
        
        // <div className='swap-slippage-row'>
        //   <div className='swap-splippage-range'>
        //     <input
        //       className='swap-splippage-range-input'
        //       type="range"
        //       min="1"
        //       max="100"
        //       value={rangeValue}
        //       onChange={({ target: { value: rangeValue } }) => {
        //         setRangeValue(rangeValue);
        //       }}
        //     />
        //     <div className="range-value">
        //       {rangeValue}%
        //     </div>
        //     <div className="range-progress"></div>
        //   </div>
        // </div>
      }
    </>
  )

};

export default SlippageRange;