import React from 'react';
import ModalHeadIcon from '../common/ModalHeadIcon/ModalHeadIcon';
import PrimaryBtn from '../common/PrimaryBtn/PrimaryBtn';
import MainModal from './../generic/MainModal';

const ModalAssetAdded = ( { onClose } ) => {

    return (
        <MainModal onClose={ onClose } extraClasses={ [ 'modal_w400 modal_p30', 'modal_mh400 modal_dflex', 'modal_success_msg' ] } dataClass="modal_asset_added" >
            <div className='modal_succes_content'>
                <ModalHeadIcon span="icon-check-circle-solid" />
                <div className='modal_succes_head'>Asset Added</div>
                <div className='modal_succes_desc'>
                    <span className='simple_text simple_text_centered'>Lorem ipsum dolor sit amet, consectetur<br /> adipiscing elit. Phasellus id cursus</span>
                </div>
            </div>

            <div className='modal_succes_content_actions'>
                <PrimaryBtn classes="btn-default btn-blue btn-br6 btn-mh46 btn-fw600 btn-fs16 close_modal_ok" label="OK" />
            </div>
        </MainModal>
    );
};

export default ModalAssetAdded;