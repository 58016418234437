import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import MainModal from "../generic/MainModal";
import ModalHeadIcon from "../common/ModalHeadIcon/ModalHeadIcon";
// import PinInput from "../common/PinInput/PinInput";
// import PrimaryBtn from "../common/PrimaryBtn/PrimaryBtn";
import OTPInputs from "./../../components/registration/OTPInputs/OTPInputs";
import "../../styles/registration/otpVerification.scss";
// import CssFix from "../../helpers/cssFix";

import { ArrowNarrowRightIcon } from "@heroicons/react/solid/";

import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";

import useModalNavigation, {
  useModalData,
} from "../../helpers/useModalNavigation";
import {
  SETTINGS_EXPORT_SEED_PHRASE,
  SETTINGS_CHANGE_EMAIL,
  CHANGE_PIN_FLOW,
  CHANGE_PASSWORD_FLOW,
  // DEFAULT_MODAL_STATUS,
  FORGOT_PIN_FLOW,
} from "../../constants/ui";
import { useRecaptcha } from "../../helpers/UserProvider";
import Storage from "../../helpers/Storage";

const initialValues = {
  first: "",
  second: "",
  third: "",
  fourth: "",
  fifth: "",
  sixth: "",
};

const ModalPinConfirm = (attrs) => {
  const props = attrs;
  const [modalData, setData] = useModalData();
  let useModalNavigationData = "";
  if (modalData.flow === "export_seed_phrase") {
    // use this for the change email
    useModalNavigationData = SETTINGS_EXPORT_SEED_PHRASE;
  } else if (modalData.type === "change_pin") {
    useModalNavigationData = CHANGE_PIN_FLOW;
  } else if (modalData.type === "change_pass") {
    useModalNavigationData = CHANGE_PASSWORD_FLOW;
  } else if (modalData.type === "forgot-pin") {
    useModalNavigationData = FORGOT_PIN_FLOW;
  } else {
    useModalNavigationData = SETTINGS_CHANGE_EMAIL;
  }

  if (props.modalNavFlow) {
    useModalNavigationData = props.modalNavFlow;
  }
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] = useModalNavigation(
    useModalNavigationData
  );
  //

  const { actions } = useApp();
  const { userData, userActions } = useUser();
  const wtime = 30;
  // const { otpStatus, setOtpStatus, resetOtpStatus, setNewPasswordToken, setLoading } = useRegistration();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(wtime);
  const [numbers, setNumbers] = useState(initialValues);
  const [counting, setCounting] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const recaptchaRef = useRecaptcha();

  const timerRef = useRef(null);
  let tm;
  let now;

  const tooltipActiveClass = showTooltip ? " opened " : " ";
  const tooltipTimer = (e) => {
    setShowTooltip(true);
    timerRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };
  useEffect(() => {
    // Reset validation when process is reloaded.
    props.setErrorMessage && props.setErrorMessage(false);
    setErrorMessage("");
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    // Clear the interval when the component unmounts

    // eslint-disable-next-line react-hooks/exhaustive-deps
    now = new Date().getTime();
    setMinutes(0);
    setCounting(true);
    start_timer();

    return () => {
      clearTimeout(timerRef.current);
      clearInterval(tm);
    };
  }, []);

  const start_timer = () => {
    tm = setInterval(() => {
      let tl = wtime - ~~((new Date().getTime() - now) / 1000);
      //
      setSeconds(tl);
      if (tl < 1) {
        clearInterval(tm);
        setCounting(false);
      }
    }, 1000);
  };
  const resetOTP = () => {
    setNumbers({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
    });
    setForceRefresh(true);
    setErrorMessage("");
    setTimeout(() => {
      setForceRefresh(false);
    }, 100);
  };

  const verifyOTP = async () => {
    // actions.setLoading(true);

    const otp = `${numbers.first}${numbers.second}${numbers.third}${numbers.fourth}${numbers.fifth}${numbers.sixth}`;
    // props.callbackOpenPopup(otp);

    if (modalData.type === "reset_email") {
      if (modalData.otpType === "otp_old_email") {
        //use this if you reset password
        let params = {
          otp,
          email: modalData.old_email,
          action: "change-email-verification",
          safleId: modalData.safleID,
          name: modalData.safleName,
          newEmail: modalData.new_email,
        };
        const resp = await userActions.verifyChangeEmailOld(params);

        if (resp.statusCode === 200 || resp.statusCode === 201) {
          // continue to next modal
          setData({ otpType: "otp_new_email" });
          setActiveModalNav("modal_email_enter_secondary_pin");
        } else if (resp.statusCode === 400) {
          if (resp?.details[0]?.message === "Service Unavailable") {
            // redirect to modal retry
            setActiveModalNav("modal_email_address_failed");
          } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
            //Invalid OTP
            setErrorMessage("You have entered wrong verification code");
          } else {
            setErrorMessage(resp?.details[0]?.message);
          }
        }
      }
      if (modalData.otpType === "otp_new_email") {
        //use this if you reset password

        let params = {
          otp,
          oldEmail: modalData.old_email,
          action: "change-email",
          safleId: modalData.safleID,
          email: modalData.new_email,
        };
        const resp = await userActions.verifyChangeEmailNew(params);

        if (resp.statusCode === 201 || resp.statusCode === 200) {
          // continue to next modal

          await userActions.updateVaultToken(resp.data.token);
          setActiveModalNav("modal_email_updated");
        } else if (resp.statusCode === 400) {
          if (resp?.details[0]?.message === "Service Unavailable") {
            // redirect to modal retry
            setActiveModalNav("modal_email_address_failed");
          } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
            //Invalid OTP
            setErrorMessage("You have entered wrong verification code");
          } else {
            setErrorMessage(resp?.details[0]?.message);
          }
        }
      }
    } else if (modalData.type === "email-confirmation") {
      props.callbackOpenPopup(otp);
    } else if (modalData.type === "change_pin") {
      // otp, email, action, safleId

      let params = {
        otp,
        email: modalData.email,
        action: "change-pin",
        safleId: modalData.safleID,
      };

      const resp = await userActions.verifyChangePinOTP(params);

      if (resp.statusCode === 201 || resp.statusCode === 200) {
        // continue to next modal

        const respChange = await userActions.changePinRequest(
          modalData.currentPin,
          modalData.newPin
        );

        if (respChange) {
          setActiveModalNav("modal_change_pin_updated");
        } else {
          setActiveModalNav("modal_change_pin_failed");
        }

        // await userActions.updateVaultToken(resp.data.token);
      } else if (resp.statusCode === 400) {
        if (resp?.details[0]?.message === "Service Unavailable") {
          // redirect to modal retry
          setActiveModalNav("modal_change_pin_failed");
        } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
          //Invalid OTP
          setErrorMessage("You have entered wrong verification code");
        } else {
          setErrorMessage(resp?.details[0]?.message);
        }
      }
    } else if (modalData.type === "forgot-pin") {
      // otp, email, action, safleId

      let params = {
        otp,
        email: modalData.email,
        action: "forgot-pin",
        safleID: modalData.safleID,
      };

      const resp = await userActions.verifyEmailOTP(params);

      if (resp.statusCode === 201 || resp.statusCode === 200) {
        // continue to next modal
        setActiveModalNav("enter_seed_phrase");

        // const respChange = await userActions.changePinRequest(modalData.currentPin, modalData.newPin);
        // if(respChange){
        //     setActiveModalNav('enter_seed_phrase');
        // }else{
        //     setActiveModalNav('modal_change_pin_failed');
        // }

        // await userActions.updateVaultToken(resp.data.token);
      } else if (resp.statusCode === 400) {
        if (resp?.details[0]?.message === "Service Unavailable") {
          // redirect to modal retry
          setActiveModalNav("pin_changed_failed");
        } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
          //Invalid OTP
          setErrorMessage("You have entered wrong verification code");
        } else {
          setErrorMessage(resp?.details[0]?.message);
        }
      }
    } else if (modalData.type === "change_pass") {
      // const resp = await userActions.changePasswordRequest(modalData.token);

      actions.setLoading(true);
      // const decKey = userActions.getDecriptionKey();

      let params = {
        otp,
        email: modalData.email,
        action: "change-password",
        safleId: modalData.safleId,

        newPDKeyHash: modalData.newPDKHash,
        oldPDKeyHash: modalData.oldPDKHash,
        hashedPassword: modalData.hashedPassword,

        vault: userData.user.vault,
        encryptedEncryptionKey: modalData.enk,
      };
      const resp = await userActions.verifyChangePassOTP(params);

      if (resp.statusCode === 201 || resp.statusCode === 200) {
        let token = await recaptchaRef.current.executeAsync();

        const user = Storage.load("user");
        Storage.save("user", {
          ...user,
          pdkeyHash: params?.newPDKeyHash,
          vault: userData.user.vault,
        });
        await userActions?.retrieveVault(
          modalData.safleId,
          modalData.passNew,
          token
        );

        setActiveModalNav("modal_change_password_updated");
      } else if (resp.statusCode === 400) {
        if (resp?.details[0]?.message === "Service Unavailable") {
          // redirect to modal retry
          setActiveModalNav("modal_change_password_failed");
        } else if (resp?.details[0]?.message.indexOf("invalid") !== -1) {
          //Invalid OTP
          setErrorMessage("You have entered wrong verification code");
        } else {
          setErrorMessage(resp?.details[0]?.message);
        }
      }
      actions.setLoading(false);
    }

    actions.setLoading(false);
  };

  const handleResendOTP = async (e) => {
    e.preventDefault();

    actions.setLoading(true);
    if (modalData.type === "reset_email") {
      if (modalData.otpType === "otp_old_email") {
        const resp = await userActions.changeEmailRequest(modalData.token);
        if (resp.statusCode === 201) {
          // const dateNow = Date.now();
          resetOTP();
          now = new Date().getTime();
          setSeconds(wtime);

          setCounting(true);

          start_timer();
        }
      }
      if (modalData.otpType === "otp_new_email") {
        const resp = await userActions.resendEmailOTP(
          modalData.new_email,
          modalData.safleID
        );
        if (resp.statusCode === 201) {
          // const dateNow = Date.now();
          resetOTP();
          now = new Date().getTime();
          setSeconds(wtime);

          setCounting(true);

          start_timer();
        }
      }
    } else if (modalData.type === "change_pass") {
      //use this if reset password
      const resp = await userActions.changePasswordRequest(modalData.token);

      if (resp.statusCode === 201) {
        // const dateNow = Date.now();
        resetOTP();
        now = new Date().getTime();
        setSeconds(wtime);

        setCounting(true);

        start_timer();
      }
    } else if (modalData.type === "email-confirmation") {
      const resp = await userActions.resendEmailOTP(
        modalData.email,
        modalData.safleID
      );
      if (resp.statusCode === 201) {
        // const dateNow = Date.now();
        resetOTP();
        now = new Date().getTime();
        setSeconds(wtime);

        setCounting(true);

        start_timer();
      }
    } else if (modalData.type === "change_pin") {
      const resp = await userActions.resendEmailOTP(
        modalData.email,
        modalData.safleID
      );
      if (resp.statusCode === 201) {
        // const dateNow = Date.now();
        resetOTP();
        now = new Date().getTime();
        setSeconds(wtime);

        setCounting(true);

        start_timer();
      }
    } else if (modalData.type === "forgot-pin") {
      const resp = await userActions.resendEmailOTP(
        modalData.email,
        modalData.safleID
      );
      if (resp.statusCode === 201) {
        // const dateNow = Date.now();
        resetOTP();
        now = new Date().getTime();
        setSeconds(wtime);

        setCounting(true);

        start_timer();
      }
    }
    actions.setLoading(false);
  };

  return (
    <MainModal
      extraClasses={[
        "modal_w440",
        "modal_mh400",
        "modal_dflex",
        "modal_enter_pin",
        "modal_pin_confirm",
        "modal_pin_otp_confirm",
      ]}
      onClose={() => props.callbackCancelPopup(props.modalCancel_label)}
      // onClose={props.onClose}
      dataClass="modal_enter_pin"
    >
      <div className="modal_succes_content">
        {/* <CssFix /> */}
        <ModalHeadIcon img={"images/icon-pin.svg"} />
        <div className="modal_succes_head ">Enter verification code</div>
        <div className="modal_succes_desc">
          {modalData.otpType === "otp_old_email" && (
            <span className="simple_text simple_text_centered">
              We have sent you an email with OTP to email {modalData.old_email}
            </span>
          )}
          {modalData.otpType === "otp_new_email" && (
            <span className="simple_text simple_text_centered">
              We have sent you an email with OTP to email {modalData.new_email}
            </span>
          )}
          {!modalData?.otpType && (
            <span className="simple_text simple_text_centered">
              We have sent you an email with OTP
            </span>
          )}
        </div>
        <div className="modal_pin_pad-wrp">
          <div className="otp_inputs_verify">
            {/* <PinInput onCompletePIN={props?.onCompletePIN} timer={true} resendCode={true} /> */}
            {/* <PinInput timer={true} resendCode={true} /> */}
            {!forceRefresh && (
              <OTPInputs
                numbers={numbers}
                setNumbers={setNumbers}
                // disabled={!counting}
                forceRefresh={forceRefresh}
                errorCallback={setErrorMessage}
                otherParentClass={
                  "mt-1 relative opt_inputs flex items-center verifyOtpInput"
                }
              />
            )}
            {(errorMessage || props.errorMessage) && (
              <div className="flex my-3 lg:block seed_error_msg no_padding_left">
                {errorMessage || props.errorMessage}
              </div>
            )}
            <p className={"my-5 flex justify-center text-sm otp_timer"}>
              {" "}
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}{" "}
            </p>
          </div>
          <div className="my-7 flex justify-center md:justify-start text-xs didnt_receive">
            <span>Didn't receive the code?</span>

            {!counting && (
              <Link
                to={"#"}
                onClick={counting ? () => {} : handleResendOTP}
                className={
                  "flex items-center font-bold ml-1 sm:ml-2 " +
                  (counting ? "text-gray-200" : "text-blue-500")
                }
              >
                Resend Code
                <ArrowNarrowRightIcon className="h-2 w-2 ml-1" />
              </Link>
            )}
            {counting && (
              <Link
                to={"#"}
                onClick={(e) => tooltipTimer(e)}
                className={
                  "flex items-center font-bold ml-1 sm:ml-2 waiting_counter text-gray-200" +
                  tooltipActiveClass
                }
                data-timer={
                  "Wait for the counter to reach 0 in order to resend code."
                }
              >
                Resend Code
                <ArrowNarrowRightIcon className="h-2 w-2 ml-1" />
              </Link>
            )}
          </div>
        </div>
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            className="btn-default btn-white-bordered close_modal_cancel"
            onClick={() => props.callbackCancelPopup(props.modalCancel_label)}
          >
            Cancel
          </button>
          <button
            className="btn-default btn-blue "
            // onClick={() => props.callbackOpenPopup(props.modalNext_label)}
            onClick={verifyOTP}
            disabled={Object.values(numbers).join("").length < 6}
            style={{
              opacity: Object.values(numbers).join("").length < 6 ? 0.5 : 1,
            }}
          >
            Verify
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalPinConfirm;
