// import { PencilIcon } from "@heroicons/react/solid";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { RESTORE_WALLET_FLOW } from "../../constants/ui";
import { useApp } from "../../helpers/AppProvider";
import { middleEllipsis } from "../../helpers/generalHelper";
import useModalNavigation, {
  useModalData,
} from "../../helpers/useModalNavigation";
import TooltipCopy from "../registration/TooltipCopy";

const ArchivedWalletList = ({ item, openEnterPinModal }) => {
  const { actions } = useApp();
  // eslint-disable-next-line no-unused-vars
  const [modalData, setModalData] = useModalData();
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(RESTORE_WALLET_FLOW);
  const [txnCount, setTxnCount] = useState();
  const openExportPvKeyHandler = () => {
    setModalData({ type: "export_pvkey", address: item.address });
    openEnterPinModal();
  };

  const openRestoreWalletHandler = () => {
    setActiveModalNav("modal_restore_wallet_popup");
    setModalData({ address: item.address });
  };

  useEffect(() => {
    getTxnCount(item?.address, item?.chainName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTxnCount = async (address, chainName) => {
    try {
      actions.setLoading(true, "loading");
      const count = await actions.getWalletTransactions(address, chainName);
      setTxnCount(count?.length);
    } catch (e) {
    } finally {
      actions.setLoading(false);
    }
  };

  const tooltipText = "";
  const tooltipTextIn = "Copy to clipboard";

  const tooltipTextInChange = "Copied to clipboard";

  const TooltipIconSvg = () => {
    return <span className="icon_copy_gray icon-copy-90"></span>;
  };
  return (
    <>
      {item && item.address && (
        <tr>
          <td className="text-left pl-8">
            {typeof item?.label === "string"
              ? item?.label?.length > 20
                ? middleEllipsis(
                    item?.label,
                    parseInt(item?.label?.length * 0.1),
                    4
                  )
                : item?.label
              : item?.label?.polygon?.length > 20
              ? middleEllipsis(
                  item?.label?.polygon,
                  parseInt(item?.label?.polygon?.length * 0.1),
                  4
                )
              : item?.label?.polygon}
          </td>
          <td className="text-left pl-8">{middleEllipsis(item?.address)}</td>
          <td className="text-center">
            <span
              className="vault_chip "
              style={{
                background:
                  item?.chainName === "polygon"
                    ? "#A693FD"
                    : item?.chainName === "bitcoin"
                    ? "#F7931A"
                    : "#8690A0",
              }}
            >
              {item?.chainName.charAt(0).toUpperCase() +
                item?.chainName.slice(1)}
            </span>
          </td>
          <td className="text-center">
            {item?.createdAt !== "-"
              ? moment(item?.createdAt).format("DD-MM-YYYY , hh:mm:ss")
              : "-"}
          </td>
          <td className="text-center">
            {" "}
            {item?.archivedAt !== "-"
              ? moment(item?.archivedAt).format("DD-MM-YYYY , hh:mm:ss")
              : "-"}{" "}
          </td>
          <td className="text-center">{item?.type} </td>
          <td className="text-center" width="10%">
            {typeof txnCount === "number" ? txnCount : "-"}
          </td>
          <td>
            {" "}
            <div className="aoc_table_col">
              <span className="filter_table_wallet_controls vault_active_wallet_actions">
                <span className="filter_table_wallet-icon">
                  <span className="ilter_table_wallet-tooltiptext export-pkey ">
                    {/* <DuplicateIcon /> */}
                    <TooltipCopy
                      extraClasses="tooltip_registration tool_tip_vault_management cursor-pointer tooltip_webapp_copy"
                      textToDisplay={tooltipText}
                      imageToDisplay={<TooltipIconSvg />}
                      tooltipTextIn={tooltipTextIn}
                      tooltipTextInChange={tooltipTextInChange}
                      copiedText={item.address}
                      itemProps={item.address}
                      hideAfter="3000"
                    />
                  </span>
                </span>
                <span
                  onClick={() => openExportPvKeyHandler()}
                  className="filter_table_wallet-icon"
                >
                  <span className="filter_table_wallet-tooltiptext export-pkey">
                    Export Wallet{" "}
                  </span>
                  <img
                    src={"images/vault/vault_management_export_wallet.png"}
                    //width="34px"
                    //height="34px"
                    alt=""
                  />
                  {/*<span className="icon-icon-wallet-outline" />*/}
                </span>
                <span
                  className="filter_table_wallet-icon"
                  onClick={() => openRestoreWalletHandler()}
                >
                  <span className="filter_table_wallet-tooltiptext">
                    Restore Wallet
                  </span>
                  <img
                    src={"images/wallets/wallet-restore.svg"}
                    width="25px"
                    height="24px"
                    alt="Restore Wallet"
                  />
                </span>
              </span>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ArchivedWalletList;
