import React from "react";
import { RefreshIcon } from "@heroicons/react/solid/esm";
import "./../../../styles/registration/seeds.scss";

const Seeds = ({
  withBlur,
  mnemonic,
  randomNumbers,
  handleRegenerateSeed,
  setConfirmSeeds,
  confirmSeeds,
  hideRegen = false,
}) => {
  const { firstNumber, secondNumber } = randomNumbers || {
    firstNumber: null,
    secondNumber: null,
  };
  let parentRef = React.useRef(null);
  const setConfirm = (nr, e) => {
    let value = e.currentTarget.value.trim().toLowerCase();
    if (value) {
      const words = value.split(" ");
      value = words.join("");
      const regex = /^[a-z]+$/;
      if (regex.test(value)) {
        setConfirmSeeds(nr, value);
      }
    } else {
      setConfirmSeeds(nr, "");
    }
  };

  const seedVal = (nr) => {
    return randomNumbers
      ? {
          defaultValue: mnemonic[nr],
          value: confirmSeeds[nr],
          onChange: (e) => {
            setConfirm(nr, e);
          },
        }
      : { value: mnemonic[nr], readOnly: true };
  };

  const pasteHandler = (e) => {
    const val = e.clipboardData.getData("Text").split(" ");
    const inputs = parentRef.current.querySelectorAll("input");
    setTimeout(() => {
      for (var i = 0; i < 12; i++) {
        inputs[i].value = val[i];
        setConfirm(i, { currentTarget: { value: val[i] } });
      }
    }, 50);
    e.preventDefault();
  };

  return (
    <div>
      <div
        className="my-5 mb-0 flex flex-col shadow-sm seed_border py-1"
        ref={parentRef}
      >
        <div className={"px-5"}>
          <div className="flex space-x-4">
            <div
              className={
                (withBlur
                  ? firstNumber === 1 || secondNumber === 1
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>1</p>
              <input
                disabled={withBlur && firstNumber !== 1 && secondNumber !== 1}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(0)}
                className={
                  (withBlur && firstNumber === 1 && secondNumber === 1
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 2 || secondNumber === 2
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>2</p>
              <input
                disabled={withBlur && firstNumber !== 2 && secondNumber !== 2}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(1)}
                className={
                  (withBlur && (firstNumber === 2 || secondNumber === 2)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 3 || secondNumber === 3
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>3</p>
              <input
                disabled={withBlur && firstNumber !== 3 && secondNumber !== 3}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(2)}
                className={
                  (withBlur && (firstNumber === 3 || secondNumber === 3)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 4 || secondNumber === 4
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>4</p>
              <input
                disabled={withBlur && firstNumber !== 4 && secondNumber !== 4}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(3)}
                className={
                  (withBlur && (firstNumber === 4 || secondNumber === 4)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
          </div>
          <div className="flex mt-2 space-x-4">
            <div
              className={
                (withBlur
                  ? firstNumber === 5 || secondNumber === 5
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>5</p>
              <input
                disabled={withBlur && firstNumber !== 5 && secondNumber !== 5}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(4)}
                className={
                  (withBlur && (firstNumber === 5 || secondNumber === 5)
                    ? " "
                    : "filled filled_no_focus ") +
                  "*block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 6 || secondNumber === 6
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>6</p>
              <input
                disabled={withBlur && firstNumber !== 6 && secondNumber !== 6}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(5)}
                className={
                  (withBlur && (firstNumber === 6 || secondNumber === 6)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 7 || secondNumber === 7
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>7</p>
              <input
                disabled={withBlur && firstNumber !== 7 && secondNumber !== 7}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(6)}
                className={
                  (withBlur && (firstNumber === 7 || secondNumber === 7)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 8 || secondNumber === 8
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>8</p>
              <input
                disabled={withBlur && firstNumber !== 8 && secondNumber !== 8}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(7)}
                className={
                  (withBlur && (firstNumber === 8 || secondNumber === 8)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
          </div>
          <div className="flex mt-2 mb-2 space-x-4">
            <div
              className={
                (withBlur
                  ? firstNumber === 9 || secondNumber === 9
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>9</p>
              <input
                disabled={withBlur && firstNumber !== 9 && secondNumber !== 9}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(8)}
                className={
                  (withBlur && (firstNumber === 9 || secondNumber === 9)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 10 || secondNumber === 10
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>10</p>
              <input
                disabled={withBlur && firstNumber !== 10 && secondNumber !== 10}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(9)}
                className={
                  (withBlur && (firstNumber === 10 || secondNumber === 10)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 11 || secondNumber === 11
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>11</p>
              <input
                disabled={withBlur && firstNumber !== 11 && secondNumber !== 11}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(10)}
                className={
                  (withBlur && (firstNumber === 11 || secondNumber === 11)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
            <div
              className={
                (withBlur
                  ? firstNumber === 12 || secondNumber === 12
                    ? ""
                    : "filter blur-sm contrast-100 "
                  : "") + "flex flex-col mt-1 items-center w-3/12"
              }
            >
              <p className={"seed_font mb-1"}>12</p>
              <input
                disabled={withBlur && firstNumber !== 12 && secondNumber !== 12}
                type="text"
                onPaste={withBlur ? () => {} : pasteHandler}
                {...seedVal(11)}
                className={
                  (withBlur && (firstNumber === 12 || secondNumber === 12)
                    ? " "
                    : "filled filled_no_focus ") +
                  "block h-8 w-full seed_input_border"
                }
              />
            </div>
          </div>
        </div>
      </div>
      {withBlur === false && !hideRegen ? (
        <div className={"justify-center align-center seed_regenerate"}>
          <button
            onClick={handleRegenerateSeed ? handleRegenerateSeed : null}
            className={"flex flex-row p-5  w-full justify-center "}
          >
            <RefreshIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />{" "}
            Regenerate seed phrase
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Seeds;
