import SettingsMenuItem from "../SettingsMenuItem/SettingsMenuItem";

const SettingsMenuDesktop = ({
  dataMenuTop,
  dataMenuBottom,
  changePage,
  getCurrentPage,
}) => {
  return (
    <div className="settings__overview_aside-menu desktop_view">
      <div className="settings__overview_aside-menu-wrp">
        {dataMenuTop.map((item, index) => {
          return (
            <SettingsMenuItem
              activeClass={getCurrentPage === item.path ? "active" : ""}
              key={item.path}
              icon={item.icon}
              label={item.label}
              path={item.path}
              onClick={() => {
                changePage(item.path);
              }}
            />
          );
        })}
        <div className="settings__overview_aside-menu-item-divider" />
        {dataMenuBottom.map((item, index) => {
          return (
            <SettingsMenuItem
              activeClass={getCurrentPage === item.path ? "active" : ""}
              key={item.path}
              icon={item.icon}
              label={item.label}
              path={item.path}
              onClick={() => {
                changePage(item.path);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SettingsMenuDesktop;
