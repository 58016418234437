import React, { useEffect } from "react";

import ModalActionSuccess from "./../../dialogs/ModalActionSuccess";

import useModalNavigation from "../../../helpers/useModalNavigation";
import {
  EDIT_LABEL_WALLET_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const ModalSuccessFunctions = () => {
  // eslint-disable-next-line no-unused-vars
  const [setActiveModalNav, , goBackward] = useModalNavigation(
    EDIT_LABEL_WALLET_FLOW
  );

  const onClose = () => {
    setActiveModalNav("CLOSED");
    window.location.reload();
  };
  const callbackOpenPopup = (popup) => {
    setActiveModalNav(popup);
  };
  const callbackCancelPopup = (popup) => {
    setActiveModalNav(popup, true);
  };

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
  }, []);
  //

  return (
    <ModalActionSuccess
      title="Wallet Label updated"
      desc=""
      dataClass="modal_action_success1"
      onClose={onClose}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
    />
  );
};

export default ModalSuccessFunctions;
