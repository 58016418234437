import React from "react";
// import { useApp } from "../../helpers/AppProvider";
// import { useUser } from "../../helpers/UserProvider";
// import AssetsTheadFilter from "../assets/AssetsTheadFilter/AssetsTheadFilter";

function VaultListHeader({ tab, items = ["amount"] }) {
  const generateItem = () => {
    switch (tab) {
      case 0:
        return [
          "Wallet Label",
          "Address",
          "Chain",
          "Date Created",
          "Type of Wallet",
          "Transaction Count",
        ];
      case 1:
        return [
          "Wallet Label",
          "Address",
          "Chain",
          "Date Created",
          "Date Archived",
          "Type of Wallet",
          "Transaction Count",
        ];

      case 2:
        return ["Wallet Label", "Address", "Imported on", "Date Archived"];
      case 3:
        return ["Wallet Label", "Address", "Imported On", "Exported on"];
      case 4:
        return [
          "Timestamp",
          "Activity",
          "Wallet address",
          "From/To Platform",
          "where",
        ];
      default:
        return [];
    }
  };
  return (
    <tr>
      {generateItem().map((i, index) => {
        return <th>{i}</th>;
      })}
    </tr>
  );
}

export default VaultListHeader;

// <div className="scrollable_mobile">
//<table width={"80%"}>
//<tr>
// {generateItem().map((i, index) => {
// return (<th width={i?.width}>
// return (<th >
//{i?.name}
// </th>)
//})}
//        </tr>
//     </table>
//   </div>
// </div>
// </div>*/}

// </div>
//-----------------
//case 1:
//         return [
//           "Wallet Label",
//           "Address",
//           "Chain",
//           "Date Created",
//           "Date Archived",
//           "Type of Wallet",
//           "Transaction Count",
//         ];
//       case 2:
//         return ["Wallet Label", "Address", "Imported on"];
//       case 3:
//         return ["Wallet Label", "Address", "Exported on"];
//       case 4:
//         return [
//           "Timestamp",
//           "activity",
//           "Wallet address",
//           "From/To Platform ",
//           "Where",
//         ];
