import React from 'react';

const SeedPhraseVisibleButton = (props) => {

    return (
        <div className={props.cssClasses}>
            <img onClick={() => props.toggleSeedsVisibility()} src={`images/settings/${props.icon}`} alt="hide seed phrase" />
            <span className="tooltiptext">
                {props.tooltipText}
            </span>
        </div>        
    );
};

export default SeedPhraseVisibleButton;