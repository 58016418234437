import React from 'react';
import './../../../styles/registration/publicFooter.scss';
//check src/components/registration/footer dont know if this one is used
const PublicFooter = () => (
  <div className='h-10 mt-8 text-xs font-thin text-gray-400 flex justify-center flex--mobile'>
    <ul className='h-full flex flex-wrap justify-center items-center footer_list'>
      <li className=''>
        <a href={process.env.REACT_APP_LINK_ABOUT} target="_blank" rel="noopener noreferrer">About</a>
      </li>
      <li className='ml-6 bl_list'>
        <span className='relative -left-1'>
          <a className='m-0' href={process.env.REACT_APP_LINK_PRIVACY} rel="noopener noreferrer" target="_blank">
            Privacy policy
          </a>
        </span>
      </li>
      <li className='ml-6 pr-2 bl_list'>
        <span className='relative -left-1'>
          <a className='m-0' href={process.env.REACT_APP_LINK_TERMS} target="_blank" rel="noopener noreferrer">
            Terms &amp; Conditions
          </a>
        </span>
      </li>
      <li className='ml-2 footer_list_text'>
        <span>Copyright 2020, All rights reserved.</span>
      </li>
    </ul>
    <div className='flex justify-center items-center text-xs font-thin text-gray-400 mt-3 md:mt-0 ml-3'>
      <a href={process.env.REACT_APP_LINK_TWITTER} target="_blank" rel="noopener noreferrer" className='h-7 w-7 flex justify-center items-center border_socials p-1 mx-1'>
        <img
          alt='twitter social icon'
          className='h-5/6 w-5/6'
          src={`${process.env.PUBLIC_URL}/images/registration/brandIcons/twitter-brands.svg`}
        />
      </a>
      <a href={process.env.REACT_APP_LINK_MEDIUM} target="_blank" rel="noopener noreferrer" className='h-7 w-7 flex justify-center items-center border_socials p-1 mx-1'>
        <img
          alt='medium social icon'
          className='h-5/6 w-5/6'
          src={`${process.env.PUBLIC_URL}/images/registration/brandIcons/medium-m-brands.svg`}
        />
      </a>
      <a href={process.env.REACT_APP_LINK_DISCORD} target="_blank" rel="noopener noreferrer" className='h-7 w-7 flex justify-center items-center border_socials p-1 mx-1'>
        <img
          alt='discord social icon'
          className='h-5/6 w-5/6'
          src={`${process.env.PUBLIC_URL}/images/registration/brandIcons/discord-brands.svg`}
        />
      </a>
      <a href={process.env.REACT_APP_LINK_INSTAGRAM} target="_blank" rel="noopener noreferrer" className='h-7 w-7 flex justify-center items-center border_socials p-1 mx-1'>
        <img
          alt='instagram social icon'
          className='h-5/6 w-5/6'
          src={`${process.env.PUBLIC_URL}/images/registration/brandIcons/instagram-brands.svg`}
        />
      </a>
      <a href={process.env.REACT_APP_LINK_TELEGRAM} target="_blank" rel="noopener noreferrer" className='h-7 w-7 flex justify-center items-center border_socials p-1 mx-1'>
        <img
          alt='telegram social icon'
          className='h-5/6 w-5/6'
          src={`${process.env.PUBLIC_URL}/images/registration/brandIcons/telegram-brands.svg`}
        />
      </a>
    </div>
  </div>

);

export default PublicFooter;
