import React, { useState, useEffect, useRef } from "react";
import DropdownWalletItemNew from "../DropdownWalletItemNew/DropdownWalletItemNew";
import { useApp } from "../../../helpers/AppProvider";
import {
  limitTitleFromMiddle,
  middleEllipsis,
} from "../../../helpers/generalHelper";
// import { useUser } from "../../../helpers/UserProvider";
// import { checkIfWalletLinked } from "../../../helpers/walletHelpers";
import Blockie from "../Blockie/Blockie";
// const Web3 = require("web3");

//type = wamount / address

const DropdownWalletNew = (props) => {
  const { appData, actions } = useApp();
  // const { userData, userActions } = useUser();
  // const activeChain = appData.chains[appData.activeChain || 0];

  let dropdownType = "";
  let extraClass = "";
  if (props.compType && props.compType !== undefined) {
    dropdownType = props.compType;
  }
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const [addreses, setAddreses] = useState([]);

  //populate an object with items
  // const dummyAddress = [
  // 	{
  // 		// key : 1,
  // 		wallet_name: 'Wallet 1',
  // 		wallet_avatar: 'images/wallet-avatar-placeholer-small.svg',
  // 		wallet_address: '0x8fF74a97643028BCd4fA21fA73AB6BA54D851964',
  // 		wallet_ammount: '1',
  // 		wallet_prefix: 'ETH'
  // 	}
  // ];
  // first time load the first address in the object
  // or select the selected used address
  const get_addreses = async () => {
    // const acc = await userActions.getAccounts();
    // let filteredWallets = appData.wallets.filter((e) => {
    //   if (appData.activeWalletChain) {
    //     const curr = appData.activeWalletChain;
    //     return e.chain === curr.chainId;
    //   }
    //   return 1;
    // });
    // console.log(
    //   "🚀 ~ file: DropdownWalletNew.js ~ line 56 ~ filteredWallets ~ filteredWallets",
    //   filteredWallets
    // );
    // filteredWallets = filteredWallets?.filter(
    //   (i) => i.chain === appData.activeWalletChain?.chainId
    // );
    // const acc = filteredWallets;
    //
    // const addrs = acc ? Object.values(acc) : [];
    // // const wallets = actions.getWalletCards( addrs );
    //
    // const web3 = new Web3(
    //   new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3_PROVIDER_URL)
    // );
    let addrs = [];
    if (appData.accounts) {
      if (props.symbol === "BTC") {
        addrs = appData.accounts.filter(
          (account) => account.type === "bitcoin"
        );
      } else {
        addrs = appData.accounts.filter((account) => account.type === "evm");
      }
    }

    let addreses = await Promise.all(
      addrs.map(async (e) => {
        return {
          wallet_name:
            typeof e.label === "string" ? e?.label : e?.label?.polygon,
          wallet_avatar: "",
          // wallet_amount: await web3.eth.getBalance(e.address),
          wallet_prefix: "ETH",
          wallet_address: e?.address,
          // wallet_linked: await checkIfWalletLinked(e, userData?.user?.linkedWallets)
        };
      })
    );

    // addreses=[...addreses].filter(i=> i.wallet_linked)

    if (addreses.length > 0) {
      setAddreses(addreses);
      // appData?.activeWallet
      //   ? setDropdown(
      //       addreses[
      //         addreses.findIndex(
      //           (item) => appData?.activeWallet === item?.wallet_address
      //         )
      //       ]
      //     )
      //   :
      setDropdown(addreses[0]);
      // appData?.activeWallet
      //   ? setHighlightedItem(
      //       addreses[
      //         addreses.findIndex(
      //           (item) => appData?.activeWallet === item?.wallet_address
      //         )
      //       ]
      //     )
      //   :
      setHighlightedItem(addreses[0]);
      // appData?.activeWallet
      //   ? props.onSelect &&
      //     props.onSelect(
      //       addreses[
      //         addreses.findIndex(
      //           (item) => appData?.activeWallet === item?.wallet_address
      //         )
      //       ]
      //     )
      //   :
      props.onSelect && props.onSelect(addreses[0]);
    }
  };
  useEffect(() => {
    if (props.compType === "NFTDropDown" && props.wallets) {
      setAddreses(props.wallets);
      setDropdown(props.wallets[0]);
      return;
    }
    get_addreses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.accounts, props.wallets]);

  //used for clicking outside of dropdown
  const onClick = () => setIsActive(!isActive);

  // dropdown classes sum up
  //default class for dropdown
  const default_classes =
    "main__controls controls__dropdown controls__dropdown-new-wallet";
  // add classes based on props
  const dropdown_extra_classes = extraClass;
  // when clicked add class opened or remove class opened
  const isDropdownOpened = isActive ? "opened" : "";
  // sum all classes in to one
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  // used for the click outside of the dropdown to close and restrict scroll on body
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        //if outside remove class opened from dropdown
        setIsActive(!isActive);

        if (props?.noBlockScroll === false) {
          //in popup dont remove add class
          // actions.setAppData({ blockScroll: false });
        } else {
          actions.setAppData({ blockScroll: false });
        }
        // document.body.classList.remove('no_scroll_dropdown');
      }
    };
    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener("click", pageClickEvent);
      // document.body.classList.add('no_scroll_dropdown');

      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: true });
      }
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);

      // document.body.classList.remove('no_scroll_dropdown');
      if (props?.noBlockScroll === false) {
        //in popup dont remove add class
        // actions.setAppData({ blockScroll: false });
      } else {
        actions.setAppData({ blockScroll: false });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  // on click item in dropdown do this ;
  const onClickItem = (index, addr) => {
    // add class with selected
    setHighlightedItem(index);
    // set the state for the dropdown with last value
    setDropdown(addr);
    setIsActive(!isActive);
    props.onSelect && props.onSelect(addr);

    // remove class from body scroll
    // close the dropdown
    // setIsActive(!isActive);
    if (props?.noBlockScroll === false) {
      //in popup dont remove add class
      // actions.setAppData({ blockScroll: false });
    } else {
      actions.setAppData({ blockScroll: false });
    }
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(addreses.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, addreses[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };
  return (
    <div
      className={finalDrpClasses}
      ref={dropdownRef}
      onKeyUp={dropdownMoveUp}
      tabIndex="0"
    >
      <div
        className="dropdown__active__item control-wallet-dropwdon-new"
        onClick={onClick}
        style={{ alignItems: props.compType === "buyAssets" && "start" }}
      >
        {(dropdownType === "NFTDropDown" || dropdownType === "sendAssets") && (
          <div className="wallet-address-new-name-content wallet-address-receive-assets">
            <img src={props.icon} alt="" />
            <span className="drp_name wallet_address wallet-address-new">
              {limitTitleFromMiddle(dropdown?.wallet_name, 16)}
            </span>
            <span className="drp_name wallet_address wallet-name-new">
              {limitTitleFromMiddle(dropdown?.wallet_address, 10)}
            </span>
          </div>
        )}
        {/* {dropdownType !== "receiveAssets" && dropdownType !== "NFTDropDown" && (
          <>
            <div className="wallet-address-new-name-content">
              <img src={dropdown.wallet_avatar} alt="" />
              <span className="drp_name wallet_address wallet-name-new">
                {dropdown.wallet_name}
              </span>
            </div>
            <span className="drp_name wallet_address wallet-address-new">
              {dropdown.wallet_address}
            </span>
          </>
        )} */}
        {dropdownType === "receiveAssets" && (
          <div className="wallet-address-new-name-content wallet-address-receive-assets">
            <img src={props.icon} alt="" />
            <span className="drp_name wallet_address wallet-address-new">
              {middleEllipsis(dropdown.wallet_address, 7)}
            </span>
          </div>
        )}
        {dropdownType === "buyAssets" && (
          <div>
            <div className="wallet-address-new-name-content">
              <div>
                {dropdown?.wallet_address ? (
                  <Blockie
                    size={6}
                    address={dropdown?.wallet_address}
                    label={dropdown.wallet_name}
                  />
                ) : null}
              </div>
              <span className="drp_name wallet_address wallet-name-new">
                {limitTitleFromMiddle(dropdown?.wallet_name, 20)}
              </span>
            </div>
            <span className="drp_name wallet_address wallet-address-new ml-2">
              {limitTitleFromMiddle(dropdown.wallet_address, 10)}
            </span>
            <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
          </div>
        )}
        <span className="dropdown_arrow_down icon-chevron-down-solid-1"></span>
        {/* {dropdownType === "wamount" && (
          <span className="wallet_asset_amount">
            {dropdown.wallet_ammount} {dropdown.wallet_prefix}
          </span>
        )} */}
      </div>
      <div className="dropdown__list__items">
        {addreses.map((dummyAddres, index) => (
          <DropdownWalletItemNew
            items={dummyAddres}
            drpType={dropdownType}
            highlight={index === highlighted}
            key={index}
            clickHandle={() => onClickItem(index, dummyAddres)}
          />
        ))}
      </div>
    </div>
  );
};

export default DropdownWalletNew;
