/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import DropdownNetworkItemNew from "../DropdownNetworkItemNew/DropdownNetworkItemNew";
import { useApp } from "../../../helpers/AppProvider";

const DropdownNetworkNew = (props) => {
  const { appData, actions } = useApp();
  const { showLabel = true } = props;
  let extraClass = "";
  if (props.extraClass && props.extraClass !== undefined) {
    extraClass = props.extraClass;
  }

  const dropdownRef = useRef(null);
  const [chosenChain, setChosenChain] = useState(
    appData?.chains?.findIndex((chain) =>
      appData?.activeAssetChain === "eth"
        ? chain.chain_name === "ethereum"
        : chain.chain_name === appData?.activeAssetChain
    ) || 0
  );
  const [isActive, setIsActive] = useState(false);
  const [highlighted, setHighlightedItem] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [chains, setChains] = useState([]);

  const dummyAddress = [
    {
      network_name: props.isNetworkOnly ? "ethereum" : "Ethereum Mainnet",
      network_logo: "images/currency/currency-ethereum-gray.svg",
    },
  ];

  useEffect(() => {
    if (props.hasOwnProperty("networks")) {
      let networks = props.networks
        ? appData.chains.filter(
            (e) => props.networks.indexOf(e.chain_name) !== -1
          )
        : [];

      if (networks.length > 0) {
        networks = networks.map((e) => {
          return {
            network_name: e.chain_name,
            network_logo: e.details.imageURL,
          };
        });
        setChains(networks);
      }
    }
  }, [props.networks]);

  const onClick = () => setIsActive(!isActive);

  const default_classes = "main__controls controls__dropdown drp1";
  const dropdown_extra_classes = extraClass;
  const isDropdownOpened = isActive ? "opened" : "";
  const finalDrpClasses =
    default_classes + " " + dropdown_extra_classes + " " + isDropdownOpened;

  useEffect(() => {
    setTimeout(() => {
      setIsActive(false);
    });
  }, []);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(false);
        if (props?.noBlockScroll === false) {
        } else {
          actions.setAppData({ blockScroll: false });
        }
      }
    };

    if (isActive) {
      window.addEventListener("click", pageClickEvent);

      if (props?.noBlockScroll === false) {
      } else {
        actions.setAppData({ blockScroll: true });
      }
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
      if (props?.noBlockScroll === false) {
      } else {
        actions.setAppData({ blockScroll: false });
      }
    };
  }, [isActive, props]);

  useEffect(() => {
    const AllChain = {
      network_name: "All Chain",
      network_logo: "images/wallets/all-chain.svg",
    };
    setIsActive(false);
    onClickItem(0, AllChain);
  }, []);

  // on click item in dropdown do this ;
  const onClickItem = (index, chain) => {
    // add class with selected
    setHighlightedItem(index);
    setChosenChain(index);
    // set the state for the dropdown with last value
    setDropdown(chain);
    const chainId = appData?.chains?.find(
      (item) => item?.chain_name === chain?.network_name 
    )?.chainId;
    if (chain?.network_name && appData?.chains?.length) {
      actions.setAppData({
        activeWalletChain: chain,
        activeChain: chainId ? chainId : 0,
      });
    }
    // remove class from body scroll
    // close the dropdown
    setIsActive(!isActive);
    props.onSelect && props.onSelect(chain);

    if (props?.noBlockScroll === false) {
    } else {
      actions.setAppData({ blockScroll: false });
    }
    document.body.classList.remove("no_scroll_dropdown");
  };

  const dropdownMoveUp = (e) => {
    let updateHighlightVal = highlighted;
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      if (e.key === "ArrowUp") {
        //up
        updateHighlightVal -= 1;
      } else {
        //down
        updateHighlightVal += 1;
      }
      updateHighlightVal = Math.max(
        0,
        Math.min(dummyAddress.length - 1, updateHighlightVal)
      );
      setHighlightedItem(updateHighlightVal);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    //if press enter after up down key
    if (e.code === "Enter") {
      //updateHighlightVal === index in object
      //dummyAddress[updateHighlightVal] === set addres from objec[X]
      onClickItem(updateHighlightVal, chains[updateHighlightVal]);
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    //
    e.stopPropagation();
  };
  return (
    <div
      className={`${
        props.type === "header"
          ? "form__input form__input_default"
          : "assets__overview_nav_right"
      } dropdownItemResponsive`}
    >
      {showLabel && (
        <span className="input_label">
          {props.type === "header" ? "Select Chain" : ""}
        </span>
      )}
      <div
        className={finalDrpClasses}
        ref={dropdownRef}
        onKeyUp={dropdownMoveUp}
        tabIndex="0"
      >
        <div
          className="dropdown__active__item control-network-dropwdon-new"
          onClick={onClick}
        >
          <div className="network-new-name-content new-name-size">
            <img src={dropdown?.network_logo} alt="" />
            {/* <span className="drp_name network_name network-name-new"> */}
            {dropdown?.network_name && (
              dropdown?.network_name.charAt(0).toUpperCase() + dropdown.network_name.slice(1)
            )}
            {/* </span> */}
          </div>
          <span className="dropdown_arrow_down icon-chevron-down-solid-1 "></span>
        </div>

        <div className="dropdown__list__items">
          {/* Add the "All Chain" option as the first item in the array */}
          {[
            {
              network_name: "All Chain",
              network_logo: "images/wallets/all-chain.svg",
            },
            ...chains,
          ].map((dummyAddres, index) => (
            <DropdownNetworkItemNew
              items={dummyAddres}
              highlight={index === highlighted}
              key={index}
              isSelected={index === chosenChain}
              clickHandle={() => onClickItem(index, dummyAddres)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownNetworkNew;