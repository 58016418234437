import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ROUTES from "../../../helpers/routes";
const PageTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const path = Object.values(ROUTES);
    const index = path.indexOf(location.pathname);
    document.title =
      Object.keys(ROUTES)[index]?.replace(/_/g, " ") || "SAFLE WEBAPP";
  }, [location]);

  return null;
};

export default PageTitleUpdater;
