import React from "react";

const LoaderScreen = () => {
  return (
    <div className="w-[100%] flex flex-col justify-center items-center h-[100%]">
      <img
        src="/images/registration/loading.svg"
        alt="loading"
        width={"20%"}
        className="my-4"
      />
      <img
        src="images/landing_page/Safle_Logo.svg"
        alt="Logo"
        className="mx-auto my-4"
        style={{ maxWidth: "150px" }}
      />
    </div>
  );
};

export default LoaderScreen;
