import React, { useEffect } from "react";

import ModalEnterVerificationCode from "./../../dialogs/ModalEnterVerificationCode";

import ModalEnterPin from "./../../dialogs/ModalEnterPin";
import { useApp } from "../../../helpers/AppProvider";
import { useUser, isValidEmail } from "../../../helpers/UserProvider";
import Storage from "../../../helpers/Storage";

import useModalNavigation, {
  useModalData,
} from "../../../helpers/useModalNavigation";
import {
  CHANGE_PASSWORD_FLOW,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const ModalPinConfirmFunctions = () => {
  const [setActiveModalNav, goNext, goBackward] =
    useModalNavigation(CHANGE_PASSWORD_FLOW);
  const [modalData, setData] = useModalData();

  const onClose = () => {
    setActiveModalNav("CLOSED");
  };

  // ON VERIFY CLICK
  const callbackOpenPopup = (next) => {
    goNext();
  };

  //on CANCEL CLICK
  const callbackCancelPopup = (popup) => {
    setActiveModalNav("modal_change_password_cancel", true);
  };
  const onCompletePIN = (value) => {};

  useEffect(() => {
    // Check and display if any modal flow is in progress in local session
    setActiveModalNav();
    //send request with
  }, []);
  //

  return (
    <ModalEnterVerificationCode
      onClose={onClose}
      onOkHandler={onCompletePIN}
      callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
      callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
    />
  );
};

export default ModalPinConfirmFunctions;
