import CurrencyIcon from "../CurrencyIcon/CurrencyIcon";

const CurrencyItem = ({
  icon,
  active,
  currencyName,
  currencyPrefix,
  currencySymbol,
  onClick,
}) => {
  return (
    <div
      className={`select__currency select__currency_item form__input_filter_item ${
        active ? "active_currency" : ""
      }`}
      onClick={() => onClick(currencyName)}
    >
      <CurrencyIcon icon={icon} currencyPrefix={currencyPrefix} />
      <div className="select__currency-details">
        <div className="select__currency-name">{currencyName}</div>
        <div className="select__currency_prefix">
          {currencyPrefix} - {currencySymbol}
        </div>
      </div>
      <div className="selected__currency">
        {active && <span className="icon-circle-check-solid"></span>}
        {!active && <span className="icon-circle"></span>}
      </div>
    </div>
  );
};

export default CurrencyItem;
