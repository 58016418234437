import React, { useEffect }  from 'react';

import ModalRemoveWalletPopup from './../../dialogs/ModalRemoveWalletPopup';



import useModalNavigation from '../../../helpers/useModalNavigation';
import { REMOVE_WALLET_FLOW, DEFAULT_MODAL_STATUS } from '../../../constants/ui';


const ModalRemoveWalletFunctions = () => {
   
    // eslint-disable-next-line no-unused-vars
    const [setActiveModalNav, goNext, goBackward] = useModalNavigation(REMOVE_WALLET_FLOW);
    
    const onClose = () => {
        setActiveModalNav('modal_remove_cancel_wallet',true);
    };
    const callbackOpenPopup = (popup) => {
        setActiveModalNav('modal_remove_enter_pin');
    };
    const callbackCancelPopup = (popup) => {
        setActiveModalNav('modal_remove_cancel_wallet', true);
    };
    

    
    useEffect( () => {
        // Check and display if any modal flow is in progress in local session
        setActiveModalNav();
    }, []);

    return (
        <ModalRemoveWalletPopup 
            onClose={() => onClose()} 
            callbackOpenPopup={(modalKey) => callbackOpenPopup(modalKey)}
            callbackCancelPopup={(modalKey) => callbackCancelPopup(modalKey)}
        />
        
    )
}

export default ModalRemoveWalletFunctions;