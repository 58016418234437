
const RecentActivityStatus = ({ label='Completed', failed='' }) => {
  
  const isFailed = failed;
  let color_default = '';
  let label_failed = label;
  if(isFailed){
    color_default = 'trans_failed';
    label_failed = 'Failed';
  }

  return (
    <div className={'activity_asset_trans_status ' + color_default}>
      {label_failed}
    </div>
  );
};

export default RecentActivityStatus;