import Header from "../components/common/Header/Header";
import SettingsOverview from "../components/settings/SettingsOverview/SettingsOverview";
import Sidebar from "../components/common/Sidebar/Sidebar";
import SidebarNotification from "../components/common/SidebarNotification/SidebarNotification";

import UserInfoOverview from "../components/settings/UserInfoOverview/UserInfoOverview";
// import { useApp } from "../helpers/AppProvider";
import { useState } from "react";
import ChangeProfileImageProcess from "../components/common/ChangeProfileImageProcess/ChangeProfileImageProcess";
import ForgotPinProcess from "../components/common/ForgotPinProcess/ForgotPinProcess";
// import { useUser } from "../helpers/UserProvider";
import SettingsOverviewMobile from "../components/settings/SettingsOverviewMobile/SettingsOverviewMobile";

const SettingsScreen = () => {
  const [sidebarActive, setSidebarActive] = useState(false);

  const [linkSafleIdOpen, setLinkSafleIdOpen] = useState(false);
  const [changePasswordMobileOpen, setChangePasswordMobileOpen] =
    useState(false);
  const [changePINMobileOpen, setChangePINMobileOpen] = useState(false);
  const [currencyContentOpen, setCurrencyContentOpen] = useState(false);
  const [guardianSetupOpen, setGuardianSetupOpen] = useState(false);
  const [contactSupportOpen, setContactSupportOpen] = useState(false);

  return (
    <>
      <Header
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <div>
        <div className="main__content__wrapper">
          {/* aside navigation */}
          <Sidebar
            sidebarActive={sidebarActive}
            setSidebarActive={setSidebarActive}
          />
          {/* end aside navigation */}
          {/* main content */}

          <div className="main__content__wrp settings-overview-wrapper">
            <div
              className={
                "settings__overview " + linkSafleIdOpen
                  ? "settings__overview link_safleid_mobile_active"
                  : "settings__overview"
              }
            >
              {!linkSafleIdOpen &&
                !changePasswordMobileOpen &&
                !changePINMobileOpen &&
                !currencyContentOpen &&
                !guardianSetupOpen &&
                !contactSupportOpen && <UserInfoOverview />}
              <SettingsOverview />
              <SettingsOverviewMobile
                linkSafleIdOpen={linkSafleIdOpen}
                setLinkSafleIdOpen={setLinkSafleIdOpen}
                changePasswordMobileOpen={changePasswordMobileOpen}
                setChangePasswordMobileOpen={setChangePasswordMobileOpen}
                changePINMobileOpen={changePINMobileOpen}
                setChangePINMobileOpen={setChangePINMobileOpen}
                currencyContentOpen={currencyContentOpen}
                setCurrencyContentOpen={setCurrencyContentOpen}
                guardianSetupOpen={guardianSetupOpen}
                setGuardianSetupOpen={setGuardianSetupOpen}
                contactSupportOpen={contactSupportOpen}
                setContactSupportOpen={setContactSupportOpen}
              />
            </div>
          </div>

          {/* end main content */}
          {/* aside notification */}
          <SidebarNotification />
          {/* end aside notification */}
        </div>
        <div className="main__footer" />
        <div className="drop_shadow" />
        <div className="drop_shadow_menu" />
      </div>

      <ChangeProfileImageProcess />
      <ForgotPinProcess />
      {sidebarActive && <div className="mobile_blur"></div>}
    </>
  );
};

export default SettingsScreen;
