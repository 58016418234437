import React from "react";
import MainModal from "./../generic/MainModal";
import { delay_code } from "../../helpers/generalHelper";
import { useApp } from "../../helpers/AppProvider";
import { useUser } from "../../helpers/UserProvider";

const ModalLogoutConfirm = ({ onClose }) => {
  const { actions } = useApp();
  const { userActions } = useUser();

  const logoutHandler = async () => {
    actions.setLoading(true);
    await delay_code(500);
    await actions.logout();
    await userActions.logout();
    actions.setLoading(false);
  };

  return (
    <MainModal
      onClose={onClose}
      extraClasses={[
        "modal_w440 modal_p30",
        "modal_dflex",
        "modal_wallet--1",
        "modal_logout_confirm",
      ]}
      dataClass="modal_logout_confirm"
    >
      {/* Modal content */}
      <div className="modal_head--1">
        <img src="images/warning-black-24-dp.svg" alt="" />
        <div className="modal_title">Confirm Logout</div>
        <div className="modal_desc">Are you sure you want to logout?</div>
      </div>
      <div className="modal_footer">
        <div className="modal_succes_content_actions modal_succes_content_actions_two_btns ">
          <button
            onClick={logoutHandler}
            className="btn-default btn-white-bordered close_modal_cancel"
          >
            Yes
          </button>
          <button
            onClick={onClose}
            className="btn-default btn-blue modal__trigger"
            data-target="modal_change_safle_id_txn"
          >
            No
          </button>
        </div>
      </div>
    </MainModal>
  );
};

export default ModalLogoutConfirm;
