import React, { useEffect, useRef, useState } from "react";
import { useApp } from "../../../helpers/AppProvider";
import { useUser } from "../../../helpers/UserProvider";

import CurrencyItem from "../CurrencyItem/CurrencyItem";
import FiatCurrenciesList from "../FiatCurrenciesList/FiatCurrenciesList";
import PopularCurrenciesList from "../PopularCurrenciesList/PopularCurrenciesList";
// import PopularCurrenciesListMobile from "../PopularCurrenciesListMobile/PopularCurrenciesListMobile";
import SearchInput from "../SearchInput/SearchInput";

const CurrencyContent = ({ contentHeight = 1000 }) => {
  const topHeader = useRef();
  const { appData, actions } = useApp();
  const { userData } = useUser();
  const [search, setSearch] = useState("");
  const [height, setHeight] = useState(contentHeight);
  const [isMobile, setIsMobile] = useState(false);

  const dataPopularCurrencies = appData.currencies.filter(
    (curr) => curr?.popular === "true"
  );

  const handleFilter = (e) => {
    setSearch(e.target.value);
  };

  const selectCurrency = async (currencyName) => {
    actions.setLoading(true);

    const curr = appData.currencies.find(
      (e) => e["Currency Name"] === currencyName
    );
    if (curr) {
      // const rate = await actions.getCurrencyRate(curr["Currency Code"]);
      // //
      // curr.rate = rate;
      actions.saveActiveCurrency(curr, userData.user.token);
      // actions.setAppData({ activeCurrency: curr });
      actions.setLoading(false);
    }
  };

  useEffect(() => {
    const resizeFn = () => {
      if (window.innerWidth < 1241) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", resizeFn);

    return () => {
      window.removeEventListener("resize", resizeFn);
    };
  }, []);

  useEffect(() => {
    if (topHeader.current) {
      const cs = window.getComputedStyle(topHeader.current, null);
      const h = parseInt(cs.getPropertyValue("height"));

      if (isMobile) {
        setHeight(contentHeight);
      } else {
        setHeight(contentHeight - h);
      }
    }
  }, [contentHeight, isMobile]);

  return (
    <div
      className="settings__overview_inner_content white_bg"
      data-show="select_currency"
    >
      <div className="settings__overview_inner-scroll">
        <div className="settings__overview_select_currency">
          <div className="form__inner_filter_assets">
            <div className="form__inner_filter_top_header" ref={topHeader}>
              <div className="form__inner_filter_top_header--width">
                <div className="form__inner_filter_top_header_h">
                  Currency Select
                </div>
                <div className="form__inner_filter_header">
                  <SearchInput search={search} handleFilter={handleFilter} />
                </div>
              </div>
              <div className="form__inner_filter_top_header_s_currency">
                <span>
                  <img src="images/settings/icon-done.svg" alt="icon done" />
                </span>
                <CurrencyItem
                  icon={`${
                    appData.activeCurrency &&
                    appData.activeCurrency.hasOwnProperty("Country Flag")
                      ? appData.activeCurrency["Country Flag"]
                      : "images/flags/dollor.png"
                  }`}
                  currencyName={`${appData?.activeCurrency["Currency Name"]}`}
                  currencyPrefix={`${
                    appData?.activeCurrency["Currency Code"]
                  } - ${appData?.activeCurrency["Currency Symbol"] || ""}`}
                  active={true}
                />
              </div>
            </div>

            <div
              className="settings__overview_select_currency_inner-scroll"
              style={{ height: height, maxHeight: height }}
            >
              {/* <PopularCurrenciesListMobile search={search} data={ appData.currencies } /> */}
              <PopularCurrenciesList
                hideLabel={isMobile}
                data={dataPopularCurrencies}
                onClick={selectCurrency}
                activeCurrency={appData?.activeCurrency["Currency Name"]}
              />
              <FiatCurrenciesList
                hideLabel={isMobile}
                search={search}
                data={appData.currencies}
                selectCurrency={selectCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyContent;
