import React from 'react';
import MainModal from '../generic/MainModal';

const ModalWalletFailed = ({ onClose, callbackOpenPopup, validation, reason = 'Failed to succeed', retryModal }) => {

    return (
        <MainModal
            onClose={onClose}
            extraClasses={['modal_w440 modal_p30', 'modal_dflex', 'modal_wallet_import_failed']}
            dataClass="modal_set_wallet_label" >
            {/* Modal content */}
            <div className="modal_head">
                <img src="images/wallets/icon-warning.svg" alt="" className='modal_icon' />
                <div className="modal_title">
                    <div>{reason}</div>
                    {validation &&
                    <div className="validation">{validation}</div>}
                    </div>
            </div>

            <div className="modal_footer">
                <div className="modal_succes_content_actions modal_succes_content_actions_two_btns">
                    <button className="btn-default btn-white-bordered" onClick={() => onClose()}>OK</button>
                    <button className="btn-default btn-blue" onClick={() => callbackOpenPopup(retryModal)}>Retry</button>
                </div>
            </div>
        </MainModal>
    )
}

export default ModalWalletFailed;