import React from "react";
import { EXPORT_WALLET_PRIVATE_KEY_FLOW } from "../../../constants/ui";
import useModalNavigation from "../../../helpers/useModalNavigation";
import ArchivedWalletList from "../../VaultLists/ArchivedWalletList";
import VaultListHeader from "../../VaultLists/VaultListHeader";

const ArchivedWallets = ({ tab, data }) => {
  const [setActiveModalNav] = useModalNavigation(
    EXPORT_WALLET_PRIVATE_KEY_FLOW
  );
  const openEnterPinModal = () => {
    setActiveModalNav("export_prvkey_enter_pin");
  };

  return (
    <div>
      <div className="assets__overview_content_tab content_wallets active">
        <div className="activity__asset-filter-wrapper">
          <div className="filter_table_body wallets_items_ctn vault_active_wallet">
            <table width={"100%"}>
              <VaultListHeader tab={tab} />
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <ArchivedWalletList
                      key={index.toString()}
                      item={item}
                      index={index}
                      openEnterPinModal={openEnterPinModal}
                      // openEnterPinModal={openEnterPinModal}
                      // openEditLabelModal={openEditLabelModal}
                      // openQRModal={openQRModal}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivedWallets;
