import React from "react";
import { useApp } from "../../../helpers/AppProvider";

import ModalPinConfirmFunctions from "./../../common/SettingsChangeNameProcess/ModalPinConfirmFunctions";
import ModalNameUpdatedFunctions from "./../../common/SettingsChangeNameProcess/ModalNameUpdatedFunctions";
import ModalNameFailedFunctions from "./../../common/SettingsChangeNameProcess/ModalNameFailedFunctions";
import ModalNameCancelFunctions from "./../../common/SettingsChangeNameProcess/ModalNameCancelFunctions";
import Storage from "../../../helpers/Storage";

import {
  //   SETTINGS_CHANGE_NAME,
  DEFAULT_MODAL_STATUS,
} from "../../../constants/ui";

const SettingsChangeNameProcess = () => {
  // console.log(useApp().appData);
  const activeModalKey = Storage.load("ACTIVE_MODAL_KEY");
  let { activeModal: { label: activeModalLabel = DEFAULT_MODAL_STATUS } = {} } =
    useApp().appData || {};

  if (activeModalKey) {
    activeModalLabel = activeModalKey;
  }

  return (
    <>
      {
        // modal order:
        // ModalPinConfirm
        // ModalActionSuccess
        // ModalActionFailed
        // ModalActionConfirm
      }
      {activeModalLabel === "modal_name_enter_pin" && (
        <ModalPinConfirmFunctions />
      )}

      {activeModalLabel === "modal_name_is_updated" && (
        <ModalNameUpdatedFunctions />
      )}
      {activeModalLabel === "modal_edit_name_failed" && (
        <ModalNameFailedFunctions />
      )}
      {activeModalLabel === "modal_cancel_edit_name" && (
        <ModalNameCancelFunctions />
      )}
    </>
  );
};

export default SettingsChangeNameProcess;
