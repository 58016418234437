import Web3 from 'web3';

class Web3Store {

    constructor(){
        this.nodes = {}
    }

    getNodeFor( network, uri ){
        if( !this.nodes.hasOwnProperty(network) ){
            this.nodes[ network ] = new Web3( new Web3.providers.HttpProvider( uri ) );
        }
        return this.nodes[ network ];
    }
}

export default Web3Store;