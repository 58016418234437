import React, { useState } from "react";
import ModalAddAsset from "../components/dialogs/ModalAddAsset";
import ModalAssetAdded from "../components/dialogs/ModalAssetAdded";
import ModalAssetPurchase from "../components/dialogs/ModalAssetPurchase";
import ModalAssetReceive from "../components/dialogs/ModalAssetReceive";
import ModalAssetSend from "../components/dialogs/ModalAssetSend";
import ModalBuyCrypto from "../components/dialogs/ModalBuyCrypto";
import ModalNFTReceive from "../components/dialogs/ModalNFTReceive";
import ModalPinConfirm from "../components/dialogs/ModalPinConfirm";
import ModalSendNFT from "../components/dialogs/ModalSendNFT";
import ModalTransactionDone from "../components/dialogs/ModalTransactionDone";

const AssetsDialogScreen = () => {
  // openedPopup state hook
  const [openedPopup, setOpenedPopup] = useState(null);

  // pass this on all dialogs, in order to close from X btn
  const onClose = () => {
    setOpenedPopup(false);
  };

  // function for opening the popup
  const openPopup = (popup) => {
    setOpenedPopup(popup);
  };

  return (
    <>
      <div>
        <h1>Assets dialogs</h1>

        {/* example of button that sets openedPopup to modal_add_asset */}
        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_add_asset")}
        >
          Add Token Dialog
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_asset_added")}
        >
          Assets added Dialog
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_asset_purchase")}
        >
          Asset Purchase
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_receive_asset")}
        >
          Asset Receive
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_asset_send")}
        >
          Assets Send
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_buy_transak")}
        >
          Assets Buy Crypto
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_receive_asset")}
        >
          Assets NFT Receive
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_enter_pin")}
        >
          Assets Pin Confirm
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_nft_send")}
        >
          Assets Send NFT
        </button>

        <br />
        <br />

        <button
          className="control__button btn_blue modal__trigger"
          onClick={() => openPopup("modal_send_txn")}
        >
          Assets Transaction Done
        </button>
      </div>

      {/* example of popup */}
      {openedPopup === "modal_add_asset" && <ModalAddAsset onClose={onClose} />}

      {openedPopup === "modal_asset_added" && (
        <ModalAssetAdded onClose={onClose} />
      )}

      {openedPopup === "modal_asset_purchase" && (
        <ModalAssetPurchase onClose={onClose} />
      )}

      {openedPopup === "modal_receive_asset" && (
        <ModalAssetReceive onClose={onClose} />
      )}

      {openedPopup === "modal_asset_send" && (
        <ModalAssetSend onClose={onClose} />
      )}

      {openedPopup === "modal_buy_transak" && (
        <ModalBuyCrypto onClose={onClose} />
      )}

      {openedPopup === "modal_receive_asset" && (
        <ModalNFTReceive onClose={onClose} />
      )}

      {openedPopup === "modal_enter_pin" && (
        <ModalPinConfirm onClose={onClose} />
      )}

      {openedPopup === "modal_nft_send" && <ModalSendNFT onClose={onClose} />}

      {openedPopup === "modal_send_txn" && (
        <ModalTransactionDone onClose={onClose} />
      )}
    </>
  );
};

export default AssetsDialogScreen;
