import { convertIFPStoHttpUrl } from "../../../helpers/generalHelper";

const NFTImage = ({ img, imgClasses }) => {
  return (
    <div className="nft__item-img">
      <img
        src={
          img !== "https://usdcgo.com/image.png"
            ? convertIFPStoHttpUrl(img)
            : ""
        }
        alt=""
        className={`${imgClasses} h-full`}
      />
    </div>
  );
};

export default NFTImage;
